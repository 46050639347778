import { useEffect, useState, useContext } from 'react';
import { IonCard, IonRow, IonCol, IonButton } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import Overlay from '../../../../components/Overlays/Overlay';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import SecuritySettingsTabs from './SecuritySettingsTabs';
import SecuritySettingsApps from './SecuritySettingsApps';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';

const ManageSecuritySettings: React.FC<RouteIndexComponent> = ({
	uid,
	routeTitle,
	permissionTo,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [data, setData] = useState<any>([]);
	const [showOverlay, setShowOverlay] = useState<boolean>(false);
	const [overlayContent, setOverlayContent] = useState<any>(null);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [securitySettingName, setSecuritySettingName] = useState<string>('');
	const [overlayFooterContent, setOverlayFooterContent] = useState<any>(null);

	const columns = [
		{
			headerName: 'Security Setting',
			field: 'label',
			sortable: true,
			sort: 'asc',
			flex: 1,
		},
		{
			headerName: 'System Colour',
			field: 'colour',
			sortable: false,
			suppressSizeToFit: true,
			width: 200,
			cellRenderer: (params: any) => {
				return <div style={{ backgroundColor: '#' + params.data.colour }}>&nbsp;</div>;
			},
		},
	];

	const triggerOverlay = (params: any) => {
		setSecuritySettingName(params.label);
		setOverlayContent(
			<SecuritySettingsTabs
				mode='template'
				roleID={params.value}
				permissionTo={permissionTo}
				hideOverlay={hideOverlay}
				securitySettingName={params.label}
				securitySettingColour={params.colour}
				handleUpdate={updateGridData}
				setOverlayFooterContent={setOverlayFooterContent}
			/>
		);
		setShowOverlay(true);
	};

	const triggerOverlayCreate = () => {
		setSecuritySettingName('Create New');
		setOverlayContent(
			<SecuritySettingsTabs
				mode='template-new'
				permissionTo={permissionTo}
				hideOverlay={hideOverlay}
				securitySettingName={''}
				securitySettingColour={'D9D9D9'}
				handleUpdate={updateGridDataCreated}
				setOverlayFooterContent={setOverlayFooterContent}
			/>
		);
		setShowOverlay(true);
	};

	const hideOverlay = () => {
		setSecuritySettingName('');
		setShowOverlay(false);
		setOverlayContent(null);
		setOverlayFooterContent(null);
	};

	const handleCellClicked = (params: any) => {
		params.node.setSelected(true);
		setSelectedRow(params.data);
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'securitySetting') {
			handleCellClicked(params);
			handleEdit();
		}
	};

	const handleCreate = () => {
		triggerOverlayCreate();
	};

	const handleEdit = () => {
		triggerOverlay(selectedRow);
	};

	const handleCustomiseAppList = () => {
		setSecuritySettingName(selectedRow.label);
		setOverlayContent(
			<SecuritySettingsApps
				mode='template'
				roleID={selectedRow.value}
				permissionTo={permissionTo}
				securitySettingName={selectedRow.label}
				securitySettingColour={selectedRow.colour}
				hideOverlay={hideOverlay}
				handleUpdate={updateGridData}
				setOverlayFooterContent={setOverlayFooterContent}
			/>
		);
		setShowOverlay(true);
	};

	const loadGridData = async () => {
		await moduleCtx.getSecuritySettingsTemplatesOptions().then((res: any) => {
			const rowData = res.map((d: any, i: number) => {
				d.id = i;
				return d;
			});

			setData(rowData);
			setGridLoading(false);
		});
	};

	const updateGridData = (updatedData: any, rowID: string) => {
		const dataUpdated: Array<any> = data.map((arr: any) => {
			if (arr.value === rowID) {
				const colour = updatedData.securitySettingColour;
				arr.label = updatedData.securitySettingName;
				arr.colour = colour.substring(colour.indexOf('#') + 1);
			}
			return arr;
		});

		setData(dataUpdated);
	};

	const updateGridDataCreated = (updatedData: any) => {
		const colour = updatedData.securitySettingColour;

		setData((prevState: any) => [
			...prevState,
			{
				label: updatedData.securitySettingName,
				value: updatedData.securitySettingRoleID,
				colour: colour.substring(colour.indexOf('#') + 1),
				id: prevState.length,
			},
		]);
	};

	// Initial grid data load
	useEffect(() => {
		loadGridData();
	}, []);

	return (
		<>
			{showOverlay && (
				<Overlay
					hideFunction={() => hideOverlay()}
					content={overlayContent}
					title={`Security Setting: ${securitySettingName}`}
					footerContent={overlayFooterContent}
				/>
			)}
			<TitleBar title={routeTitle} />
			<IonCard className='table-card filter-data-table full-height-card'>
				<DataGrid
					title={'Manage Security Settings'}
					cols={columns}
					data={data}
					autoSize={true}
					cellClickedFunction={handleCellClicked}
					cellDoubleClickedFunction={handleCellDoubleClicked}
					extraFooter={
						<IonRow>
							<IonCol size={'12'} className='text-right pe-0'>
								{permissionTo('update') && (
									<>
										<IonButton
											onClick={handleCustomiseAppList}
											color='secondary'
											disabled={!selectedRow || gridLoading}
										>
											Customise App List
										</IonButton>
										<IonButton
											onClick={handleEdit}
											color='primary'
											disabled={!selectedRow || gridLoading}
										>
											Edit Item
										</IonButton>
									</>
								)}
								{permissionTo('create') && (
									<IonButton onClick={handleCreate} color='success'>
										Create New
									</IonButton>
								)}
							</IonCol>
						</IonRow>
					}
				/>
			</IonCard>
		</>
	);
};

export default ManageSecuritySettings;

import Submenu from '../../../../components/Menu/Submenu';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import AccountDetailsSubmenuArray from './pages/arrays/AccountDetailsSubmenuArray';

const AccountDetailsIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />

				<Submenu array={AccountDetailsSubmenuArray(uid)} />
			</div>
		</>
	);
};

export default AccountDetailsIndex;

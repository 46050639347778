import { useState, useEffect } from 'react';

import axios from '../../lib/axios';
import { useSubscribe } from '../../helpers/events';

interface Props {
	floating?: boolean;
}

const NotificationsBadge: React.FC<Props> = (props: Props) => {
	const [count, setCount] = useState<string>('');

	useEffect(() => {
		getCount();
	}, []);

	useSubscribe(
		'database:sync:Item',
		() => {
			getCount();
		},
		[]
	);

	useSubscribe(
		'notification:badge',
		() => {
			getCount();
		},
		[]
	);

	const getCount = (): Promise<boolean> => {
		return new Promise((res, rej) => {
			// Fetch the navigation data
			axios.post('/api/notifications/count', {}).then((result:any) => {
				setCount(String(result.data));
				res(true);
			});
		});
	};

	return count !== '' && count !== '0' ? (
		<div className={(props.floating === true || props.floating === undefined ? 'fl-badge ':'') + 'badge badge-number background-danger'}>{count}</div>
	) : null;
};

export default NotificationsBadge;

import { useEffect, useState } from 'react';
import Loading from '../../../../components/UI/Loading';
import { Alert, AlertTitle } from '@mui/material';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { showToast } from '../../../../lib/toast';
import axios from '../../../../lib/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarNoSel } from '@fortawesome/free-regular-svg-icons';
import { IonButton } from '@ionic/react';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';

interface Props {
	parentRef: any;
	title: string;
	uid: string;
}

const KPIIndex: React.FC<Props> = (props: Props) => {
	const { jobId }: any = useParams();
	const permissionTo = usePermissionTo(props.uid);
	const KPIStatusDefault = 'Loading...';
	let KPIStarsDefault: Array<any> = [];

	for (var i = 0; i < 5; i++) {
		KPIStarsDefault.push(
			<FontAwesomeIcon key={i} className='rating-star no-sel' icon={faStarNoSel} />
		);
	}

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isRequesting, setIsRequesting] = useState<boolean>(false);
	const [KPIRating, setKPIRating] = useState<number>(0);
	const [KPIStars, setKPIStars] = useState<any>(KPIStarsDefault);
	const [KPIStatus, setKPIStatus] = useState<string>(KPIStatusDefault);

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		if (isLoading) setKPIStatus(KPIStatusDefault);
	}, [isLoading]);

	useEffect(() => {
		let tmp: Array<any> | null = [];

		for (var i = 0; i < KPIRating; i++) {
			tmp.push(<FontAwesomeIcon key={i} className='rating-star' icon={faStar} />);
		}

		for (var i = 0; i < 5 - KPIRating; i++) {
			tmp.push(
				<FontAwesomeIcon key={`${i}-no-sel`} className='rating-star no-sel' icon={faStarNoSel} />
			);
		}

		setKPIStars(tmp);
		tmp = null;
	}, [KPIRating]);

	const loadData = () => {
		setIsLoading(true);

		axios
			.post('/api/jobs/jobs_list/job_card/kpi', { job_id: jobId })
			.then((res: any) => {
				setKPIStatus(
					res.data.rating
						? `Score received ${DateTime.fromISO(res.data.rating_date).toFormat('dd/MM/yyyy')}`
						: 'Awaiting score'
				);
				setKPIRating(res.data.rating);
			})
			.catch(() => showToast('error'))
			.finally(() => setIsLoading(false));
	};

	const handleSendKPIRequest = () => {
		setIsRequesting(true);

		axios
			.post('/api/jobs/jobs_list/job_card/kpi/send', { job_id: jobId })
			.then(() => showToast('success', 'Rating request sent'))
			.catch((err: any) => showToast('error', err.response.data.message))
			.finally(() => setIsRequesting(false));
	};

	return (
		<div className='jobkpi-container'>
			<div>
				<h4 className='font-bold data-grid-title'>{props.title}</h4>
			</div>

			<div>
				<Alert severity='info' className='mx-0 mb-2'>
					<AlertTitle>
						<strong>Status:</strong> {KPIStatus}
					</AlertTitle>
					Client KPI score for this job
				</Alert>
			</div>
			{(isLoading || isRequesting) && <Loading overlay={true} />}

			<div className='rating-stars-container'>{KPIStars}</div>

			<div className='button-container'>
				{permissionTo('update') && (
					<IonButton onClick={handleSendKPIRequest}>Send KPI Request</IonButton>
				)}
			</div>
		</div>
	);
};

export default KPIIndex;

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './state/store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Settings } from 'luxon';
Settings.defaultLocale = navigator.language;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

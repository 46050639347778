import { useEffect, useState, useContext, useMemo } from 'react';
import { IonCard, IonRow, IonCol, useIonAlert, IonButton } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import FilterMenu from '../../../../components/Menu/FilterMenu';
import { moduleContext } from '../../../../contexts/ModuleContext';
import axios from '../../../../lib/axios';
import WorkingHoursCalendar from '../../../../components/Calendars/WorkingHoursCalendar';
import { DateTime } from 'luxon';
import { showToast } from '../../../../lib/toast';
import './WorkingHoursAndBreaktimes.scss';
import Form from '../../../../components/Forms/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { getGroupedOptionByValue } from '../../../../lib/functions';
import { getBreaktimeOptions } from '../../../../helpers/times';

interface RouteIndexComponentExtended extends RouteIndexComponent {
	usageMode?: string;
	workerId?: string;
}

const WorkingHoursAndBreaktimesIndex: React.FC<RouteIndexComponentExtended> = ({
	uid,
	routeTitle,
	permissionTo,
	usageMode,
	workerId,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const breaktimeOptions = getBreaktimeOptions();
	const [rolesLoading, setRolesLoading] = useState<boolean>(true);
	const [roles, setRoles] = useState<Array<any>>([]);
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [workerLocationsLoading, setWorkerLocationsLoading] = useState<boolean>(true);
	const [locationsOptions, setLocationsOptions] = useState<Array<any>>([]);
	const [hoursType, setHoursType] = useState<any>({});
	const [calendarTitle, setCalendarTitle] = useState<string>('Default');
	const [calDataLoading, setCalDataLoading] = useState<boolean>(true);
	const [calData, setCalData] = useState<Array<any>>([]);
	const [calReloadData, setCalReloadData] = useState<Array<any>>([]);
	const [calUndoData, setCalUndoData] = useState<any>([]);
	const [allowScrollToEvent, setAllowScrollToEvent] = useState<boolean>(false);
	const [breaktimeDropdownData, setBreaktimeDropdownData] = useState<any>([]);
	const [locationsDropdownData, setLocationsDropdownData] = useState<any>([]);
	const [presentAlert] = useIonAlert();
	const weekdaysIso = useMemo(() => {
		return ['', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
	}, []);

	useEffect(() => {
		loadRoles();
		loadWorkers();
		loadWorkerLocations();
	}, []);

	useEffect(() => {
		if (!rolesLoading && !workersLoading && !workerLocationsLoading) {
			// Load the first item initially
			const data = roles.filter((r: any) => r._id === '0')[0];
			loadCalendarData(null, { parentID: data._id, title: data.label });
		}
	}, [rolesLoading, workersLoading, workerLocationsLoading]);

	const loadRoles = () => {
		setRolesLoading(true);

		moduleCtx.getSecuritySettingsTemplatesOptions().then((res: any) => {
			const rowData = res.map((d: any) => {
				return {
					_id: d.value,
					label: d.label,
					colour: d.colour,
				};
			});
			setRoles([{ _id: '0', label: 'Default', colour: null }, ...rowData]);
			setRolesLoading(false);
		});
	};

	const loadWorkers = () => {
		setWorkersLoading(true);

		moduleCtx.getWorkers().then((res: any) => {
			const rowData = res.map((d: any) => {
				return { ...d, parentID: d.role_id };
			});
			setWorkers(rowData);
			setWorkersLoading(false);
		});
	};

	const loadWorkerLocations = () => {
		setWorkerLocationsLoading(true);

		moduleCtx.getWorkerLocationOptions().then((res: any) => {
			setLocationsOptions(res);
			setWorkerLocationsLoading(false);
		});
	};

	const loadCalendarData = (e: any, data: any) => {
		if (usageMode === 'worker_tabs' && workerId) {
			// Pre-fill the data if the worker ID is already known
			e = null;
			data = { childID: workerId, title: null };
		}

		setCalDataLoading(true);
		setCalReloadData(data);
		setCalendarTitle(data.title);
		setBreaktimeDropdownData([]);
		setLocationsDropdownData([]);
		setAllowScrollToEvent(true);

		// Clear the undo data if changing the role or worker category
		if (e && e.type) {
			if (e.type.toLowerCase() === 'ionchange' || e.type.toLowerCase() === 'click') {
				clearUndoData();
			}
		}

		let ident: string = '';
		let identVal: string = '';
		let payload: any = {};

		if (data.hasOwnProperty('parentID') && data.parentID !== '0') {
			ident = 'role_id';
			identVal = data.parentID;
			payload[ident] = identVal;
		}

		if (data.hasOwnProperty('childID')) {
			ident = 'worker_id';
			identVal = data.childID;
			payload[ident] = identVal;
		}

		axios
			.post('/api/utilities/workers/working_hours', payload)
			.then((res) => {
				setCalData(
					res.data.map((d: any) => {
						if (payload[ident]) d[ident] = identVal;
						return d;
					})
				);
				setHoursType(payload);
			})
			.catch((err) => {
				setCalData([]);
			})
			.finally(() => {
				setCalDataLoading(false);
			});
	};

	// Build the breaktime dropdown data
	useEffect(() => {
		setBreaktimeDropdownData([]);

		// Build the breaktime dropdowns within form objects
		if (calData.length > 0) {
			for (let i = 1; i < weekdaysIso.length; i++) {
				const eventData: any = calData.filter((cd: any) => cd.day_of_week === weekdaysIso[i]);
				const hoursAllowance: any = eventData.length > 0 ? eventData[0].hours_allowance : null;
				const endpointID: any = eventData.length > 0 ? eventData[0]._id : null;
				const dayHasEvents = eventData && eventData.length > 0;

				setBreaktimeDropdownData((prevState: any) => [
					...prevState,
					{
						dayOfWeek: weekdaysIso[i],
						disabled: !dayHasEvents || !permissionTo('update'),
						dropdownForm: (
							<Form
								array={[
									{
										type: 'dropdown',
										className: 'breaktime-select',
										values: breaktimeOptions,
										defaultValue: breaktimeOptions.filter(
											(option: any) => option.value === hoursAllowance
										),
										disabled: !dayHasEvents || !permissionTo('update'),
										stylesExtra: { menuPortal: (base: any) => ({ ...base, fontSize: '13px' }) },
										isSearchable: false,
										onChangeCallback: (option: any) => {
											// Update the calendar data state array after a breaktime change. This is because
											// we do not reload after a breaktime change, and therefore need to keep state up
											// to date to avoid any issues or strange behaviour.
											onCalBreakTimeUpdate(weekdaysIso[i], option);
										},
										db: ['hours_allowance'],
									},
								]}
								forceEdit={true}
								noButton={true}
								tableMode={true}
								permissionTo={permissionTo}
								endpoint={'/api/utilities/workers/working_hours/'}
								endpointID={endpointID}
							/>
						),
					},
				]);
			}
		}
	}, [calData]);

	// Build the locations dropdown data
	useEffect(() => {
		setLocationsDropdownData([]);

		// Build the breaktime dropdowns within form objects
		if (calData.length > 0) {
			for (let i = 1; i < weekdaysIso.length; i++) {
				const eventData: any = calData.filter((cd: any) => cd.day_of_week === weekdaysIso[i]);
				const location: any = eventData.length > 0 ? eventData[0].location : null;
				const endpointID: any = eventData.length > 0 ? eventData[0]._id : null;
				const dayHasEvents = eventData && eventData.length > 0;

				// Set the default value
				let defaultValue = [locationsOptions[0].options[0]];

				// If a location is set then filter-out the correct group
				if (location) defaultValue = getGroupedOptionByValue(locationsOptions, location);

				setLocationsDropdownData((prevState: any) => [
					...prevState,
					{
						dayOfWeek: weekdaysIso[i],
						disabled: !dayHasEvents || !permissionTo('update'),
						dropdownForm: (
							<Form
								array={[
									{
										type: 'dropdown',
										className: 'locations-select',
										values: locationsOptions,
										defaultValue: defaultValue,
										disabled: !dayHasEvents || !permissionTo('update'),
										stylesExtra: { menuPortal: (base: any) => ({ ...base, fontSize: '13px' }) },
										isSearchable: false,
										onChangeCallback: (option: any) => {
											// Update the calendar data state array after a location change. This is because
											// we do not reload after a location change, and therefore need to keep state up
											// to date to avoid any issues or strange behaviour.
											onCalLocationsUpdate(weekdaysIso[i], option);
										},
										db: ['location'],
									},
								]}
								forceEdit={true}
								noButton={true}
								tableMode={true}
								permissionTo={permissionTo}
								endpoint={'/api/utilities/workers/working_hours/'}
								endpointID={endpointID}
							/>
						),
					},
				]);
			}
		}
	}, [calData]);

	const onCalEventCreate = (event: any) => {
		// Stop the event from spanning over a day
		if (
			DateTime.fromJSDate(event.event.start).toFormat('d') !==
			DateTime.fromJSDate(event.event.end).toFormat('d')
		) {
			showToast('error', 'Working hours cannot span days');
			loadCalendarData(null, calReloadData);
			clearUndoData();
			return false;
		}

		const calEvent = {
			hours_start: DateTime.fromJSDate(event.event.start).toFormat('HH:mm'),
			hours_end: DateTime.fromJSDate(event.event.end).toFormat('HH:mm'),
			day_of_week: DateTime.fromJSDate(event.event.start).toFormat('ccc').toLowerCase(),
			hours_allowance: '0:00',
			...hoursType,
		};

		setCalDataLoading(true);

		axios
			.put('/api/utilities/workers/working_hours', calEvent)
			.then((res) => {
				calEvent._id = res.data._id;
				setBreaktimeDropdownData([]);
				setLocationsDropdownData([]);
				setCalData((prevState: any) => [...prevState, calEvent]);
				showToast('success', 'Successfully created');
				clearUndoData();
			})
			.finally(() => {
				setCalDataLoading(false);
			});
	};

	const onCalEventUpdate = (event: any) => {
		const calEvent = event.newEvent ? event.newEvent : event.event;

		// Stop the event from spanning over a day
		if (DateTime.fromJSDate(calEvent.start).day !== DateTime.fromJSDate(calEvent.end).day) {
			showToast('error', 'Working hours cannot span days');
			loadCalendarData(null, calReloadData);
			clearUndoData();
			return false;
		}

		const calDataUpdated = calData.map((evt: any) => {
			if (evt._id === calEvent._id) {
				evt.hours_start = DateTime.fromJSDate(calEvent.start).toFormat('HH:mm');
				evt.hours_end = DateTime.fromJSDate(calEvent.end).toFormat('HH:mm');
				evt.day_of_week = DateTime.fromJSDate(calEvent.start).toFormat('ccc').toLowerCase();
			}
			return evt;
		});

		const payloadTmp: any = calDataUpdated.filter((d: any) => d._id === calEvent._id)[0];

		// We're not interested at all in changing hours_allowance when updating event times,
		// so don't send it back to the API by copying the payloadTmp object and deleting the key.
		const payload = { ...payloadTmp };
		delete payload['hours_allowance'];

		setCalDataLoading(true);

		axios
			.put(`/api/utilities/workers/working_hours/${calEvent._id}`, payload)
			.then((res) => {
				setBreaktimeDropdownData([]);
				setLocationsDropdownData([]);
				setCalData(calDataUpdated);
				showToast('success', 'Successfully updated');
			})
			.catch((err) => {
				clearUndoData();
			})
			.finally(() => {
				setCalDataLoading(false);
			});
	};

	const onCalEventDragStart = (event: any) => {
		const eventToUndo = event.event;

		// Clear existing undo data
		clearUndoData();

		// Capture the undo data
		setCalUndoData((prevState: any) => [{ undoType: 'update', event: eventToUndo }]);
	};

	const onCalEventDelete = (event: any) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						onCalEventDeleteGo(event);
					},
				},
			],
		});
	};

	const onCalEventDeleteGo = (e: any) => {
		setCalDataLoading(true);

		axios
			.delete(`/api/utilities/workers/working_hours/${e.event._id}`)
			.then((res) => {
				setBreaktimeDropdownData([]);
				setLocationsDropdownData([]);
				setCalData((prevState: any) => prevState.filter((d: any) => d._id !== e.event._id));
				showToast('deleted');
				clearUndoData();
			})
			.finally(() => {
				setCalDataLoading(false);
			});
	};

	const onCalBreakTimeUpdate = (dayOfWeek: string, option: any) => {
		const _id = calData.filter((d: any) => d.day_of_week === dayOfWeek)[0]._id;
		const hoursAllowance = option.value;

		setCalData((prevState: any) =>
			prevState.map((p: any) => {
				if (p._id === _id) {
					p.hours_allowance = hoursAllowance;
				}
				return p;
			})
		);
	};

	const onCalLocationsUpdate = (dayOfWeek: string, option: any) => {
		const _id = calData.filter((d: any) => d.day_of_week === dayOfWeek)[0]._id;
		const location = option.value;

		setCalData((prevState: any) =>
			prevState.map((p: any) => {
				if (p._id === _id) {
					p.location = location;
				}
				return p;
			})
		);
	};

	const onCalUndo = () => {
		const undoData = calUndoData[0];

		switch (undoData.undoType) {
			case 'update':
				onCalEventUpdate({ event: undoData.event });
				break;
		}

		clearUndoData();
	};

	const clearUndoData = () => {
		setCalUndoData([]);
	};

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				{!usageMode && <TitleBar title={routeTitle} />}
				<IonRow>
					{!usageMode && (
						<IonCol size={'2'} className='pt-0'>
							<FilterMenu
								loading={rolesLoading || workersLoading}
								items={roles}
								children={workers}
								handleOnClick={loadCalendarData}
								handleAccordionChange={loadCalendarData}
							/>
						</IonCol>
					)}
					<IonCol size={!usageMode ? '10' : '12'} className='p-0'>
						<IonCard className='table-card full-height-card'>
							{!usageMode && <h4 className='font-bold cal-title'>{calendarTitle}</h4>}
							<h5 className='font-bold mt-3'>
								Create Working Hours blocks by left-clicking and dragging up or down (or
								double-click within a day's column). Right-click on a block to delete it.
							</h5>
							<IonButton
								className='btn-undo'
								onClick={onCalUndo}
								color='secondary'
								disabled={
									calUndoData.length === 0 || rolesLoading || workersLoading || calDataLoading
								}
							>
								<FontAwesomeIcon icon={faUndo} />
								Undo
							</IonButton>
							<WorkingHoursCalendar
								loading={calDataLoading}
								calData={calData}
								permissionTo={permissionTo}
								allowScrollToEvent={allowScrollToEvent}
								useContextMenu={true}
								onEventCreate={onCalEventCreate}
								onEventUpdate={onCalEventUpdate}
								onEventDragStart={onCalEventDragStart}
								onEventDelete={onCalEventDelete}
								setAllowScrollToEvent={setAllowScrollToEvent}
								breaktimeDropdownData={breaktimeDropdownData}
								locationsDropdownData={locationsDropdownData}
								clearUndoData={clearUndoData}
							/>
						</IonCard>
					</IonCol>
				</IonRow>
			</div>
		</>
	);
};

export default WorkingHoursAndBreaktimesIndex;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton, IonContent, IonModal, IonSpinner } from '@ionic/react';
import { Ref, useRef } from 'react';
import ReactImageGallery from 'react-image-gallery';
import './Media.scss';

interface Props {
	isVisible: boolean;
	isLoading: boolean;
	media: Array<any>;
	onWillDismiss: Function;
}

const Media: React.FC<Props> = (props: Props) => {
	const ref: any  = useRef<Ref<HTMLIonModalElement>>();

	return (
		<>
			<IonModal
				className='chat-media'
				ref={ref}
				isOpen={props.isVisible}
				onWillDismiss={() => {
					props.onWillDismiss();
				}}
			>
				<IonContent>
					<IonSpinner color={'primary'} hidden={!props.isLoading}></IonSpinner>
					<IonButton
						hidden={props.isLoading}
						className='icon-only media-close'
						fill='clear'
						color='white'
						onClick={() => ref.current.dismiss()}
					>
						<FontAwesomeIcon icon={faTimes} />
					</IonButton>
					
					{!props.isLoading && (
						<ReactImageGallery lazyLoad={true} items={props.media} showPlayButton={false} />
					)}
				</IonContent>
			</IonModal>
		</>
	);
};

export default Media;

import { useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { useHistory } from 'react-router-dom';
import { kpiFormatter } from '../../../../helpers/kpiFormatter';

interface Props {
	permissionTo: Function;
	clientId: string;
}

const JobHistoryIndex: React.FC<Props> = (props: Props) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const permissionToJobCard = usePermissionTo('jobs.jobs_list.job_card');
	let history = useHistory();

	useEffect(() => {
		loadGridData();
	}, []);

	const colData: Array<any> = [
		{
			headerName: 'Job Number',
			field: 'number',
			filter: 'agTextColumnFilter',
			sort: 'desc',
			flex: 1,
		},
		{
			headerName: 'Job Description',
			field: 'description',
			filter: 'agTextColumnFilter',
			flex: 1,
		},
		{
			headerName: 'Start Date',
			field: 'start_date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			flex: 1,
		},
		{
			headerName: 'Finish Date',
			field: 'finish_date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			flex: 1,
		},
		{
			headerName: 'Job Manager',
			field: 'manager',
			filter: 'agTextColumnFilter',
			flex: 1,
		},
		{
			headerName: 'KPI',
			field: 'kpi',
			valueFormatter: kpiFormatter,
			flex: 1,
		},
	];

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		axios
			.post(`/api/jobs/clients_list/client_card/history`, { client_id: props.clientId })
			.then((res: any) => {
				setData(res.data);
				setGridLoading(false);
			});
	};

	const handleCellClicked = (params: any) => {
		params.node.setSelected(true);
		setSelectedRow(params.data);
	};

	const handleCellDoubleClicked = (params: any) => {
		handleCellClicked(params);
		handleGoToJob();
	};

	const handleGoToJob = () => {
		history.push(`/jobs/jobs_list/job_card/${selectedRow.id}`);
	};

	const handleViewClientPage = () => {
		window.open(selectedRow.client_page);
	};

	// Security properties for the data table
	let dataTableSecurityProps: any = {};
	if (permissionToJobCard('update')) {
		dataTableSecurityProps = {
			cellClickedFunction: handleCellClicked,
			cellDoubleClickedFunction: handleCellDoubleClicked,
		};
	}

	return (
		<>
			<DataGrid
				title={'Job History'}
				cols={colData}
				data={data}
				rowCursorPointer={true}
				{...dataTableSecurityProps}
				showFooter={false}
				extraFooter={
					permissionToJobCard('update') && (
						<div className='text-right'>
							<IonButton
								color='theme-beta-quaternary-dark'
								onClick={handleViewClientPage}
								disabled={!selectedRow || !selectedRow.client_page || gridLoading}
							>
								View Client Page
							</IonButton>
							<IonButton
								color='primary'
								onClick={handleGoToJob}
								disabled={!selectedRow || gridLoading}
							>
								Go to Job
							</IonButton>
						</div>
					)
				}
			/>
		</>
	);
};

export default JobHistoryIndex;

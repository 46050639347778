import axios from '../../../../lib/axios';
import Subnav from '../../../../components/Menu/Subnav';
import { useState, useEffect } from 'react';
import Loading from '../../../../components/UI/Loading';
import SSTab from './SSTab';
import './SecuritySettings.scss';

interface Props {
	mode: string;
	roleID?: string;
	workerID?: string;
	permissionTo: Function;
	hideOverlay: Function;
	securitySettingName?: string;
	securitySettingColour?: string;
	handleUpdate: Function;
	setOverlayFooterContent: Function;
}

const SecuritySettingsTabs: React.FC<Props> = ({
	mode,
	roleID,
	workerID,
	permissionTo,
	hideOverlay,
	securitySettingName,
	securitySettingColour,
	handleUpdate,
	setOverlayFooterContent,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [moduleTabsArray, setModuleTabsArray] = useState<Array<any>>([]);

	useEffect(() => {
		switch (mode) {
			case 'template':
				// Load the module navigation data
				axios.get('/api/system/modules').then((modules) => {
					// Load the all role's permissions
					axios
						.get(`/api/utilities/security_settings/permissions/${roleID}`)
						.then((permissions) => {
							const tabs = modules.data.map((tab: any, i: number) => {
								return {
									roleID: roleID,
									moduleID: tab._id,
									title: tab.module_name,
									active: i === 0,
									page: SSTab,
									pageData: {
										mode,
										data: modules.data,
										permissionsArray: permissions.data,
										roleID,
										moduleID: tab._id,
										permissionTo,
										hideOverlay,
										securitySettingName,
										securitySettingColour,
										handleUpdate,
										setOverlayFooterContent,
									},
								};
							});
							setModuleTabsArray(tabs);
							setIsLoading(false);
						});
				});
				break;
			case 'template-new':
				// Load the module navigation data
				axios.get('/api/system/modules').then((modules) => {
					const tabs = modules.data.map((tab: any, i: number) => {
						return {
							roleID: '',
							moduleID: tab._id,
							title: tab.module_name,
							active: i === 0,
							page: SSTab,
							pageData: {
								mode,
								data: modules.data,
								permissionsArray: [],
								roleID: '',
								moduleID: tab._id,
								permissionTo,
								hideOverlay,
								securitySettingName,
								securitySettingColour,
								handleUpdate,
								setOverlayFooterContent,
							},
						};
					});
					setModuleTabsArray(tabs);
					setIsLoading(false);
				});
				break;
			case 'worker':
				// Load the module navigation data
				axios.get('/api/system/modules').then((modules) => {
					// Load the all worker's permissions
					axios
						.get(`/api/utilities/security_settings/permissions/${roleID}/worker/${workerID}`)
						.then((permissions) => {
							const tabs = modules.data.map((tab: any, i: number) => {
								return {
									workerID: workerID,
									moduleID: tab._id,
									title: tab.module_name,
									active: i === 0,
									page: SSTab,
									pageData: {
										mode,
										data: modules.data,
										permissionsArray: permissions.data,
										roleID,
										workerID,
										moduleID: tab._id,
										permissionTo,
										hideOverlay,
										handleUpdate,
										setOverlayFooterContent,
									},
								};
							});
							setModuleTabsArray(tabs);
							setIsLoading(false);
						});
				});
				break;
			default:
				break;
		}
	}, []);

	return (
		<>
			{isLoading && <Loading />}
			{!isLoading && <Subnav array={moduleTabsArray} />}
		</>
	);
};

export default SecuritySettingsTabs;

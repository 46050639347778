import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { forwardRef, useState } from 'react';
import HeatmapsIndex from '../../../Workers/Workers/Tracking/Heatmaps/HeatmapsIndex';
import { HeatmapsUsageMode } from '../../../Workers/Workers/workers-types';
import DateProvider from '../../../../contexts/DateContext';

type Props = {
	onClose: Function;
};

const HeatmapModal = forwardRef<Props, any>((props: Props, ref: any) => {
	const [modalTitle, setModalTitle] = useState<string>('');
	const [heatmapsIndexProps, setHeatmapsIndexProps] = useState<any | undefined>(undefined);

	const handleOnDidPresent = () => {
		setHeatmapsIndexProps({
			parentRef: null,
			tabRef: null,
			permissionTo: null,
			usageMode: ref.current.allocationData.usageMode,
			workerId: ref.current.allocationData.workerId,
			startDate: ref.current.allocationData.startDate,
			endDate: ref.current.allocationData.endDate,
		});

		let titleDates: string = '';
		switch (ref.current.allocationData.usageMode) {
			case HeatmapsUsageMode.TIMESHEET_DAILY:
				titleDates = `${ref.current.allocationData.startDate.toFormat('dd/MM/yyyy')}`;
				break;
			case HeatmapsUsageMode.TIMESHEET_WEEKLY:
				titleDates = `Week ending ${ref.current.allocationData.endDate.toFormat('dd/MM/yyyy')}`;
				break;
		}

		setModalTitle(`Heatmap for ${ref.current.allocationData.workerName} | ${titleDates}`);
	};

	const onClose = () => {
		// Reset
		setModalTitle('');
		setHeatmapsIndexProps(undefined);
		props.onClose();
	};

	return (
		<IonModal
			ref={ref}
			class='timesheets-heatmap-modal'
			style={{ '--width': '80vw', '--height': '98vh' }}
			onDidPresent={handleOnDidPresent}
			onWillDismiss={() => onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{modalTitle}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<DateProvider>
					{heatmapsIndexProps && <HeatmapsIndex {...heatmapsIndexProps} />}
				</DateProvider>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
});

export default HeatmapModal;

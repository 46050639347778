import KeyDetails from './WorkerTabs/KeyDetails';
import PayrollDetails from './WorkerTabs/PayrollDetails';
import NextOfKin from './WorkerTabs/NextOfKin';
import WorkingHours from './WorkerTabs/WorkingHours';
import ManagementStructure from './WorkerTabs/ManagementStructure';
import UserDefinedFields from './WorkerTabs/UserDefinedFields';
import { EngagementTypes } from './workers-types';
import WorkCalendar from './WorkerTabs/WorkCalendar';

const EditWorkerTabsArray: any = (permissionTo: Function) => {
	return [
		{
			id: 'key_details',
			title: 'Key Details',
			page: KeyDetails,
			active: true,
			pageData: { permissionTo, newWorker: false },
		},
		{
			id: 'payroll_details',
			title: 'Payroll Details',
			page: PayrollDetails,
			active: false,
			pageData: { permissionTo },
		},
		{
			id: 'next_of_kin',
			title: 'Next of Kin',
			page: NextOfKin,
			active: false,
			engagementType: EngagementTypes.DIRECTLY,
			pageData: { permissionTo },
		},
		{
			id: 'working_hours',
			title: 'Working Hours',
			page: WorkingHours, // Permissions are set in the component
			active: false,
			engagementType: EngagementTypes.DIRECTLY,
		},
		{
			id: 'work_calendar',
			title: 'Work Calendar',
			page: WorkCalendar, // Permissions are set in the component
			active: false,
			engagementType: EngagementTypes.SELF,
			pageData: { permissionTo },
		},
		{
			id: 'management_structure',
			title: 'Management Structure',
			page: ManagementStructure, // Permissions are set in the component
			active: false,
		},
		{
			id: 'user_defined_fields',
			title: 'User Defined Fields',
			page: UserDefinedFields,
			active: false,
			pageData: { permissionTo },
		},
	];
};

export default EditWorkerTabsArray;

import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import HATProvider from './HATProvider';
import HATLayout from './HATLayout';

const HATIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	return (
		<HATProvider>
			<HATLayout />
		</HATProvider>
	);
};

export default HATIndex;

import { useContext, useEffect, useRef, useState } from 'react';
import { faCirclePlus, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonButton, useIonAlert, IonInput } from '@ionic/react';
import { Alert, AlertTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { DateTime } from 'luxon';
import { NumberField, SingleDatePicker } from '../../../components/Forms/FormFields';
import { moduleContext } from '../../../contexts/ModuleContext';
import { authContext } from '../../../contexts/AuthContext';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import Loading from '../../../components/UI/Loading';
import styles from './RatesOfPay.module.scss';
import { EngagementTypes } from '../Workers/workers-types';

interface Props {
	workerId: string;
	payType: string;
	typeOfEngagement: EngagementTypes;
	permissionTo: Function;
}

const RatesOfPay = ({ workerId, payType, typeOfEngagement, permissionTo }: Props) => {
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const dateFromNewRef = useRef<any>(null);
	const amountNewRef = useRef<any>(null);
	const niNewRef = useRef<any>(null);
	const hoursPerDayNewRef = useRef<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tableDataLoading, setTableDataLoading] = useState<boolean>(true);
	const [tableData, setTableData] = useState<Array<any>>([]);
	const [NIRateLoading, setNIRateLoading] = useState<boolean>(true);
	const [NIRate, setNIRate] = useState<any>({});
	const [newTotal, setNewTotal] = useState<number>(0);
	const [reloadAmount, setReloadAmount] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();
	let tableTitle = '';
	let amountTitle = '';

	switch (payType) {
		case 'salary':
			tableTitle = "Annual salary inclusive of employer's national insurance contributions";
			amountTitle = `Amount ${authCtx.tenant.currency_symbol}`;
			break;
		case 'daily':
			tableTitle = 'Daily rate';
			if (typeOfEngagement === EngagementTypes.DIRECTLY) {
				tableTitle += " inclusive of employer's national insurance contributions";
			}
			amountTitle = `Amount per day ${authCtx.tenant.currency_symbol}`;
			break;
		case 'hourly':
			tableTitle = 'Hourly rate';
			if (typeOfEngagement === EngagementTypes.DIRECTLY) {
				tableTitle += " inclusive of employer's national insurance contributions";
			}
			amountTitle = `Amount per hour ${authCtx.tenant.currency_symbol}`;
			break;
	}

	useEffect(() => {
		loadTableData();

		if (typeOfEngagement === EngagementTypes.DIRECTLY) {
			loadNIRate();
		} else {
			setNIRateLoading(false);
		}
	}, [payType]);

	useEffect(() => {
		setIsLoading(tableDataLoading === true || NIRateLoading === true);
	}, [tableDataLoading, NIRateLoading]);

	const loadTableData = () => {
		setTableDataLoading(true);

		let payload: any = { worker_id: workerId };

		axios
			.post('/api/workers/workers_list/worker_card/rates_of_pay', payload)
			.then((res: any) => {
				setTableData(res.data);
			})
			.catch(() => showToast('error'))
			.finally(() => setTableDataLoading(false));
	};

	const loadNIRate = () => {
		setNIRateLoading(true);

		moduleCtx
			.getNIRate()
			.then((res: any) => {
				setNIRate(res.rate);

				// Set the default new NI field value
				if (niNewRef.current) {
					niNewRef.current.value = res.rate;
				}
			})
			.finally(() => {
				setNIRateLoading(false);
			});
	};

	const handleCreate = () => {
		const dateFromNew = dateFromNewRef.current ? dateFromNewRef.current.value : '';
		const amountNew = amountNewRef.current ? amountNewRef.current.value : '';
		const niNew = niNewRef.current ? niNewRef.current.value : '';
		const hoursPerDayNew = hoursPerDayNewRef.current ? hoursPerDayNewRef.current.value : '';

		let payload: any = {
			worker_id: workerId,
			payment_method: payType,
			date_from: dateFromNew,
			amount: amountNew,
		};

		// Check all fields have values
		if (!dateFromNew || String(amountNew).length === 0) {
			showToast('error', 'Please fill-in all fields correctly');
			return;
		}

		// Engagement type logic
		switch (typeOfEngagement) {
			case EngagementTypes.DIRECTLY:
				if (String(niNew).length === 0) {
					showToast('error', 'Please fill-in all fields correctly');
					return;
				}

				payload.national_insurance = niNew;
				break;
			case EngagementTypes.SELF:
				if (String(hoursPerDayNew).length === 0) {
					showToast('error', 'Please fill-in all fields correctly');
					return;
				}

				payload.hours_per_day = hoursPerDayNew;
				break;
		}

		setIsLoading(true);

		axios
			.put('/api/workers/workers_list/worker_card/rates_of_pay', payload)
			.then(() => {
				showToast('saved');

				// Reload the rows
				loadTableData();

				// Clear form
				dateFromNewRef.current.setVal('');
				setReloadAmount((prevState: any) => !prevState); // Force reload to get back to zero
				if (niNewRef.current) niNewRef.current.value = NIRate;
				if (hoursPerDayNewRef.current) hoursPerDayNewRef.current.value = 0;
				setNewTotal(0);
			})
			.catch(() => {
				setIsLoading(false);
				showToast('error');
			});
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		setIsLoading(true);
		axios
			.delete(`/api/workers/workers_list/worker_card/rates_of_pay/${id}`)
			.then(() => {
				// Reload the rows
				loadTableData();

				showToast('deleted');
			})
			.catch(() => {
				setIsLoading(false);
				showToast('error');
			});
	};

	const handleUpdateNewTotal = () => {
		const amount = amountNewRef.current.value;

		switch (typeOfEngagement) {
			case EngagementTypes.DIRECTLY:
				setNewTotal(calculateTotal(Number(amount), Number(niNewRef.current.value)));
				break;
			case EngagementTypes.SELF:
				setNewTotal(Number(amount) * Number(hoursPerDayNewRef.current.value));
				break;
		}
	};

	const calculateTotal = (amount: number, niPct: number): number => {
		return amount + amount * (niPct / 100);
	};

	if (!payType) {
		return (
			<Alert severity='info'>
				<AlertTitle>Info</AlertTitle>
				Rates of pay utility will be available once a method for calculating payment has been
				selected
			</Alert>
		);
	}

	return (
		<>
			<h3>{tableTitle}</h3>
			<IonRow className='mt-3'>
				<IonCol size={'12'}>
					<h5 className='font-bold'>Rates of Pay</h5>
					{isLoading && <Loading overlay={true} />}
					<Table className={`table-sections ${styles.ratesTable} mt-3 mb-4`} size='small'>
						<TableHead>
							<TableRow>
								<TableCell>Date From</TableCell>
								<TableCell>Date To</TableCell>
								<TableCell align='right'>{amountTitle}</TableCell>
								{typeOfEngagement === EngagementTypes.DIRECTLY && (
									<TableCell align='right'>NI %</TableCell>
								)}
								{typeOfEngagement === EngagementTypes.SELF && (
									<TableCell align='right'>Hours per day</TableCell>
								)}
								<TableCell align='right'>Total</TableCell>
								<TableCell align='right'>&nbsp;</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(!tableData || tableData.length === 0) && (
								<TableRow>
									<TableCell align='center' colSpan={6}>
										There are currently no rates set
									</TableCell>
								</TableRow>
							)}
							{tableData &&
								tableData.length > 0 &&
								tableData.map((row: any, i: number) => {
									const rowDateFrom =
										String(row.date_from).indexOf('T') > -1
											? DateTime.fromISO(row.date_from).toFormat('dd/MM/yyyy')
											: DateTime.fromFormat(row.date_from, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');

									let rowDateTo = 'Present';
									if (row.date_to) {
										rowDateTo =
											String(row.date_to).indexOf('T') > -1
												? DateTime.fromISO(row.date_to).toFormat('dd/MM/yyyy')
												: DateTime.fromFormat(row.date_to, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
									}

									let rowTotal = 0;
									switch (typeOfEngagement) {
										case EngagementTypes.DIRECTLY:
											rowTotal = calculateTotal(Number(row.amount), Number(row.national_insurance));
											break;
										case EngagementTypes.SELF:
											switch (payType) {
												case 'hourly':
													rowTotal = Number(row.amount) * Number(row.hours_per_day);
													break;
												case 'daily':
													rowTotal = Number(row.amount);
													break;
											}
									}

									return (
										<TableRow key={i}>
											<TableCell>{rowDateFrom}</TableCell>
											<TableCell>{rowDateTo}</TableCell>
											<TableCell align='right'>
												{Number(row.amount).toLocaleString('en-GB', {
													style: 'currency',
													currency: 'GBP',
												})}
											</TableCell>
											{typeOfEngagement === EngagementTypes.DIRECTLY && (
												<TableCell align='right'>{row.national_insurance}</TableCell>
											)}
											{typeOfEngagement === EngagementTypes.SELF && (
												<TableCell align='right'>{row.hours_per_day}</TableCell>
											)}
											<TableCell align='right'>
												{rowTotal.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
											</TableCell>
											<TableCell align='right'>
												{permissionTo('delete') && i === 0 && (
													<IonButton
														onClick={() => handleDelete(row.id)}
														color='danger'
														disabled={isLoading}
														className='m-0'
													>
														<FontAwesomeIcon className='icon-delete' icon={faCircleXmark} />
													</IonButton>
												)}
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>

					<h5 className='font-bold'>Add New</h5>
					<Table className={`table-sections ${styles.ratesTable}`} size='small'>
						<TableHead>
							<TableRow>
								<TableCell>Date From</TableCell>
								<TableCell>&nbsp;</TableCell>
								<TableCell align='right'>{amountTitle}</TableCell>
								{typeOfEngagement === EngagementTypes.DIRECTLY && (
									<TableCell align='right'>NI %</TableCell>
								)}
								{typeOfEngagement === EngagementTypes.SELF && (
									<TableCell align='right'>Hours per day</TableCell>
								)}
								<TableCell align='right'>Total</TableCell>
								<TableCell>&nbsp;</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									<SingleDatePicker
										id='new__dateFrom'
										innerRef={dateFromNewRef}
										returnFormat='iso8601'
										useLabels={false}
										editMode={true}
										disabled={isLoading}
									/>
								</TableCell>
								<TableCell>-</TableCell>
								<TableCell align='right'>
									<IonRow class='prop-form'>
										<IonCol size='12'>
											<NumberField
												id='new__amount'
												title={'Amount'}
												editMode={true}
												noForm={true}
												useLabels={false}
												hideSpinner={true}
												value={0}
												onBlurCallback={handleUpdateNewTotal}
												fieldRef={amountNewRef}
												decimalLimit={2}
												disableEnterKey={true}
												disableNegatives={true}
												allowEmpty={false}
												disabled={isLoading}
												reloadComponent={reloadAmount}
											/>
										</IonCol>
									</IonRow>
								</TableCell>
								{typeOfEngagement === EngagementTypes.DIRECTLY && (
									<TableCell align='right'>
										<IonRow class='prop-form'>
											<IonCol size='12'>
												<IonInput
													type='number'
													id='new__ni'
													ref={niNewRef}
													className={`ion-number-input hide-number-spinner`}
													placeholder='Enter NI %'
													onBlur={handleUpdateNewTotal}
													disabled={isLoading}
												/>
											</IonCol>
										</IonRow>
									</TableCell>
								)}
								{typeOfEngagement === EngagementTypes.SELF && (
									<TableCell align='right'>
										<IonRow class='prop-form'>
											<IonCol size='12'>
												<IonInput
													type='number'
													id='new__hours_per_day'
													ref={hoursPerDayNewRef}
													className={`ion-number-input hide-number-spinner`}
													placeholder='Enter hours per day'
													onBlur={handleUpdateNewTotal}
													disabled={isLoading}
												/>
											</IonCol>
										</IonRow>
									</TableCell>
								)}
								<TableCell align='right'>
									{newTotal.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
								</TableCell>
								<TableCell align='right'>
									{permissionTo('create') && (
										<IonButton
											data-testid='btnAdd'
											color='success'
											disabled={isLoading}
											onClick={handleCreate}
										>
											<FontAwesomeIcon className='icon-add' icon={faCirclePlus} />
										</IonButton>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</IonCol>
			</IonRow>
		</>
	);
};

export default RatesOfPay;

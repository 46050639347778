import { MbscResource } from '@mobiscroll/react';
import { ResourceMode, SchedulingState } from '../scheduling-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Avatar from '../../../components/Calendars/Avatar';
import { Link } from 'react-router-dom';

export const Resource = (resource: MbscResource, state: SchedulingState) => {
	let returnOutput = null;
	switch (state.resourceMode) {
		case ResourceMode.WORKERS:
			returnOutput = (
				<div
					ref={resource.resourceRef}
					data-resource-id={resource.id}
					className={resource?.background ? 'highlighted-resource' : ''}
				>
					<Avatar
						data={resource}
						includeTitle={true}
						titleLink={`/workers/workers_list/worker_card/${resource.id}`}
						extraInfo={
							<>
								<p>{resource.description}</p>
								{resource.skills.length > 0 && <p>{resource.skills.join(', ')}</p>}
							</>
						}
					/>
				</div>
			);
			break;
		case ResourceMode.JOBS:
			returnOutput = (
				<div
					ref={resource.resourceRef}
					data-resource-id={resource.id}
					className={`d-flex w-100 timeline-resource ${
						resource?.background ? 'highlighted-resource' : ''
					}`}
				>
					<div className='tr-text'>
						<div>
							<FontAwesomeIcon className='job-icon' icon={faClipboard} />
							<Link className='prop-link' to={`/jobs/jobs_list/job_card/${resource.id}`}>
								{resource.name}
							</Link>
						</div>
						{resource.hasOwnProperty('manager') &&
							resource.manager !== null &&
							resource.manager.length > 0 && <p>Manager: {resource.manager}</p>}
					</div>
				</div>
			);
			break;
		case ResourceMode.SKILLS:
			returnOutput = (
				<div className='d-flex w-100 timeline-resource'>
					<div className='tr-text'>
						<div>
							{resource.hasOwnProperty('children') && Array.isArray(resource.children) && (
								<FontAwesomeIcon className='job-icon' icon={faClipboard} />
							)}

							{resource.isParent && (
								<Link className='prop-link' to={`/jobs/jobs_list/job_card/${resource.id}`}>
									{resource.name}
								</Link>
							)}

							{!resource.isParent && resource.name}
						</div>
						{!resource.hasOwnProperty('children') && (
							<p>{`Man hours: ${resource.scheduledHours} / ${resource.manHours}`}</p>
						)}
					</div>
				</div>
			);
			break;
	}

	return returnOutput;
};

export const ResourceFooter = (isLoading: boolean) => {
	return (
		<div className='resources-footer-cell'>
			<div className='flex-grow-1'>
				{isLoading && <FontAwesomeIcon icon={faSpinner} className='fa-pulse' />}
			</div>
			<div>Number of Available Workers:</div>
		</div>
	);
};

import TitleBar from '../../../components/TitleBar/TitleBar';
import './Invoices.scss';
import '../shared/estimates-invoices/styles.scss';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import Submenu from '../../../components/Menu/Submenu';
import InvoicesSubmenuArray from './arrays/InvoicesSubmenuArray';

const InvoicesList: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />

				<Submenu array={InvoicesSubmenuArray()} />
			</div>
		</>
	);
};

export default InvoicesList;

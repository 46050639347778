export const EMPTY_DATA_STR = '-';

export const headerRow = (type: 'main') => {
	let output: any = {};

	switch (type) {
		case 'main':
			output = {
				col_a: 'Worker',
				col_b: 'Job Skill',
				col_c: 'Hours',
				col_d: 'Cost £',
				col_e: 'Estimate',
				col_f: 'Hours',
				col_g: 'Cost £',
				col_h: '+/- £',
			};
			break;
	}

	output.cellClass = `header-row hr-${type}`;

	return output;
};

export const subHeaderRow = (style: 'alpha' | 'beta', text: string) => {
	let output: any = { col_a: text };

	output.cellClass = `sub-header-row shr-${style}`;

	return output;
};

export const dataRow = (type: 'main', data: any, isBeforeSubTotalRow?: boolean) => {
	let output: any = {};

	switch (type) {
		case 'main':
			output = {
				col_a: data?.worker_name,
				col_b: data?.job_skill,
				col_c: data?.hours,
				col_d: data?.cost,
				col_e: null,
				col_f: null,
				col_g: null,
				col_h: null,
				is_before_sub_total_row: isBeforeSubTotalRow,
			};
			break;
	}

	// Fill blanks
	Object.keys(output).forEach(function (key, index) {
		let item: any = output[key];
		output[key] = (!item && typeof item !== 'boolean') || item.length == 0 ? EMPTY_DATA_STR : item;
	});

	output.cellClass = `data-row dr-${type}`;

	return output;
};

export const subTotalRow = (textAppend: string, total: number, data: any) => {
	return {
		col_a: 'Total ' + textAppend,
		col_d: total,
		col_e: data.job_skill,
		col_f: Number(data.estimate_hours),
		col_g: Number(data.estimate_cost),
		col_h: Number(data.diff),
		cellClass: 'sub-total-row',
	};
};

export const totalRow = (total: number, estimateTotal: number, diffTotal: number) => {
	return {
		col_a: 'Total',
		col_d: total,
		col_g: estimateTotal,
		col_h: diffTotal,
		cellClass: 'total-row',
	};
};

export const spacerRow = () => {
	let output: any = {
		col_a: '',
		cellClass: 'spacer-row',
	};
	return output;
};

export const getCellClass = (params: any) => {
	const field: string = params.colDef.field;
	let cellClass: string = String(params.data.cellClass);

	switch (cellClass) {
		case 'data-row dr-main':
			if (params.data.is_before_sub_total_row === true) cellClass += ' is-before-sub-total-row';
			if (params.data.is_last_data_row === true) cellClass += ' is-last-data-row';
			break;
	}

	if (!['col_a', 'col_b', 'col_e'].includes(field)) cellClass += ' ag-right-aligned-cell';

	// Diff value classes
	if (['col_h'].includes(field) && !isNaN(Number(params.value))) {
		if (Number(params.value) >= 0) {
			cellClass += ' text-success';
		} else {
			cellClass += ' text-danger';
		}
	}

	return cellClass;
};

export const formatCell = (params: any, formatters?: any) => {
	const cellClass: string = String(params.data.cellClass);
	const field: string = params.colDef.field;
	let output: any = params.value;

	switch (cellClass) {
		case 'data-row dr-main':
		case 'sub-total-row':
		case 'total-row':
			if (['col_d', 'col_g', 'col_h'].includes(field) && formatters.currencyFormatter) {
				if (params.value !== EMPTY_DATA_STR) {
					output = formatters.currencyFormatter(params, false, true);
				}
			}

			if (['col_c', 'col_f'].includes(field) && formatters.hoursFormatter) {
				output = formatters.hoursFormatter(params.value);
			}
			break;
	}

	return output;
};

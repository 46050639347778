export function currencyFormatter(
	params: any,
	stripCommas: boolean = false,
	includeCurrenySymbol: boolean = false
): string {
	const theValue: number = !isNaN(Number(params.value)) ? Number(params.value) : 0;
	let options: any = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

	if (includeCurrenySymbol === true) {
		options.style = 'currency';
		options.currency = 'GBP';
	}

	let output = Number(theValue).toLocaleString('en-GB', options);

	if (stripCommas === true) {
		output = output.replace(/,/g, '');
	}

	return output;
}

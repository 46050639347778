import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonChip,
	IonFooter,
} from '@ionic/react';
import { ReactNode, Ref, useEffect, useRef, useState } from 'react';

import {
	faInbox,
	faSms,
} from '@fortawesome/free-solid-svg-icons';
import { faMobile } from '../../Icons/custom-icons.module';
import { swapReactions } from '../../../helpers/strings';

interface NotificationsModalProps {
	isOpen: boolean;
	onClose: Function;
	item?: any;
}

const NotificationDetailModal: React.FC<NotificationsModalProps> = (props) => {
	const ionRef: any = useRef<Ref<HTMLIonModalElement>>();
	const parentRef: any = useRef<Ref<HTMLIonContentElement>>();
	const [notificationTitle, setNotificationTitle] = useState<string>('');
	const [notificationBody, setNotificationBody] = useState<string>('');
	const [notificationPills, setNotificationPills] = useState<ReactNode|null>(null);

	const getEnd = (item: any) => {
		let items: Array<any> = [];
		if (item.responses) {
			Object.keys(item.responses).forEach((key: any) => {
				let icon: any;
				let name: any;
				switch (key) {
					case 'firebase':
						icon = faMobile;
						name = 'Push Notification';
						break;
					case 'vonage':
						icon = faSms;
						name = 'SMS';
						break;
					case 'mail':
						icon = faInbox;
						name = 'Email';
						break;
				}
				items.push({ icon, name });
			});
		}
		return (
			<span>
				{items.map((item, index) => (
					<IonChip key={index} style={{ fontSize: 10, marginLeft: 10 }}>
						<FontAwesomeIcon style={{marginRight: 6}} icon={item.icon} /> {item.name}
					</IonChip>
				))}
			</span>
		);
	};

	useEffect(() => {
		if (props.item !== null) {
			setNotificationTitle(props.item.title);
			setNotificationBody(swapReactions(props.item.body));
			setNotificationPills(getEnd(props.item));
		}
	}, [props.item]);

	return (
		<IonModal
			id='notification-details-modal'
			showBackdrop={true}
			ref={ionRef}
			style={{ '--width': '25vw', '--height': '35vh' }}
			isOpen={props.isOpen}
			onDidDismiss={() => props.onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{notificationTitle}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => ionRef.current.dismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent ref={parentRef}>
				<div>{notificationBody}</div>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					{notificationPills}
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default NotificationDetailModal;

export enum EngagementTypes {
	DIRECTLY = 'directly',
	SELF = 'self',
}

export enum HeatmapsUsageMode {
	TIMESHEET_DAILY = 'timesheet-daily',
	TIMESHEET_WEEKLY = 'timesheet-weekly',
}

export enum RoutemapsUsageMode {
	TIMESHEET_DAILY = 'timesheet-daily',
}

export enum EventReportUsageMode {
	TIMESHEET_DAILY = 'timesheet-daily',
}

export type Worker = WorkerRecord | null;

export type WorkerKeyDetails = {
	type_of_engagement: EngagementTypes | null;
};

export type WorkerPayrollDetails = {
	appears_on_organisation_chart: boolean | undefined;
	schedule_default_location: string | undefined;
};

export type WorkerUser = {
	id: string;
	first_name: string;
	last_name: string;
};

export type WorkerRecord = {
	key_details: WorkerKeyDetails;
	payroll_details: WorkerPayrollDetails;
	user: WorkerUser;
};

export type SubNavTabs = {
	workerTabs: string;
};

export interface WorkersState {
	worker: Worker;
	activeSubNavTabs: SubNavTabs;
}

import { useContext, useEffect, useRef, useState } from 'react';
import { IonRow, IonCol, IonButton, IonCard } from '@ionic/react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TitleBar from '../../../components/TitleBar/TitleBar';
import DataGrid from '../../../components/DataGrid/DataGrid';
import './Jobs.scss';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import ListFilter from './components/ListFilter';
import { Settings } from '../../../types/module-context';
import { moduleContext } from '../../../contexts/ModuleContext';
import { JobStatus } from './job-types';
import { enumFromStringValue, titleCase } from '../../../helpers/strings';
import { dateFormatter } from '../../../helpers/dateFormatter';

const JobsList: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const gridRef: any = useRef<any>();
	const [defaultFilterItem, setDefaultFilterItem] = useState<any>({
		label: 'In Progress',
		payload: { status: JobStatus.IN_PROGRESS },
	});
	const [data, setData] = useState<Array<any>>([]);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [settingsLoading, setSettingsLoading] = useState<boolean>(true);
	const [settings, setSettings] = useState<Settings>();
	const [tableTitle, setTableTitle] = useState<string>(defaultFilterItem.label);
	const [jobId, setJobId] = useState<string | null>(null);
	let history = useHistory();

	const [columns, setColumns] = useState<Array<any>>([
		{
			field: 'number',
			headerName: 'Job Number',
			sortable: true,
			width: 150,
		},
		{
			field: 'name',
			headerName: 'Job Name',
			sortable: true,
			flex: 1,
		},
		{
			field: 'status',
			headerName: 'Job Status',
			sortable: true,
			flex: 1,
		},
		{
			field: 'address',
			headerName: 'Address',
			sortable: true,
			flex: 1,
		},
		{
			field: 'postcode',
			headerName: 'Postcode',
			sortable: true,
			width: 100,
		},
		{
			field: 'client',
			headerName: 'Client',
			sortable: true,
			flex: 1,
		},
		{
			field: 'manager',
			headerName: 'Manager',
			sortable: true,
			flex: 1,
		},
	]);

	useEffect(() => {
		const queryStatus = urlParams.get('status');
		const queryJobStatus = enumFromStringValue(JobStatus, urlParams.get('status')!)!;

		// Remove the query parameter
		urlParams.delete('status');
		history.replace({
			search: urlParams.toString(),
		});

		if (queryStatus && queryJobStatus) {
			const updatedDefaultState = {
				payload: { status: queryJobStatus },
				label: titleCase(queryJobStatus),
			};
			setDefaultFilterItem((prevState: any) => ({
				...prevState,
				...updatedDefaultState,
			}));
			setTableTitle(titleCase(queryJobStatus));
			handleListFilterChange(updatedDefaultState.label, updatedDefaultState.payload);
		} else {
			handleListFilterChange(defaultFilterItem.label, defaultFilterItem.payload);
		}

		loadSettings();
	}, []);

	useEffect(() => {
		updateColumns(defaultFilterItem.payload.status);
	}, [gridReady, defaultFilterItem]);

	// Add the suspended columns if required, then show or hide them as directed
	function updateColumns(status: string) {
		if (status === JobStatus.SUSPENDED) {
			setColumns((prevState: any) => {
				let updatedColumns: Array<any> = prevState;

				if (
					updatedColumns.some(
						(col: any) =>
							col.field === 'work_suspended_date' || col.field === 'work_suspended_reason'
					) === false
				) {
					updatedColumns = [
						...prevState,
						{
							field: 'work_suspended_date',
							headerName: 'Suspended',
							sortable: true,
							flex: 1,
							valueFormatter: dateFormatter,
						},
						{
							field: 'work_suspended_reason',
							headerName: 'Suspended Reason',
							sortable: true,
							flex: 2,
						},
					];
				}

				return updatedColumns;
			});
		}

		if (gridRef.current && gridReady === true) {
			gridRef.current.setColumnsVisible(
				['work_suspended_date', 'work_suspended_reason'],
				status === JobStatus.SUSPENDED
			);
		}
	}

	const loadGridData = (payload: any) => {
		setGridLoading(true);
		setJobId(null);
		setData([]);

		axios
			.post('/api/jobs/jobs_list', payload)
			.then((res: any) => {
				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleListFilterChange = (label: string, payload: any) => {
		loadGridData(payload);
		setTableTitle(`Jobs - ${label}`);
		updateColumns(payload.status);
	};

	const loadSettings = () => {
		setSettingsLoading(true);

		moduleCtx
			.getSettings()
			.then((res: Settings) => {
				setSettings(res);
			})
			.finally(() => {
				setSettingsLoading(false);
			});
	};

	const handleRowClicked = (params: any) => {
		params.node.setSelected(true);
		setJobId(params.data.id);
	};

	const handleRowDoubleClicked = (params: any) => {
		handleRowClicked(params);
		handleEditJob();
	};

	const handleEditJob = () => {
		if (permissionTo('update', 'jobs.jobs_list.job_card')) {
			const path = `/jobs/jobs_list/job_card/${jobId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const triggerAddJob = () => {
		if (permissionTo('create', 'jobs.jobs_list.add_new')) {
			if (!settings?.job_numbering_start || Number(settings.job_numbering_start) <= 0) {
				showToast(
					'info',
					<>
						Please set your{' '}
						<Link className='toast-link' to='/utilities/jobs/numbering'>
							Numbering
						</Link>{' '}
						before adding a new Job
					</>
				);
				return;
			}

			const path = '/jobs/jobs_list/add_new';
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const triggerImportJobs = () => {
		if (permissionTo('create', 'jobs.jobs_list.import')) {
			const path = '/jobs/jobs_list/import';
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card filter-data-table full-height-card'>
					<DataGrid
						ref={gridRef}
						onGridReady={() => setGridReady(true)}
						title={tableTitle}
						cols={columns}
						data={data}
						rowClickedFunction={handleRowClicked}
						rowDoubleClickedFunction={handleRowDoubleClicked}
						rowCursorPointer={permissionTo('update')}
						showFooter={false}
						extraFooter={
							<IonRow className='data-table-buttons'>
								<IonCol size='8'>
									<ListFilter
										defaultSelected={defaultFilterItem.payload.status}
										onChange={handleListFilterChange}
									/>
								</IonCol>
								<IonCol size='4' className='text-right'>
									<IonButton
										color='primary'
										disabled={gridLoading}
										onClick={() => triggerImportJobs()}
									>
										Import XLSX
									</IonButton>
									<IonButton
										color='primary'
										disabled={jobId === null}
										onClick={() => handleEditJob()}
									>
										Edit Job
									</IonButton>
									<IonButton
										color='success'
										disabled={gridLoading || settingsLoading}
										onClick={() => triggerAddJob()}
									>
										Add New
									</IonButton>
								</IonCol>
							</IonRow>
						}
					/>
				</IonCard>
			</div>
		</>
	);
};

export default JobsList;

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { IonRow, IonCol, IonCard } from '@ionic/react';
import WorkingHoursAndBreaktimesIndex from '../../../Utilities/Workers/WorkingHoursAndBreaktimes/WorkingHoursAndBreaktimesIndex';
import Loading from '../../../../components/UI/Loading';
import { Alert, AlertTitle } from '@mui/material';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import WorkerControls from '../WorkerControls';

interface Props {
	nextFunction?: Function;
	prevFunction?: Function;
}

const WorkingHours: React.FC<Props> = (props: Props) => {
	const tabId = 'working_hours';
	const componentUID = 'utilities.working_hours_and_breaktimes';
	const permissionTo = usePermissionTo(componentUID);
	const moduleCtx = useContext<any>(moduleContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [worker, setWorker] = useState<any>({});
	const { workerId }: any = useParams();

	useEffect(() => {
		loadWorker(workerId);
	}, []);

	const loadWorker = (workerId: string) => {
		setIsLoading(true);

		moduleCtx
			.getWorker(workerId)
			.then((res: any) => {
				setWorker(res);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			<IonRow className='mb-3'>
				<IonCol size={'12'}>
					{!isLoading && !permissionTo('read') && (
						<IonCard className='table-card full-height-card'>
							<Alert severity='info'>
								<AlertTitle>Info</AlertTitle>
								Sorry, you do not have permission to use this item
							</Alert>
						</IonCard>
					)}

					{isLoading && permissionTo('read') && (
						<IonCard className='table-card full-height-card'>
							<Loading height={600} />
						</IonCard>
					)}

					{!isLoading && (!worker || !worker.key_details.role_id) && permissionTo('read') && (
						<IonCard className='table-card full-height-card'>
							<Alert severity='info'>
								<AlertTitle>Info</AlertTitle>
								Please first select a Security Setting for this worker
							</Alert>
						</IonCard>
					)}

					{!isLoading && worker && worker.key_details.role_id && permissionTo('read') && (
						<WorkingHoursAndBreaktimesIndex
							uid={componentUID}
							routeTitle=''
							permissionTo={permissionTo}
							usageMode='worker_tabs'
							workerId={workerId}
						/>
					)}
				</IonCol>
			</IonRow>

			<WorkerControls tabId={tabId} workerId={workerId} setIsLoading={setIsLoading} />
		</>
	);
};

export default WorkingHours;

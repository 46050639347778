import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { defaultCountries, usePhoneInput } from 'react-international-phone';

import 'react-international-phone/style.css';

import timezones from '../../helpers/timezones.json';
import { DateTime } from 'luxon';

interface PhoneProps {
	value: any;
}

export const PhoneLabel = forwardRef<any, PhoneProps>((props: PhoneProps, ref) => {
	const [originalValue, setOriginalValue] = useState<any>(props.value);
	const [selectedCountry, setSelectedCountry] = useState<any>({
		value: 'GB',
		label: 'United Kingdom',
	});

	const getCountryCode = (phoneNumber: string) => {
		let number = phoneNumber.split(' ')[0].replace('+', '');
		let localCountry = defaultCountries.find((item) => {
			return item[3] === number;
		});

		if (localCountry) {
			return localCountry[2];
		} else {
			const timezone: string = DateTime.now().toFormat('z');
			const localTimezones: any = timezones;
			const _country = localTimezones[timezone].c[0];
			let localCountry = defaultCountries.find((item) => {
				return item[2] === _country.toLowerCase();
			});
			if (localCountry) {
				return localCountry[2];
			} else {
				return 'gb';
			}
		}
	};

	const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
		defaultCountry: getCountryCode(originalValue),
		value: originalValue || '',
		countries: defaultCountries,
		onChange: (data) => {},
	});

	useImperativeHandle(ref, () => ({
		get value() {
			return originalValue;
		},
		set value(value: string) {
			setOriginalValue(value);
		},
	}));

	useEffect(() => {
		if (selectedCountry.value !== country.toUpperCase()) {
			let localCountry = defaultCountries.find((item) => {
				return item[2] === country;
			});
			if (localCountry) {
				setSelectedCountry({
					value: localCountry[2].toUpperCase(),
					label: localCountry[0],
				});
			}
		}
	}, [country]);

	useEffect(() => {
		setOriginalValue(props.value);
	}, [props.value]);


	return (
		<div>{phone}</div>
	);
});

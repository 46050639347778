import { useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColDef } from '@ag-grid-community/core';
import { IonAvatar } from '@ionic/react';
import {
	faCalendar,
	faClose,
	faExclamationCircle,
	faKey,
	faUmbrellaBeach,
	faVirus,
} from '@fortawesome/free-solid-svg-icons';

import DataGrid from '../../DataGrid/DataGrid';
import { isToday, isYesterday } from '../../../helpers/dates';
import NotificationDetailModal from '../NotificationsModal/NotificationDetailModal';
import { csrf } from '../../../helpers/csrf';
import axios from '../../../lib/axios';

import './Notifications.scss';
import { publish } from '../../../helpers/events';

interface Props {
	readOnly: boolean;
	userId?: string;
}

const Notifications: React.FC<Props> = (props: Props) => {
	const [dataSource, setDataSource] = useState<any>({
		url: '/api/notifications',
		method: 'POST',
		data: {},
	});
	const gridRef: any = useRef<any>();
	const [selected, setSelected] = useState<any|null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const colData: Array<ColDef> = [
		{
			headerName: 'Type',
			field: 'type',
			filter: true,
			width: 100,
			cellRenderer: (params: any) => {
				return (
					<div
						style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}
					>
						<IonAvatar style={{ maxHeight: '30px', maxWidth: '30px', marginRight: 6 }} slot='start'>
							{getAvatar(params.data)}
						</IonAvatar>
					</div>
				);
			},
		},
		{ headerName: 'Title', field: 'title', filter: true, flex: 2, cellRenderer: (params: any) => {
			if (params.data.read === false) {
					return (<strong>{params.data.title}</strong>);
			} else {
				return params.data.title;
			}
		}},
		{
			headerName: 'Date',
			field: 'created_at',
			filter: true,
			width: 175,
			cellRenderer: (params: any) => {
				return `${showDate(params.data.created_at)}`;
			},
		},
		
	];

	const showDate = (date: string) => {
		if (date === null || date === '' || date === undefined) {
			return '';
		} else {
			const dt = DateTime.fromISO(date);
			if (isToday(dt)) {
				return dt.toFormat('HH:mm');
			} else if (isYesterday(dt)) {
				return 'Yesterday at ' + dt.toFormat('HH:mm');
			} else {
				return dt.toLocaleString() + ' at ' + dt.toFormat('HH:mm');
			}
		}
	};

	useEffect(() => {
		setDataSource((prevValue: any) => {
			let newValue = { ...prevValue };
			if (props.userId !== undefined) {
				newValue.data['user_id'] = props.userId;
			}
			return newValue;
		});
	}, [props.userId]);

	const rowClickedFunction = (event: any) => {
		setSelected(event.data);
		setIsOpen(true);
		if (event.data.read === false) {
			markAsRead(event.data)
		}
	};

	const markAsRead = (notification: any) => {
		csrf().then(() => {
			axios
				.post('/api/notifications/read', {
					id: notification._id,
				})
				.then((data: any) => {
					notification.read = true;
					gridRef.current.updateRow(notification);
					publish('notification:badge', true);
				})
				.catch((error) => {
					console.error(error);
				});
		});
	};

	const getAvatar = (item: any) => {
		let icon: any = faClose;
		switch (item.type) {
			case '2fa':
				icon = faKey;
				break;
			case 'holiday':
				icon = faUmbrellaBeach;
				break;
			case 'requests':
				icon = faCalendar;
				break;
			case 'alarms':
				icon = faExclamationCircle;
				break;
			case 'absence':
				icon = faVirus;
				break;
		}
		return (
			<div className='icon-surround' style={{ width: '100%', height: '100%' }}>
				<FontAwesomeIcon icon={icon} />
			</div>
		);
	};

	return (
		<div className='flex-grow-container' style={{ height: '100%' }}>
			<div className='content'>
				<DataGrid
					ref={gridRef}
					title={''}
					cols={colData}
					dataSource={dataSource}
					rowClickedFunction={rowClickedFunction}
					paging={true}
					exportButton={true}
				/>
				<NotificationDetailModal isOpen={isOpen} item={selected} onClose={()=>{setIsOpen(false)}} />
			</div>
		</div>
	);
};

export default Notifications;

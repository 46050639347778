import {
	IonItem,
	IonButton,
	IonInput,
	IonLabel,
	IonFooter,
	IonRow,
	IonCol,
	useIonAlert,
} from '@ionic/react';
import { useEffect, useReducer, useState } from 'react';
import { csrf } from '../../../helpers/csrf';
import { listReducer } from '../../../helpers/listReducer';
import { sleep } from '../../../helpers/sleep';
import UserInterface from '../../../interfaces/Chat/UserInterface';
import axios from '../../../lib/axios';
import useConversation from '../../../utils/custom-hooks/Chat/useConversation';
import InfinteScroll from '../../InfiniteScroll/InfiniteScroll';
import TitleBar from '../../TitleBar/TitleBar';
import Loading from '../../UI/Loading';
import UserItem from '../UserItem/UserItem';
import './NewConversation.scss';

interface Props {
	onWillDismiss: Function;
	isVisible: boolean;
	conversationId?: string;
	conversationName?: string;
	participants?: Array<string>;
}

const NewConversation: React.FC<Props> = (props: Props) => {
	const [conversationName, setConversationName] = useState<string>('');
	const [saving, setSaving] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();
	const {save} = useConversation();

	const [userData, dispatchUserData] = useReducer(listReducer, {
		list: [],
		isShowList: true,
	});

	useEffect(() => {
		csrf().then(() => {
			axios
				.get('/api/conversations/workers')
				.then((data) => {
					data.data.forEach((item: UserInterface) => {
						item.selected = false;
						if (props.participants !== undefined) {
							if (props.participants.indexOf(item._id) > -1) {
								item.selected = true;
							}
						}
					});
					dispatchUserData({ type: 'replace', data: data.data });
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}, [props.isVisible]);

	useEffect(() => {
		let participants = userData.list
			.filter((item: UserInterface) => {
				return item.selected === true;
			})
			.map((item: UserInterface) => {
				return item._id;
			});

		if (participants.length < 2) {
			setConversationName('');
		} 
	}, [userData.list]);

	useEffect(() => {
		if (
			props.conversationName !== undefined &&
			props.participants !== undefined &&
			props.participants.length > 1
		) {
			setConversationName(props.conversationName);
		}
	}, [props.conversationName, props.participants]);

	const toggleUserConversation = (id: string) => {
		let sUsers = userData.list;
		const user = sUsers.find((user: UserInterface) => {
			return user._id === id;
		});
		if (user.selected === null || user.selected === undefined) {
			user.selected = false;
		}
		user.selected = !user.selected;
		dispatchUserData({ type: 'update', data: user });
	};

	const confirmConversation = async () => {
		let participants = userData.list
			.filter((item: UserInterface) => {
				return item.selected === true;
			})
			.map((item: UserInterface) => {
				return item._id;
			});

		if (participants.length > 1 && conversationName === '') {
			presentAlert({
				header: 'Group Name',
				message: 'A group name is required',
				buttons: ['OK'],
			});
			return;
		}

		setSaving(true);
		await sleep(1);
		save(
			participants,
			conversationName,
			(data: any) => {
				props.onWillDismiss(data, 'confirm');
			},
			props.conversationId
		);
	};

	return (
		<>
			{saving && (
				<Loading height="auto" width="100%" />
			)}
			{!saving && (
				<div className="new-convo">
		
					<TitleBar
						title="Add New Conversation"
						goBack={() => {
							props.onWillDismiss({});
						}}
					/>
					<div className="content">
						<IonItem
							hidden={
								userData.list
									.filter((item: UserInterface) => {
										return item.selected === true;
									})
									.map((item: UserInterface) => {
										return item._id;
									}).length <= 1
							}
						>
							<IonLabel position="floating">Enter a group name</IonLabel>
							<IonInput
								type="text"
								value={conversationName}
								onIonChange={(e) => setConversationName(e.detail.value!)}
							/>
						</IonItem>

						{userData.list.length > 0 && (
							<InfinteScroll
								items={userData.list}
								itemTemplate={UserItem}
								click={toggleUserConversation}
							></InfinteScroll>
						)}
					</div>
					<IonFooter>
						<IonRow>
							<IonCol size="6">
								<IonButton
									color="danger"
									strong={true}
									onClick={() => props.onWillDismiss({})}
								>
									Cancel
								</IonButton>
							</IonCol>
							<IonCol size="6" className="text-right">
								<IonButton
									color="success"
									strong={true}
									onClick={() => confirmConversation()}
								>
									{props.conversationId !== undefined && (
										<>Update Conversation</>
									)}
									{props.conversationId === undefined && (
										<>Create Conversation</>
									)}
								</IonButton>
							</IonCol>
						</IonRow>
					</IonFooter>
				</div>
			)}
		</>
	);
};

export default NewConversation;

import { useState, useEffect, useContext, useRef } from 'react';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { showToast } from '../../../../lib/toast';
import axios from '../../../../lib/axios';
import axiosMP from '../../../../lib/axiosMp';
import { AxiosProgressEvent } from 'axios';
import useKeyDetailsArray from '../arrays/keyDetailsArray';
import Form from '../../../../components/Forms/Form';
import Loading from '../../../../components/UI/Loading';
import { db2Form, processForm } from '../../../../api/forms';
import { toast } from 'react-toastify';
import AddJobSkillModal from '../../../Utilities/Workers/modals/AddJobSkillModal';
import JobSkillsOptions from '../../../../utils/autocomplete/JobSkillsOptions';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import WorkerControls from '../WorkerControls';
import { WorkersContext } from '../WorkersProvider';
import { ActionType } from '../actions';
import { EngagementTypes } from '../workers-types';

interface Props {
	newWorker?: any;
	nextFunction?: Function;
	saveForLaterFunction?: Function;
	permissionTo: Function;
}

const KeyDetails: React.FC<Props> = (props: Props) => {
	const tabId = 'key_details';
	const { state, dispatch } = useContext(WorkersContext);
	const moduleCtx = useContext<any>(moduleContext);
	const toastID = useRef<any>(null);
	const [items, setItems] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [rolesLoading, setRolesLoading] = useState<boolean>(true);
	const [roles, setRoles] = useState<Array<any>>([]);
	const [jobSkillsLoading, setJobSkillsLoading] = useState<boolean>(true);
	const [jobSkills, setJobSkills] = useState<Array<any>>([]);
	const [typesOfEngagementLoading, setTypesOfEngagementLoading] = useState<boolean>(true);
	const [typesOfEngagement, setTypesOfEngagement] = useState<Array<any>>([]);
	const [staffPhoto, setStaffPhoto] = useState<Array<any>>([]);
	const [staffPhotoURL, setStaffPhotoURL] = useState<HTMLImageElement | null>(null);
	const { workerId }: any = useParams();
	const endpoint = '/api/workers/workers_list/worker_card';
	const endpointId = workerId;

	// Modals
	const [addJobSkillModal, setAddJobSkillModal] = useState<{ isOpen: boolean }>({ isOpen: false });

	// Load the form array with refs to help with mandatory field values on-screen
	// (as opposed to using the hidden undo logic values)
	const formDataArray = useKeyDetailsArray(props.newWorker);

	// Permissions
	const permissionToJobSkills = usePermissionTo('utilities.job_skills');

	useEffect(() => {
		setIsLoading(true);
		loadRoles();
		loadJobSkills();
		loadTypesOfEngagement();
	}, []);

	useEffect(() => {
		if (
			rolesLoading === false &&
			jobSkillsLoading === false &&
			typesOfEngagementLoading === false
		) {
			loadForm();
			setIsLoading(false);
		}
	}, [rolesLoading, jobSkillsLoading, typesOfEngagementLoading]);

	const loadRoles = () => {
		setRolesLoading(true);

		moduleCtx
			.getSecuritySettingsTemplatesOptions(workerId)
			.then((res: any) => {
				setRoles(res);
			})
			.finally(() => {
				setRolesLoading(false);
			});
	};

	const loadJobSkills = () => {
		setJobSkillsLoading(true);

		moduleCtx
			.getJobSkillsOptions()
			.then((res: any) => {
				setJobSkills(res);
			})
			.finally(() => {
				setJobSkillsLoading(false);
			});
	};

	const loadTypesOfEngagement = () => {
		setTypesOfEngagementLoading(true);

		moduleCtx
			.getWorkerTypesOfEngagementOptions()
			.then((res: any) => {
				setTypesOfEngagement(res);
			})
			.finally(() => {
				setTypesOfEngagementLoading(false);
			});
	};

	const loadForm = () => {
		let formData: any = formDataArray.keyDetails;

		// Form setup
		formData.map((fd: any) => {
			if (fd.id === 'role_id') fd.values = roles;
			if (fd.id === 'job_skills') {
				// Set the values immediately
				fd.values = jobSkills;
				fd.loadOptions = JobSkillsOptions;

				if (permissionToJobSkills(['read', 'create'])) {
					fd.handleAddNew = () => {
						setAddJobSkillModal({ isOpen: true });
					};
				}
			}
			if (fd.id === 'type_of_engagement') fd.values = typesOfEngagement;
			return fd;
		});

		if (props.newWorker === true) {
			setItems(formData);
		} else {
			// Get the existing worker's data
			setIsLoading(true);
			axios
				.get(`${endpoint}/${endpointId}`)
				.then((res) => res.data)
				.then((dbData) => {
					formData = db2Form(formData, dbData);
					setItems(formData);

					// Set the staff photo
					if (dbData.key_details.worker_photo) {
						let newImage = new Image();
						newImage.src = `${process.env.REACT_APP_API_URL}/api/files/${dbData.key_details.worker_photo}`;
						setStaffPhotoURL(newImage);
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	const reloadJobSkillsOptions = () => {
		items.some((item: any) => {
			if (item.id === 'job_skills') {
				// Trigger options reload
				if (item.ref.current) item.ref.current.clearValue();
			}

			// Break loop
			return item.id === 'job_skills';
		});
	};

	const handleFormSuccess = (res: any) => {
		// Get success values
		const typeOfEngagementRef: any = res.data.filter(
			(item: any) => item.id === 'type_of_engagement'
		)[0].ref;
		const typeOfEngagement: EngagementTypes = typeOfEngagementRef.current.getValue()[0].value;

		const firstName: EngagementTypes = res.data.filter((item: any) => item.id === 'first_name')[0]
			.value;

		const lastName: EngagementTypes = res.data.filter((item: any) => item.id === 'last_name')[0]
			.value;

		// Build the payload
		let payload: any = {
			key_details: {
				...state.worker?.key_details,
				type_of_engagement: typeOfEngagement,
			},
			user: { ...state.worker?.user, first_name: firstName, last_name: lastName },
		};

		// Update the state
		dispatch({ type: ActionType.UPDATE_WORKER, payload });
	};

	// Staff photograph
	useEffect(() => {
		if (staffPhoto.length > 0) {
			let newImage = new Image();
			newImage.src = URL.createObjectURL(staffPhoto[0]);
			newImage.src = newImage.src.toString();
			setStaffPhotoURL(newImage);
		}
	}, [staffPhoto]);

	const triggerUpload = () => {
		(document.getElementById('staff-image') as HTMLElement).click();
	};

	const handleUpdateImage = (e: any) => {
		const file = e.target.files;
		setStaffPhoto(file);

		// Save the photo if this is a worker
		if (props.newWorker !== true) {
			// Send the full form back to the server with the photo
			const fd: any = processForm(items);
			fd.worker_photo = file[0];

			// Reset
			toastID.current = null;

			setIsLoading(true);

			axiosMP
				.post(`${endpoint}/${endpointId}`, fd, {
					onUploadProgress: (p: AxiosProgressEvent) => {
						const progress = p.loaded / (p.total ?? 0);

						if (toastID.current === null) {
							toastID.current = toast('Uploading image...', { type: toast.TYPE.INFO });
						} else {
							toast.update(toastID.current, {
								progress,
								render: 'Uploading image (' + Math.floor(progress * 100) + '%)...',
							});
						}
					},
				})
				.then((res: any) => {
					showToast('success');
				})
				.catch((err: any) => {
					showToast('error');
					console.error(err);
				})
				.finally(() => {
					toast.dismiss(toastID.current);
					setIsLoading(false);
				});
		}
	};

	return (
		<div style={{ minHeight: '1000px' }}>
			{isLoading && <Loading height={600} />}
			<IonRow>
				<IonCol size={'10'}>
					{!isLoading && items && items.length > 0 && (
						<Form
							method='POST'
							array={items}
							permissionTo={props.permissionTo}
							forceEdit={props.permissionTo('update')}
							noButton={true}
							formSkipServerUpdate={props.newWorker}
							onChangeCallback={handleFormSuccess}
							endpoint={endpoint}
							endpointID={endpointId}
						/>
					)}
				</IonCol>
				<IonCol size={'2'} className='p-3'>
					{!isLoading && items && items.length > 0 && (
						<>
							<h4 className='text-center mt-3'>Photograph</h4>
							{staffPhotoURL ? (
								<img
									src={staffPhotoURL && staffPhotoURL.src}
									className='staff-image'
									alt='Worker photograph'
								/>
							) : (
								<div className='staff-image'>
									<p>
										Recommended image size 600 x 800 px (<b>jpg</b> or <b>png</b>)
									</p>
								</div>
							)}

							{props.permissionTo('create') && (
								<IonButton
									color='primary'
									size='small'
									className='upload-button'
									onClick={triggerUpload}
								>
									<input
										hidden
										id='staff-image'
										type='file'
										accept='image/png, image/jpeg'
										onChange={handleUpdateImage}
									/>
									<FontAwesomeIcon icon={faImage} className='button-icon' />
									Upload Image
								</IonButton>
							)}
						</>
					)}
				</IonCol>
			</IonRow>

			{props.newWorker === true ? (
				<IonRow>
					{!isLoading && items && items.length > 0 && props.permissionTo('create') && (
						<IonCol size={'12'} className='text-right'>
							<IonButton
								className='mr-3'
								color='success'
								onClick={(e) => {
									if (props.saveForLaterFunction) {
										props.saveForLaterFunction(e, items, staffPhoto);
									}
								}}
							>
								Save for Later
							</IonButton>
							{props.permissionTo('update', 'workers.workers_list.worker_card') && (
								<IonButton
									color='primary'
									onClick={(e) => {
										if (props.nextFunction) {
											props.nextFunction(e, items, staffPhoto);
										}
									}}
								>
									Next Step
								</IonButton>
							)}
						</IonCol>
					)}
				</IonRow>
			) : (
				<>
					{!isLoading && items && items.length > 0 && (
						<WorkerControls tabId={tabId} workerId={workerId} setIsLoading={setIsLoading} />
					)}
				</>
			)}

			<AddJobSkillModal
				isOpen={addJobSkillModal.isOpen}
				onClose={() => {
					setAddJobSkillModal({ isOpen: false });
				}}
				onSuccess={reloadJobSkillsOptions}
			/>
		</div>
	);
};

export default KeyDetails;

import { useContext, useEffect, useState } from 'react';
import { IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../../lib/toast';
import { toast } from 'react-toastify';
import { moduleContext } from '../../../../contexts/ModuleContext';
import Loading from '../../../../components/UI/Loading';
import AddTeamMemberModal from './modals/AddTeamMemberModal';

interface Props {
	permissionTo: Function;
}

const WorkerTeamIndex: React.FC<Props> = (props: Props) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const [workersOptions, setWorkersOptions] = useState<Array<any>>([]);
	const [data, setData] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const { workerId }: any = useParams();
	const [presentAlert] = useIonAlert();

	// Modals
	const [addTeamMemberModal, setAddTeamMemberModal] = useState<any>({
		isOpen: false,
		modalData: null,
	});

	useEffect(() => {
		loadWorkers();
	}, []);

	useEffect(() => {
		if (!workersLoading) loadGridData();
	}, [workersLoading]);

	const colData: Array<any> = [
		{
			headerName: 'Team Member',
			field: 'name',
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 90,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					props.permissionTo('delete') &&
					params.data.type !== 'automatic' && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const loadWorkers = async () => {
		setWorkersLoading(true);
		setData([]);

		await moduleCtx.getWorkers().then((res: any) => {
			const result = res.map((r: any) => {
				return {
					label: r.name,
					value: r.id,
				};
			});
			setWorkersOptions(result);
		});

		setWorkersLoading(false);
	};

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		axios
			.post('/api/workers/workers_list/worker_card/my_team', { worker_id: workerId })
			.then((res: any) => {
				const team: any = res.data;
				updateTeamSelect(team);
				setData(team);
				setGridLoading(false);
			});
	};

	const updateTeamSelect = (team: Array<any>) => {
		// Update existing team members in the dropdown (e.g. to avoid multi-adding the same member)
		setWorkersOptions((prevState: any) => {
			return prevState.filter((option: any) => team.every((t) => t.worker_id !== option.value));
		});
	};

	const handleAddTeamMember = () => {
		setAddTeamMemberModal({
			isOpen: true,
			modalData: {
				workersOptions,
				workerId,
			},
		});
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Remove Team Member',
			message: 'Are you sure you want to remove this team member?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');
		setIsLoading(true);

		axios
			.delete(`/api/workers/workers_list/worker_card/my_team/${id}`)
			.then(() => {
				// Reload the workers and grid
				loadWorkers();

				showToast('removed', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const addTeamMemberModalOnClose = () => {
		setAddTeamMemberModal({ ...addTeamMemberModal, isOpen: false });
	};

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			<DataGrid
				title={'My Team'}
				cols={colData}
				data={data}
				showFooter={false}
				extraFooter={
					<IonRow>
						<IonCol size={'10'}></IonCol>
						<IonCol size={'2'} className='px-0 py-0 text-right'>
							{props.permissionTo('update') && (
								<IonButton
									color='success'
									onClick={handleAddTeamMember}
									disabled={workersLoading || gridLoading}
								>
									Add Team Member
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				}
			/>

			<AddTeamMemberModal
				isOpen={addTeamMemberModal.isOpen}
				modalData={addTeamMemberModal.modalData}
				onClose={addTeamMemberModalOnClose}
				permissionTo={props.permissionTo}
				loadWorkers={loadWorkers}
			/>
		</>
	);
};

export default WorkerTeamIndex;

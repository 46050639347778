import { useContext } from 'react';
import { IonGrid, IonRow, IonCol, IonInput } from '@ionic/react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { JobSkillsOptions } from '../../../../interfaces/Utilities/JobSkills';
import { CatalogueItem } from '../../../../interfaces/Utilities/CatalogueItem';
import { InfoBox } from '../../../../components/Forms/FormFields';
import { authContext } from '../../../../contexts/AuthContext';

interface Props {
	jobSkillsOptions: JobSkillsOptions[];
	vatRateOptions: Array<any>[];
	formState: CatalogueItem;
	isNew: boolean;
	handleEntry: Function;
}

const ItemModalForm: React.FC<Props> = ({
	jobSkillsOptions,
	vatRateOptions,
	formState,
	isNew,
	handleEntry,
}) => {
	const authCtx: any = useContext(authContext);

	return (
		<IonGrid>
			<IonRow className='title-row'>
				<IonCol size='4'>Code</IonCol>
				<IonCol size='8'>Name</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size='4'>
					{isNew && (
						<IonInput
							className='ion-propeller'
							placeholder='Enter a code...'
							value={formState.code}
							onInput={(e: any) => handleEntry('code', e)}
						/>
					)}
					{!isNew && (
						<InfoBox
							value={formState.code}
							useLabels={false}
							showBackground={true}
							showBorder={true}
							style={{ marginTop: '0px', height: '34px' }}
						/>
					)}
				</IonCol>
				<IonCol size='8'>
					<IonInput
						className='ion-propeller'
						placeholder='Enter a name...'
						value={formState.name}
						onInput={(e: any) => handleEntry('name', e)}
					/>
				</IonCol>
			</IonRow>
			<IonRow className='title-row'>
				<IonCol size='4'>Sale Price ({authCtx.tenant.currency_symbol})</IonCol>
				<IonCol size='4'>Work Type</IonCol>
				<IonCol size='4'>Tax Rate</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size='4'>
					<IonInput
						type='number'
						className='ion-propeller ion-number-input hide-number-spinner'
						placeholder='Enter a sale price...'
						value={formState.price}
						onInput={(e: any) => handleEntry('price', e)}
					/>
				</IonCol>
				<IonCol size='4'>
					<SelectStyled
						isSearchable={true}
						options={jobSkillsOptions}
						placeholder='Select or type to search...'
						value={formState.job_skill_id}
						onChange={(e: any) => handleEntry('job_skill_id', e)}
					/>
				</IonCol>
				<IonCol size='4'>
					<SelectStyled
						isSearchable={false}
						options={vatRateOptions}
						placeholder='Select...'
						value={formState.tax_rate_id}
						onChange={(e: any) => handleEntry('tax_rate_id', e)}
					/>
				</IonCol>
			</IonRow>
			<IonRow className='title-row'>
				<IonCol size='12'>Description</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size='12'>
					<IonInput
						className='ion-propeller'
						placeholder='Enter a description...'
						value={formState.description}
						onInput={(e: any) => handleEntry('description', e)}
					/>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default ItemModalForm;

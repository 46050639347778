import { useContext } from 'react';
import { authContext } from '../../contexts/AuthContext';
import { getPermissionTo } from '../../lib/functions';

// Build the permissionTo function by passing in the setup arguments first
const usePermissionTo = (restUID: string = '') => {
	const authCtx = useContext<any>(authContext);

	// Return a function that can determine permissions to perform actions
	return (actions: string | Array<any> = '', uid: string = '') => {
		return getPermissionTo(actions, uid, authCtx, restUID);
	};
};

export default usePermissionTo;

import { DateTime } from 'luxon';
import { createElement, useEffect, useReducer, useState } from 'react';

import { listReducer } from '../../../helpers/listReducer';
import RouteItem from './RouteItem';

import './Routes.scss';

interface Props {
	date: DateTime;
	routeData: Array<any>;
	onClick: Function;
	height: number;
}

const Routes: React.FC<Props> = (props: Props) => {
	const [date, setDate] = useState<DateTime | undefined>(undefined);
	const [routeData, dispatchRouteData] = useReducer(listReducer, {
		list: [],
		originalList: [],
		isShowList: true,
	});


	useEffect(() => {
		setDate(props.date);
	}, [props.date]);

	useEffect(() => {
		dispatchRouteData({
			type: 'replace',
			data: props.routeData,
		});
	}, [props.routeData]);

	return (
		<div className='route-container'>
			<div className='route-title'>
				<h2 style={{ margin: 0 }}>{date ? date.toLocaleString() : ''}</h2>
			</div>
			<div className='route-content'>
				{routeData.list.map((item: any, index: number) => {
					return createElement(RouteItem, {
						item: item,
						index: index,
						key: index,
						click: () => {
							props.onClick(item);
						},
					});
				})}
			</div>
		</div>
	);
};

export default Routes;

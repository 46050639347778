import { Alert, AlertTitle } from '@mui/material';

const GeneralError = () => {
	return (
		<div className='prop-general-error'>
			<Alert severity='error'>
				<AlertTitle>Error</AlertTitle>
				Sorry, there was an error processing your request. Please try again later.
			</Alert>
		</div>
	);
};

export default GeneralError;

import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { IonCol, IonLabel, IonRow } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { defaultCountries, usePhoneInput, validatePhone } from 'react-international-phone';

import 'react-international-phone/style.css';

import { validateField } from './FormValidation';
import SelectStyled from '../UI/SelectStyled';
import FlagIcons from '../Icons/FlagIcons';
import timezones from './../../helpers/timezones.json';
import { DateTime } from 'luxon';

interface PhoneProps {
	title?: string;
	value: any;
	editMode: boolean;
	useLabels: boolean;
	handleUpdateValue?: Function;
	disabled?: boolean;
	isMandatory?: boolean;
	noForm?: boolean;
}

export const PhoneField = forwardRef<any, PhoneProps>((props: PhoneProps, ref) => {
	const [originalValue, setOriginalValue] = useState<any>(props.value);
	const [selectedCountry, setSelectedCountry] = useState<any>({
		value: 'GB',
		label: 'United Kingdom',
	});
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);

	const getCountryCode = (phoneNumber: string) => {
		// Handle empty
		if (!phoneNumber || phoneNumber.length === 0) return 'gb';

		let number = phoneNumber.split(' ')[0].replace('+', '');
		let localCountry = defaultCountries.find((item) => {
			return item[3] === number;
		});

		if (localCountry) {
			return localCountry[2];
		} else {
			const timezone: string = DateTime.now().toFormat('z');
			const localTimezones: any = timezones;
			const _country = localTimezones[timezone].c[0];
			let localCountry = defaultCountries.find((item) => {
				return item[2] === _country.toLowerCase();
			});
			if (localCountry) {
				return localCountry[2];
			} else {
				return 'gb';
			}
		}
	};

	const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
		defaultCountry: getCountryCode(originalValue),
		value: originalValue || '',
		countries: defaultCountries,
		onChange: (data) => {},
	});

	useImperativeHandle(ref, () => ({
		get value() {
			return originalValue;
		},
		set value(value: string) {
			setOriginalValue(value);
		},
	}));

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	useEffect(() => {
		if (selectedCountry.value !== country.toUpperCase()) {
			let localCountry = defaultCountries.find((item) => {
				return item[2] === country;
			});
			if (localCountry) {
				setSelectedCountry({
					value: localCountry[2].toUpperCase(),
					label: localCountry[0],
				});
			}
		}
	}, [country]);

	useEffect(() => {
		setOriginalValue(props.value);
	}, [props.value]);

	const handleFieldBlur = (e: any) => {
		let curVal = e.target.value;
		let change = checkTempValue(curVal);
		let phoneValid = validatePhone(phone, { country: country });
		let valid = validateField(e.target, phoneValid.isValid, true);

		if (props.noForm === true && change === true) {
			setOriginalValue(curVal);
			return;
		}

		if (valid) {
			if (change === true && props.handleUpdateValue) {
				setUndoVal(originalValue);
				setOriginalValue(formatPhone(curVal));
				setShowUndo(true);
				setTimeout(() => {
					if (props.handleUpdateValue) {
						props.handleUpdateValue(formatPhone(curVal));
					}
				}, 100);
			}
		}
	};

	const formatPhone = (phone: string) => {
		if (!phone) phone = '';
		return phone.replace(/ /g, '').replace('+', '');
	};

	const checkTempValue = (val: any) => {
		if (formatPhone(val) === formatPhone(originalValue)) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateValue) {
			setOriginalValue(undoVal);
			setUndoVal(null);
			setShowUndo(false);
			setTimeout(() => {
				if (props.handleUpdateValue) {
					props.handleUpdateValue(undoVal);
				}
			}, 100);
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					{showUndo && (
						<FontAwesomeIcon
							icon={faRotateLeft}
							className='icon-primary undo-icon'
							onClick={() => onUndoClick()}
						/>
					)}
					<div style={{ width: '120px', marginRight: 8 }}>
						<SelectStyled
							isSearchable={true}
							onChange={(e: any) => {
								setCountry(e.value.toLowerCase());
								setSelectedCountry(e);
							}}
							formatOptionLabel={(country: any) => (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<FlagIcons height={'12px'} countryCode={country.value} />
									<span style={{ marginLeft: 6 }}>{country.value}</span>
								</div>
							)}
							value={selectedCountry}
							options={defaultCountries.map((country: any, i: number) => {
								return {
									value: country[2].toUpperCase(),
									label: country[0],
								};
							})}
						/>
					</div>

					<div style={{ flex: 1 }}>
						<div className='fake-ion-input'>
							<input
								className='native-input sc-ion-input-md'
								name={props.title}
								placeholder={'Enter here'}
								value={phone}
								type='tel'
								ref={inputRef}
								onBlur={(e) => handleFieldBlur(e)}
								onChange={handlePhoneValueChange}
								disabled={props.disabled}
							/>
						</div>
					</div>
				</div>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'9'}>
				<p>{props.value || ''}</p>
			</IonCol>
		</IonRow>
	);
});

import { useLoadScript } from '@react-google-maps/api';

const libraries: ('places' | 'visualization' | 'drawing' | 'geometry' | 'localContext')[] = [
	'places',
	'visualization',
	'drawing',
];

const useGoogleMapsHandler = () => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}` ?? '',
		libraries: libraries,
	});

	return {
		isLoaded,
	};
};

export default useGoogleMapsHandler;

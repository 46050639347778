import { useContext, useEffect, useState } from 'react';
import {
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonItem,
	IonLabel,
	IonList,
	IonPopover,
	IonRow,
	useIonAlert,
} from '@ionic/react';
import { showToast } from '../../../../lib/toast';
import { useHistory } from 'react-router-dom';
import { EstimateContext } from '../EstimatesProvider';
import axios from '../../../../lib/axios';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import { ActionType } from '../actions';
import { EstimateItem } from '../estimate-types';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import Loading from '../../../../components/UI/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCircleCheck,
	faCircleChevronUp,
	faCirclePause,
	faCirclePlay,
	faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import ResourcesModal from '../modals/ResourcesModal';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	estimateId?: string;
	handleUploadsModalOpen: Function;
}

const EstimateFooter: React.FC<Props> = ({
	permissionTo,
	isNew,
	estimateId,
	handleUploadsModalOpen,
}) => {
	const { state, dispatch } = useContext(EstimateContext);
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const [taxRateIdCount, setTaxRateIdCount] = useState(0);
	const [presentAlert] = useIonAlert();
	let history = useHistory();

	// Modals
	const [resourcesModal, setResourcesModal] = useState<{ isOpen: boolean }>({ isOpen: false });

	useEffect(() => {
		// Load the terms and conditions
		axios.get('/api/utilities/styles/terms_and_conditions/latest').then((res: any) => {
			if (res.data && res.data.content !== null) {
				// Convert back to an editor state object
				const convertedState = convertFromRaw(JSON.parse(res.data.content));
				const editorValue = EditorState.createWithContent(convertedState);

				// Set the editor's content
				setEditorState(editorValue);
			}
		});
	}, []);

	// Check all the tax rate dropdowns have been selected
	useEffect(() => {
		setTaxRateIdCount(state.items.filter((item: any) => item.taxRateId.length > 0).length);
	}, [state]);

	// Check if the form is ready to submit
	let canSubmit = false;
	if (
		state.items.length > 0 &&
		state.estimateData?.jobId &&
		(taxRateIdCount === state.items.length || state.estimateData.vatRegistered === false)
	) {
		canSubmit = true;
	}

	const handleUploadFiles = () => {
		if (permissionTo('update')) {
			handleUploadsModalOpen();
		} else {
			showToast('permission');
		}
	};

	const handleSaveEstimate = () => {
		if (permissionTo('create')) {
			if (!mandatoryFieldsCheckOK()) return false;

			storeEstimate('save');
		} else {
			showToast('permission');
		}
	};

	const handleSendEstimate = () => {
		if (permissionTo('create')) {
			if (!mandatoryFieldsCheckOK()) return false;

			presentAlert({
				header: 'Send Estimate',
				message: 'Are you sure you want to send this estimate?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							storeEstimate('send');
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleReIssueEstimate = () => {
		if (permissionTo('update')) {
			if (!mandatoryFieldsCheckOK()) return false;

			presentAlert({
				header: 'Re-issue Estimate',
				message: 'Are you sure you want to re-issue this estimate?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							storeEstimate('reissue');
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const storeEstimate = (action: string) => {
		let url: string = '/api/jobs/estimates_list/estimates_card';
		if (
			action === 'reissue' ||
			(state.estimateData?.status && state.estimateData?.status.toLowerCase() === 'draft')
		) {
			url = `/api/jobs/estimates_list/estimates_card/${estimateId}`;
		}

		dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

		let payload: any = {
			job_id: state.estimateData?.jobId,
			number: state.estimateData?.number,
			issue_date: state.estimateData?.issueDate,
			expiry_date: state.estimateData?.expiryDate,
			reference: state.estimateData?.reference,
			title: state.estimateData?.title ? state.estimateData.title.trim() : null,
			summary: state.estimateData?.summary ? state.estimateData.summary.trim() : null,
			tax_exclusive: state.estimateData?.amountsAre[0].value === 'exclusive',
			items: state.items.map((item: EstimateItem) => {
				return {
					id: item.hasOwnProperty('id') ? item.id : '0',
					item: item.item,
					description: item.description,
					quantity: Number(item.quantity),
					price: Number(item.price),
					discount: Number(item.discount),
					tax_rate_id: item.taxRateId,
				};
			}),
		};

		// Add the status depending on the store action
		if (action !== 'save') payload.status = 'issued';

		axios
			.put(url, payload)
			.then(() => {
				switch (action) {
					case 'save':
						showToast('success', 'Estimate successfully saved');
						break;
					case 'issue':
						showToast('success', 'Estimate successfully sent');
						break;
					case 'reissue':
						showToast('success', 'Estimate successfully re-issued');
						break;
				}

				history.push('/jobs/estimates_list');
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
			});
	};

	const handleMarkEstimate = (status: string) => {
		if (permissionTo('update')) {
			presentAlert({
				header: `Mark estimate ${status}`,
				message: `Are you sure you want mark this estimate as ${status}?`,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleMarkEstimateGo(status);
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleMarkEstimateGo = (status: string) => {
		const payload: any = {
			status: status,
		};

		dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

		axios
			.put(`/api/jobs/estimates_list/estimates_card/${estimateId}/status`, payload)
			.then(() => {
				showToast('success', `Estimate successfully marked as ${status}`);
				history.push('/jobs/estimates_list');
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
			});
	};

	const handleJobTargetsAndSkills = () => {
		setResourcesModal((prevState: any) => ({ ...prevState, isOpen: true }));
	};

	const mandatoryFieldsCheckOK = () => {
		if (!state.estimateData?.amountsAre) {
			showToast('error', "Please select a tax status from the 'Amounts Are' field");
			return false;
		}

		if (!state.estimateData?.issueDate || !state.estimateData.expiryDate) {
			showToast('error', 'Please ensure all dates are selected');
			return false;
		}

		return true;
	};

	// Mark estimate pop-over items
	let markEstimateItems: Array<any> = [];
	if (state.estimateData?.status) {
		if (state.estimateData.status.toLowerCase() === 'issued') {
			markEstimateItems.push(
				<IonItem
					className='btn-mark-draft'
					button={true}
					detail={false}
					onClick={() => handleMarkEstimate('draft')}
				>
					<FontAwesomeIcon icon={faCirclePause} />
					Mark as Draft
				</IonItem>
			);
		}

		if (state.estimateData.status.toLowerCase() !== 'issued') {
			markEstimateItems.push(
				<IonItem
					className='btn-mark-issued'
					button={true}
					detail={false}
					onClick={() => handleMarkEstimate('issued')}
				>
					<FontAwesomeIcon icon={faCirclePlay} />
					Mark as Issued
				</IonItem>
			);
		}

		if (state.estimateData.status.toLowerCase() !== 'approved') {
			markEstimateItems.push(
				<IonItem
					className='btn-mark-approved'
					button={true}
					detail={false}
					onClick={() => handleMarkEstimate('approved')}
				>
					<FontAwesomeIcon icon={faCircleCheck} />
					Mark as Approved
				</IonItem>
			);
		}

		if (state.estimateData.status.toLowerCase() !== 'refused') {
			markEstimateItems.push(
				<IonItem
					className='btn-mark-refused'
					button={true}
					detail={false}
					onClick={() => handleMarkEstimate('refused')}
				>
					<FontAwesomeIcon icon={faCircleXmark} />
					Mark as Refused
				</IonItem>
			);
		}
	}

	return (
		<>
			<IonGrid className='estimate-footer'>
				<IonRow>
					<IonCol size='8'>
						<IonLabel className='lbl-terms'>Terms & Conditions</IonLabel>
						<Editor
							editorState={editorState}
							toolbarClassName='terms-toolbar'
							editorClassName='wysiwyg-editor-class-readonly terms-editor'
							readOnly
						/>
					</IonCol>
					<IonCol size='4'>
						{state.totalsLoading && <Loading overlay={true} />}
						<Table className='tbl-totals' size='small'>
							<TableBody>
								<TableRow>
									<TableCell variant='head' width='70%'>
										Sub Total
									</TableCell>
									<TableCell>
										{state.totals.subtotal.toLocaleString('en-GB', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
									</TableCell>
								</TableRow>
								{state.totals.vat.map((vatTotal: any, i: number) => {
									if (Number(vatTotal.total) <= 0) return;
									return (
										<TableRow key={i}>
											<TableCell variant='head'>{vatTotal.description}</TableCell>
											<TableCell>
												{vatTotal.total.toLocaleString('en-GB', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</TableCell>
										</TableRow>
									);
								})}
								{Number(state.totals.discount) > 0 && (
									<TableRow>
										<TableCell variant='head'>Included Discount</TableCell>
										<TableCell>
											{state.totals.discount.toLocaleString('en-GB', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</TableCell>
									</TableRow>
								)}
								<TableRow>
									<TableCell variant='head'>Total</TableCell>
									<TableCell>
										{state.totals.total.toLocaleString('en-GB', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size='6'>
						{!isNew && <IonButton onClick={handleUploadFiles}>Upload Files</IonButton>}
					</IonCol>
					<IonCol size='6' className='text-right'>
						{state.estimateData?.jobId && (
							<IonButton onClick={handleJobTargetsAndSkills}>Job Targets and Skills</IonButton>
						)}
						{(isNew || state.estimateData?.status.toLowerCase() === 'draft') && (
							<>
								<IonButton color='primary' onClick={handleSaveEstimate} disabled={!canSubmit}>
									Save Estimate
								</IonButton>
								<IonButton color='success' onClick={handleSendEstimate} disabled={!canSubmit}>
									Send Estimate
								</IonButton>
							</>
						)}
						{!isNew && state.estimateData?.status.toLowerCase() !== 'draft' && (
							<>
								<IonButton color='success' onClick={handleReIssueEstimate} disabled={!canSubmit}>
									Re-Issue Estimate
								</IonButton>
								<IonButton id='btn-mark-estimate' color='secondary'>
									Mark Estimate
									<FontAwesomeIcon icon={faCircleChevronUp} />
								</IonButton>
								<IonPopover
									className={`popover-mark-estimate items-count-${markEstimateItems.length}`}
									trigger='btn-mark-estimate'
									dismissOnSelect={true}
									alignment='start'
									side='left'
								>
									<IonContent className='popover-content'>
										<IonList>
											{markEstimateItems.map((item: any, i: number) => (
												<span key={i}>{item}</span>
											))}
										</IonList>
									</IonContent>
								</IonPopover>
							</>
						)}
					</IonCol>
				</IonRow>
			</IonGrid>

			{state.estimateData?.jobId && (
				<ResourcesModal
					isOpen={resourcesModal.isOpen}
					onClose={() => {
						setResourcesModal((prevState: any) => ({ ...prevState, isOpen: false }));
					}}
					onSuccess={() => {}}
					jobId={state.estimateData.jobId}
				/>
			)}
		</>
	);
};

export default EstimateFooter;

import {
	createElement,
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
} from 'react';
import { ListRange, Virtuoso } from 'react-virtuoso';

interface Props {
	items: Array<any>;
	itemTemplate?: any;
	click?: Function;
	handledScroll?: Function;
	reversed?: boolean;
	extraProps?: any;
	startReached?: Function;
	endReached?: Function;
	overscan?: number;
	firstItemIndex?: number;
	initialTopMostItemIndex?: number;
	hidden?: boolean;
}

const InfinteScroll = forwardRef<any, Props>((props: Props, ref) => {
	const listRef = useRef<any>(null);
	const scrollRef = useRef<any>(null);

	useImperativeHandle(ref, () => ({
		scrollToBottom() {
			if (listRef && listRef.current) {
				setTimeout(() => {
					listRef.current?.scrollToIndex({
						index: props.items.length - 1,
						behavior: 'smooth',
					});
				}, 500);
			}
		},
		scrollToIndex(index: number) {
			if (listRef && listRef.current) {
				setTimeout(() => {
					listRef.current?.scrollToIndex({
						index: index,
						behavior: 'smooth',
					});
				}, 500);
			}
		},
	}));

	const viewPortCheck = useCallback(
		(range: any) => {
			for (let i = range.startIndex; i <= range.endIndex; i++) {
				let foundMessage = props.items.find((message: any, index) => {
					return index === i;
				});
				if (foundMessage !== null && foundMessage !== undefined) {
					if (props.handledScroll) {
						props.handledScroll(foundMessage);
					}
				}
			}
		},
		[props]
	);

	const Item = (index: number, data: any) => {
		const obj: any = {
			item: data,
			index: index,
			click: props.click,
			parentRef: listRef,
		};

		if (props.extraProps !== undefined) {
			Object.keys(props.extraProps).forEach((key: string) => {
				obj[key] = props.extraProps[key];
			});
		}

		if (props.itemTemplate) {
			return createElement(props.itemTemplate, obj);
		} else {
			return <div>{data.message}</div>;
		}
	};

	const startReached = useCallback(
		(e: boolean) => {
			setTimeout(() => {
				if (props.startReached !== undefined && e === true && props.items.length > 0) {
					props.startReached();
				}
			}, 300);
		},
		[props]
	);

	const endReached = useCallback(
		(e: boolean) => {
			setTimeout(() => {
				if (props.endReached !== undefined && e === true && props.items.length > 0) {
					props.endReached();
				}
			}, 300);
		},
		[props]
	);

	const renderOut = () => {
		const fieldProperties: any = {
			scrollerRef: (ref: HTMLElement | Window | null) => {
				scrollRef.current = ref;
			},
			ref: listRef,
			data: props.items,
			style: { flex: 1 },
			rangeChanged: (range: ListRange) => {
				viewPortCheck(range);
			},
			itemContent: (index: number, data: any) => Item(index, data),
			followOutput: props.reversed ? 'auto' : false,
			atBottomStateChange: endReached,
			atTopStateChange: startReached,
		};

		if (props.reversed) {
			if (props.firstItemIndex !== undefined) {
				fieldProperties.firstItemIndex = props.firstItemIndex;
			}
			if (props.initialTopMostItemIndex !== undefined) {
				fieldProperties.initialTopMostItemIndex = props.initialTopMostItemIndex;
			}
		}

		if (props.overscan) {
			fieldProperties.overscan = props.overscan;
		}

		return (
			<>
				<Virtuoso hidden={props.hidden ?? false} {...fieldProperties} />
			</>
		);
	};

	return renderOut();
});

export default InfinteScroll;

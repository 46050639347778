import { useParams } from 'react-router-dom';
import { useState, useRef, Fragment, useContext, useEffect } from 'react';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker } from '@mobiscroll/react';
import { DateTime, Duration, Info } from 'luxon';
import {
	IonButton,
	IonButtons,
	IonChip,
	IonContent,
	IonHeader,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBuilding,
	faClipboard,
	faHome,
	faTimes,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Alert } from '@mui/material';
import axios from './../../../../../lib/axios';
import { showToast } from '../../../../../lib/toast';
import { dateContext } from './../../../../../contexts/DateContext';
import DataGrid from '../../../../../components/DataGrid/DataGrid';
import RadioButtons from '../../../../../components/UI/RadioButtons/RadioButtons';
import { Coordinates, PropMarker } from '../../../../../interfaces/Maps/MapsInterface';
import Map from '../../../../../components/Maps/Map';
import { EventReportUsageMode } from '../../workers-types';

interface Props {
	parentRef: any;
	tabRef: any;
	permissionTo: Function;
	usageMode?: EventReportUsageMode;
	workerId?: string;
	selectedDate?: DateTime;
}

const EventReportIndex: React.FC<Props> = (props: Props) => {
	const dateCtx: any = useContext(dateContext);

	// Usage mode defaults
	let { workerId }: any = useParams();
	let selectedDateDefault: DateTime = dateCtx.date ?? DateTime.now();

	switch (props.usageMode) {
		case EventReportUsageMode.TIMESHEET_DAILY:
			workerId = props.workerId;
			selectedDateDefault = props.selectedDate!.startOf('day');
			break;
	}

	const [calendarType, setCalendarType] = useState<'year' | 'month' | 'week' | undefined>('week');
	const [selectedDate, setSelectedDate] = useState<DateTime>(selectedDateDefault);
	const [selectedOption, setSelectedOption] = useState<Array<any>>(['summary']);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [markerPosition, setMarkerPosition] = useState<Array<PropMarker>>([]);
	const [mapOptions, setMapOptions] = useState<any>();
	const [selectedItem, setSelectedItem] = useState<any>({});
	const [marked, setMarked] = useState<Array<any>>([]);
	const [dataSource, setDataSource] = useState<any>({
		url: '/api/workers/workers_list/worker_card/event_report',
		method: 'POST',
		data: {
			worker_id: workerId,
			start: selectedDate?.startOf('day').toISO(),
			end: selectedDate?.endOf('day').toISO(),
			type: selectedOption[0],
		},
	});
	const [gridReady, setGridReady] = useState<boolean>(false);
	const gridRef: any = useRef<any>();
	const divRef = useRef<HTMLDivElement>(null);
	const [dayNames, setDayNames] = useState<Array<string>>([]);

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	const options: Array<any> = [
		{
			label: 'Locations',
			value: 'summary',
		},
		{
			label: 'Hours',
			value: 'hours',
		},
		{
			label: 'Stationary',
			value: 'stationary',
		},
		{
			label: 'Full',
			value: 'full',
		},
		{
			label: 'Raw',
			value: 'raw',
		},
	];

	const colData: Array<any> = [
		{
			headerName: selectedOption[0] === 'summary' ? 'Location' : 'Event',
			field: 'location',
			filter: true,
			cellRenderer: (params: any) => {
				let location = params.data.location;
				if (selectedOption[0] === 'summary' && location !== undefined) {
					if (
						location.indexOf('Transit') === -1 &&
						location.indexOf('Break') === -1 &&
						location.indexOf('Total') === -1
					) {
						location = 'Located at ' + location;
					}
				}
				return location;
			},
		},
		{
			headerName: 'Start',
			field: 'start',
			filter: true,
			cellRenderer: (params: any) => {
				if (params.data.start !== null && params.data.start !== undefined) {
					let date = DateTime.fromISO(params.data.start);
					return date.toFormat('HH:mm:ss');
				}
				return '';
			},
		},
		{
			headerName: 'End',
			field: 'end',
			filter: true,
			cellRenderer: (params: any) => {
				if (params.data.end !== null && params.data.end !== undefined) {
					let date = DateTime.fromISO(params.data.end);
					return date.toFormat('HH:mm:ss');
				}
				return '';
			},
		},
		{
			headerName: 'Time',
			field: 'seconds',
			filter: true,
			cellRenderer: (params: any) => {
				if (
					params.data.start !== null &&
					params.data.end !== null &&
					params.data.start !== undefined &&
					params.data.end !== undefined
				) {
					let start = DateTime.fromISO(params.data.start);
					let end = DateTime.fromISO(params.data.end);
					let duration1 = end.diff(start);
					return duration1.toFormat('hh:mm:ss');
				} else if (params.data.time !== null && params.data.time !== undefined) {
					let duration1 = Duration.fromObject({ seconds: params.data.time });
					return duration1.toFormat('hh:mm:ss');
				} else {
					return '';
				}
			},
		},
		{
			headerName: 'Type',
			field: 'type',
			filter: true,
			cellRenderer: (params: any) => {
				if (params.data.type !== null && params.data.type !== undefined) {
					let icon = null;
					let name = '';
					switch (params.data.type) {
						case 'event':
							icon = faUser;
							name = 'Event';
							break;
						case 'home':
							icon = faHome;
							name = 'Home';
							break;
						case 'job':
							icon = faClipboard;
							name = 'Job';
							break;
						case 'office':
							icon = faBuilding;
							name = 'Office';
							break;
					}
					if (icon !== null) {
						return (
							<IonChip style={{ fontSize: 10 }}>
								<FontAwesomeIcon style={{ marginRight: 6 }} icon={icon} /> {name}
							</IonChip>
						);
					}
				}
				return '';
			},
		},
		{
			headerName: 'Timestamp',
			field: 'recorded',
			filter: true,
			cellRenderer: (params: any) => {
				if (params.data.recorded !== null && params.data.recorded !== undefined) {
					let date = DateTime.fromISO(params.data.recorded);
					return date.toFormat('HH:mm:ss');
				}
				return '';
			},
		},
	];

	useEffect(() => {
		if (gridRef.current && gridReady === true) {
			if (selectedOption[0] !== 'full' && selectedOption[0] !== 'raw') {
				gridRef.current.setColumnsVisible(['recorded', 'type'], false);
				gridRef.current.setColumnsVisible(['start', 'end', 'seconds'], true);
			} else {
				gridRef.current.setColumnsVisible(['recorded', 'type'], true);
				gridRef.current.setColumnsVisible(['start', 'end', 'seconds'], false);
			}
			gridRef.current.sizeToFit();
		}
	}, [selectedOption, gridReady]);

	const handleCellClick = async (e: any) => {
		setSelectedDate(DateTime.fromJSDate(e.value));
	};

	useEffect(() => {
		dateCtx.setDate(selectedDate);
		setDataSource((prevValue: any) => {
			let newValue = { ...prevValue };
			newValue.data.start = selectedDate?.set({ hour: 0, minute: 0, second: 0 }).toISO();
			newValue.data.end = selectedDate?.set({ hour: 23, minute: 59, second: 59 }).toISO();
			return newValue;
		});
	}, [selectedDate]);

	useEffect(() => {
		setDataSource((prevValue: any) => {
			let newValue = { ...prevValue };
			newValue.data.type = selectedOption[0];
			return newValue;
		});
	}, [selectedOption]);

	const loadEventLogSummary = async (start: DateTime, end: DateTime) => {
		return new Promise((res, rej) => {
			axios
				.post('/api/workers/workers_list/worker_card/event_report', {
					worker_id: workerId,
					start: start.toISO(),
					end: end.toISO(),
					summary: 1,
				})
				.then((result) => {
					if (result.data) {
						setMarked(
							result.data.map((item: any) => {
								let date = DateTime.now().set({
									year: item._id.date.year,
									month: item._id.date.month,
									day: item._id.date.day,
								});
								return { date: date.toJSDate() };
							})
						);
						res(true);
					}
				})
				.catch((e) => {
					showToast('error');
					rej(e);
				});
		});
	};

	const knobClick = () => {
		setCalendarType((prevValue) => {
			if (prevValue === 'week') {
				return 'month';
			} else {
				return 'week';
			}
		});
	};

	const rowClickedFunction = (event: any) => {
		//triggerOverlay(event.data);
		if (selectedOption[0] === 'raw') {
			if (event.data.gps_data) {
				let latlng: Coordinates = {
					lng: event.data.gps_data.longitude,
					lat: event.data.gps_data.latitude,
				};
				let radius = event.data.gps_data.accuracy;
				setMarkerPosition([
					{
						canOpen: false,
						latlng,
						radius,
						showRadius: true,
						title: event.data.location,
						isOpen: false,
						date: DateTime.fromISO(event.data.recorded),
					},
				]);
				setSelectedItem(event.data);
				setMapOptions(latlng);
				setIsOpen(true);
			}
		}
	};

	const totalRow = function (rowdata: any) {
		let result: any = [];
		if (selectedOption[0] !== 'full' && selectedOption[0] !== 'raw') {
			let d: any = {};
			rowdata.forEach((row: any) => {
				if (d[row.location] === undefined) {
					d[row.location] = 0;
				}
				let start = DateTime.fromISO(row.start);
				let end = DateTime.fromISO(row.end);
				let duration1 = end.diff(start);
				d[row.location] += Number(duration1.toFormat('ss'));
			});

			let total = 0;
			Object.keys(d).forEach((key) => {
				result.push({
					location: key,
					start: null,
					end: null,
					time: isNaN(d[key]) ? null : d[key],
				});
				if (!isNaN(d[key])) {
					total += d[key];
				}
			});

			result.sort((a: any, b: any) => {
				if (a.time < b.time) return 1;
				if (a.time > b.time) return -1;
				return 0;
			});
			result.push({
				location: 'Total',
				start: null,
				end: null,
				time: total,
			});
		}

		return result;
	};

	const flattenObject = (obj: any, parentKey?: string) => {
		let result: any = {};
		if (obj !== null && obj !== undefined) {
			Object.keys(obj).forEach((key) => {
				const value = obj[key];
				const _key = parentKey ? parentKey + '.' + key : key;
				if (value !== null && value !== undefined) {
					if (typeof value === 'object') {
						result = { ...result, ...flattenObject(value, _key) };
					} else {
						result[_key] = value;
					}
				}
			});
		}
		return result ?? {};
	};

	const getDetails = (obj: any) => {
		let result = flattenObject(obj.gps_data);
		if (Object.keys(result).length > 0) {
			return Object.keys(result).map((key, index) => (
				<div key={index}>
					{key}: {result[key]}
				</div>
			));
		} else {
			return <span></span>;
		}
	};

	return (
		<div className='flex-grow-container' style={{ height: '100%' }}>
			<div className='date-expand' ref={divRef} style={{ margin: '0 auto', width: '30vw' }}>
				<Datepicker
					dayNamesMin={dayNames}
					calendarSize={1}
					showRangeLabels={false}
					marked={marked}
					calendarType={calendarType}
					controls={['calendar']}
					display='inline'
					defaultValue={selectedDate.toFormat('dd/MM/yy')}
					onChange={(e) => handleCellClick(e)}
					onPageLoading={(e) => {
						let start = DateTime.fromJSDate(e.firstDay);
						let end = DateTime.fromJSDate(e.lastDay);
						loadEventLogSummary(start, end);
					}}
					renderCalendarHeader={() => {
						return (
							<Fragment>
								<CalendarPrev className='custom-prev' />
								<CalendarNav className='custom-nav' />
								<CalendarNext className='custom-next' />
							</Fragment>
						);
					}}
				/>
				<div className='date-expand-control'>
					<div className='knob' onClick={knobClick}></div>
				</div>
			</div>

			<div style={{ flex: 1 }}>
				<DataGrid
					ref={gridRef}
					onGridReady={() => {
						setGridReady(true);
					}}
					dataSource={dataSource}
					title={
						<RadioButtons
							value={selectedOption}
							options={options}
							onChange={(v: any) => {
								setSelectedOption(v);
							}}
						/>
					}
					cols={colData}
					rowClickedFunction={rowClickedFunction}
					totalsRow={totalRow}
					exportButton={props.usageMode !== EventReportUsageMode.TIMESHEET_DAILY}
					paging={selectedOption[0] === 'raw'}
				/>
			</div>
			<IonModal
				style={{ '--width': '60vw', '--height': '70vh' }}
				isOpen={isOpen}
				className='prop-form'
				onDidDismiss={() => {
					setIsOpen(false);
				}}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{`Coordinate`}</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton
								onClick={() => {
									setIsOpen(false);
								}}
							>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className='flex-grow-container' style={{ height: '100%' }}>
						<Alert severity='info' style={{ margin: '0 0 8px 0' }}>
							<h3>GPS Data</h3>
							<div style={{ display: 'grid', gridTemplateColumns: '25vw 25vw' }}>
								{getDetails(selectedItem)}
							</div>
						</Alert>
						<div style={{ flex: 1, overflow: 'hidden' }}>
							<Map center={mapOptions} markers={markerPosition} />
						</div>
					</div>
				</IonContent>
			</IonModal>
		</div>
	);
};

export default EventReportIndex;

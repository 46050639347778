import { NavLink } from 'react-router-dom';
import { IonRow, IonCol, IonCard } from '@ionic/react';
import TitleBar from '../../components/TitleBar/TitleBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserPlus,
	faUserCheck,
	faUserPen,
	faSearch,
	faComment,
	faFilePen,
	faFileExport,
	faFileImport,
	faUserCog,
	faFileCirclePlus,
	faFileCircleMinus,
} from '@fortawesome/free-solid-svg-icons';
import usePermissionTo from '../../utils/custom-hooks/PermissionTo';

const JobsIndex = () => {
	const permissions: any = {
		jobs_list: usePermissionTo('jobs.jobs_list'),
		clients_list: usePermissionTo('jobs.clients_list'),
		estimates_list: usePermissionTo('jobs.estimates_list'),
		invoices_list: usePermissionTo('jobs.invoices_list'),
		reports: usePermissionTo('jobs.reports'),
	};

	return (
		<>
			<TitleBar title='Jobs' />
			<IonRow className='module-panels'>
				{permissions.jobs_list('read') && (
					<IonCol size={'6'}>
						<NavLink to='/jobs/jobs_list'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Jobs</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faFileCirclePlus} />
										Add New Jobs
									</li>
									<li>
										<FontAwesomeIcon icon={faFilePen} />
										Update Job Information
									</li>
									<li>
										<FontAwesomeIcon icon={faFileExport} />
										View and Export Job History
									</li>
									<li>
										<FontAwesomeIcon icon={faFileImport} />
										Upload Jobs via a spreadsheet
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
				{permissions.clients_list('read') && (
					<IonCol size={'6'}>
						<NavLink to='/jobs/clients_list'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Clients List</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faUserPlus} />
										Add New Clients
									</li>
									<li>
										<FontAwesomeIcon icon={faUserCog} />
										Update Client information
									</li>
									<li>
										<FontAwesomeIcon icon={faUserCheck} />
										View and Export Client History
									</li>
									<li>
										<FontAwesomeIcon icon={faUserPen} />
										Upload Clients via a spreadsheet
									</li>
									<li>
										<FontAwesomeIcon icon={faComment} />
										Message Clients via Text and Email
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
				{permissions.estimates_list('read') && (
					<IonCol size={'6'}>
						<NavLink to='/jobs/estimates_list'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Estimates</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faFileCirclePlus} />
										Create a new estimate
									</li>
									<li>
										<FontAwesomeIcon icon={faFilePen} />
										Edit an existing estimate
									</li>
									<li>
										<FontAwesomeIcon icon={faFileCircleMinus} />
										Delete an estimate
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
				{permissions.invoices_list('read') && (
					<IonCol size={'6'}>
						<NavLink to='/jobs/invoices_list'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Invoices</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faFileCirclePlus} />
										Invoice a job
									</li>
									<li>
										<FontAwesomeIcon icon={faFilePen} />
										Edit an existing invoice
									</li>
									<li>
										<FontAwesomeIcon icon={faFileCircleMinus} />
										Delete an invoice
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
				{permissions.reports('read') && (
					<IonCol size={'6'}>
						<NavLink to='/jobs/reports#job-cost-report'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Reports</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faSearch} />
										Run a number of reports relevant to Jobs
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
			</IonRow>
		</>
	);
};

export default JobsIndex;

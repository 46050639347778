import { faBuilding, faClipboard, faHome } from '@fortawesome/free-solid-svg-icons';
import axios from '../../lib/axios';
import { showToast } from '../../lib/toast';

export function getCalCellLocationClass(location: null | string): string {
	let cssClass: string = 'bg-cal-cell ';
	switch (location) {
		case null:
		case '0':
			cssClass += 'bg-cal-cell-schedule';
			break;
		case '1':
			cssClass += 'bg-cal-cell-home';
			break;
		case '2':
			cssClass += 'bg-cal-cell-roaming';
			break;
		default:
			cssClass += 'bg-cal-cell-office';
			break;
	}

	return cssClass;
}

export function loadWorkLocations(setIsLoading: Function, setData: Function) {
	setIsLoading(true);

	axios
		.get('/api/utilities/office_locations')
		.then((res: any) => {
			let tmp: Array<any> = [
				{ text: 'Job', value: '0', icon: faClipboard },
				{ text: 'Home', value: '1', icon: faHome },
			];

			res.data.forEach((office: any) => {
				tmp.push({ text: office.name, value: office._id, icon: faBuilding });
			});

			setData(tmp);
		})
		.catch(() => showToast('error', 'Could not load offices', null, { position: 'top-center' }))
		.finally(() => setIsLoading(false));
}

import CreateJob from './CreateJob';
import CreateWorker from './CreateWorker';
import Diary from './Diary';
import GoTo from './GoTo';
import HolidaysThisMonth from './HolidaysThisMonth';
import Requests from './Requests';
import RequestsApproval from './RequestsApproval';
import Sales from './Sales';
import Sick from './Sick';
import SicknessToday from './SicknessToday';
import SuspendedJobs from './SuspendedJobs';
import TeamLocations from './TeamLocations';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const Widget: React.FC<Props> = (props) => {
	const widgets: any = {
		'dashboard.personal.requests': Requests,
		'dashboard.personal.sick': Sick,
		'dashboard.personal.team_locations': TeamLocations,
		'dashboard.personal.suspended_jobs': SuspendedJobs,
		'dashboard.personal.diary': Diary,
		'dashboard.personal.holidays_approval': RequestsApproval,
		'dashboard.personal.sales': Sales,
		'dashboard.features.create_worker': CreateWorker,
		'dashboard.features.create_job': CreateJob,
		'dashboard.features.go_to_worker': GoTo,
		'dashboard.features.go_to_job': GoTo,
		'dashboard.company.sickness_today': SicknessToday,
		'dashboard.company.holidays_this_month': HolidaysThisMonth,
		'dashboard.company.suspended_jobs': SuspendedJobs,
		'dashboard.company.sales': Sales,
	};
	const widgetProps = { widgetId: props.widgetId, systemSettings: props.systemSettings };
	const WidgetComponent = widgets[props.widgetId];

	return <WidgetComponent {...widgetProps} />;
};

export default Widget;

import { useState, useRef, useContext } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import useJobReport from '../useJobReport';
import ExtraFooterControls from '../components/ExtraFooterControls';
import { ReportContextType, reportContext } from '../reportContext';
import { TotalCol, TotalColTitle } from '../report-types';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';

interface Props {
	parentRef: any;
	title: string;
	uid: string;
	permissionTo: Function;
}

const JobCostReportIndex: React.FC<Props> = (props: Props) => {
	const reportCtx: ReportContextType = useContext(reportContext);
	const jobReport = useJobReport({
		uid: props.uid,
		title: props.title,
	});
	const gridUrl = '/api/jobs/reports/job_cost_report';
	const gridPayload = {
		client_id: jobReport.chosenClient?.value,
		manager_id: jobReport.chosenJobManager?.value,
		status: jobReport.chosenJobStatus?.value,
	};
	const [gridReady, setGridReady] = useState<boolean>(false);
	const gridRef: any = useRef<any>();
	const calcTotalCols: TotalCol[] = [
		{ id: 'estimated_labour_cost' },
		{ id: 'actual_labour_cost' },
		{ id: 'remaining_labour_value' },
	];
	const totalColsTitles: TotalColTitle[] = [{ id: 'client', title: 'Totals' }];
	const colData: Array<any> = [
		{ headerName: 'Job No.', field: 'job_number', flex: 1 },
		{ headerName: 'Job Name', field: 'job_name', flex: 1 },
		{ headerName: 'Job Manager', field: 'job_manager', flex: 1 },
		{ headerName: 'Address', field: 'address', flex: 1 },
		{ headerName: 'Client', field: 'client', flex: 1 },
		{
			headerName: 'Estimated Labour Cost',
			field: 'estimated_labour_cost',
			type: 'rightAligned',
			valueFormatter: (params: any) => currencyFormatter(params, false, true),
			minWidth: 175,
			flex: 1,
		},
		{
			headerName: 'Actual Labour Cost',
			field: 'actual_labour_cost',
			type: 'rightAligned',
			valueFormatter: (params: any) => currencyFormatter(params, false, true),
			minWidth: 150,
			flex: 1,
		},
		{
			headerName: 'Remaining Labour Value',
			field: 'remaining_labour_value',
			type: 'rightAligned',
			valueFormatter: (params: any) => currencyFormatter(params, false, true),
			minWidth: 185,
			flex: 1,
		},
	];

	return (
		<div className='flex-grow-container h-100'>
			<DataGrid
				ref={gridRef}
				onGridReady={() => setGridReady(true)}
				useSearch={false}
				title={props.title}
				cols={colData}
				dataSource={{
					url: gridReady ? gridUrl : '',
					method: 'POST',
					data: gridPayload,
				}}
				excelStyles={jobReport.excelStyles}
				rowCursorPointer={false}
				totalsRow={jobReport.totalRow(calcTotalCols, totalColsTitles)}
				extraFooter={
					gridReady && gridRef.current ? (
						<ExtraFooterControls
							gridRef={gridRef}
							clients={reportCtx.clients}
							chosenClient={jobReport.chosenClient}
							setChosenClient={jobReport.setChosenClient}
							jobManagers={reportCtx.jobManagers}
							chosenJobManager={jobReport.chosenJobManager}
							setChosenJobManager={jobReport.setChosenJobManager}
							jobStatuses={reportCtx.jobStatuses}
							chosenJobStatus={jobReport.chosenJobStatus}
							setChosenJobStatus={jobReport.setChosenJobStatus}
						/>
					) : null
				}
			/>
		</div>
	);
};

export default JobCostReportIndex;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MbscEventList, MbscEventcalendarView } from '@mobiscroll/react';
import { DateTime } from 'luxon';

export enum ViewMode {
	MONTH = 'month',
	WEEK = 'week',
}

export type Views = {
	[key in ViewMode]: MbscEventcalendarView;
};

export enum ResourceMode {
	JOBS = 'jobs',
	WORKERS = 'workers',
	SKILLS = 'skills',
}

export enum EventType {
	APPOINTMENT = 'appointment',
}

export enum SideMenuAction {
	CANCEL = 'cancel',
	CREATE_EVENT = 'createEvent',
	UPDATE_EVENT = 'updateEvent',
	DELETE_EVENT = 'deleteEvent',
}

export type ResourceModeOption = {
	label: string;
	value: string;
	icon: IconProp;
};

export interface SchedulingState {
	viewMode: ViewMode;
	resourceMode: ResourceMode;
	tempEvent?: SchedulingEvent;
	filter?: any;
}

export interface SchedulingEvent {
	isNew: boolean;
	id: string;
	requestId?: string;
	start: DateTime;
	end: DateTime;
	time: Array<any>;
	enumEventType?: string;
	editable?: boolean;
	job?: { label: string; value: string; manager: string };
	skill?: { label: string; value: string };
	workers?: Array<string>;
	workerName?: string;
	clashOverrides?: Array<any>;
	locationId?: string;
}

export interface FooterData {
	date: Date;
	events: MbscEventList[];
}

export interface DayScheduleModalDefaults {
	isOpen: boolean;
	selectedDate: DateTime;
	events: Array<any>;
	resource: any;
}

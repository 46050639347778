import { RefObject, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
	CalendarNav,
	CalendarNext,
	CalendarPrev,
	Datepicker,
	MbscDatepickerOptions,
} from '@mobiscroll/react';
import { IonButton } from '@ionic/react';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import JobsContext from '../../../../contexts/JobsContext';
import Loading from '../../../../components/UI/Loading';
import { faCalendarXmark } from '@fortawesome/free-regular-svg-icons';
import TimelineFilterRow from '../components/TimelineFilterRow';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import { TimelineDataItem } from '../job-types';
import InfinteScroll from '../../../../components/InfiniteScroll/InfiniteScroll';
import { Timeline } from '@mui/lab';
import TimelineRow from '../components/TimelineRow';
import PhotoModal from '../../../../components/UI/modals/PhotoModal';

interface Props {
	parentRef: any;
	title: string;
	uid: string;
}

const DiaryIndex: React.FC<Props> = (props: Props) => {
	const { jobId }: any = useParams();
	const { jobData } = useContext(JobsContext);
	const calendarRef = useRef<any>(null);
	const onBeforeGetContentResolve = useRef<any>(null);
	const timelineContainerRef = useRef<HTMLDivElement>(null);
	const defaultDate: Date[] = [
		DateTime.now().startOf('day').toJSDate(),
		DateTime.now().endOf('day').toJSDate(),
	];
	const [calendarInit, setCalendarInit] = useState<boolean>(false);
	const [calPageDate, setCalPageDate] = useState<DateTime>(DateTime.now());
	const [calMarkedDates, setCalMarkedDates] = useState<Array<MbscDatepickerOptions>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filtersLoading, setFiltersLoading] = useState<boolean>(true);
	const [filters, setFilters] = useState<Array<any>>([]);
	const [data, setData] = useState<Array<TimelineDataItem>>([]);
	const [dataPrint, setDataPrint] = useState<Array<any>>([]);
	const [inactiveFilters, setInactiveFilters] = useState<Array<string>>([]);
	const toastId = useRef<any>(null);

	// Modals
	const [photoModal, setPhotoModal] = useState<any>({ isOpen: false });
	const [photoModalData, setPhotoModalData] = useState<any>({});

	useEffect(() => {
		loadFilters();
	}, []);

	useEffect(() => {
		setIsLoading(filtersLoading);
	}, [filtersLoading]);

	useEffect(() => {
		if (filters.length > 0) loadTimeline(defaultDate);
	}, [filters]);

	useEffect(() => {
		if (calendarInit === true && calendarRef.current && calendarRef.current.value) {
			if (calendarRef.current.value[0] && calendarRef.current.value[1]) {
				loadCalendarMarkers('filter-change');
				loadTimeline(calendarRef.current.value);
			}
		}
	}, [inactiveFilters, calendarInit]);

	useEffect(() => {
		if (dataPrint.length > 0) {
			// Resolve Promise - tell react-to-print to load printable content
			onBeforeGetContentResolve.current();
			if (toastId.current) toast.dismiss(toastId.current);
		}
	}, [dataPrint, onBeforeGetContentResolve]);

	const loadCalendarMarkers = (
		action: 'on-init' | 'page-change' | 'filter-change',
		event?: any
	) => {
		let start: DateTime | null = null;
		let end: DateTime | null = null;
		let tmp: any = null;

		switch (action) {
			case 'on-init':
				tmp = event._value;
				setCalPageDate(DateTime.fromJSDate(tmp[0]).startOf('month'));
				start = DateTime.fromJSDate(tmp[0]);
				end = DateTime.fromJSDate(tmp[0]);
				break;
			case 'page-change':
				tmp = event.month;
				setCalPageDate(DateTime.fromJSDate(tmp).startOf('month'));
				start = DateTime.fromJSDate(tmp);
				end = DateTime.fromJSDate(tmp);
				break;
			case 'filter-change':
				start = calPageDate;
				end = calPageDate;
				break;
		}

		// Expand the date range around the current month
		start = start.startOf('month').minus({ weeks: 2 }).startOf('day');
		end = end.endOf('month').plus({ weeks: 2 }).endOf('day');

		const payload: any = {
			job_id: jobId,
			start,
			end,
			inactive_filters: inactiveFilters,
			summary: true,
		};

		axios
			.post('/api/jobs/jobs_list/job_card/diary', payload)
			.then((res: any) => {
				setCalMarkedDates(res.data.map((item: any) => DateTime.fromISO(item).toJSDate()));
			})
			.catch(() => showToast('error'));
	};

	const loadFilters = () => {
		setFiltersLoading(true);
		axios
			.get('/api/jobs/jobs_list/job_card/diary/filter')
			.then((res: any) => {
				setFilters(res.data);
			})
			.catch(() => showToast('error'))
			.finally(() => setFiltersLoading(false));
	};

	const loadTimeline = (
		selectedDate: Date[],
		printMode: boolean = false,
		resolvePromise: any = null
	) => {
		if (!selectedDate[0] || !selectedDate[1]) return false;

		setIsLoading(true);

		if (printMode) {
			toastId.current = toast.loading('Preparing printer output...');
		}

		const start: string = DateTime.fromJSDate(selectedDate[0]).startOf('day').toISO();
		const end: string = DateTime.fromJSDate(selectedDate[1]).endOf('day').toISO();
		const payload: any = {
			job_id: jobId,
			start,
			end,
			inactive_filters: inactiveFilters,
		};

		axios
			.post('/api/jobs/jobs_list/job_card/diary', payload)
			.then((res: any) => {
				setData(res.data);

				if (printMode && resolvePromise) {
					onBeforeGetContentResolve.current = resolvePromise;
					setDataPrint(res.data);
				}
			})
			.catch(() => showToast('error'))
			.finally(() => setIsLoading(false));
	};

	const handleDatepickerOnInit = (e: any) => {
		setCalendarInit(true);
		loadCalendarMarkers('on-init', e.inst);
	};

	const handleDatepickerOnPageChange = (e: any) => {
		// Slight delay to remove non-applicable warning
		setTimeout(() => {
			loadCalendarMarkers('page-change', e);
		}, 1);
	};

	const handleDatepickerOnChange = (e: any) => {
		loadTimeline(e.value);
	};

	const handleSetInactiveFilters = (item: any) => {
		setInactiveFilters((prevState: any) => {
			if (!prevState.some((id: string) => id === item.id)) {
				prevState = [...prevState, item.id];
			} else {
				prevState = prevState.filter((id: string) => id !== item.id);
			}

			return prevState;
		});
	};

	const handleOnBeforeGetContent = () => {
		return new Promise((resolve: any) => {
			// `react-to-print` will wait for this Promise to resolve before continuing
			loadTimeline(calendarRef.current.value, true, resolve);
		});
	};

	const handleOnAfterPrint = () => {
		setDataPrint([]);
		onBeforeGetContentResolve.current = null;
	};

	const getCalendarDate = (prepend: string = ''): string => {
		let dateOutput: string = '';

		if (calendarRef.current && calendarRef.current.value) {
			if (calendarRef.current.value[0] && calendarRef.current.value[1]) {
				const start: DateTime = DateTime.fromJSDate(calendarRef.current.value[0]);
				const end: DateTime = DateTime.fromJSDate(calendarRef.current.value[1]);

				if (start.toFormat('dd/MM/yyyy') !== end.toFormat('dd/MM/yyyy')) {
					dateOutput = `${start.toFormat('dd/MM/yyyy')} to ${end.toFormat('dd/MM/yyyy')}`;
				} else {
					dateOutput = start.toFormat('dd/MM/yyyy');
				}
			}
		}

		return dateOutput ? prepend + dateOutput : '';
	};

	const photoModalOpen = (data: any) => {
		setPhotoModalData(data);
		setPhotoModal({ isOpen: true });
	};

	const photoModalOnClose = () => {
		setPhotoModal({ isOpen: false });
	};

	const handleDownloadImage = (data: any) => {
		let link = document.createElement('a');
		link.href = data.path;
		link.download = data.file_name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<div className='jobdiary-container'>
				{isLoading && <Loading overlay={true} />}
				<div>
					<h4 className='font-bold data-grid-title'>{`${props.title}${getCalendarDate(' - ')}`}</h4>
				</div>

				<div className='split-view'>
					<div className='split-view-left'>
						<div className='calendar-container'>
							<Datepicker
								ref={calendarRef}
								onInit={handleDatepickerOnInit}
								select={'range'}
								showRangeLabels={false}
								calendarSize={1}
								marked={calMarkedDates}
								maxRange={31}
								calendarType={'month'}
								defaultValue={defaultDate}
								controls={['calendar']}
								display='inline'
								firstDay={1}
								onChange={handleDatepickerOnChange}
								onPageChange={handleDatepickerOnPageChange}
								renderCalendarHeader={() => {
									return (
										<>
											<CalendarPrev className='custom-prev' />
											<CalendarNav className='custom-nav' />
											<CalendarNext className='custom-next' />
										</>
									);
								}}
							/>
						</div>
						<div className='filters'>
							<h4 className='font-bold data-grid-title'>Key / Filter</h4>

							{filters.map((item: any, index: number) => {
								return (
									<TimelineFilterRow
										key={index}
										item={item}
										inactiveFilters={inactiveFilters}
										onClick={handleSetInactiveFilters}
									/>
								);
							})}
						</div>
					</div>

					<div
						className={`job-diary-timeline-container${
							data.length === 0 ? ' no-data-placeholder' : ''
						}`}
					>
						{data.length === 0 && (
							<div className='no-data-placeholder'>
								<div className='no-data-placeholder-title'>No Diary Entries</div>
								<FontAwesomeIcon icon={faCalendarXmark} />
							</div>
						)}

						{data.length > 0 && (
							<Timeline position='alternate'>
								<InfinteScroll
									items={data}
									itemTemplate={TimelineRow}
									extraProps={{ photoModalOpen: photoModalOpen }}
								></InfinteScroll>
							</Timeline>
						)}
					</div>
				</div>

				<div className='footer'>
					<ReactToPrint
						trigger={() => (
							<IonButton disabled={data.length === 0}>
								<FontAwesomeIcon icon={faPrint} className='pe-1' />
								Print
							</IonButton>
						)}
						content={() => timelineContainerRef.current}
						onBeforeGetContent={handleOnBeforeGetContent}
						onAfterPrint={handleOnAfterPrint}
					/>
				</div>
			</div>

			{/* Component to print - hidden from web view */}
			<div style={{ display: 'none' }}>
				<ComponentToPrint
					ref={timelineContainerRef}
					selectedDate={getCalendarDate()}
					jobData={jobData}
					dataPrint={dataPrint}
				/>
			</div>

			<PhotoModal
				isOpen={photoModal.isOpen}
				onClose={photoModalOnClose}
				onDidDismiss={photoModalOnClose}
				initialData={photoModalData}
				handleDownloadImage={handleDownloadImage}
			/>
		</>
	);
};

export default DiaryIndex;

interface PrintProps {
	ref: any;
	selectedDate: string;
	jobData: any;
	dataPrint: Array<any>;
}

const ComponentToPrint: React.FC<PrintProps> = forwardRef((props, ref) => {
	return (
		<div ref={ref as RefObject<HTMLDivElement>} className='job-diary-timeline-container'>
			<div className='print-header for-print'>
				<style>{'/* Printed page margin */ @page { margin: 26pt }'}</style>
				<h1>Diary Timeline - {props.jobData?.number + ': ' + props.jobData?.name}</h1>
				<h3>{props.selectedDate}</h3>
			</div>

			<Timeline position='alternate'>
				{props.dataPrint.map((item: any, index: number) => (
					<TimelineRow key={index} index={index} item={item} printMode={true} />
				))}
			</Timeline>
		</div>
	);
});

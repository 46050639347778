import { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { getCenter } from 'geolib';

import Map from '../../../../../components/Maps/Map';
import axios from '../../../../../lib/axios';
import { showToast } from '../../../../../lib/toast';
import { boundsFromLatLngList, getBoundsZoomLevel } from '../../../../../helpers/geo';
import { MapControls, PropMarker } from '../../../../../interfaces/Maps/MapsInterface';
import Routes from '../../../../../components/Maps/Routes/Routes';
import { subtleGreyScale } from '../../../../../components/Maps/MapStyles';
import { mapLocations } from '../../../../../components/Maps/MapLocations';
import Calendar from '../../../../../components/Maps/Calendar/Calendar';
import { dateContext } from './../../../../../contexts/DateContext';
import Loading from '../../../../../components/UI/Loading';
import { RoutemapsUsageMode } from '../../workers-types';

interface Props {
	parentRef: any;
	tabRef: any;
	permissionTo: Function;
	usageMode?: RoutemapsUsageMode;
	workerId?: string;
	selectedDate?: DateTime;
}

const RouteMapIndex: React.FC<Props> = (props: Props) => {
	const dateCtx: any = useContext(dateContext);

	// Usage mode defaults
	let { workerId }: any = useParams();
	let selectedDateDefault: DateTime = dateCtx.date ?? DateTime.now();

	switch (props.usageMode) {
		case RoutemapsUsageMode.TIMESHEET_DAILY:
			workerId = props.workerId;
			selectedDateDefault = props.selectedDate!.startOf('day');
			break;
	}

	const [mapCenter, setMapCenter] = useState({ lat: 51.96217, lng: -0.265839 });
	const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
	const [zoom, setZoom] = useState<number>(14);
	const [marked, setMarked] = useState<Array<any>>([]);
	const [selectedDate, setSelectedDate] = useState<DateTime>(selectedDateDefault);
	const [controls, setControls] = useState<Array<MapControls>>([]);
	const [routes, setRoutes] = useState<Array<any>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [routeMarkers, setRouteMarkers] = useState<Array<any>>([]);
	const [lastRequest, setLastRequest] = useState<any>({});
	const [markers, setMarkers] = useState<Array<any>>([]);
	const [routeLines, setRouteLines] = useState<Array<any>>([]);
	const [route, setRoute]: any = useState('');

	const loadEventLogSummary = async (start: DateTime, end: DateTime) => {
		return new Promise((res, rej) => {
			axios
				.post('/api/workers/workers_list/worker_card/route_map/routes', {
					worker_id: workerId,
					start: start.toISO(),
					end: end.toISO(),
					summary: 1,
				})
				.then((result) => {
					if (result.data) {
						setMarked(
							result.data.map((item: any) => {
								let date = DateTime.now().set({
									year: item._id.date.year,
									month: item._id.date.month,
									day: item._id.date.day,
								});
								return { date: date.toJSDate() };
							})
						);
						res(true);
					}
				})
				.catch((e) => {
					showToast('error');
					rej(e);
				});
		});
	};

	const defaultControl = (): MapControls => {
		return {
			position: 'TOP_CENTER',
			component: Calendar,
			data: {
				changeFunction: setSelectedDate,
				summaryFunction: loadEventLogSummary,
				marked: marked,
				select: 'date',
				defaultValue: selectedDate,
				usageMode: RoutemapsUsageMode.TIMESHEET_DAILY,
			},
		};
	};

	useEffect(() => {
		const locations = async () => {
			let localMarkers = await mapLocations(workerId);
			setMarkers(localMarkers.markers);
		};
		locations();
	}, []);

	useEffect(() => {
		setControls([
			defaultControl(),
			{
				position: 'LEFT_TOP',
				component: Routes,
				data: {
					date: selectedDate,
					routeData: routes,
					onClick: (obj: any) => {
						setRoute(obj.start + '|' + obj.finish);
					},
				},
				style: { height: '100%' },
			},
		]);
	}, [marked]);

	useEffect(() => {
		if (mapRef !== null) {
			getRoutes();
		}
		dateCtx.setDate(selectedDate);
	}, [selectedDate, mapRef]);

	useEffect(() => {
		if (mapRef !== null && route !== '') {
			getRouteMarkers();
		}
	}, [route, mapRef]);

	useEffect(() => {
		if (mapRef !== null) {
			setControls([
				defaultControl(),
				{
					position: 'LEFT_TOP',
					component: Routes,
					data: {
						date: selectedDate,
						routeData: routes,
						onClick: (obj: any) => {
							setRoute(obj.start + '|' + obj.finish);
						},
					},
					style: { height: '100%' },
				},
			]);
			if (route !== '') {
				getRouteMarkers();
			}
		}
	}, [selectedDate, route, mapRef]);

	const getRoutes = () => {
		return new Promise((res, rej) => {
			if (selectedDate) {
				let start = selectedDate.startOf('day');
				let end = selectedDate.endOf('day');
				axios
					.post('/api/workers/workers_list/worker_card/route_map/routes', {
						worker_id: workerId,
						start: start.toISO(),
						end: end.toISO(),
					})
					.then((result) => {
						if (result.data) {
							setControls([
								defaultControl(),
								{
									position: 'LEFT_TOP',
									component: Routes,
									data: {
										date: selectedDate,
										routeData: result.data,
										onClick: (obj: any) => {
											setRoute(obj.start + '|' + obj.finish);
										},
									},
									style: { height: '100%' },
								},
							]);
							setRoutes(result.data);
							res(true);
						}
					})
					.catch((e) => {
						showToast('error');
						rej(e);
					});
			}
		});
	};

	const getRouteMarkers = () => {
		return new Promise((res, rej) => {
			setLoading(true);
			let parts = route.split('|');
			let request = {
				worker_id: workerId,
				start: parts[0],
				end: parts[1],
			};
			axios
				.post('/api/workers/workers_list/worker_card/route_map/route-data', request)
				.then((result) => {
					if (result.data) {
						const fenceList: Array<any> = [];
						const coords: Array<any> = [];
						result.data.markers.forEach((site: PropMarker) => {
							site.isOpen = false;
							site.canOpen = true;
							fenceList.push(site);
							coords.push({
								lat: Number(site.latlng.lat),
								lng: Number(site.latlng.lng),
							});
						});
						result.data.corrected.forEach((site: PropMarker) => {
							site.isOpen = false;
							site.canOpen = true;
							fenceList.push(site);
							coords.push({
								lat: Number(site.latlng.lat),
								lng: Number(site.latlng.lng),
							});
						});
						setRouteMarkers(fenceList);
						setRouteLines(result.data.lines);

						if (
							coords.length > 0 &&
							(request.start !== lastRequest.start || request.end !== lastRequest.end)
						) {
							const coordinate: any = getCenter(coords);

							const bounds = boundsFromLatLngList(coords);
							var w = mapRef?.getDiv().offsetWidth ?? 0;
							var h = mapRef?.getDiv().offsetHeight ?? 0;
							let localZoom = getBoundsZoomLevel(bounds, {
								height: h,
								width: w,
							});
							if (localZoom > 19) {
								localZoom = 19;
							}
							setMapCenter({ lat: coordinate.latitude, lng: coordinate.longitude });
							setZoom(isNaN(localZoom) ? 19 : localZoom);
						}

						setLoading(false);
						setLastRequest(request);
						res(true);
					}
				})
				.catch((e) => {
					showToast('error');
					rej(e);
				});
		});
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			{loading && <Loading overlay={true} />}
			<Map
				mapStyle={subtleGreyScale}
				center={mapCenter}
				markers={[...routeMarkers, ...markers]}
				route={routeLines}
				zoom={zoom}
				controls={controls}
				onMapLoad={(map: google.maps.Map) => {
					setMapRef(map);
				}}
				onMapZoom={(zoom: number) => {
					setZoom(zoom);
				}}
			/>
		</div>
	);
};

export default RouteMapIndex;

import { useContext, useEffect, useRef, useState } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import axios from '../../../../lib/axios';
import RequestModal from '../modals/RequestModal';
import {
	EventType,
	HalfDayOptions,
	RequestAction,
	RequestData,
	RequestStatus,
} from './request-types';
import { DateTime } from 'luxon';
import { showToast } from '../../../../lib/toast';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import Loading from '../../../../components/UI/Loading';
import { HATContext } from '../HATProvider';
import { updateSubmenuBadgeCounts } from '../hat-functions';
import { getTimezone } from '../../../../lib/functions';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';
import ToolTip from './ToolTip';
import { handleSaveRequest } from './request-functions';
import { useHistory, useLocation } from 'react-router';

interface Props {
	componentMode: Array<RequestStatus>;
	usageMode?: 'workerCard' | undefined;
	usageModeId?: string | undefined;
	usageModeCallback?: Function | undefined;
	parentRef: any;
	tabRef: any;
}

const Requests: React.FC<Props> = (props: Props) => {
	const { state, dispatch } = useContext(HATContext);
	const moduleCtx = useContext<any>(moduleContext);
	const [presentAlert] = useIonAlert();
	const gridRef: any = useRef<any>();
	const approverFilterRef: any = useRef<any>();
	const workerFilterRef: any = useRef<any>();
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [gridTitle, setGridTitle] = useState<string>('');
	const [data, setData] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [approverFilter, setApproverFilter] = useState<any>(null);
	const [approvers, setApprovers] = useState<Array<any>>([]);
	const [workerFilter, setWorkerFilter] = useState<any>(null);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [requestId, setRequestId] = useState<string | null>('');
	const [curMonth, setCurMonth] = useState<number>(
		props.usageMode === 'workerCard' ? 13 : Number(DateTime.now().toFormat('MM'))
	);
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const permissionTo = usePermissionTo('workers.holidays_and_absences.requests');
	const highlightRowRules = {
		'danger-row': (params: any) => params.node.data.enum_status === RequestStatus.DECLINED,
	};
	const requestDataDefault: RequestData = {
		requestId: undefined,
		workerId: undefined,
		requestType: EventType.HOLIDAY,
		requestedDate: DateTime.now().startOf('day'),
		period: 1,
		status: RequestStatus.REQUESTED,
	};
	const [settingsLoading, setSettingsLoading] = useState<boolean>(
		!props.componentMode.includes(RequestStatus.REQUESTED)
	);
	const [workingHolidayYear, setWorkingHolidayYear] = useState<DateTime>(
		DateTime.now().set({ month: 1 })
	);
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	let history = useHistory();

	// Modals
	const [requestModal, setRequestModal] = useState<{ isOpen: boolean }>({ isOpen: false });
	const [requestModalData, setRequestModalData] = useState<RequestData>(requestDataDefault);

	// Build the columns
	let columns: Array<any> = [];

	if (props.usageMode !== 'workerCard') {
		columns = columns.concat([
			{
				field: 'attendees',
				headerName: 'Worker Name',
				getQuickFilterText: (params: any) => params.node.data.attendees[0].label,
				valueFormatter: (params: any) => params.node.data.attendees[0].label,
				cellClassRules: { ...highlightRowRules },
			},
		]);
	}

	columns = columns.concat([
		{
			field: 'type',
			cellClassRules: { ...highlightRowRules },
			flex: 1,
		},
	]);

	if (props.componentMode.includes(RequestStatus.REQUESTED)) {
		columns = columns.concat([
			{
				field: 'approver',
				headerName: 'Approver',
				valueFormatter: (params: any) => params.node.data.attendees[0].approver,
				cellClassRules: { ...highlightRowRules },
				flex: 1,
			},
		]);
	}

	columns = columns.concat([
		{
			field: 'requested_date',
			headerName: 'Requested',
			width: 130,
			suppressSizeToFit: true,
			sort: 'desc',
			valueFormatter: dateFormatter,
			cellClassRules: { ...highlightRowRules },
		},
		{
			field: 'start',
			width: 165,
			suppressSizeToFit: true,
			valueFormatter: (params: any) => dateFormatter(params, true),
			cellClassRules: { ...highlightRowRules },
		},
		{
			field: 'end',
			width: 165,
			suppressSizeToFit: true,
			valueFormatter: (params: any) => dateFormatter(params, true),
			cellClassRules: { ...highlightRowRules },
		},
		{
			field: 'period',
			cellRenderer: (params: any) =>
				`${params.node.data.period} working day${Number(params.node.data.period) !== 1 ? 's' : ''}`,
			cellClassRules: { ...highlightRowRules },
			flex: 1,
		},
	]);

	if (!props.componentMode.includes(RequestStatus.REQUESTED)) {
		columns = columns.concat([
			{
				field: 'decision_date',
				headerName: 'Approved Date',
				width: 130,
				suppressSizeToFit: true,
				cellRenderer: (params: any) => {
					if (params.node.data.enum_status === RequestStatus.APPROVED) {
						return DateTime.fromISO(params.node.data.decision_date).toFormat('dd/MM/yyyy');
					}
				},
				cellClassRules: { ...highlightRowRules },
			},
			{
				field: 'decision_date',
				headerName: 'Declined Date',
				width: 130,
				suppressSizeToFit: true,
				valueFormatter: dateFormatter,
				cellRenderer: (params: any) => {
					if (params.node.data.enum_status === RequestStatus.DECLINED) {
						return DateTime.fromISO(params.node.data.decision_date).toFormat('dd/MM/yyyy');
					}
				},
				cellClassRules: { ...highlightRowRules },
			},
			{
				field: 'decision_by',
				headerName: 'Decision',
				cellClassRules: { ...highlightRowRules },
				flex: 1,
			},
		]);
	}

	// Add tooltips to the columns if required
	if (!props.componentMode.includes(RequestStatus.REQUESTED)) {
		columns = columns.map((col: any) => {
			col.tooltipField = col.field;
			return col;
		});
	}

	useEffect(() => {
		if (!props.componentMode.includes(RequestStatus.REQUESTED)) {
			loadSettings();
			loadWorkers();
		} else if (props.componentMode.includes(RequestStatus.REQUESTED)) {
			loadApprovers();
		}
	}, []);

	useEffect(() => {
		if (props.usageModeCallback) props.usageModeCallback(curMonth, curYear);
	}, [curMonth, curYear]);

	useEffect(() => {
		setGridTitle('Requests - History');

		if (props.componentMode.includes(RequestStatus.REQUESTED)) setGridTitle('Requests');

		if (props.usageMode === 'workerCard') {
			let timeFrame: string = DateTime.now()
				.set({ day: 1, month: curMonth, year: curYear })
				.toFormat('LLLL yyyy');
			if (curMonth === 13) timeFrame = 'Annual';
			if (curYear === 0) timeFrame = 'All time';

			setGridTitle(`Holidays & Approved Absences - ${timeFrame}`);
		}
	}, [props.componentMode]);

	// Load the grid data once ready
	useEffect(() => {
		if (gridReady && !location.search) loadGridData(curMonth, curYear);
	}, [gridReady, workerFilter, approverFilter]);

	// Auto-approver filter
	useEffect(() => {
		if (gridReady && approvers.length > 0 && location && location.search) {
			setApproverFilter(String(urlParams.get('approver')));

			// Remove the query parameter
			urlParams.delete('approver');
			history.replace({
				search: urlParams.toString(),
			});
		}
	}, [gridReady, approvers]);

	// Open the modal if the modal's data contains a requestId
	useEffect(() => {
		if (requestModalData.requestId) setRequestModal({ isOpen: true });
	}, [requestModalData]);

	const loadSettings = () => {
		setSettingsLoading(true);
		moduleCtx
			.getSettings()
			.then((res: any) => {
				setWorkingHolidayYear(DateTime.fromISO(res.working_holiday_year));
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				setSettingsLoading(false);
			});
	};

	const loadApprovers = () => {
		moduleCtx.getWorkersList(null, false, false, true).then((res: any) => {
			setApprovers(res.map((item: any) => ({ label: item.name, value: item.id })));
		});
	};

	const loadWorkers = () => {
		moduleCtx.getWorkersOptions().then((res: any) => {
			setWorkers(res);
		});
	};

	const loadGridData = (month: number, year: number) => {
		setGridLoading(true);
		setRequestId(null);
		setData([]);

		gridRef.current.deselectAll();

		const payload: any = {
			event_types: [EventType.HOLIDAY, EventType.APPROVED_ABSENCE],
			status: props.componentMode,
			...getTimezone(),
		};

		// Include extra statuses
		if (props.usageMode === 'workerCard')
			payload.status = props.componentMode.concat(RequestStatus.REQUESTED);

		if (!props.componentMode.includes(RequestStatus.REQUESTED)) {
			payload.month = month;
			payload.year = year;
		}

		if (approverFilter) payload.approver_id = approverFilter;

		if (workerFilter) payload.worker_id = workerFilter;

		if (props.usageMode === 'workerCard') payload.worker_id = props.usageModeId;

		payload.worker_id = axios
			.post('/api/workers/holidays_and_absences/requests', payload)
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleCellClicked = (params: any) => {
		params.node.setSelected(true);
		setRequestId(params.data.request_id);
	};

	const handleCellDoubleClicked = (params: any) => {
		handleCellClicked(params);
		handleViewRecord();
	};

	const handleViewRecord = () => {
		if (permissionTo(['read', 'update'])) {
			setIsLoading(true);
			axios
				.get(`/api/workers/holidays_and_absences/requests/${requestId}`)
				.then((res: any) => {
					setRequestModalData({
						...requestDataDefault,
						requestId: requestId!,
						workerId: res.data.attendees[0].value,
						workerName: res.data.attendees[0].label,
						requestType: res.data.type,
						requestedDate: DateTime.fromISO(res.data.requested_date),
						start: DateTime.fromISO(res.data.start),
						end: DateTime.fromISO(res.data.end),
						period: res.data.period,
						status: res.data.enum_status,
						approver: res.data.decision_by,
						approvedDate: DateTime.fromISO(res.data.decision_date),
						clashes: res.data.clashes,
						decisionReason: res.data.decision_reason,
					});
				})
				.catch(() => {
					showToast('error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			showToast('permission');
			return;
		}
	};

	const handleDeleteRecord = (id: string | null | undefined, onSuccess: Function) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Request',
				message: 'Are you sure you want delete this request?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							let tmpRequestId: string | null | undefined = requestId;
							if (id) tmpRequestId = id;

							setIsLoading(true);
							setGridLoading(true);
							axios
								.delete(`/api/workers/holidays_and_absences/requests/${tmpRequestId}`)
								.then(() => {
									updateSubmenuBadgeCounts(dispatch);
									loadGridData(curMonth, curYear);
									onSuccess();
								})
								.catch(() => {
									showToast('error');
									setIsLoading(false);
									setGridLoading(false);
								})
								.finally(() => setIsLoading(false));
						},
					},
				],
			});
		}
	};

	const handleOnFilterChanged = () => {
		setRequestId(null);
		gridRef.current.deselectAll();
	};

	const handleCreateRecord = () => {
		if (permissionTo('create')) {
			setRequestModal({ isOpen: true });
			setRequestModalData(requestDataDefault);
		} else {
			showToast('permission');
			return;
		}
	};

	const handleApproveDeclineRequest = (data: RequestData, action: RequestAction) => {
		if (permissionTo('update')) {
			setIsLoading(true);

			// Close the modal
			setRequestModal((prevState: any) => ({ ...prevState, isOpen: false }));

			let payload: any = {};
			let msg: string = '';

			switch (action) {
				case RequestAction.APPROVE:
					payload.status = RequestStatus.APPROVED;
					msg = 'Your request was successfully approved';
					break;
				case RequestAction.DECLINE:
					payload.status = RequestStatus.DECLINED;
					payload.declined_reason = data.decisionReason;
					msg = 'Your request was successfully declined';
					break;
			}

			axios
				.put(`/api/workers/holidays_and_absences/requests/${data.requestId}/decision`, payload)
				.then(() => {
					showToast('success', msg);
					loadGridData(curMonth, curYear);
					updateSubmenuBadgeCounts(dispatch);
				})
				.catch((err: any) => {
					if (err.response.status === 422) {
						showToast('error', err.response.data.errors.join(', '));
					} else {
						showToast('error', null);
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			showToast('permission');
			return;
		}
	};

	const btnViewRequest = (
		<IonButton color='primary' disabled={!requestId} onClick={handleViewRecord}>
			View Request
		</IonButton>
	);

	const btnDeleteRequest = (
		<IonButton
			color='danger'
			disabled={!requestId}
			onClick={() => handleDeleteRecord(null, () => {})}
		>
			Delete Request
		</IonButton>
	);

	const gridControls = () => {
		let controls: any = {};

		if (!props.componentMode.includes(RequestStatus.REQUESTED)) {
			controls = {
				filterButtons: true,
				filterOptions: {
					monthStart: workingHolidayYear.month,
					displayCurrentMonth: loadGridData,
					defaultAnnual: props.usageMode === 'workerCard',
					rowSlot: props.usageMode === 'workerCard' && btnViewRequest,
				},
				toolTip: ToolTip,
			};

			return controls;
		}
	};

	const handleApproverFilter = (option: any) => {
		if (gridLoading === true) return;

		setGridLoading(true);
		if (!option) {
			setApproverFilter(null);
			return;
		}

		if (option.value.length > 0) {
			setApproverFilter(option.value);
		}
	};

	const handleClearApproverFilter = () => {
		if (approverFilterRef.current) {
			approverFilterRef.current.clearValue();
		}
	};

	const handleWorkerFilter = (option: any) => {
		setGridLoading(true);
		if (!option) {
			setWorkerFilter(null);
			return;
		}

		if (option.value.length > 0) {
			setWorkerFilter(option.value);
		}
	};

	const handleClearWorkerFilter = () => {
		if (workerFilterRef.current) {
			workerFilterRef.current.clearValue();
		}
	};

	return (
		<>
			{(settingsLoading || gridLoading || isLoading) && <Loading overlay={true} />}
			{!settingsLoading && (
				<DataGrid
					onGridReady={() => {
						setGridReady(true);
					}}
					ref={gridRef}
					title={gridTitle}
					cols={columns}
					data={data}
					useSearch={false}
					onFilterChanged={handleOnFilterChanged}
					cellClickedFunction={handleCellClicked}
					cellDoubleClickedFunction={handleCellDoubleClicked}
					rowCursorPointer={permissionTo('update')}
					{...gridControls()}
					extraFooter={
						props.usageMode !== 'workerCard' && (
							<IonRow className='mt-2'>
								<IonCol size='6' className='row-lower-controls-left ps-0'>
									<div className='row-lower-controls-container'>
										{props.componentMode.includes(RequestStatus.REQUESTED) && (
											<>
												<SelectStyled
													className='approver-filter'
													placeholder='Select a name to filter by approver...'
													forwardRef={approverFilterRef}
													options={approvers}
													onChange={(option: any) => handleApproverFilter(option)}
													value={approvers.filter((item: any) => item.value === approverFilter)}
												/>
												<IonButton
													color='secondary'
													disabled={!approverFilter || gridLoading}
													onClick={() => handleClearApproverFilter()}
												>
													Reset Approver
												</IonButton>
											</>
										)}
										{!props.componentMode.includes(RequestStatus.REQUESTED) && (
											<>
												<SelectStyled
													className='worker-filter'
													placeholder='Select a name to filter by worker...'
													forwardRef={workerFilterRef}
													options={workers}
													onChange={(option: any) => handleWorkerFilter(option)}
												/>
												<IonButton
													color='secondary'
													disabled={!workerFilter || gridLoading}
													onClick={() => handleClearWorkerFilter()}
												>
													Reset Worker
												</IonButton>
											</>
										)}
									</div>
								</IonCol>
								<IonCol size='6' className='row-lower-controls-right pe-0'>
									<div className='row-lower-controls-container text-right'>
										{props.componentMode.includes(RequestStatus.REQUESTED) &&
											permissionTo('delete') &&
											btnDeleteRequest}
										{btnViewRequest}
										{props.componentMode.includes(RequestStatus.REQUESTED) && (
											<IonButton color='success' onClick={handleCreateRecord}>
												Create New
											</IonButton>
										)}
									</div>
								</IonCol>
							</IonRow>
						)
					}
				/>
			)}

			<RequestModal
				isOpen={requestModal.isOpen}
				showLoading={isLoading}
				initialData={requestModalData}
				onClose={() => {
					setRequestModal((prevState: any) => ({ ...prevState, isOpen: false }));
					setRequestModalData(requestDataDefault);
				}}
				onSave={(data: RequestData, halfDayOptions: HalfDayOptions[]) => {
					handleSaveRequest(data, halfDayOptions, permissionTo, setIsLoading, () => {
						// On success
						loadGridData(curMonth, curYear);
						updateSubmenuBadgeCounts(dispatch);

						// Reset the modal
						setRequestModal({ isOpen: false });
						setRequestModalData(requestDataDefault);
					});
				}}
				onApproveDecline={(data: RequestData, mode: RequestAction) => {
					handleApproveDeclineRequest(data, mode);
				}}
				onDelete={(data: RequestData) => {
					handleDeleteRecord(data.requestId, () => {
						// Reset the modal
						setRequestModal({ isOpen: false });
						setRequestModalData(requestDataDefault);
					});
				}}
				permissionTo={permissionTo}
				usageMode={props.usageMode}
			/>
		</>
	);
};

export default Requests;

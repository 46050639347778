import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import DailyHourlyExceptionReport from './DailyHourlyExceptionReport';
import DailyHourlyTimesheets from './DailyHourlyTimesheets';
import WorkerExceptionsReport from './WorkerExceptionsReport';
import WorkerAllocationsReport from './WorkerAllocationsReport';

const TabsArray: any = () => {
	return [
		{
			title: 'Daily/Hourly Timesheets',
			page: DailyHourlyTimesheets,
			pageData: {
				pageId: 'tab-ts-daily-hourly-timesheets',
				permissionTo: usePermissionTo('timesheets.timesheets_and_allocations'),
			},
			active: true,
		},
		{
			title: 'Daily/Hourly Exception Report',
			page: DailyHourlyExceptionReport,
			pageData: {
				pageId: 'tab-ts-daily-hourly-exception-report',
				permissionTo: usePermissionTo('timesheets.timesheets_and_allocations'),
			},
			active: false,
		},
		{
			title: 'Worker Exceptions Report',
			page: WorkerExceptionsReport,
			pageData: {
				pageId: 'tab-ts-worker-exceptions-report',
				permissionTo: usePermissionTo('timesheets.timesheets_and_allocations'),
			},
			active: false,
		},
		{
			title: 'Worker Allocations Report',
			page: WorkerAllocationsReport,
			pageData: {
				pageId: 'tab-ts-worker-allocations-report',
				permissionTo: usePermissionTo('timesheets.timesheets_and_allocations'),
			},
			active: false,
		},
	];
};

export default TabsArray;

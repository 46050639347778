//expects (e.target, checkValidXYZ(e.target.value), true/false)
export const validateField = (target: any, validateFunction: boolean, useParent: boolean) => {
	let element = target;
	if (useParent === true) {
		switch (target.nodeName.toLowerCase()) {
			case 'textarea':
				element = target.closest('ion-textarea');
				break;
			default:
				element = target.closest('ion-input');
				if (element === null) {
					element = target.closest('.fake-ion-input');
				}
				break;
		}
	}
	let result = validateFunction;
	if (result === true) {
		element.classList.remove('is-invalid');
	} else {
		element.classList.add('is-invalid');
	}

	if (target.value.length === 0) {
		element.classList.remove('is-invalid');
	}

	return result;
};

export const checkValidEmail = (email: string) => {
	let valid = new RegExp(/^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/gim);
	let result = valid.test(email);

	return result;
};

export const checkValidPostcode = (postcode: string) => {
	let valid = new RegExp(/^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/gim);
	let result = valid.test(postcode);

	return result;
};

export const checkPresent = (text: string) => {
	let result = false;
	text = text.trim();

	if (text.length > 0) {
		result = true;
	}

	return result;
};

export const checkValidDateUK = (date: string) => {
	let valid = new RegExp(
		/^(([0-9])|([0-2][0-9])|(3[0-1]))\/(([1-9])|(0[1-9])|(1[0-2]))\/(([0-9][0-9])|([1-2][0,9][0-9][0-9]))$/
	);
	let result = valid.test(date);

	return result;
};

export const checkValidDateISO = (date: string) => {
	let valid = new RegExp(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/gm);
	let result = valid.test(date);

	return result;
};

export const checkValidPassword = (pw: string) => {
	/**
	 * Rules:
	 * Minimum of 8 characters
	 * Must contain a symbol
	 * Must contain upper and lower case letters
	 * Must contain a number
	 */
	let valid = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/gm);
	let result = valid.test(pw);

	return result;
};

export const mandatoryFieldChecks = (formData: any) => {
	let formValid = true;
	let errorMessage = '';

	// Mandatory field checks
	for (let i in formData) {
		let f = formData[i];

		if (f.hasOwnProperty('db') && f.isMandatory === true) {
			let val = null;

			// Get the value
			switch (f.type) {
				case 'dropdown':
				case 'dropdownAsync':
				case 'autocomplete':
					// Get value based on ref or array value
					if (f.ref && f.ref.current) {
						val = f.ref.current.getValue();
					} else {
						if (f.hasOwnProperty('value')) {
							// This key was set when changing values on-form
							val = f.value;
						} else if (f.hasOwnProperty('defaultValue')) {
							// This key was set when loading the form from fresh
							val = f.defaultValue;
						}
					}

					if (val && Array.isArray(val) && val.length > 0) val = val[0].value;
					break;
				default:
					// Get value based on ref or array value
					if (f.ref && f.ref.current) {
						val = f.ref.current.value;
					} else {
						val = f.value;
					}
					break;
			}

			// Check if the value is empty
			if (val === undefined || val === null) formValid = false;

			// Check if the value is an empty array
			if (Array.isArray(val) && val.length === 0) formValid = false;

			// Check the value length
			if (formValid) {
				if (
					f.type !== 'number' &&
					f.type !== 'boundary' &&
					typeof val !== 'boolean' &&
					val.trim().length === 0
				) {
					formValid = false;
				}
			}

			// Specific checks
			switch (f.type) {
				case 'password':
					if (!checkValidPassword(val)) {
						formValid = false;
						errorMessage = 'Password is not valid';
					}
					break;
			}

			if (!formValid) {
				if (errorMessage.length === 0) errorMessage = `Mandatory field missing: ${f.title}`;
				break;
			}
		}
	}

	// Missing field data
	if (!formValid) {
		if (errorMessage.length === 0) errorMessage = 'Please fill-in all mandatory fields(*)';
	}

	// General validation
	if (formValid) {
		if (document.querySelectorAll('.is-invalid').length > 0) {
			formValid = false;
			errorMessage = 'Please check all fields are valid';
		}
	}

	return { formValid, errorMessage };
};

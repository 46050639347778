export const customSelectStyles = {
	control: (base: any) => ({
		...base,
		height: 34,
		minHeight: 34,
		marginTop: -1,
		borderColor: '#9ea0aa',
	}),
	valueContainer: (base: any) => ({
		...base,
		marginTop: '-2px',
		marginLeft: '-4px',
	}),
	indicatorsContainer: (base: any) => ({
		...base,
		marginTop: '-2px',
	}),
};

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonCol,
	IonFooter,
	IonRow,
} from '@ionic/react';
import { useState } from 'react';
import Loading from '../../../../components/UI/Loading';
import React from 'react';
import Form from '../../../../components/Forms/Form';
import useFormFieldsArray from './formFieldsArray';
import { db2Form } from '../../../../api/forms';

interface ItemModalProps {
	isOpen: boolean;
	itemId: string;
	itemName: string;
	itemData: any;
	isLoading: boolean;
	onSave: Function;
	onClose: Function;
	onSuccess: Function;
	permissionTo: Function;
}

const ItemModal: React.FC<ItemModalProps> = ({
	isOpen,
	itemId,
	itemName,
	itemData,
	isLoading,
	onSave,
	onClose,
	onSuccess,
	permissionTo,
}) => {
	const [items, setItems] = useState<Array<any>>([]);
	const isNew: boolean = itemId === '';

	const formDataArray = useFormFieldsArray();

	const loadForm = () => {
		let formData: any = formDataArray.formFields;

		// Form setup
		formData.map((fd: any) => {
			return fd;
		});

		if (isNew) {
			setItems(formData);
		} else {
			formData = db2Form(formData, itemData);
			setItems(formData);
		}
	};

	const handleOnPresent = () => {
		loadForm();
	};

	const handleOnClose = () => {
		setItems([]);
		onClose();
	};

	return (
		<IonModal
			style={{ '--width': '60vw', '--height': '650px', '--min-height': '450px' }}
			isOpen={isOpen}
			onDidPresent={handleOnPresent}
			onDidDismiss={handleOnClose}
			className='prop-form'
		>
			{isLoading && <Loading overlay={true} />}
			<IonHeader>
				<IonToolbar>
					<IonTitle>{isNew ? 'Add Office' : `Editing ${itemName ? itemName : '...'}`}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{!isLoading && items && items.length > 0 && (
					<Form
						array={items}
						permissionTo={permissionTo}
						forceEdit={permissionTo('update')}
						noButton={true}
						formSkipServerUpdate={true}
					/>
				)}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
							<IonButton color='success' onClick={() => onSave(isNew, items)}>
								{isNew ? 'Add Office' : 'Save Changes'}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default ItemModal;

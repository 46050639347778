import { TimelineDotProps } from '@mui/lab';

export enum JobStatus {
	IN_PROGRESS = 'in-progress',
	SUSPENDED = 'suspended',
	COMPLETED = 'completed',
	ALL = 'all',
}

export const timelineItemTypes = [
	'invoice',
	'estimate',
	'message',
	'client_media',
	'job',
	'appointment',
] as const;

export type TimelineItemType = (typeof timelineItemTypes)[number];

export type TimelineDataItem = {
	colour: TimelineDotProps['color'];
	date: string;
	description: string;
	id: string;
	link_type: TimelineItemType;
	palette: string;
	type: string;
	worker_name: string;
	isEnd?: boolean;
	file?: {
		file_type: string;
		file_name: string;
		thumb: string;
		path: string;
		created_at: string;
		description: string;
		town: string;
		postcode: string;
	};
};

export type WorkerTimesheetsTabs = 'allocation_history' | 'cumulative_cost';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
} from '@ionic/react';
import { Ref, useEffect, useRef, useState } from 'react';
import Loading from '../../UI/Loading';
import Form from '../../Forms/Form';
import useSettingsFormArray from '../settingsFormArray';
import { db2Form } from '../../../api/forms';
import axios from '../../../lib/axios';

interface NotificationsModalProps {
	isOpen: boolean;
	onClose: Function;
}

const SettingsModal: React.FC<NotificationsModalProps> = (props) => {
	const ionRef: any = useRef<Ref<HTMLIonModalElement>>();
	const [isLoading, setIsLoading] = useState(false);
	const [items, setItems] = useState<Array<any>>([]);
	const formDataArray = useSettingsFormArray();
	const endpoint = '/api/settings';
	const reloadWidgetsEvent = new Event('reloadWidgets', { bubbles: true, cancelable: false });

	useEffect(() => {
		if (props.isOpen) loadForm();
	}, [props.isOpen]);

	const loadForm = () => {
		let formData: any = formDataArray.settings;

		// Form setup
		formData.map((fd: any) => {
			return fd;
		});

		// Get the existing worker's data
		setIsLoading(true);
		axios
			.get(`${endpoint}`)
			.then((res) => res.data)
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<IonModal
			ref={ionRef}
			style={{ '--width': '89vw', '--height': '96vh' }}
			isOpen={props.isOpen}
			onDidDismiss={() => props.onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Settings</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => ionRef.current.dismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{isLoading && <Loading />}
				{!isLoading && items && items.length > 0 && (
					<Form
						method='POST'
						array={items}
						forceEdit={true}
						permissionTo={() => {
							return true;
						}}
						noButton={true}
						endpoint={endpoint}
						onChangeCallback={(res: any) => {
							if (res.data[res.index].id === 'widgets') {
								document.dispatchEvent(reloadWidgetsEvent);
							}
						}}
					/>
				)}
			</IonContent>
		</IonModal>
	);
};

export default SettingsModal;

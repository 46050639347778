import { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import SelectStyled from '../components/UI/SelectStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
	isOpen: boolean;
	initialData: any;
	onClose: Function;
	onSave: Function;
	onDidDismiss: Function;
	permissionTo: Function;
}

const ReplaceWithModal: React.FC<Props> = ({
	isOpen,
	initialData,
	onClose,
	onSave,
	onDidDismiss,
	permissionTo
}) => {
	const [withId, setWithId] = useState<string>('');

	const handleWorkerChanged = (e: any) => {
		setWithId(e.value);
	}

	return (
		<IonModal isOpen={isOpen} onDidDismiss={() => onDidDismiss()} className="prop-form" style={{ '--height': '256px' }}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Management Structure Replacement</IonTitle>
					<IonButtons slot="end" className="ion-modal-buttons">
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<p>
					Replace <b>{initialData.replaceName}</b> with:
				</p>
				<SelectStyled options={initialData.workersOptions} onChange={handleWorkerChanged} />
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size={'12'} className="text-right">
							<IonButton color="secondary" onClick={() => onClose()}>
								Cancel
							</IonButton>
							{permissionTo('update') && (
								<IonButton color="primary" onClick={() => onSave(initialData.replaceId, withId)} disabled={!withId}>
									Save
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
}

export default ReplaceWithModal;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { Ref, useContext, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Map from '../Maps/Map';
import axios from '../../lib/axios';
import { mapLocations } from '../Maps/MapLocations';
import { faMapMarker } from '../Icons/faMapMarker';
import { Coordinates, PropMarker } from '../../interfaces/Maps/MapsInterface';

import './Toastify.scss';
import { subtleGreyScale } from '../Maps/MapStyles';
import { PhoneLabel } from '../Forms/PhoneLabel';
import { webSocketContext } from '../../contexts/WebSocketContext';
import { useSubscribe } from '../../helpers/events';

interface Props {
	worker: any,
}

const WorkerAlarm: React.FC<Props> = (props: Props) => {
	const ref: any  = useRef<Ref<HTMLIonModalElement>>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [markerPosition, setMarkerPosition] = useState<Array<PropMarker>>([]);
	const [mapCenter, setMapCenter] = useState({ lat: 51.96217, lng: -0.265839 });
	const webSocketCtx: any = useContext(webSocketContext);

	const viewMap = async() => {
		const response: any = await axios.get(
			'/api/workers/location/' + props.worker._id,
		);
		let event = response.data;
		const w3w: any = await axios.get(
			'/api/workers/w3w/' + event.location.coordinates[1] + '/' + event.location.coordinates[0]
		);
		if (event) {
			let latlng: Coordinates = {
				lng: event.location.coordinates[0],
				lat: event.location.coordinates[1],
			};
			let radius = event.accuracy;
			let localMarkers = await mapLocations();
			let obj: PropMarker = {
				_id: props.worker._id,
				latlng,
				radius,
				showRadius: true,
				title: w3w.data[0].words,
				canOpen: true,
				isOpen: false,
				date: DateTime.fromISO(event.datetime),
			}
			localMarkers.markers.push(obj);
			
			setMarkerPosition(localMarkers.markers);
			
			setMapCenter(latlng);
			setIsOpen(true);
		}
	};

	useSubscribe(
		'GPS:workerUpdate',
		(data: any) => {
			if (data.item._id === props.worker.worker_id) {
				let localMarkers = [...markerPosition];
				if (localMarkers.length > 0) {
					let markerIndex = localMarkers.findIndex((marker: PropMarker, index: number) => {
						return marker._id === props.worker._id;
					});

					if (markerIndex > -1) {
						let worker = data.item;
						axios.get(
							'/api/workers/w3w/' + localMarkers[markerIndex].latlng.lat + '/' + localMarkers[markerIndex].latlng.lng
						).then((w3w: any) => {
							localMarkers[markerIndex].title = w3w.data[0].words;
							localMarkers[markerIndex].date = DateTime.fromISO(worker.datetime);
							localMarkers[markerIndex].radius = worker.accuracy;
							localMarkers[markerIndex].latlng = {
								lat: worker.location.coordinates[1],
								lng: worker.location.coordinates[0],
							};
							setMarkerPosition(localMarkers);
						}).catch((e: any) => {
							localMarkers[markerIndex].title = worker.location.coordinates[1] + ', ' + worker.location.coordinates[0];
							localMarkers[markerIndex].date = DateTime.fromISO(worker.datetime);
							localMarkers[markerIndex].radius = worker.accuracy;
							localMarkers[markerIndex].latlng = {
								lat: worker.location.coordinates[1],
								lng: worker.location.coordinates[0],
							};
							setMarkerPosition(localMarkers);
						});
					}

				}
			}
		},
		[markerPosition]
	);

	useEffect(() => {
		if (webSocketCtx.isConnected && isOpen) {
			webSocketCtx.sendMessage(
				JSON.stringify({
					type: 'User:locationUpdates',
					data: true,
				})
			);
		} else {
			webSocketCtx.sendMessage(
				JSON.stringify({
					type: 'User:locationUpdates',
					data: false,
				})
			);
		}
	}, [webSocketCtx.isConnected, isOpen]);

	return (
		<>
			<div>
				<IonToolbar color={'danger'}>
					<h2>Worker In Danger</h2>
					<IonButtons slot="end">
						<IonButton
							onClick={() => viewMap()}
							fill='clear'
							className='icon-only'
						>
							<FontAwesomeIcon icon={faMapMarker} className='button-icon' />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonRow>
					<IonCol className="p-0" size={'5'} sizeMd={'4'} sizeLg={'3'}>
						Name:
					</IonCol>
					<IonCol className="p-0" size={'7'} sizeMd={'8'} sizeLg={'9'}>
						{props.worker.first_name} {props.worker.last_name}
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol className="p-0" size={'5'} sizeMd={'4'} sizeLg={'3'}>
						Mobile:
					</IonCol>
					<IonCol className="p-0" size={'7'} sizeMd={'8'} sizeLg={'9'}>
						<PhoneLabel value={props.worker.mobile} />
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol className="p-0" size={'5'} sizeMd={'4'} sizeLg={'3'}>
						Time:
					</IonCol>
					<IonCol className="p-0" size={'7'} sizeMd={'8'} sizeLg={'9'}>
						{DateTime.fromISO(props.worker.alarm_at).toLocaleString()} at {DateTime.fromISO(props.worker.alarm_at).toFormat('HH:mm')}
					</IonCol>
				</IonRow>
			</div>
			<IonModal
				ref={ref}
				style={{ '--width': '60vw', '--height': '70vh' }}
				isOpen={isOpen}
				className='prop-form'
				onDidDismiss={() => {
					setIsOpen(false);
				}}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{`Coordinate`}</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton
								onClick={() => {
									setIsOpen(false);
								}}
							>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className='flex-grow-container' style={{ height: '100%' }}>
						<div style={{ flex: 1, overflow: 'hidden' }}>
							<Map
								mapStyle={subtleGreyScale} center={mapCenter} markers={markerPosition} />
						</div>
					</div>
				</IonContent>
			</IonModal>
		</>
	);
};

export default WorkerAlarm;

import { useGoogleMap } from '@react-google-maps/api';
import { useEffect, useRef } from 'react';

import { MapControlProps } from '../../interfaces/Maps/MapsInterface';

const MapControl = (props: React.PropsWithChildren<MapControlProps>) => {
	const map = useGoogleMap();
	const ref = useRef<any>();

	useEffect(() => {
		if (map && ref) {
			map.controls[window.google.maps.ControlPosition[props.position]].push(ref.current);
		}
	}, [map, ref]);

	return <div ref={ref} style={props.style}>{props.children}</div>;
};

export default MapControl;

import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { defaultStylesArray } from './arrays/defaultStylesArray';
import Form from '../../../../../components/Forms/Form';
import Loading from '../../../../../components/UI/Loading';
import axios from '../../../../../lib/axios';
import axiosMP from '../../../../../lib/axiosMp';
import { showToast } from '../../../../../lib/toast';
import { AxiosProgressEvent } from 'axios';
import { db2Form } from '../../../../../api/forms';

interface Props {
	uid: string;
	permissionTo: Function;
}

const DefaultStyles = (props: Props) => {
	const [items, setItems] = useState<Array<any>>([]);
	const [endpointID, setEndpointID] = useState<string>('');
	const [loadingOverlay, setLoadingOverlay] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const endPoint = '/api/utilities/styles';
	const toastId = useRef<any>(null);

	// Create a reference to the endpointID state for use within callback functions
	const endpointIDRef = useRef<any>();
	endpointIDRef.current = endpointID;

	useEffect(() => {
		setIsLoading(true);
		let formData: any = defaultStylesArray;

		// Apply the image upload handlers
		formData.map((fd: any) => {
			if (fd.id === 'logo' || fd.id === 'image_header' || fd.id === 'image_footer') {
				fd.handleImageUpload = handleImageUpload;
			}
			return fd;
		});

		// Initial data fetch
		axios
			.get(endPoint)
			.then((res) => res.data[0])
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
				setEndpointID(dbData ? dbData._id : '');
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
				setLoadingOverlay(true);
			});
	}, []);

	const onFormChange = (data: any) => {
		// Set the endpoint ID from the form update return data
		if (!endpointID) {
			setEndpointID(data.result.returnData.data._id);
		}
	};

	// TODO: put this in the Form object so its re-usable
	const handleImageUpload = (e: any, imageId: string) => {
		const file = e.target.files[0];

		let fd = new FormData();
		fd.append('_method', 'PUT');
		fd.append(imageId, file);

		// Reset
		toastId.current = null;

		setIsLoading(true);

		axiosMP
			.post(endPoint + (endpointIDRef.current ? '/' + endpointIDRef.current : ''), fd, {
				onUploadProgress: (p: AxiosProgressEvent) => {
					const progress = p.loaded / (p.total ?? 0);

					if (toastId.current === null) {
						toastId.current = toast('Uploading image...', { type: toast.TYPE.INFO });
					} else {
						toast.update(toastId.current, {
							progress,
							render: 'Uploading image (' + Math.floor(progress * 100) + '%)...',
						});
					}
				},
			})
			.then((res: any) => {
				// Set the endpoint ID from the form update return data
				if (!endpointIDRef.current) {
					setEndpointID(res.data._id);
				}
				showToast('success');
			})
			.catch((err: any) => {
				showToast('error');
				console.error(err);
			})
			.finally(() => {
				toast.dismiss(toastId.current);
				setIsLoading(false);
			});
	};

	return (
		<div className={`component-${props.uid.replaceAll('.', '-')}`}>
			{isLoading && <Loading overlay={loadingOverlay} />}
			{items && items.length > 0 && (
				<Form
					array={items}
					noButton={true}
					forceEdit={props.permissionTo('update')}
					permissionTo={props.permissionTo}
					endpoint={endPoint}
					endpointID={endpointID}
					onChangeCallback={onFormChange}
				/>
			)}
		</div>
	);
};

export default DefaultStyles;

import { useContext, useEffect, useRef, useState } from 'react';
import { authContext } from '../../contexts/AuthContext';

const useSettingsFormArray = () => {
	const authCtx: any = useContext(authContext);
	const [myWidgetsOptions, setMyWidgetsOptions] = useState<Array<any>>([]);

	// Build the my-widgets options
	useEffect(() => {
		let myWidgetsValues: Array<any> = [];

		// Gather the classes of widgets the user can see
		if (authCtx.user.security.dashboard) {
			Object.entries(authCtx.user.security.dashboard).forEach(([classId, classData]: any) => {
				if (classData.hasOwnProperty('read') && classData.read === true) {
					// Using the widget class, gather widgets this user is allowed to see
					Object.entries(authCtx.user.security.dashboard[classId]).forEach(
						([widgetId, widgetData]: any) => {
							if (widgetData.hasOwnProperty('read') && widgetData.read === true) {
								// Get widget name from nav data
								const widgetLabel: string = authCtx.navData
									.filter((data: any) => data.security_key === 'dashboard')[0]
									.items.filter((item: any) => item.security_key === classId)[0]
									.items.filter((item: any) => item.security_key === widgetId)[0].item_name;

								// Push option
								myWidgetsValues.push({
									label: widgetLabel,
									value: `dashboard.${classId}.${widgetId}`,
								});
							}
						}
					);
				}
			});
		}

		setMyWidgetsOptions(myWidgetsValues);
	}, []);

	let settings: Array<any> = [
		{
			title: 'Two Factor Authentication',
			type: 'title',
			style: 'alpha',
		},
		{
			id: '2fa_method',
			title: '2FA Method',
			type: 'dropdown',
			ref: useRef(),
			isMulti: true,
			values: [
				{
					value: 'sms',
					label: 'SMS',
				},
				{
					value: 'mail',
					label: 'E-Mail',
				},
			],
			onChangeCallback: null,
			db: ['twofa_settings'],
		},
		{
			title: 'Notifications',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'notifications_method',
			title: 'Notifications Method',
			type: 'dropdown',
			ref: useRef(),
			isMulti: true,
			values: [
				{
					value: 'sms',
					label: 'SMS',
				},
				{
					value: 'mail',
					label: 'E-Mail',
				},
				{
					value: 'firebase',
					label: 'Push Notifications',
				},
			],
			onChangeCallback: null,
			db: ['notification_settings'],
		},
		{
			title: 'Dashboard',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'widgets',
			title: 'My Widgets',
			type: 'dropdown',
			ref: useRef(),
			isMulti: true,
			values: myWidgetsOptions,
			onChangeCallback: null,
			db: ['widgets'],
		},
	];

	return { settings };
};

export default useSettingsFormArray;

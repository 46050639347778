export const keyUserDetails: Array<any> = [
	{
		title: "Key User",
		type: "title",
	},
	{
		title: "First Name",
		type: "text",
		db: ["keyuser", "first_name"]
	},
	{
		title: "Last Name",
		type: "text",
		db: ["keyuser", "last_name"]
	},
	{
		title: "Email Address",
		type: "email",
		db: ["keyuser", "email"]
	},
	{
		title: "Mobile Number",
		type: "phone",
		db: ["keyuser", "mobile"]
	},
];

import { DateTime } from 'luxon';
import { useState, useEffect, useRef } from 'react';
import { IonCol, IonRow } from '@ionic/react';
import SelectStyled from '../UI/SelectStyled';
import { getYearSelectOptions } from '../../lib/functions';
import RadioButtons from '../UI/RadioButtons/RadioButtons';

interface Props {
	gridIsReady?: boolean;
	monthStart?: number;
	selectOptions?: Array<{ label: string; value: number }>;
	displayAnnual?: boolean;
	displayAllTime?: boolean;
	displaySelect?: boolean;
	rowSlot?: any;
	defaultAnnual?: boolean;
	defaultAllTime?: boolean;
	initialLoad?: boolean;
	displayCurrentMonth?: Function;
	forceDate?: string;
}

const FilterButtons: React.FC<Props> = (props: Props) => {
	const yearSelectRef: any = useRef<any>(null);
	const monthStart: number = props.monthStart ? Number(props.monthStart) : 1;
	const offsetMode: boolean = monthStart !== 1;
	const [curYear, setCurYear] = useState<number>(DateTime.now().startOf('day').year);
	const yearSelectOptions = props.selectOptions
		? props.selectOptions
		: getYearSelectOptions({ offsetMode });

	// Default button selection
	let btnSelDefault = DateTime.now().startOf('day').toFormat('MM-yyyy');
	if (props.defaultAnnual) btnSelDefault = `13-${curYear}`;
	if (props.defaultAllTime) btnSelDefault = `00-0000`;
	const [btnSel, setBtnSel] = useState<Array<any>>(
		!props.forceDate ? [btnSelDefault] : [props.forceDate]
	);

	// This is used to force-set the buttons and year dropdown from outside this component
	useEffect(() => {
		if (props.forceDate) {
			setBtnSel([props.forceDate]);
			setCurYear(Number(props.forceDate.split('-')[1]));
		}
	}, [props.forceDate]);

	useEffect(() => {
		// If our default month is before monthStart, then take
		// one year off the curYear to maintain the offset year split
		if (!props.forceDate) {
			setCurYear(() =>
				monthStart > 1 && DateTime.now().month < monthStart
					? DateTime.now().year - 1
					: DateTime.now().year
			);
		}
	}, [props.monthStart]);

	// Initial load checks
	useEffect(() => {
		if (props.initialLoad === true && props.gridIsReady === true) {
			const selDate: Array<number> = btnSel[0].split('-').map((d: number) => Number(d));
			if (props.displayCurrentMonth) props.displayCurrentMonth(selDate[0], selDate[1]);
		}
	}, [props.initialLoad, props.gridIsReady]);

	const selMonth = (e: Array<any>) => {
		// Highlight the button
		setBtnSel(e);

		// Split out the month and year
		const selDate: Array<number> = e[0].split('-').map((d: number) => Number(d));

		// Make request
		if (props.displayCurrentMonth) props.displayCurrentMonth(selDate[0], selDate[1]);
	};

	const selYear = (newValue: any) => {
		setCurYear(() => {
			// Get the currently selected button to inform the new year
			const selDate: Array<number> = btnSel[0].split('-').map((d: number) => Number(d));
			const theMonth: number = selDate[0];
			let newYear: number = selDate[1];

			// The all-time button should not change the year
			if (btnSel[0] !== '00-0000') {
				newYear = newValue.value;

				// Offset mode behaviours
				if (offsetMode && theMonth < monthStart) newYear += 1;

				setBtnSel((prevValue) => {
					const selDate: Array<number> = prevValue[0].split('-').map((d: any) => d);
					return [selDate[0] + '-' + newYear];
				});
			}

			// Make request
			if (props.displayCurrentMonth) props.displayCurrentMonth(theMonth, newYear);

			return newValue.value;
		});
	};

	const buildButtons = (theYear: number) => {
		let buttons: Array<any> = [];

		for (let i = 0; i < 12; i++) {
			let buttonDate: DateTime = DateTime.now()
				.set({ day: 1, month: i + 1 - (13 - monthStart), year: theYear + 1 })
				.startOf('day');
			let monthName = buttonDate.toFormat('LLL').substring(0, 3);
			buttons.push({
				label: monthName,
				value: buttonDate.toFormat('MM-yyyy'),
			});
		}

		if (props.displayAnnual !== false) {
			buttons.push({
				label: 'Annual',
				value: `13-${theYear}`,
			});
		}

		if (props.displayAllTime !== false) {
			buttons.push({
				label: 'All Time',
				value: `00-0000`,
			});
		}

		return buttons;
	};

	return (
		<>
			<IonRow className='data-table-buttons'>
				<IonCol className='d-flex flex-grow-0 align-items-end ps-0'>
					<RadioButtons options={buildButtons(curYear)} onChange={selMonth} value={btnSel} />
				</IonCol>
				<IonCol className='d-flex flex-grow-1 align-items-end w-100'>
					<SelectStyled
						id='select-year'
						className={offsetMode ? 'offset-mode' : ''}
						isSearchable={false}
						forwardRef={yearSelectRef}
						options={yearSelectOptions}
						value={yearSelectOptions.filter((option: any) => Number(option.value) === curYear)}
						onChange={selYear}
						isDisabled={btnSel[0] === '00-0000'}
					/>
				</IonCol>
				{props.rowSlot && (
					<IonCol className='row-slot pe-0'>{props.rowSlot && props.rowSlot}</IonCol>
				)}
			</IonRow>
		</>
	);
};

export default FilterButtons;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { faWorkers, faJobs } from '../../../components/Icons/custom-icons.module';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { AutoComplete } from '../../../components/Forms/AutoComplete';
import { WorkersOptions, JobsOptions } from '../../../utils/autocomplete';
import { useState } from 'react';
import { showToast } from '../../../lib/toast';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const GoTo: React.FC<Props> = (props) => {
	const [selectedItem, setSelectedItem] = useState<string>('');
	let history = useHistory();
	let wgtTitleWord = '';

	// Widget mode
	switch (props.widgetId) {
		case 'dashboard.features.go_to_worker':
			wgtTitleWord = 'Worker';
			break;
		case 'dashboard.features.go_to_job':
			wgtTitleWord = 'Job';
			break;
	}

	const handleDropdownChange = (e: any) => {
		setSelectedItem(e.value);
	};

	const handleGoTo = () => {
		if (!selectedItem) {
			showToast('error', `Please first select a ${wgtTitleWord}`);
			return;
		}

		switch (props.widgetId) {
			case 'dashboard.features.go_to_worker':
				history.push(`/workers/workers_list/worker_card/${selectedItem}`);
				break;
			case 'dashboard.features.go_to_job':
				history.push(`/jobs/jobs_list/job_card/${selectedItem}`);
				break;
		}
	};

	return (
		<>
			<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
				<div className='widget-header'>Go To {wgtTitleWord}</div>
				<div className='widget-content'>
					<div className='icon-container'>
						{props.widgetId === 'dashboard.features.go_to_worker' && (
							<FontAwesomeIcon icon={faWorkers as IconDefinition} />
						)}
						{props.widgetId === 'dashboard.features.go_to_job' && (
							<FontAwesomeIcon icon={faJobs as IconDefinition} />
						)}
					</div>
					<div className='dropdown-container'>
						{props.widgetId === 'dashboard.features.go_to_worker' && (
							<AutoComplete
								onChangeCallback={handleDropdownChange}
								placeholder={`Type a worker name...`}
								loadOptions={(inputValue: any, callback: any) => {
									WorkersOptions(inputValue, callback);
								}}
								isMulti={false}
								isClearable={true}
								defaultOptions={true}
								useLabels={false}
								editMode={true}
							/>
						)}
						{props.widgetId === 'dashboard.features.go_to_job' && (
							<AutoComplete
								onChangeCallback={handleDropdownChange}
								placeholder={`Type a job name...`}
								loadOptions={(inputValue: any, callback: any) => {
									JobsOptions(inputValue, callback, true);
								}}
								isMulti={false}
								isClearable={true}
								defaultOptions={true}
								useLabels={false}
								editMode={true}
							/>
						)}
					</div>
				</div>
				<div className='widget-footer justify-content-end'>
					<IonButton size='small' onClick={handleGoTo}>
						Go To {wgtTitleWord}
					</IonButton>
				</div>
			</div>
		</>
	);
};

export default GoTo;

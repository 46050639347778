import { useState } from 'react';
import { useHistory } from 'react-router';
import axios from '../../lib/axios';
import { csrf } from '../../helpers/csrf';
import ErrorMessage from '../../components/Auth/ErrorMessage';
import useErrorHandler from '../../utils/custom-hooks/ErrorHandler';
import AuthButton from '../../components/Forms/AuthButton';
import { Logos, AuthStyles } from '../../components/Auth/Styling';
import AuthFooter from '../../components/Auth/AuthFooter';

interface Props {
	location?: any;
	match?: any;
}

const PasswordReset: React.FC<Props> = (props: Props) => {
	const [userPassword, setUserPassword] = useState('');
	const [userPasswordConfirm, setUserPasswordConfirm] = useState('');
	const [newPasswordSuccess, setNewPasswordSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const { error, showError } = useErrorHandler(null);

	// Get the email and token from the URL
	const queryString = new URLSearchParams(props.location.search);
	const userEmail = queryString.get('email');
	const token = props.match.params[0];

	let history = useHistory();

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		// Check for empty entries
		if (userPassword.length === 0 || userPasswordConfirm.length === 0) {
			showError('Please fill-in all password fields.');
			return false;
		}

		// Check passwords match
		if (userPassword !== userPasswordConfirm) {
			showError('Please ensure your passwords match.');
			return false;
		}

		try {
			setLoading(true);

			await csrf();

			await axios.post('/reset-password', {
				token: token,
				email: userEmail,
				password: userPassword,
				password_confirmation: userPasswordConfirm,
			});

			setNewPasswordSuccess(true);
		} catch (err: any) {
			setNewPasswordSuccess(false);
			showError(err.response.data.message);
		} finally {
			setLoading(false);
		}
	};

	const handleGoToLogin = () => {
		setLoading(true);
		window.location.href = '/';
	};

	return (
		<>
			<AuthStyles />
			<form className="form-auth" onSubmit={handleSubmit}>
				<div className="form-inner rounded-3 card">
					<div className="card-body p-md-5 mx-md-4">
						<Logos />

						<div className="text-center mb-4">Password Reset</div>

						{!newPasswordSuccess && (
							<>
								<div className="form-group mb-4">
									<label htmlFor="password" className="form-label">
										New Password
									</label>
									<input
										type="password"
										id="password"
										className="form-control"
										placeholder="Enter a new password"
										disabled={loading}
										onChange={(e) => setUserPassword(e.target.value)}
										value={userPassword}
									/>
								</div>

								<div className="form-group mb-4">
									<label htmlFor="password-confirm" className="form-label">
										Confirm New Password
									</label>
									<input
										type="password"
										id="password-confirm"
										className="form-control"
										placeholder="Confirm the new password"
										disabled={loading}
										onChange={(e) => setUserPasswordConfirm(e.target.value)}
										value={userPasswordConfirm}
									/>
								</div>

								<div className="buttons-2fa">
									<AuthButton
										btnType="submit"
										loading={loading}
										className="mb-3"
									>
										SET PASSWORD
									</AuthButton>
								</div>
								{error && <ErrorMessage errorMessage={error.errorMessage} />}
							</>
						)}

						{newPasswordSuccess && (
							<div className="text-center text-success">
								<div className="mb-4">
									Your password has been successfully changed. Please log-in to
									the system.
								</div>
								<AuthButton
									btnType="button"
									btnTypeClassOverride="btn-primary"
									loading={loading}
									onClick={handleGoToLogin}
								>
									GO TO LOG IN
								</AuthButton>
							</div>
						)}
					</div>
				</div>
			</form>
			<AuthFooter />
		</>
	);
};

export default PasswordReset;

import { useState, useEffect, useContext } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { IonCard, IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import './ItemCatalogue.scss';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import ItemModal from './ItemModal';
import { CatalogueItem } from '../../../../interfaces/Utilities/CatalogueItem';
import { cloneDeep } from 'lodash';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';
import { authContext } from '../../../../contexts/AuthContext';

interface RouteIndexComponentExtended extends RouteIndexComponent {
	usageMode?: string;
	usageModeCompletion?: Function;
}

const ItemCatalogueIndex: React.FC<RouteIndexComponentExtended> = ({
	uid,
	routeTitle,
	permissionTo,
	usageMode,
	usageModeCompletion,
}) => {
	const authCtx: any = useContext(authContext);
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [itemId, setItemId] = useState<string>('');
	const [itemName, setItemName] = useState<string>('');
	const [itemData, setItemData] = useState<any>(null);
	const [itemModalIsLoading, setItemModalIsLoading] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();

	// Modals
	const [itemModal, setItemModal] = useState<any>({
		isOpen: false,
		itemId: '',
		itemName: '',
	});

	useEffect(() => {
		loadGridData();
	}, []);

	const columns = [
		{
			headerName: 'Code',
			field: 'code',
			sortable: true,
			sort: 'asc',
			flex: 1,
		},
		{
			headerName: 'Name',
			field: 'name',
			sortable: true,
			flex: 1,
		},
		{
			headerName: 'Work Type',
			field: 'job_skill',
			sortable: true,
			flex: 1,
		},
		{
			headerName: 'Tax Rate',
			field: 'tax_rate',
			sortable: true,
			flex: 1,
		},
		{
			headerName: `Sale Price (${authCtx.tenant.currency_symbol})`,
			field: 'price',
			filter: 'agNumberColumnFilter',
			valueFormatter: currencyFormatter,
			type: 'rightAligned',
			sortable: true,
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data._id)}
						/>
					)
				);
			},
		},
	];

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		setItemId('');
		setItemName('');
		setItemData(null);
		axios.get('/api/utilities/jobs/catalogue').then((res) => {
			setData(res.data);
			if (usageModeCompletion) usageModeCompletion();
			setGridLoading(false);
		});
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete') {
			params.node.setSelected(true);
			setItemId(params.data._id);
			setItemName(params.data.name);
			setItemData(params.data);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete') {
			handleCellClicked(params);
			handleEditItem();
		}
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');
		setItemId('');
		setItemName('');
		setItemData(null);

		axios
			.delete(`/api/utilities/jobs/catalogue/${id}`)
			.then(() => {
				loadGridData();

				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const handleAddItem = () => {
		if (permissionTo('create')) {
			setItemModal({ isOpen: true, itemId: '', itemName: '' });
		} else {
			showToast('permission');
		}
	};

	const handleEditItem = () => {
		if (permissionTo('update')) {
			setItemModal({ isOpen: true, itemId: itemId, itemName: itemName, itemData: itemData });
		} else {
			showToast('permission');
		}
	};

	const handleSave = (isNew: boolean, formState: CatalogueItem) => {
		if (!permissionTo(isNew ? 'create' : 'update')) {
			showToast('permission');
			return false;
		}

		// Check mandatory fields
		let formBad = false;
		Object.keys(formState).every((key: string) => {
			switch (key) {
				case 'code':
				case 'name':
					if (formState[key].trim().length === 0) {
						showToast('error', `Please enter a ${key.charAt(0).toUpperCase() + key.slice(1)}`);
						formBad = true;
					}
					break;
				case 'price':
					if (String(formState[key]).trim().length === 0) {
						showToast('error', 'Please enter a Sale Price');
						formBad = true;
					}
					break;
				case 'job_skill_id':
					if (!formState[key]?.value || formState[key]?.value.trim().length === 0) {
						showToast('error', `Please enter a Work Type`);
						formBad = true;
					}
					break;
				case 'tax_rate_id':
					if (!formState[key]?.value || formState[key]?.value.trim().length === 0) {
						showToast('error', `Please enter a Tax Rate`);
						formBad = true;
					}
					break;
			}

			return !formBad;
		});

		// Stop if missing mandatory information
		if (formBad) return false;

		const url = isNew
			? '/api/utilities/jobs/catalogue'
			: `/api/utilities/jobs/catalogue/${formState._id}`;
		let payload: any = cloneDeep(formState);

		// Parse the payload
		if (isNew) delete payload._id;
		Object.keys(payload).forEach((key: any) => {
			switch (key) {
				case 'job_skill_id':
				case 'tax_rate_id':
					payload[key] = payload[key].value;
					break;
			}
		});

		setItemModalIsLoading(true);

		axios
			.put(url, payload)
			.then(() => {
				loadGridData();
				showToast('success');
				setItemModal({ isOpen: false });
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				setItemModalIsLoading(false);
			});
	};

	// Build the output
	let output = (
		<>
			<DataGrid
				title={routeTitle}
				cols={columns}
				data={data}
				autoSize={true}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				rowCursorPointer={permissionTo('update')}
				extraFooter={
					<IonRow>
						<IonCol size={'12'} className='text-right pe-0'>
							{permissionTo('update') && (
								<IonButton
									color='primary'
									onClick={() => handleEditItem()}
									disabled={itemId.length === 0}
								>
									Edit Item
								</IonButton>
							)}
							{permissionTo('create') && (
								<IonButton color='success' onClick={() => handleAddItem()}>
									Add Item
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				}
			/>

			<ItemModal
				isOpen={itemModal.isOpen}
				itemId={itemModal.itemId}
				itemName={itemModal.itemName}
				itemData={itemModal.itemData}
				isLoading={itemModalIsLoading}
				onSave={handleSave}
				onClose={() => {
					setItemModal((prevState: any) => {
						return { ...prevState, isOpen: false };
					});
				}}
				onSuccess={loadGridData}
				permissionTo={permissionTo}
			/>
		</>
	);

	switch (usageMode) {
		case 'modal':
			output = (
				<div className='usage-modal' style={{ height: '100%' }}>
					<div className='filter-data-table' style={{ height: '100%' }}>
						{output}
					</div>
				</div>
			);
			break;
		default:
			output = (
				<IonCard
					className='table-card filter-data-table full-height-card'
				>
					{output}
				</IonCard>
			);
			break;
	}

	return (
		<div className={`component-${uid.replaceAll('.', '-')}`}>
			{!usageMode && <TitleBar title={routeTitle} />}
			{output}
		</div>
	);
};

export default ItemCatalogueIndex;

import {
	CalendarNav,
	CalendarNext,
	CalendarPrev,
	CalendarToday,
	Eventcalendar,
	MbscCellClickEvent,
	MbscEventClickEvent,
	MbscEventcalendarView,
	MbscPageChangeEvent,
} from '@mobiscroll/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Loading from '../../../../components/UI/Loading';
import { DateTime } from 'luxon';
import axios from '../../../../lib/axios';
import { baseColours } from '../../../../components/Calendars/Defaults';
import {
	IonButton,
	IonCol,
	IonGrid,
	IonLabel,
	IonRow,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import DayScheduleModal from '../modals/DayScheduleModal';
import EventTip from '../../../../components/Calendars/EventTip';
import { loadBankHolidays, parseCalendarEvent } from '../../../../helpers/calendar';
import { WorkersContext } from '../WorkersProvider';
import { EngagementTypes } from '../workers-types';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { useHistory } from 'react-router';

interface DayScheduleModalDefaults {
	isOpen: boolean;
	selectedDate: DateTime;
	events: Array<any>;
}

const DiaryIndex: React.FC = () => {
	const { workerId }: any = useParams();
	const { state, dispatch } = useContext(WorkersContext);
	const [events, setEvents] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [workCalendarLoading, setWorkCalendarLoading] = useState<boolean>(true);
	const [workCalendarData, setWorkCalendarData] = useState<any>(null);
	const [calendarLoading, setCalendarLoading] = useState<boolean>(true);
	const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
	const [viewMode, setViewMode] = useState<string>('month');
	const [colours, setColours] = useState<Array<any>>([]);
	const [bankHolidays, setBankHolidays] = useState<Array<any>>([]);
	const [view, setView] = useState<MbscEventcalendarView>({
		calendar: { type: 'month', size: 1, labels: true },
	});
	const permissions: any = {
		scheduling: usePermissionTo('scheduling'),
	};
	let history = useHistory();

	// Modals
	const dayScheduleModalDefaults: DayScheduleModalDefaults = {
		isOpen: false,
		selectedDate: DateTime.now(),
		events: [],
	};
	const [dayScheduleModal, setDayScheduleModal] =
		useState<DayScheduleModalDefaults>(dayScheduleModalDefaults);

	const handleSetView = (viewMode: string) => {
		setViewMode(viewMode);

		switch (viewMode) {
			case 'month':
				setView({ calendar: { type: 'month', size: 1, labels: true } });
				break;
			case 'fortnight':
				setView({ calendar: { type: 'week', size: 2, labels: true } });
				break;
			case 'week':
				setView({ calendar: { type: 'week', size: 1, labels: true } });
				break;
		}
	};

	const customHeader = () => {
		return (
			<IonGrid className='mbsc-prop-custom-header p-0'>
				<IonRow>
					<IonCol size='4' className='p-0'>
						<CalendarNav />
					</IonCol>
					<IonCol size='4' className='p-0 text-center'>
						<IonSegment value={viewMode}>
							<IonSegmentButton value='month' onClick={() => handleSetView('month')}>
								<IonLabel>Month</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value='fortnight' onClick={() => handleSetView('fortnight')}>
								<IonLabel>Fortnight</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value='week' onClick={() => handleSetView('week')}>
								<IonLabel>Week</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</IonCol>
					<IonCol size='4' className='p-0 text-right'>
						<CalendarPrev />
						<CalendarToday />
						<CalendarNext />
					</IonCol>
				</IonRow>
			</IonGrid>
		);
	};

	useEffect(() => {
		if (state.worker?.key_details.type_of_engagement === EngagementTypes.SELF) {
			setIsLoading(workCalendarLoading || calendarLoading || !workCalendarData);
		} else {
			setIsLoading(calendarLoading);
		}
	}, [workCalendarLoading, calendarLoading, workCalendarData]);

	useEffect(() => {
		let calendarColours: Array<any> = [];

		if (
			state.worker?.key_details.type_of_engagement === EngagementTypes.SELF &&
			Array.isArray(workCalendarData) &&
			workCalendarData.length > 0
		) {
			workCalendarData.forEach((d: any) => {
				// Build the correct class based on the self-employed worker's location
				let cellCssClass: string = 'bg-cal-cell ';
				switch (d.location) {
					case null:
					case '0':
						cellCssClass += 'bg-cal-cell-schedule';
						break;
					case '1':
						cellCssClass += 'bg-cal-cell-home';
						break;
					case '2':
						cellCssClass += 'bg-cal-cell-roaming';
						break;
					default:
						cellCssClass += 'bg-cal-cell-office';
						break;
				}

				calendarColours.push({
					date: DateTime.fromISO(d.start),
					cellCssClass,
				});
			});
		}

		// Finally set the calendar's properties
		if (calendarColours.length > 0) setColours(calendarColours);
	}, [workCalendarData]);

	const loadWorkCalendar = async (fromDay: Date, toDay: Date) => {
		setWorkCalendarLoading(true);

		let payload: any = {
			worker_id: workerId,
			start: fromDay,
			end: toDay,
		};

		axios
			.post('/api/workers/workers_list/worker_card/work_calendar', payload)
			.then((res: any) => {
				setWorkCalendarData(res.data);
			})
			.catch(() => {})
			.finally(() => setWorkCalendarLoading(false));
	};

	const loadCalendar = async (fromDay: Date, toDay: Date) => {
		setCalendarLoading(true);

		if (state.worker?.key_details.type_of_engagement === EngagementTypes.SELF) {
			await loadWorkCalendar(fromDay, toDay);
		}

		let payload: any = {
			worker_id: workerId,
			start: fromDay,
			end: toDay,
		};

		loadBankHolidays(
			DateTime.fromJSDate(fromDay).month,
			DateTime.fromJSDate(fromDay).year,
			setBankHolidays
		);

		axios
			.post('/api/workers/workers_list/worker_card/diary', payload)
			.then((res: any) => {
				// Parse and set the event data
				setEvents(
					res.data.map((event: any) => parseCalendarEvent({ event, source: 'workersDiary' }))
				);
			})
			.catch(() => {})
			.finally(() => {
				setCalendarLoading(false);
			});
	};

	const handleOnCalPageLoading = useCallback((e: MbscPageChangeEvent, inst: Eventcalendar) => {
		// Set a timeout on the first load to avoid the component update warning
		setTimeout(
			() => {
				loadCalendar(inst._firstDay, inst._lastDay);
			},
			firstPageLoaded ? 0 : 200
		);
	}, []);

	const handleOnCalPageLoaded = useCallback(() => {
		setFirstPageLoaded(true);
	}, []);

	const onEventClick = useCallback((e: MbscEventClickEvent) => {
		// Stop the cell click event from firing
		e.domEvent.stopPropagation();
	}, []);

	const onCellClick = useCallback(
		(e: MbscCellClickEvent) => {
			// Look for label class (e.g. clicked '2 more' button) and stop the modal from opening if found
			const lbl = e.domEvent?.target as HTMLElement;
			const labelClicked: boolean = lbl && lbl.classList.contains('mbsc-calendar-label-text');

			// Get the events for this day in time order ascending
			const dayEvents = events
				.filter(
					(event: any) =>
						event.start.startOf('day').valueOf() ===
						DateTime.fromJSDate(e.date).startOf('day').valueOf()
				)
				.sort(function (a: any, b: any) {
					return a.start - b.start;
				});

			// Default selected date
			let selectedDate = DateTime.now().set({
				day: e.date.getDate(),
				month: e.date.getMonth() + 1,
				year: e.date.getFullYear(),
				hour: 8,
				minute: 30,
			});

			// Generate the selected date using the first event as the start time
			if (dayEvents.length > 0) {
				const eventDate: DateTime = dayEvents[0].start;
				selectedDate = selectedDate.set({ hour: eventDate.hour, minute: eventDate.minute });
			}

			if (!labelClicked) {
				setDayScheduleModal({
					isOpen: true,
					selectedDate,
					events: dayEvents,
				});
			}
		},
		[events]
	);

	const handleScheduleWork = () => {
		if (permissions.scheduling('read')) {
			history.push(`/scheduling?worker_id=${workerId}#workers`);
		}
	};

	// Event Tip
	const [eventTipIsOpen, setEventTipIsOpen] = useState(false);
	const [eventTipAnchor, setEventTipAnchor] = useState(undefined);
	const [currentEvent, setCurrentEvent] = useState<any>(null);
	const eventTipTimerRef = useRef<any>(null);
	const onEventHoverIn = useCallback((args: any) => {
		setCurrentEvent(args.event);
		if (eventTipTimerRef.current) clearTimeout(eventTipTimerRef.current);
		setEventTipAnchor(args.domEvent.target);
		setEventTipIsOpen(true);
	}, []);

	const onEventHoverOut = useCallback(() => {
		eventTipTimerRef.current = setTimeout(() => setEventTipIsOpen(false), 200);
	}, []);

	const onMouseEnter = useCallback(() => {
		if (eventTipTimerRef.current) clearTimeout(eventTipTimerRef.current);
	}, []);

	const onMouseLeave = useCallback(() => {
		eventTipTimerRef.current = setTimeout(() => setEventTipIsOpen(false), 200);
	}, []);

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			<Alert severity='info'>Click on a day to view this worker's day schedule</Alert>
			<Eventcalendar
				height={'calc(100% - 100px)'}
				data={events}
				firstDay={1}
				view={view}
				renderHeader={customHeader}
				onPageLoading={handleOnCalPageLoading}
				onPageLoaded={handleOnCalPageLoaded}
				onEventHoverIn={onEventHoverIn}
				onEventHoverOut={onEventHoverOut}
				onEventClick={onEventClick}
				onCellClick={onCellClick}
				colors={[...baseColours, ...colours, ...bankHolidays]}
				showEventTooltip={false}
			/>

			<EventTip
				eventTipIsOpen={eventTipIsOpen}
				eventTipAnchor={eventTipAnchor}
				currentEvent={currentEvent}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			/>

			<DayScheduleModal
				isOpen={dayScheduleModal.isOpen}
				selectedDate={dayScheduleModal.selectedDate}
				events={dayScheduleModal.events}
				onClose={() => {
					setDayScheduleModal((prevState: any) => ({ ...prevState, isOpen: false }));
				}}
				onDidDismiss={() => {
					setDayScheduleModal(dayScheduleModalDefaults);
				}}
			/>

			<div className='d-flex justify-content-end m-0 mt-2'>
				<IonButton onClick={handleScheduleWork}>Schedule Work</IonButton>
			</div>
		</>
	);
};

export default DiaryIndex;


import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const faJobs: IconDefinition = {
	prefix: 'fas',
	iconName: 'clipboard',
	icon: [
		100,
		100,
		[] as string[],
		'',
		'M64.46,10.98h-8.04c-.13-3.61-3.09-6.5-6.73-6.5s-6.6,2.9-6.73,6.5h-7.58v8.88h29.07V10.98Zm-18.44,0c.12-1.92,1.72-3.45,3.68-3.45s3.56,1.53,3.68,3.45h-7.36Zm33.18,9.21v62.77c0,4.11-3.34,7.46-7.46,7.46H28.25c-4.11,0-7.46-3.35-7.46-7.46V20.19c0-4.11,3.34-7.46,7.46-7.46h3.45v3.58h-3.45c-2.14,0-3.88,1.74-3.88,3.88v62.77c0,2.14,1.74,3.88,3.88,3.88h43.49c2.14,0,3.88-1.74,3.88-3.88V20.19c0-2.14-1.74-3.88-3.88-3.88h-3.68v-3.58h3.68c4.11,0,7.46,3.34,7.46,7.46ZM31.71,37.6c0-.98,.79-1.77,1.77-1.77h3.31c.98,0,1.77,.79,1.77,1.77s-.79,1.77-1.77,1.77h-3.31c-.98,0-1.77-.79-1.77-1.77Zm36.99,0c0,.98-.79,1.77-1.77,1.77h-22.92c-.98,0-1.77-.79-1.77-1.77s.79-1.77,1.77-1.77h22.92c.98,0,1.77,.79,1.77,1.77Zm-36.99,11.72c0-.98,.79-1.77,1.77-1.77h3.31c.98,0,1.77,.79,1.77,1.77s-.79,1.77-1.77,1.77h-3.31c-.98,0-1.77-.79-1.77-1.77Zm36.99,0c0,.98-.79,1.77-1.77,1.77h-22.92c-.98,0-1.77-.79-1.77-1.77s.79-1.77,1.77-1.77h22.92c.98,0,1.77,.79,1.77,1.77Zm-36.99,11.71c0-.98,.79-1.77,1.77-1.77h3.31c.98,0,1.77,.79,1.77,1.77s-.79,1.77-1.77,1.77h-3.31c-.98,0-1.77-.79-1.77-1.77Zm36.99,0c0,.98-.79,1.77-1.77,1.77h-22.92c-.98,0-1.77-.79-1.77-1.77s.79-1.77,1.77-1.77h22.92c.98,0,1.77,.79,1.77,1.77Z',
	],
};

import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
	value: string
}

export const initialState = {
	value: ''
} as InitialState;

export const browserID = createSlice({
	name: "browserID",
	initialState,
	reducers: {
		setBrowserID: (state: any, action: any) => {
			state.value = action.payload;
		},
	},
});

export const { setBrowserID }: any = browserID.actions;

export default browserID.reducer;

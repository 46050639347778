import { useContext, useEffect, useRef, useState } from 'react';
import { faCirclePlus, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material';
import { toast } from 'react-toastify';
import { moduleContext } from '../../../contexts/ModuleContext';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import Loading from '../../../components/UI/Loading';
import styles from './Resources.module.scss';
import { JobSkillsOptions } from '../../../interfaces/Utilities/JobSkills';
import SelectStyled from '../../../components/UI/SelectStyled';
import Resource from '../../../interfaces/Jobs/Resource';
import { Dropdown, NumberField } from '../../../components/Forms/FormFields';

interface Props {
	jobId: string;
	permissionTo: Function;
	uid?: string;
	usageMode?: string;
	usageModeCompletion?: Function;
}

const SkillsRequired = ({ jobId, permissionTo, uid, usageMode, usageModeCompletion }: Props) => {
	const moduleCtx = useContext<any>(moduleContext);
	const jobSkillIdNewRef = useRef<any>(null);
	const manHoursNewRef = useRef<any>(null);
	const costPerHourNewRef = useRef<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tableDataLoading, setTableDataLoading] = useState<boolean>(true);
	const [resources, setResources] = useState<Resource[]>([]);
	const [jobSkillsLoading, setJobSkillsLoading] = useState<boolean>(true);
	const [jobSkills, setJobSkills] = useState<Array<any>>([]);
	const [newTotal, setNewTotal] = useState<number>(0);
	const [presentAlert] = useIonAlert();
	const endpoint = '/api/jobs/jobs_list/job_card/resources';
	let grandTotal = 0;

	useEffect(() => {
		loadJobSkills();
	}, []);

	useEffect(() => {
		setIsLoading(tableDataLoading === true || jobSkillsLoading === true);
	}, [tableDataLoading, jobSkillsLoading]);

	const loadTableData = async (fullReload: boolean = false) => {
		setTableDataLoading(true);
		if (fullReload) setResources([]);

		return await axios
			.post<Resource[]>(endpoint, { job_id: jobId })
			.then((res) => res.data)
			.then((res) => {
				setResources(res);
			})
			.finally(() => {
				setTableDataLoading(false);
			});
	};

	const loadJobSkills = () => {
		setJobSkillsLoading(true);

		moduleCtx
			.getJobSkillsOptions()
			.then((res: JobSkillsOptions[]) => {
				setJobSkills(res);
			})
			.finally(() => {
				setJobSkillsLoading(false);
				loadTableData();
			});
	};

	const handleCreate = async () => {
		const jobSkillId = jobSkillIdNewRef.current ? jobSkillIdNewRef.current.getValue() : '';
		const manHours = manHoursNewRef.current ? manHoursNewRef.current.value : '';
		const costPerHour = costPerHourNewRef.current
			? costPerHourNewRef.current.innerHTML.replace('£', '')
			: '';

		// Check all fields have values
		if (
			jobSkillId.length === 0 ||
			String(manHours).length === 0 ||
			String(costPerHour).length === 0
		) {
			showToast('error', 'Please fill-in all fields correctly');
			return;
		}

		setIsLoading(true);

		const toastID = toast.loading('Please wait...');

		const payload = {
			job_id: jobId,
			job_skill_id: jobSkillId[0].value,
			man_hours: manHours,
			cost_per_hour: costPerHour,
		};

		// Clear form
		jobSkillIdNewRef.current.clearValue();
		costPerHourNewRef.current.innerHTML = '£0.00';
		setNewTotal(0);

		axios
			.put(endpoint, payload)
			.then(() => {
				showToast('saved', null, toastID);

				// Reload the rows
				loadTableData(true);
			})
			.catch((err) => {
				console.log(err);
				showToast('error', null, toastID);
				setIsLoading(false);
			});
	};

	const handleDelete = (resourceId: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(resourceId);
					},
				},
			],
		});
	};

	const handleDeleteGo = async (resourceId: string) => {
		const toastID = toast.loading('Please wait...');
		setIsLoading(true);

		axios
			.delete(`${endpoint}/${resourceId}`)
			.then((res) => {
				// Reload the rows
				loadTableData(true);

				showToast('deleted', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
				setIsLoading(false);
			});
	};

	const handleUpdateTotalNew = (newValue: JobSkillsOptions | null) => {
		const manHours = manHoursNewRef.current.value;
		let costPerHour = 0;

		if (newValue) {
			costPerHour = newValue.hourlyRate;
		} else {
			const tmp = jobSkillIdNewRef.current.getValue();
			costPerHour = tmp.length > 0 ? tmp[0].hourlyRate : 0;
		}

		setNewTotal(calculateTotal(Number(manHours), Number(costPerHour)));
	};

	const calculateTotal = (manHours: number, costPerHour: number): number => {
		return Number(manHours * costPerHour);
	};

	const handleJobSkillChange = (newValue: JobSkillsOptions) => {
		handleUpdateTotalNew(newValue);
		if (costPerHourNewRef.current && newValue) {
			costPerHourNewRef.current.innerHTML =
				'£' + newValue.hourlyRate.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
		}
	};

	const handleJobSkillChangeEdit = (newValue: JobSkillsOptions, resourceId: string) => {
		const toastID = toast.loading('Please wait...');
		setIsLoading(true);

		const payload = {
			job_skill_id: newValue.value,
			cost_per_hour: newValue.hourlyRate,
		};

		axios
			.put(`${endpoint}/${resourceId}`, payload)
			.then((res) => {
				// Reload the rows
				loadTableData();

				showToast('success', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
				setIsLoading(false);
			});
	};

	const handleManHoursChangeEdit = (newValue: number, resourceId: string) => {
		const toastID = toast.loading('Please wait...');
		setIsLoading(true);

		const payload = {
			man_hours: Number(newValue),
		};

		axios
			.put(`${endpoint}/${resourceId}`, payload)
			.then((res) => {
				// Reload the rows
				loadTableData();

				showToast('success', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
				setIsLoading(false);
			});
	};

	return (
		<>
			<IonRow className='mt-3'>
				<IonCol size={'12'}>
					<h5 className='font-bold'>Job Skills</h5>
					{isLoading && <Loading overlay={true} />}
					<Table className={`table-sections ${styles.skillsTable} mt-3 mb-4`} size='small'>
						<TableHead>
							<TableRow>
								<TableCell>Skills Required</TableCell>
								<TableCell align='right'>Man Hours</TableCell>
								<TableCell align='right'>Cost Per Hour</TableCell>
								<TableCell align='right'>Total</TableCell>
								<TableCell align='right'>&nbsp;</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(!resources || resources.length === 0) && (
								<TableRow>
									<TableCell align='center' colSpan={6}>
										There are currently no resources set
									</TableCell>
								</TableRow>
							)}
							{resources &&
								resources.map((row: Resource, i: number) => {
									const rowTotal = calculateTotal(Number(row.man_hours), Number(row.cost_per_hour));
									grandTotal += rowTotal;

									return (
										<TableRow key={i}>
											<TableCell>
												<Dropdown
													values={jobSkills}
													defaultValue={jobSkills.filter((js) => js.value === row.job_skill_id)}
													handleUpdateSelect={(newValue: JobSkillsOptions) =>
														handleJobSkillChangeEdit(newValue, row.id)
													}
													editMode={true}
													useLabels={false}
												/>
											</TableCell>
											<TableCell align='right'>
												<NumberField
													handleUpdateValue={(newValue: number) =>
														handleManHoursChangeEdit(newValue, row.id)
													}
													value={row.man_hours.toFixed(1)}
													editMode={true}
													useLabels={false}
													hideSpinner={true}
													decimalLimit={1}
												/>
											</TableCell>
											<TableCell align='right'>
												{Number(row.cost_per_hour).toLocaleString('en-GB', {
													style: 'currency',
													currency: 'GBP',
												})}
											</TableCell>
											<TableCell align='right'>
												{rowTotal.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
											</TableCell>
											<TableCell align='right'>
												{permissionTo('delete') && (
													<IonButton
														onClick={() => handleDelete(row.id)}
														color='danger'
														disabled={isLoading}
														className='m-0'
													>
														<FontAwesomeIcon className='icon-delete' icon={faCircleXmark} />
													</IonButton>
												)}
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
						<TableFooter className='table-footer-grand-total'>
							<TableRow>
								<TableCell align='right' colSpan={3}>
									Grand Total:
								</TableCell>
								<TableCell align='right'>
									{grandTotal.toLocaleString('en-GB', {
										style: 'currency',
										currency: 'GBP',
									})}
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableFooter>
					</Table>

					<h5 className='font-bold'>Add New</h5>
					<Table className={`table-sections ${styles.skillsTable}`} size='small'>
						<TableHead>
							<TableRow>
								<TableCell>Skills Required</TableCell>
								<TableCell align='right'>Man Hours</TableCell>
								<TableCell align='right'>Cost Per Hour</TableCell>
								<TableCell align='right'>Total</TableCell>
								<TableCell>&nbsp;</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									<SelectStyled
										id='new__jobSkillId'
										forwardRef={jobSkillIdNewRef}
										options={jobSkills}
										onChange={handleJobSkillChange}
										disabled={isLoading}
									/>
								</TableCell>
								<TableCell align='right'>
									<IonRow class='prop-form'>
										<IonCol size='12'>
											{/* Temporary field to help clear the main field after an item creation */}
											{isLoading && (
												<NumberField
													title=''
													editMode={true}
													useLabels={false}
													hideSpinner={true}
													value={0}
												/>
											)}
											{!isLoading && (
												<NumberField
													id='new__manHours'
													title=''
													fieldRef={manHoursNewRef}
													onChangeCallback={() => handleUpdateTotalNew(null)}
													disabled={isLoading}
													decimalLimit={1}
													editMode={true}
													useLabels={false}
													noForm={true}
													hideSpinner={true}
													value={0}
												/>
											)}
										</IonCol>
									</IonRow>
								</TableCell>
								<TableCell align='right'>
									<IonRow class='prop-form'>
										<IonCol size='12'>
											<p ref={costPerHourNewRef}>
												{Number('0').toLocaleString('en-GB', {
													style: 'currency',
													currency: 'GBP',
												})}
											</p>
										</IonCol>
									</IonRow>
								</TableCell>
								<TableCell align='right'>
									{newTotal.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
								</TableCell>
								<TableCell align='right'>
									{permissionTo('create') && (
										<IonButton
											data-testid='btnAdd'
											color='success'
											disabled={isLoading}
											onClick={handleCreate}
										>
											<FontAwesomeIcon className='icon-add' icon={faCirclePlus} />
										</IonButton>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</IonCol>
			</IonRow>
		</>
	);
};

export default SkillsRequired;

import { IonButton, useIonAlert } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { showToast } from '../../../lib/toast';
import SuspendWorkModal from './modals/SuspendWorkModal';
import JobsContext from '../../../contexts/JobsContext';
import axios from '../../../lib/axios';
import { useHistory } from 'react-router';
import { moduleContext } from '../../../contexts/ModuleContext';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import { DateTime } from 'luxon';
import TextMessageModal from '../../../components/UI/modals/TextMessageModal';

interface Props {
	permissionTo: Function;
	jobId: string;
}

const JobControls: React.FC<Props> = (props: Props) => {
	const { jobData, reloadJob } = useContext(JobsContext);
	const moduleCtx = useContext<any>(moduleContext);
	const [presentAlert] = useIonAlert();
	const textMessageModalRef = useRef<any>(null);
	const [jobControlsLoading, setJobControlsLoading] = useState<boolean>(false);
	const [btnLblCompleteJob, setBtnLblCompleteJob] = useState<string>('Complete Job');
	let history = useHistory();
	const permissions: any = {
		job_card: props.permissionTo,
		scheduling: usePermissionTo('scheduling'),
	};

	useEffect(() => {
		// Keep the completion button label up to date
		setBtnLblCompleteJob(jobData?.status_enum !== 'completed' ? 'Complete Job' : 'Un-Complete Job');
	}, [jobData]);

	// Modals
	type SuspendWorkModalType = { isOpen: boolean };
	const [suspendWorkModal, setSuspendWorkModal] = useState<SuspendWorkModalType>({ isOpen: false });

	const handleSuspendWork = () => {
		if (permissions.job_card('update')) {
			setSuspendWorkModal({ isOpen: true });
		}
	};

	const handleSuspendWorkGo = (
		reasonId: string,
		setIsLoading: Function,
		onModalClose: Function
	) => {
		if (permissions.job_card('update')) {
			setIsLoading(true);
			axios
				.put(`/api/jobs/jobs_list/job_card/${props.jobId}`, {
					work_suspended_reason_id: reasonId,
					work_suspended_date: DateTime.now().toISO(),
				})
				.then(() => {
					reloadJob();
					showToast('saved');
					onModalClose();
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			showToast('permission');
		}
	};

	const handleUnsuspendWork = () => {
		if (permissions.job_card('update')) {
			presentAlert({
				header: 'Unsuspend Work',
				message: 'Are you sure you want to unsuspend this job?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleUnsuspendWorkGo();
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleUnsuspendWorkGo = async () => {
		setJobControlsLoading(true);

		await axios
			.put(`/api/jobs/jobs_list/job_card/${props.jobId}`, { work_suspended_reason_id: null })
			.catch((err) => {
				console.error(err);
				setJobControlsLoading(false);
			});

		await reloadJob();

		showToast('saved');
		setJobControlsLoading(false);
	};

	const handleScheduleWork = () => {
		if (permissions.scheduling('read')) {
			history.push(`/scheduling?job_id=${props.jobId}`);
		}
	};

	const handleCompleteJob = async () => {
		if (permissions.job_card('update')) {
			setJobControlsLoading(true);

			const setCompleted: boolean = jobData?.status_enum !== 'completed';
			await moduleCtx.setJobComplete(props.jobId, setCompleted);
			await reloadJob();

			setJobControlsLoading(false);
		} else {
			showToast('permission');
		}
	};

	const handleAddEstimate = () => {
		if (jobData?.estimate_id) {
			history.push(`/jobs/estimates_list/estimates_card/${jobData.estimate_id}`);
		} else {
			history.push(`/jobs/estimates_list/add_new/${props.jobId}`);
		}
	};

	const handleTextMessageClient = () => {
		if (
			permissions.job_card('update') &&
			jobData?.client.email &&
			jobData?.client.email.length > 5
		) {
			textMessageModalRef.current.isOpen = true;
		} else {
			showToast('permission');
		}
	};

	return (
		<>
			<IonButton
				color='alt'
				className='mt-3'
				onClick={handleTextMessageClient}
				disabled={jobControlsLoading}
			>
				Text Message Client
			</IonButton>
			<IonButton
				color='alt'
				className='mt-3'
				onClick={handleAddEstimate}
				disabled={jobControlsLoading}
			>
				{!jobData?.estimate_id ? 'Add' : 'Edit'} Estimate
			</IonButton>
			<IonButton
				color='alt'
				className='mt-3'
				onClick={!jobData?.work_suspended_reason_id ? handleSuspendWork : handleUnsuspendWork}
				disabled={jobControlsLoading}
			>
				{!jobData?.work_suspended_reason_id ? 'Suspend Work' : 'Unsuspend Work'}
			</IonButton>
			<IonButton
				color='alt'
				className='mt-3'
				onClick={handleScheduleWork}
				disabled={jobControlsLoading}
			>
				Schedule Work
			</IonButton>
			<IonButton
				color='alt'
				className='mt-3'
				onClick={handleCompleteJob}
				disabled={jobControlsLoading}
			>
				{btnLblCompleteJob}
			</IonButton>

			<SuspendWorkModal
				isOpen={suspendWorkModal.isOpen}
				onClose={() => setSuspendWorkModal({ isOpen: false })}
				permissionTo={props.permissionTo}
				jobId={props.jobId}
				handleSuspendWork={handleSuspendWorkGo}
			/>

			<TextMessageModal
				ref={textMessageModalRef}
				title={`Text Message: ${
					jobData?.client.company_name
						? jobData.client.company_name
						: jobData?.client.first_name + ' ' + jobData?.client.last_name
				}`}
				modalData={{ dataType: 'client', clientId: jobData?.client.id, jobId: props.jobId }}
				onClose={() => (textMessageModalRef.current.isOpen = false)}
			/>
		</>
	);
};

export default JobControls;

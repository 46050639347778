import useQueue from 'react-use-queue';
import { publish, useSubscribe } from '../../helpers/events';
import { sleep } from '../../helpers/sleep';

interface Props {}

const SyncComponent: React.FC<Props> = (props: Props) => {
	const Queue = useQueue();

	const syncItem = (data: any): Promise<any> => {
		return new Promise(async (res, rej) => {
			Queue.addJob({
				task: async () => {
					await doSyncItem(data);
					res(true);
				},
			});
		});
	};

	useSubscribe('database:sync:Item', syncItem, []);

	const doSyncItem = (data: any): Promise<any> => {
		return new Promise(async (res, rej) => {
			await sleep(1);
			if (data.method === 'update') {
				publish('database:new:' + data.type, data.item);
			} else {
				publish('database:delete:' + data.type, data.item);
			}
			res(true);
		});
	};
	return <></>;
};

export default SyncComponent;

import { useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from '../../../../../lib/axios';
import Loading from '../../../../../components/UI/Loading';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
	uid: string;
	permissionTo: Function;
}

const StorageUse = (props: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<any>(null);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context: any) => {
						let label = context.raw || '';
						if (label) {
							label += ' GB';
						}
						return label;
					},
				},
			},
		},
		scales: {
			y: {
				ticks: {
					// Include a dollar sign in the ticks
					callback: function (value: any, index: number, ticks: any) {
						return value + ' GB';
					},
				},
			},
		},
	};

	useEffect(() => {
		setIsLoading(true);

		axios
			.get('/api/utilities/company_details')
			.then((res) => {
				const d = res.data[0];

				// Parse data
				if (!d?.storage?.allowance) d.storage.allowance = 0;
				if (!d?.storage?.current_usage) d.storage.current_usage = 0;

				setData({
					labels: ['Free Allowance', 'Current Usage'],
					datasets: [
						{
							data: [d.storage.allowance, d.storage.current_usage],
							backgroundColor: ['#2967d9', '#20bbf1'],
						},
					],
				});
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<>
			{isLoading && <Loading />}
			{!isLoading && data && (
				<>
					<h5 className='font-bold'>Data Usage</h5>
					<Bar options={options} data={data} />
				</>
			)}
		</>
	);
};

export default StorageUse;

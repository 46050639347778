import { useEffect, useRef, useState } from 'react';
import { IonButton } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import MessageModal from './MessageModal';

interface Props {
	clientId: string;
}

const MessagesIndex: React.FC<Props> = (props: Props) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const messageModalRef = useRef<any>(null);

	useEffect(() => {
		loadGridData();
	}, []);

	const colData: Array<any> = [
		{
			headerName: 'Date Sent',
			field: 'date',
			valueFormatter: (params: any) => dateFormatter(params, true),
			sort: 'desc',
			minWidth: 184,
			maxWidth: 184,
			flex: 1,
		},
		{
			headerName: 'Sent By',
			field: 'sent_by',
			minWidth: 320,
			maxWidth: 320,
			flex: 1,
		},
		{
			headerName: 'Message',
			field: 'message',
			flex: 1,
		},
	];

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		axios
			.post('/api/jobs/clients_list/client_card/messages', { client_id: props.clientId })
			.then((res: any) => {
				setData(res.data);
				setGridLoading(false);
			});
	};

	const handleCellClicked = (params: any) => {
		params.node.setSelected(true);
		setSelectedRow(params.data);
	};

	const handleCellDoubleClicked = (params: any) => {
		handleCellClicked(params);
		handleViewMessage();
	};

	const handleViewMessage = () => {
		messageModalRef.current.isOpen = true;
		messageModalRef.current.modalData = selectedRow;
	};

	return (
		<>
			<DataGrid
				title={'Messages'}
				cols={colData}
				data={data}
				rowCursorPointer={true}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				showFooter={false}
				extraFooter={
					<div className='text-right'>
						<IonButton
							color='primary'
							onClick={handleViewMessage}
							disabled={!selectedRow || gridLoading}
						>
							View Message
						</IonButton>
					</div>
				}
			/>

			<MessageModal
				ref={messageModalRef}
				title={'Message'}
				onClose={() => (messageModalRef.current.isOpen = false)}
			/>
		</>
	);
};

export default MessagesIndex;

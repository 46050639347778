import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { getDomElementInPath } from '../../lib/functions';

const ContextMenu = ({ targetId, srcElementClass, options, classes }: any) => {
	const [contextData, setContextData] = useState<any>({ visible: false, posX: 0, posY: 0, id: '' });
	const contextRef = useRef<any>(null);

	useEffect(() => {
		const contextMenuEventHandler = (event: any) => {
			const targetElement = document.getElementById(targetId);

			if (targetElement && targetElement.contains(event.target)) {
				event.preventDefault();

				const srcElement = getDomElementInPath(event.srcElement, srcElementClass);
				if (srcElement) {
					const clientX = event.clientX;
					const clientY = event.clientY;

					setContextData({ visible: true, posX: clientX, posY: clientY, id: srcElement.id });
				}
			} else if (contextRef.current && !contextRef.current.contains(event.target)) {
				setContextData({ ...contextData, visible: false, id: '' });
			}
		};

		const offClickHandler = (event: any) => {
			if (contextRef.current && !contextRef.current.contains(event.target)) {
				setContextData({ ...contextData, visible: false, id: '' });
			}
		};

		document.addEventListener('contextmenu', contextMenuEventHandler);
		document.addEventListener('click', offClickHandler);
		return () => {
			document.removeEventListener('contextmenu', contextMenuEventHandler);
			document.removeEventListener('click', offClickHandler);
		};
	}, [contextData, targetId, srcElementClass]);

	const closeMenu = () => {
		setContextData({ ...contextData, visible: false, id: '' });
	};

	useLayoutEffect(() => {
		if (contextData.posX + contextRef.current?.offsetWidth > window.innerWidth) {
			setContextData({ ...contextData, posX: contextData.posX - contextRef.current?.offsetWidth });
		}
		if (contextData.posY + contextRef.current?.offsetHeight > window.innerHeight) {
			setContextData({ ...contextData, posY: contextData.posY - contextRef.current?.offsetHeight });
		}
	}, [contextData]);

	return (
		<div
			ref={contextRef}
			className='contextMenu'
			style={{
				position: 'absolute',
				zIndex: '9999',
				display: `${contextData.visible ? 'block' : 'none'}`,
				left: contextData.posX,
				top: contextData.posY,
			}}
		>
			<div className={`optionsList ${classes?.listWrapper}`}>
				{options.map((option: any, i: number) => (
					<li
						key={i}
						className={`optionListItem ${classes?.listItem}`}
						onClick={(e) => {
							option.action(contextData.id);
							closeMenu();
						}}
					>
						{option.label}
					</li>
				))}
			</div>
		</div>
	);
};

export default ContextMenu;

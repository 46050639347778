export const faMessages = {
	prefix: 'fas',
	iconName: 'messages',
	icon: [
		100,
		100,
		[] as string[],
		null,
		'M82.93,62.6c3.54-5.02,5.41-10.72,5.41-16.54,0-17.98-17.46-32.6-38.93-32.6S10.49,28.08,10.49,46.06s17.46,32.6,38.92,32.6c7.15,0,14.13-1.65,20.23-4.76l20.78,7.1-7.49-18.4Zm-13.64,6.77l-.8,.43c-5.69,3.07-12.28,4.69-19.07,4.69-19.16,0-34.75-12.76-34.75-28.43s15.59-28.43,34.75-28.43,34.75,12.76,34.75,28.43c0,5.28-1.82,10.46-5.26,14.98l-.72,.95,4.93,12.1-13.82-4.72Zm-27.72-24.08c0,2.55-2.07,4.62-4.62,4.62s-4.62-2.07-4.62-4.62,2.07-4.62,4.62-4.62,4.62,2.07,4.62,4.62Zm13.48,0c0,2.55-2.07,4.62-4.62,4.62s-4.62-2.07-4.62-4.62,2.07-4.62,4.62-4.62,4.62,2.07,4.62,4.62Zm13.48,0c0,2.55-2.07,4.62-4.62,4.62s-4.62-2.07-4.62-4.62,2.07-4.62,4.62-4.62,4.62,2.07,4.62,4.62Z',
	],
};

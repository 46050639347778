import { createContext } from 'react';

export type ReportContextType = {
	clients: Array<any>;
	jobManagers: Array<any>;
	jobStatuses: Array<any>;
};

export const reportContext = createContext<ReportContextType>({
	clients: [],
	jobManagers: [],
	jobStatuses: [],
});

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
} from '@ionic/react';
import { Ref, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

import PersonalNotifications from './PersonalNotifications';

interface NotificationsModalProps {
	isOpen: boolean;
	onClose: Function;
}

const NotificationsModal: React.FC<NotificationsModalProps> = (props) => {
	const ionRef: any = useRef<Ref<HTMLIonModalElement>>();
	const parentRef: any = useRef<Ref<HTMLIonContentElement>>();
	const location = useLocation();
	let history = useHistory();

	useEffect(() => {
		if (props.isOpen) {
			// Switch to tab if required
			if (location && location.search) {
				const urlParams = new URLSearchParams(location.search);
				if (urlParams.get('tab')) {
					// Remove the query parameter
					urlParams.delete('tab');
					history.replace({
						search: urlParams.toString(),
					});
				}
			}
		}
	}, [props]);

	return (
		<IonModal
			ref={ionRef}
			style={{ '--width': '89vw', '--height': '96vh' }}
			isOpen={props.isOpen}
			onDidDismiss={() => props.onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{`Notifications`}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => ionRef.current.dismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent ref={parentRef}>
				<PersonalNotifications />
			</IonContent>
		</IonModal>
	);
};

export default NotificationsModal;

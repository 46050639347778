import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';

interface Props {
	page: number;
	setPage: Function;
	pageMeta: any;
}

const Paginator: React.FC<Props> = ({ page, setPage, pageMeta }: Props) => {
	const handleNext = () => {
		setPage((prevState: number) => (prevState = prevState + 1));
	};

	const handlePrev = () => {
		setPage((prevState: number) => (prevState = prevState - 1));
	};

	return (
		<IonGrid className='paginator'>
			<IonRow>
				<IonCol size='3'>
					<IonButton disabled={page <= 1} size='small' color='alt' onClick={handlePrev}>
						<FontAwesomeIcon icon={faChevronLeft} />
						&nbsp;Prev
					</IonButton>
				</IonCol>
				<IonCol size='6' className='pageInfo'>
					Page {page} of {pageMeta.last_page}
				</IonCol>
				<IonCol size='3' className='text-right'>
					<IonButton
						disabled={page >= pageMeta.last_page}
						size='small'
						color='alt'
						onClick={handleNext}
					>
						Next&nbsp;
						<FontAwesomeIcon icon={faChevronRight} />
					</IonButton>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default Paginator;

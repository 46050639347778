import { useContext, useRef } from 'react';
import { authContext } from '../../../../contexts/AuthContext';

const useKeyDetailsArray = (newWorker: boolean) => {
	const authCtx: any = useContext(authContext);

	let keyDetails: Array<any> = [
		{
			title: 'Type of Worker',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'type_of_engagement',
			title: 'Status',
			type: 'dropdown',
			ref: useRef(),
			isMandatory: true,
			values: null,
			onChangeCallback: null,
			noForm: newWorker,
			db: ['key_details', 'type_of_engagement'],
		},
		{
			title: 'Name',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'title',
			title: 'Title',
			type: 'text',
			ref: useRef(),
			noForm: newWorker,
			db: ['user', 'title'],
		},
		{
			id: 'first_name',
			title: 'Name',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: newWorker,
			db: ['user', 'first_name'],
		},
		{
			id: 'last_name',
			title: 'Surname',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: newWorker,
			db: ['user', 'last_name'],
		},
		{
			title: 'Contact Details and Security',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'mobile',
			title: 'Mobile Number',
			type: 'phone',
			ref: useRef(),
			isMandatory: true,
			noForm: newWorker,
			db: ['user', 'mobile'],
		},
		{
			id: 'email',
			title: 'Email Address',
			type: 'email',
			ref: useRef(),
			isMandatory: true,
			noForm: newWorker,
			db: ['user', 'email'],
		},
		{
			id: 'role_id',
			title: 'Security Setting',
			type: 'dropdown',
			ref: useRef(),
			isMandatory: true,
			values: null,
			onChangeCallback: null,
			noForm: newWorker,
			db: ['key_details', 'role_id'],
		},
		/*{
			id: 'password',
			title: 'Password',
			type: 'password',
			ref: useRef(),
			isMandatory: true,
			noSet: true,
			noForm: newWorker,
			db: ['user', 'password'],
		},*/
		{
			title: 'Approvals and Targets',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'approves_holidays',
			title: 'Can Approve Holidays',
			type: 'dropdown',
			ref: useRef(),
			isMandatory: true,
			values: [
				{ label: 'Yes', value: true },
				{ label: 'No', value: false },
			],
			defaultValue: [{ label: 'Yes', value: true }],
			onChangeCallback: null,
			isSearchable: false,
			noForm: newWorker,
			db: ['key_details', 'approves_holidays'],
		},
		{
			id: 'receives_worker_alarm',
			title: 'Can Receive Worker Alarms',
			type: 'dropdown',
			ref: useRef(),
			isMandatory: true,
			values: [
				{ label: 'Yes', value: true },
				{ label: 'No', value: false },
			],
			defaultValue: [{ label: 'No', value: false }],
			onChangeCallback: null,
			isSearchable: false,
			noForm: newWorker,
			db: ['key_details', 'receives_worker_alarm'],
		},
		{
			id: 'approves_all_timesheets',
			title: 'Can Approve All Timesheets',
			type: 'dropdown',
			ref: useRef(),
			isMandatory: true,
			values: [
				{ label: 'Yes', value: true },
				{ label: 'No', value: false },
			],
			defaultValue: [{ label: 'No', value: false }],
			onChangeCallback: null,
			isSearchable: false,
			noForm: newWorker,
			db: ['key_details', 'approves_all_timesheets'],
		},
		{
			id: 'sales_target',
			title: `Sales Target (${authCtx.tenant.currency_symbol})`,
			type: 'number',
			hideSpinner: true,
			ref: useRef(),
			isMandatory: true,
			decimalLimit: 2,
			noForm: newWorker,
			db: ['key_details', 'sales_target'],
		},
		{
			id: 'sales_target_date',
			title: 'Sales Target Start',
			type: 'date',
			ref: useRef(),
			isMandatory: true,
			defaultToday: true,
			noForm: newWorker,
			db: ['key_details', 'sales_target_date'],
		},
		{
			title: 'Title and Skills',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'job_title',
			title: 'Job Title',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: newWorker,
			db: ['key_details', 'job_title'],
		},
		{
			id: 'job_skills',
			title: 'Job Skills',
			type: 'dropdownAsync',
			ref: useRef(),
			isMandatory: true,
			isMulti: true,
			values: null,
			onChangeCallback: null,
			loadOptions: null,
			isSearchable: false,
			addNewLabel: 'Add New Job Skill',
			handleAddNew: null,
			noForm: newWorker,
			db: ['key_details', 'job_skills'],
		},
		{
			title: 'Address and Location Boundary',
			type: 'title',
			style: 'alpha',
		},
		{
			// Address look-up fields must have auto-assigned IDs
			title: 'Address Lookup',
			type: 'addressLookup',
			id: 'address-lookup-worker',
		},
		{
			title: 'Address 1',
			type: 'text',
			ref: useRef(),
			group: 'worker',
			isMandatory: true,
			noForm: newWorker,
			db: ['key_details', 'address', 'address_1'],
			subType: 'address_1',
		},
		{
			title: 'Address 2',
			type: 'text',
			ref: useRef(),
			group: 'worker',
			isMandatory: false,
			noForm: newWorker,
			db: ['key_details', 'address', 'address_2'],
			subType: 'address_2',
		},
		{
			title: 'Town',
			type: 'text',
			ref: useRef(),
			group: 'worker',
			isMandatory: true,
			noForm: newWorker,
			db: ['key_details', 'address', 'town'],
			subType: 'town',
		},
		{
			title: 'Postcode',
			type: 'postcode',
			ref: useRef(),
			group: 'worker',
			isMandatory: true,
			noForm: newWorker,
			db: ['key_details', 'address', 'postcode'],
			subType: 'postcode',
		},
		{
			title: 'Latitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting latitude',
			group: 'worker',
			isMandatory: false,
			disabled: true,
			noForm: newWorker,
			db: ['key_details', 'address', 'latitude'],
			subType: 'latitude',
		},
		{
			title: 'Longitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting longitude',
			group: 'worker',
			isMandatory: false,
			disabled: true,
			noForm: newWorker,
			db: ['key_details', 'address', 'longitude'],
			subType: 'longitude',
		},
		{
			title: 'Boundary Size',
			type: 'boundary',
			ref: useRef(),
			group: 'worker',
			isMandatory: true,
			noForm: newWorker,
			db: ['key_details', 'address'],
			custom: false,
			subType: 'boundary',
		},
	];

	if (newWorker !== true) {
		// Password field is only for new workers
		keyDetails = keyDetails.filter((details: any) => details.id !== 'password');
	}

	return { keyDetails };
};

export default useKeyDetailsArray;

import { IonRow, IonCol, IonLabel, IonButton, IonProgressBar } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { capitalFirstLetter, getTimezone, getYearSelectOptions } from '../../../lib/functions';
import axios from '../../../lib/axios';
import {
	EventType,
	HalfDayOptions,
	RequestData,
	RequestStatus,
} from '../../Workers/HolidayAbsenceTraining/Requests/request-types';
import { authContext } from '../../../contexts/AuthContext';
import { DateTime } from 'luxon';
import { dateFormatter } from '../../../helpers/dateFormatter';
import { showToast } from '../../../lib/toast';
import SelectStyled from '../../../components/UI/SelectStyled';
import RequestModal from '../../Workers/HolidayAbsenceTraining/modals/RequestModal';
import { handleSaveRequest } from '../../Workers/HolidayAbsenceTraining/Requests/request-functions';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const Requests: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const gridRef: any = useRef<any>();
	const [viewHistory, setViewHistory] = useState<boolean>(false);
	const [tabMode, setTabMode] = useState<EventType>(EventType.HOLIDAY);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [tableData, setTableData] = useState<Array<any>>([]);
	const [curYear, setCurYear] = useState<number>(DateTime.now().year);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [requestUsageProgress, setRequestUsageProgress] = useState<number>(0);
	const monthStart: number = DateTime.fromSQL(props.systemSettings.working_holiday_year).month;
	const yearSelectOptions = getYearSelectOptions({ offsetMode: monthStart > 1 });
	const defaultStats: any = {
		holiday: { allowance: '-', approved: '-', remaining: '-', requested: '-' },
		absence: { allowance: '-', approved: '-', remaining: '-', requested: '-' },
	};
	const [stats, setStats] = useState<any>(defaultStats);
	const requestDataDefault: RequestData = {
		requestId: undefined,
		workerId: authCtx.user.worker_id,
		requestType: EventType.HOLIDAY,
		requestedDate: DateTime.now().startOf('day'),
		period: 1,
		status: RequestStatus.REQUESTED,
	};
	const permissionTo = usePermissionTo('workers.holidays_and_absences.requests');

	// Modals
	const [requestModal, setRequestModal] = useState<{ isOpen: boolean }>({ isOpen: false });
	const [requestModalData, setRequestModalData] = useState<RequestData>(requestDataDefault);

	useEffect(() => {
		if (gridReady) {
			loadGridData(curYear);
		}

		loadStats(curYear);
	}, [gridReady, tabMode, curYear, viewHistory]);

	const columns: Array<any> = [
		{
			field: 'requested_date',
			headerName: 'Requested',
			valueFormatter: dateFormatter,
			flex: 2.5,
		},
		{
			field: 'start',
			valueFormatter: (params: any) => dateFormatter(params, true),
			flex: 4,
		},
		{
			field: 'end',
			valueFormatter: (params: any) => dateFormatter(params, true),
			flex: 4,
		},
		{
			field: 'period',
			cellRenderer: (params: any) => (
				<>
					{params.node.data.period}
					<span className='small'> day{Number(params.node.data.period) !== 1 ? 's' : ''}</span>
				</>
			),
			flex: 2,
		},
		{
			field: 'status',
			headerName: '',
			cellRenderer: (params: any) => {
				const statusString = params.node.data.status.toLowerCase();
				let icon = null;

				switch (statusString) {
					case 'requested':
						icon = (
							<FontAwesomeIcon
								title={params.node.data.status}
								className={`icon-${statusString}`}
								icon={faClock}
							/>
						);
						break;
					case 'approved':
						icon = (
							<FontAwesomeIcon
								title={params.node.data.status}
								className={`icon-${statusString}`}
								icon={faCircleCheck}
							/>
						);
						break;
					case 'declined':
						icon = (
							<FontAwesomeIcon
								title={params.node.data.status}
								className={`icon-${statusString}`}
								icon={faCircleXmark}
							/>
						);
						break;
				}

				return <span className='status-icon'>{icon}</span>;
			},
			flex: 1,
		},
	];

	const handleTabChange = (event: any) => {
		let tab = event.target;
		if (!tab.classList.contains('subnav-item')) {
			tab = tab.closest('.subnav-item');
		}

		setTabMode(tab.id);
		setRequestUsageProgress(0);
	};

	const loadGridData = (year: number) => {
		setTableData([]);

		const payload: any = {
			worker_id: authCtx.user.worker_id,
			event_types: [tabMode],
			year,
			month: 13,
			...getTimezone(),
		};

		axios
			.post('/api/workers/holidays_and_absences/requests', payload)
			.then((res: any) => {
				setTableData(
					res.data.sort(function (a: any, b: any) {
						return DateTime.fromISO(a.requested_date).valueOf() <
							DateTime.fromISO(b.requested_date).valueOf()
							? 1
							: -1;
					})
				);
			})
			.catch(() => {
				showToast('error', 'There was a problem with the My Holidays and Approved Absences widget');
			});
	};

	const loadStats = (year: number) => {
		let payload: any = {
			worker_id: authCtx.user.worker_id,
			event_types: [tabMode],
			year,
			month: 13,
		};

		axios
			.post('/api/workers/holidays_and_absences/information', payload)
			.then((res: any) => {
				setStats((prevState: any) => ({ ...prevState, [tabMode]: res.data[tabMode] }));

				// Calculate percentage of request usage
				let pct: number = (res.data[tabMode].remaining / res.data[tabMode].allowance) * 100;
				pct = (100 - Number(pct.toFixed(2))) / 100;
				if (pct > 1) pct = 1;
				setRequestUsageProgress(pct);
			})
			.catch(() => {
				showToast('error');
			});
	};

	const handleMakeRequest = () => {
		setRequestModal(() => {
			// Set the event type before opening the modal
			setRequestModalData((prevState: any) => ({
				...prevState,
				requestType: tabMode,
			}));

			// Open the modal
			return { isOpen: true };
		});
	};

	const tabs = (
		<IonRow className='sub-nav-tabs'>
			<IonCol
				size='6'
				id={EventType.HOLIDAY}
				className={tabMode === EventType.HOLIDAY ? 'active subnav-item' : 'subnav-item'}
				onClick={(e: any) => handleTabChange(e)}
			>
				<span>Holidays</span>
			</IonCol>
			<IonCol
				size='6'
				id={EventType.APPROVED_ABSENCE}
				className={tabMode === EventType.APPROVED_ABSENCE ? 'active subnav-item' : 'subnav-item'}
				onClick={(e: any) => handleTabChange(e)}
			>
				<span>Approved Absences</span>
			</IonCol>
		</IonRow>
	);

	return (
		<>
			<div
				className={`widget-container ${
					props.widgetId.replaceAll('.', '-') + (viewHistory ? ' view-history' : '')
				}`}
			>
				<div className='widget-header'>My Holidays and Approved Absences</div>
				{!viewHistory && (
					<>
						<div className='widget-content'>
							{tabs}
							<div className='widget-plaques-container'>
								{Object.keys(stats[tabMode])
									.sort()
									.map((stat: any, index: number) => (
										<div key={index} className={`widget-plaque widget-plaque__${stat}`}>
											<div className='title'>{capitalFirstLetter(stat)}</div>
											<div className='stat'>{stats[tabMode][stat]}</div>
										</div>
									))}
							</div>
							<div className='progress-bar-container'>
								<IonProgressBar value={requestUsageProgress}></IonProgressBar>
							</div>
						</div>
						<div className='widget-footer'>
							<IonButton className='btn-alt' size='small' onClick={() => setViewHistory(true)}>
								View History
							</IonButton>
							<IonButton size='small' onClick={handleMakeRequest}>
								Make Request
							</IonButton>
						</div>
					</>
				)}
				{viewHistory && (
					<>
						<div className='widget-content'>
							{tabs}
							<DataGrid
								ref={gridRef}
								title=''
								onGridReady={() => setGridReady(true)}
								useSearch={false}
								cols={columns}
								data={tableData}
								rowCursorPointer={false}
								suppressRowHoverHighlight={true}
								compact={true}
								gridSizeChangedFunction={() => {
									if (gridReady) gridRef.current.sizeToFit();
								}}
							/>
						</div>
						<div className='widget-footer'>
							<div className='history-stats'>
								{stats && stats.hasOwnProperty(tabMode) && (
									<>
										Allowance: <strong>{stats[`${tabMode}`].allowance}</strong> | Approved:{' '}
										<strong>{stats[`${tabMode}`].approved}</strong> | Remaining:{' '}
										<strong>{stats[`${tabMode}`].remaining}</strong>
									</>
								)}
							</div>

							<div className='history-controls'>
								<IonLabel>Year:</IonLabel>
								<SelectStyled
									value={[
										{
											label: yearSelectOptions.filter((opt: any) => opt.value === curYear)[0].label,
											value: curYear,
										},
									]}
									isSearchable={false}
									options={yearSelectOptions}
									onChange={(newValue: any) => {
										setCurYear(newValue.value);
									}}
								/>
								<IonButton className='btn-alt' size='small' onClick={() => setViewHistory(false)}>
									View Stats
								</IonButton>
								<IonButton size='small' onClick={handleMakeRequest}>
									Make Request
								</IonButton>
							</div>
						</div>
					</>
				)}
			</div>

			<RequestModal
				isOpen={requestModal.isOpen}
				showLoading={isLoading}
				initialData={requestModalData}
				onClose={() => {
					setRequestModal((prevState: any) => ({ ...prevState, isOpen: false }));
					setRequestModalData(requestDataDefault);
				}}
				onSave={(data: RequestData, halfDayOptions: HalfDayOptions[]) => {
					handleSaveRequest(data, halfDayOptions, permissionTo, setIsLoading, () => {
						// On success
						loadGridData(curYear);
						loadStats(curYear);

						// Reset the modal
						setRequestModal({ isOpen: false });
						setRequestModalData(requestDataDefault);
					});
				}}
				onApproveDecline={() => {}}
				usageMode='dashboard'
				permissionTo={permissionTo}
			/>
		</>
	);
};

export default Requests;

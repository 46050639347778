import { useRef } from 'react';
import useClientDetailsArray from '../../Clients/arrays/clientDetailsArray';

const useKeyDetailsArray = (newJob: boolean) => {
	let keyDetails: Array<any> = [
		{
			title: 'Job Details',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'name',
			title: 'Job Name',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: newJob,
			db: ['name'],
		},
		{
			id: 'description',
			title: 'Job Description',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: newJob,
			db: ['description'],
		},
	];

	if (newJob !== true) {
		keyDetails = keyDetails.concat([
			{
				id: 'number',
				title: 'Job Number',
				type: 'infoBox',
				showBorder: true,
				showBackground: true,
				db: ['number'],
			},
		]);
	}

	keyDetails = keyDetails.concat([
		{
			id: 'job_type_id',
			title: 'Job Type',
			type: 'dropdownAsync',
			ref: useRef(),
			isMandatory: true,
			values: null,
			onChangeCallback: null,
			loadOptions: null,
			isSearchable: false,
			addNewLabel: 'Add New Job Type',
			handleAddNew: null,
			noForm: newJob,
			db: ['job_type_id'],
		},
		{
			id: 'manager_id',
			title: 'Job Manager',
			type: 'dropdown',
			ref: useRef(),
			isMandatory: true,
			values: null,
			onChangeCallback: null,
			noForm: newJob,
			db: ['manager_id'],
		},
	]);

	// Get and append the client details array to this form
	const clientDetailsArray = useClientDetailsArray(newJob, true, true);

	clientDetailsArray.clientDetails.map((c: any) => {
		if (c.db && Array.isArray(c.db)) c.db.unshift('client');
		return c;
	});

	keyDetails = keyDetails.concat(clientDetailsArray.clientDetails);

	// Finialise the form
	const jobAddressArray: Array<any> = [
		{
			title: 'Job Address',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'copy_job_address',
			title: 'Check box if same as above',
			type: 'copyAddress',
			groupFrom: 'client',
			groupTo: 'job',
			value: false,
			includeLatLong: true,
		},
		{
			// Address look-up fields must have auto-assigned IDs
			title: 'Address Lookup',
			type: 'addressLookup',
			id: 'address-lookup-job',
		},
		{
			title: 'Address 1',
			type: 'text',
			ref: useRef(),
			group: 'job',
			isMandatory: true,
			noForm: newJob,
			db: ['address', 'address_1'],
			subType: 'address_1',
		},
		{
			title: 'Address 2',
			type: 'text',
			ref: useRef(),
			group: 'job',
			isMandatory: false,
			noForm: newJob,
			db: ['address', 'address_2'],
			subType: 'address_2',
		},
		{
			title: 'Town',
			type: 'text',
			ref: useRef(),
			group: 'job',
			isMandatory: true,
			noForm: newJob,
			db: ['address', 'town'],
			subType: 'town',
		},
		{
			title: 'Postcode',
			type: 'postcode',
			ref: useRef(),
			group: 'job',
			isMandatory: true,
			noForm: newJob,
			db: ['address', 'postcode'],
			subType: 'postcode',
		},
		{
			title: 'Latitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting latitude',
			group: 'job',
			isMandatory: false,
			disabled: true,
			noForm: newJob,
			db: ['address', 'latitude'],
			subType: 'latitude',
		},
		{
			title: 'Longitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting longitude',
			group: 'job',
			isMandatory: false,
			disabled: true,
			noForm: newJob,
			db: ['address', 'longitude'],
			subType: 'longitude',
		},
		{
			title: 'Boundary Size',
			type: 'boundary',
			isMandatory: true,
			ref: useRef(),
			noForm: newJob,
			group: 'job',
			db: ['address'],
			custom: true,
			subType: 'boundary',
		},
	];

	keyDetails = keyDetails.concat(jobAddressArray);

	return { keyDetails };
};

export default useKeyDetailsArray;

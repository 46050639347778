import { useState, useEffect } from 'react';

import axios from '../../../lib/axios';
import { useSubscribe } from '../../../helpers/events';

interface Props {}

const ConversationsBadge: React.FC<Props> = (props: Props) => {
	const [count, setCount] = useState<string>('');

	useEffect(() => {
		getConversationCount();
	}, []);

	useSubscribe(
		'database:sync:Item',
		() => {
			getConversationCount();
		},
		[]
	);

	const getConversationCount = (): Promise<boolean> => {
		return new Promise((res, rej) => {
			// Fetch the navigation data
			axios.get('/api/conversations/count').then((result) => {
				setCount(String(result.data.count));
				res(true);
			});
		});
	};

	return count !== '' && count !== '0' ? (
		<div className='badge badge-number background-secondary'>{count}</div>
	) : null;
};

export default ConversationsBadge;

import { DateTime } from 'luxon';
import { PropCluster, PropMarker } from '../interfaces/Maps/MapsInterface';
import { IonButton } from '@ionic/react';
import { publish } from './events';
import styles from './../assets/scss/styles.module.scss';
import { mapLocations } from '../components/Maps/MapLocations';
import { getCenter } from 'geolib';
import { boundsFromLatLngList, getBoundsZoomLevel } from '../helpers/geo';
import axios from '../lib/axios';
import { titleCase } from './strings';
import { isset } from './typeFunctions';


export const expectedKeys = [
	'Sick',
	'Holiday',
	'Stop_work',
	'Start_break',
	'transit',
	'Home',
];

export const keyTransform: any = {
	Stop_work: 'Not Logged In',
	Start_break: 'On Break',
	Holiday: 'On Holiday',
	Sick: 'Off Sick',
	Transit: 'In Transit',
	Home: 'Home',
};

export type Subordinate = {
	worker_id: string;
	worker_name: string;
};

export const locations = async (
	mapRef: google.maps.Map | null,
	setLoading: Function,
	setMapCenter: Function,
	setZoom: Function,
	setIdleFunc: Function,
	setMarkers: Function,
	setClusters: Function,
	fromDashboard?: boolean
) => {
	setLoading(true);
	let localMarkers = await mapLocations(undefined, fromDashboard);

	let coords: Array<any> = [];
	localMarkers.markers.forEach((site: PropMarker) => {
		coords.push({
			lat: Number(site.latlng.lat),
			lng: Number(site.latlng.lng),
		});
	});
	localMarkers.cluster.forEach((site: PropMarker) => {
		coords.push({
			lat: Number(site.latlng.lat),
			lng: Number(site.latlng.lng),
		});
	});
	if (coords.length > 0) {
		const coordinate: any = getCenter(coords);

		const bounds = boundsFromLatLngList(coords);
		var w = mapRef?.getDiv().offsetWidth ?? 0;
		var h = mapRef?.getDiv().offsetHeight ?? 0;
		let localZoom = getBoundsZoomLevel(bounds, {
			height: h,
			width: w,
		});
		if (localZoom > 19) {
			localZoom = 19;
		}
		setMapCenter({ lat: coordinate.latitude, lng: coordinate.longitude });
		setZoom(isNaN(localZoom) ? 20 : localZoom);
	}

	setIdleFunc(() => {
		return () => {
			setMarkers(localMarkers.markers);
			setClusters([{ markers: localMarkers.cluster }]);
			setLoading(false);
			setIdleFunc(() => {
				return () => { };
			});
		};
	});
};

export const subscribeGPSWorkerUpdate = (
	data: any,
	clusters: Array<PropCluster>,
	setClusters: Function,
	locationData: any,
	setLocationData: Function,
	fromDashboard?: boolean,
	workerId?: string,
	subordinates?: Array<string>
) => {
	let localMarkers = [...clusters];
	if (localMarkers.length > 0) {
		let markerIndex = localMarkers[0].markers.findIndex((marker: PropMarker, index: number) => {
			return marker._id === data.item._id;
		});

		if (markerIndex === -1) {
			let worker = data.item;
			const dt = DateTime.fromISO(worker.datetime);
			const now = DateTime.now();
			let minutes = now.diff(dt, ['minutes']).toObject().minutes ?? 0;
			let color = minutes < 15 ? styles.success : styles.quaternary;
			let textColor = minutes < 15 ? styles.white : styles.white;
			var matches = worker.name.match(/\b(\w)/g); // ['J','S','O','N']
			var acronym = matches.join(''); // JSON
			let obj: PropMarker = {
				_id: worker._id,
				latlng: {
					lat: worker.location.coordinates[1],
					lng: worker.location.coordinates[0],
				},
				title: 'Worker: ' + worker.name,
				isOpen: false,
				canOpen: true,
				showRadius: false,
				accuracyFill: color,
				accuracyStroke: color,
				icon: {
					path: google.maps.SymbolPath.CIRCLE,
					scale: 25,
					fillColor: color,
					fillOpacity: 1,
					strokeColor: '',
					strokeWeight: 0,
				},
				label: {
					color: textColor,
					fontSize: '25px',
					text: acronym,
				},
				radius: worker.accuracy,
				infoComponent: (
					<>
						<p>Name: {worker.name}</p>
						<p>
							Last Seen: {dt.toLocaleString()} {dt.toFormat('HH:mm:ss')}
						</p>
						{!fromDashboard && (
							<div className='align-center'>
								<IonButton
									size='small'
									color='primary'
									onClick={() => {
										publish('message:worker', worker);
									}}
								>
									Message Worker
								</IonButton>
							</div>
						)}
					</>
				),
				date: dt,
			};
			localMarkers[0].markers.push(obj);
		}

		localMarkers[0].markers.forEach((marker: PropMarker, index: number) => {
			if (marker._id === data.item._id) {
				let newMarker = { ...marker };

				newMarker.radius = data.item.accuracy;
				newMarker.latlng = {
					lat: data.item.location.coordinates[1],
					lng: data.item.location.coordinates[0],
				};
				const dt = DateTime.fromISO(data.item.datetime);
				const now = DateTime.now();
				let minutes = now.diff(dt, ['minutes']).toObject().minutes ?? 0;
				let color = minutes < 15 ? styles.success : styles.quaternary;
				let textColor = minutes < 15 ? styles.white : styles.white;
				newMarker.infoComponent = (
					<>
						<p>Name: {data.item.name}</p>
						<p>
							Last Seen: {dt.toLocaleString()} {dt.toFormat('HH:mm:ss')}
						</p>
						{!fromDashboard && (
							<div className='align-center'>
								<IonButton
									size='small'
									color='primary'
									onClick={() => {
										publish('message:worker', data.item);
									}}
								>
									Message Worker
								</IonButton>
							</div>
						)}
					</>
				);
				newMarker.date = dt;
				newMarker.accuracyFill = color;
				newMarker.accuracyStroke = color;
				newMarker.icon = {
					path: google.maps.SymbolPath.CIRCLE,
					scale: 25,
					fillColor: color,
					fillOpacity: 1,
					strokeColor: '',
					strokeWeight: 0,
				};
				if (newMarker.label !== undefined && typeof newMarker.label !== 'string') {
					newMarker.label.color = textColor;
				}
				localMarkers[0].markers[index] = newMarker;
			} else {
				const dt = marker.date;
				const now = DateTime.now();
				let minutes = now.diff(dt, ['minutes']).toObject().minutes ?? 0;
				let color = minutes < 15 ? styles.success : styles.quaternary;
				let textColor = minutes < 15 ? styles.white : styles.white;
				let newMarker = { ...marker };
				newMarker.accuracyFill = color;
				newMarker.accuracyStroke = color;
				newMarker.icon = {
					path: google.maps.SymbolPath.CIRCLE,
					scale: 25,
					fillColor: color,
					fillOpacity: 1,
					strokeColor: '',
					strokeWeight: 0,
				};
				if (newMarker.label !== undefined && typeof newMarker.label !== 'string') {
					newMarker.label.color = textColor;
				}
				localMarkers[0].markers[index] = newMarker;
			}
		});

		// Filter workers based on the logged-in user's subordinates (team)
		if (fromDashboard === true) {
			localMarkers[0].markers = localMarkers[0].markers.filter(
				(worker: any) =>
					(subordinates && subordinates.indexOf(worker._id) >= 0) || worker._id === workerId
			);
		}

		setClusters(localMarkers);
	}

	let localData = removeWorker(data.item._id, locationData);
	let siteId = titleCase(data.item.site._id);
	let siteType = '_id';
	if (data.item.site.name === 'Home') {
		siteId = 'Home';
		siteType = 'location';
	} else if (siteId === 'Transit') {
		siteId = 'transit';
	}

	Object.keys(localData).forEach((key: string) => {
		if (localData[key].length > 0) {
			let breakIndex = localData[key].findIndex((ldata: any) => {
				return ldata[siteType] === siteId;
			});
			if (breakIndex > -1) {
				let workerIndex = localData[key][breakIndex].workers.findIndex((ldata: any) => {
					return ldata._id === data.item._id;
				});
				if (workerIndex === -1) {
					localData[key][breakIndex].workers.push({
						_id: data.item._id,
						name: data.item.name,
						date: data.item.datetime,
					});
				}
			}
		}
	});
	setLocationData(localData);
};

export const subscribeGPSWorkerStartBreak = (
	data: any,
	locationData: any,
	setLocationData: Function,
	clusters: Array<PropCluster>,
	setClusters: Function
) => {
	let localData = removeWorker(data.item._id, locationData);
	if (isset(localData['general']) && localData['general'].length > 0) {
		let breakIndex = localData['general'].findIndex((ldata: any, index: number) => {
			return ldata._id === 'Start_break';
		});
		if (breakIndex > -1) {
			let workerIndex = localData['general'][breakIndex].workers.findIndex((ldata: any) => {
				return ldata.name === data.item.worker;
			});
			if (workerIndex === -1) {
				localData['general'][breakIndex].workers.push({
					name: data.item.worker,
					_id: data.item._id,
					date: data.item.date,
				});
			}
		}
		setLocationData(localData);
	}

	removeMarker(data.item._id, clusters, setClusters);
};

export const removeWorker = (workerId: string, locationData: any) => {
	let localData = { ...locationData };
	Object.keys(localData).forEach((key: string) => {
		let breakIndex = localData[key].findIndex((ldata: any) => {
			let workerIndex: number = ldata.workers.findIndex((sdata: any) => {
				return sdata._id === workerId;
			});
			if (workerIndex > -1) {
				return true;
			} else {
				return false;
			}
		});

		if (breakIndex > -1) {
			let workerIndex = localData[key][breakIndex].workers.findIndex((ldata: any) => {
				return ldata._id === workerId;
			});

			if (workerIndex > -1) {
				localData[key][breakIndex].workers.splice(workerIndex, 1);
			}
		}
	});
	localData = addExpected(localData);
	return localData;
};

export const removeMarker = (
	workerId: string,
	clusters: Array<PropCluster>,
	setClusters: Function
) => {
	let localMarkers = [...clusters];
	if (localMarkers.length > 0) {
		let markerIndex = localMarkers[0].markers.findIndex((marker: PropMarker) => {
			return marker._id === workerId;
		});
		if (markerIndex > -1) {
			localMarkers[0].markers.splice(markerIndex, 1);
		}
		setClusters(localMarkers);
	}
};

export const getData = (setLocationData: Function): Promise<Array<any>> => {
	return new Promise((res, rej) => {
		axios
			.get('/api/workers/today')
			.then((response) => {
				let items = response.data;
				let keys: Array<any> = [];
				let arr: any = {};
				let promises = [];
				Object.keys(items).forEach((key) => {
					promises.push(new Promise((res1, rej1) => {
						let keyParts = key.split(':');
						let _id = key;
						let text = key;
						let sub: string = 'general';
						if (keyParts.length > 1) {
							_id = keyParts[1];
							text = keyParts[2];
							if (keyParts[0] !== 'home' && keyParts[0] !== 'transit' && text.toLowerCase() !== 'home') {
								sub = keyParts[0];
							} else if (text.toLowerCase() === 'home') {
								_id = 'Home:home:home';
								text = 'Home';
								keys.push(titleCase('Home'));
							}
						}
						if (arr[sub] === undefined || arr[sub] === null) {
							arr[sub] = [];
						}
						arr[sub].push({
							_id,
							location: keyTransform[titleCase(text)] ?? titleCase(text.replace('_', ' ')),
							workers: items[key].map((it: any) => {
								return { name: it.worker, _id: it._id, date: it.date ?? null };
							}),
						});
						keys.push(titleCase(key));
						res1(true);
					}));
				});
				promises.push(new Promise((res1, rej1) => {
					res1(true);
				}));
				Promise.all(promises).then(() => {
					arr = addExpected(arr);
					setLocationData(arr);
					res(arr);
				});
			})
			.catch((e) => {
				setLocationData([]);
				rej(e);
			});
	});
};

export const addExpected = (arr: any) => {
	if (isset(arr['general']) && arr['general'].length > 0) {
		let keys = arr['general'].map((item: any) => {
			return titleCase(item._id)
		});
		for (let key of expectedKeys) {
			if (!keys.includes(titleCase(key))) {
				arr['general'].push({
					_id: titleCase(key),
					location: keyTransform[titleCase(key)] ?? titleCase(key),
					workers: [],
				});
			}
		}
	}
	return arr;
}

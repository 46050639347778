import { useEffect, useRef, useState } from 'react';
import { IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { TextField } from '../../../../components/Forms/FormFields';
import axios from '../../../../lib/axios';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../../lib/toast';
import { toast } from 'react-toastify';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import NoteModal from '../../../Jobs/Jobs/modals/NoteModal';

interface Props {
	permissionTo: Function;
}

const NotesIndex: React.FC<Props> = (props: Props) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const newNoteRef = useRef<any>(null);
	const gridRef: any = useRef<any>();
	const { workerId }: any = useParams();
	const [presentAlert] = useIonAlert();

	// Note modal state
	const [noteModal, setNoteModal] = useState<any>({ isOpen: false });
	const [noteModalData, setNoteModalData] = useState<any>({});

	useEffect(() => {
		loadGridData();
	}, []);

	const colData: Array<any> = [
		{
			headerName: 'Note Date',
			field: 'note_date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			sort: 'desc',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			headerName: 'Person Entering Note',
			field: 'author',
			filter: 'agTextColumnFilter',
			width: 280,
			suppressSizeToFit: true,
		},
		{
			headerName: 'Content',
			field: 'note',
			filter: 'agTextColumnFilter',
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 50,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					props.permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		axios
			.delete(`/api/workers/workers_list/worker_card/notes/${id}`)
			.then((res) => {
				loadGridData();

				showToast('deleted', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		axios
			.post(`/api/workers/workers_list/worker_card/notes`, { worker_id: workerId })
			.then((res: any) => {
				setData(res.data);
				setGridLoading(false);
			});
	};

	const handleAddNote = (e: any) => {
		const newNote: string = newNoteRef.current.value.trim();

		if (newNote.length === 0) {
			showToast('error', 'Please enter a new note');
			return;
		}

		const toastID = toast.loading('Please wait...');

		const payload: any = {
			worker_id: workerId,
			note: newNote,
		};

		axios
			.put(`/api/workers/workers_list/worker_card/notes`, payload)
			.then(() => {
				loadGridData();

				// Reset
				newNoteRef.current.value = '';

				// Reset the search
				gridRef.current.clearSearch();

				showToast('saved', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const noteModalOpen = async (params: any) => {
		if (params.column.colId !== 'delete') {
			setNoteModalData({
				date: dateFormatter({
					data: { note_date: params.data.note_date },
					colDef: { field: 'note_date' },
				}),
				author: params.data.author,
				note: params.data.note,
			});
			setNoteModal({ isOpen: true });
		}
	};

	const noteModalOnClose = () => {
		setNoteModal({ isOpen: false });
	};

	return (
		<>
			<DataGrid
				ref={gridRef}
				title={'Notes'}
				cols={colData}
				data={data}
				cellClickedFunction={noteModalOpen}
				rowCursorPointer={true}
				exportButton={true}
				extraFooter={
					<IonRow>
						<IonCol size={'12'} className='ps-1 py-0'>
							<h5>Add a new note</h5>
						</IonCol>
						<IonCol size={'12'} className='px-0'>
							<TextField
								forwardRef={newNoteRef}
								useLabels={false}
								editMode={true}
								multiLine={true}
								noForm={true}
								handleUpdateValue={handleAddNote}
								value=''
							/>
						</IonCol>
						<IonCol size={'12'} className='text-right'>
							<IonButton color='success' onClick={handleAddNote} disabled={gridLoading}>
								Add
							</IonButton>
						</IonCol>
					</IonRow>
				}
			/>

			<NoteModal
				isOpen={noteModal.isOpen}
				onClose={noteModalOnClose}
				onDidDismiss={noteModalOnClose}
				initialData={noteModalData}
			/>
		</>
	);
};

export default NotesIndex;

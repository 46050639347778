import { useState, useEffect, useRef, MouseEventHandler } from 'react';
import {
	IonRow,
	IonCol,
	IonLabel,
	IonInput,
	IonCheckbox,
	IonButton,
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonTextarea,
	useIonAlert,
} from '@ionic/react';
import { DateTime, Info } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faRotateLeft, faXmark, faLaptop, faCog } from '@fortawesome/free-solid-svg-icons';
import { BlockPicker, Color } from 'react-color';
import { Datepicker } from '@mobiscroll/react';
import AsyncSelect from 'react-select/async';
import { ActionMeta } from 'react-select';
import {
	validateField,
	checkValidPostcode,
	checkValidEmail,
	checkPresent,
	checkValidDateISO,
	checkValidPassword,
} from './FormValidation';
import SelectStyled from '../../components/UI/SelectStyled';
import Form from './Form';
import axios from '../../lib/axios';
import Loading from '../../components/UI/Loading';
import { showToast } from '../../lib/toast';
import { publish } from '../../helpers/events';
import { enforceDecimalLimit } from '../../lib/functions';

import './FormFields.scss';

interface TextProps {
	id?: any;
	forwardRef?: any;
	title?: string;
	value: any;
	editMode?: boolean;
	useLabels?: boolean;
	multiLine?: boolean;
	placeholder?: string;
	handleUpdateValue?: Function;
	disabled?: boolean;
	group?: string;
	noForm?: boolean;
	onKeyUp?: Function;
	isMandatory?: boolean;
}

export const TextField: React.FC<TextProps> = (props: TextProps) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState(props.value);
	const [showUndo, setShowUndo] = useState(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	let fieldRef = useRef<any>(null);
	if (props.forwardRef) fieldRef = props.forwardRef;

	useEffect(() => {
		setIsEdit(props.editMode ?? false);
	}, [props.editMode]);

	useEffect(() => {
		setOriginalValue(props.value);
	}, [props.value]);

	const handleFieldBlur = (e: any) => {
		let curVal = e.target.value;
		let valid = validateField(e.target, checkPresent(curVal), true);

		if (props.noForm === true) return;

		if (valid === true) {
			let change = checkTempValue(curVal);

			if (change === true && props.handleUpdateValue) {
				props.handleUpdateValue(curVal);
				setUndoVal(originalValue);
				setOriginalValue(curVal);
				setShowUndo(true);
			}
		}
	};

	const handleFieldKeyPress = (e: any) => {
		if (e.key.toLowerCase() === 'enter') {
			e.preventDefault();

			if (props.noForm === true && props.handleUpdateValue) {
				let curVal = e.target.value;
				validateField(e.target, checkPresent(curVal), true);
				props.handleUpdateValue(curVal);
			} else {
				e.target.blur();
			}
		}
	};

	const checkTempValue = (val: any) => {
		if (val === originalValue) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateValue) {
			props.handleUpdateValue(undoVal);
			setOriginalValue(undoVal);
			setUndoVal(null);
			setShowUndo(false);
		}
	};

	const handleFieldKeyUp = (e: any) => {
		if (props.onKeyUp) {
			props.onKeyUp(e);
		}
	};

	// Render the element value
	let elementValue = originalValue || '';
	if (props.noForm === true) {
		// Directly use props.value instead of setOriginalValue to eliminate lag
		// on each re-render, e.g. when using onKeyUp to set state.
		elementValue = props.value;
	}

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				{showUndo && (
					<FontAwesomeIcon
						icon={faRotateLeft}
						className='icon-primary undo-icon'
						onClick={() => onUndoClick()}
					/>
				)}
				{props.multiLine === true ? (
					<IonTextarea
						ref={fieldRef}
						id={props.id}
						name={props.title}
						placeholder={props.placeholder ? props.placeholder : 'Enter here'}
						value={elementValue}
						onBlur={(e) => handleFieldBlur(e)}
						onKeyPress={(e) => handleFieldKeyPress(e)}
						onKeyUp={(e) => handleFieldKeyUp(e)}
						disabled={props.disabled}
						data-group={props.group}
					/>
				) : (
					<IonInput
						ref={fieldRef}
						id={props.id}
						name={props.title}
						placeholder={props.placeholder ? props.placeholder : 'Enter here'}
						value={elementValue}
						onBlur={(e: any) => handleFieldBlur(e)}
						onKeyPress={(e) => handleFieldKeyPress(e)}
						onKeyUp={(e) => handleFieldKeyUp(e)}
						disabled={props.disabled}
						data-group={props.group}
					/>
				)}
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<p>{props.value || ''}</p>
			</IonCol>
		</IonRow>
	);
};

interface NumberProps {
	fieldRef?: any;
	id?: any;
	title?: string;
	value: any;
	editMode: boolean;
	useLabels: boolean;
	handleUpdateValue?: Function;
	disabled?: boolean;
	hideSpinner?: boolean;
	noForm?: boolean;
	placeholder?: string;
	onChangeCallback?: Function;
	onBlurCallback?: Function;
	isMandatory?: boolean;
	decimalLimit?: number;
	disableEnterKey?: boolean;
	disableNegatives?: boolean;
	allowEmpty?: boolean;
	reloadComponent?: any;
}

export const NumberField: React.FC<NumberProps> = (props: NumberProps) => {
	let propValue = parseValue(props.value);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>(propValue);
	const [showUndo, setShowUndo] = useState(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	let otherProps: any = {};
	let fieldRef = useRef<any>(null);
	if (props.fieldRef) fieldRef = props.fieldRef;

	if (props.disableNegatives === true) {
		otherProps.min = 0;
		otherProps.oninput =
			'this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null';
	}

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	useEffect(() => {
		setOriginalValue(propValue);
	}, [props.value, props.reloadComponent]);

	function parseValue(val: any) {
		let output = null;

		// Check for decimalised number
		if (String(val).indexOf('.') > -1) {
			output = String(val);
		} else {
			output = Number(val);
		}

		return output;
	}

	const handleFieldBlur = (e: any) => {
		let curVal = parseValue(e.target.value);
		let change = checkTempValue(curVal);

		if (props.noForm === true && change === true) {
			setOriginalValue(curVal);
			if (props.onChangeCallback) props.onChangeCallback(curVal);
			if (props.onBlurCallback) props.onBlurCallback(e, curVal);
			return;
		}

		if (change === true && props.handleUpdateValue) {
			props.handleUpdateValue(curVal);
			if (props.onChangeCallback) props.onChangeCallback(curVal);
			if (props.onBlurCallback) props.onBlurCallback(e, curVal);
			setUndoVal(originalValue);
			setOriginalValue(curVal);
			setShowUndo(true);
		}
	};

	const handleFieldKeyPress = (e: any) => {
		if (e.key.toLowerCase() === 'enter' && props.disableEnterKey !== true) {
			e.preventDefault();

			if (props.noForm === true && props.handleUpdateValue) {
				let curVal = e.target.value;
				props.handleUpdateValue(curVal);
				if (props.onChangeCallback) props.onChangeCallback(curVal);
			} else {
				e.target.blur();
			}
		}
	};

	const handleFieldOnInput = (e: any) => {
		if (Number(props.decimalLimit) > 0) {
			fieldRef.current.value = enforceDecimalLimit(e.target.value, Number(props.decimalLimit));
		}
	};

	const checkTempValue = (val: any) => {
		if (val === originalValue) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateValue) {
			props.handleUpdateValue(undoVal);
			if (props.onChangeCallback) props.onChangeCallback(undoVal);
			setOriginalValue(undoVal);
			setUndoVal(null);
			setShowUndo(false);
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				{showUndo && (
					<FontAwesomeIcon
						icon={faRotateLeft}
						className='icon-primary undo-icon'
						onClick={() => onUndoClick()}
					/>
				)}
				<IonInput
					ref={fieldRef}
					id={props.id}
					type='number'
					className={`ion-number-input${props.hideSpinner === true ? ' hide-number-spinner' : ''}`}
					name={props.title}
					placeholder={'Enter value'}
					value={originalValue || (props.allowEmpty ? '' : 0)}
					onBlur={(e) => handleFieldBlur(e)}
					onKeyPress={(e) => handleFieldKeyPress(e)}
					onInput={(e) => handleFieldOnInput(e)}
					disabled={props.disabled}
					{...otherProps}
				/>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
				<IonLabel>{props.title}</IonLabel>
			</IonCol>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'9'}>
				<p>{propValue || ''}</p>
			</IonCol>
		</IonRow>
	);
};

interface PostcodeProps {
	fieldRef?: any;
	id?: any;
	title?: string;
	value: any;
	editMode: boolean;
	useLabels: boolean;
	handleUpdateValue?: Function;
	disabled?: boolean;
	group?: string;
	isMandatory?: boolean;
	noForm?: boolean;
}

export const PostcodeField: React.FC<PostcodeProps> = (props: PostcodeProps) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>(props.value);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	let fieldRef = useRef<any>(null);
	if (props.fieldRef) fieldRef = props.fieldRef;

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	useEffect(() => {
		setOriginalValue(props.value);
	}, [props.value]);

	const handleFieldBlur = (e: any) => {
		let curVal = e.target.value;
		let valid = validateField(e.target, checkValidPostcode(curVal), true);

		if (props.noForm === true) return;

		if (valid === true) {
			let change = checkTempValue(curVal);

			if (change === true && props.handleUpdateValue) {
				props.handleUpdateValue(curVal);
				setUndoVal(originalValue);
				setOriginalValue(curVal);
				setShowUndo(true);
			}
		}
	};

	const handleFieldKeyPress = (e: any) => {
		if (e.key.toLowerCase() === 'enter') {
			e.preventDefault();
			e.target.blur();
		}
	};

	const checkTempValue = (val: any) => {
		if (val === originalValue) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateValue) {
			props.handleUpdateValue(undoVal);
			setOriginalValue(undoVal);
			setUndoVal(null);
			setShowUndo(false);
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				{showUndo && (
					<FontAwesomeIcon
						icon={faRotateLeft}
						className='icon-primary undo-icon'
						onClick={() => onUndoClick()}
					/>
				)}
				<IonInput
					ref={fieldRef}
					id={props.id}
					name={props.title}
					placeholder={'Enter here'}
					value={originalValue || ''}
					onBlur={(e) => handleFieldBlur(e)}
					onKeyPress={(e) => handleFieldKeyPress(e)}
					disabled={props.disabled}
					data-group={props.group}
				/>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
				<IonLabel>{props.title}</IonLabel>
			</IonCol>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'9'}>
				<p>{props.value || ''}</p>
			</IonCol>
		</IonRow>
	);
};

interface PasswordProps {
	fieldRef?: any;
	id?: any;
	title?: string;
	value: any;
	editMode: boolean;
	useLabels: boolean;
	handleUpdateValue?: Function;
	disabled?: boolean;
	noSet?: boolean;
	isMandatory?: boolean;
}

export const PasswordField: React.FC<PasswordProps> = (props: PasswordProps) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>(props.value);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	const fieldID = Math.floor(Math.random() * 9999999999);
	let fieldRef = useRef<any>(null);
	if (props.fieldRef) fieldRef = props.fieldRef;

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	const handleOnClick = () => {
		if (fieldRef.current) {
			let curVal = fieldRef.current.value;
			let valid = validateField(fieldRef.current, checkValidPassword(curVal), true);

			if (valid === true) {
				let change = checkTempValue(curVal);

				if (change === true && props.handleUpdateValue) {
					props.handleUpdateValue(curVal);
					setUndoVal(originalValue);
					setOriginalValue(curVal);
					// Do not allow undo on password fields
					// setShowUndo(true);
				}
			}
		}
	};

	const handleFieldKeyPress = (e: any) => {
		if (e.key.toLowerCase() === 'enter') {
			e.preventDefault();
			handleOnClick();
		}
	};

	const checkTempValue = (val: any) => {
		if (val === originalValue) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateValue) {
			props.handleUpdateValue(undoVal);
			setOriginalValue(undoVal);
			setUndoVal(null);
			setShowUndo(false);
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
						<ul>
							<li>Minimum of 8 characters</li>
							<li>Must contain a symbol</li>
							<li>Must contain upper and lower case letters</li>
							<li>Must contain a number</li>
						</ul>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<IonRow>
					<IonCol
						size={props.noSet !== true ? '3' : '12'}
						className={`password-container${props.noSet === true ? ' p-0' : ''}`}
					>
						{showUndo && (
							<FontAwesomeIcon
								icon={faRotateLeft}
								className='icon-primary undo-icon'
								onClick={() => onUndoClick()}
							/>
						)}
						{/* This input captures annoying browser auto-fill behaviour */}
						<input
							type='text'
							name='ignore_username_autocomplete_capture'
							style={{
								width: '0px',
								height: '0px',
								overflow: 'hidden',
								border: '0px',
								margin: '0px',
								padding: '0px',
								float: 'left',
							}}
						/>
						<IonInput
							id={`${props.noSet !== true ? `password_${fieldID}` : props.id ?? 'password'}`}
							name={`${props.noSet !== true ? `password_${fieldID}` : props.id ?? 'password'}`}
							className='form-control'
							ref={fieldRef}
							type='password'
							placeholder='Enter a password'
							onKeyDown={(e) => handleFieldKeyPress(e)}
							disabled={props.disabled}
							autocomplete='new-password'
						/>
					</IonCol>
					{props.noSet !== true && (
						<IonCol size='9' className='p-0'>
							<IonButton onClick={() => handleOnClick()}>Set Password</IonButton>
						</IonCol>
					)}
				</IonRow>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
				<IonLabel>{props.title}</IonLabel>
			</IonCol>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'9'}>
				<p>Password hidden for security reasons</p>
			</IonCol>
		</IonRow>
	);
};

interface EmailProps {
	fieldRef?: any;
	title?: string;
	value: any;
	editMode: boolean;
	useLabels: boolean;
	handleUpdateValue?: Function;
	disabled?: boolean;
	isMandatory?: boolean;
	noForm?: boolean;
}

export const EmailField: React.FC<EmailProps> = (props: EmailProps) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>(props.value);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	let fieldRef = useRef<any>(null);
	if (props.fieldRef) fieldRef = props.fieldRef;

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	useEffect(() => {
		setOriginalValue(props.value);
	}, [props.value]);

	const handleFieldBlur = (e: any) => {
		let curVal = e.target.value;
		let valid = validateField(e.target, checkValidEmail(curVal), true);

		if (props.noForm === true) return;

		if (valid === true) {
			let change = checkTempValue(curVal);

			if (change === true && props.handleUpdateValue) {
				props.handleUpdateValue(curVal);
				setUndoVal(originalValue);
				setOriginalValue(curVal);
				setShowUndo(true);
			}
		}
	};

	const handleFieldKeyPress = (e: any) => {
		if (e.key.toLowerCase() === 'enter') {
			e.preventDefault();
			e.target.blur();
		}
	};

	const checkTempValue = (val: any) => {
		if (val === originalValue) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateValue) {
			props.handleUpdateValue(undoVal);
			setOriginalValue(undoVal);
			setUndoVal(null);
			setShowUndo(false);
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				{showUndo && (
					<FontAwesomeIcon
						icon={faRotateLeft}
						className='icon-primary undo-icon'
						onClick={() => onUndoClick()}
					/>
				)}
				<IonInput
					ref={fieldRef}
					name={props.title}
					placeholder={'Enter here'}
					value={originalValue || ''}
					onBlur={(e) => handleFieldBlur(e)}
					onKeyPress={(e) => handleFieldKeyPress(e)}
					disabled={props.disabled}
				/>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
				<IonLabel>{props.title}</IonLabel>
			</IonCol>
			<IonCol size={'12'} sizeMd={'6'} sizeLg={'9'}>
				<p>{props.value || ''}</p>
			</IonCol>
		</IonRow>
	);
};

export { PhoneField } from './PhoneField';

interface DropdownProps {
	id?: any;
	forwardRef?: any;
	className?: string;
	title?: string;
	values: Array<any>;
	editMode?: boolean;
	defaultValue?: Array<any>;
	useLabels?: boolean;
	handleUpdateSelect?: Function;
	disabled?: boolean;
	onChangeCallback?: Function;
	stylesExtra?: any;
	isSearchable?: boolean;
	isMandatory?: boolean;
	isMulti?: boolean;
	addNewFunction?: Function;
	noForm?: boolean;
	isClearable?: boolean;
	preChangeCallback?: Function;
}

export const Dropdown: React.FC<DropdownProps> = (props: DropdownProps) => {
	// Determine initial value
	const val =
		String(props.defaultValue).length > 0
			? props.defaultValue
			: props.isMulti === true
			? props.values
			: props.values[0];

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>(val);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);

	let noEditReturnValue = 'Nothing selected';
	if (
		val !== undefined &&
		val.length > 0 &&
		val.every((element: any) => element === null) !== true
	) {
		noEditReturnValue = val.map((v: any) => v.label).join(', ');
	}

	useEffect(() => {
		if (props.editMode) {
			setIsEdit(props.editMode);
		}
	}, [props.editMode]);

	const handleFieldChange = (e: any) => {
		let curVal: Array<any> = Array.isArray(e) ? e : [e];
		let valid = true;

		if (valid === true) {
			let change = checkTempValue(curVal);

			if (change === true && props.handleUpdateSelect) {
				if (props.preChangeCallback) {
					props.preChangeCallback(props.isMulti === true ? curVal : curVal[0]);
				}
				props.handleUpdateSelect(props.isMulti === true ? curVal : curVal[0]);
				if (props.onChangeCallback) {
					props.onChangeCallback(props.isMulti === true ? curVal : curVal[0]);
				}

				// We must set this before returning in case of no-form mode
				setOriginalValue(curVal);

				if (props.noForm === true) return;
				setUndoVal(originalValue);
				setShowUndo(true);
			}
		}
	};

	const checkTempValue = (val: any) => {
		if (originalValue === undefined || props.isMulti === true) return true;

		if (val[0].value === originalValue[0].value) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateSelect) {
			if (undoVal !== undefined) {
				if (props.preChangeCallback) {
					props.preChangeCallback(props.isMulti === true ? undoVal : undoVal[0]);
				}
				props.handleUpdateSelect(props.isMulti === true ? undoVal : undoVal[0]);
				if (props.onChangeCallback) {
					props.onChangeCallback(props.isMulti === true ? undoVal : undoVal[0]);
				}
				setOriginalValue(undoVal);
				setUndoVal(null);
				setShowUndo(false);
			} else {
				showToast('error', "Sorry, you can't undo back to no selection");
			}
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				{showUndo && (
					<FontAwesomeIcon
						icon={faRotateLeft}
						className='icon-primary undo-icon'
						onClick={() => onUndoClick()}
					/>
				)}
				<SelectStyled
					id={props.id && props.id}
					className={`react-select-container react-select-dropdown${
						props.isMulti ? '-multi' : ''
					} ${props.className ? props.className : ''}`}
					forwardRef={props.forwardRef && props.forwardRef}
					defaultValue={originalValue}
					value={originalValue}
					isSearchable={props.hasOwnProperty('isSearchable') ? props.isSearchable : true}
					name={props.title}
					options={props.values}
					onChange={(e: any) => {
						handleFieldChange(e);
					}}
					menuPortalTarget={document.body}
					isDisabled={props.disabled}
					stylesExtra={props.stylesExtra}
					isMulti={props.isMulti}
					isClearable={props.isClearable && props.isClearable}
				/>
				{props.addNewFunction && (
					<FontAwesomeIcon
						icon={faCog}
						className='icon-dark add-new-data-icon'
						onClick={() => props.addNewFunction && props.addNewFunction()}
					/>
				)}
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<p>{noEditReturnValue}</p>
			</IonCol>
		</IonRow>
	);
};

interface DropdownAsyncProps {
	id?: any;
	forwardRef?: any;
	className?: string;
	title?: string;
	values: Array<any>;
	editMode?: boolean;
	defaultValue?: any;
	useLabels?: boolean;
	handleUpdateSelect?: Function;
	disabled?: boolean;
	onChangeCallback?: Function;
	isSearchable?: boolean;
	isMandatory?: boolean;
	isMulti?: boolean;
	noForm?: boolean;
	isClearable?: boolean;
	loadOptions: any;
	addNewLabel?: string;
	handleAddNew?: MouseEventHandler<HTMLIonButtonElement> | null;
}

export const DropdownAsync: React.FC<DropdownAsyncProps> = (props: DropdownAsyncProps) => {
	// Determine initial value
	const val =
		String(props.defaultValue).length > 0
			? props.defaultValue
			: props.isMulti === true
			? props.values
			: props.values[0];

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>(val);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	const [keyVal, setKeyVal] = useState<number>(0);

	let noEditReturnValue = 'Nothing selected';
	if (val !== undefined && val.length > 0) {
		noEditReturnValue = val.map((v: any) => v.label).join(', ');
	}

	useEffect(() => {
		if (props.editMode) {
			setIsEdit(props.editMode);
		}
	}, [props.editMode]);

	const handleFieldChange = (e: any) => {
		let curVal: Array<any> = Array.isArray(e) ? e : [e];
		let valid = true;

		if (valid === true) {
			let change = checkTempValue(curVal);

			if (change === true && props.handleUpdateSelect) {
				props.handleUpdateSelect(props.isMulti === true ? curVal : curVal[0]);
				if (props.onChangeCallback) {
					props.onChangeCallback(props.isMulti === true ? curVal : curVal[0]);
				}

				if (props.noForm === true) return;

				setUndoVal(originalValue);
				setOriginalValue(curVal);
				setShowUndo(true);
			}
		}
	};

	const checkTempValue = (val: any) => {
		if (originalValue === undefined || props.isMulti === true) return true;

		if (val[0].value === originalValue[0].value) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateSelect) {
			if (undoVal !== undefined) {
				props.handleUpdateSelect(props.isMulti === true ? undoVal : undoVal[0]);
				if (props.onChangeCallback) {
					props.onChangeCallback(props.isMulti === true ? undoVal : undoVal[0]);
				}
				setOriginalValue(undoVal);
				setUndoVal(null);
				setShowUndo(false);
			} else {
				showToast('error', "Sorry, you can't undo back to no selection");
			}
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{showUndo && (
						<FontAwesomeIcon
							icon={faRotateLeft}
							className='icon-primary undo-icon'
							onClick={() => onUndoClick()}
						/>
					)}
					<div style={{ flex: 1 }}>
						<AsyncSelect
							key={keyVal}
							id={props.id && props.id}
							className={`react-select-container react-select-dropdownasync${
								props.isMulti ? '-multi' : ''
							} ${props.className ? props.className : ''}`}
							classNamePrefix='react-select'
							ref={props.forwardRef && props.forwardRef}
							defaultValue={originalValue}
							value={originalValue}
							isSearchable={props.hasOwnProperty('isSearchable') ? props.isSearchable : true}
							name={props.title}
							options={props.values}
							onChange={(newValue: any, actionMeta: ActionMeta<any>): void => {
								if ((newValue === null || newValue.length === 0) && actionMeta.action === 'clear') {
									// Trigger a new key value which also triggers a loadOptions data re-fetch
									setKeyVal((prevState: number) => (prevState = prevState + 1));
								} else {
									handleFieldChange(newValue);
								}
							}}
							menuPortalTarget={document.body}
							isDisabled={props.disabled}
							isMulti={props.isMulti}
							isClearable={props.isClearable && props.isClearable}
							defaultOptions={true}
							cacheOptions={false}
							loadOptions={props.loadOptions}
						/>
					</div>
					{props.handleAddNew && (
						<IonButton
							style={{ margin: '0 0 0 8px' }}
							color='primary'
							size='small'
							disabled={props.disabled}
							onClick={props.handleAddNew}
						>
							{props.addNewLabel ? props.addNewLabel : 'Add New'}
						</IonButton>
					)}
				</div>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<p>{noEditReturnValue}</p>
			</IonCol>
		</IonRow>
	);
};

interface ImageProps {
	id: string;
	db: string;
	class?: string;
	title: string;
	value?: any;
	editMode?: boolean;
	disabled?: boolean;
	handleImageUpload?: Function;
}

export const ImageUpload: React.FC<ImageProps> = (props: ImageProps) => {
	const [image, setImage] = useState<Array<any>>([]);
	const [imageURL, setImageURL] = useState<HTMLImageElement | null>(null);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const buttonId = props.id;
	const dbId = props.db[props.db.length - 1];

	if (!buttonId) throw Error('ImageUpload component must have an id!');
	if (!dbId) throw Error('ImageUpload component must have a db id!');

	useEffect(() => {
		setIsEdit(props.editMode ?? false);
	}, [props.editMode]);

	useEffect(() => {
		// Check for a supplied image
		if (props.value) {
			let newImage = new Image();
			newImage.src = `${process.env.REACT_APP_API_URL}/api/files/${props.value}`;
			setImageURL(newImage);
		}
	}, [props.value]);

	useEffect(() => {
		if (image.length > 0) {
			let newImage = new Image();
			newImage.src = URL.createObjectURL(image[0]);
			newImage.src = newImage.src.toString();
			setImageURL(newImage);
		}
	}, [image]);

	const triggerUpload = () => {
		(document.getElementById(buttonId) as HTMLElement).click();
	};

	const handleUpdateImage = (e: any) => {
		if (props.handleImageUpload && e.target.files.length > 0) {
			setImage(e.target.files);
			props.handleImageUpload(e, dbId);
		}
	};

	return isEdit ? (
		<>
			<h5 className='font-bold form-title'>{props.title}</h5>
			{(imageURL || props.value) && (
				<div className={`form-image-wrapper${props.class ? ` ${props.class}` : ''}`}>
					<img
						src={(imageURL && imageURL.src) || props.value}
						alt={props.title}
						className={`${props.class ? ` ${props.class}` : ''}`}
					/>
				</div>
			)}
			<div className='upload-button-wrapper'>
				<IonButton
					color='primary'
					size='small'
					className='upload-button'
					onClick={triggerUpload}
					disabled={props.disabled}
				>
					<input
						hidden
						id={buttonId}
						type='file'
						accept='image/png, image/jpeg'
						onChange={handleUpdateImage}
						disabled={props.disabled}
					/>
					<FontAwesomeIcon icon={faImage} className='button-icon' />
					Upload Image
				</IonButton>
				<span className='form-button-caption'>Please select an image</span>
			</div>
		</>
	) : (
		<>
			<h5 className='font-bold form-title'>{props.title}</h5>
			{!((imageURL && imageURL.src) || props.value) && (
				<h5 className='text-secondary'>No image uploaded</h5>
			)}
			{((imageURL && imageURL.src) || props.value) && (
				<img src={(imageURL && imageURL.src) || props.value} alt={props.title} />
			)}
		</>
	);
};

interface FileUploadProps {
	id: string;
	db: string;
	class?: string;
	title: string;
	value?: any;
	editMode?: boolean;
	disabled?: boolean;
	handleFileUpload?: Function;
	useLabels: boolean;
	isMandatory?: boolean;
	miniUi?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
	const uploadInputRef: any = useRef(null);
	const [fileUploadReturn, setFileUploadReturn] = useState<Promise<boolean> | null>(null);
	const [fileData, setFileData] = useState<any>();
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const buttonId = props.id;
	const dbId = props.db[props.db.length - 1];

	if (!buttonId) throw Error('FileUpload component must have an id!');
	if (!dbId) throw Error('FileUpload component must have a db id!');

	useEffect(() => {
		setIsEdit(props.editMode ?? false);
	}, [props.editMode]);

	useEffect(() => {
		// Check for a supplied file
		if (props.value) {
			setFileData(props.value);
		}
	}, [props.value]);

	const triggerUpload = () => {
		(document.getElementById(buttonId) as HTMLElement).click();
	};

	const triggerDownload = () => {
		let link = document.createElement('a');
		link.href = fileData.file_path;
		link.download = fileData.file_name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleUpdateFile = (e: any) => {
		if (props.handleFileUpload && e.target.files.length > 0) {
			setFileUploadReturn(props.handleFileUpload(e, dbId));
		}
	};

	// Check for when the file upload handler returns
	useEffect(() => {
		if (fileUploadReturn) {
			fileUploadReturn.then((res: any) => {
				if (res === true && uploadInputRef.current) {
					// Clear the input value to allow the same file to be uploaded again
					uploadInputRef.current.value = null;
					setFileUploadReturn(null);
				}
			});
		}
	}, [fileUploadReturn]);

	const mimeTypes: Array<string> = [
		'image/png',
		'image/jpeg',
		'image/tiff',
		'text/plain',
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	];

	const btnUpload = (
		<IonButton color='primary' size='small' onClick={triggerUpload} disabled={props.disabled}>
			<input
				ref={uploadInputRef}
				hidden
				id={buttonId}
				type='file'
				accept={mimeTypes.join(', ')}
				onChange={handleUpdateFile}
				disabled={props.disabled}
			/>
			Upload File
		</IonButton>
	);

	let output = <></>;

	if (isEdit) {
		if (props.miniUi) {
			output = btnUpload;
		} else {
			output = (
				<>
					<IonRow className='prop-form prop-form-edit'>
						{props.useLabels !== false && (
							<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
								<IonLabel
									position='floating'
									className={`${props.isMandatory ? 'is-mandatory' : ''}`}
								>
									<span className='field-title'>{props.title}</span>
								</IonLabel>
							</IonCol>
						)}
						<IonCol
							size={'12'}
							sizeMd={props.useLabels === false ? '12' : '6'}
							sizeLg={props.useLabels === false ? '12' : '9'}
							className='prop-form-file-upload-controls-container'
						>
							<>
								<div className='form-info-box-container'>
									<div className='form-info-box-output info-box-border'>
										<p>{fileData ? fileData.file_name : 'None'}</p>
									</div>
								</div>
								{btnUpload}
								<IonButton
									color='theme-beta-quaternary-dark'
									size='small'
									onClick={triggerDownload}
									disabled={!fileData || props.disabled}
								>
									Download File
								</IonButton>
							</>
						</IonCol>
					</IonRow>
				</>
			);
		}
	}

	return output;
};

interface CardProps {
	title: string;
	children?: Array<any>;
	formProps: any;
}

export const CardSection: React.FC<CardProps> = (props: CardProps) => {
	const [items, setItems] = useState<any>(null);
	const [useCols, setUseCols] = useState(false);

	// Always regenerate the form if any of the parent form props change
	useEffect(() => {
		if (props.children) {
			setItems(
				<Form
					{...props.formProps}
					array={props.children}
					noButton={true}
					forceEdit={props.formProps.forceEdit}
				/>
			);
		}
	}, [props.children, props.formProps]);

	useEffect(() => {
		let table = document.getElementById('demo-table');
		if (table) {
			setUseCols(true);
			let parent = table.closest('ion-col');
			(parent as HTMLElement).removeChild(table);
			let secondCol = document.getElementById('card-second-col');
			(secondCol as HTMLElement).appendChild(table);
		}
	}, []);

	return (
		<IonCard className='m-0 form-card'>
			{props.title && <IonCardHeader className='form-card-header'>{props.title}</IonCardHeader>}
			<IonCardContent>
				<IonRow>
					<IonCol size={useCols ? '7' : '12'} className='pe-3'>
						{items}
					</IonCol>
					<IonCol size={useCols ? '5' : '0'} id='card-second-col' className='ps-3'></IonCol>
				</IonRow>
			</IonCardContent>
		</IonCard>
	);
};

interface ColorProps {
	title: string;
	color?: Color;
}

export const ColorPicker: React.FC<ColorProps> = (props: ColorProps) => {
	return (
		<div className='color-picker'>
			<h5 className='font-bold'>{props.title}</h5>
			<BlockPicker color={props.color} />
		</div>
	);
};

interface ColorInputProps {
	id?: string;
	onChange: Function;
	value?: string;
}

export const ColorInput: React.FC<ColorInputProps> = (props: ColorInputProps) => {
	return (
		<label
			className='color-input-picker-label'
			htmlFor={props.id}
			style={{ backgroundColor: props.value }}
		>
			<input
				id={props.id}
				type='color'
				className='colour-input-picker'
				onChange={(e: any) => props.onChange(e)}
				value={props.value}
			/>
		</label>
	);
};

interface DateProps {
	id?: any;
	title?: string;
	value?: any;
	editMode?: boolean;
	useLabels?: boolean;
	handleUpdateValue?: Function;
	disabled?: boolean;
	innerRef?: any;
	noForm?: boolean;
	onChangeCallback?: Function;
	isMandatory?: boolean;
	placeholder?: string;
	returnFormat?: any;
	defaultToday?: boolean;
}

export const SingleDatePicker: React.FC<DateProps> = (props: DateProps) => {
	// Parse the provided date
	let val = props.value;

	// ISO date
	if (val && val.indexOf('T') > -1) {
		val = DateTime.fromISO(val).toFormat('yyyy-MM-dd HH:mm:ss');
	}

	if (!val && props.defaultToday === true) {
		val = DateTime.now().startOf('day').toFormat('yyyy-MM-dd HH:mm:ss');
	}

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>(val);
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	const [dayNames, setDayNames] = useState<Array<string>>([]);

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	useEffect(() => {
		if (props.editMode) {
			setIsEdit(props.editMode);
		}
	}, [props.editMode]);

	const handleFieldChange = (e: any) => {
		let curVal = '';
		let valid = false;

		// Build the current valud based on the date format
		switch (props.returnFormat) {
			case 'iso8601':
				curVal = DateTime.fromISO(e.value).toFormat('yyyy-MM-dd') + ' 00:00:00';
				break;
			default:
				curVal = DateTime.fromJSDate(e.value).toFormat('yyyy-MM-dd') + ' 00:00:00';
				break;
		}

		valid = validateField(e.inst._el, checkValidDateISO(curVal), false);

		if (props.noForm === true) {
			let change = checkTempValue(curVal);
			if (change === true && props.onChangeCallback) {
				props.onChangeCallback(curVal);
			}

			setOriginalValue(curVal);
			return;
		}

		if (props.onChangeCallback) props.onChangeCallback(curVal);

		if (valid === true) {
			let change = checkTempValue(curVal);

			if (change === true && props.handleUpdateValue) {
				props.handleUpdateValue(curVal);
				setUndoVal(originalValue);
				setOriginalValue(curVal);
				setShowUndo(true);
			}
		}
	};

	const checkTempValue = (val: any) => {
		if (val === originalValue) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateValue) {
			props.handleUpdateValue(undoVal);
			if (props.onChangeCallback) props.onChangeCallback(undoVal);
			setOriginalValue(undoVal);
			setUndoVal(null);
			setShowUndo(false);
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating' className={`${props.isMandatory ? 'is-mandatory' : ''}`}>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				{showUndo && (
					<FontAwesomeIcon
						icon={faRotateLeft}
						className='icon-primary undo-icon'
						onClick={() => onUndoClick()}
					/>
				)}
				<Datepicker
					dayNamesMin={dayNames}
					id={props.id && props.id}
					ref={props.innerRef ? props.innerRef : null}
					onChange={(e) => {
						handleFieldChange(e);
					}}
					controls={['calendar']}
					inputComponent='input'
					inputProps={{
						placeholder: props.placeholder ? props.placeholder : 'Please select...',
						className: 'prop-form-date',
						disabled: props.disabled,
					}}
					value={originalValue}
					returnFormat={props.returnFormat ? props.returnFormat : 'jsdate'}
				/>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<p>
					{(val && DateTime.fromFormat(val, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy')) || ''}
				</p>
			</IonCol>
		</IonRow>
	);
};

interface CopyAddressProps {
	title: string;
	value: any;
	editMode: boolean;
	checkboxFunction: Function;
	disabled: boolean;
}

export const CopyAddress: React.FC<CopyAddressProps> = (props: CopyAddressProps) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			<IonCol size={'12'}>
				<IonCheckbox
					className='lowered'
					value={props.value}
					onIonChange={(e) => props.checkboxFunction(e.target.checked)}
					disabled={props.disabled}
				></IonCheckbox>
				<IonLabel position='floating'>{props.title}</IonLabel>
			</IonCol>
		</IonRow>
	) : null;
};

export { AddressLookup } from './AddressLookup';

interface ItemListProps {
	id: string;
	title: string;
	value?: any;
	editMode: boolean;
	placeholder?: string;
	useLabels: boolean;
	disabled?: boolean;
	permissionTo: Function;
	endpointID?: string;
}

export const ItemList: React.FC<ItemListProps> = (props: ItemListProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [devices, setDevices] = useState<Array<any>>([]);
	const [presentAlert] = useIonAlert();

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	useEffect(() => {
		loadDevicesList();
	}, []);

	const loadDevicesList = () => {
		setIsLoading(true);
		axios
			.get(`/api/utilities/security_settings/devices/${props.endpointID}`)
			.then((res) => {
				if (res.data) {
					setDevices(res.data);
				}
			})
			.catch((err) => setDevices([]))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleDeleteDevice = (deviceID: string) => {
		presentAlert({
			header: 'Delete Device',
			message: 'Are you sure you want to delete this device?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteDeviceGo(deviceID);
					},
				},
			],
		});
	};

	const handleDeleteDeviceGo = (deviceID: string) => {
		axios
			.delete(`/api/utilities/security_settings/devices/${props.endpointID}`, {
				data: {
					device_id: deviceID,
				},
			})
			.then((res) => {
				if (res.data.logout === true) {
					publish('logout', true);
				}
			})
			.catch((err) => showToast('error'))
			.finally(() => loadDevicesList());
	};

	return (
		<>
			<IonRow className='prop-form prop-form-edit'>
				{props.useLabels !== false && (
					<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
						<IonLabel position='floating'>{props.title}</IonLabel>
					</IonCol>
				)}
				<IonCol
					size={'12'}
					sizeMd={props.useLabels === false ? '12' : '6'}
					sizeLg={props.useLabels === false ? '12' : '9'}
				>
					<div className='item-list'>
						{isLoading && <Loading height={100} />}
						{!isLoading && devices.length === 0 && <span>No devices approved</span>}
						{!isLoading && devices.length > 0 && (
							<ul>
								{devices.map((device, i) => (
									<li key={i}>
										<label>
											<FontAwesomeIcon
												className={`icon-device${!isEdit ? '-no-edit' : ''}`}
												icon={faLaptop}
											/>
											<span>Device ID: {device.fingerprint}</span>
										</label>
										{isEdit && props.permissionTo('delete') && (
											<IonButton
												className='item-btn'
												color='danger'
												disabled={props.disabled}
												onClick={() => {
													handleDeleteDevice(device.id);
												}}
											>
												<FontAwesomeIcon icon={faXmark} />
											</IonButton>
										)}
									</li>
								))}
							</ul>
						)}
					</div>
				</IonCol>
			</IonRow>
		</>
	);
};

interface InfoProps {
	id?: string;
	title?: string;
	value: any;
	useLabels: boolean;
	disabled?: boolean;
	showBorder?: boolean;
	multiLine?: boolean;
	showBackground?: boolean;
	showSpinner?: boolean;
	style?: any;
	className?: string;
}

export const InfoBox: React.FC<InfoProps> = (props: InfoProps) => {
	const [output, setOutput] = useState<any>(props.value);
	const outputContainerProps: any = {};
	const outputContainerClasses: Array<string> = ['form-info-box-container'];
	const outputProps: any = { className: 'form-info-box-output' };

	// Build classes - container
	if (props.showBorder) outputContainerClasses.push('info-box-border');
	if (props.showBackground) outputContainerClasses.push('info-box-show-background');
	if (props.multiLine) outputContainerClasses.push('info-box-multi-line');
	if (props.className)
		outputContainerClasses.push(
			props.className
				.split(' ')
				.map((cls: string) => 'info-box-' + cls)
				.join(' ')
		);

	// Build properties
	if (props.id) outputProps.id = props.id;

	if (props.multiLine) {
		outputProps.style = { whiteSpace: 'pre-wrap', lineHeight: '1.45em' };
	} else {
		outputProps.style = { overflow: 'hidden', height: '31px' };
	}

	if (props.style) {
		outputContainerProps.style = { ...props.style };
	}

	useEffect(() => {
		setOutput(props.value);
	}, [props.value]);

	const finalOutput = () => {
		let content: any = output ? output : '';
		if (props.showSpinner) {
			outputProps.style.textAlign = 'center';
			content = <FontAwesomeIcon icon={faCog} className='fa-spin' />;
		}
		return <p {...outputProps}>{content}</p>;
	};

	return (
		<IonRow className='prop-form'>
			{props.useLabels && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={`${props.useLabels ? 6 : 12}`}
				sizeLg={`${props.useLabels ? 9 : 12}`}
				className='form-info-box-col'
			>
				<div className={outputContainerClasses.join(' ')} {...outputContainerProps}>
					{finalOutput()}
				</div>
			</IonCol>
		</IonRow>
	);
};

export { AutoComplete } from './AutoComplete';
export { Boundary } from './Boundary';
export { YesNoValue } from './YesNoValue';

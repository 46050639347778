import { DateTime } from 'luxon';

export enum SickStatus {
	REQUESTED = 'requested',
	APPROVED = 'approved',
	DECLINED = 'declined',
}

export enum EventType {
	SICK = 'sick',
}

export interface SickDate {
	start: string;
	end: string;
}

export interface SickData {
	requestId: string | undefined;
	workerId: string | undefined;
	workerName?: string;
	description: string;
	requestedDate: DateTime;
	start?: DateTime;
	end?: DateTime;
	startHourIndex?: number;
	endHourIndex?: number;
	period: Number;
	uploaded?: boolean;
	fileName?: string;
	filePath?: string;
	status: SickStatus;
	managersNotes?: string;
}

export interface SickPayload {
	event_type: EventType;
	worker_ids: Array<string>;
	description: string;
	requested_date: DateTime;
	start: DateTime;
	end: DateTime;
	status: SickStatus;
}

export interface HalfDayOptions {
	label: string;
	value: number;
}

import { useState, useEffect } from 'react';
import { requestsArray } from './requestsArray';
import Subnav from '../../../../components/Menu/Subnav';

interface Props {
	parentRef: any;
	permissionTo: Function;
}

const RequestsIndex: React.FC<Props> = (props: Props) => {
	const [subnav, setSubnav] = useState<Array<any>>([]);

	useEffect(() => {
		let array = requestsArray.map((item) => {
			item.active = false;
			return item;
		});
		array[0].active = true;
		setSubnav(array);
	}, []);

	return <>{subnav.length > 0 && <Subnav array={subnav} parentRef={props.parentRef} />}</>;
}

export default RequestsIndex;

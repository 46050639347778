import React, { useContext, useEffect, useRef, useState } from 'react';
import { faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonCol,
	IonRow,
	IonGrid,
	IonLabel,
	IonInput,
	IonRadioGroup,
	IonRadio,
	IonTextarea,
} from '@ionic/react';
import {
	Clash,
	EventType,
	HalfDayOptions,
	RequestAction,
	RequestData,
	RequestStatus,
} from '../Requests/request-types';
import { DateTime, Info } from 'luxon';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { Datepicker } from '@mobiscroll/react';
import { cloneDeep } from 'lodash';
import Loading from '../../../../components/UI/Loading';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { InfoBox } from '../../../../components/Forms/FormFields';
import { buildHalfDayOptions, getNoOfDays } from '../../../../lib/functions';
import { showToast } from '../../../../lib/toast';

interface RequestModalProps {
	isOpen: boolean;
	initialData: RequestData;
	onClose: Function;
	onSave: Function;
	onApproveDecline: Function;
	onDelete?: Function;
	permissionTo: Function;
	showLoading: boolean;
	usageMode?: 'dashboard' | 'workerCard';
}

const RequestModal: React.FC<RequestModalProps> = ({
	isOpen,
	initialData,
	onClose,
	onSave,
	onApproveDecline,
	onDelete,
	permissionTo,
	showLoading,
	usageMode,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [title, setTitle] = useState<any>('');
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const [halfDayOptionsLoading, setHalfDayOptionsLoading] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<RequestData>();
	const [workers, setWorkers] = useState<Array<{ label: string; value: string }>>();
	const [halfDayOptions, setHalfDayOptions] = useState<Array<HalfDayOptions>>([]);
	const [isNew, setIsNew] = useState<boolean>(true);
	const [declinePressed, setDeclinePressed] = useState<boolean>(false);
	const [dayNames, setDayNames] = useState<Array<string>>([]);
	const infoBoxColStyle = { padding: '1px 0 0 0' };
	const refWorkingHoursStart = useRef<any>(null);
	const refWorkingHoursEnd = useRef<any>(null);

	// Local constants to block multiple loads upon modal presenting
	let _workersLoading = false;
	let _halfDayOptionsLoading = false;

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	// Mask the modal if waiting for an operation to complete in the parent
	useEffect(() => {
		setIsLoading(showLoading);
	}, [showLoading]);

	// Global loading signal
	useEffect(() => {
		if (isNew) {
			setIsLoading(workersLoading || halfDayOptionsLoading);
		}
	}, [workersLoading, halfDayOptionsLoading]);

	// Date validation and number of days
	useEffect(() => {
		// Make sure dates are in valid order
		if (data?.start && data?.end) {
			if (data.start > data.end) {
				showToast('warning', 'Please ensure the start date is on or before the end date');
				return;
			}

			setData((prevState: any) => ({
				...prevState,
				period: getNoOfDays([data.start!, data.end!], true, [
					data.startHourIndex!,
					data.endHourIndex!,
				]),
			}));
		}
	}, [data?.start, data?.end, data?.startHourIndex, data?.endHourIndex]);

	const handleWorkerChange = (workerId: string) => {
		setHalfDayOptionsLoading(true);
		moduleCtx
			.getHalfDayOptions(workerId)
			.then((res: any) => {
				setHalfDayOptions(res);
			})
			.finally(() => {
				setHalfDayOptionsLoading(false);
			});
	};

	const handleOnWillPresent = () => {
		// Reset
		setDeclinePressed(false);

		// Must clone the initial data into the new state variable or changes affect the initial data
		setData(cloneDeep(initialData));

		// Load the half day options - debounced with local variable
		if (!_halfDayOptionsLoading) {
			_halfDayOptionsLoading = true;
			setHalfDayOptionsLoading(_halfDayOptionsLoading);
			moduleCtx
				.getHalfDayOptions(initialData.workerId ? initialData.workerId : null)
				.then((res: any) => {
					setHalfDayOptions(res);
				})
				.finally(() => {
					_halfDayOptionsLoading = false;
					setHalfDayOptionsLoading(_halfDayOptionsLoading);
				});
		}

		// Setup
		if (!initialData.requestId) {
			setIsNew(true);
			setTitle('New Request');

			// Get workers dropdown options unless we already know the workerId
			if (!_workersLoading && !initialData.workerId) {
				_workersLoading = true;
				setWorkersLoading(_workersLoading);
				moduleCtx
					.getWorkersOptions()
					.then((res: any) => {
						setWorkers(res);
					})
					.finally(() => {
						_workersLoading = false;
						setWorkersLoading(_workersLoading);
					});
			} else {
				setWorkersLoading(false);
			}
		} else if (initialData.requestId) {
			setIsNew(false);

			switch (initialData.status) {
				case RequestStatus.REQUESTED:
					setTitle(
						<>
							<p>
								{initialData?.clashes && initialData.clashes.length > 0 && (
									<FontAwesomeIcon icon={faWarning} className='pe-2' />
								)}
								Approve Request
								{initialData?.clashes && initialData.clashes.length > 0 && ' (Clash Detected)'}
							</p>
						</>
					);
					break;
				case RequestStatus.APPROVED:
					setTitle('Approved Request');
					break;
				case RequestStatus.DECLINED:
					setTitle('Declined Request');
					break;
			}
		}
	};

	const handleOnDidDismiss = () => {
		// Close the modal
		onClose();
	};

	const handleDeclineRequest = () => {
		const infoMsg = 'Please provide a reason for declining this request';

		if (!declinePressed) {
			setDeclinePressed(true);
			showToast('info', infoMsg);
			return;
		}

		if (!data?.decisionReason || data.decisionReason.trim().length === 0) {
			showToast('error', infoMsg);
		} else {
			onApproveDecline(data, RequestAction.DECLINE);
		}
	};

	const handleDeleteRequest = () => {
		if (permissionTo('delete') && onDelete) {
			onDelete(data);
		}
	};

	return (
		<IonModal
			style={{ '--width': '50vw', '--height': '60vh' }}
			isOpen={isOpen}
			onWillPresent={handleOnWillPresent}
			onDidDismiss={handleOnDidDismiss}
			className={`prop-form hatrequest-request-modal${usageMode ? ' usage-mode-' + usageMode : ''}`}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{title}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => handleOnDidDismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='tight-margins-modal prop-form'>
				{isLoading && <Loading overlay={true} />}
				<IonGrid>
					{!initialData.workerId && (
						<IonRow>
							<IonCol size='4'>
								<IonLabel>Worker Name</IonLabel>
							</IonCol>
							{!isNew && (
								<IonCol size='8' style={infoBoxColStyle}>
									<InfoBox
										title='Worker Name'
										value={data?.workerName}
										useLabels={false}
										showBorder={true}
										showBackground={true}
									/>
								</IonCol>
							)}
							{isNew && (
								<IonCol size='8'>
									<SelectStyled
										className='react-select-container'
										classNamePrefix='react-select'
										options={workers}
										onChange={(newValue: any) => {
											handleWorkerChange(newValue.value);
											setData((prevState: any) => ({
												...prevState,
												workerId: newValue.value,
											}));
										}}
										value={workers?.filter((option: any) => option.value === data?.workerId)}
									/>
								</IonCol>
							)}
						</IonRow>
					)}
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Request Type</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='Request Type'
									value={data!.requestType}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8' style={{ padding: '7px 0 2px 5px' }}>
								<IonRadioGroup
									onIonChange={(e: any) =>
										setData((prevState: any) => ({ ...prevState, requestType: e.detail.value }))
									}
									value={data?.requestType}
								>
									<IonRow>
										<IonCol className='ps-0 flex-grow-0 text-nowrap'>Holiday</IonCol>
										<IonCol className='pe-3 flex-grow-0'>
											<IonRadio value={EventType.HOLIDAY} />
										</IonCol>
										<IonCol className='flex-grow-0 text-nowrap'>Approved Absence</IonCol>
										<IonCol className='flex-grow-0'>
											<IonRadio value={EventType.APPROVED_ABSENCE} />
										</IonCol>
									</IonRow>
								</IonRadioGroup>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Requested Date</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='Requested Date'
									value={data?.requestedDate.toFormat('dd/MM/yyyy')}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8'>
								<Datepicker
									dayNamesMin={dayNames}
									controls={['calendar']}
									firstDay={1}
									defaultValue={isNew ? data?.requestedDate : null}
									inputComponent={IonInput}
									inputProps={{
										placeholder: 'Please select...',
									}}
									returnFormat={'jsdate'}
									onChange={(e: any) => {
										setData((prevState: any) => {
											return {
												...prevState,
												requestedDate: e.value ? DateTime.fromJSDate(e.value).startOf('day') : null,
											};
										});
									}}
								/>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>Start</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='Start'
									value={data?.start ? data.start.toFormat('dd/MM/yyyy @ HH:mm') : null}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8'>
								<IonRow>
									<IonCol className='p-0 pe-2 flex-grow-1'>
										<Datepicker
											dayNamesMin={dayNames}
											controls={['calendar']}
											firstDay={1}
											defaultValue={isNew ? data?.start : null}
											inputComponent={IonInput}
											inputProps={{
												placeholder: 'Please select...',
											}}
											returnFormat={'jsdate'}
											onChange={(e: any) => {
												setData((prevState: any) => {
													return {
														...prevState,
														start: e.value ? DateTime.fromJSDate(e.value).startOf('day') : null,
													};
												});
											}}
										/>
									</IonCol>
									<IonCol className='p-0 flex-grow-0 half-day-col'>
										<SelectStyled
											className='react-select-container'
											classNamePrefix='react-select'
											forwardRef={refWorkingHoursStart}
											options={buildHalfDayOptions(
												halfDayOptions,
												refWorkingHoursStart,
												data?.start ? data.start.toFormat('ccc').toLowerCase() : null,
												'start'
											)}
											onChange={(newValue: any) => {
												if (newValue.value !== data?.startHourIndex) {
													setData((prevState: any) => ({
														...prevState,
														startHourIndex: newValue.value,
													}));
												}
											}}
										/>
									</IonCol>
								</IonRow>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>End</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='End'
									value={data?.end ? data.end.toFormat('dd/MM/yyyy @ HH:mm') : null}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8'>
								<IonRow>
									<IonCol className='p-0 pe-2 flex-grow-1'>
										<Datepicker
											dayNamesMin={dayNames}
											controls={['calendar']}
											firstDay={1}
											defaultValue={isNew ? data?.end : null}
											inputComponent={IonInput}
											inputProps={{
												placeholder: 'Please select...',
											}}
											returnFormat={'jsdate'}
											onOpen={(e: any) => {
												// Pre-set the calendar page with the start date if nothing selected yet
												if (!e.inst.getVal()) e.inst.setTempVal(data?.start);
											}}
											onChange={(e: any) => {
												setData((prevState: any) => {
													return {
														...prevState,
														end: e.value ? DateTime.fromJSDate(e.value).startOf('day') : null,
													};
												});
											}}
										/>
									</IonCol>
									<IonCol className='p-0 flex-grow-0 half-day-col'>
										<SelectStyled
											className='react-select-container'
											classNamePrefix='react-select'
											forwardRef={refWorkingHoursEnd}
											options={buildHalfDayOptions(
												halfDayOptions,
												refWorkingHoursEnd,
												data?.end ? data.end.toFormat('ccc').toLowerCase() : null,
												'end'
											)}
											onChange={(newValue: any) => {
												if (newValue.value !== data?.endHourIndex) {
													setData((prevState: any) => ({
														...prevState,
														endHourIndex: newValue.value,
													}));
												}
											}}
										/>
									</IonCol>
								</IonRow>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>{`${isNew ? 'Calendar' : 'Working'} Days`}</IonLabel>
						</IonCol>
						<IonCol size='8' style={infoBoxColStyle}>
							<InfoBox
								title='Days'
								value={() => {
									if (isNew) {
										return data?.period !== undefined ? String(data.period) : 0;
									} else {
										return initialData.period;
									}
								}}
								useLabels={false}
								showBorder={true}
								showBackground={true}
							/>
						</IonCol>
					</IonRow>
					{!isNew &&
						(data?.status === RequestStatus.APPROVED ||
							data?.status === RequestStatus.DECLINED) && (
							<IonRow>
								<IonCol size='4'>
									<IonLabel>
										{data.status === RequestStatus.APPROVED ? 'Approved By' : 'Declined By'}
									</IonLabel>
								</IonCol>
								<IonCol size='8' className='p-0 m-0'>
									<InfoBox
										title='Approver'
										value={`${data.approver} - ${data.approvedDate?.toFormat(
											'dd/MM/yyyy @ HH:mm'
										)}`}
										useLabels={false}
										showBorder={true}
										showBackground={true}
									/>
								</IonCol>
							</IonRow>
						)}
					{!isNew &&
						data?.status !== RequestStatus.DECLINED &&
						data?.clashes &&
						data.clashes.length > 0 &&
						data.clashes.map((clash: Clash, i: number) => (
							<IonRow key={i}>
								<IonCol size='4'>
									<IonLabel color='danger'>Request Clash</IonLabel>
								</IonCol>
								<IonCol size='8' className='p-0 m-0'>
									<InfoBox
										className='danger request-clash'
										title='Request Clash'
										value={clash.description}
										useLabels={false}
										showBorder={true}
										showBackground={true}
										multiLine={true}
									/>
								</IonCol>
							</IonRow>
						))}
					{!isNew && (declinePressed || data?.status === RequestStatus.DECLINED) && (
						<IonRow>
							<IonCol size='4'>
								<IonLabel>Declined Reason</IonLabel>
							</IonCol>
							{declinePressed && data?.status === RequestStatus.REQUESTED && (
								<IonCol size='8' className='pt-1'>
									<IonTextarea
										onIonInput={(e: any) =>
											setData((prevState: any) => {
												return {
													...prevState,
													decisionReason: e.target.value,
												};
											})
										}
									/>
								</IonCol>
							)}
							{!declinePressed && data?.status === RequestStatus.DECLINED && (
								<IonCol size='8' className='p-0 m-0'>
									<InfoBox
										title='Declined Reason'
										value={data.decisionReason}
										useLabels={false}
										showBorder={true}
										showBackground={true}
										multiLine={true}
									/>
								</IonCol>
							)}
						</IonRow>
					)}
				</IonGrid>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size={'12'} className='text-right'>
							<IonButton
								color='secondary'
								onClick={() => handleOnDidDismiss()}
								disabled={isLoading}
							>
								{data?.status === RequestStatus.REQUESTED ? 'Cancel' : 'Close'}
							</IonButton>
							{!isNew && data?.status === RequestStatus.REQUESTED && (
								<>
									{permissionTo('delete') && usageMode !== 'workerCard' && (
										<IonButton color='danger' onClick={handleDeleteRequest} disabled={isLoading}>
											Delete Request
										</IonButton>
									)}
									<IonButton color='warning' onClick={handleDeclineRequest} disabled={isLoading}>
										Decline Request
									</IonButton>
									<IonButton
										color='success'
										onClick={() => onApproveDecline(data, RequestAction.APPROVE)}
										disabled={isLoading}
									>
										Approve Request
									</IonButton>
								</>
							)}
							{isNew && (
								<IonButton
									color='primary'
									onClick={() => onSave(data, halfDayOptions)}
									disabled={isLoading}
								>
									Create Request
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default RequestModal;

import { Alert } from '@mui/material';

export const defaultStylesArray: Array<any> = [
	{
		title: 'Logos',
		type: 'title',
		style: 'alpha',
	},
	{
		title: (
			<Alert severity='info' className='m-0'>
				Accepted client logo image types are <span className='font-bold'>jpg</span> and{' '}
				<span className='font-bold'>png</span>. Recommended size is 200px x 150px.
			</Alert>
		),
		type: 'title',
		style: 'beta',
	},
	{
		id: 'logo',
		class: 'prop-client-logo-dim',
		title: 'Client Logo',
		type: 'imageUpload',
		db: ['logo'],
	},
	{
		title: 'Header and Footer',
		type: 'title',
		style: 'alpha',
	},
	{
		title: (
			<Alert severity='info' className='m-0'>
				Accepted header/footer image types are <span className='font-bold'>jpg</span> and{' '}
				<span className='font-bold'>png</span>. Recommended size is 675px x 100px.
			</Alert>
		),
		type: 'title',
		style: 'beta',
	},
	{
		id: 'image_header',
		class: 'prop-header-dim',
		title: 'Header Image',
		type: 'imageUpload',
		db: ['header'],
	},
	{
		type: 'sectionBreak',
	},
	{
		id: 'image_footer',
		class: 'prop-footer-dim',
		title: 'Footer Image',
		type: 'imageUpload',
		db: ['footer'],
	},
	{
		type: 'sectionBreak',
	},
	{
		title: 'Header & Footer Styles',
		type: 'cardSection',
		children: [
			{
				title: 'Font',
				type: 'dropdown',
				id: 'header-footer-font',
				values: [
					{ label: 'Default', value: 'default' },
					{ label: 'Comic Sans', value: 'comic_sans' },
					{ label: 'Web Dings', value: 'web_dings' },
				],
				db: ['font'],
			},
		],
	},
	{
		type: 'sectionBreak',
	},
	{
		title: 'Information',
		type: 'title',
		style: 'alpha',
	},
	{
		id: 'payment_information',
		title: 'Payment Information',
		type: 'text',
		db: ['payment_information'],
	},
];

import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonLabel } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import SelectStyled from '../UI/SelectStyled';
import { showToast } from '../../lib/toast';
import { DateTime, Interval } from 'luxon';
import { onMenuOpen } from '../../helpers/dropdowns';

interface Props {
	id?: any;
	forwardRef?: any;
	className?: string;
	title?: string;
	editMode?: boolean;
	value: string;
	useLabels?: boolean;
	handleUpdate?: Function;
	disabled?: boolean;
	onChangeCallback?: Function;
	stylesExtra?: any;
	isMandatory?: boolean;
	isMulti?: boolean;
	noForm?: boolean;
	preChangeCallback?: Function;
}

export const YesNoValue: React.FC<Props> = (props: Props) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [originalValue, setOriginalValue] = useState<any>();
	const [originalValueDropdown, setOriginalValueDropdown] = useState<any>();
	const [originalValueInput, setOriginalValueInput] = useState<any>();
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	const dropdownOptions: Array<any> = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	];
	const inputOptions = useMemo(() => {
		const timeStart: DateTime = DateTime.now().startOf('day');
		const timeEnd: DateTime = DateTime.now().endOf('day');
		const timeInterval: Interval = Interval.fromDateTimes(timeStart, timeEnd);
		return timeInterval
			.splitBy({ minutes: 15 })
			.map((d) => ({ label: d.start.toFormat('H:mm') + ' hr', value: d.start.toFormat('H:mm') }));
	}, []);

	// Set the original value from outside
	useEffect(() => {
		if (props.value) {
			setOriginalValue(props.value);
		} else {
			// Default values
			setOriginalValue('0,0:00');
		}
	}, [props.value]);

	// Set the internal values based on the original string value
	useEffect(() => {
		if (originalValue) {
			setOriginalValueDropdown(() =>
				dropdownOptions.filter(
					(option: any) => option.value === Boolean(Number(originalValue.split(',')[0]))
				)
			);
			setOriginalValueInput(() => {
				let selectedOption: any = inputOptions.filter(
					(option: any) => option.value === originalValue.split(',')[1]
				);

				return selectedOption.length === 1 ? selectedOption : [inputOptions[0]];
			});
		}
	}, [originalValue]);

	// Set the edit mode value from outside
	useEffect(() => {
		if (props.editMode) {
			setIsEdit(props.editMode);
		}
	}, [props.editMode]);

	const handleChange = (e: any, id: string) => {
		const prevVal: any = originalValue;
		let curVal: any = '';

		switch (id) {
			case 'dropdown':
				curVal =
					(e.value === true ? 1 : 0) +
					',' +
					(e.value === true ? originalValueInput[0].value : '0:00');
				break;
			case 'input':
				curVal = (originalValueDropdown[0].value === true ? 1 : 0) + ',' + e.value;
				break;
		}

		//console.log(`prevVal (${id})`, prevVal, `curVal (${id})`, curVal);

		if (checkTempValue(curVal) === true && props.handleUpdate) {
			if (props.preChangeCallback) props.preChangeCallback(curVal);
			props.handleUpdate(curVal);
			if (props.onChangeCallback) props.onChangeCallback(curVal);

			// We must set this before returning in case of no-form mode
			setOriginalValue(curVal);

			if (props.noForm === true) return;
			setUndoVal(prevVal);
			setShowUndo(true);
		}
	};

	const checkTempValue = (val: any): boolean => {
		if (originalValue === undefined) return true;

		if (val === originalValue) {
			return false;
		} else {
			return true;
		}
	};

	const onUndoClick = () => {
		if (props.disabled) return false;

		if (props.handleUpdate) {
			if (undoVal !== undefined) {
				if (props.preChangeCallback) props.preChangeCallback(undoVal);
				props.handleUpdate(undoVal);
				if (props.onChangeCallback) props.onChangeCallback(undoVal);

				setOriginalValue(undoVal);
				setUndoVal(null);
				setShowUndo(false);
			} else {
				showToast('error', "Sorry, you can't undo back to no selection");
			}
		}
	};

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating'>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				{showUndo && (
					<FontAwesomeIcon
						icon={faRotateLeft}
						className='icon-primary undo-icon'
						onClick={() => onUndoClick()}
					/>
				)}
				<div className='yes-no-value-container'>
					<div className='yes-no-value-dropdown-container'>
						<SelectStyled
							id={props.id && props.id}
							className={`react-select-container react-select-dropdown ${
								props.className ? props.className : ''
							}`}
							defaultValue={originalValueDropdown}
							value={originalValueDropdown}
							isSearchable={false}
							name={props.title}
							options={dropdownOptions}
							onChange={(e: any) => handleChange(e, 'dropdown')}
							menuPortalTarget={document.body}
							isDisabled={props.disabled}
							isClearable={false}
						/>
					</div>
					{originalValueDropdown[0].value === true && (
						<>
							<div className='yes-no-value-input-denominator'>Time</div>
							<div className='yes-no-value-input-container'>
								<SelectStyled
									id={props.id && props.id + '_input'}
									className={`react-select-container react-select-dropdown ${
										props.className ? props.className : ''
									}`}
									defaultValue={originalValueDropdown}
									value={originalValueInput}
									isSearchable={false}
									options={inputOptions}
									onChange={(e: any) => handleChange(e, 'input')}
									menuPortalTarget={document.body}
									isDisabled={props.disabled}
									isClearable={false}
									onMenuOpen={onMenuOpen}
								/>
							</div>
							<div className='yes-no-value-end-fill'></div>
						</>
					)}
				</div>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<p>N/A</p>
			</IonCol>
		</IonRow>
	);
};

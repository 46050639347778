import {
	IonAvatar,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonItem,
	IonLabel,
} from '@ionic/react';
import {
	faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope as faEn } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import sanitize from 'sanitize-html';
import {
	MessageInterface,
} from '../../../interfaces/Chat/MessageInterface';
import './SearchItem.scss';
import { DateTime } from 'luxon';

interface Props {
	item: MessageInterface;
	index: number;
	preview?: boolean;
	participants?: Array<any>;
	click: Function;
}

const SearchItem: React.FC<Props> = (props: Props) => {
	const message: MessageInterface | null = useMemo(
		() => {
			if (props.item) {
				let date = DateTime.fromISO(props.item.created_at);
				props.item.date = date.toFormat('DDDD');
				props.item.time = date.toFormat('HH:mm');
			}
			return props.item !== undefined ? props.item : null
		},
		[props.item]
	);

	return (
		<>
			<div
				key={'search-' + message?._id}
				id={'search-' + message?._id}
				className={`search-message`}
			>
				<IonCard
					onClick={() => {
						props?.click(message?._id);
					}}
				>
					<IonCardHeader>
						<IonItem>
							<IonLabel>
								<h4>{message?.created_by}</h4>
							</IonLabel>
						</IonItem>
					</IonCardHeader>
					<IonCardContent>
						<IonLabel>
							<p
								dangerouslySetInnerHTML={{
									__html: sanitize(props.item?.message ?? '', {
										allowedTags: ['mark'],
										disallowedTagsMode: 'escape',
									}),
								}}
							></p>
						</IonLabel>

						<div className="time-read">
							<span>
								{message?.date} at {message?.time}
							</span>
							<FontAwesomeIcon
								icon={
									message?.read?.length ===
									(props?.participants?.length ?? 0) + 1
										? faEnvelope
										: faEn
								}
							/>
						</div>
					</IonCardContent>
				</IonCard>
			</div>
		</>
	);
};

export default SearchItem;

import logoSquare from "../../assets/images/logos/hf_logo_square.png";
import logoText from "../../assets/images/logos/hf_logo_text.png";

const Logos = () => {
	return (
		<div className="text-center mb-5">
			<img src={logoSquare} className="mb-4" alt="HumanFacta" />
			<img src={logoText} alt="HumanFacta" />
		</div>
	);
}

const AuthStyles = () => {
	return (
		<style>
			{`
					/* Override the IonRouterOutlet style to hide the main left menu */
					ion-router-outlet {
						width: 100% !important;
						margin-left: 0 !important;
						background-image: linear-gradient(
							145deg,
							#ffffff 10%,
							#c9c9cf 100%
						);
					}
				`}
		</style>
	)
}

export { Logos, AuthStyles };

import { Select } from '@mobiscroll/react';
import { useCallback, useState } from 'react';

interface Props {
	options: Array<{ text: string; value: string; action: Function; hasPermission: boolean }>;
	contextMenuOpen: boolean;
	contextMenuAnchor: any;
	setContextMenuInst: Function;
	setContextMenuOpen: Function;
	setContextMenuRightClickedEvent: Function;
}

const CalendarContextMenu = (props: Props) => {
	const data = props.options.filter((option: any) => option.hasPermission === true);
	const [contextMenuValue, setContextMenuValue] = useState<any>(false);

	const handleOnChange = useCallback((args: any) => {
		if (!args.value) return;

		setContextMenuValue(args.value);

		// Get the action from the options
		const theOption: any = args.inst.props.data.filter((item: any) => item.value === args.value);
		if (
			Array.isArray(theOption) &&
			theOption.length === 1 &&
			theOption[0].hasOwnProperty('action')
		) {
			// Execute action
			theOption[0].action();
		}
	}, []);

	const handleOnOpen = useCallback((e: any, inst: any) => {
		window.addEventListener('contextmenu', rightClickOverlay);
		props.setContextMenuInst(inst);
	}, []);

	const handleOnClose = useCallback(() => {
		window.removeEventListener('contextmenu', rightClickOverlay);
		props.setContextMenuRightClickedEvent(null);
		setContextMenuValue('');
		props.setContextMenuOpen(false);
	}, []);

	function rightClickOverlay(e: any) {
		// Close the menu if right-clicking on the overlay
		const target = e.target as Element;
		if (target.classList.contains('mbsc-popup-overlay')) {
			e.preventDefault();
			handleOnClose();
		}
	}

	if (data.length > 0) {
		return (
			<Select
				inputProps={{ type: 'hidden', className: 'calendar-context-menu-input' }}
				display='anchored'
				touchUi={false}
				showOverlay={false}
				anchor={props.contextMenuAnchor}
				data={data}
				value={contextMenuValue}
				isOpen={props.contextMenuOpen}
				onChange={handleOnChange}
				onOpen={handleOnOpen}
				onClose={handleOnClose}
				animation={false}
			/>
		);
	} else {
		return null;
	}
};

export default CalendarContextMenu;

import { ExcelStyle } from '@ag-grid-community/core';
import { IonButton } from '@ionic/react';
import { isNumber } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { useMemo, useRef, useState } from 'react';

type Props = {
	uid: string;
	title: string;
	modalColumns?: Array<any>;
};

const useWorkerReport = (props?: Props) => {
	const [startDate, setStartDate] = useState<DateTime | null>(DateTime.now().startOf('month'));
	const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now().endOf('month'));
	const [chosenRoles, setChosenRoles] = useState<Array<any>>([]);
	const [chosenPaymentMethod, setChosenPaymentMethod] = useState<{
		label: string;
		value: string;
	} | null>(null);

	const setMonthYear = (month: number, year: number) => {
		let start: DateTime | null = null;
		let end: DateTime | null = null;

		if (month === 13) {
			// Annual
			start = DateTime.now().set({ year }).startOf('year');
			end = start.endOf('year');
		} else if (month === 0) {
			// All time
			start = null;
			end = null;
		} else {
			start = DateTime.now().set({ year, month }).startOf('month');
			end = start.endOf('month');
		}

		setStartDate(start);
		setEndDate(end);
	};

	const formatTime = (input: number | string) => {
		let result: any = null;

		if (isNumber(input)) {
			let seconds: number = input;
			if (seconds % 1 !== 0) seconds = seconds * 86400;
			result = Duration.fromObject({ seconds }).toFormat('hh:mm:ss');
		} else {
			result = input;
		}

		return result;
	};

	const formatNumber = (n: number) => {
		return isNaN(Number(n)) ? 0 : n;
	};

	const totalRow = (calcTotalCols: Array<any>, totalColsTitles?: Array<any>) => {
		// Return the totalling function, provided with the relevant columns array
		return (rowdata: any) => {
			let result: any = [{}];

			// Initialise total columns to zero
			calcTotalCols.forEach(function (params) {
				result[0][params.id] = 0;
				rowdata.forEach(function (line: any) {
					const val: any = line[params.id];
					if (typeof val === 'string') {
						result[0][params.id] = val;
					} else if (typeof val === 'number') {
						result[0][params.id] += Number(val);
					}
				});
			});

			if (Array.isArray(totalColsTitles) && totalColsTitles.length > 0) {
				totalColsTitles.forEach((item: any) => {
					result[0][item.id] = item.title;
				});
			}

			// Pin this row
			return result;
		};
	};

	const excelStyles = useMemo(() => {
		const excelStylesArray: ExcelStyle[] = [
			{
				id: 'duration-type',
				alignment: {
					horizontal: 'Right',
				},
			},
			{
				id: 'ag-right-aligned-header',
				alignment: {
					horizontal: 'Right',
				},
			},
		];

		return excelStylesArray;
	}, []);

	// Report modal - no useState allowed otherwise it reloads the report grid
	const btnViewDetailsRef = useRef<any>(null);
	const reportModalRef = useRef<any>(null);

	const handleRowClicked = (params: any) => {
		if (params.rowPinned) return;

		params.node.setSelected(true);

		if (reportModalRef.current && props && props.modalColumns) {
			reportModalRef.current.modalData = {
				uid: props.uid,
				title: `${props.title} - ${params.data.worker_name}`,
				rowId: params.data.worker_id,
				start: startDate,
				end: endDate,
				columns: props.modalColumns,
			};
		}

		if (btnViewDetailsRef.current) btnViewDetailsRef.current.disabled = false;
	};

	const handleRowDoubleClicked = (params: any) => {
		if (params.rowPinned) return;
		handleRowClicked(params);
		handleViewDetails();
	};

	const handleViewDetails = () => {
		if (reportModalRef.current) reportModalRef.current.isOpen = true;
	};

	const handleReportModalClose = () => {
		if (reportModalRef.current) reportModalRef.current.isOpen = false;
	};

	const btnViewDetails = (key?: number) => {
		return (
			<IonButton key={key ?? 0} ref={btnViewDetailsRef} onClick={handleViewDetails} disabled={true}>
				View Details
			</IonButton>
		);
	};

	return {
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		setMonthYear,
		chosenRoles,
		setChosenRoles,
		chosenPaymentMethod,
		setChosenPaymentMethod,
		formatTime,
		formatNumber,
		totalRow,
		excelStyles,
		// Report modal
		btnViewDetailsRef,
		reportModalRef,
		handleRowClicked,
		handleRowDoubleClicked,
		handleViewDetails,
		handleReportModalClose,
		btnViewDetails,
	};
};

export default useWorkerReport;

import { DependencyList, useEffect } from 'react';

export const publish = (eventName: string, data: any) => {
	const element = getElement();
	const event = new CustomEvent(eventName, { detail: data });
	element.dispatchEvent(event);
};

let targetElement: undefined | HTMLDivElement;

const getElement = () => {
	if (!targetElement) {
		targetElement = document.createElement('div');
	}

	return targetElement;
};

export function useSubscribe<T>(
	eventName: string,
	eventHandler: (data: T) => void,
	deps?: DependencyList
): (el: HTMLElement | null) => void {
	let element: HTMLElement | null;

	useEffect(() => {
		const handleEvent = (event: CustomEvent | Event) => {
			const data = (event as CustomEvent).detail;
			eventHandler(data);
		};

		setUp(handleEvent, deps);

		return () => {
			cleanUp(handleEvent);
		};
	}, deps);

	const setUp = (handleEvent: any, deps?: DependencyList) => {
		let promises: Array<Promise<any>> = [];
		let canContinue = true;
		deps?.forEach((dep: any) => {
			promises.push(
				new Promise((res, rej) => {
					if (typeof dep === 'boolean') {
						if (!dep) {
							canContinue = false;
						}
					}
					res(true);
				})
			);
		});
		promises.push(
			new Promise((res, rej) => {
				res(true);
			})
		);

		Promise.all(promises).then(() => {
			element = element || getElement();

			if (canContinue === true) {
				element.addEventListener(eventName, handleEvent, false);
			}
		});
	};

	const cleanUp = (handleEvent: any) => {
		element = element || getElement();
		element.removeEventListener(eventName, handleEvent, false);
	};

	return (el: HTMLElement | null) => {
		element = el;
	};
}

import { IonRow, IonCol, IonButton } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db2Form } from '../../../../api/forms';
import Form from '../../../../components/Forms/Form';
import useJobDatesArray from '../arrays/jobDatesArray';
import JobControls from '../JobControls';
import JobTitle from '../JobTitle';
import JobsContext from '../../../../contexts/JobsContext';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';

interface Props {
	permissionTo: Function;
}

const JobDates: React.FC<Props> = (props: Props) => {
	const [items, setItems] = useState<Array<any>>([]);
	const { jobId }: any = useParams();
	const { jobData, reloadJob } = useContext(JobsContext);
	const endpoint = '/api/jobs/jobs_list/job_card';
	const endpointId = jobId;

	const formDataArray = useJobDatesArray();

	useEffect(() => {
		loadForm();
	}, []);

	const loadForm = () => {
		let formData: any = formDataArray.jobDates;
		let tmp = cloneDeep(jobData);

		// Parse 'actual dates'
		if (tmp) {
			tmp.work_scheduled_date = tmp.work_scheduled_date
				? DateTime.fromISO(tmp.work_scheduled_date).toFormat('dd/MM/yyyy')
				: 'Not set';

			tmp.work_started_date = tmp.work_started_date
				? DateTime.fromISO(tmp.work_started_date).toFormat('dd/MM/yyyy')
				: 'Not set';

			tmp.work_completed_date = tmp.work_completed_date
				? DateTime.fromISO(tmp.work_completed_date).toFormat('dd/MM/yyyy')
				: 'Not set';
		}

		formData = db2Form(formData, tmp);
		setItems(formData);
	};

	return (
		<>
			<JobTitle />
			<IonRow>
				<IonCol size={'12'}>
					{items && items.length > 0 && (
						<Form
							array={items}
							permissionTo={props.permissionTo}
							forceEdit={props.permissionTo('update')}
							noButton={true}
							endpoint={endpoint}
							endpointID={endpointId}
							onChangeCallback={() => reloadJob()}
						/>
					)}
				</IonCol>
			</IonRow>

			{items && items.length > 0 && (
				<IonRow className='mt-4'>
					<IonCol size={'12'} className='text-right'>
						<JobControls permissionTo={props.permissionTo} jobId={jobId} />
					</IonCol>
				</IonRow>
			)}
		</>
	);
};

export default JobDates;

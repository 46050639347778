import { DateTime } from 'luxon';
import axios from '../lib/axios';

export function downloadFile(
	url: string,
	setIsLoading?: Function,
	downloadAs?: string,
	tmpLinkId?: string
) {
	let objectUrl: any = null;
	let fileLink: any = null;
	let linkId: string = tmpLinkId ?? `download_file_link_helper_${DateTime.now().toMillis()}`;
	let fileName: string = downloadAs
		? downloadAs
		: `File Download ${DateTime.now().toFormat('dd-MM-yyyy')}.tmp`;

	if (setIsLoading) setIsLoading(true);

	axios
		.get(url, { responseType: 'blob' })
		.then((res: any) => {
			objectUrl = window.URL.createObjectURL(new Blob([res.data]));
			fileLink = document.createElement('a');
			fileLink.id = linkId;
			fileLink.href = objectUrl;
			fileLink.setAttribute('download', fileName);
			document.body.appendChild(fileLink);
			fileLink.click();
		})
		.finally(() => {
			document.body.removeChild(fileLink);
			if (setIsLoading) setIsLoading(false);
		});
}

import { useState, useEffect, cloneElement, Children, createElement, useRef } from 'react';
import { returnSubmenuBadge } from '../../helpers/badge';
import { IonCol } from '@ionic/react';

interface Props {
	array: Array<any>;
	disabled?: boolean;
	parentRef?: any;
	onTogglePage?: Function;
	hideOverflow?: boolean;
}

const Subnav: React.FC<Props> = (props: Props) => {
	const [currentPage, setCurrentPage] = useState<JSX.Element>();
	const [subnavArray, setSubnavArray] = useState<Array<any>>([]);
	const [subnavItems, setSubnavItems] = useState<Array<any>>([]);
	const tabRef = useRef<any>();

	useEffect(() => {
		setSubnavArray(props.array);
	}, [props.array]);

	useEffect(() => {
		generateSubnavItems();
	}, [subnavArray]);

	const generateSubnavItems = () => {
		let result = subnavArray.map((item, i) => {
			let classNames = ['subnav-item'];
			if (item.active) classNames.push('active');
			if (props.disabled) classNames.push('disabled');

			if (item.active === true) {
				const page = createElement(item.page, {
					...item.pageData,
					title: item?.title,
					parentRef: props.parentRef,
					tabRef: tabRef,
				});
				const hasNextFunction = page.props.hasOwnProperty('nextFunction');
				let finalPage = null;

				// Wrap the component page in a helper to provide additional props
				if (!hasNextFunction) {
					finalPage = (
						<SubnavPageWrapper
							nextFunction={() => handleTogglePage(i + 1)}
							prevFunction={() => handleTogglePage(i - 1)}
						>
							{page}
						</SubnavPageWrapper>
					);
				} else {
					// If we already have the next function then do not wrap
					finalPage = page;
				}

				setCurrentPage(finalPage);
			}

			return (
				<IonCol key={i} className={classNames.join(' ')} onClick={() => handleTogglePage(i)}>
					<span>{item.title}</span>
					{(item.badge || item.badgeNumber || item.badgeColor) && returnSubmenuBadge(item)}
				</IonCol>
			);
		});
		setSubnavItems(result);
	};

	const handleTogglePage = (index: any) => {
		if (props.disabled === true) return;
		let array = subnavArray;
		for (let i = 0; i < array.length; i++) {
			array[i].active = false;
		}
		array[index].active = true;
		setSubnavArray(array);
		generateSubnavItems();
		if (props.onTogglePage) props.onTogglePage({ activeItem: array[index] });
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<div className='sub-nav-tabs' ref={tabRef}>
				{subnavItems}
			</div>
			<div
				className=''
				style={{ flex: 1, overflow: props.hideOverflow === false ? 'visible' : 'hidden' }}
			>
				{currentPage}
			</div>
		</div>
	);
};

export default Subnav;

// Wrapper helper component to provide additional props to the Subnav page
interface PropsWrapper {
	children: any;
	nextFunction: Function;
	prevFunction: Function;
}

const SubnavPageWrapper: React.FC<PropsWrapper> = (props: PropsWrapper) => {
	let ch = Children.only(props.children);
	ch = cloneElement(ch, {
		nextFunction: props.nextFunction,
		prevFunction: props.prevFunction,
	});
	return ch;
};

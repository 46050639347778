import { faChevronLeft, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import { isEqual } from 'lodash';
import {
	CardSection,
	ColorPicker,
	CopyAddress,
	Dropdown,
	EmailField,
	ImageUpload,
	PhoneField,
	PostcodeField,
	TextField,
	AddressLookup,
	ItemList,
	NumberField,
	SingleDatePicker,
	PasswordField,
	InfoBox,
	AutoComplete,
	DropdownAsync,
	Boundary,
	FileUpload,
} from './FormFields';
import { updateForm } from '../../api/forms';
import { toast } from 'react-toastify';
import { showToast } from '../../lib/toast';
import { mandatoryFieldChecks } from './FormValidation';
import { YesNoValue } from './YesNoValue';

interface Props {
	noButton?: boolean;
	array: Array<any>;
	forceEdit?: boolean;
	tableMode?: boolean;
	endpoint?: string;
	endpointID?: string;
	formSkipServerUpdate?: boolean;
	method?: undefined | 'PUT' | 'POST';
	handleUpdateSelect?: Function;
	permissionTo: Function;
	onChangeCallback?: Function;
}

const Form: React.FC<Props> = (props: Props) => {
	const [formItems, setFormItems] = useState<Array<any>>([]);
	const [items, setItems] = useState<Array<any>>(props.array);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		generateForm();
	}, [editMode, isLoading, props.endpointID]);

	useEffect(() => {
		if (props.forceEdit === true) {
			setEditMode(true);
		}
	}, [props.forceEdit]);

	const generateForm = () => {
		let result = [];
		for (let i = 0; i < items.length; i++) {
			const fieldID = items[i].id ?? null;

			if (items[i].type === 'title') {
				let titleClasses: Array<string> = ['form-title'];
				if (items[i].classes) titleClasses = titleClasses.concat(items[i].classes);

				switch (items[i].style) {
					case 'alpha':
					case 'beta':
						titleClasses.push(`style-${items[i].style}`);

						result.push(
							<h5 className={titleClasses.join(' ')} key={i}>
								{items[i].title}
							</h5>
						);
						break;
					default:
						result.push(
							<h5 className={titleClasses.join(' ')} key={i}>
								{items[i].title}
							</h5>
						);
						break;
				}
			}
			if (items[i].type === 'sectionBreak') {
				result.push(<hr className='section-break' key={i} />);
			}
			if (items[i].type === 'text') {
				result.push(
					<TextField
						forwardRef={items[i].ref && items[i].ref}
						id={fieldID ?? 'text-field-' + i}
						title={items[i].title}
						key={i}
						placeholder={items[i].placeholder}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateValue={(target: any) => handleUpdateValue(target, i)}
						multiLine={false}
						disabled={items[i].hasOwnProperty('disabled') ? items[i].disabled : isLoading}
						group={items[i].group}
						isMandatory={items[i].isMandatory}
						noForm={items[i].noForm}
					/>
				);
			}
			if (items[i].type === 'number') {
				result.push(
					<NumberField
						fieldRef={items[i].ref && items[i].ref}
						id={fieldID ?? 'number-field-' + i}
						title={items[i].title}
						key={i}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						hideSpinner={items[i].hideSpinner}
						handleUpdateValue={(target: any) => handleUpdateValue(target, i)}
						onChangeCallback={items[i].onChangeCallback}
						disabled={isLoading || items[i].disabled}
						isMandatory={items[i].isMandatory}
						noForm={items[i].noForm}
						decimalLimit={Number(items[i].decimalLimit)}
						disableEnterKey={items[i].disableEnterKey}
						disableNegatives={items[i].disableNegatives}
					/>
				);
			}
			if (items[i].type === 'email') {
				result.push(
					<EmailField
						fieldRef={items[i].ref && items[i].ref}
						title={items[i].title}
						key={i}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateValue={(target: any) => handleUpdateValue(target, i)}
						disabled={isLoading}
						isMandatory={items[i].isMandatory}
						noForm={items[i].noForm}
					/>
				);
			}
			if (items[i].type === 'phone') {
				result.push(
					<PhoneField
						ref={items[i].ref && items[i].ref}
						title={items[i].title}
						key={i}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateValue={(target: any) => handleUpdateValue(target, i)}
						disabled={isLoading}
						isMandatory={items[i].isMandatory}
						noForm={items[i].noForm}
					/>
				);
			}
			if (items[i].type === 'postcode') {
				result.push(
					<PostcodeField
						fieldRef={items[i].ref && items[i].ref}
						id={'postcode-field-' + i}
						title={items[i].title}
						key={i}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateValue={(target: any) => handleUpdateValue(target, i)}
						disabled={isLoading}
						group={items[i].group}
						isMandatory={items[i].isMandatory}
						noForm={items[i].noForm}
					/>
				);
			}
			if (items[i].type === 'password') {
				result.push(
					<PasswordField
						id={items[i].id}
						fieldRef={items[i].ref && items[i].ref}
						title={items[i].title}
						key={i}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateValue={(target: any) => handleUpdateValue(target, i)}
						disabled={isLoading}
						isMandatory={items[i].isMandatory}
						noSet={items[i].noSet}
					/>
				);
			}
			if (items[i].type === 'boundary') {
				result.push(
					<Boundary
						id={items[i].id}
						title={items[i].title}
						ref={items[i].ref}
						key={i}
						value={items[i].value || ''}
						custom={items[i].custom || false}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateSelect={(target: any) => handleBoundary(target, items[i])}
						disabled={isLoading}
						isMandatory={items[i].isMandatory}
						noForm={items[i].noForm}
					/>
				);
			}
			if (items[i].type === 'dropdown') {
				result.push(
					<Dropdown
						title={items[i].title}
						key={i}
						id={items[i].id}
						className={items[i].className}
						forwardRef={items[i].ref}
						values={items[i].values || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateSelect={(target: any) => {
							if (items[i].isMulti === true) {
								handleUpdateValue(target, i, true);
							} else {
								handleUpdateValue(target.value, i, true);
							}
						}}
						disabled={isLoading || items[i].disabled}
						defaultValue={items[i].defaultValue}
						preChangeCallback={items[i].preChangeCallback}
						onChangeCallback={items[i].onChangeCallback}
						stylesExtra={items[i].stylesExtra}
						isSearchable={items[i].isSearchable}
						isMandatory={items[i].isMandatory}
						isMulti={items[i].isMulti}
						addNewFunction={items[i].addNewFunction}
						noForm={items[i].noForm}
						isClearable={items[i].isClearable}
					/>
				);
			}
			if (items[i].type === 'dropdownAsync') {
				result.push(
					<DropdownAsync
						title={items[i].title}
						key={i}
						id={items[i].id}
						className={items[i].className}
						forwardRef={items[i].ref}
						values={items[i].values || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateSelect={(target: any) => {
							if (items[i].isMulti === true) {
								handleUpdateValue(target, i, true);
							} else {
								handleUpdateValue(target.value, i, true);
							}
						}}
						disabled={isLoading || items[i].disabled}
						defaultValue={items[i].defaultValue}
						onChangeCallback={items[i].onChangeCallback}
						isSearchable={items[i].isSearchable}
						isMandatory={items[i].isMandatory}
						isMulti={items[i].isMulti}
						noForm={items[i].noForm}
						isClearable={items[i].isClearable}
						loadOptions={items[i].loadOptions}
						addNewLabel={items[i].addNewLabel ? items[i].addNewLabel : ''}
						handleAddNew={items[i].handleAddNew ? items[i].handleAddNew : null}
					/>
				);
			}
			if (items[i].type === 'date') {
				result.push(
					<SingleDatePicker
						title={items[i].title}
						key={i}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleUpdateValue={(target: any) => handleUpdateValue(target, i)}
						onChangeCallback={items[i].onChangeCallback}
						disabled={isLoading}
						isMandatory={items[i].isMandatory}
						innerRef={items[i].ref && items[i].ref}
						defaultToday={items[i].defaultToday}
					/>
				);
			}
			if (items[i].type === 'imageUpload') {
				result.push(
					<ImageUpload
						key={i}
						id={items[i].id}
						db={items[i].db}
						class={items[i].class}
						title={items[i].title}
						value={items[i].value || ''}
						editMode={editMode}
						handleImageUpload={items[i].handleImageUpload}
					/>
				);
			}
			if (items[i].type === 'fileUpload') {
				result.push(
					<FileUpload
						key={i}
						id={items[i].id}
						db={items[i].db}
						class={items[i].class}
						title={items[i].title}
						value={items[i].value || ''}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						handleFileUpload={items[i].handleFileUpload}
					/>
				);
			}
			if (items[i].type === 'cardSection') {
				result.push(
					<CardSection
						key={i}
						title={items[i].title}
						children={items[i].children || ''}
						formProps={props}
					/>
				);
			}
			if (items[i].type === 'colorPicker') {
				result.push(<ColorPicker title={items[i].title} key={i} color={items[i].color} />);
			}
			if (items[i].type === 'copyAddress') {
				result.push(
					<CopyAddress
						title={items[i].title}
						key={i}
						value={items[i].value || ''}
						editMode={editMode}
						checkboxFunction={(e: any) =>
							items[i].handleCopyAddress
								? items[i].handleCopyAddress(
										e,
										items[i].groupFrom,
										items[i].groupTo,
										items[i].includeLatLong
								  )
								: handleCopyAddress(
										e,
										items[i].groupFrom,
										items[i].groupTo,
										items[i].includeLatLong
								  )
						}
						disabled={isLoading}
					/>
				);
			}
			if (items[i].type === 'addressLookup') {
				result.push(
					<AddressLookup
						title={items[i].title}
						id={items[i].id}
						key={i}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						addressLookupFunction={handleAddressLookupFill}
						disabled={isLoading}
					/>
				);
			}
			if (items[i].type === 'itemList') {
				result.push(
					<ItemList
						title={items[i].title}
						id={items[i].id}
						key={i}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						disabled={isLoading}
						permissionTo={props.permissionTo}
						endpointID={props.endpointID}
					/>
				);
			}
			if (items[i].type === 'infoBox') {
				result.push(
					<InfoBox
						key={i}
						title={items[i].title}
						value={items[i].value || ''}
						useLabels={props.tableMode ? false : true}
						showBorder={items[i].showBorder ?? false}
						showBackground={items[i].showBackground ?? false}
					/>
				);
			}
			if (items[i].type === 'autocomplete') {
				result.push(
					<AutoComplete
						key={i}
						id={items[i].id}
						forwardRef={items[i].ref}
						className={items[i].className}
						title={items[i].title}
						editMode={editMode}
						useLabels={props.tableMode ? false : true}
						disabled={isLoading || items[i].disabled}
						isSearchable={items[i].isSearchable}
						onChangeCallback={items[i].onChangeCallback}
						placeholder={items[i].placeholder}
						loadOptions={items[i].loadOptions}
						addNewLabel={items[i].addNewLabel ? items[i].addNewLabel : ''}
						handleAddNew={items[i].handleAddNew ? items[i].handleAddNew : null}
					/>
				);
			}
			if (items[i].type === 'yesNoValue') {
				result.push(
					<YesNoValue
						title={items[i].title}
						key={i}
						id={items[i].id}
						className={items[i].className}
						forwardRef={items[i].ref}
						editMode={editMode}
						value={items[i].value}
						useLabels={props.tableMode ? false : true}
						handleUpdate={(target: any) => handleUpdateValue(target, i, true)}
						disabled={isLoading || items[i].disabled}
						preChangeCallback={items[i].preChangeCallback}
						onChangeCallback={items[i].onChangeCallback}
						stylesExtra={items[i].stylesExtra}
						isMandatory={items[i].isMandatory}
						isMulti={items[i].isMulti}
						noForm={items[i].noForm}
					/>
				);
			}
		}
		setFormItems(result);
	};

	const toggleEditMode = () => {
		setEditMode(!editMode);
	};

	const handleUpdateValue = async (
		value: any,
		index: number,
		propHandle?: boolean,
		skipServerUpdate?: boolean
	) => {
		return new Promise(async (res, rej) => {
			setTimeout(() => {
				setItems((prevValue: any) => {
					prevValue[index].value = value;
					if (skipServerUpdate === true || props.formSkipServerUpdate === true) {
						res(prevValue);
					} else if (mandatoryFieldsEmpty(prevValue) !== true) {
						updateServer(prevValue, [{ value, index }], propHandle).then(() => {
							res(prevValue);
						});
					}
					return prevValue;
				});
			}, 50); // Fractional delay to allow form update before continuing
		});
	};

	const handleUpdateValues = async (obj: any, propHandle?: boolean, skipServerUpdate?: boolean) => {
		return new Promise(async (res, rej) => {
			setTimeout(() => {
				setItems((prevValue: any) => {
					Object.keys(obj).forEach((key) => {
						prevValue[key].value = obj[key];
					});
					if (skipServerUpdate === true || props.formSkipServerUpdate === true) {
						res(prevValue);
					} else if (mandatoryFieldsEmpty(prevValue) !== true) {
						updateServer(prevValue, [], propHandle).then(() => {
							res(prevValue);
						});
					}
					return prevValue;
				});
			}, 50); // Fractional delay to allow form update before continuing
		});
	};

	const updateServer = async (tempArray: any, updatingList: Array<any>, propHandle?: any) => {
		setIsLoading(true);

		const toastID = toast.loading('Please wait...');

		const result = await updateForm(tempArray, props.endpoint, props.endpointID, props.method);

		if (result.success === true) {
			generateForm();
			showToast('saved', null, toastID);

			updatingList.forEach((updating: any) => {
				if (propHandle === true && props.handleUpdateSelect) {
					props.handleUpdateSelect(updating.value, updating.index);
				}

				// Call the overall form callback function if provided
				if (props.onChangeCallback) {
					props.onChangeCallback({
						data: tempArray,
						endpoint: props.endpoint,
						endpointID: props.endpointID,
						result: result,
						index: updating.index,
					});
				}
			});
		} else {
			// Handle the server error message gracefully
			switch (result.errorResponse.response.status) {
				case 422:
					// Unprocessable Content
					showToast('error', result.errorResponse.response.data.message, toastID);
					break;
				default:
					showToast('error', null, toastID);
					break;
			}
		}

		setIsLoading(false);
	};

	const handleCopyAddress = (
		checked: boolean,
		groupFrom: string,
		groupTo: string,
		includeLatLong: boolean = false
	) => {
		if (checked === true) {
			let changed = [];
			let fromItems = items.filter((item: any) => item.group === groupFrom && item.db.length > 0);
			let toItems = items.filter((item: any) => item.group === groupTo && item.db.length > 0);

			toItems.forEach((toItem: any, toIndex: number) => {
				let index = fromItems.findIndex((fromItem: any) => {
					return JSON.stringify(toItem.subType) === JSON.stringify(fromItem.subType);
				});

				let isGPSField = toItem.db.indexOf('latitude') > -1 || toItem.db.indexOf('longitude') > -1;

				if (index > -1 && ((isGPSField && includeLatLong === true) || !isGPSField)) {
					if (toItem.value !== fromItems[index].value) changed.push(true);

					toItems[toIndex].value = fromItems[index].value;
					if (
						toItems[toIndex].ref &&
						toItems[toIndex].ref.current &&
						fromItems[index].ref &&
						fromItems[index].ref.current
					) {
						toItems[toIndex].ref.current.value = fromItems[index].ref.current.value;
					} else if (toItems[toIndex].ref && toItems[toIndex].ref.current) {
						toItems[toIndex].ref.current.value = fromItems[index].value;
					}
				}
			});

			if (changed.length > 0) {
				let obj: any = {};
				items.forEach((item: any, index: number) => {
					toItems.forEach((toItem: any, toIndex: number) => {
						if (isEqual(toItem, item)) {
							obj[index] = toItem.value;
						}
					});
				});
				handleUpdateValues(obj, true, false);
			}
		}
	};

	const handleBoundary = (value: any, field: any) => {
		setItems((prevItems) => {
			let newItems = [];
			prevItems.forEach((item: any, i: number) => {
				if (item.group === field.group && item.type !== 'boundary') {
					if (item.db.length > 1) {
						let key = item.db[1];
						if (key === 'address') {
							key = item.db[2];
						}
						if (value[key] !== null && value[key] !== undefined) {
							prevItems[i].value = value[key];
							newItems.push({ value: value[key], index: i });
						}
					}
				}
			});
			let index = items.findIndex((item) => field.group === item.group && item.type === 'boundary');
			if (index > -1) {
				prevItems[index].value = value;
				newItems.push({ value, index });
			}
			if (prevItems[index].noForm !== true) {
				updateServer(prevItems, [{ value, index }]);
			}
			return prevItems;
		});
	};

	const handleAddressLookupFill = async (address: any, lookupID: string) => {
		const addressType = lookupID.split('-')[2];
		const addressFields = document.querySelectorAll('[data-group="' + addressType + '"]');
		let obj: any = {};

		for (let i in addressFields) {
			let field = addressFields[i] as HTMLInputElement;

			switch (field.name) {
				case 'Address 1':
					field.value = address.addressline1;
					obj[parseInt(field.id.replace('text-field-', ''))] = address.addressline1;
					break;
				case 'Address 2':
					field.value = address.addressline2;
					obj[parseInt(field.id.replace('text-field-', ''))] = address.addressline2;
					break;
				case 'Town':
					field.value = address.posttown;
					obj[parseInt(field.id.replace('text-field-', ''))] = address.posttown;
					break;
				case 'Postcode':
					field.value = address.postcode;
					obj[parseInt(field.id.replace('postcode-field-', ''))] = address.postcode;
					break;
				case 'Latitude':
					field.value = address.latitude;
					obj[parseInt(field.id.replace('text-field-', ''))] = address.latitude;
					break;
				case 'Longitude':
					field.value = address.longitude;
					obj[parseInt(field.id.replace('text-field-', ''))] = address.longitude;
					break;
			}
		}

		let itemIndex = items.findIndex(
			(item) => item.type === 'boundary' && item.group === addressType
		);

		let item = items[itemIndex];
		if (item !== null && item !== undefined && item.ref !== undefined) {
			item.ref.current.setProperty('latitude', address.latitude);
			item.ref.current.setProperty('longitude', address.longitude);

			let v = item.ref.current.value;
			Object.keys(address).forEach((key) => {
				v[key] = address[key];
			});
			obj[itemIndex] = v;
		}

		handleUpdateValues(obj, true, false);

		// Update the server by focusing/blurring the first address element
		setTimeout(() => {
			const fieldParent = addressFields[0] as HTMLElement;
			if (typeof fieldParent === 'object') {
				const field = fieldParent.getElementsByTagName('input')[0];
				field.focus();
				field.blur();
			}
		}, 300);
	};

	function mandatoryFieldsEmpty(items: any) {
		let hasEmptyFields = false;

		if (
			items.some((item: any) => item.hasOwnProperty('isMandatory') && item.isMandatory === true)
		) {
			const checks = mandatoryFieldChecks(items);

			if (checks.formValid !== true) {
				showToast('error', checks.errorMessage);
				hasEmptyFields = true;
			}
		}

		return hasEmptyFields;
	}

	return (
		<>
			{formItems}

			{!props.noButton && (
				<IonRow>
					<IonCol size={'12'} className='text-right'>
						{props.permissionTo('update') && (
							<IonButton
								onClick={() => toggleEditMode()}
								color={editMode ? 'medium' : 'primary'}
								disabled={isLoading}
							>
								{editMode ? (
									<>
										<FontAwesomeIcon icon={faChevronLeft} className='button-icon' />{' '}
										<span>Back</span>
									</>
								) : (
									<>
										<FontAwesomeIcon icon={faPencil} className='button-icon' /> <span>Edit</span>
									</>
								)}
							</IonButton>
						)}
					</IonCol>
				</IonRow>
			)}
		</>
	);
};

export default Form;

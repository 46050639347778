import AddInvoiceList from '../InvoiceTabs/AddInvoiceList';
import DebtorsList from '../InvoiceTabs/DebtorsList';
import CreditNotesList from '../InvoiceTabs/CreditNotesList';
import PaymentRegisterList from '../InvoiceTabs/PaymentRegisterList';

const InvoicesSubmenuArray: any = () => {
	return [
		{
			id: 'submenu-invoiceaddinvoice',
			uid: 'jobs.invoices_list.add_invoice',
			title: 'Add Invoice',
			page: AddInvoiceList,
			hash: 'add-invoice',
			active: true,
			fullHeight: true,
		},
		{
			id: 'submenu-invoicedebtors',
			uid: 'jobs.invoices_list.debtors',
			title: 'Debtors',
			page: DebtorsList,
			hash: 'debtors',
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-invoicecreditnotes',
			uid: 'jobs.invoices_list.credit_notes',
			title: 'Credit Notes',
			page: CreditNotesList,
			hash: 'credit-notes',
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-invoicepaymentregister',
			uid: 'jobs.invoices_list.payment_register',
			title: 'Payment Register',
			page: PaymentRegisterList,
			hash: 'payment-register',
			active: false,
			fullHeight: true,
		},
	];
};

export default InvoicesSubmenuArray;

import React, { createContext, useReducer } from 'react';
import { schedulingReducer } from './scheduling-reducer';
import { ResourceMode, SchedulingState, ViewMode } from './scheduling-types';
import { Actions as Action } from './actions';

interface ProviderProps {
	children: React.ReactNode;
}

interface ContextProvider {
	state: SchedulingState;
	dispatch: React.Dispatch<Action>;
}

const initialState: SchedulingState = {
	viewMode: ViewMode.WEEK,
	resourceMode: ResourceMode.JOBS,
};

const contextProps = {
	state: initialState,
	dispatch: () => null,
};

export const SchedulingContext = createContext<ContextProvider>(contextProps);

const SchedulingProvider = ({ children }: ProviderProps) => {
	const [state, dispatch] = useReducer(schedulingReducer, initialState);

	return (
		<SchedulingContext.Provider value={{ state, dispatch }}>{children}</SchedulingContext.Provider>
	);
};

export default SchedulingProvider;

import { IonCol } from '@ionic/react';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import Loading from '../UI/Loading';

interface Props {
	path: string;
	description: Array<string>;
	imageFunction?: MouseEventHandler<HTMLImageElement>;
	printMode?: boolean;
}

const Photo: React.FC<Props> = (props: Props) => {
	const [loaded, setLoaded] = useState(false);
	const imgRef = useRef<any>();
	let output: JSX.Element = <></>;

	const onImageLoaded = (e: any) => {
		setLoaded(true);
	};

	useEffect(() => {
		// Reset the image on path change
		if (imgRef.current && !props.printMode) {
			imgRef.current.src = '';
			setLoaded(false);

			// Load the current path image
			setTimeout(() => {
				if (imgRef.current) imgRef.current.src = props.path;
			}, 1);
		}
	}, [props.path]);

	const structureJSX: JSX.Element = (
		<div className='photo'>
			<div className='photo-image-container'>
				{!loaded && !props.printMode && <Loading />}
				<img
					ref={imgRef}
					src={props.path}
					alt=''
					className={`gallery-photo ${loaded ? 'gp-show' : 'gp-hide'} ${
						!props?.imageFunction ? 'gp-no-click' : ''
					}`}
					onClick={props?.imageFunction ? props.imageFunction : () => {}}
					onLoad={onImageLoaded}
				/>
			</div>
			<ul className='photo-details'>
				<li>
					{props.description
						.map((item: string) => {
							if (item) item.trim();
							return item;
						})
						.filter(
							(item: string) => String(item).length > 0 && item !== null && item !== undefined
						)
						.join(' | ')}
				</li>
			</ul>
		</div>
	);

	if (!props.printMode) {
		output = <IonCol size={'4'}>{structureJSX}</IonCol>;
	} else {
		output = structureJSX;
	}

	return output;
};

export default Photo;

import { IonLabel } from '@ionic/react';
import { useContext, useState, useEffect, useRef } from 'react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';

interface SFProps {
	defaultSelected: any;
	onChange?: Function;
}

const ListFilter: React.FC<SFProps> = ({ defaultSelected, onChange }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [statuses, setStatuses] = useState<any>([]);
	const [workers, setWorkers] = useState<any>([]);
	const [jobTypes, setJobTypes] = useState<any>([]);

	const [filterStatus, setFilterStatus] = useState<any>(undefined);
	const [filterIssuedBy, setFilterIssuedBy] = useState<any>(undefined);
	const [filterJobType, setFilterJobType] = useState<any>(undefined);

	const payloadRef = useRef<any>({
		statusLabel: defaultSelected.status.label,
		status: defaultSelected.status.payload.status,
		worker_id: defaultSelected.issuedBy.payload.workerId,
		job_type_id: '',
	});

	useEffect(() => {
		moduleCtx.getEstimateStatusOptions().then((res: any) => {
			setStatuses(res);
		});

		moduleCtx.getWorkersOptions().then((res: any) => {
			setWorkers(res);
		});

		moduleCtx.getJobTypeOptions().then((res: any) => {
			setJobTypes(res);
		});
	}, []);

	useEffect(() => {
		applyFilters();
	}, []);

	useEffect(() => {
		// Set the default option
		if (statuses.length > 0 && defaultSelected.status.label.length > 0) {
			setFilterStatus({
				label: defaultSelected.status.label,
				value: defaultSelected.status.payload.status,
			});
		}
	}, [statuses]);

	useEffect(() => {
		// Set the default option
		if (workers.length > 0 && defaultSelected.issuedBy.label.length > 0) {
			setFilterIssuedBy({
				label: defaultSelected.issuedBy.label,
				value: defaultSelected.issuedBy.payload.workerId,
			});
		}
	}, [workers]);

	const setFilters = (newValue: any, filterName: string) => {
		switch (filterName) {
			case 'status':
				setFilterStatus(() => newValue);
				if (newValue && newValue.hasOwnProperty('value')) {
					payloadRef.current.statusLabel = newValue.label;
					payloadRef.current.status = newValue.value;
				} else {
					payloadRef.current.statusLabel = 'All';
					payloadRef.current.status = '';
				}
				break;
			case 'worker_id':
				setFilterIssuedBy(() => newValue);
				payloadRef.current.worker_id =
					newValue && newValue.hasOwnProperty('value') ? newValue.value : '';
				break;
			case 'job_type_id':
				setFilterJobType(() => newValue);
				payloadRef.current.job_type_id =
					newValue && newValue.hasOwnProperty('value') ? newValue.value : '';
				break;
		}

		applyFilters();
	};

	const applyFilters = () => {
		if (onChange) onChange(payloadRef.current.statusLabel, payloadRef.current);
	};

	return (
		<div className='d-flex w-100 align-items-center'>
			<div className='pe-3 text-nowrap'>
				<IonLabel>Estimate Status</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={statuses}
					onChange={(newValue: any) => setFilters(newValue, 'status')}
					value={filterStatus}
					isClearable={true}
				/>
			</div>
			<div className='ps-4 pe-3 text-nowrap'>
				<IonLabel>Issued By</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={workers}
					onChange={(newValue: any) => setFilters(newValue, 'worker_id')}
					value={filterIssuedBy}
					isClearable={true}
				/>
			</div>
			<div className='ps-4 pe-3 text-nowrap'>
				<IonLabel>Job Type</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={jobTypes}
					onChange={(newValue: any) => setFilters(newValue, 'job_type_id')}
					value={filterJobType}
					isClearable={true}
				/>
			</div>
		</div>
	);
};

export default ListFilter;

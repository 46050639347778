export const removeItem = (list:Array<any>, payload:any ): Array<any> => {
	let id = 0;
	if (typeof payload.data === 'string') {
		id = payload.data;
	} else {
		id = payload.data._id;
	}
	let newList = list.filter((item: any) => {
		if (item._id === id) {
			return false;
		}
		return item;
	});
	if (payload.postFunction !== undefined) {
		newList = payload.postFunction(newList);
	}
	return newList;
};

export const prependItems = (list:Array<any>, payload: any): Array<any> => {
	list = [...payload.data, ...list];
	if (payload.postFunction !== undefined) {
		list = payload.postFunction(list);
	}
	return list;
};

export const appendItems = (list:Array<any>, payload: any): Array<any> => {
	list = [...list, ...payload.data];
	if (payload.postFunction !== undefined) {
		list = payload.postFunction(list);
	}
	return list;
};

export const filterItems = (list: Array<any>, payload: any): Array<any> => {
	const searchText: string = payload.data.text;
	const keys: Array<string> = payload.data.keys ?? [];
	const re = new RegExp(searchText, 'gi');
	let newList = list.filter((item: any) => {
		let found = false;
		Object.keys(item).forEach((key: string) => {
			if (keys.indexOf(key) > -1 || keys.length === 0) {
				if (typeof item[key] === 'string') {
					let v = item[key].match(re) ?? [];
					if (v.length > 0) {
						found = true;
					}
				} else if (Array.isArray(item[key])) {
					item[key].forEach((value: any) => {
						if (typeof value === 'string' && value !== null) {
							let v = value.match(re) ?? [];
							if (v.length > 0) {
								found = true;
							}
						}
					})
				}
			}
		});
		return found;
	});
	if (payload.postFunction !== undefined) {
		newList = payload.postFunction(newList);
	}
	return newList;
};

export const updateItem = (list:Array<any>, payload: any): Array<any> => {
	let newList = list.map((item: any) => {
		if (item._id === payload.data._id) {
			return payload.data;
		}

		return item;
	});
	if (payload.postFunction !== undefined) {
		newList = payload.postFunction(newList);
	}
	return newList;
};

export const addItem = (list: Array<any>, payload: any): Array<any> => {
	let found = false;
	let newList = list.map((item: any) => {
		if (item._id === payload.data._id) {
			found = true;
			return payload.data;
		}

		return item;
	});
	if (!found) {
		newList = [...list];
		if (payload.position !== undefined) {
			if (payload.position === 'top') {
				newList.unshift(payload.data);
			} else {
				newList.push(payload.data);
			}
		} else {
			newList.push(payload.data);
		}
	}
	if (payload.postFunction !== undefined) {
		newList = payload.postFunction(newList);
	}
	return newList;
};

export const replaceList = (list: Array<any>, payload: any): Array<any> => {
	let newList = payload.data;
	if (payload.postFunction !== undefined) {
		newList = payload.postFunction(newList);
	}
	return newList;
};

export const highlightList = (list: Array<any>, payload: any): Array<any> => {
	const newList = list.map((item: any) => {
		const searchText: string = payload.data.text;
		const startKey: string | null = payload.data.key ?? null;

		let aItem = item;
		if (startKey !== null) {
			aItem = aItem[startKey];
		}

		if (aItem !== null) {
			aItem['highlighted'] = false;
			Object.keys(aItem).forEach((key: string) => {
				if (typeof aItem[key] === 'string') {
					const value = aItem[key];
					const div = document.createElement('div');
					div.innerHTML = value;

					// Remove old mark tags
					const elements = div.getElementsByTagName('mark');
					if (elements.length > 0) {
						Array.from(elements).forEach((element: any) => {
							const content = document.createTextNode(element.innerHTML);
							element.parentNode?.insertBefore(content, element);
							element.parentNode?.removeChild(element);
						});
					}

					if (searchText !== '') {
						// Add new mark tags
						const re = new RegExp(searchText, 'gi');
						aItem[key] = div.innerHTML.replace(
							re,
							'<mark>' + searchText + '</mark>'
						);
						if (aItem[key].indexOf('<mark') > -1) {
							aItem['highlighted'] = true;
						}
					} else {
						aItem[key] = div.innerHTML;
					}
				}
			});

			if (startKey !== null) {
				item[startKey] = aItem;
			} else {
				item = aItem;
			}
		}

		return item;
	});
	return newList;
};
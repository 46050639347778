import AddUploads from './AddUploads';
import TrainingEvents from './TrainingEvents';

export const trainingArray: Array<any> = [
	{
		title: 'Training and Events',
		page: TrainingEvents,
		active: true,
	},
	{
		title: 'Add Uploads',
		page: AddUploads,
		active: false,
	},
];

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonCol,
	IonRow,
	useIonAlert,
	IonLabel,
	IonInput,
	IonTextarea,
} from '@ionic/react';
import { useContext, useRef, useState } from 'react';
import { showToast } from '../../../../lib/toast';
import { InvoiceContext } from '../InvoicesProvider';

interface SendModalProps {
	isOpen: boolean;
	mode: string | null;
	onClose: Function;
	onSave: Function;
}

const SendModal: React.FC<SendModalProps> = ({ isOpen, mode, onClose, onSave }) => {
	const { state, dispatch } = useContext(InvoiceContext);
	const [presentAlert] = useIonAlert();
	const refSubject = useRef<any>(null);
	const refBody = useRef<any>(null);

	// Defaults
	const subjectDef = `Invoice from ${state.invoiceData?.clientName} for ${state.invoiceData?.name}`;
	const bodyDef =
		'Dear ' +
		state.invoiceData?.clientContactName +
		',\n\n' +
		'Please find attached an invoice for ' +
		state.invoiceData?.name +
		', with a link below to your online job page which contains all information about the work ' +
		'carried out.\n\nRegards,\n' +
		state.invoiceData?.issuedByName;
	const [subject, setSubject] = useState<string>(subjectDef);
	const [body, setBody] = useState<string>(bodyDef);

	// Mode logic
	let modalAction = '';
	let presentMessage = '';
	switch (mode) {
		case 'send':
			modalAction = 'Send Invoice';
			presentMessage = 'Are you sure you want to send this invoice?';
			break;
		case 'reissue':
			modalAction = 'Re-issue Invoice';
			presentMessage = 'Are you sure you want to re-issue this invoice?';
			break;
	}

	const updateValues = () => {
		// Update the values state in the parent of this modal to prevent text flicker
		setSubject(refSubject.current.value.trim());
		setBody(refBody.current.value.trim());
	};

	const handleInvoiceSendAction = () => {
		const subject = refSubject.current.value.trim();
		const body = refBody.current.value.trim();

		updateValues();

		if (subject.length === 0 || body.length === 0) {
			showToast('error', 'Please enter all fields');
			return;
		}

		presentAlert({
			header: modalAction,
			message: presentMessage,
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						onSave(mode);
					},
				},
			],
		});
	};

	const handleOnWillPresent = () => {
		// Reset values
		setSubject(subjectDef);
		setBody(bodyDef);
	};

	const handleOnDidDismiss = () => {
		updateValues();

		// Close the modal
		onClose();
	};

	return (
		<IonModal
			style={{ '--width': '50vw', '--height': '460px' }}
			isOpen={isOpen}
			onWillPresent={handleOnWillPresent}
			onDidDismiss={handleOnDidDismiss}
			className='prop-form invoice-send-modal'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{modalAction}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => handleOnDidDismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='tight-margins-modal'>
				<IonLabel>Subject</IonLabel>
				<IonInput ref={refSubject} value={subject} />

				<IonLabel>Body</IonLabel>
				<IonTextarea ref={refBody} value={body} />
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size={'12'} className='text-right'>
							<IonButton color='secondary' onClick={() => handleOnDidDismiss()}>
								Cancel
							</IonButton>
							<IonButton color='primary' onClick={handleInvoiceSendAction}>
								{modalAction}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default SendModal;

import { useContext, useEffect, useRef, useState } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import axios from '../../../../lib/axios';
import SickModal from '../modals/SickModal';
import { EventType, SickData, SickStatus, HalfDayOptions } from './sick-types';
import { DateTime } from 'luxon';
import { showToast } from '../../../../lib/toast';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import Loading from '../../../../components/UI/Loading';
import { uploadCell } from '../../../../helpers/uploadCell';
import { HATContext } from '../HATProvider';
import { updateSubmenuBadgeCounts } from '../hat-functions';
import { getTimezone } from '../../../../lib/functions';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';
import ToolTip from './ToolTip';
import { handleSaveRequest } from './sick-functions';

interface Props {
	usageMode?: 'workerCard' | undefined;
	usageModeId?: string | undefined;
	usageModeCallback?: Function | undefined;
	parentRef: any;
	tabRef: any;
}

const Requests: React.FC<Props> = (props: Props) => {
	const { state, dispatch } = useContext(HATContext);
	const moduleCtx = useContext<any>(moduleContext);
	const [presentAlert] = useIonAlert();
	const gridRef: any = useRef<any>();
	const componentMode: Array<SickStatus> = [SickStatus.APPROVED, SickStatus.DECLINED];
	const workerFilterRef: any = useRef<any>();
	const toastId = useRef<any>(null);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [workerFilter, setWorkerFilter] = useState<any>(null);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [requestId, setRequestId] = useState<string | null>('');
	const [curMonth, setCurMonth] = useState<number>(
		props.usageMode === 'workerCard' ? 13 : Number(DateTime.now().toFormat('MM'))
	);
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const permissionTo = usePermissionTo('workers.holidays_and_absences.sick');
	const sickDataDefault: SickData = {
		requestId: undefined,
		workerId: undefined,
		description: '',
		requestedDate: DateTime.now().startOf('day'),
		period: 1,
		status: SickStatus.APPROVED,
	};

	// Modals
	const [sickModal, setSickModal] = useState<{ isOpen: boolean }>({ isOpen: false });
	const [sickModalData, setSickModalData] = useState<SickData>(sickDataDefault);

	// Build the columns
	let columns: Array<any> = [];

	if (props.usageMode !== 'workerCard') {
		columns = columns.concat([
			{
				field: 'attendees',
				getQuickFilterText: (params: any) => params.node.data.attendees[0].label,
				valueFormatter: (params: any) => params.node.data.attendees[0].label,
				flex: 1,
			},
		]);
	}

	columns = columns.concat([
		{ field: 'description', headerName: 'Sickness Reason', flex: 1 },
		{
			field: 'requested_date',
			headerName: 'Requested',
			sort: 'desc',
			valueFormatter: dateFormatter,
			flex: 1,
		},
		{ field: 'start', valueFormatter: dateFormatter, flex: 1 },
		{ field: 'end', valueFormatter: dateFormatter, flex: 1 },
		{
			field: 'period',
			cellRenderer: (params: any) =>
				`${params.node.data.period} working day${Number(params.node.data.period) !== 1 ? 's' : ''}`,
			flex: 1,
		},
		...uploadCell(permissionTo, {
			mainId: 'request_id',
			subId: 'worker_id',
			toastId,
			setIsLoading,
			setData,
		}),
	]);

	// Add tooltips to the columns
	columns = columns.map((col: any) => {
		col.tooltipField = col.field;
		return col;
	});

	useEffect(() => {
		loadWorkers();
	}, []);

	// Load the grid data once ready
	useEffect(() => {
		if (gridReady) loadGridData(curMonth, curYear);
	}, [gridReady, workerFilter]);

	// Open the modal if the modal's data contains a requestId
	useEffect(() => {
		if (sickModalData.requestId) setSickModal({ isOpen: true });
	}, [sickModalData]);

	useEffect(() => {
		if (props.usageModeCallback) props.usageModeCallback(curMonth, curYear);
	}, [curMonth, curYear]);

	const loadWorkers = () => {
		moduleCtx.getWorkersOptions().then((res: any) => {
			setWorkers(res);
		});
	};

	const loadGridData = (month: number, year: number) => {
		setGridLoading(true);
		setRequestId(null);
		setData([]);

		gridRef.current.deselectAll();

		let payload: any = {
			event_types: [EventType.SICK],
			status: componentMode,
			month,
			year,
			...getTimezone(),
		};

		if (workerFilter) payload.worker_id = workerFilter;

		if (props.usageMode === 'workerCard') payload.worker_id = props.usageModeId;

		axios
			.post('/api/workers/holidays_and_absences/requests', payload)
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'uploaded') {
			params.node.setSelected(true);
			setRequestId(params.data.request_id);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'uploaded') {
			handleCellClicked(params);
			handleViewRecord();
		}
	};

	const handleViewRecord = () => {
		if (permissionTo(['read', 'update'])) {
			setIsLoading(true);
			axios
				.get(`/api/workers/holidays_and_absences/requests/${requestId}`)
				.then((res: any) => {
					setSickModalData({
						...sickDataDefault,
						requestId: requestId!,
						workerId: res.data.attendees[0].value,
						workerName: res.data.attendees[0].label,
						description: res.data.description,
						requestedDate: DateTime.fromISO(res.data.requested_date),
						start: DateTime.fromISO(res.data.start),
						end: DateTime.fromISO(res.data.end),
						period: res.data.period,
						uploaded: res.data.uploaded,
						fileName: res.data.file_name,
						filePath: res.data.file_path,
						status: res.data.enum_status,
						managersNotes: res.data.managers_notes,
					});
				})
				.catch(() => {
					showToast('error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			showToast('permission');
			return;
		}
	};

	const handleDeleteRecord = (id: string | null | undefined, onSuccess: Function) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Request',
				message: 'Are you sure you want delete this request?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							let tmpRequestId: string | null | undefined = requestId;
							if (id) tmpRequestId = id;

							setIsLoading(true);
							setGridLoading(true);
							axios
								.delete(`/api/workers/holidays_and_absences/requests/${tmpRequestId}`)
								.then(() => {
									updateSubmenuBadgeCounts(dispatch);
									loadGridData(curMonth, curYear);
									onSuccess();
								})
								.catch(() => {
									showToast('error');
									setIsLoading(false);
									setGridLoading(false);
								})
								.finally(() => setIsLoading(false));
						},
					},
				],
			});
		}
	};

	const handleOnFilterChanged = () => {
		setRequestId(null);
		gridRef.current.deselectAll();
	};

	const handleCreateRecord = () => {
		if (permissionTo('create')) {
			setSickModal({ isOpen: true });
			setSickModalData(sickDataDefault);
		} else {
			showToast('permission');
			return;
		}
	};

	const handleRequestManager = (data: SickData) => {
		// TODO: handleRequestManager()
		showToast('info', 'Feature not yet implemented');
	};

	const handleSaveManagersNotes = (data: SickData) => {
		let managersNotes: string =
			data.managersNotes && data.managersNotes.trim().length > 0 ? data.managersNotes.trim() : '';

		setIsLoading(true);

		let payload: any = {
			managers_notes: managersNotes,
		};

		axios
			.put(`/api/workers/holidays_and_absences/requests/${data.requestId}`, payload)
			.then(() => {
				loadGridData(curMonth, curYear);
				showToast('success', 'Managers notes successfully saved');
			})
			.catch(() => {
				showToast('error', null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleWorkerFilter = (option: any) => {
		setGridLoading(true);
		if (!option) {
			setWorkerFilter(null);
			return;
		}

		if (option.value.length > 0) {
			setWorkerFilter(option.value);
		}
	};

	const handleClearWorkerFilter = () => {
		if (workerFilterRef.current) {
			workerFilterRef.current.clearValue();
		}
	};

	const btnViewRequest = (
		<IonButton color='primary' disabled={!requestId} onClick={handleViewRecord}>
			View Sickness
		</IonButton>
	);

	const btnDeleteRequest = (
		<IonButton
			color='danger'
			disabled={!requestId}
			onClick={() => handleDeleteRecord(null, () => {})}
		>
			Delete Sickness
		</IonButton>
	);

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			<DataGrid
				onGridReady={() => {
					setGridReady(true);
				}}
				ref={gridRef}
				title={'Reported Sicknesses'}
				cols={columns}
				data={data}
				useSearch={false}
				onFilterChanged={handleOnFilterChanged}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				rowCursorPointer={permissionTo('update')}
				toolTip={ToolTip}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: loadGridData,
					defaultAnnual: props.usageMode === 'workerCard',
					rowSlot: props.usageMode === 'workerCard' && btnViewRequest,
				}}
				extraFooter={
					props.usageMode !== 'workerCard' && (
						<IonRow className='mt-2'>
							<IonCol size='6' className='row-lower-controls-left ps-0'>
								<div className='row-lower-controls-container'>
									<SelectStyled
										className='worker-filter'
										placeholder='Select a name to filter by worker...'
										forwardRef={workerFilterRef}
										options={workers}
										onChange={(option: any) => handleWorkerFilter(option)}
									/>
									<IonButton
										color='secondary'
										disabled={!workerFilter || gridLoading}
										onClick={() => handleClearWorkerFilter()}
									>
										Reset Worker
									</IonButton>
								</div>
							</IonCol>
							<IonCol size='6' className='row-lower-controls-right pe-0'>
								<div className='row-lower-controls-container'>
									{permissionTo('delete') && btnDeleteRequest}
									{btnViewRequest}
									<IonButton color='success' onClick={handleCreateRecord}>
										Create New
									</IonButton>
								</div>
							</IonCol>
						</IonRow>
					)
				}
			/>

			<SickModal
				isOpen={sickModal.isOpen}
				showLoading={isLoading}
				initialData={sickModalData}
				onClose={() => {
					setSickModal((prevState: any) => ({ ...prevState, isOpen: false }));
					setSickModalData(sickDataDefault);
				}}
				onSave={(data: SickData, halfDayOptions: HalfDayOptions[]) => {
					handleSaveRequest(data, halfDayOptions, permissionTo, setIsLoading, () => {
						// On success
						loadGridData(curMonth, curYear);
						updateSubmenuBadgeCounts(dispatch);

						// Reset the modal
						setSickModal({ isOpen: false });
						setSickModalData(sickDataDefault);
					});
				}}
				onRequestManager={(data: SickData) => {
					handleRequestManager(data);
				}}
				onSaveManagersNotes={(data: SickData) => {
					handleSaveManagersNotes(data);
				}}
				onDelete={(data: SickData) => {
					handleDeleteRecord(data.requestId, () => {
						// Reset the modal
						setSickModal({ isOpen: false });
						setSickModalData(sickDataDefault);
					});
				}}
				permissionTo={permissionTo}
				usageMode={props.usageMode}
			/>
		</>
	);
};

export default Requests;

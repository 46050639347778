import { IonButton, IonCol, IonLabel, IonRow, useIonPopover } from '@ionic/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SchedulingContext } from '../SchedulingProvider';
import { ActionType } from '../actions';
import TimeRow from '../../../components/Forms/TimeRow';
import { ResourceMode, SchedulingEvent, SideMenuAction } from '../scheduling-types';
import { Alert } from '@mui/material';
import axios from '../../../lib/axios';
import Loading from '../../../components/UI/Loading';
import { Select } from '@mobiscroll/react';
import { showToast } from '../../../lib/toast';
import { DateTime } from 'luxon';
import { EventType as RequestEventType } from '../../Workers/HolidayAbsenceTraining/Requests/request-types';
import { EventType as SickEventType } from '../../Workers/HolidayAbsenceTraining/Sick/sick-types';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faBuilding, faClipboard, faHome } from '@fortawesome/free-solid-svg-icons';
import LocationPopover from './LocationPopover';
import SelectStyled from '../../../components/UI/SelectStyled';
import { loadWorkLocations } from '../scheduling-functions';

interface Props {
	permissionTo: Function;
	handleSideMenuClose: any;
	handleOnSuccess: Function;
	handleDeleteEvent: Function;
	menuAction: string;
	setMenuAction: Function;
	menuActionDefault: SideMenuAction;
}

const SideMenuForm = (props: Props) => {
	const { state, dispatch } = useContext(SchedulingContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [jobsLoading, setJobsLoading] = useState<boolean>(false);
	const [jobs, setJobs] = useState<Array<any>>([]);
	const [jobsSelectProps, setJobsSelectProps] = useState<any>({});
	const [skillsLoading, setSkillsLoading] = useState<boolean>(false);
	const [skills, setSkills] = useState<Array<any>>([]);
	const [skillsSelectProps, setSkillsSelectProps] = useState<any>({});
	const [workersLoading, setWorkersLoading] = useState<boolean>(false);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [selectionInfo, setSelectionInfo] = useState<string>('');
	const [highlightField, setHighlightField] = useState<string>('');
	const [clashes, setClashes] = useState<Array<any>>([]);
	const [clashOverridesLength, setClashOverridesLength] = useState<Number>(0);
	const [workersListSkills, setWorkersListSkills] = useState<Array<any>>([]);
	const [workersListLocations, setWorkersListLocations] = useState<Array<any>>([]);
	const [workLocationsLoading, setWorkLocationsLoading] = useState<boolean>(false);
	const [workLocations, setWorkLocations] = useState<Array<any>>([]);
	const skillsRef = useRef<any>(null); // Used for clearning value when changing job
	const workersRef = useRef<any>(null);
	const formColL = '2';
	const formColR = '10';

	// This effect is run each time due to the form being shown/hidden by its parent
	useEffect(() => {
		// Reset
		setIsLoading(false);

		// Set the information about the selection
		switch (state.resourceMode) {
			case ResourceMode.WORKERS:
				setSelectionInfo(`Worker: ${state.tempEvent?.workerName}`);
				break;
			case ResourceMode.JOBS:
				setSelectionInfo(
					`Job: ${state.tempEvent?.job?.label}, Manager: ${state.tempEvent?.job?.manager}`
				);
				break;
			case ResourceMode.SKILLS:
				setSelectionInfo(
					`Job: ${state.tempEvent?.job?.label}, Manager: ${state.tempEvent?.job?.manager}, Skill: ${state.tempEvent?.skill?.label}`
				);
				break;
		}
	}, []);

	// Execute action based on the menu action state to let the main scheduler know what to do after
	// the menu has closed (e.g. clear the temporary event if the user just cancel-closed the menu)
	useEffect(() => {
		switch (props.menuAction) {
			case SideMenuAction.CREATE_EVENT:
			case SideMenuAction.UPDATE_EVENT:
				handleCreateOrUpdateEvent();
				break;
			case SideMenuAction.DELETE_EVENT:
				props.handleDeleteEvent(
					state.tempEvent?.requestId,
					state.tempEvent?.skill?.value,
					false,
					props.menuActionDefault,
					props.setMenuAction,
					null,
					null,
					setIsLoading
				);
				break;
		}
	}, [props.menuAction]);

	// Only set the select data and values when options are loaded (avoids initial onChange event)
	useEffect(() => {
		if (jobs.length > 0)
			setJobsSelectProps({
				data: jobs,
				value: state.tempEvent?.job?.value,
				defaultSelection: state.tempEvent?.job?.value,
			});
	}, [jobs]);

	// Only set the select data and values when options are loaded (avoids initial onChange event)
	useEffect(() => {
		if (skills.length > 0)
			setSkillsSelectProps({
				data: skills,
				value: state.tempEvent?.skill?.value,
				defaultSelection: state.tempEvent?.skill?.value,
			});
	}, [skills]);

	// Pre-selected workers
	useEffect(() => {
		if (workers.length > 0 && state.tempEvent?.workers && workersRef.current)
			workersRef.current.setVal(state.tempEvent?.workers);
	}, [workers]);

	// General loading
	useEffect(() => {
		if (state.resourceMode === ResourceMode.WORKERS) loadJobs();
		if (state.resourceMode === ResourceMode.JOBS || state.resourceMode === ResourceMode.SKILLS)
			loadSkills(true);
	}, []);

	// Load the skills each time the job changes
	useEffect(() => {
		if (state.resourceMode === ResourceMode.WORKERS) loadSkills();
	}, [state.tempEvent?.job]);

	// Load the workers and work locations
	useEffect(() => {
		if (state.resourceMode === ResourceMode.JOBS || state.resourceMode === ResourceMode.SKILLS)
			loadWorkers();

		if (state.resourceMode === ResourceMode.WORKERS)
			loadWorkLocations(setWorkLocationsLoading, setWorkLocations);
	}, []);

	// Clear the clashes and clash overrides each time we change the worker(s)
	useEffect(() => {
		if (clashes.length > 0 && state.tempEvent?.workers !== undefined) {
			setClashes([]);
			dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { clashOverrides: null } });
		}
	}, [state.tempEvent?.workers]);

	// Highlight the current required field
	useEffect(() => {
		let tmpField: string = '';

		if (!state.tempEvent?.job) tmpField = 'jobs';

		if (
			state.tempEvent?.job &&
			state.resourceMode === ResourceMode.WORKERS &&
			!state.tempEvent?.skill
		)
			tmpField = 'skills';

		if (!state.tempEvent?.workers?.length) tmpField = 'workers';

		if (
			clashes.length > 0 &&
			(!state.tempEvent?.clashOverrides || state.tempEvent?.clashOverrides.length === 0)
		)
			tmpField = 'clashes';

		setHighlightField(tmpField);
	}, [state.tempEvent, clashes]);

	// Count the number of clash overrides
	useEffect(() => {
		let tmp = 0;

		if (state.tempEvent?.clashOverrides && Array.isArray(state.tempEvent.clashOverrides))
			tmp = state.tempEvent.clashOverrides.length;

		setClashOverridesLength(tmp);
	}, [state.tempEvent?.clashOverrides]);

	const loadJobs = () => {
		setJobsLoading(true);

		let payload: any = { worker_id: state.tempEvent?.workers };

		axios
			.post('/api/scheduling/jobs', payload)
			.then((res: any) => {
				setJobs(
					res.data.map((job: any) => ({
						text: job.number + ': ' + job.name,
						value: job._id,
						group: job.group,
					}))
				);
			})
			.catch(() => {
				showToast('error', null, null, { position: 'top-center' });
			})
			.finally(() => setJobsLoading(false));
	};

	const loadSkills = (returnAll: boolean = false) => {
		let payload: any = {};

		if (returnAll === false) payload.worker_id = state.tempEvent?.workers;

		if (state.tempEvent?.job) {
			payload.job_id = state.tempEvent.job.value;
		} else {
			setSkills([]);
			return false;
		}

		setSkillsLoading(true);
		axios
			.post('/api/scheduling/resources', payload)
			.then((res: any) => {
				setSkills(
					res.data.map((skill: any) => ({
						text: skill.name,
						value: skill.skill_id,
						hasSkill: skill.has_skill,
						scheduledHours: skill.scheduled_hours,
						manHours: skill.man_hours,
					}))
				);
			})
			.catch(() => {
				showToast('error', null, null, { position: 'top-center' });
			})
			.finally(() => setSkillsLoading(false));
	};

	const loadWorkers = () => {
		setWorkersLoading(true);

		let payload: any = {};
		if (state.tempEvent?.job) {
			const time: Array<string> = state.tempEvent?.time[0].split(' - ');
			payload.start = state.tempEvent?.start
				.set({
					hour: Number(time[0].split(':')[0]),
					minute: Number(time[0].split(':')[1]),
					second: 0,
				})
				.toISO();
			payload.end = state.tempEvent?.end
				.set({
					hour: Number(time[1].split(':')[0]),
					minute: Number(time[1].split(':')[1]),
					second: 0,
				})
				.toISO();
			payload.job_id = state.tempEvent.job.value;
			payload.view = state.resourceMode;
		} else {
			setWorkers([{ text: '', value: '' }]);
			return false;
		}

		if (state.resourceMode === ResourceMode.SKILLS) {
			payload.skill_id = state.tempEvent.skill?.value;
		}

		axios
			.post('/api/scheduling/workers', payload)
			.then((res: any) => {
				setWorkers(
					res.data.map((worker: any) => {
						let optionValue: string = `${worker._id},${worker.skill_id ?? 'no_skill_id'}`;
						let disabled: boolean = false;

						// Disable all options if editing this appointment except the one that came with it
						if (state.tempEvent?.workers && state.tempEvent?.isNew === false) {
							if (optionValue !== state.tempEvent.workers[0]) disabled = true;
						}

						return {
							workerId: worker._id,
							text: worker.name + ' ' + worker.skill,
							value: optionValue,
							group: worker.group,
							availability: worker.availability,
							name: worker.name,
							skill: worker.skill,
							scheduledHours: worker.scheduled_hours,
							manHours: worker.man_hours,
							locationId: worker.location_id,
							location: worker.location,
							disabled,
						};
					})
				);
			})
			.catch(() => {
				showToast('error', null, null, { position: 'top-center' });
			})
			.finally(() => setWorkersLoading(false));
	};

	const renderSelectOptionJobs = (item: any) => {
		let option: any = null;

		if (item.hasOwnProperty('isGroup') && item.isGroup === true) {
			// Group heading
			option = <div className='prop-mobi-select-group-heading'>{item.display}</div>;
		} else {
			// Option item
			option = (
				<div className='job-option'>
					<div>{item.data.text}</div>
				</div>
			);
		}

		return option;
	};

	const renderSelectOptionSkills = (item: any) => {
		let option: any = null;

		if (item.hasOwnProperty('isGroup') && item.isGroup === true) {
			// Group heading
			option = <div className='prop-mobi-select-group-heading'>{item.display}</div>;
		} else {
			// Option item
			option = (
				<div className='skill-option'>
					<div className={`flex-grow-1${item.data.has_skill ? ' has-skill' : ''}`}>
						{item.data.text}
					</div>
					{item.data.scheduledHours !== undefined && item.data.manHours !== undefined && (
						<div className='skill-option-man-hours-label'>{`MH: ${item.data.scheduledHours}/${item.data.manHours}`}</div>
					)}
				</div>
			);
		}

		return option;
	};

	const renderSelectOptionWorkers = (item: any) => {
		const NO_SKILLS_MATCH = 'No Skills Match';
		const workerId: string = item.value.split(',')[0];
		let option: any = null;

		if (item.hasOwnProperty('isGroup') && item.isGroup === true) {
			// Group heading
			option = <div className='prop-mobi-select-group-heading'>{item.display}</div>;
		} else {
			// Man hours
			let mh: string = '';
			if (item.data.scheduledHours && item.data.manHours) {
				mh = item.data.scheduledHours + '/' + item.data.manHours;
			}

			// Work from location
			let locationData: any = workersListLocations.filter(
				(workerLoc: any) => workerLoc.workerValue === item.value
			)[0];

			// Check if falling back from state modified data to existing worker location data
			if (!locationData) {
				locationData = {
					locationId: item.data.locationId,
					location: item.data.location,
				};
			}

			let locationIcon: FontAwesomeIconProps['icon'];
			let locationTitle: string = locationData.location;
			switch (locationData.locationId) {
				case '0':
					locationIcon = faClipboard;
					break;
				case '1':
					locationIcon = faHome;
					break;
				default:
					locationIcon = faBuilding;
					locationTitle = 'Office: ' + locationData.location;
					break;
			}

			// Option item
			option = (
				<div
					className={`worker-option avail-${item.data.availability}`}
					title={`${item.data.name} | ${item.data.skill}${mh.length > 0 ? ' | ' + mh : ''}`}
				>
					<div>{item.data.name}</div>
					<div>
						{item.data.skill !== NO_SKILLS_MATCH && item.data.skill}
						{item.data.skill === NO_SKILLS_MATCH && (
							<select
								key={item.value}
								className='workers-list-skills-select'
								onClick={(e: any) => {
									e.stopPropagation();
								}}
								onChange={(e: any) => {
									handleWorkersListSkillsChange(workerId, e.target.value);
								}}
							>
								<option value={`${workerId},0`}>-- Choose a Skill --</option>
								{skills
									.sort((a: any, b: any) => (a.text > b.text ? 1 : -1))
									.map((skillOption: any, i: number) => (
										<option key={i} value={`${workerId},${skillOption.value}`}>
											{skillOption.text}
										</option>
									))}
							</select>
						)}
					</div>
					<div className='mh-label'>{mh.length > 0 ? mh : ''}</div>
					<div
						className='btn-location'
						onClick={(e: any) =>
							handleBtnLocationClick(e, {
								workerValue: item.value,
								locationId: locationData.locationId,
								location: locationData.location,
								handleUpdateWorkerLocation,
							})
						}
						title={locationTitle}
					>
						<FontAwesomeIcon icon={locationIcon} />
					</div>
				</div>
			);
		}

		return option;
	};

	const renderWorkLocationsOption = (option: any) => {
		return {
			label: (
				<div className='work-location-option'>
					<FontAwesomeIcon icon={option.icon} />
					{option.text}
				</div>
			),
			value: option.value,
		};
	};

	const handleWorkLocationChange = (option: any) => {
		if (option.value !== state.tempEvent?.locationId) {
			dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { locationId: option.value } });
		}
	};

	// Location popover
	const [locationPopoverData, setLocationPopoverData] = useState<any>(null);

	const handleUpdateWorkerLocation = (
		workerValue: string,
		locationId: string,
		location: string
	) => {
		// Remove existing worker from state array so that they can only have one selected location
		let tmp: Array<any> = workersListLocations.filter(
			(item: any) => item.workerValue !== workerValue
		);

		// Add this worker's location
		tmp.push({ workerValue, locationId, location });

		// Update the state
		setWorkersListLocations(tmp);
	};

	const [present, dismiss] = useIonPopover(LocationPopover, {
		onDismiss: (data: any, role: string) => dismiss(data, role),
		locationPopoverData,
	});

	const handleBtnLocationClick = useCallback((e: any, locationData: any) => {
		e.stopPropagation();
		setLocationPopoverData({ e, ...locationData, dismiss });
	}, []);

	useEffect(() => {
		if (locationPopoverData) {
			present({
				event: locationPopoverData.e,
				onDidDismiss: (e: CustomEvent) => setLocationPopoverData(null),
			});
		}
	}, [locationPopoverData]);

	const mandatoryChecksOk = useCallback(() => {
		let formIsOk = false;

		// Mandatory field check
		if (state.tempEvent) {
			const tmp: SchedulingEvent = state.tempEvent;

			if (tmp.workers && tmp.workers.length > 0 && tmp.job) {
				formIsOk = true;

				if (state.resourceMode === ResourceMode.WORKERS && !tmp.skill) formIsOk = false;
			}
		}

		if (!formIsOk) {
			showToast('error', 'Please fill-in all fields', null, { position: 'top-center' });
			props.setMenuAction(props.menuActionDefault);
			return false;
		}

		return true;
	}, [state]);

	const clashChecks = useCallback(
		(workerSkillPairs: Array<string>) => {
			// Reset
			setClashes([]);
			dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { clashOverrides: null } });

			// Skip if no workers selected
			if (workerSkillPairs.length === 0) return;

			// Reset
			let clashItems: Array<any> = [];

			// Build an array of worker/skill pairs
			const workerSkillPairsFinal:
				| Array<{ worker_id: string; skill_id: string | null }>
				| undefined = workerSkillPairs.map((item: any) => {
				let tmp: Array<any> = item.split(',');
				return {
					worker_id: tmp[0],
					skill_id: tmp[1] !== 'no_skill_id' ? tmp[1] : null,
				};
			});

			const time: Array<string> = state.tempEvent?.time[0].split(' - ');

			let payload: any = {
				start: state.tempEvent?.start
					.set({
						hour: Number(time[0].split(':')[0]),
						minute: Number(time[0].split(':')[1]),
						second: 0,
					})
					.toISO(),
				end: state.tempEvent?.end
					.set({
						hour: Number(time[1].split(':')[0]),
						minute: Number(time[1].split(':')[1]),
						second: 0,
					})
					.toISO(),
				job_id: state.tempEvent?.job?.value,
				worker_skill_pairs: workerSkillPairsFinal,
			};

			// Extra payload items
			if (state.tempEvent?.isNew === false) payload.request_id = state.tempEvent?.requestId;

			// Perform checks
			setIsLoading(true);
			axios
				.post('/api/scheduling/clashes', payload)
				.then((res: any) => {
					if (res.data.length > 0) {
						clashItems = res.data.map((item: any) => {
							const clashDate: string =
								DateTime.fromISO(item.clash_date_start).toFormat('dd/MM/yy HH:mm') +
								' to ' +
								DateTime.fromISO(item.clash_date_end).toFormat('HH:mm');
							const clashDescription: string = `${item.worker_name} | ${item.item_name} | ${clashDate}`;

							return {
								text: (
									<div className='clash-option' title={clashDescription}>
										<div className='clash-option__worker-name'>{item.worker_name}</div>
										<div>{item.item_name}</div>
										<div className='clash-option__date-time'>{clashDate}</div>
									</div>
								),
								disabled:
									[
										RequestEventType.HOLIDAY,
										RequestEventType.APPROVED_ABSENCE,
										SickEventType.SICK,
									].indexOf(item.event_type_enum) >= 0,
								clashDateStart: item.clash_date_start,
								workerId: item.worker_id,
								eventId: item.event_id,
								clashType: item.clash_type,
								value: `${item.clash_date_start},${item.worker_id},${
									item.event_id ?? 'no_event_id'
								},${item.clash_type}`,
							};
						});

						setClashes(clashItems);

						props.setMenuAction(props.menuActionDefault);
					}
				})
				.catch(() => showToast('error'))
				.finally(() => setIsLoading(false));
		},
		[state]
	);

	const addWorkerHelper = (workerId: string, skillId: string) => {
		let workers: Array<string> | undefined = state.tempEvent?.workers;

		// Check worker isn't already in the array
		let workerExists: boolean = false;
		if (workers && workers.length > 0) {
			workerExists = workers.some((worker: string) => {
				let tmp: Array<string> = worker.split(',');
				return tmp[0] === workerId;
			});
		}

		// Add the worker to the temporary state if not currently in the state array
		if (workerExists === false && workers) {
			workers.push(`${workerId},no_skill_id`);

			// Clash checks
			clashChecks(workers);

			// Update the workers temp event and control
			updateWorkersHelper(workers);
		}
	};

	const removeWorkerHelper = (workerId: string) => {
		let workers: Array<string> | undefined = state.tempEvent?.workers;
		let filtered: Array<string> = [];
		if (workers && workers.length > 0) {
			filtered = workers.filter((worker: string) => {
				let tmp: Array<string> = worker.split(',');
				return tmp[0] !== workerId;
			});
		}

		// Update the workers temp event and control
		updateWorkersHelper(filtered);
	};

	// Form handlers
	const handleEventTimeChange = useCallback(
		(index: number, e: any, defaultTime: string) => {
			// Update the time array at the correct key
			let timeChange = state.tempEvent!.time!;
			timeChange[index] = e.valueText.length > 5 ? e.valueText : defaultTime;
			dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { time: timeChange } });
		},
		[state]
	);

	const handleEventTimeClose = useCallback(
		(index: number, e: any, defaultTime: string) => {
			// Upon closing the time dialog
			switch (state.resourceMode) {
				case ResourceMode.WORKERS:
					// Check clashes with new time
					if (
						state.tempEvent?.workers &&
						state.tempEvent?.job &&
						state.tempEvent.job.value &&
						state.tempEvent?.skill &&
						state.tempEvent.skill.value
					) {
						clashChecks([state.tempEvent.workers + ',' + state.tempEvent.skill.value]);
					}
					break;
				case ResourceMode.JOBS:
				case ResourceMode.SKILLS:
					// Reload the workers list to reflect the new time
					loadWorkers();

					// Perform clash checks again if editing an appointment
					if (state.tempEvent?.isNew === false) {
						if (Array.isArray(state.tempEvent.workers)) {
							clashChecks(state.tempEvent.workers);
						}
					}
					break;
			}
		},
		[state]
	);

	const handleWorkerChange = useCallback((args: any, inst: any) => {
		// Disallow multiple selections of the same worker
		let usedWorkerIds: Array<string> = [];
		let useWorkerIdsIndices: Array<number> = [];
		let valFinal: Array<string>;

		// Add all values to array but note indices of existing worker IDs
		args.value.forEach((val: string) => {
			let tmpWorkerId: string = val.split(',')[0];
			if (usedWorkerIds.indexOf(tmpWorkerId) < 0) {
				usedWorkerIds.push(tmpWorkerId);
			} else {
				useWorkerIdsIndices.push(usedWorkerIds.indexOf(tmpWorkerId));
			}
		});

		// Strip previously existing worker_ids using the indices previously gathered
		valFinal = args.value.filter(
			(val: string, index: number) => useWorkerIdsIndices.indexOf(index) < 0
		);

		// Clash checks
		clashChecks(valFinal);

		// Update the workers temp event and control
		updateWorkersHelper(valFinal);
	}, []);

	const handleJobChange = useCallback(
		(args: any, inst: any) => {
			// Update the control's values
			setJobsSelectProps((prevState: any) => ({
				...prevState,
				value: args.value,
				defaultSelection: args.value,
			}));

			// Update the temp event
			dispatch({
				type: ActionType.UPDATE_TEMP_EVENT,
				payload: { job: { value: args.value } },
			});

			// Clear the current skill
			dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { skill: null } });

			// Clear the skills select data option
			if (skillsRef.current) {
				skillsRef.current.setVal(null);
				setSkillsSelectProps({});
			}
		},
		[skillsRef]
	);

	const handleSkillChange = useCallback(
		(args: any, inst: any) => {
			// Update the control's values
			setSkillsSelectProps((prevState: any) => ({
				...prevState,
				value: args.value,
				defaultSelection: args.value,
			}));

			// Update the temp event
			dispatch({
				type: ActionType.UPDATE_TEMP_EVENT,
				payload: { skill: { value: args.value } },
			});

			// Clash checks
			clashChecks([state.tempEvent?.workers + ',' + args.value]);
		},
		[state]
	);

	const handleWorkersListSkillsChange = (id: string, val: string) => {
		const valSplit: Array<string> = val.split(',');
		const workerId: string = valSplit[0];
		const skillId: string = valSplit[1];

		// Remove existing worker from state array so that they can only have one selected skill
		let tmp: Array<any> = workersListSkills.filter((item: any) => item.workerId !== workerId);

		if (skillId !== '0') {
			// Add this worker/skill combo
			tmp.push({ workerId, skillId });

			// If changing the skill, then ensure the worker is selected
			addWorkerHelper(workerId, skillId);
		} else {
			// If reverting back to no skill, then ensure the worker is de-selected
			removeWorkerHelper(workerId);
		}

		// Update the state
		setWorkersListSkills(tmp);
	};

	const handleClashChange = useCallback(
		(args: any, inst: any) =>
			dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { clashOverrides: args.value } }),
		[]
	);

	const handleCreateOrUpdateEvent = useCallback(async () => {
		// Mandatory checks
		if (mandatoryChecksOk() === false) return;

		const time: Array<string> = state.tempEvent?.time[0].split(' - ');
		const payload: any = {
			event_type: 'appointment',
			start: state.tempEvent?.start
				.set({
					hour: Number(time[0].split(':')[0]),
					minute: Number(time[0].split(':')[1]),
					second: 0,
				})
				.toISO(),
			end: state.tempEvent?.end
				.set({
					hour: Number(time[1].split(':')[0]),
					minute: Number(time[1].split(':')[1]),
					second: 0,
				})
				.toISO(),
			job_id: state.tempEvent?.job!.value,
			skill_id: state.tempEvent?.skill?.value,
			worker_ids: state.tempEvent?.workers,
		};

		// Workers
		if (state.resourceMode === ResourceMode.WORKERS) {
			// Pre-format the worker IDs array
			payload.worker_ids = [state.tempEvent?.workers + ',' + state.tempEvent?.skill?.value];
		}

		// Split the workers array
		let workersTmp: Array<any> = [];
		if (payload.worker_ids && Array.isArray(payload.worker_ids)) {
			workersTmp = payload.worker_ids.map((item: any) => {
				// Look for this worker's potentially updated location
				let locationId: string = '';
				if (state.resourceMode === ResourceMode.WORKERS) {
					locationId = state.tempEvent?.locationId ? state.tempEvent.locationId : '';
				} else {
					locationId =
						workersListLocations.filter((loc: any) => loc.workerValue === item)[0]?.locationId ??
						workers.filter((loc: any) => loc.value === item)[0]?.locationId;
				}

				// Look for this worker's potentially updated skill
				let tmp: Array<any> = item.split(',');
				let skillId: any = tmp[1] !== 'no_skill_id' ? tmp[1] : null;

				if (!skillId) {
					const selectedSkillId: Array<any> = workersListSkills.filter(
						(item: any) => item.workerId === tmp[0]
					);

					if (
						Array.isArray(selectedSkillId) &&
						selectedSkillId.length > 0 &&
						selectedSkillId[0].hasOwnProperty('skillId')
					) {
						skillId = selectedSkillId[0].skillId;
					}
				}

				return {
					worker_id: tmp[0],
					skill_id: skillId,
					location_id: locationId,
				};
			});
		}

		// Check each worker has a skill
		if (workersTmp.some((worker: any) => worker.skill_id === null) === true) {
			showToast('error', 'Please ensure all workers have a selected skill', null, {
				position: 'top-center',
			});
			props.setMenuAction(props.menuActionDefault);
			return;
		} else {
			payload.worker_ids = workersTmp;
		}

		setIsLoading(true);

		// Clashes: Always send the clash_overrides even if none were selected
		if (clashes.length > 0) {
			payload.clash_overrides = [];

			// Clash overrides
			if (state.tempEvent?.clashOverrides && Array.isArray(state.tempEvent.clashOverrides)) {
				// Add the selected clash overrides to the payload
				payload.clash_overrides = state.tempEvent.clashOverrides.map((item: any) => {
					let tmp: Array<any> = item.split(',');
					return {
						start: tmp[0],
						worker_id: tmp[1],
						event_id: tmp[2] !== 'no_event_id' ? tmp[2] : null,
						clash_type: tmp[3],
						override: true,
					};
				});
			}

			// Add all other clashes to the payload that were not over-ridden
			clashes.forEach((clash: any) => {
				const payloadCheck: any = payload.clash_overrides.filter(
					(clashOverride: any) =>
						clashOverride.start === clash.clashDateStart &&
						clashOverride.worker_id === clash.workerId &&
						clashOverride.event_id === clash.eventId
				);

				if (payloadCheck.length === 0)
					payload.clash_overrides.push({
						start: clash.clashDateStart,
						worker_id: clash.workerId,
						event_id: clash.eventId,
						clash_type: clash.clashType,
						override: false,
					});
			});
		}

		let url: string = '/api/scheduling/requests';
		if (!state.tempEvent?.isNew) {
			url = `/api/scheduling/requests/${state.tempEvent?.requestId}`;
		}

		axios
			.put(url, payload)
			.then((response: any) => {
				if (response.data && response.data.error !== false) {
					setIsLoading(false);
					showToast('error', response.data.error, null, { position: 'top-center' });
					props.setMenuAction(props.menuActionDefault);
				} else {
					props.handleOnSuccess(
						state.tempEvent?.isNew ? 'created' : 'updated',
						state.tempEvent?.skill?.value
					);
				}
			})
			.catch(() => {
				setIsLoading(false);
				showToast('error', 'Sorry there was an error', null, { position: 'top-center' });
				props.setMenuAction(props.menuActionDefault);
			});
	}, [state, clashes, workersListLocations, workersListSkills]);

	const handleToggleAllClashes = useCallback(() => {
		let allClashes: Array<any> | null = null;
		const selectableClashes = clashes.filter((item: any) => !item.disabled);

		if (selectableClashes.length === clashOverridesLength) {
			// Deselect all
			allClashes = null;
		} else {
			// Select all
			allClashes = selectableClashes.map((item: any) => item.value);
		}

		dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { clashOverrides: allClashes } });
	}, [clashOverridesLength, clashes]);

	function updateWorkersHelper(theValue: Array<string>) {
		// The Mobiscroll select doesn't recognise a new value unless previously undefined
		if (workersRef.current) {
			// Clear the value first so that the control can register a new value
			workersRef.current.setVal(undefined);

			// Set the new value
			setTimeout(() => {
				workersRef.current.setVal(theValue);
			}, 100);
		}

		// Update the temp event
		dispatch({ type: ActionType.UPDATE_TEMP_EVENT, payload: { workers: theValue } });
	}

	return (
		<>
			{(isLoading || jobsLoading || skillsLoading || workersLoading || workLocationsLoading) && (
				<Loading overlay={true} />
			)}
			<div className='prop-form d-flex h-100 flex-column justify-content-between'>
				<div>
					{selectionInfo.length > 0 && (
						<Alert severity='info' className='m-0 mb-3'>
							{selectionInfo}
						</Alert>
					)}
					<IonRow>
						<IonCol size={formColL}>
							<IonLabel>Event Time</IonLabel>
						</IonCol>
						<IonCol size={state.resourceMode === ResourceMode.WORKERS ? '3' : formColR}>
							{state.tempEvent?.time.map((theTime: string, index: number) => {
								return TimeRow(
									theTime,
									index,
									state.tempEvent,
									undefined,
									handleEventTimeChange,
									handleEventTimeClose
								);
							})}
						</IonCol>
						{state.resourceMode === ResourceMode.WORKERS && (
							<>
								<IonCol size={formColL}>
									<IonLabel className='ps-3'>Work from</IonLabel>
								</IonCol>
								<IonCol size='5'>
									<SelectStyled
										isSearchable={false}
										className='scheduling-work-locations-select'
										value={workLocations
											.filter((option: any) => option.value === state.tempEvent?.locationId)
											.map((option: any) => renderWorkLocationsOption(option))}
										options={workLocations.map((option: any) => renderWorkLocationsOption(option))}
										onChange={handleWorkLocationChange}
									/>
								</IonCol>
							</>
						)}
					</IonRow>
					{state.resourceMode === ResourceMode.WORKERS && (
						<>
							<IonRow>
								<IonCol size={formColL}>
									<IonLabel className={highlightField === 'jobs' ? 'curItem' : ''}>Job</IonLabel>
								</IonCol>
								<IonCol size={formColR}>
									<div className='prop-mobi-select-inline filter'>
										<Select
											display='inline'
											filter={true}
											onChange={handleJobChange}
											selectMultiple={false}
											theme='ios'
											themeVariant='auto'
											touchUi={false}
											renderItem={renderSelectOptionJobs}
											rows={5}
											{...jobsSelectProps}
										/>
									</div>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size={formColL}>
									<IonLabel className={highlightField === 'skills' ? 'curItem' : ''}>
										Skill
									</IonLabel>
								</IonCol>
								<IonCol size={formColR}>
									<div className='prop-mobi-select-inline filter'>
										<Select
											ref={skillsRef}
											display='inline'
											filter={true}
											onChange={handleSkillChange}
											selectMultiple={false}
											theme='ios'
											themeVariant='auto'
											touchUi={false}
											renderItem={renderSelectOptionSkills}
											rows={5}
											{...skillsSelectProps}
										/>
									</div>
								</IonCol>
							</IonRow>
						</>
					)}
					{(state.resourceMode === ResourceMode.JOBS ||
						state.resourceMode === ResourceMode.SKILLS) && (
						<IonRow>
							<IonCol size={formColL}>
								<IonLabel className={highlightField === 'workers' ? 'curItem' : ''}>
									Worker(s)
								</IonLabel>
							</IonCol>
							<IonCol size={formColR}>
								<div className='prop-mobi-select-inline filter'>
									<Select
										ref={workersRef}
										display='inline'
										filter={true}
										onChange={handleWorkerChange}
										selectMultiple={true}
										theme='ios'
										themeVariant='auto'
										touchUi={false}
										renderItem={renderSelectOptionWorkers}
										rows={9}
										data={workers}
									/>
								</div>
							</IonCol>
						</IonRow>
					)}
					{clashes.length > 0 && (
						<>
							<IonRow>
								<IonCol size={formColL}></IonCol>
								<IonCol size={formColR}>
									<Alert severity='error' className='alert-clashes'>
										Please choose which clashes to override from the list below
									</Alert>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol size={formColL}>
									<IonLabel className={highlightField === 'clashes' ? 'curItem' : ''}>
										Clashes
									</IonLabel>
								</IonCol>
								<IonCol size={formColR}>
									<div className='prop-mobi-select-inline'>
										<Select
											value={state.tempEvent?.clashOverrides}
											defaultSelection={state.tempEvent?.clashOverrides}
											display='inline'
											onChange={handleClashChange}
											selectMultiple={true}
											theme='ios'
											themeVariant='auto'
											touchUi={false}
											data={clashes}
											rows={state.resourceMode === ResourceMode.WORKERS ? 3 : 4}
										/>
									</div>
								</IonCol>
							</IonRow>
						</>
					)}
					{/* <IonRow>
						<IonCol size={formColL}>Debug state:</IonCol>
						<IonCol size={formColL}>
							<div style={{ whiteSpace: 'pre', fontSize: '0.7em' }}>
								{state.tempEvent && JSON.stringify(state.tempEvent, null, '\t')}
							</div>
						</IonCol>
					</IonRow> */}
				</div>
				<div className='d-flex justify-content-between'>
					<IonButton onClick={props.handleSideMenuClose}>Cancel</IonButton>
					{clashes.length > 0 && (
						<IonButton color='secondary' onClick={handleToggleAllClashes}>
							{`${
								clashOverridesLength === clashes.filter((item: any) => !item.disabled).length
									? 'Clear'
									: 'Select '
							} All Clashes`}
						</IonButton>
					)}

					{state.tempEvent?.isNew === true && props.permissionTo('create') && (
						<IonButton
							onClick={() => props.setMenuAction(SideMenuAction.CREATE_EVENT)}
							color='success'
							disabled={isLoading}
						>
							Create Event
						</IonButton>
					)}
					{state.tempEvent?.isNew === false && (
						<div>
							{props.permissionTo('delete') && (
								<IonButton
									onClick={() => props.setMenuAction(SideMenuAction.DELETE_EVENT)}
									color='danger'
									disabled={isLoading}
								>
									Delete Event
								</IonButton>
							)}
							{props.permissionTo('update') && (
								<IonButton
									onClick={() => props.setMenuAction(SideMenuAction.UPDATE_EVENT)}
									color='success'
									disabled={isLoading}
								>
									Update Event
								</IonButton>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SideMenuForm;

import { faBuilding, faImage, faUser, faUsers, faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonAvatar, IonItem, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import { ConversationInterface } from '../../../interfaces/Chat/ConversationInterface';
import axios from '../../../lib/axios';
import FileIcon from '../../Icons/FileIcon';
import './ConversationItem.scss';
import { DateTime } from 'luxon';
import { isToday, isYesterday } from '../../../helpers/dates';
import { swapIcon } from '../../../helpers/strings';

interface Props {
	item: ConversationInterface;
	index: number;
	click: Function;
}

const ConversationItem: React.FC<Props> = (props: Props) => {
	const endpoint = '/api/conversations/workers/photo';
	const [image, setImage] = useState<any | undefined>(undefined);

	const showDate = (date: string) => {
		if (date === null || date === '' || date === undefined) {
			return '';
		} else {
			const dt = DateTime.fromISO(date);
			if (isToday(dt)) {
				return dt.toFormat('HH:mm');
			} else if (isYesterday(dt)) {
				return 'Yesterday';
			} else {
				return dt.toLocaleString();
			}
		}
	};

	const swapIt = (msg: string) => {
		let obj = swapIcon(msg);
		if (obj.start !== undefined) {
			let start = obj.start;
			let end = obj.end;
			let pieces = obj.pieces;
			if (pieces[2].indexOf('image') > -1) {
				return start + ' \u{1F4F7} ' + end;
			} else if (pieces[2].indexOf('video') > -1) {
				return start + ' \u{1F3A5} ' + end;
			} else {
				return (
					<>
						{start} <FileIcon height={'14px'} filename={pieces[1]} /> {end}
					</>
				);
			}
		} else if (obj.msg !== undefined) {
			return obj.msg;
		}
		return msg;
	};

	useEffect(() => {
		if (props.item.other_participants.length === 1) {
			axios
				.get(`${endpoint}/${props.item.other_participants[0]}`)
				.then((res) => res.data)
				.then((dbData) => {
					// Set the staff photo
					if (dbData.file) {
						let newImage = new Image();
						newImage.src = dbData.file;
						setImage(newImage);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [props.item]);

	return (
		<>
			<div>
				<IonItem
					button={true}
					className='conversation-item'
					onClick={() => {
						props?.click(props.item._id);
					}}
				>
					<IonAvatar slot='start'>
						{image !== undefined && <img src={image && image.src} alt='Worker photograph' />}
						{(image === undefined || image.src === undefined) && (
							<div className='icon-surround'>
								<FontAwesomeIcon
									icon={
										props.item.isGroup === true
											? faUsers
											: props.item.isJob === true
											? faBuilding
											: faUser
									}
								/>
							</div>
						)}
					</IonAvatar>
					<IonLabel>
						<h2>{props.item.name}</h2>
						<p>{swapIt(props.item.lastMsg)}</p>
					</IonLabel>
					<div slot='end'>
						<div className='end-surround'>
							<span>{showDate(props.item.last_message_date)}</span>
							{props.item.notRead > 0 && (
								<div style={{ textAlign: 'right' }}>
									<div className='badge badge-number'>{props.item.notRead}</div>
								</div>
							)}
						</div>
					</div>
				</IonItem>
			</div>
		</>
	);
};

export default ConversationItem;

import KeyDetails from './WorkerTabs/KeyDetails';

const NewWorkerTabsArray: any = (
	permissionTo: Function,
	saveForLaterFunction: Function,
	nextFunction: Function
) => {
	return [
		{
			title: 'Key Details',
			page: KeyDetails,
			active: true,
			pageData: {newWorker: true, nextFunction, saveForLaterFunction, permissionTo}
		},
		{ title: 'Payroll Details' },
		{ title: 'Next of Kin' },
		{ title: 'Working Hours' },
		{ title: 'Management Structure' },
		{ title: 'User Defined Fields' },
	];
};

export default NewWorkerTabsArray;

import EarlyStartLateFinishIndex from './EarlyStartLateFinish/EarlyStartLateFinishIndex';
import FailedToRecordIndex from './FailedToRecord/FailedToRecordIndex';
import FailedToRecordBreaktimesIndex from './FailedToRecordBreaktimes/FailedToRecordBreaktimesIndex';
import KPIIndex from './KPI/KPIIndex';
import LateStartEarlyFinishReportIndex from './LateStartEarlyFinishReport/LateStartEarlyFinishReportIndex';
import PhoneUsageReportIndex from './PhoneUsageReport/PhoneUsageReportIndex';
import StationaryPhoneIndex from './StationaryPhone/StationaryPhoneIndex';

export const reportsSubmenuArray: Array<any> = [
	{
		id: 'submenu-workerreportlatestartearlyfinish',
		uid: 'workers.reports.late_start_early_finish',
		title: 'League: Late Start / Early Finish',
		hash: 'late-start-early-finish',
		page: LateStartEarlyFinishReportIndex,
		active: true,
		fullHeight: true,
	},
	{
		id: 'workerreportearlystartlatefinish',
		uid: 'workers.reports.early_start_late_finish',
		title: 'League: Early Start / Late Finish',
		hash: 'early-start-late-finish',
		page: EarlyStartLateFinishIndex,
		active: false,
		fullHeight: true,
	},
	{
		id: 'workerreportfailedtorecord',
		uid: 'workers.reports.failed_to_record',
		title: 'League: Failed to Record',
		hash: 'failed-to-record',
		page: FailedToRecordIndex,
		active: false,
		fullHeight: true,
	},
	{
		id: 'workerreportfailedtorecordbreaktimes',
		uid: 'workers.reports.failed_to_record_breaktimes',
		title: 'League: Failed to Record Breaktimes',
		hash: 'failed-to-record-breaktimes',
		page: FailedToRecordBreaktimesIndex,
		active: false,
		fullHeight: true,
	},
	{
		id: 'workerreportstationaryphone',
		uid: 'workers.reports.stationary_phone',
		title: 'League: Stationary Phone',
		hash: 'stationary-phone',
		page: StationaryPhoneIndex,
		active: false,
		fullHeight: true,
	},
	{
		id: 'workerreportkpi',
		uid: 'workers.reports.kpi',
		title: 'League: KPI',
		hash: 'kpi',
		page: KPIIndex,
		active: false,
		fullHeight: true,
	},
	{
		id: 'submenu-workerreportphoneusage',
		uid: 'workers.reports.phone_usage',
		title: 'League: Phone Usage',
		hash: 'phone-usage',
		page: PhoneUsageReportIndex,
		active: false,
		fullHeight: true,
	},
];

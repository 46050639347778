import { useRef, useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { IonCard, IonRow, IonCol, IonButton } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';
import Form from '../../../../components/Forms/Form';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import SelectStyled from '../../../../components/UI/SelectStyled';

const WorkingHolidayEntitlementIndex: React.FC<RouteIndexComponent> = ({
	uid,
	routeTitle,
	permissionTo,
}) => {
	const gridRef: any = useRef<any>();
	const entitlementYearRef: any = useRef<any>();
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [data, setData] = useState<Array<any>>([]);
	const endpoint: string = '/api/utilities/workers/working_holiday_entitlement';
	const [showHistory, setShowHistory] = useState<boolean>(false);
	const [entitlementOptions, setEntitlementOptions] = useState<Array<any>>([]);
	const [fieldsDisabled, setFieldsDisabled] = useState<boolean>(false);
	const [gridYear, setGridYear] = useState<any>(DateTime.now());

	useEffect(() => {
		loadSetup();
	}, []);

	const loadSetup = () => {
		axios
			.get('/api/utilities/company_details/settings')
			.then((res) => {
				let workingHolidayYear: any = DateTime.now();

				if (res.data[0] && res.data[0]?.settings?.working_holiday_year) {
					workingHolidayYear = DateTime.fromFormat(
						res.data[0].settings.working_holiday_year.replace('00:00:00', '').trim(),
						'yyyy-MM-dd'
					);
				}

				// Hide the setup columns if today is more than 1 year from the working holiday year
				const curDate = DateTime.now();
				if (curDate.minus({ years: 1 }) > workingHolidayYear) {
					// Hide columns
					gridRef.current.setColumnsVisible(
						['holiday_allowance_first', 'absence_allowance_first'],
						false
					);

					// Show history controls
					setShowHistory(true);

					// Set history options
					const startYear: number = parseInt(workingHolidayYear.toFormat('yyyy'));
					const endYear: number = parseInt(DateTime.now().toFormat('yyyy'), 10);
					let tmpEntitlementOptions: Array<any> = [];
					for (let i = startYear; i < endYear; i++) {
						tmpEntitlementOptions.push({
							label: `${i} Worker Entitlement`,
							value: i,
						});
					}
					setEntitlementOptions(tmpEntitlementOptions);
				}

				loadGridData();
			})
			.catch((err: any) => {
				showToast('error');
			});
	};

	const loadGridData = (historicalEntitlementYear: number = 0) => {
		setGridLoading(true);
		setFieldsDisabled(false);
		setData([]);

		let payload: any = {};
		let gridYearTmp: any = null;
		if (historicalEntitlementYear > 0) {
			gridYearTmp = DateTime.fromFormat(historicalEntitlementYear.toString(), 'yyyy');
			payload.year = historicalEntitlementYear;
			setFieldsDisabled(true);
		} else {
			gridYearTmp = DateTime.now();
		}

		// Update the grid year state
		setGridYear(gridYearTmp);

		axios
			.post(endpoint, payload)
			.then((res: any) => {
				let result = res.data
					.filter((r: any) => r.entitlements.length > 0)
					.map((r: any) => {
						// Get the current year's entitlements
						const entitlementsObj: any = r.entitlements.filter(
							(ent: any) => parseInt(ent.year, 10) === parseInt(gridYearTmp.toFormat('yyyy'), 10)
						)[0];

						// Merge the entitlements with the parent
						const mergedObj: any = { ...r, ...entitlementsObj };

						// Remove the entitlements object property - no longer needed
						const { entitlements, ...rest } = mergedObj;

						return rest;
					});

				setData(result);
			})
			.catch((err: any) => {
				showToast('error');
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const onEntitlementYearChange = (option: any) => {
		let historicalEntitlementYear: number = 0;
		if (option && option.value) historicalEntitlementYear = parseInt(option.value, 10);

		loadGridData(historicalEntitlementYear);
	};

	const cell = (params: any, key: string) => {
		return (
			<Form
				array={[
					{ type: 'number', value: parseInt(params.data[key], 10), db: ['entitlements', key] },
				]}
				forceEdit={permissionTo('update') && !fieldsDisabled}
				noButton={true}
				tableMode={true}
				permissionTo={permissionTo}
				endpoint={endpoint}
				endpointID={`${params.data._id}/${params.data.year}`}
			/>
		);
	};

	const columns: Array<any> = [
		{
			headerName: 'Worker Name',
			field: 'last_name',
			sortable: true,
			sort: 'asc',
			cellRenderer: (params: any) => {
				return `${params.data.first_name} ${params.data.last_name}`;
			},
		},
		{
			headerName: 'Days Allowed: Holiday',
			field: 'holiday_allowance',
			sortable: true,
			type: 'rightAligned',
			cellRenderer: (params: any) => cell(params, 'holiday_allowance'),
		},
		{
			headerName: 'Pre-setup Initial Used: Holiday',
			field: 'holiday_allowance_first',
			sortable: true,
			type: 'rightAligned',
			cellRenderer: (params: any) => cell(params, 'holiday_allowance_first'),
		},
		{
			headerName: 'Days Allowed: Approved Absence',
			field: 'absence_allowance',
			sortable: true,
			type: 'rightAligned',
			cellRenderer: (params: any) => cell(params, 'absence_allowance'),
		},
		{
			headerName: 'Pre-setup Initial Used: Absence',
			field: 'absence_allowance_first',
			sortable: true,
			type: 'rightAligned',
			cellRenderer: (params: any) => cell(params, 'absence_allowance_first'),
		},
	];

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonCard className='table-card filter-data-table full-height-card'>
				<DataGrid
					ref={gridRef}
					title={`Holiday & Absence Entitlement - ${gridYear.toFormat('yyyy')}`}
					cols={columns}
					data={data}
					extraFooter={
						showHistory && (
							<>
								<h4 className='font-bold mt-3'>Historical Entitlements</h4>
								<IonRow className='mt-1'>
									<IonCol size={'6'} className='ps-0 p-1'>
										<SelectStyled
											forwardRef={entitlementYearRef}
											placeholder={"Select a previous year's entitlements to view above..."}
											options={entitlementOptions}
											isDisabled={gridLoading}
											onChange={(option: any, event: any) => {
												onEntitlementYearChange(option);
											}}
										/>
									</IonCol>
									<IonCol size={'6'} className='p-0'>
										<IonButton
											onClick={() => {
												entitlementYearRef.current.clearValue();
											}}
										>
											Reset
										</IonButton>
									</IonCol>
								</IonRow>
							</>
						)
					}
				/>
			</IonCard>
		</>
	);
};

export default WorkingHolidayEntitlementIndex;

export const faDashboard = {
	prefix: 'fas',
	iconName: 'dashboard',
	icon: [
		100,
		100,
		[] as string[],
		null,
		'M 88.48,41.25 H 61.5 v 25 H 88.48 Z M 84.31,62.08 H 65.68 V 45.42 h 18.63 z m -58.8,4.46 h 26.96 c 1.15,0 2.09,-0.93 2.09,-2.08 0,-1.15 -0.94,-2.08 -2.09,-2.08 H 25.51 c -1.15,0 -2.09,0.93 -2.09,2.08 0,1.15 0.94,2.08 2.09,2.08 z m -12.39,0 h 3.89 c 1.15,0 2.09,-0.93 2.09,-2.08 0,-1.15 -0.94,-2.08 -2.09,-2.08 h -3.89 c -1.15,0 -2.09,0.93 -2.09,2.08 0,1.15 0.94,2.08 2.09,2.08 z M 25.51,56.1 h 22.14 c 1.15,0 2.09,-0.93 2.09,-2.08 0,-1.15 -0.94,-2.08 -2.09,-2.08 H 25.51 c -1.15,0 -2.09,0.93 -2.09,2.08 0,1.15 0.94,2.08 2.09,2.08 z m -12.39,0 h 3.89 c 1.15,0 2.09,-0.93 2.09,-2.08 0,-1.15 -0.94,-2.08 -2.09,-2.08 h -3.89 c -1.15,0 -2.09,0.93 -2.09,2.08 0,1.15 0.94,2.08 2.09,2.08 z M 25.51,45.65 h 26.96 c 1.15,0 2.09,-0.93 2.09,-2.08 0,-1.15 -0.94,-2.08 -2.09,-2.08 H 25.51 c -1.15,0 -2.09,0.93 -2.09,2.08 0,1.15 0.94,2.08 2.09,2.08 z m -12.39,0 h 3.89 c 1.15,0 2.09,-0.93 2.09,-2.08 0,-1.15 -0.94,-2.08 -2.09,-2.08 h -3.89 c -1.15,0 -2.09,0.93 -2.09,2.08 0,1.15 0.94,2.08 2.09,2.08 z M 89.47,10.13 H 10.82 c -4.88,0 -8.85,3.97 -8.85,8.84 v 59.78 c 0,4.88 3.97,8.84 8.85,8.84 h 78.65 c 4.88,0 8.85,-3.97 8.85,-8.84 V 18.97 c 0,-4.88 -3.97,-8.84 -8.85,-8.84 z m 4.67,68.62 c 0,2.58 -2.1,4.67 -4.67,4.67 H 10.82 c -2.58,0 -4.67,-2.1 -4.67,-4.67 V 29.17 h 87.99 v 49.57 z m 0,-53.74 h -88 v -6.03 c 0,-2.58 2.1,-4.67 4.67,-4.67 h 78.66 c 2.58,0 4.67,2.1 4.67,4.67 z',
	],
};

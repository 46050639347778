import { ToastOptions, toast } from 'react-toastify';

type toastTypeString =
	| 'success'
	| 'warning'
	| 'error'
	| 'saved'
	| 'deleted'
	| 'removed'
	| 'permission'
	| 'info';
type msg = string | JSX.Element | undefined | null;
type toastID = any | undefined | null;

const toastOptions: ToastOptions = {
	autoClose: 3000,
	closeButton: true,
	closeOnClick: true,
	pauseOnFocusLoss: false,
	isLoading: false,
};

export const closeToast = (toastID: toastID) => {
	toast.dismiss(toastID);
};

export const showToast = (
	toastTypeString: toastTypeString,
	msg?: msg,
	toastID?: toastID,
	otherOptions?: ToastOptions
) => {
	let toastType = null;

	switch (toastTypeString) {
		case 'success':
			toastType = toast.TYPE.SUCCESS;
			msg = msg ? msg : 'Success!';
			break;
		case 'warning':
			toastType = toast.TYPE.WARNING;
			msg = msg ? msg : 'Warning!';
			break;
		case 'error':
			toastType = toast.TYPE.ERROR;
			msg = msg ? msg : 'Sorry there was an error';
			break;
		case 'saved':
			toastType = toast.TYPE.SUCCESS;
			msg = msg ? msg : 'Successfully saved';
			break;
		case 'deleted':
			toastType = toast.TYPE.SUCCESS;
			msg = msg ? msg : 'Successfully deleted';
			break;
		case 'removed':
			toastType = toast.TYPE.SUCCESS;
			msg = msg ? msg : 'Successfully removed';
			break;
		case 'permission':
			toastType = toast.TYPE.WARNING;
			msg = msg ? msg : 'You do not have access';
			break;
		case 'info':
			toastType = toast.TYPE.INFO;
			msg = msg ? msg : '...';
			break;
	}

	if (
		toastID !== undefined &&
		toastID !== null &&
		otherOptions !== undefined &&
		otherOptions !== null &&
		(otherOptions.toastId === null ||
			otherOptions.toastId === undefined ||
			otherOptions.toastId === '')
	) {
		otherOptions.toastId = toastID;
	}

	// Reset the toast options
	let toastOptionsFinal: any = { ...toastOptions };

	// Add any other additional options if supplied
	if (otherOptions) toastOptionsFinal = { ...toastOptions, ...otherOptions };

	if (toastID && toast.isActive(toastID)) {
		toast.update(toastID, {
			...toastOptionsFinal,
			render: msg,
			type: toastType,
		});
	} else {
		const toastId = toast(msg, {
			...toastOptionsFinal,
			type: toastType,
		});
		return toastId;
	}
};

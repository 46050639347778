import { DateTime } from 'luxon';
import { useEffect, useReducer, useState } from 'react';
import { csrf } from '../../../helpers/csrf';
import { useSubscribe } from '../../../helpers/events';
import { highlightList } from '../../../helpers/listFunctions';
import { listReducer } from '../../../helpers/listReducer';
import axios from '../../../lib/axios';
import InfinteScroll from '../../InfiniteScroll/InfiniteScroll';
import Loading from '../../UI/Loading';
import SearchItem from '../SearchItem/SearchItem';

import './SearchMenu.scss';

interface Props {
	isMenuOpen: boolean;
	conversationId: string;
	participants?: Array<any>;
	searchTerm: string;
	clickFunction: Function;
}

const SearchMenu: React.FC<Props> = (props: Props) => {
	const [messageData, dispatchMessageData] = useReducer(listReducer, {
		list: [],
		isShowList: true,
	});
	const [loading, setLoading] = useState<boolean>(false);

	const searchIsOpen = props.isMenuOpen === true;

	useSubscribe(
		'database:new:messages',
		(data: any) => {
			dispatchMessageData({ type: 'add', data: data });
		},
		[searchIsOpen]
	);

	useSubscribe(
		'database:update:messages',
		(data: any) => {
			dispatchMessageData({ type: 'add', data: data });
		},
		[searchIsOpen]
	);

	useSubscribe(
		'database:delete:messages',
		(data: any) => {
			dispatchMessageData({ type: 'remove', data: data });
		},
		[searchIsOpen]
	);
	useEffect(() => {
		if (props.searchTerm !== '') {
			setLoading(true);
			doSearch();
		} else {
			dispatchMessageData({
				type: 'replace',
				data: [],
			});
		}
	}, [props.searchTerm]);

	const doSearch = () => {
		csrf().then(() => {
			axios
				.post('/api/conversations/' + props.conversationId + '/messages', {
					search: props.searchTerm,
				})
				.then((data: any) => {
					dispatchMessageData({
						type: 'replace',
						data: highlightList(data.data.messages, { data: { text: props.searchTerm } }),
					});
					setTimeout(() => {
						setLoading(false);
					}, 100);
				})
				.catch((error: any) => {
					console.error(error);
				});
		});
	};

	return (
		<>
			<div className="search-menu" hidden={!props.isMenuOpen}>
				<div className="content">
					{loading && <Loading width="100%"></Loading>}
					{messageData.list.length > 0 && !loading && (
						<InfinteScroll
							items={messageData.list}
							itemTemplate={SearchItem}
							extraProps={{
								participants: props.participants,
							}}
							click={(id: string) => props.clickFunction(id)}
						></InfinteScroll>
					)}
					{messageData.list.length === 0 && !loading && <p>No Results</p>}
				</div>
			</div>
		</>
	);
};

export default SearchMenu;

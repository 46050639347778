import { useContext, useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import { authContext } from '../../../contexts/AuthContext';
import { Eventcalendar } from '@mobiscroll/react';
import { DateTime } from 'luxon';
import axios from '../../../lib/axios';
import { parseCalendarEvent } from '../../../helpers/calendar';
import Loading from '../../../components/UI/Loading';
import { dateFormatterFullOrdinal } from '../../../helpers/dateFormatter';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const Diary: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const permissionTo = usePermissionTo('workers.workers_list.worker_card.diary');
	const selectedDate: DateTime = DateTime.now();
	const [events, setEvents] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	let history = useHistory();

	useEffect(() => {
		setIsLoading(true);
		let payload: any = {
			worker_id: authCtx.user.worker_id,
			start: selectedDate.startOf('day').toISO(),
			end: selectedDate.endOf('day').toISO(),
		};

		axios
			.post('/api/workers/workers_list/worker_card/diary', payload)
			.then((res: any) => {
				// Parse and set the event data
				setEvents(
					res.data.map((event: any) => parseCalendarEvent({ event, source: 'workersDiary' }))
				);
			})
			.catch(() => {})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const handleViewDiary = () => {
		history.push(`/workers/workers_list/worker_card/${authCtx.user.worker_id}#diary`);
	};

	const curDate = dateFormatterFullOrdinal(DateTime.now(), false);

	return (
		<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
			<div className='widget-header'>My Diary - {curDate}</div>
			<div className='widget-content'>
				{isLoading && <Loading overlay={true} />}
				<div className='diary-container'>
					<Eventcalendar
						actionableEvents={false}
						data={events}
						defaultSelectedDate={selectedDate}
						view={{
							schedule: {
								type: 'day',
								allDay: false,
							},
						}}
						showControls={false}
						renderDay={() => null}
					/>
				</div>
			</div>
			<div className='widget-footer justify-content-end'>
				{permissionTo('read') && (
					<IonButton size='small' onClick={handleViewDiary}>
						View Diary
					</IonButton>
				)}
			</div>
		</div>
	);
};

export default Diary;

import { DateTime } from 'luxon';

export const ScheduleEvent = (event: any) => {
	return (
		<>
			<div className='mbsc-schedule-event-background mbsc-timeline-event-background mbsc-material'></div>
			<div aria-hidden='true' className='mbsc-schedule-event-inner mbsc-material'>
				<div className='mbsc-schedule-event-title mbsc-material'>{event.title}</div>
				<div className='mbsc-schedule-event-range mbsc-material'>
					{`${DateTime.fromJSDate(event.startDate).toFormat('HH:mm')} - ${DateTime.fromJSDate(
						event.endDate
					).toFormat('HH:mm')}`}
				</div>
			</div>
			<div className='mbsc-hidden-content'>{event.ariaLabel}</div>
		</>
	);
};

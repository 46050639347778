import { DateTime } from 'luxon';

export function isToday(a: DateTime): boolean {
	const b = DateTime.now().set({ hour: 0, minute: 0, second: 0 });
	return a.hasSame(b, 'day') && a.hasSame(b, 'month') && a.hasSame(b, 'year');
}

export function isYesterday(a: DateTime): boolean {
	const b = DateTime.now().set({ hour: 0, minute: 0, second: 0 }).minus({ days: 1 });
	return a.hasSame(b, 'day') && a.hasSame(b, 'month') && a.hasSame(b, 'year');
}

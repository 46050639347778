import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton } from '@ionic/react';
import { AxiosProgressEvent } from 'axios';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import axiosMP from '../../lib/axiosMp';
import { showToast } from '../../lib/toast';
import { Alert } from '@mui/material';
import Loading from '../UI/Loading';

interface Props {
	permissionTo: Function;
	uploadKey: string;
	apiUrl: string;
	acceptTypes: string;
}

const UploadFileButton = (props: Props) => {
	const toastId = useRef<any>(null);
	const refFileInput = useRef<HTMLInputElement>(null);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [errors, setErrors] = useState<Array<any>>([]);

	const triggerUpload = () => {
		if (refFileInput.current) {
			refFileInput.current.value = '';
			refFileInput.current.click();
		}
	};

	const handleFileUploaded = (e: any) => {
		const file = e.target.files;

		// Reset
		toastId.current = null;
		setErrors([]);

		setIsUploading(true);

		axiosMP
			.post(
				props.apiUrl,
				{ [props.uploadKey]: file[0] },
				{
					onUploadProgress: (p: AxiosProgressEvent) => {
						const progress = p.loaded / (p.total ?? 0);

						if (toastId.current === null) {
							toastId.current = toast('Uploading file...', { type: toast.TYPE.INFO });
						} else {
							toast.update(toastId.current, {
								progress,
								render: 'Uploading file (' + Math.floor(progress * 100) + '%)...',
							});
						}
					},
				}
			)
			.then(() => {
				showToast('success');
			})
			.catch((err: any) => {
				showToast('error');

				// Display friendly errors
				if (err.response.status === 422) {
					setErrors(err.response.data.errors);
				}
			})
			.finally(() => {
				toast.dismiss(toastId.current);
				setIsUploading(false);
			});
	};

	return (
		<>
			{isUploading && <Loading overlay={true} />}
			{!props.permissionTo(['read', 'create']) && (
				<Alert severity='warning' style={{ margin: '0 0 8px 0' }}>
					You do not have permission to use this feature
				</Alert>
			)}
			{props.permissionTo(['read', 'create']) && (
				<>
					<IonButton
						className='btn-large-icon'
						color={'light'}
						onClick={triggerUpload}
						disabled={isUploading}
					>
						<input
							hidden
							id={props.uploadKey}
							type='file'
							accept={props.acceptTypes}
							onChange={handleFileUploaded}
							ref={refFileInput}
						/>
						<div className='d-flex flex-column'>
							<div>
								<FontAwesomeIcon icon={faFileImport} />
							</div>
							<div className='btn-large-icon-label'>Import XLSX or CSV</div>
						</div>
					</IonButton>
				</>
			)}
			{errors.length > 0 && (
				<>
					<Alert severity='error' style={{ margin: '8px 0' }}>
						It looks like there are some errors in your uploaded file:
						<ul>
							{errors.map((error: any, index: number) => (
								<li key={index}>{error[0]}</li>
							))}
						</ul>
					</Alert>
				</>
			)}
		</>
	);
};

export default UploadFileButton;

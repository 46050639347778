import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { faJobs } from '../../../components/Icons/custom-icons.module';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const CreateJob: React.FC<Props> = (props) => {
	let history = useHistory();

	const handleCreateJob = () => {
		history.push('/jobs/jobs_list/add_new');
	};

	return (
		<>
			<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
				<div className='widget-header'>Create Job</div>
				<div className='widget-content'>
					<IonButton className='btn-widget-plaque'>
						<div className='widget-plaque' onClick={handleCreateJob}>
							<FontAwesomeIcon icon={faJobs as IconDefinition} />
						</div>
					</IonButton>
				</div>
				<div className='widget-footer justify-content-end'>
					<IonButton size='small' onClick={handleCreateJob}>
						Create Job
					</IonButton>
				</div>
			</div>
		</>
	);
};

export default CreateJob;

import React, {
	Ref,
	useEffect,
	useRef,
	useContext,
	useState,
} from 'react';
import { DateTime } from 'luxon';

import { authContext } from "../../../contexts/AuthContext";
import { isset } from '../../../helpers/typeFunctions';
import { IonModal } from '@ionic/react';
import TimesheetList from './TimesheetList/TimesheetList';
import { showToast } from '../../../lib/toast';
import axios from '../../../lib/axios';

interface Props {
	date?: DateTime;
	minHours?: number;
	visible: boolean;
	onClose: Function;
}

const AllocationsPage: React.FC<Props> = (props: Props) => {
	const authCtx: any = useContext(authContext);
	const ref: any = useRef<Ref<HTMLIonModalElement>>();
	const [month, setMonth] = useState<string>('');
	const [year, setYear] = useState<string>();

	useEffect(() => {
		if (isset(props.date)) {
			setMonth(props.date ? props.date.toFormat('LL') : '');
			setYear(props.date ? props.date.toFormat('yyyy') : '');
		}
	}, [props.date]);

	const saveAllocation = async (rows: Array<any>) => {
		axios.put('/api/workers/allocations/' + month + '/' + year, {
			data: rows,
			timestamp: DateTime.now().toISO(),
		}).then(() => {
			props.onClose();
			let obj = {...authCtx.user}
			obj.monthly_allocation_start = false;
			obj.monthly_allocation_amount = false;
			authCtx.setUserData(obj);
		});
	}

	return (
		<>
			<IonModal
				ref={ref}
				style={{ '--width': '89vw', '--height': '96vh' }}
				isOpen={props.visible}
				backdropDismiss={false}
				className='prop-form'
				onDidDismiss={() => {
					//setIsOpen(false);
				}}
			>
				<TimesheetList
					date={props.date}
					minHours={props.minHours}
					onClose={async (rows: Array<any>) => {
						saveAllocation(rows);
					}}
				/>
			</IonModal>
		</>
	);
};

export default AllocationsPage;

import { TimelineDot } from '@mui/lab';
import { MouseEventHandler } from 'react';

type Props = {
	item: any;
	inactiveFilters: Array<string>;
	onClick: MouseEventHandler<HTMLDivElement>;
};

const TimelineFilterRow: React.FC<Props> = (props: Props) => {
	const isInactive: boolean = props.inactiveFilters.indexOf(props.item.id) >= 0;
	let colour = props.item.colour;
	let palette = props.item.palette;

	if (isInactive) {
		colour = 'grey';
		palette = null;
	}

	return (
		<div
			className={`timeline-filter-row ${
				props.inactiveFilters.indexOf(props.item.id) >= 0 ? 'inactive' : ''
			}`}
			onClick={() => props.onClick(props.item)}
		>
			<div className='tfr-dot'>
				<TimelineDot
					color={colour}
					sx={palette ? { backgroundColor: `${colour}.${palette}` } : null}
					variant={isInactive ? 'outlined' : 'filled'}
				/>
			</div>
			<div className='tfr-label'>{props.item.label}</div>
		</div>
	);
};

export default TimelineFilterRow;

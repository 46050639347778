import { configureStore } from "@reduxjs/toolkit";
import menuExpanded from "./slices/menuExpanded";
import browserID from "./slices/browserID";
import appState from "./slices/appState";

export default configureStore({
	reducer: {
		menuExpanded: menuExpanded,
		browserID: browserID,
		appState: appState,
	},
});

import { useState, useContext, useEffect } from 'react';
import AuthButton from '../Forms/AuthButton';
import { authContext } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { publish } from '../../helpers/events';
import NotificationsModal from '../Notifications/NotificationsModal/NotificationsModal';
import SettingsModal from '../Settings/SettingsModal/SettingsModal';
import useComponentVisible from '../../utils/custom-hooks/ComponentVisible';
import NotificationsBadge from './NotificationsBadge';
import MyDetailsModal from '../MyDetails/MyDetailsModal/MyDetailsModal';

interface Props {
	isVisible: boolean;
	onVisibleChange: Function;
}

const UserMenu = (props: Props) => {
	const authCtx: any = useContext(authContext);
	const [isLoggingOut, setIsLoggingOut] = useState(false);
	const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);
	const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
	const [isMyDetailsOpen, setIsMyDetailsOpen] = useState<boolean>(false);
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const handleLogout = async () => {
		setIsLoggingOut(true);
		await authCtx.setUnauthStatus();
		publish('logout', true);
	};

	useEffect(() => {
		setIsComponentVisible(props.isVisible);
	}, [props.isVisible]);

	useEffect(() => {
		props.onVisibleChange(isComponentVisible);
	}, [isComponentVisible]);

	return (
		<>
			{isComponentVisible && (
				<div className='title-user-menu' ref={ref}>
					<div className='user-menu-header'>
						<FontAwesomeIcon icon={faCircleUser} /> {authCtx.user.first_name}{' '}
						{authCtx.user.last_name} - Your Account
					</div>
					<div className='user-menu-inner'>
						<AuthButton
							btnType='logout'
							id='btn-settings'
							className='w-100 mb-2'
							onClick={() => {
								setIsComponentVisible(false);
								setIsSettingsOpen(true);
							}}
						>
							SETTINGS
						</AuthButton>
						<AuthButton
							btnType='logout'
							id='btn-notifications'
							className='w-100 mb-2'
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							onClick={() => {
								setIsComponentVisible(false);
								setIsNotificationsOpen(true);
							}}
						>
							NOTIFICATIONS <NotificationsBadge floating={false} />
						</AuthButton>
						<AuthButton
							btnType='logout'
							id='btn-my-details'
							className='w-100 mb-2'
							onClick={() => {
								setIsComponentVisible(false);
								setIsMyDetailsOpen(true);
							}}
						>
							MY DETAILS
						</AuthButton>
						<AuthButton
							btnType='logout'
							id='btn-logout'
							className='w-100'
							loading={isLoggingOut}
							loadingMessage=''
							onClick={handleLogout}
						>
							LOGOUT
						</AuthButton>
					</div>
				</div>
			)}
			<NotificationsModal
				isOpen={isNotificationsOpen}
				onClose={() => {
					setIsNotificationsOpen(false);
				}}
			/>
			<SettingsModal
				isOpen={isSettingsOpen}
				onClose={() => {
					setIsSettingsOpen(false);
				}}
			/>
			<MyDetailsModal
				isOpen={isMyDetailsOpen}
				onClose={() => {
					setIsMyDetailsOpen(false);
				}}
			/>
		</>
	);
};

export default UserMenu;

import { InvoiceData, InvoiceItem, InvoiceState } from './invoice-types.d';
import { Actions as Action, ActionType } from './actions';

export const invoicesReducer = (state: InvoiceState, action: Action): InvoiceState => {
	switch (action.type) {
		case ActionType.RESET: {
			return {
				...state,
				invoiceData: null,
				items: [],
				isLoading: false,
			};
		}
		case ActionType.SET_ITEMS: {
			const { payload } = action;

			const items: InvoiceItem[] = payload;

			return {
				...state,
				items: items,
			};
		}
		case ActionType.UPDATE_INVOICE_DATA: {
			const { payload } = action;

			const invoiceData: InvoiceData = { ...state.invoiceData, ...payload };

			return {
				...state,
				invoiceData: invoiceData,
			};
		}
		case ActionType.SET_IS_LOADING: {
			const { payload } = action;
			return {
				...state,
				isLoading: payload,
			};
		}
		case ActionType.ADD_ITEM_ROW: {
			const { payload } = action;

			const items: Array<InvoiceItem> = state.items;
			items.push(payload);

			return {
				...state,
				items: items,
			};
		}
		case ActionType.DELETE_ITEM_ROW: {
			const { payload } = action;

			const items: Array<InvoiceItem> = state.items;
			items.splice(payload.index, 1);

			return {
				...state,
				items: items,
			};
		}
		case ActionType.UPDATE_ITEM_ROW: {
			const { payload } = action;
			const items: Array<InvoiceItem> = state.items;
			items[payload.index] = payload.localState;

			return {
				...state,
				items: items,
			};
		}
		case ActionType.UPDATE_ITEM_ROW_AMOUNT: {
			const { payload } = action;
			const items: Array<InvoiceItem> = state.items;

			if (payload.hasOwnProperty('totals')) {
				// Update the item rows
				payload.totals.map((p: any, i: number) => {
					items[i].amount = p.total;
					return p;
				});
			}

			return {
				...state,
				items: items,
			};
		}
		case ActionType.UPDATE_INVOICE_TOTALS: {
			const { payload } = action;

			return {
				...state,
				totals: { ...payload },
			};
		}
		case ActionType.SET_INVOICE_TOTALS_LOADING: {
			const { payload } = action;
			return {
				...state,
				totalsLoading: payload,
			};
		}
		default: {
			return state;
		}
	}
};

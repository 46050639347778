import { toast } from 'react-toastify';
import axios from '../lib/axios';
import { showToast } from '../lib/toast';
import { getGroupedOptionByValue } from '../lib/functions';

const keysToNestedObject = (keys: Array<string>, value: any) => {
	let obj: any = {};
	let last: any = obj;
	keys.forEach((key: string, index: number) => {
		last[key] = keys.length - 1 === index ? value : {};
		last = last[key];
	});
	return obj;
};

const realObjMerge = (to: any, from: any) => {
	for (let n in from) {
		if (typeof to[n] != 'object') {
			to[n] = from[n];
		} else if (typeof from[n] == 'object') {
			to[n] = realObjMerge(to[n], from[n]);
		}
	}

	return to;
};

/**
 * Function to strip form data foen to DB data fields only
 * Notes:
 * allowrefs
 * ---------
 * On pages like adding a worker or a job, the initial page uses refs on
 * the fields to access them all at the time of saving the entire form
 * at once (as opposed to the normal onBlur saving). On the edit worker
 * or job page (its the same form), we don't use refs. However on forms
 * like editing a job, we use autocompleters that require refs to set the
 * fields below. So in this case the form still uses refs when in editing
 * mode and that it why we need an allowRefs override at the time of
 * processing/saving to force this function to not use refs and therefore
 * act as normal.
 */
export const processForm = (formData: Array<any>, allowRefs: boolean = true) => {
	let result = {};

	for (const fd of formData) {
		// Check if the form field has a DB identifier array
		if (fd.db && fd.db.length > 0) {
			// Create nested objects based on form DB arrays and attach the value
			let fieldValue: any = null;
			let d: any = null;

			// Check if we're using a reference to the field value
			if (fd.ref && fd.ref.current && allowRefs === true) {
				switch (fd.type) {
					case 'dropdown':
					case 'dropdownAsync':
					case 'autocomplete':
						if (fd.isMulti) {
							fieldValue = fd.ref.current.getValue().map((item: any) => item.value);
						} else {
							fieldValue = fd.ref.current.getValue();
							if (fieldValue.length > 0) fieldValue = fieldValue[0].value;
						}
						break;
					default:
						fieldValue = fd.ref.current.value;
						break;
				}
			} else {
				// Normal field values from form array
				switch (fd.type) {
					case 'dropdown':
					case 'dropdownAsync':
					case 'autocomplete':
						if (fd.isMulti) {
							if (fd.hasOwnProperty('value')) {
								// This key was set when changing values on-form
								fieldValue = fd.value;
							} else if (fd.hasOwnProperty('defaultValue')) {
								// This key was set when loading the form from fresh
								fieldValue = fd.defaultValue;
							}

							if (fieldValue) fieldValue = fieldValue.map((item: any) => item.value);
						} else {
							if (fd.hasOwnProperty('value')) {
								// This key was set when changing values on-form
								fieldValue = fd.value;
							} else if (fd.hasOwnProperty('defaultValue')) {
								// This key was set when loading the form from fresh
								fieldValue = fd.defaultValue[0].value;
							}
						}
						break;
					default:
						fieldValue = fd.value;
						break;
				}
			}

			d = keysToNestedObject(fd.db, fieldValue);

			// Deep merge each object back with the result object
			realObjMerge(result, d);
		}
	}

	return result;
};

// Function to update database as a result of form changes
type methodString = undefined | 'PUT' | 'POST';
export const updateForm = async (
	changes: any,
	endpoint?: string,
	endpointID?: string,
	method: methodString = 'PUT',
	showLoading?: Function
) => {
	const formData = processForm(changes, false);
	let formUpdated = false;
	let endpointURL = endpoint;
	let result = null;
	let toastID = null;
	let errorResponse: any = null;

	if (showLoading) {
		showLoading(true);
		toastID = toast.loading('Please wait...');
	}

	if (endpointURL) {
		if (endpointID && endpointID.length > 0) {
			if (endpointURL.slice(-1) !== '/') {
				endpointURL = endpointURL.concat('/');
			}
			endpointURL = endpointURL.concat(endpointID);
		}

		try {
			switch (method) {
				case 'PUT':
					result = await axios.put(endpointURL, formData);
					break;
				case 'POST':
					result = await axios.post(endpointURL, formData);
					break;
			}
			if (typeof result !== 'undefined') {
				formUpdated = true;
				if (showLoading) {
					showLoading(false);
					showToast('saved', null, toastID);
				}
			}
		} catch (err: any) {
			formUpdated = false;
			errorResponse = err;

			if (showLoading) {
				showLoading(false);

				// Handle the server error message gracefully
				switch (err.response.status) {
					case 422:
						// Unprocessable Content
						showToast('error', err.response.data.message, toastID);
						break;
					default:
						showToast('error', null, toastID);
						break;
				}
			}
		}
	} else {
		showToast('error', 'No endpoint supplied', toastID);
	}

	return {
		success: formUpdated,
		returnData: result,
		errorResponse,
	};
};

// Function that uses an array of keys as a path for a nested object
const getValueUsingPath = (data: any, path: any) => {
	return path.reduce((data: any, item: any) => {
		return data ? data[item] : null;
	}, data);
};

// Function to merge stored DB values with the form structure before displaying
export const db2Form = (formData: any, dbData: any): Array<any> => {
	for (const fd of formData) {
		if (fd.children && fd.children.length > 0) {
			db2Form(fd.children, dbData);
		} else {
			if (fd.db && fd.db.length > 0) {
				const fieldValue = getValueUsingPath(dbData, fd.db);

				switch (fd.type) {
					case 'dropdown':
					case 'dropdownAsync':
						if (String(fieldValue).length > 0 && fieldValue !== undefined && fieldValue !== null) {
							if (Array.isArray(fieldValue)) {
								// isMulti selection
								fd.defaultValue = fieldValue.map((fv: any) => {
									const itemVal = fd.values.filter((v: any) => v.value === fv);
									return itemVal[0] ?? null;
								});
							} else {
								const hasGroupedValues: boolean = fd.values.some((item: any) =>
									item.hasOwnProperty('options')
								);

								// Single selection
								if (hasGroupedValues) {
									// Set the value
									fd.defaultValue = getGroupedOptionByValue(fd.values, fieldValue);
								} else {
									// set the value
									fd.defaultValue = fd.values.filter((v: any) => v.value === fieldValue);
								}
							}
						}
						break;
					default:
						// All other fields
						fd.value = fieldValue;
						break;
				}
			}
		}
	}

	// Attach the _id DB identifier
	let dbIdent = null;
	if (dbData && dbData._id && dbData._id.length > 0) dbIdent = dbData._id;
	if (dbData && dbData.id && dbData.id.length > 0) dbIdent = dbData.id;
	if (dbIdent && dbIdent.length > 0) {
		formData['_id'] = dbIdent;
	} else {
		formData['_id'] = '';
	}

	return formData;
};

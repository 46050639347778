import { useEffect, useState } from 'react';
import {
	IonRow,
	IonCol,
	IonCard,
	IonButton,
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonFooter,
	IonButtons,
} from '@ionic/react';
import axios from '../../../../../lib/axios';
import DataGrid from '../../../../../components/DataGrid/DataGrid';
import './AccountDetails.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
	uid: string;
	permissionTo: Function;
}

const Messages = (props: Props) => {
	const [data, setData] = useState<Array<any>>([]);

	// Message modal state
	const [messageModal, setMessageModal] = useState<any>({ isOpen: false });
	const [messageModalData, setMessageModalData] = useState<any>({});

	const columns = [
		{
			headerName: 'Date',
			field: 'date',
			filter: 'agDateColumnFilter',
			flex: 1,
		},
		{
			headerName: 'Subject Line',
			field: 'subject',
			filter: 'agTextColumnFilter',
			flex: 10,
		},
	];

	// TODO: Update endpoint and data once ready in the backend
	useEffect(() => {
		axios.get('/api/utilities/company_details').then((d: any) => {
			const messagesData: Array<any> = [
				{
					date: '01/01/2023',
					subject: 'Happy New Year!',
					body: 'Happy new year from the team at HumanFacta.',
				},
			];
			setData(messagesData);
		});
	}, []);

	const messageModalOpen = async (params: any) => {
		setMessageModalData({
			date: params.data.date,
			subject: params.data.subject,
			body: params.data.body,
		});
		setMessageModal({ isOpen: true });
	};

	const messageModalOnClose = () => {
		setMessageModal({ isOpen: false });
	};

	return (
		<>
			<div className={`component-${props.uid.replaceAll('.', '-')}`}>
				<IonCard className='propeller-content-card full-height-card p-0'>
					<DataGrid
						title={'Messages'}
						cols={columns}
						data={data}
						rowClickedFunction={messageModalOpen}
						rowCursorPointer={true}
					/>
				</IonCard>
			</div>

			<MessageModal
				isOpen={messageModal.isOpen}
				onClose={messageModalOnClose}
				onDidDismiss={messageModalOnClose}
				initialData={messageModalData}
			/>
		</>
	);
};

export default Messages;

interface MessageModalProps {
	isOpen: boolean;
	onClose: Function;
	onDidDismiss: Function;
	initialData: any;
}

const MessageModal: React.FC<MessageModalProps> = ({
	isOpen,
	onClose,
	onDidDismiss,
	initialData,
}) => {
	return (
		<IonModal
			class='message-modal'
			isOpen={isOpen}
			onDidDismiss={() => onDidDismiss()}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Message: {initialData.date}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<h4>{initialData.subject}</h4>
				<p>{initialData.body}</p>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

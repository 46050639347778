import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { publish } from '../helpers/events';
import axios from '../lib/axios';

interface Props {
	arrNoAuthCheck: Array<any>;
}

export const ResponseInterceptor: React.FC<Props> = (props: Props) => {
	const interceptorId = useRef<number | null>(null);
	let history = useHistory();

	useEffect(() => {
		interceptorId.current = axios.interceptors.response.use(
			(response) => {
				// Successful response
				if (response.status === 200 && response.data.logout === 'true') {
					publish('logout', true);
				}
				return response;
			},
			(error) => {
				const otp = error.response?.data?.otp;
				// Unauthorised response - not logged-in
				if (
					error &&
					error.response &&
					(error.response.status === 401 || error.response.status === 419) &&
					otp !== 'required'
				) {
					publish('logout', true);
				} else {
					// Unsuccessful response
					return Promise.reject(error);
				}
			}
		);

		return () => {
			axios.interceptors.response.eject(interceptorId.current as number);
		};
	}, [history, props]);

	return null;
};

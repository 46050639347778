import {
	faCircleCheck,
	faCircleExclamation,
	faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IonRow, IonCol } from '@ionic/react';
import TitleBar from '../../components/TitleBar/TitleBar';
import { useGetRoutes } from '../../routes';
import { translateRouteStatus } from '../../helpers/ui';

interface Props {
	uid: string;
	routeTitle: string;
}

const UtilitiesIndex: React.FC<Props> = ({ uid, routeTitle }) => {
	const routes = useGetRoutes(uid, true);
	const [utilities, setUtilities] = useState<Array<any>>([]);
	let utilitiesArray: Array<any> = [];

	// Gather unique groups
	const utilityGroups: any = routes
		.map((item) => item.item_group)
		.filter((value, index, self) => self.indexOf(value) === index);

	// Create a grouped array of utilities
	for (let i in utilityGroups) {
		let groupName = utilityGroups[i];
		let groupData: any = routes.filter(
			(r) => r.item_group === groupName && String(r.uid).split('.').length === 2
		);

		utilitiesArray.push({
			name: groupName.split(' ').map((t: any) => t[0].toUpperCase() + t.substring(1)),
			children: groupData.map((g: any, i: number) => {
				return {
					name: g.title,
					url: g.pathname,
					status: translateRouteStatus(g.status),
				};
			}),
		});
	}

	useEffect(() => {
		setUtilities(utilitiesArray);
	}, []);

	const utilitiesSections = () => {
		if (utilities && utilities.length > 0) {
			let sections = utilities.map((section, key) => {
				return (
					<IonRow className='utilities-section rounded-3 p-2' key={key}>
						<IonCol size={'12'}>
							<h3 className='utilities-title'>{section.name}</h3>
						</IonCol>
						{getSectionChildren(section)}
					</IonRow>
				);
			});
			return sections;
		} else {
			return null;
		}
	};

	const getSectionChildren = (section: any) => {
		let items = section.children.map((item: any, key: any) => {
			return (
				<IonCol size={'12'} sizeSm={'6'} sizeMd={'4'} sizeLg={'3'} key={key}>
					<NavLink to={item.url}>
						<div className='utilities-item'>
							<FontAwesomeIcon icon={getItemIcon(item.status)} className={'icon-' + item.status} />
							<h4>{item.name}</h4>
						</div>
					</NavLink>
				</IonCol>
			);
		});
		return items;
	};

	const getItemIcon = (status: any) => {
		if (status === 'success') {
			return faCircleCheck;
		} else if (status === 'warning') {
			return faCircleExclamation;
		} else {
			return faCircleXmark;
		}
	};

	return (
		<>
			<TitleBar title={routeTitle} />
			<div id='utilities-sections'>{utilitiesSections()}</div>
		</>
	);
};

export default UtilitiesIndex;

import { useEffect, useState } from "react";

interface Props {
	countryCode: string;
	height?: string;
}

const FlagIcons: React.FC<Props> = (props: Props) => {	
	const [icon, setIcon] = useState<string>('');

	useEffect(() => {
		setIcon(props.countryCode.toLowerCase());
	}, [props.countryCode]);
		

	return (
		<>
			<img
				src={
					`/assets/flags/` +
					icon +
					`.svg`
				}
				style={{ maxHeight: props.height }}
			/>
		</>
	);
}

export default FlagIcons;
import { useContext, useEffect, useRef } from 'react';
import { InvoiceContext } from '../InvoicesProvider';
import Loading from '../../../../components/UI/Loading';
import InvoiceHeader from './InvoiceHeader';
import InvoiceItems from './InvoiceItems';
import InvoiceFooter from './InvoiceFooter';
import UploadsModal from '../../shared/estimates-invoices/UploadsModal';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	estimateId: string;
	jobId: string;
	invoiceId?: string;
}

const InvoiceCardLayout: React.FC<Props> = ({
	permissionTo,
	isNew,
	estimateId,
	jobId,
	invoiceId,
}) => {
	const { state, dispatch } = useContext(InvoiceContext);
	const uploadsModalRef = useRef<any>(null);

	const handleUploadsModalOpen = () => {
		if (uploadsModalRef.current) {
			uploadsModalRef.current.isOpen = true;
			uploadsModalRef.current.type = 'invoice';
			uploadsModalRef.current.typeId = invoiceId;
		}
	};

	const handleUploadsModalClose = () => {
		if (uploadsModalRef.current) uploadsModalRef.current.isOpen = false;
	};

	return (
		<>
			{state.isLoading && <Loading overlay={true} />}
			<InvoiceHeader
				permissionTo={permissionTo}
				isNew={isNew}
				estimateId={estimateId}
				jobId={jobId}
				invoiceId={invoiceId}
			/>
			<InvoiceItems permissionTo={permissionTo} />
			<InvoiceFooter
				permissionTo={permissionTo}
				isNew={isNew}
				invoiceId={invoiceId}
				handleUploadsModalOpen={handleUploadsModalOpen}
			/>
			<UploadsModal
				ref={uploadsModalRef}
				onClose={handleUploadsModalClose}
				permissionTo={permissionTo}
			/>
		</>
	);
};

export default InvoiceCardLayout;

import {
	CalendarNav,
	CalendarNext,
	CalendarPrev,
	CalendarToday,
	Eventcalendar,
	MbscCalendarEvent,
	MbscEventClickEvent,
	MbscEventcalendarView,
	MbscPageChangeEvent,
} from '@mobiscroll/react';
import {
	IonGrid,
	IonRow,
	IonCol,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	useIonAlert,
} from '@ionic/react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import WorkerControls from '../WorkerControls';
import { useParams } from 'react-router-dom';
import axios from '../../../../lib/axios';
import { loadBankHolidays, parseCalendarEvent } from '../../../../helpers/calendar';
import Loading from '../../../../components/UI/Loading';
import { baseColours } from '../../../../components/Calendars/Defaults';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { showToast } from '../../../../lib/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CalendarContextMenu from '../../../../components/Calendars/CalendarContextMenu';
import WorkCalendarModal from '../modals/WorkCalendarModal';
import { DateTime } from 'luxon';

interface Props {
	nextFunction?: Function;
	prevFunction?: Function;
	permissionTo: Function;
}

const WorkCalendar: React.FC<Props> = (props: Props) => {
	const tabId = 'work_calendar';
	const moduleCtx = useContext<any>(moduleContext);
	const { workerId }: any = useParams();
	const [presentAlert] = useIonAlert();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [workerLocationsLoading, setWorkerLocationsLoading] = useState<boolean>(true);
	const [locationsOptions, setLocationsOptions] = useState<Array<any>>([]);
	const [calInst, setCalInst] = useState<Eventcalendar>();
	const [calendarLoading, setCalendarLoading] = useState<boolean>(true);
	const [events, setEvents] = useState<Array<any>>([]);
	const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
	const [viewMode, setViewMode] = useState<string>('month');
	const [view, setView] = useState<MbscEventcalendarView>({
		calendar: { type: 'month', size: 1, labels: true },
	});
	const [tempEvent, setTempEvent] = useState<any>(null);
	const [bankHolidays, setBankHolidays] = useState<Array<any>>([]);
	let permissionOptions = {};

	// Modals
	const [workCalendarModal, setWorkCalendarModal] = useState<any>({ isOpen: false });

	// Calender options based on permissions
	if (props.permissionTo('create')) {
		permissionOptions = { clickToCreate: 'single', dragToCreate: true };
	} else {
		permissionOptions = { actionableEvents: false };
	}

	// Right-click context menu
	const [contextMenuInst, setContextMenuInst] = useState<any>();
	const [contextMenuAnchor, setContextMenuAnchor] = useState<any>();
	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
	const [contextMenuRightClickedEvent, setContextMenuRightClickedEvent] = useState<any>(null);
	const contextMenuOptions: Array<any> = [
		{
			text: (
				<>
					<FontAwesomeIcon icon={faTrash} className='pe-2' />
					Delete
				</>
			),
			value: 'delete',
			action: () => deleteEvent(contextMenuRightClickedEvent.event),
			hasPermission: props.permissionTo('delete'),
		},
	];

	const handleSetView = (viewMode: string) => {
		setViewMode(viewMode);

		switch (viewMode) {
			case 'month':
				setView({ calendar: { type: 'month', size: 1, labels: true } });
				break;
			case 'fortnight':
				setView({ calendar: { type: 'week', size: 2, labels: true } });
				break;
			case 'week':
				setView({ calendar: { type: 'week', size: 1, labels: true } });
				break;
		}
	};

	const customHeader = () => {
		return (
			<IonGrid className='mbsc-prop-custom-header p-0'>
				<IonRow>
					<IonCol size='4' className='p-0'>
						<CalendarNav />
					</IonCol>
					<IonCol size='4' className='p-0 text-center'>
						<IonSegment value={viewMode}>
							<IonSegmentButton value='month' onClick={() => handleSetView('month')}>
								<IonLabel>Month</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value='fortnight' onClick={() => handleSetView('fortnight')}>
								<IonLabel>Fortnight</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value='week' onClick={() => handleSetView('week')}>
								<IonLabel>Week</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</IonCol>
					<IonCol size='4' className='p-0 text-right'>
						<CalendarPrev />
						<CalendarToday />
						<CalendarNext />
					</IonCol>
				</IonRow>
			</IonGrid>
		);
	};

	useEffect(() => {
		setIsLoading(workerLocationsLoading || calendarLoading || locationsOptions.length === 0);
	}, [workerLocationsLoading, calendarLoading, locationsOptions]);

	useEffect(() => {
		loadWorkerLocations();
	}, []);

	useEffect(() => {
		if (tempEvent) {
			workCalendarModalOpen();
		}
	}, [tempEvent]);

	const loadWorkerLocations = () => {
		setWorkerLocationsLoading(true);

		moduleCtx.getWorkerLocationOptions().then((res: any) => {
			setLocationsOptions(res);
			setWorkerLocationsLoading(false);
		});
	};

	const loadCalendar = (fromDay: Date, toDay: Date) => {
		setCalendarLoading(true);
		cancelCreation();

		let payload: any = {
			worker_id: workerId,
			start: fromDay,
			end: toDay,
		};

		loadBankHolidays(
			DateTime.fromJSDate(fromDay).month,
			DateTime.fromJSDate(fromDay).year,
			setBankHolidays
		);

		axios
			.post('/api/workers/workers_list/worker_card/work_calendar', payload)
			.then((res: any) => {
				// Parse and set the event data
				setEvents(
					res.data.map((event: any) => {
						let locationData: any = { label: null };
						locationsOptions.map((item: any) => {
							const d = item.options.filter((option: any) => option.value === event.location);
							if (d.length > 0) locationData = d[0];
						});

						event.title = locationData.label;

						return parseCalendarEvent({ event });
					})
				);
			})
			.catch((err: any) => console.error(err))
			.finally(() => {
				setCalendarLoading(false);
			});
	};

	const deleteEvent = (event: MbscCalendarEvent) => {
		presentAlert({
			header: 'Delete Availability',
			message: 'Are you sure you want delete this availability?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						setIsLoading(true);
						axios
							.delete(`/api/workers/workers_list/worker_card/work_calendar/${event.id}`)
							.then(() => {
								if (calInst) {
									loadCalendar(calInst._firstDay, calInst._lastDay);
								}
							})
							.catch(() => {
								showToast('error');
							})
							.finally(() => cancelCreation());
					},
				},
			],
		});
	};

	const handleOnCalInit = useCallback((e: any, inst: Eventcalendar) => {
		setCalInst(inst);
	}, []);

	const handleOnCalPageLoading = useCallback(
		(e: MbscPageChangeEvent, inst: Eventcalendar) => {
			// Set a timeout on the first load to avoide the component update warning
			setTimeout(
				() => {
					loadCalendar(inst._firstDay, inst._lastDay);
				},
				firstPageLoaded ? 0 : 200
			);
		},
		[locationsOptions]
	);

	const handleOnCalPageLoaded = useCallback(() => {
		setFirstPageLoaded(true);
	}, []);

	const handleOnCalEventClick = useCallback(
		(e: MbscEventClickEvent) => {
			// Stop the cell click event from firing
			e.domEvent.stopPropagation();

			// Close the context menu if open
			if (contextMenuInst && contextMenuInst.props.isOpen === true) {
				contextMenuInst.close();
			}
		},
		[contextMenuInst]
	);

	const handleOnCalEventRightClick = useCallback(
		(e: any, inst: Eventcalendar) => {
			e.domEvent.preventDefault();

			// Don't open context menu on new events
			if (tempEvent && tempEvent.event.id === e.event.id) return;

			// Open the context menu
			setContextMenuAnchor(e.domEvent.target);
			setContextMenuRightClickedEvent(e);
			setTimeout(() => setContextMenuOpen(true));
		},
		[tempEvent]
	);

	const handleOnCalEventDragStart = useCallback(
		(e: MbscCalendarEvent, inst: Eventcalendar) => {
			cancelCreation();

			// Close the context menu if open
			if (contextMenuInst && contextMenuInst.props.isOpen === true) {
				contextMenuInst.close();
			}
		},
		[events, contextMenuInst]
	);

	const handleOnCalTempEventCreate = useCallback((e: MbscCalendarEvent, inst: Eventcalendar) => {
		setTempEvent(e);
	}, []);

	const handleCalRenderLabelContent = useCallback((data: any) => {
		return <div className='ev-lbl'>{data.original.title}</div>;
	}, []);

	const handleOnCalEventDelete = useCallback(
		(e: MbscCalendarEvent, inst: Eventcalendar) => {
			cancelCreation();
		},
		[events]
	);

	const cancelCreation = () => {
		setEvents([...events]);
		setTempEvent(null);
	};

	const createEvent = useCallback(
		(selectedOption: any) => {
			if (selectedOption) {
				setIsLoading(true);

				let payload: any = {
					start: DateTime.fromJSDate(tempEvent.event.start).toISO(),
					end: DateTime.fromJSDate(tempEvent.event.end).toISO(),
					worker_id: workerId,
					location: selectedOption.value,
				};

				axios
					.put('/api/workers/workers_list/worker_card/work_calendar', payload)
					.then(() => {
						if (calInst) {
							loadCalendar(calInst._firstDay, calInst._lastDay);
						}
					})
					.catch(() => {
						showToast('error');
					})
					.finally(() => cancelCreation());
			}
		},
		[calInst, tempEvent]
	);

	const workCalendarModalOpen = () => {
		setWorkCalendarModal({ isOpen: true });
	};

	const workCalendarModalOnClose = () => {
		cancelCreation();
		setWorkCalendarModal({ ...workCalendarModal, isOpen: false });
	};

	return (
		<div className='work-calendar'>
			{isLoading && <Loading overlay={true} />}

			{!workerLocationsLoading && (
				<>
					<Eventcalendar
						height={'630px'}
						data={events}
						firstDay={1}
						view={view}
						renderHeader={customHeader}
						renderLabelContent={handleCalRenderLabelContent}
						onInit={handleOnCalInit}
						onPageLoading={handleOnCalPageLoading}
						onPageLoaded={handleOnCalPageLoaded}
						onEventClick={handleOnCalEventClick}
						onEventRightClick={handleOnCalEventRightClick}
						onEventDragStart={handleOnCalEventDragStart}
						onEventCreate={handleOnCalTempEventCreate}
						onEventDelete={handleOnCalEventDelete}
						colors={[...baseColours, ...bankHolidays]}
						showEventTooltip={false}
						newEventText='New Availability'
						{...permissionOptions}
					/>

					<CalendarContextMenu
						options={contextMenuOptions}
						contextMenuOpen={contextMenuOpen}
						contextMenuAnchor={contextMenuAnchor}
						setContextMenuInst={setContextMenuInst}
						setContextMenuOpen={setContextMenuOpen}
						setContextMenuRightClickedEvent={setContextMenuRightClickedEvent}
					/>
				</>
			)}

			<WorkerControls tabId={tabId} workerId={workerId} setIsLoading={setIsLoading} />

			<WorkCalendarModal
				isOpen={workCalendarModal.isOpen}
				eventData={tempEvent}
				locationsOptions={locationsOptions}
				createEvent={createEvent}
				onClose={workCalendarModalOnClose}
			/>
		</div>
	);
};

export default WorkCalendar;

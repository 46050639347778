import { useCallback, useEffect, useState } from 'react';
import {
	Eventcalendar,
	setOptions,
	localeEnGB,
	MbscEventcalendarView,
	MbscCellClickEvent,
	MbscPageChangeEvent,
	MbscEventClickEvent,
	Select,
	CalendarPrev,
	CalendarNext,
	CalendarToday,
	CalendarNav,
} from '@mobiscroll/react';
import EventModal from '../modals/EventModal';
import { EventData, EventPayload, EventType } from './event-types';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { getDaysArray } from '../../../../lib/functions';
import { AxiosResponse } from 'axios';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { IonCol, IonGrid, IonRow, useIonAlert } from '@ionic/react';
import { baseColours } from '../../../../components/Calendars/Defaults';
import { loadBankHolidays } from '../../../../helpers/calendar';

setOptions({
	locale: localeEnGB,
	theme: 'material',
	themeVariant: 'light',
});

const TrainingEvents = () => {
	const [events, setEvents] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [calInst, setCalInst] = useState<Eventcalendar>();
	const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
	const view: MbscEventcalendarView = {
		calendar: { type: 'month' },
	};
	const eventDataDefault: EventData = {
		requestId: undefined,
		selectedDate: undefined,
		multiDay: false,
		time: ['09:00 - 17:00'],
		description: '',
		type: EventType.TRAINING,
		certificate: false,
	};
	const permissionTo = usePermissionTo('workers.holidays_and_absences.training_or_event');
	const [presentAlert] = useIonAlert();
	const [bankHolidays, setBankHolidays] = useState<Array<any>>([]);

	// Right-click context menu
	const [menuAnchor, setMenuAnchor] = useState();
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [contextMenuValue, setContextMenuValue] = useState<any>(false);
	const [rightClickedEvent, setRightClickedEvent] = useState<any>(null);
	const contextMenu = [
		{
			text: 'Delete',
			value: 'delete',
		},
	];

	// Modals
	const [eventModal, setEventModal] = useState<{ isOpen: boolean }>({ isOpen: false });
	const [eventModalData, setEventModalData] = useState<EventData>(eventDataDefault);

	useEffect(() => {
		// Open the modal when a date is selected from the calendar
		if (eventModalData.selectedDate) {
			setEventModal((prevState: any) => ({ ...prevState, isOpen: true }));
		}
	}, [eventModalData]);

	const loadCalendar = (fromDay: Date, toDay: Date) => {
		setIsLoading(true);
		let payload: any = {
			event_types: [EventType.TRAINING, EventType.OTHER],
			start: fromDay,
			end: toDay,
		};

		loadBankHolidays(
			DateTime.fromJSDate(fromDay).month,
			DateTime.fromJSDate(fromDay).year,
			setBankHolidays
		);

		axios
			.post('/api/workers/holidays_and_absences/events/diary', payload)
			.then((res: any) => {
				// Parse and set the event data
				setEvents(
					res.data.map((event: any) => ({
						id: event.request_id,
						start: DateTime.fromISO(event.start),
						end: DateTime.fromISO(event.end),
						title: event.description,
						color: '#' + event.colour,
					}))
				);
			})
			.catch(() => {})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const loadEvent = async (requestId: string) => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>(
				`/api/workers/holidays_and_absences/requests/${requestId}`
			);
			return response.data;
		} catch (error: any) {
			showToast('error');
		}
	};

	const handleEventSave = (eventData: EventData) => {
		let isNew: boolean = true;

		// Permissions and create/update checks
		if (eventData.requestId) {
			if (!permissionTo('update')) {
				showToast('permission');
				return;
			}

			isNew = false;
		} else {
			if (!permissionTo('create')) {
				showToast('permission');
				return;
			}

			isNew = true;
		}

		// Mandatory fields checks
		if (eventData.multiDay === true && (!eventData.startDate || !eventData.endDate)) {
			showToast('error', 'Please select a date range when using multiple days');
			return;
		}

		if (eventData.time.some((t: string) => t === undefined || t.trim().length <= 5)) {
			showToast(
				'error',
				`Please ensure ${
					eventData.time.length > 1 ? 'all time fields are' : 'the time field is'
				} provided and valid`
			);
			return;
		}

		if (!eventData.time) {
			showToast('error', 'Please select an event time');
			return;
		}

		if (!eventData.description) {
			showToast('error', 'Please provide a description');
			return;
		}

		if (!eventData.attendees || eventData.attendees.length === 0) {
			showToast('error', 'Please select at least one attendee');
			return;
		}

		if (!eventData.location) {
			showToast('error', 'Please choose an event location');
			return;
		}

		const toastID = toast.loading('Please wait...');
		setIsLoading(true);

		// Close the modal
		setEventModal((prevState: any) => ({ ...prevState, selectedDate: null, isOpen: false }));

		let payload: EventPayload = {
			dates: [],
			event_type: eventData.type!,
			description: eventData.description,
			company_name: eventData.companyName,
			contact_name: eventData.contactName,
			contact_number: eventData.contactNumber,
			worker_ids: eventData.attendees.map((attendee: any) => attendee.value),
			location: {
				address_1: eventData.location.address1,
				address_2: eventData.location.address2,
				town: eventData.location.town,
				postcode: eventData.location.postcode,
				latitude: eventData.location.latitude,
				longitude: eventData.location.longitude,
				boundary_size: eventData.location.boundarySize,
			},
			enable_uploads: eventData.certificate,
		};

		// Calculate the dates and times
		let startTime, startDate, endTime, endDate;
		if (eventData.multiDay === true && eventData.startDate && eventData.endDate) {
			const days = getDaysArray(eventData.startDate.toJSDate(), eventData.endDate.toJSDate());

			days.forEach((theDate: Date, i: number) => {
				startTime = eventData.time[i].split(' - ')[0].split(':');
				startDate = DateTime.fromJSDate(theDate)
					.set({
						hour: Number(startTime[0]),
						minute: Number(startTime[1]),
					})
					.toISO();
				endTime = eventData.time[i].split(' - ')[1].split(':');
				endDate = DateTime.fromJSDate(theDate)
					.set({
						hour: Number(endTime[0]),
						minute: Number(endTime[1]),
					})
					.toISO();

				payload.dates.push({ start: startDate, end: endDate });
			});
		} else {
			startTime = eventData.time[0].split(' - ')[0].split(':');
			startDate = DateTime.fromJSDate(eventData.selectedDate!)
				.set({
					hour: Number(startTime[0]),
					minute: Number(startTime[1]),
				})
				.toISO();
			endTime = eventData.time[0].split(' - ')[1].split(':');
			endDate = DateTime.fromJSDate(eventData.selectedDate!)
				.set({
					hour: Number(endTime[0]),
					minute: Number(endTime[1]),
				})
				.toISO();

			payload.dates = [{ start: startDate, end: endDate }];
		}

		let url: string = '';
		if (isNew) {
			url = '/api/workers/holidays_and_absences/requests';
		} else {
			url = `/api/workers/holidays_and_absences/requests/${eventData.requestId}`;
		}

		axios
			.put(url, payload)
			.then(() => {
				showToast(
					'success',
					`Your event was successfully ${isNew ? 'created' : 'updated'}`,
					toastID
				);
				if (calInst) loadCalendar(calInst._firstDay, calInst?._lastDay);
			})
			.catch(() => {
				showToast('error', null, toastID);
			})
			.finally(() => {
				setIsLoading(false);

				// Reset the modal
				setEventModal({ isOpen: false });
				setEventModalData(eventDataDefault);
			});
	};

	// Calendar event handlers
	const handleOnCalInit = useCallback((e: MbscCellClickEvent, inst: Eventcalendar) => {
		setCalInst(inst);
	}, []);

	const handleOnCalPageLoading = useCallback((e: MbscPageChangeEvent, inst: Eventcalendar) => {
		// Set a timeout on the first load to avoid the component update warning
		setTimeout(
			() => {
				loadCalendar(inst._firstDay, inst._lastDay);
			},
			firstPageLoaded ? 0 : 200
		);
	}, []);

	const handleOnCalPageLoaded = useCallback(() => {
		setFirstPageLoaded(true);
	}, []);

	const handleOnCalEventClick = useCallback((e: MbscEventClickEvent) => {
		// Stop the cell click event from firing
		e.domEvent.stopPropagation();

		setEventModalData({
			...eventDataDefault,
			requestId: String(e.event.id),
			selectedDate: e.date,
			description: String(e.event.title),
		});
	}, []);

	const handleOnCalEventRightClick = useCallback((e: MbscEventClickEvent) => {
		e.domEvent.preventDefault();

		if (!permissionTo('delete')) return false;

		setMenuAnchor(e.domEvent.target);
		setRightClickedEvent(e.event);
		setTimeout(() => {
			setMenuOpen(true);
		});
	}, []);

	const handleOnCalCellClick = useCallback((e: MbscCellClickEvent) => {
		// Look for label class (e.g. clicked '2 more' button) and stop the modal from opening if found
		const lbl = e.domEvent?.target as HTMLElement;
		const labelClicked: boolean = lbl && lbl.classList.contains('mbsc-calendar-label-text');
		if (!labelClicked) setEventModalData({ ...eventDataDefault, selectedDate: e.date });
	}, []);

	const handleOnCalEventDragEnd = useCallback((e: any, inst: Eventcalendar) => {
		const start: DateTime = DateTime.fromJSDate(e.event.start);
		const end: DateTime = DateTime.fromJSDate(e.event.end);

		// Cancel drop if the event was not moved to a new date
		if (start.toISO() === null || end.toISO() === null) return;

		setIsLoading(true);

		const payload: any = {
			start: start.toISO(),
			end: end.toISO(),
			drag: true,
		};

		axios
			.put(`/api/workers/holidays_and_absences/requests/${e.event.id}`, payload)
			.then(() => {
				showToast('success', 'Your event was successfully updated');
				loadCalendar(inst._firstDay, inst._lastDay);
			})
			.catch(() => {
				showToast('error');
				setIsLoading(false);
			});
	}, []);

	// Context menu functions
	const deleteSelectedEvent = useCallback(() => {
		presentAlert({
			header: 'Delete Event',
			message: 'Are you sure you want to delete this event?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteEvent(rightClickedEvent.id);
					},
				},
			],
		});
	}, [rightClickedEvent]);

	const handleDeleteEvent = (requestId: string) => {
		setIsLoading(true);

		axios
			.delete(`/api/workers/holidays_and_absences/requests/${requestId}`)
			.then(() => {
				setEvents((prevState: any) => prevState.filter((d: any) => d.id !== requestId));
				showToast('deleted');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const selectChange = useCallback(
		(args: any) => {
			setContextMenuValue(args.value);

			if (args.value === 'delete') {
				deleteSelectedEvent();
			}
		},
		[deleteSelectedEvent]
	);

	const menuClose = useCallback(() => {
		setRightClickedEvent(null);
		setContextMenuValue('');
		setMenuOpen(false);
	}, []);

	const customHeader = () => {
		let msg: any = [];
		if (permissionTo('update')) msg.push('drag and drop events');
		if (permissionTo('delete')) msg.push('right-click an event to delete it');
		if (msg.length > 0) msg = `(${msg.join(', ')})`;

		return (
			<IonGrid className='p-0'>
				<IonRow>
					<IonCol size='4' className='p-0'>
						<CalendarNav />
					</IonCol>
					<IonCol size='4' className='p-0 text-center'>
						<p className='pt-2 m-0 small'>{msg}</p>
					</IonCol>
					<IonCol size='4' className='p-0 text-right'>
						<CalendarPrev />
						<CalendarToday />
						<CalendarNext />
					</IonCol>
				</IonRow>
			</IonGrid>
		);
	};

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			<Eventcalendar
				data={events}
				firstDay={1}
				view={view}
				renderHeader={customHeader}
				dragToMove={permissionTo('update')}
				onInit={handleOnCalInit}
				onEventClick={handleOnCalEventClick}
				onEventRightClick={handleOnCalEventRightClick}
				onEventDragEnd={handleOnCalEventDragEnd}
				onCellClick={handleOnCalCellClick}
				onPageLoading={handleOnCalPageLoading}
				onPageLoaded={handleOnCalPageLoaded}
				colors={[...baseColours, ...bankHolidays]}
			/>

			<Select
				inputProps={{ type: 'hidden' }}
				display='anchored'
				dropdown={false}
				touchUi={false}
				anchor={menuAnchor}
				data={contextMenu}
				value={contextMenuValue}
				isOpen={menuOpen}
				onChange={selectChange}
				onClose={menuClose}
			/>

			<EventModal
				isOpen={eventModal.isOpen}
				initialData={eventModalData}
				onClose={() => {
					setEventModal((prevState: any) => ({ ...prevState, selectedDate: null, isOpen: false }));
				}}
				onSave={(eventData: EventData) => {
					handleEventSave(eventData);
				}}
				loadEvent={loadEvent}
			/>
		</>
	);
};

export default TrainingEvents;

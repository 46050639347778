import { useState } from "react";
import { useDispatch } from 'react-redux';
import { setLoginSuccess } from '../../state/slices/appState';
import axios from '../../lib/axios'

const useAuthHandler = (initialState: any) => {
	const [isAuthenticated, setIsAuthenticated] = useState(initialState.isAuthenticated);
	const [user, setUser] = useState(initialState.user);
	const [tenant, setTenant] = useState(initialState.tenant);
	const [navData, setNavData] = useState(initialState.navData);

	const DEFAULT_USER_AUTH = { isAuthenticated: false, navData: [], user: {}, tenant: {} }

	const dispatch = useDispatch();

	const setAuthStatus = (userAuth: any) => {
		setIsAuthenticated(userAuth.isAuthenticated);
		setUser(userAuth.user);
		setTenant(userAuth.tenant);
		setNavData(userAuth.navData);

		// Update the app state
		if (userAuth.isAuthenticated === true) {
			dispatch(setLoginSuccess());
		}
	};

	const setUserData = (userData: any) => {
		setUser(userData);
	}

	const setUnauthStatus = async () => {
		await axios.post('/logout');
		setIsAuthenticated(DEFAULT_USER_AUTH.isAuthenticated);
		setUser(DEFAULT_USER_AUTH.user);
		setTenant(DEFAULT_USER_AUTH.tenant);
		// Do not unset navData here or you lose the ability to navigate
	};

	return {
		isAuthenticated,
		user,
		tenant,
		navData,
		setAuthStatus,
		setUnauthStatus,
		setUserData
	};
};

export default useAuthHandler;

import { createContext } from "react";

import useDateHandler from '../utils/custom-hooks/DateHandler';

export const dateContext = createContext({});

const { Provider } = dateContext;

const DateProvider = ({ children, ...props }: any) => {
	const { date, setDate } =
		useDateHandler();

	return (
		<Provider value={{ date, setDate }}>
			{children}
		</Provider>
	);
};

export default DateProvider;
import { Alert } from '@mui/material';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { TimesheetsContext } from '../../TimesheetsProvider';
import { IonButton } from '@ionic/react';
import { DateTime } from 'luxon';
import { ActionType } from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBackwardStep,
	faClockRotateLeft,
	faForwardStep,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { myTeamOptions } from '../../timesheets-constants';
import { getYearSelectOptions } from '../../../../lib/functions';

type Props = {
	mode: 'main' | 'modal';
	approvesAllTimesheets: boolean;
	curMonth: number;
	curYear: number;
	modalData?: any;
	setCurMonth: Function;
	setCurYear: Function;
};

const WorkerReportHeader = forwardRef<Props, any>((props: Props, ref: any) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const [title, setTitle] = useState<string>('');
	const [headerDateString, setHeaderDateString] = useState<string>('');
	const [btnPrevDisabled, setBtnPrevDisabled] = useState<boolean>(true);
	const [btnNextDisabled, setBtnNextDisabled] = useState<boolean>(true);
	const [teamOptionsDisabled, setTeamOptionsDisabled] = useState<boolean>(true);
	const minYear: number = Math.min(
		...getYearSelectOptions({}).map((item: any) => Number(item.value))
	);
	const maxYear: number = Math.max(
		...getYearSelectOptions({}).map((item: any) => Number(item.value))
	);

	useImperativeHandle(ref, () => ({
		setHeaderTitle(value: string) {
			setTitle(value);
		},
	}));

	useEffect(() => {
		setTeamOptionsDisabled(!props.approvesAllTimesheets);
	}, [props.approvesAllTimesheets]);

	useEffect(() => {
		// Header date
		setHeaderDateString(() => {
			let tmp: string = '';

			switch (props.curMonth) {
				case 13:
					tmp = 'Annual - ' + props.curYear;
					break;
				default:
					tmp = DateTime.fromFormat(`${props.curMonth}-${props.curYear}`, 'M-yyyy').toFormat(
						'LLLL yyyy'
					);
					break;
			}

			// Additional titling
			if (props.mode === 'modal' && props.modalData)
				tmp += ` - ${props.modalData.worker.user.first_name} ${props.modalData.worker.user.last_name}`;

			return tmp;
		});

		// Enable or disable the month prev/next buttons
		setBtnPrevDisabled((props.curMonth === 1 && props.curYear === minYear) || props.curMonth > 12);
		setBtnNextDisabled((props.curMonth === 12 && props.curYear === maxYear) || props.curMonth > 12);
	}, [props.curMonth, props.curYear]);

	const handleMonthButtonClick = (action: 'prev' | 'next') => {
		props.setCurMonth((prevMonth: number) => {
			let newMonth: number = -1;

			switch (action) {
				case 'prev':
					newMonth = prevMonth - 1;
					if (newMonth < 1) {
						newMonth = 12;
						props.setCurYear((prevYear: number) => prevYear - 1);
					}
					break;
				case 'next':
					newMonth = prevMonth + 1;
					if (newMonth > 12) {
						newMonth = 1;
						props.setCurYear((prevYear: number) => prevYear + 1);
					}
					break;
			}

			return newMonth;
		});
	};

	const handleResetDate = () => {
		props.setCurMonth(DateTime.now().month);
		props.setCurYear(DateTime.now().year);
	};

	const handleMyTeamChange = (sel: any) => {
		if (sel.value !== state.showMyTeam) {
			dispatch({
				type: ActionType.SET_SHOW_MY_TEAM,
				payload: sel.value,
			});
		}
	};

	return (
		<div ref={ref} className='timesheets-header-container'>
			<Alert severity='info' className='m-0 mb-2'>
				{title}: <strong>{headerDateString}</strong>
			</Alert>
			<div className='timesheets-header-controls-container'>
				<IonButton onClick={() => handleMonthButtonClick('prev')} disabled={btnPrevDisabled}>
					<FontAwesomeIcon icon={faBackwardStep} className='pe-2' />
					Previous Month
				</IonButton>

				<div className='timesheet-header-controls'>
					{props.mode === 'main' && (
						<Select
							options={myTeamOptions}
							value={myTeamOptions.find((item: any) => item.value === state.showMyTeam)}
							onChange={(sel: any) => handleMyTeamChange(sel)}
							className='sel-my-team'
							isSearchable={false}
							isDisabled={teamOptionsDisabled}
						/>
					)}
					<IonButton
						onClick={handleResetDate}
						color='secondary'
						title='Reset to current month'
						disabled={`${props.curMonth}-${props.curYear}` === DateTime.now().toFormat('M-yyyy')}
					>
						<FontAwesomeIcon icon={faClockRotateLeft} />
					</IonButton>
				</div>

				<IonButton onClick={() => handleMonthButtonClick('next')} disabled={btnNextDisabled}>
					Next Month
					<FontAwesomeIcon icon={faForwardStep} className='ps-2' />
				</IonButton>
			</div>
		</div>
	);
});

export default WorkerReportHeader;

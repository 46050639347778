import { useEffect, useState } from 'react';
import { IonCard } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';

const AuditLogIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const [data, setData] = useState<Array<any>>([]);

	const columns = [
		{
			field: 'name',
			filter: 'agTextColumnFilter',
			searchable: true,
			resizable: true,
			width: 300,
		},
		{
			field: 'time',
			filter: 'agDateColumnFilter',
			searchable: true,
			resizable: true,
			width: 120,
			sort: 'desc',
		},
		{
			field: 'date',
			filter: 'agDateColumnFilter',
			searchable: true,
			resizable: true,
			width: 120,
			sort: 'desc',
		},
		{
			field: 'event',
			filter: 'agTextColumnFilter',
			searchable: true,
			resizable: true,
			maxWidth: 200,
		},
		{
			field: 'changes',
			filter: 'agTextColumnFilter',
			searchable: true,
			resizable: true,
			flex: 1,
		},
	];

	useEffect(() => {
		axios.get('/api/utilities/general/audit').then((d: any) => {
			setData(d.data);
		});
	}, []);

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={'General - Audit Log'} />
				<IonCard className='table-card full-height-card'>
					<DataGrid
						title={'Audit Log'}
						cols={columns}
						autoSize={false}
						data={data}
						exportButton={true}
					/>
				</IonCard>
			</div>
		</>
	);
};

export default AuditLogIndex;

import { faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
	IonChip,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { TimesheetsContext } from '../../TimesheetsProvider';
import Loading from '../../../../components/UI/Loading';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { getTimezone } from '../../../../lib/functions';
import WorkerReportHeader from '../components/WorkerReportHeader';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import { convertSecondsToDurationString } from '../../../../helpers/times';

type Props = {
	isOpen: boolean;
	modalData: any;
	onClose: Function;
	permissionTo: Function;
};

const WorkerExceptionsReportModal: React.FC<Props> = (props) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [curMonth, setCurMonth] = useState<number>(-1);
	const [curYear, setCurYear] = useState<number>(-1);
	const [aggregateScore, setAggregateScore] = useState<number>(0);
	const headerRef: any = useRef<any>();
	const gridRef: any = useRef<any>();
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<any>>([]);
	const gridColumns: Array<any> = [
		{ field: 'date', headerName: 'Date', valueFormatter: dateFormatter, flex: 1 },
		{ field: 'worker_name', headerName: 'Worker Name', flex: 1 },
		{ field: 'location', headerName: 'Location', flex: 1 },
		{
			field: 'late_start',
			headerName: 'Late Starts > 10 Mins.',
			type: 'rightAligned',
			valueFormatter: (params: any) => String(params.value),
			flex: 1,
		},
		{
			field: 'early_leave',
			headerName: 'Early Finishes > 10 Mins.',
			type: 'rightAligned',
			valueFormatter: (params: any) => String(params.value),
			flex: 1,
		},
		{
			field: 'failed_to_record',
			headerName: 'Failed to Record',
			type: 'rightAligned',
			valueFormatter: (params: any) => String(params.value),
			flex: 1,
		},
		{
			field: 'early_start',
			headerName: 'Early Starts > 10 Mins.',
			type: 'rightAligned',
			valueFormatter: (params: any) => String(params.value),
			flex: 1,
		},
		{
			field: 'late_leave',
			headerName: 'Late Finishes > 10 Mins.',
			type: 'rightAligned',
			valueFormatter: (params: any) => String(params.value),
			flex: 1,
		},
		{
			field: 'aggregate_mins',
			headerName: 'Aggregate Mins.',
			type: 'rightAligned',
			cellRenderer: (params: any) => convertSecondsToDurationString(params.value, 'hh:mm:ss'),
			flex: 1,
		},
	];

	useEffect(() => {
		setIsLoading(gridLoading);
	}, [gridLoading]);

	useEffect(() => {
		if (props.isOpen) {
			if (props.modalData && props.modalData.worker) {
				setModalTitle(
					`Exception Details for ${props.modalData.worker.user.first_name} ${props.modalData.worker.user.last_name}`
				);
			}

			// Setup the local state with the main state values
			setCurMonth(state.workerReportMonth);
			setCurYear(state.workerReportYear);
		}
	}, [props.isOpen, props.modalData]);

	useEffect(() => {
		if (props.isOpen && curMonth > 0 && curYear > 0) loadGridData(curMonth, curYear);
	}, [props.isOpen, curMonth, curYear]);

	const loadGridData = (month: number, year: number) => {
		setGridLoading(true);
		setAggregateScore(0);
		setData([]);

		let payload: any = {
			month,
			year,
			...getTimezone(),
			my_team: state.showMyTeam,
			worker_id: props.modalData.workerId,
		};

		axios
			.post('/api/timesheets/timesheets_and_allocations/exceptions_salaried', payload)
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);
				setAggregateScore(
					res.data.reduce((acc: any, val: any) => (acc = acc + val.aggregate_mins), 0)
				);
				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleOnDidPresent = () => {
		if (headerRef.current) headerRef.current.setHeaderTitle('Worker Exceptions Report - Details');
	};

	const onClose = () => {
		// Reset
		setModalTitle('');
		setData([]);
		setIsLoading(false);
		setGridLoading(false);
		setCurMonth(-1);
		setCurYear(-1);

		props.onClose();
	};

	return (
		<>
			<IonModal
				class='timesheets-worker-exceptions-report-modal'
				style={{ '--width': '90vw', '--height': '90vh' }}
				isOpen={props.isOpen}
				onDidPresent={handleOnDidPresent}
				onWillDismiss={() => onClose()}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{modalTitle}</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton onClick={() => onClose()}>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{isLoading && <Loading overlay={true} />}
					{props.isOpen && (
						<div className='content-container'>
							<div className='header-container'>
								<WorkerReportHeader
									ref={headerRef}
									mode={'modal'}
									approvesAllTimesheets={
										props.modalData.worker && props.modalData.worker.hasOwnProperty('key_details')
											? props.modalData.worker.key_details.approves_all_timesheets
											: false
									}
									curMonth={curMonth}
									curYear={curYear}
									modalData={props.modalData}
									setCurMonth={setCurMonth}
									setCurYear={setCurYear}
								/>
							</div>
							<div className='grid-container'>
								<DataGrid ref={gridRef} cols={gridColumns} data={data} title='' useSearch={false} />
							</div>
						</div>
					)}
				</IonContent>
				<IonFooter>
					<IonToolbar>
						<IonRow>
							<IonCol size='6'>
								<div className='aggregate-score-container'>
									Aggregate score:
									<IonChip
										className={`ion-chip-${
											gridLoading
												? 'grey'
												: convertSecondsToDurationString(aggregateScore, 'hh:mm:ss').indexOf('-') >=
												  0
												? 'danger'
												: 'success'
										}`}
									>
										<FontAwesomeIcon icon={faClock} className='pe-1' />
										{convertSecondsToDurationString(aggregateScore, 'hh:mm:ss')}
									</IonChip>
								</div>
							</IonCol>
							<IonCol size='6' className='text-right'>
								<IonButton color='secondary' onClick={() => onClose()}>
									Close
								</IonButton>
							</IonCol>
						</IonRow>
					</IonToolbar>
				</IonFooter>
			</IonModal>
		</>
	);
};

export default WorkerExceptionsReportModal;

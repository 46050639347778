import { CSSProperties, useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';

import './RadioButtons.scss';

interface Props {
	options: Array<any>;
	value: Array<any>;
	onChange: Function;
	multiple?: boolean;
	style?: CSSProperties;
}

const RadioButtons = (props: Props) => {
	const [selected, setSelected] = useState<Array<any>>(props.value);
	const [multiple, setMultiple] = useState<boolean>(props.multiple ?? false);

	useEffect(() => {
		setSelected(props.value);
	}, [props.value]);

	useEffect(() => {
		setMultiple(props.multiple ?? false);
	}, [props.multiple]);

	return (
		<div className='radio-buttons' style={props.style}>
			{props.options.map((option: any, index: number) => {
				return (
					<IonButton
						key={index}
						className={selected.indexOf(option.value) > -1 ? 'active' : ''}
						onClick={() => {
							setSelected((prevValue) => {
								if (multiple) {
									if (prevValue.indexOf(option.value) > -1) {
										prevValue.splice(option.value, 1);
									} else {
										prevValue.push(option.value);
									}
								} else {
									prevValue = [option.value];
								}
								props.onChange(prevValue);
								return prevValue;
							});
						}}
					>
						{option.label}
					</IonButton>
				);
			})}
		</div>
	);
};

export default RadioButtons;

import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { authContext } from '../../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import {
	EventType,
	RequestStatus,
} from '../../Workers/HolidayAbsenceTraining/Requests/request-types';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const RequestsApproval: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const [loading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<Array<any>>([]);
	const [dataCount, setDataCount] = useState<Number>(0);
	const permissionTo = usePermissionTo('workers.holidays_and_absences.requests');
	let history = useHistory();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		setLoading(true);
		setData([]);

		const payload: any = {
			event_types: [EventType.HOLIDAY, EventType.APPROVED_ABSENCE],
			status: [RequestStatus.REQUESTED],
			dashboard: true,
			approver_id: authCtx.user.worker_id,
		};

		axios
			.post('/api/workers/holidays_and_absences/requests', payload)
			.then((res: any) => {
				setDataCount(res.data.length);

				// Gather unique names
				let uniqueNames: Array<string> = [];
				res.data.map((worker: any) => {
					const workerName: string = worker.attendees[0].label;
					if (uniqueNames.indexOf(workerName) === -1) uniqueNames.push(workerName);
				});

				// Sort and set the names data
				setData(
					uniqueNames.sort((a: any, b: any) => {
						const nameA: Array<string> = a.split(' ');
						const nameB: Array<string> = b.split(' ');
						return nameA[nameA.length - 1] > nameB[nameB.length - 1] ? 1 : -1;
					})
				);
			})
			.catch(() => {
				showToast(
					'error',
					`There was a problem with the My Holidays and Approved Absences to Approve widget`
				);
			})
			.finally(() => setLoading(false));
	};

	const handleGoToApprovals = () => {
		history.push(`/workers/holidays_and_absences?approver=${authCtx.user.worker_id}#requests`);
	};

	return (
		<>
			<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
				<div className='widget-header'>My Holidays and Approved Absences to Approve</div>
				<div className='widget-content'>
					<div className='left'>
						<div className='widget-plaque'>
							<div className='title'>Total</div>
							<div className='stat'>{loading ? '-' : String(dataCount)}</div>
						</div>
					</div>
					<div className='right'>
						<IonList className='widget-list' lines={dataCount === 0 || loading ? 'none' : 'full'}>
							{loading && (
								<IonItem>
									<IonLabel className='text-muted'>Loading, please wait...</IonLabel>
								</IonItem>
							)}
							{dataCount === 0 && !loading && (
								<IonItem>
									<IonLabel>
										<FontAwesomeIcon icon={faCircleCheck} />
										Nothing to approve
									</IonLabel>
								</IonItem>
							)}
							{data.map((workerName: any, i: number) => (
								<IonItem key={i}>
									<IonLabel>{workerName}</IonLabel>
								</IonItem>
							))}
						</IonList>
					</div>
				</div>
				<div className='widget-footer justify-content-end'>
					{permissionTo('read') && (
						<IonButton size='small' onClick={handleGoToApprovals}>
							Go To Approvals
						</IonButton>
					)}
				</div>
			</div>
		</>
	);
};

export default RequestsApproval;

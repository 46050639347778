import debounce from 'lodash.debounce';
import axios from '../../lib/axios';

const ClientsOptions = debounce((inputValue: any, callback: any) => {
	axios({
		method: 'post',
		url: '/api/jobs/clients_list',
		data: {
			name: inputValue
		}
	}).then((res) => {
		const options = res.data.map((r: any) => {
			const name = r.first_name + ' ' + r.last_name;
			const label: string = r.company_name ? r.company_name + ' - ' + name : name;
			return { label, value: r.id }
		});
		callback(options);
	});
}, 600);

export default ClientsOptions;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { useRef, useState } from 'react';
import Loading from '../../../../../components/UI/Loading';
import SelectStyled from '../../../../../components/UI/SelectStyled';
import { showToast } from '../../../../../lib/toast';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import axios from '../../../../../lib/axios';

type Props = {
	isOpen: boolean;
	modalData: any;
	onClose: Function;
	permissionTo: Function;
	data: any;
	setData: Function;
	sortTeam: Function;
	updateTeamSelect: Function;
};

const AddTeamMemberModal: React.FC<Props> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const teamSelectRef = useRef<any>(null);
	const [teamSelect, setTeamSelect] = useState<any>(null);

	const handleTeamSelect = (newValue: { label: string; value: string }) => {
		setTeamSelect(newValue);
	};

	const handleAddTeamMember = () => {
		const toastID = toast.loading('Please wait...');
		setIsLoading(true);

		// Add selected team member
		const newMember: any = teamSelectRef.current.getValue()[0];
		let newData = cloneDeep(props.data);

		// Get default team members (to add back to the data array)
		const defaultMembers = newData.filter((worker: any) => worker.role !== 'Additional');

		// Create a new data array of only additional team members
		newData = newData.filter((worker: any) => worker.role === 'Additional');
		newData.push({ id: newMember.value, name: newMember.label, role: 'Additional' });

		// Sort
		newData = props.sortTeam(newData);

		// Send just the additional-type team members back
		const payload: any = {
			team: newData,
		};

		// Full grid data with new team member
		const finalGridData = defaultMembers.concat(newData);

		axios
			.put(`/api/jobs/jobs_list/job_card/${props.modalData.jobId}`, payload)
			.then(() => {
				props.updateTeamSelect(newData);
				props.setData(finalGridData);

				// Reset selection
				teamSelectRef.current.setValue(null);

				showToast('saved', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			})
			.finally(() => {
				setIsLoading(false);

				// Close the modal
				onClose();
			});
	};

	const onClose = () => {
		// Reset
		setIsLoading(false);
		setTeamSelect(null);

		props.onClose();
	};

	return (
		<>
			<IonModal
				class='add-team-member-modal'
				style={{ '--width': '600px', '--height': '240px' }}
				isOpen={props.isOpen}
				onWillDismiss={() => onClose()}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Add Team Member</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton onClick={() => onClose()}>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{isLoading && <Loading overlay={true} />}
					{props.isOpen && (
						<SelectStyled
							forwardRef={teamSelectRef}
							options={props.modalData.workersOptions}
							onChange={handleTeamSelect}
						/>
					)}
				</IonContent>
				<IonFooter>
					<IonToolbar>
						<IonRow>
							<IonCol size='12' className='text-right'>
								<IonButton onClick={handleAddTeamMember} disabled={!teamSelect}>
									Add Team Member
								</IonButton>
								<IonButton color='secondary' onClick={onClose}>
									Close
								</IonButton>
							</IonCol>
						</IonRow>
					</IonToolbar>
				</IonFooter>
			</IonModal>
		</>
	);
};

export default AddTeamMemberModal;

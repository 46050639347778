import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
} from '@ionic/react';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import VATRates from './VATRates';

interface EditRatesModalProps {
	isOpen: boolean;
	registerId: string;
	registerName: string;
	onClose: Function;
	onSuccess: Function;
	onAnimationEnd: Function;
}

const EditRatesModal: React.FC<EditRatesModalProps> = ({
	isOpen,
	registerId,
	registerName,
	onClose,
	onSuccess,
	onAnimationEnd,
}) => {
	const uid = 'utilities.vat_rates';

	return (
		<IonModal
			style={{ '--width': '80%', '--height': '500px' }}
			isOpen={isOpen}
			onDidDismiss={() => onClose()}
			onAnimationEnd={() => onAnimationEnd()}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Edit Rates for {registerName}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<VATRates
					uid={uid}
					routeTitle=''
					permissionTo={usePermissionTo(uid)}
					usageMode='modal'
					usageModeCompletion={(autoClose: boolean) => {
						onSuccess();
						if (autoClose) onClose();
					}}
					registerId={registerId}
				/>
			</IonContent>
		</IonModal>
	);
};

export default EditRatesModal;

import { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonRow, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColDef } from '@ag-grid-community/core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

import { isset } from '../../../../helpers/typeFunctions';
import DataGrid from '../../../DataGrid/DataGrid';
import { listReducer } from '../../../../helpers/listReducer';
import axios from '../../../../lib/axios';
import { Dropdown, NumberField } from '../../../Forms/FormFields';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { JobSkillsOptions } from '../../../../interfaces/Utilities/JobSkills';
import Job from '../../../../interfaces/Jobs/Job';
import { authContext } from '../../../../contexts/AuthContext';

interface Props {
	date?: DateTime;
	minHours?: number;
	onClose: Function;
}

const TimesheetList: React.FC<Props> = (props: Props) => {

	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const gridRef: any = useRef<any>();
	const [presentAlert] = useIonAlert();
	const [jobs, setJobs] = useState<Array<any>>([]);
	const [jobSkills, setJobSkills] = useState<Array<any>>([]);
	const [percentageTotal, setPercentageTotal] = useState<number>(0);
	const [hideBanner, setHideBanner] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [newValue, setNewValue] = useState<any>({});
	const [logData, dispatchLogData] = useReducer(listReducer, {
		list: [],
		originalList: [],
		isShowList: true,
	});
	const colData: Array<ColDef> = [
		{
			headerName: 'Job',
			field: 'job_name',
			filter: true,
			flex: 1,
		},
		{
			headerName: 'Job Skill',
			field: 'job_skill_name',
			filter: true,
			flex: 1,
			cellRenderer: (params: any) => {
				if (params.data.job_name === 'Total') {
					return ('');
				} else {
					return (
						<Dropdown
							title={'Job Skill'}
							values={jobSkills}
							defaultValue={jobSkills.filter((js) => js.value === params.data.job_skill_id)}
							handleUpdateSelect={(value: JobSkillsOptions) => {
								let data = params.data;
								data.job_skill_id = value.value;
								data.job_skill_name = value.label;
								dispatchLogData({
									type: 'update',
									data
								});
							}}
							editMode={true}
							noForm={true}
							useLabels={false}
						/>
					);
				}
			},
		},
		{
			headerName: 'Percentage',
			field: 'percentage',
			filter: true,
			width: 175,
			cellRenderer: (params: any) => {
				if (params.data.job_name === 'Total') {
					return (<div style={{ color: percentageTotal !== 100 ? 'var(--ion-color-danger)' : 'var(--ion-color-success)' }} >{params.data.percentage + '%'}</div>);
				} else {
					return (
						<NumberField
							title={'Percentage'}
							value={params.data.percentage ?? ''}
							editMode={true}
							noForm={true}
							onChangeCallback={(value: any) => {
								let data = params.data;
								data.percentage = value;
								dispatchLogData({
									type: 'update',
									data
								});
							}}
							useLabels={false}
						/>
					);
				}
			},
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					params.getValue() !== false && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => deleteRow(params.data._id)}
						/>
					)
				);
			},
		},
	];

	useEffect(() => {
		loadJobs();
		loadJobSkills();
	}, []);

	const loadJobs = () => {
		axios
			.post(
				'/api/workers/workers_list/worker_card/jobs/' + authCtx.user.worker_id,
				{}
			)
			.then((res: any) => {
				setJobs(res.data.map((option: Job) => {
					return {
						label: option.name,
						value: option.id,
					};
				}));
			})
			.catch((e: any) => {
				console.log(e);
			});

	};

	const loadJobSkills = () => {
		moduleCtx
			.getJobSkillsOptions()
			.then((res: JobSkillsOptions[]) => {
				setJobSkills(res);
			});
	};

	useEffect(() => {
		getTimesheet();
	}, [props.date])

	useEffect(() => {
		if (loading) {
			gridRef.current.showLoading();
		} else {
			gridRef.current.hideLoading();
		}
	}, [loading])

	const getTimesheet = () => {
		setLoading(true);
		return new Promise((res, rej) => {
			if (isset(props.date)) {
				let month = props.date ? props.date.toFormat('LL') : false;
				let year = props.date ? props.date.toFormat('yyyy') : false;
				axios
					.get(
						'/api/workers/allocations/' + month + '/' + year,
					)
					.then((data: any) => {
						dispatchLogData({
							type: 'replace',
							data: data.data
						});
						setLoading(false);
						res(true);
					})
					.catch((e: any) => {
						console.log(e);
					});
			}
		});
	};

	function round(value: number, precision: number) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	const submitForm = () => {
		presentAlert({
			header: 'Submit Timesheet',
			message: 'Are you sure you want to submit this timesheet? This cannot be undone.',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						props.onClose(gridRef ? gridRef.current.getData() : []);
					},
				},
			],
		});
	};

	const deleteRow = (id: any) => {
		dispatchLogData({
			type: 'remove',
			data: id
		});
	}

	const addRow = useCallback(() => {
		setLoading(true);
		let result = logData.list.find((item: any) => {
			return item.job_skill_id === newValue.job_skill_id && item.job_id === newValue.job_id && item._id !== newValue._id;
		});
		if (isset(result)) {
			setLoading(false);
			presentAlert({
				header: 'Already Added',
				message: 'This combination of job and job skill has already been added.',
			});
		} else {
			dispatchLogData({
				type: 'add',
				data: newValue
			});
			setTimeout(() => {
				setLoading(false);
				setNewValue((prevValue: any) => {
					let newValue = { ...prevValue };
					delete newValue._id;
					return newValue;
				});
			}, 0)
		}
	}, [newValue]);

	const totalRow = function (items: any) {
		let result: any = [{}];

		let amount = 0;
		items.forEach((item: any) => {
			amount += Number(item.percentage);
		});

		setPercentageTotal(round(amount, 2));
		// Clean-up row output
		result[0]['job_name'] = 'Total';
		result[0]['percentage'] = round(amount, 2);
		result[0]['delete'] = false;

		return result;
	};

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{props.date ? props.date.toFormat('LLLL yyyy') : 'Month Failed'}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='flex-grow-container' style={{ height: '100%' }}>
					{!hideBanner ? (
						<Alert severity='info' style={{ margin: '0 0 8px 0' }} onClose={() => { setHideBanner(true) }}>
							You must complete your monthly allocation of
							time. Your allocated time must total 100%​. You had a total of {props.minHours ? props.minHours : 0} hours available this month.
						</Alert>)
						: null}
					<div style={{ flex: 1 }}>
						<DataGrid
							totalsRow={totalRow}
							ref={gridRef}
							cols={colData}
							data={logData.list}
							title=''
							useSearch={false}
							extraFooter={
								<IonRow>
									<IonCol size={'4'}>
										<Dropdown
											id='add-job'
											title={'Job'}
											values={jobs}
											defaultValue={jobs.filter((js) => js.value === newValue.job_id)}
											handleUpdateSelect={(value: any) => {
												setNewValue((prevValue: any) => {
													let newValue = { ...prevValue };
													if (!isset(newValue._id)) {
														newValue._id = 'tmp_' + DateTime.now().toMillis();
													}
													newValue.job_id = value.value;
													newValue.job_name = value.label;
													return newValue;
												});
											}}
											editMode={true}
											noForm={true}
											useLabels={true}
										/>
									</IonCol>
									<IonCol size={'3'}>
										<Dropdown
											id='add-job-skill'
											title={'Job Skill'}
											values={jobSkills}
											defaultValue={jobSkills.filter((js) => js.value === newValue.job_skill_id)}
											handleUpdateSelect={(value: JobSkillsOptions) => {
												setNewValue((prevValue: any) => {
													let newValue = { ...prevValue };
													if (!isset(newValue._id)) {
														newValue._id = 'tmp_' + DateTime.now().toMillis();
													}
													newValue.job_skill_id = value.value;
													newValue.job_skill_name = value.label;
													return newValue;
												});
											}}
											editMode={true}
											noForm={true}
											useLabels={true}
										/>
									</IonCol>
									<IonCol size={'3'}>
										<NumberField
											id='add-percentage'
											title={'Percentage'}
											value={newValue.percentage ?? ''}
											editMode={true}
											noForm={true}
											onChangeCallback={(value: any) => {
												setNewValue((prevValue: any) => {
													let newValue = { ...prevValue };
													if (!isset(newValue._id)) {
														newValue._id = 'tmp_' + DateTime.now().toMillis();
													}
													newValue.percentage = value;
													return newValue;
												});
											}}
											useLabels={true}
										/>
									</IonCol>
									<IonCol size={'2'} className='text-right pe-0'>
										<IonButton color='success' onClick={() => addRow()} disabled={loading}>
											Create
										</IonButton>
									</IonCol>
								</IonRow>
							}
						/>
					</div>
				</div>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => submitForm()} disabled={
								loading || percentageTotal != 100
							}>
								Submit
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</>
	);
};

export default TimesheetList;

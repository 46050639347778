import { NavLink } from 'react-router-dom';
import { IonRow, IonCol, IonCard } from '@ionic/react';
import TitleBar from '../../components/TitleBar/TitleBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFileImport,
	faBook,
	faCalendarWeek,
	faClock,
	faListCheck,
} from '@fortawesome/free-solid-svg-icons';
import usePermissionTo from '../../utils/custom-hooks/PermissionTo';
import './Timesheets.scss';

const TimesheetsIndex = () => {
	const permissions: any = {
		timesheets_and_allocations: usePermissionTo('timesheets.timesheets_and_allocations'),
		reports_and_exports: usePermissionTo('timesheets.reports_and_exports'),
	};

	return (
		<>
			<TitleBar title='Timesheets' />
			<IonRow className='module-panels'>
				{permissions.timesheets_and_allocations('read') && (
					<IonCol size={'6'}>
						<NavLink to='/timesheets/timesheets_and_allocations'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Timesheets and Time Allocations</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faClock} />
										Review hourly paid staff timesheets
									</li>
									<li>
										<FontAwesomeIcon icon={faCalendarWeek} />
										Review weekly time allocations
									</li>
									<li>
										<FontAwesomeIcon icon={faListCheck} />
										Approve timesheets
									</li>
									<li>
										<FontAwesomeIcon icon={faBook} />
										View exception log
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
				{permissions.reports_and_exports('read') && (
					<IonCol size={'6'}>
						<NavLink to='/timesheets/reports_and_exports'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Reports and Exports</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faFileImport} />
										Import files for accounts packages
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
			</IonRow>
		</>
	);
};

export default TimesheetsIndex;

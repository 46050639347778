import { createContext, useState, useEffect } from 'react';
import Loading from '../components/UI/Loading';
import Job from '../interfaces/Jobs/Job';
import axios from '../lib/axios';

interface IJobsContext {
	jobId: string;
	jobData: Job | undefined;
	reloadJob: () => void;
}

const defaultState = {
	jobId: '',
	jobData: undefined,
	reloadJob: () => {},
};

export const JobsContext = createContext<IJobsContext>(defaultState);

export default JobsContext;

type JobsProviderProps = {
	children: React.ReactNode;
	jobId: string;
};

export const JobsProvider = ({ children, jobId }: JobsProviderProps) => {
	const [jobData, setJobData] = useState<Job | undefined>(defaultState.jobData);

	const loadJob = async () => {
		return axios
			.get<Job>(`/api/jobs/jobs_list/job_card/${jobId}`)
			.then((res) => res.data)
			.then((res) => setJobData(res))
			.catch((err) => console.error(err));
	};

	const reloadJob = async () => {
		await loadJob();
	};

	useEffect(() => {
		loadJob();
	}, []);

	return (
		<JobsContext.Provider
			value={{
				jobId,
				jobData,
				reloadJob,
			}}
		>
			{jobData !== undefined ? children : <Loading />}
		</JobsContext.Provider>
	);
};

export interface EstimateState {
	estimateData: EstimateData | null;
	items: Array<EstimateItem>;
	isLoading: boolean;
	totals: EstimateTotals;
	totalsLoading: Boolean;
}

export interface EstimateData {
	estimateId?: string | undefined;
	jobId?: string | undefined;
	status: string;
	issueDate: string;
	expiryDate: string;
	reference: string;
	number: string;
	revision: number;
	name?: string;
	estimateNumber: string;
	clientDetails: string;
	amountsAre: any;
	showTitleSummary: boolean;
	title?: string;
	summary?: string;
	issuedByName: string;
	vatRegistered: Boolean;
}

export interface EstimateItem {
	_uid: string;
	id?: string;
	item: string;
	description: string;
	quantity: number;
	price: number;
	discount: number;
	taxRateId: string;
	amount: number | string;
}

export interface EstimateTotals {
	vat: Array<any>;
	subtotal: number;
	discount: number;
	total: number;
}

export enum EstimateStatus {
	DRAFT = 'draft',
	ISSUED = 'issued',
	APPROVED = 'approved',
	REFUSED = 'refused',
	COMPLETED = 'completed',
}

import Axios from "axios"

const axiosMP = Axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		Accept: 'multipart/form-data',
		'Content-Type': 'multipart/form-data',
	},
	withCredentials: true,
});

export default axiosMP;

import { useState } from 'react';
import { IonCard } from '@ionic/react';
import { useHistory } from 'react-router';
import Subnav from '../../../components/Menu/Subnav';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Loading from '../../../components/UI/Loading';
import NewWorkerTabsArray from './NewWorkerTabsArray';
import { processForm } from '../../../api/forms';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { mandatoryFieldChecks } from '../../../components/Forms/FormValidation';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';

const AddWorker: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	let history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);

	const saveForLaterFunction = (e: any, formData: any, staffPhoto: any) => {
		saveForm(formData, staffPhoto, 'later');
	}

	const nextFunction = (e: any, formData: any, staffPhoto: any) => {
		saveForm(formData, staffPhoto, 'next');
	}

	const saveForm = (formData: any, staffPhoto: any, action: string) => {
		// Check if any of the form's fields are mandatory and stop the form they're empty
		if (mandatoryFieldsEmpty(formData) === true) return;

		// Strip the form data down to DB data fields only
		const fd: any = processForm(formData);

		setLoading(true);

		// Attach the staff photo if set
		if (staffPhoto && staffPhoto.length > 0) {
			fd.worker_photo = staffPhoto[0];
		}

		axios.post(
			"/api/workers/workers_list/worker_card",
			fd,
			{
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			}
		).then((res: any) => {
			switch (action) {
				case 'later':
					history.replace("/workers/workers_list");
					break;
				case 'next':
					history.replace(`/workers/workers_list/worker_card/${res.data._id}?tab=payroll_details`);
					break;
			}
		})
			.catch((err: any) => {
				setLoading(false);
				showToast("error", err.response.data.message);
			});
	}

	function mandatoryFieldsEmpty(items: any) {
		let hasEmptyFields = false;

		if (items.some((item: any) => item.hasOwnProperty('isMandatory') && item.isMandatory === true)) {
			const checks = mandatoryFieldChecks(items);

			if (checks.formValid !== true) {
				showToast("error", checks.errorMessage);
				hasEmptyFields = true;
			}
		}

		return hasEmptyFields;
	}

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className="table-card">
					{loading && <Loading overlay={true} />}
					<Subnav array={NewWorkerTabsArray(permissionTo, saveForLaterFunction, nextFunction)} disabled={true} />
				</IonCard>
			</div>
		</>
	);
};

export default AddWorker;

import { useState, useEffect, useContext } from 'react';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { reportsSubmenuArray } from './reportsSubmenuArray';
import Submenu from '../../../components/Menu/Submenu';
import { reportContext } from './reportContext';
import { moduleContext } from '../../../contexts/ModuleContext';
import Loading from '../../../components/UI/Loading';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';

const ReportsIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { Provider } = reportContext;
	const [submenu, setSubmenu] = useState<Array<any>>([]);
	const moduleCtx = useContext<any>(moduleContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [roles, setRoles] = useState<Array<any>>([]);
	const paymentMethods: Array<any> = [
		{ label: 'Directly Employed: Salaried', value: 'directly_salaried' },
		{ label: 'Directly Employed: Daily/Hourly', value: 'directly_daily_hourly' },
		{ label: 'Self Employed', value: 'self' },
	];

	useEffect(() => {
		setIsLoading(true);
		moduleCtx
			.getSecuritySettingsTemplatesOptions()
			.then((res: any) => {
				setRoles(res);
			})
			.finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		if (!isLoading) {
			let array = reportsSubmenuArray.map((item) => {
				item.active = false;
				return item;
			});
			array[0].active = true;
			setSubmenu(array);
		}
	}, [isLoading]);

	return (
		<div className={`component-${uid.replaceAll('.', '-')}`}>
			{isLoading && <Loading overlay={true} />}
			{!isLoading && submenu.length > 0 && (
				<Provider value={{ roles, paymentMethods }}>
					<TitleBar title={'Workers - Reports'} />
					{submenu.length > 0 && <Submenu array={submenu} />}
				</Provider>
			)}
		</div>
	);
};

export default ReportsIndex;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
	IonLabel,
} from '@ionic/react';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { DateTime } from 'luxon';
import { useContext, useEffect, useRef, useState } from 'react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { getGroupedOptionByValue } from '../../../../lib/functions';
import { WorkersContext } from '../WorkersProvider';

type Props = {
	isOpen: boolean;
	eventData: MbscCalendarEvent;
	locationsOptions: Array<any>;
	createEvent: Function;
	onClose: Function;
};

const WorkCalendarModal: React.FC<Props> = (props) => {
	const { state, dispatch } = useContext(WorkersContext);
	const locationsRef: any = useRef(null);
	const [titleDate, setTitleDate] = useState<string>('');
	const [selectedLocation, setSelectedLocation] = useState<any>(null);

	useEffect(() => {
		if (props.eventData) {
			const dStart: string = DateTime.fromJSDate(props.eventData.event.start).toFormat(
				'ccc dd/MM/yyyy'
			);
			const dEnd: string = DateTime.fromJSDate(props.eventData.event.end).toFormat(
				'ccc dd/MM/yyyy'
			);

			if (dStart === dEnd) {
				setTitleDate(dStart);
			} else {
				setTitleDate(`${dStart} to ${dEnd}`);
			}
		}

		// Initial dropdown setting
		if (props.locationsOptions.length > 0) {
			setSelectedLocation(
				getGroupedOptionByValue(
					props.locationsOptions,
					state.worker?.payroll_details.schedule_default_location
				)[0]
			);
		}
	}, [props.eventData, props.locationsOptions]);

	return (
		<IonModal
			class='work-calendar-modal'
			style={{ '--width': '600px', '--height': '300px' }}
			isOpen={props.isOpen}
			onWillDismiss={() => props.onClose()}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Availability for {titleDate}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => props.onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLabel>Select availability location:</IonLabel>
				<SelectStyled
					className='office-select'
					options={props.locationsOptions}
					onChange={(sel: any) => setSelectedLocation(sel)}
					value={selectedLocation}
					forwardRef={locationsRef}
				/>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => props.onClose()}>
								Close
							</IonButton>
							<IonButton
								color='success'
								disabled={!selectedLocation}
								onClick={() => {
									props.onClose();
									props.createEvent(selectedLocation);
								}}
							>
								Create Availability
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default WorkCalendarModal;

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { workersReducer } from './workers-reducer';
import { WorkerRecord, WorkersState } from './workers-types';
import { Actions as Action, ActionType } from './actions';
import { moduleContext } from '../../../contexts/ModuleContext';

interface ProviderProps {
	workerId: string | null;
	children: React.ReactNode;
}

interface ContextProvider {
	state: WorkersState;
	dispatch: React.Dispatch<Action>;
}

const initialState: WorkersState = {
	worker: null,
	activeSubNavTabs: {
		workerTabs: 'key_details',
	},
};

const contextProps = {
	state: initialState,
	dispatch: () => null,
};

export const WorkersContext = createContext<ContextProvider>(contextProps);

const WorkersProvider = ({ workerId, children }: ProviderProps) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [state, dispatch] = useReducer(workersReducer, initialState);

	// Initial load of worker into state - only the required fields, not the whole record
	useEffect(() => {
		if (state.worker === null) {
			moduleCtx.getWorker(workerId).then((res: any) => {
				const payload: WorkerRecord = {
					key_details: { type_of_engagement: res.key_details.type_of_engagement },
					payroll_details: {
						appears_on_organisation_chart: res.payroll_details.appears_on_organisation_chart,
						schedule_default_location: res.payroll_details.schedule_default_location,
					},
					user: {
						id: res.user.id,
						first_name: res.user.first_name,
						last_name: res.user.last_name,
					},
				};

				dispatch({ type: ActionType.SET_WORKER, payload });
			});
		}
	}, [state]);

	return <WorkersContext.Provider value={{ state, dispatch }}>{children}</WorkersContext.Provider>;
};

export default WorkersProvider;

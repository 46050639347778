import { useEffect, useContext, useState } from 'react';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { getCenter } from 'geolib';
import Map from '../../../../../components/Maps/Map';
import axios from '../../../../../lib/axios';
import { showToast } from '../../../../../lib/toast';
import { boundsFromLatLngList, getBoundsZoomLevel } from '../../../../../helpers/geo';
import { Coordinates, MapControls, PropMarker } from '../../../../../interfaces/Maps/MapsInterface';
import { subtleGreyScale } from '../../../../../components/Maps/MapStyles';
import { mapLocations } from '../../../../../components/Maps/MapLocations';
import Calendar from '../../../../../components/Maps/Calendar/Calendar';
import { dateContext } from './../../../../../contexts/DateContext';
import { googleMapsContext } from './../../../../../contexts/GoogleMapsContext';
import Loading from '../../../../../components/UI/Loading';
import { HeatmapsUsageMode } from '../../workers-types';

interface Props {
	parentRef: any;
	tabRef: any;
	permissionTo: Function;
	usageMode?: HeatmapsUsageMode;
	workerId?: string;
	startDate?: DateTime;
	endDate?: DateTime;
}

const HeatmapsIndex: React.FC<Props> = (props: Props) => {
	const dateCtx: any = useContext(dateContext);
	const googleMapsCxt = useContext<any>(googleMapsContext);

	// Usage mode defaults
	let { workerId }: any = useParams();
	let startDateDefault: DateTime = dateCtx.date ?? DateTime.now();
	let endDateDefault: DateTime = dateCtx.date ?? DateTime.now();

	switch (props.usageMode) {
		case HeatmapsUsageMode.TIMESHEET_DAILY:
		case HeatmapsUsageMode.TIMESHEET_WEEKLY:
			workerId = props.workerId;
			startDateDefault = props.startDate!.startOf('day');
			endDateDefault = props.endDate!.endOf('day');
			break;
	}

	const [mapCenter, setMapCenter] = useState({ lat: 51.96217, lng: -0.265839 });
	const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
	const [zoom, setZoom] = useState<number>(14);
	const [controls, setControls] = useState<Array<MapControls>>([]);
	const [heatmap, setHeatmap] = useState<any>(null);
	const [marked, setMarked] = useState<Array<any>>([]);
	const [markers, setMarkers] = useState<Array<PropMarker>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [lastRequest, setLastRequest] = useState<any>({});
	const [startDate, setStartDate] = useState<DateTime>(startDateDefault);
	const [endDate, setEndDate] = useState<DateTime>(endDateDefault);

	const loadHeatmapSummary = async (start: DateTime, end: DateTime) => {
		return new Promise((res, rej) => {
			axios
				.post('/api/workers/workers_list/worker_card/heatmap', {
					worker_id: workerId,
					start: start.toISO(),
					end: end.toISO(),
					summary: 1,
				})
				.then((result) => {
					if (result.data) {
						setMarked(
							result.data.map((item: any) => {
								let date = DateTime.now().set({
									year: item._id.date.year,
									month: item._id.date.month,
									day: item._id.date.day,
								});
								return { date: date.toJSDate() };
							})
						);
						res(true);
					}
				})
				.catch((e) => {
					showToast('error');
					rej(e);
				});
		});
	};

	const handleCellClick = async (e: any) => {
		if (e.value) {
			if (e.value[1] !== null) {
				let startDate = DateTime.fromJSDate(e.value[0]);
				let endDate = DateTime.fromJSDate(e.value[1]);
				dateCtx.setDate(startDate);
				setStartDate(startDate);
				setEndDate(endDate);
			}
		}
	};

	useEffect(() => {
		const locations = async () => {
			let localMarkers = await mapLocations(workerId);
			setMarkers(localMarkers.markers);
		};
		locations();
	}, []);

	useEffect(() => {
		if (googleMapsCxt.isLoaded === true && mapRef !== null) {
			loadHeatmap(startDate, endDate);
		}
	}, [googleMapsCxt.isLoaded, mapRef]);

	useEffect(() => {
		if (googleMapsCxt.isLoaded === true && mapRef !== null) {
			loadHeatmap(startDate, endDate);
		}
	}, [startDate, endDate]);

	useEffect(() => {
		const objControls: any = {
			position: 'TOP_CENTER',
			component: Calendar,
			data: {
				changeFunction: handleCellClick,
				summaryFunction: loadHeatmapSummary,
				marked: marked,
				select: 'range',
				defaultValue: startDateDefault,
			},
		};

		if (props.usageMode === HeatmapsUsageMode.TIMESHEET_WEEKLY) {
			objControls.data.defaultValue = [startDateDefault, endDateDefault];
		}

		setControls([objControls]);
	}, [marked]);

	const loadHeatmap = async (startDate: DateTime, endDate: DateTime) => {
		return new Promise((res, rej) => {
			setLoading(true);
			let request = {
				worker_id: workerId,
				start: startDate?.set({ hour: 0, minute: 0, second: 0 }).toISO(),
				end: endDate?.set({ hour: 23, minute: 59, second: 59 }).toISO(),
				kalman: 1,
			};
			axios
				.post('/api/workers/workers_list/worker_card/heatmap', request)
				.then((result) => {
					if (result.data) {
						const coords: Array<Coordinates> = [];
						const localHeatmap = result.data.map((hmd: any, index: number) => {
							coords.push({
								lat: Number(hmd.location.coordinates[1]),
								lng: Number(hmd.location.coordinates[0]),
							});
							return new google.maps.LatLng(
								hmd.location.coordinates[1],
								hmd.location.coordinates[0]
							);
						});
						setHeatmap(localHeatmap);

						if (
							coords.length > 0 &&
							(request.start !== lastRequest.start || request.end !== lastRequest.end)
						) {
							const coordinate: any = getCenter(coords);
							const bounds = boundsFromLatLngList(coords);
							var w = mapRef?.getDiv().offsetWidth ?? 0;
							var h = mapRef?.getDiv().offsetHeight ?? 0;
							let localZoom = getBoundsZoomLevel(bounds, {
								height: h,
								width: w,
							});
							if (localZoom > 19) {
								localZoom = 19;
							}
							setMapCenter({
								lat: Number(coordinate.latitude),
								lng: Number(coordinate.longitude),
							});
							setZoom(localZoom);
						}

						setLastRequest(request);
						setLoading(false);
						res(true);
					}
				})
				.catch((e) => {
					showToast('error');
					rej(e);
				});
		});
	};

	return (
		<div className='heatmap' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			{loading && <Loading overlay={true} />}
			<Map
				mapStyle={subtleGreyScale}
				center={mapCenter}
				zoom={zoom}
				controls={controls}
				markers={markers}
				heatmap={heatmap}
				onMapLoad={(map: google.maps.Map) => {
					setMapRef(map);
				}}
				onMapZoom={(zoom: number) => {
					setZoom(zoom);
				}}
			/>
		</div>
	);
};

export default HeatmapsIndex;

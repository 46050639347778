import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { forwardRef, useState } from 'react';
import { RoutemapsUsageMode } from '../../../Workers/Workers/workers-types';
import DateProvider from '../../../../contexts/DateContext';
import RouteMapIndex from '../../../Workers/Workers/Tracking/RouteMap/RouteMapIndex';

type Props = {
	onClose: Function;
};

const RoutemapModal = forwardRef<Props, any>((props: Props, ref: any) => {
	const [modalTitle, setModalTitle] = useState<string>('');
	const [routemapIndexProps, setRoutemapIndexProps] = useState<any | undefined>(undefined);

	const handleOnDidPresent = () => {
		setRoutemapIndexProps({
			parentRef: null,
			tabRef: null,
			permissionTo: null,
			usageMode: RoutemapsUsageMode.TIMESHEET_DAILY,
			workerId: ref.current.allocationData.workerId,
			selectedDate: ref.current.allocationData.selectedDate,
		});

		setModalTitle(
			`Routemap for ${
				ref.current.allocationData.workerName
			} | ${ref.current.allocationData.selectedDate.toFormat('dd/MM/yyyy')}`
		);
	};

	const onClose = () => {
		// Reset
		setModalTitle('');
		setRoutemapIndexProps(undefined);
		props.onClose();
	};

	return (
		<IonModal
			ref={ref}
			class='timesheets-routemap-modal'
			style={{ '--width': '80vw', '--height': '98vh' }}
			onDidPresent={handleOnDidPresent}
			onWillDismiss={() => onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{modalTitle}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<DateProvider>
					{routemapIndexProps && <RouteMapIndex {...routemapIndexProps} />}
				</DateProvider>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
});

export default RoutemapModal;

import { IonItem, IonLabel } from '@ionic/react';
import { DateTime } from 'luxon';

interface Props {
	item: any;
	index: number;
	click: Function;
}

const RouteItem: React.FC<Props> = (props: Props) => {

	const displayDate = (start: string, end: string) => {
		let startDate = DateTime.fromISO(start);
		let endDate = DateTime.fromISO(end);
		return (
			startDate.toFormat('HH:mm') +
			' - ' +
			endDate.toFormat('HH:mm')
		);
	};
	return (
		<>
			<div>
				<IonItem
					button={true}
					className='conversation-item'
					onClick={() => {
						props?.click(props.item._id);
					}}
				>
					<IonLabel>
						<h3>
							{displayDate(props.item.start, props.item.finish)}
						</h3>
					</IonLabel>
				</IonItem>
			</div>
		</>
	);
};

export default RouteItem;

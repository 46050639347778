import { DateTime, Info } from 'luxon';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker } from '@mobiscroll/react';
import { Fragment, useEffect, useRef, useState } from 'react';

import './Calendar.scss';
import { RoutemapsUsageMode } from '../../../pages/Workers/Workers/workers-types';

interface Props {
	changeFunction: Function;
	summaryFunction: Function;
	marked: Array<any>;
	select: 'range' | 'date';
	defaultValue: DateTime;
	usageMode?: any;
}

const Calendar: React.FC<Props> = (props: Props) => {
	const [calendarType, setCalendarType] = useState<'year' | 'month' | 'week' | undefined>('week');
	const [defaultValue, setDefaultValue] = useState<null | DateTime | Date | Array<DateTime | Date>>(
		null
	);
	const divRef = useRef<HTMLDivElement>(null);
	const [dayNames, setDayNames] = useState<Array<string>>([]);
	const [datepickerProps, setDatePickerProps] = useState<any>({});

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));

		// Set the default date value
		let defaultValueTmp: DateTime | Date | Array<DateTime | Date> = DateTime.now();
		if (props.defaultValue) {
			if (props.select === 'range') {
				if (Array.isArray(props.defaultValue)) {
					defaultValueTmp = [props.defaultValue[0].toJSDate(), props.defaultValue[1].toJSDate()];
				} else {
					defaultValueTmp = [props.defaultValue.toJSDate(), props.defaultValue.toJSDate()];
				}
			} else {
				defaultValueTmp = props.defaultValue.toJSDate();

				if (props.usageMode === RoutemapsUsageMode.TIMESHEET_DAILY) {
					setDatePickerProps((prevState: any) => ({ ...prevState, value: defaultValueTmp }));
				}
			}
		}

		setDefaultValue(defaultValueTmp);
	}, []);

	const handleCellClick = async (e: any) => {
		if (props.usageMode === RoutemapsUsageMode.TIMESHEET_DAILY) {
			props.changeFunction(DateTime.fromJSDate(e.date));
			setDatePickerProps((prevState: any) => ({
				...prevState,
				value: DateTime.fromJSDate(e.date),
			}));
		}
	};

	const handleOnChange = async (e: any) => {
		if (props.usageMode === RoutemapsUsageMode.TIMESHEET_DAILY) return;

		if (props.select === 'date') {
			props.changeFunction(DateTime.fromJSDate(e.value));
		} else {
			props.changeFunction(e);
		}
	};

	const knobClick = () => {
		setCalendarType((prevValue) => {
			if (prevValue === 'week') {
				return 'month';
			} else {
				return 'week';
			}
		});
	};

	return (
		<div className='date-expand' ref={divRef} style={{ width: '30vw' }}>
			<Datepicker
				dayNamesMin={dayNames}
				select={props.select}
				showRangeLabels={false}
				calendarSize={1}
				marked={props.marked}
				calendarType={calendarType}
				defaultValue={defaultValue}
				controls={['calendar']}
				display='inline'
				firstDay={1}
				onCellClick={(e) => handleCellClick(e)}
				onChange={(e) => handleOnChange(e)}
				onPageLoading={(e) => {
					let start = DateTime.fromJSDate(e.firstDay);
					let end = DateTime.fromJSDate(e.lastDay);
					props.summaryFunction(start, end);
				}}
				renderCalendarHeader={() => {
					return (
						<Fragment>
							<CalendarPrev className='custom-prev' />
							<CalendarNav className='custom-nav' />
							<CalendarNext className='custom-next' />
						</Fragment>
					);
				}}
				{...datepickerProps}
			/>
			<div className='date-expand-control'>
				<div className='knob' onClick={knobClick}></div>
			</div>
		</div>
	);
};

export default Calendar;

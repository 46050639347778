import React, { useContext, useEffect, useRef, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonCol,
	IonRow,
	IonGrid,
	IonLabel,
	IonInput,
	IonTextarea,
} from '@ionic/react';
import { HalfDayOptions, SickData, SickStatus } from '../Sick/sick-types';
import { DateTime, Info } from 'luxon';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { Datepicker } from '@mobiscroll/react';
import { cloneDeep } from 'lodash';
import Loading from '../../../../components/UI/Loading';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { InfoBox } from '../../../../components/Forms/FormFields';
import { buildHalfDayOptions, getNoOfDays } from '../../../../lib/functions';
import { showToast } from '../../../../lib/toast';

interface SickModalProps {
	isOpen: boolean;
	initialData: SickData;
	onClose: Function;
	onSave: Function;
	onRequestManager: Function;
	onSaveManagersNotes: Function;
	onDelete?: Function;
	permissionTo: Function;
	showLoading: boolean;
	usageMode?: 'dashboard' | 'workerCard';
}

const SickModal: React.FC<SickModalProps> = ({
	isOpen,
	initialData,
	onClose,
	onSave,
	onRequestManager,
	onSaveManagersNotes,
	onDelete,
	permissionTo,
	showLoading,
	usageMode,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [title, setTitle] = useState<string>('');
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const [halfDayOptionsLoading, setHalfDayOptionsLoading] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<SickData>();
	const [workers, setWorkers] = useState<Array<{ label: string; value: string }>>();
	const [halfDayOptions, setHalfDayOptions] = useState<Array<HalfDayOptions>>([]);
	const [isNew, setIsNew] = useState<boolean>(true);
	const infoBoxColStyle = { padding: '1px 0 0 0' };
	const refWorkingHoursStart = useRef<any>(null);
	const refWorkingHoursEnd = useRef<any>(null);
	const [dayNames, setDayNames] = useState<Array<string>>([]);

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	// Local constants to block multiple loads upon modal presenting
	let _workersLoading = false;
	let _halfDayOptionsLoading = false;

	// Mask the modal if waiting for an operation to complete in the parent
	useEffect(() => {
		setIsLoading(showLoading);
	}, [showLoading]);

	// Global loading signal
	useEffect(() => {
		if (isNew) {
			setIsLoading(workersLoading || halfDayOptionsLoading);
		}
	}, [workersLoading, halfDayOptionsLoading]);

	// Date validation and number of days
	useEffect(() => {
		// Make sure dates are in valid order
		if (data?.start && data?.end) {
			if (data.start > data.end) {
				showToast('error', 'Please ensure the start date is on or before the end date');
				return;
			}

			setData((prevState: any) => ({
				...prevState,
				period: getNoOfDays([data.start!, data.end!], true, [
					data.startHourIndex!,
					data.endHourIndex!,
				]),
			}));
		}
	}, [data?.start, data?.end, data?.startHourIndex, data?.endHourIndex]);

	const handleWorkerChange = (workerId: string) => {
		setHalfDayOptionsLoading(true);
		moduleCtx
			.getHalfDayOptions(workerId)
			.then((res: any) => {
				setHalfDayOptions(res);
			})
			.finally(() => {
				setHalfDayOptionsLoading(false);
			});
	};

	const handleOnWillPresent = () => {
		// Must clone the initial data into the new state variable or changes affect the initial data
		setData(cloneDeep(initialData));

		// Load the half day options - debounced with local variable
		if (!_halfDayOptionsLoading) {
			_halfDayOptionsLoading = true;
			setHalfDayOptionsLoading(_halfDayOptionsLoading);
			moduleCtx
				.getHalfDayOptions(initialData.workerId ? initialData.workerId : null)
				.then((res: any) => {
					setHalfDayOptions(res);
				})
				.finally(() => {
					_halfDayOptionsLoading = false;
					setHalfDayOptionsLoading(_halfDayOptionsLoading);
				});
		}

		// Setup
		if (!initialData.requestId) {
			setIsNew(true);
			setTitle('New Sickness');

			// Get workers dropdown options unless we already know the workerId
			if (!_workersLoading && !initialData.workerId) {
				_workersLoading = true;
				setWorkersLoading(_workersLoading);
				moduleCtx
					.getWorkersOptions()
					.then((res: any) => {
						setWorkers(res);
					})
					.finally(() => {
						_workersLoading = false;
						setWorkersLoading(_workersLoading);
					});
			} else {
				setWorkersLoading(false);
			}
		} else if (initialData.requestId) {
			setIsNew(false);

			switch (initialData.status) {
				case SickStatus.APPROVED:
					setTitle('Reported Sickness');
					break;
			}
		}
	};

	const handleOnDidDismiss = () => {
		// Close the modal
		onClose();
	};

	const handleDeleteSickness = () => {
		if (permissionTo('delete') && onDelete) {
			onDelete(data);
		}
	};

	return (
		<IonModal
			style={{ '--width': '50vw', '--height': `${isNew ? 50 : 75}vh` }}
			isOpen={isOpen}
			onWillPresent={handleOnWillPresent}
			onDidDismiss={handleOnDidDismiss}
			className={`prop-form hatsick-sick-modal${usageMode ? ' usage-mode-' + usageMode : ''}`}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{title}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => handleOnDidDismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='tight-margins-modal prop-form'>
				{isLoading && <Loading overlay={true} />}
				<IonGrid>
					{!initialData.workerId && (
						<IonRow>
							<IonCol size='4'>
								<IonLabel>Worker Name</IonLabel>
							</IonCol>
							{!isNew && (
								<IonCol size='8' style={infoBoxColStyle}>
									<InfoBox
										title='Worker Name'
										value={data?.workerName}
										useLabels={false}
										showBorder={true}
										showBackground={true}
									/>
								</IonCol>
							)}
							{isNew && (
								<IonCol size='8'>
									<SelectStyled
										className='react-select-container'
										classNamePrefix='react-select'
										options={workers}
										onChange={(newValue: any) => {
											handleWorkerChange(newValue.value);
											setData((prevState: any) => ({
												...prevState,
												workerId: newValue.value,
											}));
										}}
										value={workers?.filter((option: any) => option.value === data?.workerId)}
									/>
								</IonCol>
							)}
						</IonRow>
					)}
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Sickness Reason</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='Sickness Reason'
									value={data?.description}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8'>
								<IonInput
									onIonInput={(e: any) =>
										setData((prevState: any) => ({ ...prevState, description: e.target.value }))
									}
									value={data?.description}
								/>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Requested Date</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='Requested Date'
									value={data?.requestedDate.toFormat('dd/MM/yyyy')}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8'>
								<Datepicker
									dayNamesMin={dayNames}
									controls={['calendar']}
									firstDay={1}
									defaultValue={isNew ? data?.requestedDate : null}
									inputComponent={IonInput}
									inputProps={{
										placeholder: 'Please select...',
									}}
									returnFormat={'jsdate'}
									onChange={(e: any) => {
										setData((prevState: any) => {
											return {
												...prevState,
												requestedDate: e.value ? DateTime.fromJSDate(e.value).startOf('day') : null,
											};
										});
									}}
								/>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>Start</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='Start'
									value={data?.start ? data.start.toFormat('dd/MM/yyyy @ HH:mm') : null}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8'>
								<IonRow>
									<IonCol className='p-0 pe-2 flex-grow-1'>
										<Datepicker
											dayNamesMin={dayNames}
											controls={['calendar']}
											firstDay={1}
											defaultValue={isNew ? data?.start : null}
											inputComponent={IonInput}
											inputProps={{
												placeholder: 'Please select...',
											}}
											returnFormat={'jsdate'}
											onChange={(e: any) => {
												setData((prevState: any) => {
													return {
														...prevState,
														start: e.value ? DateTime.fromJSDate(e.value).startOf('day') : null,
													};
												});
											}}
										/>
									</IonCol>
									<IonCol className='p-0 flex-grow-0 half-day-col'>
										<SelectStyled
											className='react-select-container'
											classNamePrefix='react-select'
											forwardRef={refWorkingHoursStart}
											options={buildHalfDayOptions(
												halfDayOptions,
												refWorkingHoursStart,
												data?.start ? data.start.toFormat('ccc').toLowerCase() : null,
												'start'
											)}
											onChange={(newValue: any) => {
												if (newValue.value !== data?.startHourIndex) {
													setData((prevState: any) => ({
														...prevState,
														startHourIndex: newValue.value,
													}));
												}
											}}
										/>
									</IonCol>
								</IonRow>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>End</IonLabel>
						</IonCol>
						{!isNew && (
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='End'
									value={data?.end ? data.end.toFormat('dd/MM/yyyy @ HH:mm') : null}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						)}
						{isNew && (
							<IonCol size='8'>
								<IonRow>
									<IonCol className='p-0 pe-2 flex-grow-1'>
										<Datepicker
											dayNamesMin={dayNames}
											controls={['calendar']}
											firstDay={1}
											defaultValue={isNew ? data?.end : null}
											inputComponent={IonInput}
											inputProps={{
												placeholder: 'Please select...',
											}}
											returnFormat={'jsdate'}
											onOpen={(e: any) => {
												// Pre-set the calendar page with the start date if nothing selected yet
												if (!e.inst.getVal()) e.inst.setTempVal(data?.start);
											}}
											onChange={(e: any) => {
												setData((prevState: any) => {
													return {
														...prevState,
														end: e.value ? DateTime.fromJSDate(e.value).startOf('day') : null,
													};
												});
											}}
										/>
									</IonCol>
									<IonCol className='p-0 flex-grow-0 half-day-col'>
										<SelectStyled
											className='react-select-container'
											classNamePrefix='react-select'
											forwardRef={refWorkingHoursEnd}
											options={buildHalfDayOptions(
												halfDayOptions,
												refWorkingHoursEnd,
												data?.end ? data.end.toFormat('ccc').toLowerCase() : null,
												'end'
											)}
											onChange={(newValue: any) => {
												if (newValue.value !== data?.endHourIndex) {
													setData((prevState: any) => ({
														...prevState,
														endHourIndex: newValue.value,
													}));
												}
											}}
										/>
									</IonCol>
								</IonRow>
							</IonCol>
						)}
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>{`${isNew ? 'Calendar' : 'Working'} Days`}</IonLabel>
						</IonCol>
						<IonCol size='8' style={infoBoxColStyle}>
							<InfoBox
								title='Days'
								value={() => {
									if (isNew) {
										return data?.period !== undefined ? String(data.period) : 0;
									} else {
										return initialData.period;
									}
								}}
								useLabels={false}
								showBorder={true}
								showBackground={true}
							/>
						</IonCol>
					</IonRow>
					{!isNew && data?.uploaded && data.fileName && data.filePath && (
						<IonRow>
							<IonCol size='4'>
								<IonLabel>Uploaded Document</IonLabel>
							</IonCol>
							<IonCol size='8' style={infoBoxColStyle}>
								<InfoBox
									title='Uploaded Document'
									value={
										<a className='link-button' href={data.filePath} download={data.fileName}>
											{data.fileName}
										</a>
									}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							</IonCol>
						</IonRow>
					)}
					{!isNew && (
						<IonRow>
							<IonCol size='4'>
								<IonLabel>Managers Notes</IonLabel>
							</IonCol>
							<IonCol size='8'>
								<IonTextarea
									className='managers-notes'
									value={data?.managersNotes}
									onIonInput={(e: any) =>
										setData((prevState: any) => {
											return {
												...prevState,
												managersNotes: e.target.value,
											};
										})
									}
								/>
							</IonCol>
						</IonRow>
					)}
				</IonGrid>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size={'12'} className='text-right'>
							<IonButton
								color='secondary'
								onClick={() => handleOnDidDismiss()}
								disabled={isLoading}
							>
								{isNew ? 'Cancel' : 'Close'}
							</IonButton>
							{!isNew && (
								<>
									{permissionTo('delete') && usageMode !== 'workerCard' && (
										<IonButton color='danger' onClick={handleDeleteSickness} disabled={isLoading}>
											Delete Sickness
										</IonButton>
									)}
									<IonButton
										color='primary'
										onClick={() => onRequestManager(data)}
										disabled={isLoading}
									>
										Contact Manager
									</IonButton>
									<IonButton
										color='success'
										onClick={() => onSaveManagersNotes(data)}
										disabled={isLoading}
									>
										Save Managers Notes
									</IonButton>
								</>
							)}
							{isNew && (
								<IonButton
									color='primary'
									onClick={() => onSave(data, halfDayOptions)}
									disabled={isLoading}
								>
									Report Sickness
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default SickModal;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';

interface NoteModalProps {
	isOpen: boolean;
	onClose: Function;
	onDidDismiss: Function;
	initialData: any;
}

const NoteModal: React.FC<NoteModalProps> = ({ isOpen, onClose, onDidDismiss, initialData }) => {
	return (
		<IonModal
			class='note-modal'
			style={{ '--width': '70%' }}
			isOpen={isOpen}
			onDidDismiss={() => onDidDismiss()}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Note: {initialData.date}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<h4>Note author: {initialData.author}</h4>
				<p>{initialData.note}</p>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default NoteModal;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonCol,
	IonFooter,
	IonRow,
} from '@ionic/react';
import ItemModalForm from './ItemModalForm';
import { useContext, useState, useEffect } from 'react';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { JobSkillsOptions } from '../../../../interfaces/Utilities/JobSkills';
import { CatalogueItem } from '../../../../interfaces/Utilities/CatalogueItem';
import Loading from '../../../../components/UI/Loading';
import { cloneDeep } from 'lodash';
import axios from '../../../../lib/axios';
import { enforceDecimalLimit } from '../../../../lib/functions';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';

interface ItemModalProps {
	isOpen: boolean;
	itemId: string;
	itemName: string;
	itemData: CatalogueItem;
	isLoading: boolean;
	onSave: Function;
	onClose: Function;
	onSuccess: Function;
	permissionTo: Function;
}

const ItemModal: React.FC<ItemModalProps> = ({
	isOpen,
	itemId,
	itemName,
	itemData,
	isLoading,
	onSave,
	onClose,
	onSuccess,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const isNew: boolean = itemId === '';
	const [jobSkillsLoading, setJobSkillsLoading] = useState<boolean>(true);
	const [jobSkills, setJobSkills] = useState<Array<any>>([]);
	const [vatRatesLoading, setVatRatesLoading] = useState<boolean>(true);
	const [vatRates, setVatRates] = useState<Array<any>>([]);

	// Form state
	const formStateDefault: CatalogueItem = {
		_id: '',
		code: '',
		name: '',
		description: '',
		price: 0,
		job_skill_id: null,
		tax_rate_id: null,
	};
	const [formState, setFormState] = useState<CatalogueItem>(formStateDefault);

	useEffect(() => {
		if (!jobSkillsLoading && !vatRatesLoading) {
			if (!isNew) fillForm();
		}
	}, [jobSkillsLoading, vatRatesLoading]);

	const loadJobSkills = () => {
		setJobSkillsLoading(true);

		moduleCtx
			.getJobSkillsOptions(true)
			.then((res: JobSkillsOptions[]) => {
				setJobSkills(res);
			})
			.finally(() => {
				setJobSkillsLoading(false);
			});
	};

	const loadVatRates = () => {
		setVatRatesLoading(true);

		axios
			.get('/api/utilities/general/vat_register')
			.then((res: any) => {
				if (res && res.data.length > 0) {
					setVatRates(
						res.data.map((item: any) => {
							return {
								label: item.description,
								value: item.id,
							};
						})
					);
				}
			})
			.finally(() => {
				setVatRatesLoading(false);
			});
	};

	const fillForm = () => {
		let formData: any = cloneDeep(itemData);
		Object.keys(formData).forEach((key: any) => {
			switch (key) {
				case 'price':
					formData[key] = currencyFormatter({ value: formData[key] }, true);
					break;
				case 'job_skill_id':
					formData[key] = jobSkills.filter((item: any) => item.value === formData[key])[0];
					break;
				case 'tax_rate_id':
					formData[key] = vatRates.filter((item: any) => item.value === formData[key])[0];
					break;
			}
		});

		setFormState(formData);
	};

	const handleOnPresent = () => {
		loadJobSkills();
		loadVatRates();
	};

	const handleOnClose = () => {
		setJobSkills([]);
		setJobSkillsLoading(true);

		setVatRates([]);
		setVatRatesLoading(true);

		setFormState(formStateDefault);

		onClose();
	};

	const handleEntry = (id: string, item: any) => {
		// Build the form state
		switch (id) {
			case 'job_skill_id':
			case 'tax_rate_id':
				// Dropdowns
				setFormState((prevState: CatalogueItem) => {
					const update: any = {};
					update[id] = { label: item.label, value: item.value };
					return { ...prevState, ...update };
				});
				break;
			case 'price':
				// Number fields
				setFormState((prevState: CatalogueItem) => {
					const update: any = {};
					update[id] = enforceDecimalLimit(item.target.value, 2);
					return { ...prevState, ...update };
				});
				break;
			default:
				// Text inputs
				setFormState((prevState: CatalogueItem) => {
					const update: any = {};
					update[id] = item.target.value;
					return { ...prevState, ...update };
				});
				break;
		}
	};

	return (
		<IonModal
			style={{ '--width': '60vw', '--height': '450px' }}
			isOpen={isOpen}
			onDidPresent={handleOnPresent}
			onDidDismiss={handleOnClose}
			className='item-modal prop-form'
		>
			{(isLoading || jobSkillsLoading || vatRatesLoading) && <Loading overlay={true} />}
			<IonHeader>
				<IonToolbar>
					<IonTitle>{isNew ? 'Add Item' : `Editing ${itemName ? itemName : '...'}`}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<ItemModalForm
					jobSkillsOptions={jobSkills}
					vatRateOptions={vatRates}
					formState={formState}
					isNew={isNew}
					handleEntry={handleEntry}
				/>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton
								color='secondary'
								onClick={() => onClose()}
								disabled={jobSkillsLoading || vatRatesLoading}
							>
								Close
							</IonButton>
							<IonButton
								color='success'
								onClick={() => onSave(isNew, formState)}
								disabled={jobSkillsLoading || vatRatesLoading}
							>
								{isNew ? 'Add Item' : 'Save Changes'}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default ItemModal;

import { useContext, useEffect, useRef, useState } from 'react';
import { TimesheetsContext } from '../TimesheetsProvider';
import DataGrid from '../../../components/DataGrid/DataGrid';
import axios from '../../../lib/axios';
import { IonButton, IonLabel } from '@ionic/react';
import Loading from '../../../components/UI/Loading';
import { ActionType } from '../actions';
import WorkerReportHeader from './components/WorkerReportHeader';
import { moduleContext } from '../../../contexts/ModuleContext';
import { authContext } from '../../../contexts/AuthContext';
import { getTimezone } from '../../../lib/functions';
import { showToast } from '../../../lib/toast';
import WorkerAllocationsReportModal from './modals/WorkerAllocationsReportModal';
import { AutoComplete } from '../../../components/Forms/AutoComplete';
import { WorkersOptions } from '../../../utils/autocomplete';

type Props = {
	pageId: string;
	title: string;
	permissionTo: Function;
};

const WorkerAllocationsReport = (props: Props) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const gridRef: any = useRef<any>();
	const headerRef: any = useRef<any>();
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<Array<any>>([]);
	const [workerLoading, setWorkerLoading] = useState<boolean>(true);
	const [worker, setWorker] = useState<any>({});
	const [workerId, setWorkerId] = useState<string | null>('');
	const [filterWorkerId, setFilterWorkerId] = useState<string | null>(null);
	const columns: any = [
		{ field: 'worker_name', headerName: 'Worker Name', flex: 1 },
		{ field: 'date_recorded', headerName: 'Date Recorded', type: 'rightAligned', flex: 1 },
	];

	// Setup the local state with the main state values
	const [curMonth, setCurMonth] = useState<number>(state.workerReportMonth);
	const [curYear, setCurYear] = useState<number>(state.workerReportYear);

	// Modals
	const [workerAllocationsReportModal, setWorkerAllocationsReportModal] = useState<any>({
		isOpen: false,
		modalData: null,
	});

	useEffect(() => {
		setIsLoading(workerLoading || gridLoading);
	}, [workerLoading, gridLoading]);

	useEffect(() => {
		if (headerRef.current) headerRef.current.setHeaderTitle(props.title);
		loadWorker(authCtx.user.worker_id);
	}, []);

	useEffect(() => {
		if (gridReady) {
			loadGridData(curMonth, curYear);

			// Update the main state values with the local state values
			dispatch({ type: ActionType.SET_WORKER_REPORT_MONTH, payload: curMonth });
			dispatch({ type: ActionType.SET_WORKER_REPORT_YEAR, payload: curYear });
		}
	}, [gridReady, curMonth, curYear, state.showMyTeam]);

	// Worker filter
	useEffect(() => {
		if (gridReady) loadGridData(curMonth, curYear);
	}, [filterWorkerId]);

	const loadWorker = (workerId: string) => {
		setWorkerLoading(true);

		moduleCtx
			.getWorker(workerId)
			.then((res: any) => {
				setWorker(res);
			})
			.finally(() => {
				setWorkerLoading(false);
			});
	};

	const loadGridData = (month: number, year: number) => {
		setGridLoading(true);
		setWorkerId(null);
		setData([]);

		let payload: any = {
			month,
			year,
			...getTimezone(),
			my_team: state.showMyTeam,
			payment_method: ['salary'],
		};

		if (filterWorkerId) payload.worker_id = filterWorkerId;

		axios
			.post('/api/timesheets/timesheets_and_allocations/allocations_salaried', payload)
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleRowClicked = (params: any) => {
		params.node.setSelected(true);
		setWorkerId(params.data._id);
	};

	const handleRowDoubleClicked = (params: any) => {
		handleRowClicked(params);
		handleViewDetails();
	};

	const handleViewDetails = () => {
		if (workerId === null) {
			showToast('error', 'Please select a worker from the list');
			return;
		}

		// Load the selected worker
		setWorkerLoading(true);
		moduleCtx
			.getWorker(workerId)
			.then((res: any) => {
				// Open the modal
				setWorkerAllocationsReportModal({
					isOpen: true,
					modalData: { workerId, worker: res },
				});
			})
			.finally(() => {
				setWorkerLoading(false);
			});
	};

	const workerAllocationsReportModalOnClose = () => {
		setWorkerAllocationsReportModal({ ...workerAllocationsReportModal, isOpen: false });
	};

	const handleWorkerChange = (worker: any) => {
		setFilterWorkerId(worker?.value ?? null);
	};

	return (
		<div className={`${props.pageId}`}>
			{isLoading && <Loading overlay={true} />}
			<WorkerReportHeader
				ref={headerRef}
				mode={'main'}
				approvesAllTimesheets={
					worker && worker.hasOwnProperty('key_details')
						? worker.key_details.approves_all_timesheets
						: false
				}
				curMonth={curMonth}
				curYear={curYear}
				setCurMonth={setCurMonth}
				setCurYear={setCurYear}
			/>

			<DataGrid
				onGridReady={() => setGridReady(true)}
				ref={gridRef}
				title={''}
				cols={columns}
				data={data}
				rowClickedFunction={handleRowClicked}
				rowDoubleClickedFunction={handleRowDoubleClicked}
				rowCursorPointer={props.permissionTo('read')}
				suppressRowHoverHighlight={!props.permissionTo('read')}
				useSearch={false}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: (month: number, year: number) => {
						setCurMonth(month);
						setCurYear(year);
					},
					displayAllTime: false,
					displayAnnual: true,
					forceDate: `${String(curMonth).padStart(2, '0')}-${curYear}`,
					rowSlot: (
						<>
							<IonLabel className='lbl-filter-by-worker'>Filter Workers:</IonLabel>
							<AutoComplete
								className='filter-by-worker'
								onChangeCallback={handleWorkerChange}
								placeholder='Type a worker name...'
								loadOptions={(inputValue: any, callback: any) => {
									WorkersOptions(inputValue, callback);
								}}
								isMulti={false}
								isClearable={true}
								defaultOptions={true}
								useLabels={false}
								editMode={true}
							/>
							<IonButton onClick={handleViewDetails} disabled={!workerId}>
								View Details
							</IonButton>
						</>
					),
				}}
			/>

			<WorkerAllocationsReportModal
				isOpen={workerAllocationsReportModal.isOpen}
				modalData={workerAllocationsReportModal.modalData}
				onClose={workerAllocationsReportModalOnClose}
				permissionTo={props.permissionTo}
			/>
		</div>
	);
};

export default WorkerAllocationsReport;

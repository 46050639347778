import { useContext, useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import axios from '../../../lib/axios';
import { getTimezone } from '../../../lib/functions';
import { showToast } from '../../../lib/toast';
import { DateTime } from 'luxon';
import { authContext } from '../../../contexts/AuthContext';
import { EventType as RequestEventType } from '../../Workers/HolidayAbsenceTraining/Requests/request-types';
import RequestAndSickListingModal from '../modals/RequestAndSickListingModal';
import { titleCase } from '../../../helpers/strings';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const HolidaysThisMonth: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const permissionTo = usePermissionTo('workers.holidays_and_absences.requests');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [values, setValues] = useState<any>({});
	const payload: any = {
		worker_id: authCtx.user.worker_id,
		event_types: [RequestEventType.APPROVED_ABSENCE, RequestEventType.HOLIDAY],
		month: DateTime.now().month,
		year: DateTime.now().year,
		...getTimezone(),
	};

	// Modals
	const [requestAndSickListingModal, setRequestAndSickListingModal] = useState<{
		isOpen: boolean;
		title: string;
		widgetId: string;
		team: boolean;
		payload: any;
	}>({ isOpen: false, title: '', widgetId: '', team: false, payload });

	useEffect(() => {
		setIsLoading(true);
		axios
			.post('/api/dashboard/events/count', payload)
			.then((res: any) => setValues(res.data))
			.catch(() => showToast('error', 'There was a problem with the Holidays This Month widget'))
			.finally(() => setIsLoading(false));
	}, []);

	const handleShow = (mode: 'team' | 'company') => {
		setRequestAndSickListingModal((prevState: any) => ({
			...prevState,
			isOpen: true,
			title: `Holidays This Month - ${titleCase(mode)}`,
			widgetId: props.widgetId,
			team: mode === 'team',
		}));
	};

	return (
		<>
			<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
				<div className='widget-header'>Holidays This Month</div>
				<div className='widget-content'>
					<div className='widget-plaques-container'>
						<div className='widget-plaque widget-plaque__team'>
							<div className='title'>My Team</div>
							<div className='stat'>
								{isLoading ? (
									'-'
								) : (
									<>
										{values.team}
										<span className='ps-1 fs-6'>day{Number(values.team) !== 1 ? 's' : ''}</span>
									</>
								)}
							</div>
						</div>
						<div className='widget-plaque widget-plaque__company'>
							<div className='title'>Company</div>
							<div className='stat'>
								{isLoading ? (
									'-'
								) : (
									<>
										{values.company}
										<span className='ps-1 fs-6'>day{Number(values.company) !== 1 ? 's' : ''}</span>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='widget-footer justify-content-end'>
					{permissionTo('read') && (
						<>
							<IonButton className='btn-alt' size='small' onClick={() => handleShow('team')}>
								Show Team
							</IonButton>
							<IonButton size='small' onClick={() => handleShow('company')}>
								Show Company
							</IonButton>
						</>
					)}
				</div>
			</div>

			<RequestAndSickListingModal
				isOpen={requestAndSickListingModal.isOpen}
				onClose={() => {
					setRequestAndSickListingModal((prevState: any) => ({
						...prevState,
						isOpen: false,
					}));
				}}
				title={requestAndSickListingModal.title}
				widgetId={requestAndSickListingModal.widgetId}
				team={requestAndSickListingModal.team}
				payload={requestAndSickListingModal.payload}
			/>
		</>
	);
};

export default HolidaysThisMonth;

import { IonCol } from '@ionic/react';
import { MouseEventHandler, useEffect, useState } from 'react';
import Loading from '../UI/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import './Video.scss';

interface Props {
	path: string;
	description: Array<string>;
	imageFunction?: MouseEventHandler<HTMLImageElement>;
	printMode?: boolean;
}

const Video: React.FC<Props> = (props: Props) => {
	const [loaded, setLoaded] = useState(false);
	const [imgSrc, setImgSrc] = useState<string>(props.path);
	let output: JSX.Element = <></>;

	const onImageLoaded = (e: any) => {
		setLoaded(true);
	};

	useEffect(() => {
		// Reset the image on path change
		setImgSrc(props.path);
		setLoaded(false);
	}, [props.path]);

	const structureJSX: JSX.Element = (
		<div className='photo'>
			<div className='photo-image-container'>
				{!loaded && !props.printMode && <Loading />}
				<div
					className='thumbnail'
					style={{ backgroundImage: `url(${imgSrc})` }}
					onClick={props?.imageFunction ? props.imageFunction : () => {}}
				>
					{!props.printMode && (
						<div className='play-icon'>
							<FontAwesomeIcon icon={faPlay} />
						</div>
					)}
				</div>
				<img src={props.path} alt='' style={{ display: 'none' }} onLoad={onImageLoaded} />
			</div>
			<ul className='photo-details'>
				<li>
					{props.description
						.map((item: string) => {
							if (item) item.trim();
							return item;
						})
						.filter(
							(item: string) => String(item).length > 0 && item !== null && item !== undefined
						)
						.join(' | ')}
				</li>
			</ul>
		</div>
	);

	if (!props.printMode) {
		output = <IonCol size={'4'}>{structureJSX}</IonCol>;
	} else {
		output = structureJSX;
	}

	return output;
};

export default Video;

import TitleBar from '../../../components/TitleBar/TitleBar';
import EditClientSubmenuArray from './EditClientSubmenuArray';
import Submenu from '../../../components/Menu/Submenu';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import { useParams } from 'react-router-dom';

const ClientCardIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { clientId }: any = useParams();

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<Submenu array={EditClientSubmenuArray(permissionTo, clientId)} />
			</div>
		</>
	);
};

export default ClientCardIndex;

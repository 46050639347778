import { useEffect, useState } from 'react';
import axios from '../../lib/axios';
import ErrorMessage from '../../components/Auth/ErrorMessage';
import useErrorHandler from '../../utils/custom-hooks/ErrorHandler';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import AuthButton from '../../components/Forms/AuthButton';
import { useSelector, useDispatch } from 'react-redux';
import { Logos, AuthStyles } from '../../components/Auth/Styling';
import { csrf } from '../../helpers/csrf';
import { publish } from '../../helpers/events';
import { setLoginFail } from '../../state/slices/appState';
import AuthFooter from '../../components/Auth/AuthFooter';

interface Props {
	onClose: Function;
}

const Login: React.FC<Props> = (props: Props) => {
	const [userEmail, setUserEmail] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const { error, showError } = useErrorHandler(null);

	let browserID = useSelector((state: any) => state.browserID.value);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setLoginFail());
	}, []);

	const validateLoginForm = (email: string, password: string, setError: Function) => {
		// Check for undefined or empty input fields
		if (!email || !password) {
			setError('Please enter a valid email/phone number and password.');
			return false;
		}

		// Validate email
		if (!isEmail(email) && !isMobilePhone(email)) {
			setError('Please enter a valid email address/phone number.');
			return false;
		}

		return true;
	};

	const handleLogin = async (event: any) => {
		event.preventDefault();
		if (validateLoginForm(userEmail, userPassword, showError)) {
			handleAuthentication();
		}
	};

	const handleLoginEnter = (event: any) => {
		if (event.key.toLowerCase() === 'enter') {
			handleLogin(event);
		}
	};

	const handleAuthentication = async () => {
		try {
			setLoading(true);
			await csrf();

			await axios.post('/login', { email: userEmail, password: userPassword });

			const userData = await axios.post('/api/user', {
				device_fingerprint: browserID,
			});

			const navData = await axios.get('/api/system/modules');

			setTimeout(() => {
				publish('login', true);
			}, 500);

			props.onClose({
				isAuthenticated: true,
				navData: navData.data,
				...userData.data,
			});
		} catch (err: any) {
			console.log(err);
			if (err.response.status === 401 && err.response.data.otp === 'required') {
				// Redirect to 2FA
				publish('2fa', true);
			} else {
				setLoading(false);
				showError('Log-in failed, please try again.');
			}
		}
	};

	return (
		<>
			<AuthStyles />
			<div className='form-auth'>
				<div className='form-inner rounded-3 card'>
					<div className='card-body p-md-5 mx-md-4'>
						<Logos />

						<p>Please log-in to your account</p>

						<div className='form-group mb-2'>
							<label htmlFor='email' className='form-label'>
								Username
							</label>
							<input
								type='text'
								className='form-control'
								placeholder='Phone number or email address'
								autoFocus
								disabled={loading}
								onChange={(e) => setUserEmail(e.target.value)}
								onKeyDown={(e) => handleLoginEnter(e)}
								value={userEmail}
							/>
						</div>

						<div className='form-group mb-4'>
							<label htmlFor='password' className='form-label'>
								Password
							</label>
							<input
								type='password'
								className='form-control'
								placeholder='Password'
								disabled={loading}
								onChange={(e) => setUserPassword(e.target.value)}
								onKeyDown={(e) => handleLoginEnter(e)}
								value={userPassword}
							/>
						</div>

						<AuthButton
							onClick={(e: any) => {
								handleLogin(e);
							}}
							btnType='submit'
							loading={loading}
							className='mb-3'
						>
							LOG IN
						</AuthButton>

						<a className='block text-right' href={'/forgotten-password'}>
							Forgotten your password?
						</a>
						<div style={{ clear: 'both' }}></div>

						{error && <ErrorMessage errorMessage={error.errorMessage} />}
					</div>
				</div>
			</div>
			<AuthFooter />
		</>
	);
};

export default Login;

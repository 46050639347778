import { useParams } from 'react-router-dom';
import SystemUsageIndex from '../../../Utilities/Company/SystemUsage/SystemUsageIndex';

const UsageIndex = () => {
	const { workerId }: any = useParams();
	const uid = 'utilities-system_usage';

	return (
		<SystemUsageIndex
			uid={uid}
			routeTitle={''}
			permissionTo={() => { }}
			usageMode='worker_tabs'
			workerId={workerId}
		/>
	);
};

export default UsageIndex;

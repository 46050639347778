import { useState } from 'react';
import { useParams } from 'react-router';
import Conversations from '../../../../components/Chat/Conversations/Conversations';

interface Props {}

const MessagesIndex: React.FC<Props> = (props: Props) => {
	const { workerId }: any = useParams();
	const [hasTitleBar, setHasTitleBar] = useState<boolean>(true);

	const showTitleBar = (value: boolean) => {
		setHasTitleBar(value);
	};

	return <Conversations convoListFunction={showTitleBar} workerId={workerId} readOnly={true} />;
};

export default MessagesIndex;

import { useEffect, useRef, useState } from 'react';
import { IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import DataGrid from '../../../../../components/DataGrid/DataGrid';
import axios from '../../../../../lib/axios';
import { DateTime } from 'luxon';
import { showToast } from '../../../../../lib/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loading from '../../../../../components/UI/Loading';

interface Props {
	uid: string;
	permissionTo: Function;
}

const TermsAndConditions = (props: Props) => {
	const gridRef: any = useRef<any>();
	const [tabMode, setTabMode] = useState<string>('terms_edit');
	const [tableData, setTableData] = useState<Array<any>>([]);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const [presentAlert] = useIonAlert();
	const editorToolbarOptions = {
		options: [
			'inline',
			'blockType',
			'fontSize',
			'list',
			'textAlign',
			'colorPicker',
			'emoji',
			'remove',
			'history',
		],
		inline: {
			options: ['bold', 'italic', 'underline'],
		},
		list: {
			options: ['unordered', 'ordered'],
		},
	};

	const columns: Array<any> = [
		{
			headerName: 'Terms and Conditions',
			field: 'summary',
			sortable: false,
			cellRenderer: (params: any) => {
				let output = params.value;
				if (output.length > 200) output = output.substring(0, 200).trim() + '...';
				return output;
			},
			flex: 1,
		},
		{
			headerName: 'Date Created',
			field: 'created_at',
			sortable: true,
			sort: 'desc',
			cellRenderer: (params: any) => {
				let output = '-';
				try {
					if (params.value) {
						output = DateTime.fromISO(params.value).toFormat('dd/MM/yyyy HH:mm');
					}
				} catch (err) {
					output = '-';
				}
				return output;
			},
		},
		{
			id: 'delete',
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 60,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					props.permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data._id)}
						/>
					)
				);
			},
		},
	];

	useEffect(() => {
		loadTerms();
	}, []);

	useEffect(() => {
		if (gridReady) {
			loadGridData();
		}
	}, [gridReady]);

	const loadTerms = (id: string = '') => {
		// Clear the editor's content
		setEditorState(EditorState.createEmpty());

		setIsLoading(true);

		// Fetch specific or latest terms content
		let url = '/api/utilities/styles/terms_and_conditions/';
		if (id.length > 0) {
			url += id;
		} else {
			url += 'latest';
		}

		axios
			.get(url)
			.then((res) => {
				if (res.data && res.data.content !== null) {
					// Convert back to an editor state object
					const convertedState = convertFromRaw(JSON.parse(res.data.content));
					const editorValue = EditorState.createWithContent(convertedState);

					// Set the editor's content
					setEditorState(editorValue);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const loadGridData = () => {
		setTableData([]);
		setSelectedRow(null);
		setGridLoading(true);

		// Reset the search
		gridRef.current.clearSearch();

		axios.get('/api/utilities/styles/terms_and_conditions').then((res) => {
			setTableData(res.data);
			setGridLoading(false);
		});
	};

	const onGridReady = () => {
		setGridReady(true);
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete') {
			params.node.setSelected(true);
			setSelectedRow(params.data);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete') {
			handleCellClicked(params);
			handleEditRow();
		}
	};

	const handleTabChange = (event: any = null, forceTab: string = '') => {
		let tab = null;

		if (forceTab.length === 0) {
			tab = event.target;
			if (!tab.classList.contains('subnav-item')) {
				tab = tab.closest('.subnav-item');
			}
		} else {
			tab = { id: forceTab };
		}

		switch (tab.id) {
			case 'terms_edit':
				setTableData([]);
				if (!selectedRow) loadTerms();
				setGridReady(false);
				break;
			case 'terms_list':
				setSelectedRow(null);
				if (gridReady) loadGridData();
				break;
		}

		setTabMode(tab.id);
	};

	const handleSaveTerms = () => {
		const contentState = editorState.getCurrentContent();
		const contentPlain = contentState.getPlainText();

		if (!contentPlain || contentPlain.length === 0) {
			showToast('error', 'Please enter your terms and conditions');
			return;
		}

		// Trim the summary
		let summary = String(contentPlain);
		if (summary.length > 200) summary = summary.substring(0, 200).trim() + '...';

		const payload = {
			summary: summary,
			content: JSON.stringify(convertToRaw(contentState)),
		};

		setIsLoading(true);
		axios.put('/api/utilities/styles/terms_and_conditions', payload).then(() => {
			showToast('saved');
			setIsLoading(false);
		});
	};

	const handleEditRow = () => {
		if (props.permissionTo('update')) {
			handleTabChange(null, 'terms_edit');
			loadTerms(selectedRow._id);
		} else {
			showToast('permission');
		}
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		axios
			.delete(`/api/utilities/styles/terms_and_conditions/${id}`)
			.then(() => {
				loadGridData();
				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	return (
		<div className={`component-${props.uid.replaceAll('.', '-')} p-0 flex-grow-container`}>
			<IonRow className='sub-nav-tabs'>
				<IonCol
					id='terms_edit'
					className={tabMode === 'terms_edit' ? 'active subnav-item' : 'subnav-item'}
					onClick={(e: any) => handleTabChange(e)}
				>
					<span>Edit Terms and Conditions</span>
				</IonCol>
				<IonCol
					id='terms_list'
					className={tabMode === 'terms_list' ? 'active subnav-item' : 'subnav-item'}
					onClick={(e: any) => handleTabChange(e)}
				>
					<span>Previous Edits</span>
				</IonCol>
			</IonRow>

			{tabMode === 'terms_edit' && (
				<>
					{isLoading && <Loading overlay={true} />}
					<Editor
						editorState={editorState}
						onEditorStateChange={setEditorState}
						toolbar={editorToolbarOptions}
						wrapperClassName='wysiwyg-wrapper-class'
						editorClassName='wysiwyg-editor-class'
						toolbarClassName='wysiwyg-toolbar-class'
					/>

					<IonRow>
						<IonCol size='12' className='text-right'>
							{props.permissionTo('update') && (
								<IonButton onClick={handleSaveTerms} color='primary' disabled={isLoading}>
									Save as New Terms
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				</>
			)}

			{tabMode === 'terms_list' && (
				<div style={{ flex: 1, overflow: 'hidden' }}>
					<DataGrid
						onGridReady={onGridReady}
						ref={gridRef}
						title={'Previous Edits'}
						cols={columns}
						data={tableData}
						cellClickedFunction={handleCellClicked}
						cellDoubleClickedFunction={handleCellDoubleClicked}
						rowCursorPointer={true}
						extraFooter={
							<IonRow>
								<IonCol size='12' className='text-right pe-0'>
									{props.permissionTo('update') && (
										<IonButton
											onClick={handleEditRow}
											color='primary'
											disabled={!selectedRow || gridLoading}
										>
											Edit Item
										</IonButton>
									)}
								</IonCol>
							</IonRow>
						}
					/>
				</div>
			)}
		</div>
	);
};

export default TermsAndConditions;

import React, { createContext, useReducer } from 'react';
import { hatReducer } from './hat-reducer';
import { HATState } from './hat-types';
import { Actions as Action } from './actions';

interface ProviderProps {
	children: React.ReactNode;
}

interface ContextProvider {
	state: HATState;
	dispatch: React.Dispatch<Action>;
}

const initialState: HATState = {
	menuCounts: {
		requests: 0,
		sick: 0,
	},
};

const contextProps = {
	state: initialState,
	dispatch: () => null,
};

export const HATContext = createContext<ContextProvider>(contextProps);

const HATProvider = ({ children }: ProviderProps) => {
	const [state, dispatch] = useReducer(hatReducer, initialState);

	return <HATContext.Provider value={{ state, dispatch }}>{children}</HATContext.Provider>;
};

export default HATProvider;

import { useState, useCallback, useRef, useEffect, useContext } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import BackButton from '../../../../components/TitleBar/BackButton';
import useWorkerReport from '../useWorkerReport';
import ExtraFooterControls from '../components/ExtraFooterControls';
import { ReportContextType, reportContext } from '../reportContext';
import { TotalCol, TotalColTitle } from '../report-types';

interface Props {
	parentRef: any;
	title: string;
	uid: string;
	permissionTo: Function;
}

const PhoneUsageReportIndex: React.FC<Props> = (props: Props) => {
	const reportCtx: ReportContextType = useContext(reportContext);
	const workerReport = useWorkerReport();
	const [workerId, setWorkerId] = useState<string>('');
	const [workerName, setWorkerName] = useState<string>('');
	const [gridReady, setGridReady] = useState<boolean>(false);
	const gridRef: any = useRef<any>();
	const calcTotalCols: TotalCol[] = [{ id: 'seconds_string' }];
	const totalColsTitles: TotalColTitle[] = [
		{ id: 'app_name', title: 'All Apps' },
		{ id: 'worker_name', title: 'All Workers' },
	];
	const colData: Array<any> = [
		{ headerName: 'Worker', field: 'worker_name', flex: 1 },
		{ headerName: 'Security', field: 'security_role', flex: 1 },
		{ headerName: 'Status', field: 'type_of_engagement', flex: 1 },
		{ headerName: 'Application', field: 'app_name', flex: 1 },
		{
			headerName: 'Total Time',
			field: 'seconds_string',
			headerClass: 'ag-right-aligned-header',
			cellClass: 'duration-type',
			type: 'rightAligned',
			valueFormatter: (params: any) => workerReport.formatTime(params.value),
			sort: 'desc',
			flex: 1,
		},
	];

	useEffect(() => {
		if (gridRef.current && gridReady === true) {
			if (workerId === '') {
				gridRef.current.setColumnsVisible(['app_name'], false);
				gridRef.current.setColumnsVisible(
					['worker_name', 'security_role', 'type_of_engagement'],
					true
				);
			} else {
				gridRef.current.setColumnsVisible(['app_name'], true);
				gridRef.current.setColumnsVisible(
					['worker_name', 'security_role', 'type_of_engagement'],
					false
				);
			}
			gridRef.current.sizeToFit();
		}
	}, [workerId, gridReady]);

	const rowClickedFunction = useCallback((event: any) => {
		if (event.data._id !== undefined) {
			setWorkerId(event.data._id);
			setWorkerName(event.data.worker_name);
		}
	}, []);

	return (
		<div className='flex-grow-container h-100'>
			<DataGrid
				ref={gridRef}
				onGridReady={() => setGridReady(true)}
				useSearch={false}
				title={
					workerId === '' ? (
						props.title
					) : (
						<div className='report-header-back'>
							<BackButton
								onClick={() => {
									setWorkerId('');
									setWorkerName('');
								}}
							/>
							<div className='report-header-title'>
								{props.title} - {workerName}
							</div>
						</div>
					)
				}
				cols={colData}
				dataSource={{
					url: gridReady
						? `/api/workers/workers_list/worker_card/${props.uid.split('.').pop()}`
						: '',
					method: 'POST',
					data: {
						worker_id: workerId,
						start: workerReport.startDate,
						end: workerReport.endDate,
						roles: workerReport.chosenRoles.map((value) => value.value),
						payment_method: workerReport.chosenPaymentMethod?.value,
					},
				}}
				excelStyles={workerReport.excelStyles}
				exportExcelButton={true}
				rowClickedFunction={rowClickedFunction}
				totalsRow={workerReport.totalRow(calcTotalCols, totalColsTitles)}
				rowCursorPointer={!workerId}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: workerReport.setMonthYear,
					initialLoad: false,
					defaultAnnual: false,
				}}
				extraFooter={
					<ExtraFooterControls
						roles={reportCtx.roles}
						chosenRoles={workerReport.chosenRoles}
						setChosenRoles={workerReport.setChosenRoles}
						paymentMethods={reportCtx.paymentMethods}
						chosenPaymentMethod={workerReport.chosenPaymentMethod}
						setChosenPaymentMethod={workerReport.setChosenPaymentMethod}
					/>
				}
			/>
		</div>
	);
};

export default PhoneUsageReportIndex;

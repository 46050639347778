import { EventType, ResourceMode, SchedulingState } from './../scheduling-types';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTrash, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';

const useCalendarContextMenu = (
	permissionTo: Function,
	contextMenuRightClickedEvent: any,
	state: SchedulingState,
	events: Array<any>,
	resources: Array<any>,
	callbacks: any
): any => {
	let contextMenuOptions: Array<any> = [];
	if (!contextMenuRightClickedEvent || !contextMenuRightClickedEvent.type) return [];

	// Menu options based on what type of event was fired
	switch (contextMenuRightClickedEvent.type) {
		case 'onCellRightClick':
			contextMenuOptions = [
				{
					text: (
						<>
							<FontAwesomeIcon icon={faMagnifyingGlass} className='pe-2' />
							View Day Details
						</>
					),
					value: 'view_details',
					action: () => {
						let selectedDate = DateTime.fromJSDate(contextMenuRightClickedEvent.date);

						// Get a copy of the events for this day and resource in time order ascending
						let dayEvents = cloneDeep(events);
						dayEvents = dayEvents
							.filter(
								(event: any) =>
									event.start.startOf('day').valueOf() === selectedDate.startOf('day').valueOf() &&
									event.resource === contextMenuRightClickedEvent.resource
							)
							.map((event: any) => {
								event.editable = event.enumEventType === EventType.APPOINTMENT;
								return event;
							})
							.sort(function (a: any, b: any) {
								return a.start - b.start;
							});

						// Set the selected date to the start time of the first event
						if (dayEvents.length > 0) {
							const eventDate: DateTime = dayEvents[0].start;
							selectedDate = selectedDate.set({ hour: eventDate.hour, minute: eventDate.minute });
						}

						// Get the selected resource
						let resource: any = null;
						switch (state.resourceMode) {
							case ResourceMode.SKILLS:
								resources.every((item: any) => {
									let child: any = item.children.filter(
										(skill: any) => skill.id === contextMenuRightClickedEvent.resource
									);

									if (Array.isArray(child) && child.length === 1 && child[0].hasOwnProperty('id')) {
										resource = child[0];
										resource.parentName = item.name;

										// Break the loop
										return false;
									} else {
										// Keep the loop running
										return true;
									}
								});
								break;
							default:
								resource = resources.filter(
									(item: any) => item.id === contextMenuRightClickedEvent.resource
								)[0];
								break;
						}

						callbacks.setDayScheduleModal({
							isOpen: true,
							selectedDate,
							events: dayEvents,
							resource: resource,
						});
					},
					hasPermission: permissionTo('update'),
				},
			];
			break;
		case 'onEventRightClick':
			contextMenuOptions = [
				{
					text: (
						<>
							<FontAwesomeIcon icon={faTrash} className='pe-2' />
							Delete Single Appointment
						</>
					),
					value: 'delete_appointment_single',
					action: () => {
						callbacks.handleDeleteEvent(
							contextMenuRightClickedEvent.event.request_id,
							contextMenuRightClickedEvent.resource,
							false,
							null,
							null,
							contextMenuRightClickedEvent.event.id,
							true
						);
					},
					hasPermission: permissionTo('delete'),
				},
				{
					text: (
						<>
							<FontAwesomeIcon icon={faTrashCan} className='pe-2' />
							Delete Series
						</>
					),
					value: 'delete_appointment',
					action: () => {
						callbacks.handleDeleteEvent(
							contextMenuRightClickedEvent.event.request_id,
							contextMenuRightClickedEvent.resource
						);
					},
					hasPermission: permissionTo('delete'),
				},
			];
			break;
	}

	return contextMenuOptions;
};

export default useCalendarContextMenu;

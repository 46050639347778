import Requests from './Requests';
import { RequestStatus } from './request-types';

/**
 * Note how we use a unique key on the Requests component. This tell React we want to re-render the
 * component because it has different properties and therefore different behaviours, etc. Otherwise
 * React just sees the same component in the same part of the DOM and therefore will do nothing
 * when switching between these pages.
 */
export const requestsArray: Array<any> = [
	{
		title: 'Requests',
		page: Requests,
		active: true,
		pageData: { key: 'requests', componentMode: [RequestStatus.REQUESTED] },
	},
	{
		title: 'History',
		page: Requests,
		active: false,
		pageData: { key: 'history', componentMode: [RequestStatus.APPROVED, RequestStatus.DECLINED] },
	},
];

import { useState, useEffect } from 'react';
import { IonCard } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import axios from '../../../../lib/axios';
import Form from '../../../../components/Forms/Form';
import { db2Form } from '../../../../api/forms';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';

const ContractualDates: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const [items, setItems] = useState<Array<any>>([]);
	const [endpointID, setEndpointID] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		let formData: any = [
			{
				title: 'Contractual Dates',
				type: 'title',
				style: 'alpha',
			},
			{
				title: 'Set the rules for estimating and invoicing jobs',
				type: 'title',
				style: 'beta',
			},
			{
				type: 'number',
				title: 'Estimate Expiry Days',
				db: ['settings', 'estimate_expiry_days'],
			},
			{
				type: 'number',
				title: 'Invoice Days Until Due',
				db: ['settings', 'invoice_days_until_due'],
			},
		];

		axios
			.get('/api/utilities/company_details/settings')
			.then((res) => res.data[0])
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
				setEndpointID(dbData._id);
			})
			.catch((err) => {
				showToast('error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonCard className='table-card'>
				{isLoading && <Loading height={140} />}
				{!isLoading && items && items.length > 0 && (
					<div className='utility-form-padding'>
						<Form
							array={items}
							permissionTo={permissionTo}
							forceEdit={permissionTo('update')}
							noButton={true}
							endpoint={'/api/utilities/company_details/settings/'}
							endpointID={endpointID}
						/>
					</div>
				)}
			</IonCard>
		</>
	);
};

export default ContractualDates;

import { IonCol } from '@ionic/react';
import { MouseEventHandler, useEffect, useState } from 'react';
import FileIcon from '../Icons/FileIcon';
import { MediaOutputType } from '../../types/media-types';
import './Video.scss';

interface Props {
	path: string;
	fileName?: string;
	description: Array<string>;
	imageFunction?: MouseEventHandler<HTMLImageElement>;
	printMode?: boolean;
	outputMode?: MediaOutputType;
}

const File: React.FC<Props> = (props: Props) => {
	const [imgSrc, setImgSrc] = useState<string>(props.path);
	let output: JSX.Element = <></>;
	let structureJSX: JSX.Element = <></>;

	useEffect(() => {
		// Reset the image on path change
		setImgSrc(props.path);
	}, [props.path]);

	// Output type
	switch (props.outputMode) {
		case 'job-timeline':
			structureJSX = (
				<>
					<strong>File: </strong>
					<a className='prop-link' href={props.path} download={imgSrc}>
						{!props.printMode && <FileIcon height={'20px'} filename={imgSrc} />}
						<span className={!props.printMode ? 'ps-1' : ''}>
							{props.fileName ? props.fileName : 'Download file'}
						</span>
					</a>
				</>
			);
			break;
		default:
			structureJSX = (
				<div className='photo'>
					<div className='photo-image-container'>
						<a href={props.path} download={imgSrc}>
							<FileIcon height={'100%'} filename={imgSrc} />
						</a>
					</div>
					<ul className='photo-details'>
						<li>
							{props.description
								.map((item: string) => {
									if (item) item.trim();
									return item;
								})
								.filter(
									(item: string) => String(item).length > 0 && item !== null && item !== undefined
								)
								.join(' | ')}
						</li>
					</ul>
				</div>
			);
			break;
	}

	if (!props.printMode) {
		output = <IonCol size={'4'}>{structureJSX}</IonCol>;
	} else {
		output = structureJSX;
	}

	return output;
};

export default File;

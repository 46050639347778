import { createContext } from "react";

import useWebSocketHandler from '../utils/custom-hooks/WebSocketHandler';

export const webSocketContext = createContext({});

const { Provider } = webSocketContext;

const WebSocketContext = ({ children, ...props }: any) => {
	const { isConnected, sendMessage } =
		useWebSocketHandler();

	return <Provider value={{ isConnected, sendMessage }}>{children}</Provider>;
};

export default WebSocketContext;
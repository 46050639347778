import debounce from 'lodash.debounce';
import axios from '../../lib/axios';

const WorkersOptions = debounce((inputValue: string, callback: any, leaders?: boolean) => {
	let payload: any = { name: inputValue };
	if (leaders === true) payload.leaders = true;

	axios({
		method: 'post',
		url: '/api/workers/workers_list',
		data: payload,
	}).then((res) => {
		const options = res.data
			.sort((a: any, b: any) => {
				return a.last_name > b.last_name ? 1 : -1;
			})
			.map((r: any) => {
				return { label: r.first_name + ' ' + r.last_name, value: r.id };
			});

		callback(options);
	});
}, 600);

export default WorkersOptions;

import { InvoiceItem } from '../invoice-types';
import { VatRate } from '../../shared/estimates-invoices/types';
import { useContext, useEffect, useRef, useState } from 'react';
import { InvoiceContext } from '../InvoicesProvider';
import { enforceDecimalLimit } from '../../../../lib/functions';
import { ActionType } from '../actions';
import debounce from 'lodash.debounce';
import axios from '../../../../lib/axios';
import { calculateInvoiceAmount } from '../invoice-functions';
import { CatalogueItem } from '../../../../interfaces/Utilities/CatalogueItem';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';
import ItemRowStructure from '../../shared/estimates-invoices/ItemRowStructure';
import { showToast } from '../../../../lib/toast';

interface Props {
	index: number;
	item: InvoiceItem;
	catalogueItems: any;
	vatRates: VatRate[];
	isNew: boolean;
	onDeleteRow: Function;
	loadCatalogueItems: Function;
}

const ItemRow: React.FC<Props> = ({
	index,
	item,
	catalogueItems,
	vatRates,
	isNew,
	onDeleteRow,
	loadCatalogueItems,
}) => {
	const { state, dispatch } = useContext(InvoiceContext);
	const [amountSpinner, setAmountSpinner] = useState<boolean>(false);
	const [lastInputField, setLastInputField] = useState<string | null>(null);

	// Use a local state for responsive user inputs (then debounce to main state)
	let localStateItem: InvoiceItem = {
		_uid: item._uid,
		item: '',
		description: '',
		percentageComplete: 100,
		percentageCompleted: 0,
		quantity: 0,
		price: 0,
		discount: 0,
		taxRateId: item.taxRateId,
		amount: 0,
	};

	if (!isNew) localStateItem = { ...localStateItem, ...item };

	const [localState, setLocalState] = useState(localStateItem);
	const [localStatePrev, setLocalStatePrev] = useState(localStateItem);

	// Fast acting function to manage local state
	const onItemInput = (event: any, field: string, index: number) => {
		let value: any = null;
		setLastInputField(field);

		// Enforce decimal limits
		switch (field) {
			case 'percentageComplete':
			case 'quantity':
			case 'price':
			case 'discount':
				value = enforceDecimalLimit(event.target.value, 2);
				break;
			case 'item':
			case 'taxRateId':
				if (event) {
					value = event.value;
				} else {
					value = '';
				}
				break;
			default:
				value = event.target.value;
				break;
		}

		if (field === 'percentageComplete') {
			if (Number(value) + Number(localStatePrev.percentageCompleted) > 100) {
				showToast(
					'error',
					`Please enter a percentage complete lower than ${
						100 - Number(localStatePrev.percentageCompleted)
					}%`
				);
				return;
			}
		}

		setLocalStatePrev(localState);
		setLocalState((prevState: any) => {
			const newValue: any = {};
			newValue[field] = value;
			return { ...prevState, ...newValue };
		});
	};

	// De-bounced function to update the main state
	const onItemChange = useRef(
		debounce((field: string, index: number, state: any, localState: any) => {
			dispatch({ type: ActionType.UPDATE_ITEM_ROW, payload: { index, localState } });

			// Calculate row amount total once the overall state has bounced
			switch (field) {
				case 'percentageComplete':
				case 'quantity':
				case 'price':
				case 'discount':
				case 'taxRateId':
					calculateRowAmount(state, localState);
					break;
			}
		}, 600)
	).current;

	const onSelectChange = (index: number, newValue: any, field: string) => {
		let payload: any = { index, localState };
		const update: any = {};
		update[field] = newValue ? newValue.value : '';
		payload = { ...payload, localState: { ...localState, ...update } };
		dispatch({ type: ActionType.UPDATE_ITEM_ROW, payload: payload });

		if (field === 'taxRateId') calculateRowAmount(state, payload.localState);
	};

	const itemChangeUpdateRow = (newValue: any) => {
		// Update this row based on the item selected from the catalogue
		if (!newValue) return false;
		const catalogueData: CatalogueItem = newValue.data;

		setLocalState((prevState: any) => {
			return {
				...prevState,
				price: currencyFormatter({ value: catalogueData.price }, true),
				description: catalogueData.description,
				taxRateId: catalogueData.tax_rate_id,
			};
		});
	};

	const calculateRowAmount = (state: any, localState: any) => {
		// Parse the tax state
		const amountsAre: any = state.invoiceData?.amountsAre;
		let taxExclusive: boolean = false;
		if (Array.isArray(amountsAre)) taxExclusive = amountsAre[0].value === 'exclusive';

		const itemsParsed: any = {
			percentage_complete: Number(localState.percentageComplete),
			quantity: Number(localState.quantity),
			price: Number(localState.price),
			discount: Number(localState.discount),
			tax_rate_id: localState.taxRateId,
		};

		// Create the calculation payload
		const payload: any = {
			tax_exclusive: taxExclusive,
			items: [itemsParsed],
		};

		setAmountSpinner(true);

		axios
			.post('/api/jobs/items/calculate', payload)
			.then((res: any) => {
				// Update this row's amount
				let rowAmount = 0;
				if (res.data.items.length > 0) rowAmount = Number(res.data.items[0].total);
				setLocalState((prevState: any) => {
					return { ...prevState, amount: rowAmount };
				});

				// Update the invoice's totals
				calculateInvoiceAmount(taxExclusive, state.items, dispatch, setAmountSpinner);
			})
			.catch(() => {
				setAmountSpinner(false);
			});
	};

	useEffect(() => {
		// Show the spinner if the row state has changed
		switch (lastInputField) {
			case 'percentageComplete':
			case 'quantity':
			case 'price':
			case 'discount':
			case 'taxRateId':
				if (localState[lastInputField] !== localStatePrev[lastInputField]) {
					// This gives us immediate feedback while waiting for the state bounce
					setAmountSpinner(true);
				}
				break;
		}

		// Reset last input field
		setLastInputField(null);
	}, [localState]);

	// This component mostly passes-through this page's data and logic to build the row structure
	return (
		<ItemRowStructure
			rowType='invoice'
			state={state}
			localState={localState}
			loadCatalogueItems={loadCatalogueItems}
			onItemInput={onItemInput}
			onItemChange={onItemChange}
			onSelectChange={onSelectChange}
			itemChangeUpdateRow={itemChangeUpdateRow}
			onDeleteRow={onDeleteRow}
			index={index}
			catalogueItems={catalogueItems}
			vatRates={vatRates}
			amountSpinner={amountSpinner}
		/>
	);
};

export default ItemRow;

import { IonRow, IonCol, IonLabel } from '@ionic/react';
import { useContext, useState, useRef, useEffect } from 'react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';

interface Props {
	onChange?: Function;
}

const ListFilterAddInvoice: React.FC<Props> = ({ onChange }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [statuses, setStatuses] = useState<any>();
	const [workers, setWorkers] = useState<any>();

	const filterByStatusRef = useRef<any>();
	const filterByIssuedByRef = useRef<any>();
	const [filterStatus, setFilterStatus] = useState<any>(null);
	const [filterStatusDefault, setFilterStatusDefault] = useState<any>(null);
	const [filterIssuedBy, setFilterIssuedBy] = useState<any>({ label: '', value: '' });

	useEffect(() => {
		moduleCtx.getJobStatusOptions().then((res: any) => {
			setStatuses(res);

			// Set the default option
			if (filterByStatusRef.current) {
				const _filterStatusDefault = {
					label: '',
					value: res.map((st: any) => st.value).filter((st: string) => st !== 'completed'),
				};
				setFilterStatusDefault(_filterStatusDefault);
				setFilterStatus(_filterStatusDefault);
			}
		});

		moduleCtx.getWorkersOptions().then((res: any) => {
			setWorkers(res);
		});
	}, []);

	useEffect(() => {
		if (filterStatus !== null) applyFilters();
	}, [filterStatus, filterIssuedBy]);

	const setFilters = (newValue: any, filterName: string) => {
		switch (filterName) {
			case 'job_status':
				if (!newValue) {
					setFilterStatus(filterStatusDefault);
				} else {
					if (newValue.value !== filterStatus.value) {
						setFilterStatus({ label: newValue.label, value: [newValue.value] });
					}
				}
				break;
			case 'worker_id':
				setFilterIssuedBy(newValue);
				break;
		}
	};

	const applyFilters = () => {
		let label: string = 'All';
		let payload: any = { job_status: '', worker_id: '' };

		if (filterStatus.value) {
			if (filterStatus.value.length === 1) label = filterStatus.label;
			payload.job_status = filterStatus.value;
		}

		if (filterIssuedBy) payload.worker_id = filterIssuedBy.value;

		if (onChange) onChange(label, payload);
	};

	return (
		<div className='d-flex w-100 align-items-center'>
			<div className='pe-3 text-nowrap'>
				<IonLabel>Job Status</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={statuses}
					onChange={(newValue: any) => setFilters(newValue, 'job_status')}
					forwardRef={filterByStatusRef}
					isClearable={true}
				/>
			</div>
			<div className='ps-4 pe-3 text-nowrap'>
				<IonLabel>Issued By</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={workers}
					onChange={(newValue: any) => setFilters(newValue, 'worker_id')}
					forwardRef={filterByIssuedByRef}
					isClearable={true}
				/>
			</div>
		</div>
	);
};

export default ListFilterAddInvoice;

import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Subnav from '../../../../components/Menu/Subnav';
import EditTrackingTabsArray from './EditTrackingTabsArray';
import DateProvider from '../../../../contexts/DateContext';

interface Props {
	parentRef: any;
	permissionTo: Function;
}

const TrackingIndex: React.FC<Props> = (props: Props) => {
	const [subnav, setSubnav] = useState<Array<any>>([]);
	const location = useLocation();
	let history = useHistory();

	useEffect(() => {
		// Switch to tab if required
		let jumpToTab: string = '';
		if (location && location.search) {
			const urlParams = new URLSearchParams(location.search);
			if (urlParams.get('tab')) {
				jumpToTab = String(urlParams.get('tab'));

				// Remove the query parameter
				urlParams.delete('tab');
				history.replace({
					search: urlParams.toString(),
				});
			}
		}

		let array = EditTrackingTabsArray(props.permissionTo).map((item: any) => {
			if (jumpToTab && item.id === jumpToTab) {
				item.active = true;
			} else {
				item.active = false;
			}
			return item;
		});

		// Select the first item as the default if no tab specified in the URL
		if (array.every((element: any) => element.active === false)) array[0].active = true;

		setSubnav(array);
	}, []);

	return <>
		<DateProvider>
			{subnav.length > 0 && <Subnav array={subnav} parentRef={props.parentRef} />}
		</DateProvider>
	</>;
}

export default TrackingIndex;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonCol,
	IonRow,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import Loading from '../../../components/UI/Loading';
import React from 'react';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { dateFormatter } from '../../../helpers/dateFormatter';
import chroma from 'chroma-js';
import axios from '../../../lib/axios';
import { useHistory } from 'react-router';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';

interface RequestAndSickListingModalProps {
	isOpen: boolean;
	onClose: Function;
	title: string;
	widgetId: string;
	team: boolean;
	payload: any;
}

const RequestAndSickListingModal: React.FC<RequestAndSickListingModalProps> = ({
	isOpen,
	onClose,
	title,
	widgetId,
	team,
	payload,
}) => {
	const gridRef: any = useRef<any>();
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<any>>([]);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [columns, setColumns] = useState<Array<any>>([]);
	const permissionToRequests = usePermissionTo('workers.holidays_and_absences.requests');
	const permissionToSick = usePermissionTo('workers.holidays_and_absences.sick');
	let history = useHistory();

	useEffect(() => {
		if (gridReady) loadGridData();
	}, [gridReady]);

	const onDidPresent = () => {
		switch (widgetId) {
			case 'dashboard.company.holidays_this_month':
				setColumns([
					{
						field: 'attendees',
						headerName: 'Worker Name',
						cellStyle: (params: any) => {
							const bgColour = params.node.data.attendees[0].role.colour;
							const fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';
							return { color: fontColour, backgroundColor: '#' + bgColour };
						},
						getQuickFilterText: (params: any) => params.node.data.attendees[0].label,
						cellRenderer: (params: any) => params.node.data.attendees[0].label,
					},
					{
						field: 'type',
						flex: 1,
					},
					{
						field: 'requested_date',
						headerName: 'Requested',
						width: 130,
						suppressSizeToFit: true,
						sort: 'desc',
						valueFormatter: dateFormatter,
					},
					{
						field: 'start',
						width: 165,
						suppressSizeToFit: true,
						valueFormatter: (params: any) => dateFormatter(params, true),
					},
					{
						field: 'end',
						width: 165,
						suppressSizeToFit: true,
						valueFormatter: (params: any) => dateFormatter(params, true),
					},
					{
						field: 'period',
						cellRenderer: (params: any) =>
							`${params.node.data.period} working day${
								Number(params.node.data.period) !== 1 ? 's' : ''
							}`,
						flex: 1,
					},
				]);
				break;
			case 'dashboard.company.sickness_today':
				setColumns([
					{
						field: 'attendees',
						cellStyle: (params: any) => {
							const bgColour = params.node.data.attendees[0].role.colour;
							const fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';
							return { color: fontColour, backgroundColor: '#' + bgColour };
						},
						getQuickFilterText: (params: any) => params.node.data.attendees[0].label,
						cellRenderer: (params: any) => params.node.data.attendees[0].label,
						flex: 1,
					},
					{ field: 'description', headerName: 'Sickness Reason', flex: 1 },
					{
						field: 'requested_date',
						headerName: 'Requested',
						sort: 'desc',
						valueFormatter: dateFormatter,
						flex: 1,
					},
					{ field: 'start', valueFormatter: dateFormatter, flex: 1 },
					{ field: 'end', valueFormatter: dateFormatter, flex: 1 },
					{
						field: 'period',
						cellRenderer: (params: any) =>
							`${params.node.data.period} working day${
								Number(params.node.data.period) !== 1 ? 's' : ''
							}`,
						flex: 1,
					},
				]);
				break;
		}
	};

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);

		let reqPayload: any = {
			dashboard: true,
			...payload,
		};

		if (team === true) reqPayload.team = true;

		axios
			.post('/api/workers/holidays_and_absences/requests', reqPayload)
			.then((res: any) => {
				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleClose = () => {
		setGridReady(false);
		setData([]);
		onClose();
	};

	const handleGoTo = () => {
		switch (widgetId) {
			case 'dashboard.company.holidays_this_month':
				history.push('/workers/holidays_and_absences#requests');
				break;
			case 'dashboard.company.sickness_today':
				history.push('/workers/holidays_and_absences#sick');
				break;
		}
	};

	return (
		<IonModal
			style={{ '--width': '70vw', '--height': '70vh' }}
			isOpen={isOpen}
			onDidPresent={() => onDidPresent()}
			onDidDismiss={() => handleClose()}
			className='request-and-sick-listing-modal'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{title}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => handleClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{gridLoading && <Loading overlay={true} />}
				<DataGrid
					ref={gridRef}
					onGridReady={() => setGridReady(true)}
					cols={columns}
					data={data}
					title=''
					rowCursorPointer={false}
					useSearch={false}
					suppressRowHoverHighlight={true}
				/>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size={'12'} className='text-right'>
							<IonButton color='secondary' onClick={() => handleClose()} disabled={gridLoading}>
								Close
							</IonButton>
							{widgetId === 'dashboard.company.holidays_this_month' &&
								permissionToRequests('read') && (
									<IonButton onClick={() => handleGoTo()} disabled={gridLoading}>
										Go To Holidays and Absences
									</IonButton>
								)}
							{widgetId === 'dashboard.company.sickness_today' && permissionToSick('read') && (
								<IonButton onClick={() => handleGoTo()} disabled={gridLoading}>
									Go To Sicknesses
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default RequestAndSickListingModal;

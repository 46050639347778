import { useState, useEffect, useContext } from 'react';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Submenu from '../../../components/Menu/Submenu';
import HatTabsArray from './HatTabsArray';
import { HATContext } from './HATProvider';
import { updateSubmenuBadgeCounts } from './hat-functions';

const HATLayout: React.FC = () => {
	const { state, dispatch } = useContext(HATContext);
	const [submenu, setSubmenu] = useState<Array<any>>([]);

	// Initial load of submenu badge counts
	useEffect(() => {
		updateSubmenuBadgeCounts(dispatch);
	}, []);

	useEffect(() => {
		let array = HatTabsArray(state.menuCounts).map((item: any) => {
			item.active = false;
			return item;
		});

		array[0].active = true;
		setSubmenu(array);
	}, [state]);

	return (
		<>
			<TitleBar title='Workers - Holidays and Absences' />
			{submenu.length > 0 && <Submenu array={submenu} />}
		</>
	);
};

export default HATLayout;

import { useState } from 'react';
import { IonCard } from '@ionic/react';
import { useHistory } from 'react-router';
import Subnav from '../../../components/Menu/Subnav';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Loading from '../../../components/UI/Loading';
import NewJobTabsArray from './NewJobTabsArray';
import { processForm } from '../../../api/forms';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { mandatoryFieldChecks } from '../../../components/Forms/FormValidation';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';

const AddJob: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	let history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);

	const createJob = (e: any, formData: any) => {
		saveForm(formData, 'create');
	};

	const nextFunction = (e: any, formData: any, nextAction: string) => {
		saveForm(formData, nextAction);
	};

	const saveForm = (formData: any, action: string) => {
		// Check if any of the form's fields are mandatory and stop the form they're empty
		if (mandatoryFieldsEmpty(formData) === true) return;

		// Strip the form data down to DB data fields only
		const fd: any = processForm(formData);

		// Check the client ID
		if (fd.client.hasOwnProperty('id') && (!fd.client.id || Array.isArray(fd.client.id))) {
			fd.client.id = undefined;
		}

		setLoading(true);

		axios
			.put('/api/jobs/jobs_list/job_card', fd)
			.then((res: any) => {
				switch (action) {
					case 'create':
						history.replace('/jobs/jobs_list');
						break;
					case 'next':
						history.replace(`/jobs/jobs_list/job_card/${res.data._id}?tab=job_targets_and_skills`);
						break;
					case 'createEstimate':
						history.replace(`/jobs/estimates_list/add_new/${res.data._id}`);
						break;
				}
			})
			.catch((err: any) => {
				setLoading(false);
				showToast('error', err.response.data.message);
			});
	};

	function mandatoryFieldsEmpty(items: any) {
		let hasEmptyFields = false;

		if (
			items.some((item: any) => item.hasOwnProperty('isMandatory') && item.isMandatory === true)
		) {
			const checks = mandatoryFieldChecks(items);

			if (checks.formValid !== true) {
				showToast('error', checks.errorMessage);
				hasEmptyFields = true;
			}
		}

		return hasEmptyFields;
	}

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card'>
					{loading && <Loading overlay={true} />}
					<Subnav array={NewJobTabsArray(permissionTo, createJob, nextFunction)} disabled={true} />
				</IonCard>
			</div>
		</>
	);
};

export default AddJob;

import { useState, useEffect } from 'react';
import { trainingArray } from './trainingArray';
import Subnav from '../../../../components/Menu/Subnav';

const TrainingEventIndex: React.FC = () => {
	const [subnav, setSubnav] = useState<Array<any>>([]);

	useEffect(() => {
		let array = trainingArray.map((item) => {
			item.active = false;
			return item;
		});
		array[0].active = true;
		setSubnav(array);
	}, []);

	return <>{subnav.length > 0 && <Subnav array={subnav} />}</>;
};

export default TrainingEventIndex;

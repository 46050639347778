import React, { createContext, useReducer } from 'react';
import { timesheetsReducer } from './timesheets-reducer';
import { TimesheetsState } from './timesheets-types';
import { Actions as Action } from './actions';
import { DateTime } from 'luxon';

interface ProviderProps {
	children: React.ReactNode;
}

interface ContextProvider {
	state: TimesheetsState;
	dispatch: React.Dispatch<Action>;
}

const initialState: TimesheetsState = {
	year: DateTime.now().year,
	weekEnding: DateTime.now().endOf('week'),
	showMyTeam: true,
	workerReportMonth: DateTime.now().month,
	workerReportYear: DateTime.now().year,
};

const contextProps = {
	state: initialState,
	dispatch: () => null,
};

export const TimesheetsContext = createContext<ContextProvider>(contextProps);

const TimesheetsProvider = ({ children }: ProviderProps) => {
	const [state, dispatch] = useReducer(timesheetsReducer, initialState);

	return (
		<TimesheetsContext.Provider value={{ state, dispatch }}>{children}</TimesheetsContext.Provider>
	);
};

export default TimesheetsProvider;

import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonContent,
	IonHeader,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonPopover,
	IonToolbar,
	useIonAlert,
} from '@ionic/react';
import {
	faEnvelope,
	faCircleDown,
	faReply,
	faTrash,
	faThumbsUp,
	faTimes,
	faDownload,
	faPlay,
	faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope as faEn } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Ref, createElement, useEffect, useMemo, useRef, useState } from 'react';
import sanitize from 'sanitize-html';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import ImageViewer from 'react-simple-image-viewer';
import { publish } from '../../../helpers/events';
import Reaction from '../Reaction/Reaction';
import { MessageInterface } from '../../../interfaces/Chat/MessageInterface';
import { humanFileSize } from '../../../helpers/file';
import PreviewItem from './PreviewItem';

import './MessageItem.scss';

interface Props {
	item: MessageInterface;
	index: number;
	preview?: boolean;
	group?: boolean;
	job?: boolean;
	readOnly?: boolean;
	participants?: Array<any>;
}

const MessageItem: React.FC<Props> = (props: Props) => {
	const message: MessageInterface | null = useMemo(() => {
		return props.item !== undefined ? props.item : null;
	}, [props.item]);
	const [presentAlert] = useIonAlert();

	const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
	const [reactOpen, setReactOpen] = useState<boolean>(false);
	const [viewerOpen, setViewerOpen] = useState<boolean>(false);
	const [buttonVisible, setButtonVisible] = useState<boolean>(false);
	const [reactTarget, setReactTarget] = useState<any>({});
	const [reactions, setReactions] = useState<any>([]);
	const [reactionList, setReactionList] = useState<any>([]);
	const [reactionListOpen, setReactionListOpen] = useState<boolean>(false);
	const [selectedReaction, setSelectedReaction] = useState<string | null>(null);

	const ref: any = useRef<HTMLIonPopoverElement>(null);
	const modalRef: any = useRef<Ref<HTMLIonModalElement>>();
	const reactionRef: any = useRef<HTMLIonPopoverElement>(null);

	const previewMessage = (message: MessageInterface) => {
		if (message?.reply !== undefined && message?.reply !== null) {
			const reply: MessageInterface = message.reply;

			let sItem: MessageInterface = Object.assign({}, props.item);
			sItem = reply;

			return createElement(PreviewItem, {
				index: 0,
				item: sItem,
				preview: true,
			});
		}
	};

	const selectEmoji = (unified: string | null) => {
		if (unified !== null) {
			publish('message:react', { item: props.item, unified });
		}
		setReactOpen(false);
	};

	useEffect(() => {
		getReactions(props.item);
	}, [props.item]);

	const getReactions = (localMessage: any) => {
		if (localMessage?.reaction !== undefined) {
			setReactionList(localMessage?.reaction);
			const reactions = Object.keys(localMessage?.reaction).reduce((p: any, c) => {
				let value = localMessage?.reaction ? localMessage?.reaction[c].length : 0;
				if (!p.hasOwnProperty(c)) {
					p[c] = 0;
				}
				p[c] += value;
				return p;
			}, {});
			setReactions(reactions);
		} else {
			setReactions([]);
		}
	};

	return (
		<>
			{message?.file !== undefined && message?.file_type !== undefined && (
				<IonModal
					className='chat-media'
					ref={modalRef}
					isOpen={viewerOpen}
					onDidDismiss={() => setViewerOpen(false)}
				>
					<IonHeader>
						<IonToolbar color={'primary'}>
							<IonButtons slot='end'>
								<a href={message.file} download={message.file_name}>
									<IonButton className='icon-only'>
										<FontAwesomeIcon icon={faDownload} />
									</IonButton>
								</a>
								<IonButton className='icon-only' onClick={() => modalRef.current.dismiss()}>
									<FontAwesomeIcon icon={faTimes} />
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonContent className='center'>
						{message?.file_type.indexOf('image/') > -1 && (
							<ImageViewer
								src={[message.file]}
								currentIndex={0}
								closeComponent={<></>}
								disableScroll={false}
							/>
						)}
						{message?.file_type.indexOf('video/') > -1 && (
							<video controls className='gallery-photo'>
								<source src={message.file} type='video/mp4' />
							</video>
						)}
					</IonContent>
				</IonModal>
			)}
			<div
				key={message?._id}
				id={message?._id}
				className={`message ${message?.own ? 'own' : ''} ${
					message?.isRead === false ? 'not-read' : ''
				} ${
					props.preview === undefined || props.preview === null || props.preview === false
						? ''
						: 'preview'
				}`}
			>
				<IonCard
					onMouseEnter={() => {
						setButtonVisible(true);
					}}
					onMouseLeave={() => {
						setButtonVisible(false);
					}}
				>
					{(props.readOnly === undefined || props.readOnly === false) &&
						(props.preview === undefined || props.preview === null || props.preview === false) &&
						buttonVisible && (
							<IonButtons style={{ position: 'absolute', right: 0 }}>
								<IonButton
									id='popover-trigger'
									onClick={(e) => {
										ref.current!.event = e;
										setPopoverOpen((previousVal: boolean) => !previousVal);
									}}
									className='icon-only'
								>
									<FontAwesomeIcon icon={faChevronDown} />
								</IonButton>
								<IonPopover
									isOpen={popoverOpen}
									ref={ref}
									dismissOnSelect={true}
									onDidDismiss={() => {
										setPopoverOpen(false);
									}}
								>
									<IonList>
										<IonItem
											button={true}
											detail={false}
											onClick={() => {
												publish('message:reply', props.item);
											}}
										>
											<div slot='start'>
												<FontAwesomeIcon icon={faReply} />
											</div>
											<IonLabel>Reply</IonLabel>
										</IonItem>
										{props.group !== undefined && props.group === true && (
											<IonItem
												button={true}
												detail={false}
												onClick={() => {
													publish('message:privateReply', props.item);
												}}
											>
												<div slot='start'>
													<FontAwesomeIcon icon={faReply} />
												</div>
												<IonLabel>Private Reply</IonLabel>
											</IonItem>
										)}
										<IonItem
											button={true}
											detail={false}
											onClick={(e) => {
												setReactTarget(e);
												setReactOpen(true);
											}}
										>
											<div slot='start'>
												<FontAwesomeIcon icon={faThumbsUp} />
											</div>
											<IonLabel>React</IonLabel>
										</IonItem>
										{message?.own && (
											<IonItem
												button={true}
												detail={false}
												onClick={() => {
													presentAlert({
														header: 'Delete Message',
														message:
															'Are you sure you want to delete this message? This cannot be undone.',
														buttons: [
															{
																text: 'Cancel',
																role: 'cancel',
															},
															{
																text: 'OK',
																role: 'confirm',
																handler: () => {
																	publish('message:delete', message?._id);
																},
															},
														],
													});
												}}
											>
												<div slot='start'>
													<FontAwesomeIcon icon={faTrash} />
												</div>
												<IonLabel>Delete</IonLabel>
											</IonItem>
										)}
									</IonList>
								</IonPopover>
							</IonButtons>
						)}
					{message?.own === false && (props.group === true || props.job === true) && (
						<IonCardHeader>
							<IonItem>
								<IonLabel>
									<h4 style={{ fontWeight: 'bold' }}>{message?.created_by}</h4>
								</IonLabel>
							</IonItem>
						</IonCardHeader>
					)}
					{message?.reply !== undefined && (
						<div
							onClick={() => {
								publish('message:jump', message?.reply?._id);
							}}
						>
							{previewMessage(message)}
						</div>
					)}
					{message?.file !== undefined &&
						message?.file_type !== undefined &&
						message?.file_type.indexOf('image/') > -1 && (
							<div
								className='thumbnail'
								style={{ backgroundImage: `url(${message.file_thumb})` }}
								onClick={() => {
									setViewerOpen(true);
								}}
							></div>
						)}
					{message?.file !== undefined &&
						message?.file_type !== undefined &&
						message?.file_type.indexOf('video/') > -1 && (
							<div
								className='thumbnail'
								style={{ backgroundImage: `url(${message.file_thumb})` }}
								onClick={() => {
									setViewerOpen(true);
								}}
							>
								<div className='play-icon'>
									<FontAwesomeIcon icon={faPlay} />
								</div>
							</div>
						)}
					{message?.file !== undefined &&
						message?.file_type !== undefined &&
						message.file_type.indexOf('image/') === -1 &&
						message.file_type.indexOf('video/') === -1 && (
							<a href={message.file} download={message.file_name} className='doc-link'>
								<div>
									<div>{message.file_name}</div>
									<div className='file-size'>{humanFileSize(message.file_size ?? 0)}</div>
								</div>
								<div className='file-icon'>
									<FontAwesomeIcon icon={faCircleDown} />
								</div>
							</a>
						)}
					<IonCardContent>
						<IonLabel>
							<p
								dangerouslySetInnerHTML={{
									__html: sanitize(props.item?.message ?? '', {
										allowedTags: ['mark'],
										disallowedTagsMode: 'escape',
									}),
								}}
							></p>
						</IonLabel>
					</IonCardContent>
				</IonCard>
				{(props.preview === undefined || props.preview === null || props.preview === false) &&
					Object.keys(reactions).length > 0 && (
						<div className='reactions'>
							{Object.keys(reactions).map((reaction: string, index) => {
								return (
									<div className='emoji-container' key={index}>
										<div
											onClick={(e) => {
												reactionRef.current!.event = e;
												setSelectedReaction(reaction);
												setReactionListOpen(true);
											}}
										>
											<Emoji emojiStyle={EmojiStyle.NATIVE} size={15} unified={reaction} />
										</div>
										{reactions[reaction] > 1 && (
											<div className='emoji-number'>{reactions[reaction]}</div>
										)}
									</div>
								);
							})}
							<IonPopover
								ref={reactionRef}
								onDidDismiss={() => {
									setReactionListOpen(false);
									setSelectedReaction(null);
								}}
								isOpen={reactionListOpen}
							>
								<IonList>
									{selectedReaction !== null &&
										reactionList[selectedReaction].map((item: any, index: any) => {
											return <IonItem key={index}>{item.name}</IonItem>;
										})}
								</IonList>
							</IonPopover>
						</div>
					)}
				{(props.preview === undefined || props.preview === null || props.preview === false) && (
					<div className='time-read'>
						<span>{message?.time}</span>
						<FontAwesomeIcon
							className={
								message?.read?.length === (props?.participants?.length ?? 0) + 1
									? 'success-icon'
									: 'primary-icon'
							}
							icon={
								message?.read?.length === (props?.participants?.length ?? 0) + 1 ? faEnvelope : faEn
							}
						/>
					</div>
				)}
				<Reaction show={reactOpen} target={reactTarget} onSelect={selectEmoji} />
			</div>
			{props.item?.has_heading === true &&
				(props.preview === undefined || props.preview === null || props.preview === false) && (
					<div className='heading'>{props.item?.date}</div>
				)}
		</>
	);
};

export default MessageItem;

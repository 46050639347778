import { Actions as Action, ActionType } from './actions';
import { Worker, WorkersState } from './workers-types';

export const workersReducer = (state: WorkersState, action: Action): WorkersState => {
	switch (action.type) {
		case ActionType.SET_WORKER: {
			const { payload } = action;

			const worker: Worker = payload;

			return {
				...state,
				worker,
			};
		}
		case ActionType.UPDATE_WORKER: {
			const { payload } = action;

			const worker: Worker = { ...state.worker, ...payload };

			return {
				...state,
				worker,
			};
		}
		case ActionType.UPDATE_ACTIVE_SUBNAV_TAB: {
			const { payload } = action;

			return {
				...state,
				activeSubNavTabs: { ...state.activeSubNavTabs, ...payload },
			};
		}
		default: {
			return state;
		}
	}
};

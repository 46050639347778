import styles from './../../../assets/scss/styles.module.scss';
export const subtleGreyScale: google.maps.MapTypeStyle[] = [
	{
		featureType: 'administrative',
		elementType: 'all',
		stylers: [
			{
				saturation: '-100',
			},
		],
	},
	{
		featureType: 'administrative.province',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: 65,
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'landscape.man_made.building',
		elementType: 'all',
		stylers: [
			{
				color: styles.secondary,
			},
			{
				saturation: -40,
			},
			{
				lightness: 80,
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: '50',
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [
			{
				color: styles.success,
			},
			{
				saturation: -80,
			},
			{
				lightness: 70,
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: '-100',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'all',
		stylers: [
			{
				lightness: -10,
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'all',
		stylers: [
			{
				lightness: -10,
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'simplified',
			},
			{
				color: styles.dark,
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'simplified',
			},
			{
				color: styles.dark,
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				saturation: -100,
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				color: styles.primary,
			},
			{
				saturation: -70,
			},
			{
				lightness: 50,
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'labels',
		stylers: [
			{
				lightness: -25,
			},
			{
				saturation: -10,
			},
		],
	},
];

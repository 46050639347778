import { useEffect, useState } from 'react';
import { companyDetails } from './arrays/companyDetails';
import { db2Form } from '../../../../../api/forms';
import Form from '../../../../../components/Forms/Form';
import Loading from '../../../../../components/UI/Loading';
import axios from '../../../../../lib/axios';

interface Props {
	uid: string;
	permissionTo: Function;
}

const CompanyDetails = (props: Props) => {
	const [items, setItems] = useState<Array<any>>([]);
	const [companyName, setCompanyName] = useState<Array<any>>([]);
	const [endpointID, setEndpointID] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		let formData: any = companyDetails;

		axios
			.get('/api/utilities/company_details')
			.then((res) => res.data[0])
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
				setEndpointID(dbData._id);
				setCompanyName(dbData.company_name);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<>
			{isLoading && <Loading />}
			{!isLoading && items && items.length > 0 && (
				<>
					<h3 className='pb-3'>{companyName}</h3>
					<Form
						array={items}
						permissionTo={props.permissionTo}
						endpoint={'/api/utilities/company_details/'}
						endpointID={endpointID}
					/>
				</>
			)}
		</>
	);
};

export default CompanyDetails;

import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useRef, useState } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';

type Props = {
	gridUrl: string;
	gridPayload: any;
	onClose: Function;
};

const ReportModal = (props: Props, ref: any) => {
	const gridRef: any = useRef<any>();
	const [didPresent, setDidPresent] = useState<boolean>(false);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('');

	const handleOnWillPresent = () => {
		if (ref.current) {
			let tmp: string = ref.current.modalData.title;
			if (ref.current.modalData.start) {
				tmp += ' - ' + ref.current.modalData.start.toFormat('dd/MM/yyyy');
			} else {
				tmp += ' - All time';
			}
			if (ref.current.modalData.end)
				tmp += ' to ' + ref.current.modalData.end.toFormat('dd/MM/yyyy');
			setTitle(tmp);
		}
	};

	const handleOnDidPresent = () => {
		setDidPresent(true);
	};

	const onClose = () => {
		// Reset
		setDidPresent(false);
		setTitle('');
		props.onClose();
	};

	return (
		<IonModal
			ref={ref}
			class='worker-reports-modal'
			style={{ '--width': '70vw', '--height': '80vh' }}
			onWillPresent={handleOnWillPresent}
			onDidPresent={handleOnDidPresent}
			onWillDismiss={() => onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Report Details</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{didPresent && (
					<DataGrid
						ref={gridRef}
						onGridReady={() => setGridReady(true)}
						useSearch={false}
						title={title}
						cols={ref.current.modalData.columns}
						dataSource={{
							url: gridReady ? props.gridUrl + '/' + ref.current.modalData.rowId : '',
							method: 'POST',
							data: props.gridPayload,
						}}
					/>
				)}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default forwardRef(ReportModal);

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonCol,
	IonFooter,
	IonRow,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import LocateOnMap from '../../../../components/Maps/LocateOnMap';
import { Alert } from '@mui/material';

interface MapModalProps {
	isOpen: boolean;
	itemName: string;
	itemData: any;
	onSave: Function;
	onClose: Function;
}

const MapModal: React.FC<MapModalProps> = ({ isOpen, itemName, itemData, onSave, onClose }) => {
	const [markerPosition, setMarkerPosition] = useState<any>(null);
	const initialCentre = {
		lat: Number(itemData.address.latitude),
		lng: Number(itemData.address.longitude),
	};
	const options = {
		initialCentre: initialCentre,
		markerTitle: itemName,
	};

	useEffect(() => {
		setMarkerPosition(initialCentre);
	}, [itemData]);

	const handleOnClose = () => {
		setMarkerPosition(initialCentre);
		onClose();
	};

	return (
		<IonModal
			style={{ '--width': '60vw', '--height': '612px' }}
			isOpen={isOpen}
			onDidDismiss={handleOnClose}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{`Set Office Location: ${itemName}`}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className='flex-grow-container' style={{ height: '100%' }}>
					<Alert severity='info' style={{ margin: '0 0 8px 0' }}>
						Left-click on the map below to refine the selected office's location ({itemName})
					</Alert>
					<div style={{ flex: 1, overflow: 'hidden' }}>
						<LocateOnMap
							options={options}
							markerPosition={markerPosition}
						/>
					</div>
				</div>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
							<IonButton color='success' onClick={() => onSave(itemData, markerPosition)}>
								Update Office Location
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default MapModal;

import { circle, polygon, point, points, centerOfMass, distance, intersect } from '@turf/turf';

import { Bounds, Coordinates } from '../interfaces/Maps/MapsInterface';

export const boundsFromLatLngList = (list: Array<Coordinates>): Bounds => {
	if (list.length > 0) {
		let x0: number = 0;
		let x1: number = 0;
		let y0: number = 0;
		let y1: number = 0;
		list.forEach((latLng) => {
			if (x0 === 0) {
				x0 = x1 = latLng.lat;
				y0 = y1 = latLng.lng;
			} else {
				if (latLng.lat > x1) x1 = latLng.lat;
				if (latLng.lat < x0) x0 = latLng.lat;
				if (latLng.lng > y1) y1 = latLng.lng;
				if (latLng.lng < y0) y0 = latLng.lng;
			}
		});
		return {
			northeast: new window.google.maps.LatLng(x1, y1),
			southwest: new window.google.maps.LatLng(x0, y0),
		};
	} else {
		throw new Error("No coordinates supplied");
	}
};

export const getBoundsZoomLevel = (
	bounds: Bounds,
	mapDim: {
		height: number;
		width: number;
	}
): number => {
	var WORLD_DIM = { height: 256, width: 256 };
	var ZOOM_MAX = 21;

	function latRad(lat: number) {
		var sin = Math.sin((lat * Math.PI) / 180);
		var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
		return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
	}

	function zoom(mapPx: number, worldPx: number, fraction: number) {
		return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
	}

	var ne = bounds.northeast;
	var sw = bounds.southwest;

	var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

	var lngDiff = ne.lng() - sw.lng();
	var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

	var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
	var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

	return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

export const intersectionIterations = (intersections: Array<any>): Array<any> => {
	if (intersections.length > 1) {
		const newIntersections = [];
		for (let i = 1; i < intersections.length; i++) {
			var c1 = polygon(intersections[i - 1]);
			var c2 = polygon(intersections[i]);

			var iter = intersect(c1, c2);
			if (iter !== null) {
				newIntersections.push(iter.geometry.coordinates);
			}
		}

		if (newIntersections.length > 0) {
			return intersectionIterations(newIntersections);
		} else {
			return intersections;
		}
	} else if (intersections.length === 1) {
		return intersections[0];
	} else {
		return [];
	}
};

export const getIntersectionPoints = (circles: Array<{lat: number, lng: number, radius: number}>) => {
	const intersections: Array<any> = [];
	for (let i = 0; i < circles.length; ++i) {
		for (let j = i + 1; j < circles.length; ++j) {
			const center1 = [circles[i].lng, circles[i].lat];
			let radius = circles[i].radius;
			const circle1 = circle(center1, radius / 1000, {
				steps: 200,
				units: 'kilometers',
				properties: {},
			});

			const center2 = [circles[j].lng, circles[j].lat];
			radius = circles[j].radius;
			const circle2 = circle(center2, radius, {
				steps: 200,
				units: 'meters',
				properties: {},
			});

			const c1 = polygon(circle1.geometry.coordinates);
			const c2 = polygon(circle2.geometry.coordinates);

			const iter = intersect(c1, c2);
			if (iter !== null) {
				intersections.push(iter.geometry.coordinates);
			}
		}
	}

	const finalIntersection = intersectionIterations(intersections);
	const ret: Array<any> = [];
	const distances: Array<any> = [];
	let theCenter = null;
	if (finalIntersection.length > 0) {
		const features = points(finalIntersection[0]);
		theCenter = centerOfMass(features);
		const from = point(theCenter.geometry.coordinates);
		finalIntersection[0].forEach((intersection: Array<any>) => {
			ret.push({
				latitude: intersection[1],
				longitude: intersection[0],
			});

			const to = point(intersection);
			distances.push(distance(from, to, { units: 'meters' }));
		});
	}
	return {
		polygon: ret,
		center:
			theCenter !== null
				? {
						latitude: theCenter.geometry.coordinates[1],
						longitude: theCenter.geometry.coordinates[0],
						minRadius: Math.min.apply(Math, distances),
						maxRadius: Math.max.apply(Math, distances),
				  }
				: {},
	};
};
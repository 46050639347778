import { useState, useEffect } from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { Alert, AlertTitle } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import File from './File';
import Photo from './Photo';
import Video from './Video';
import axios from '../../lib/axios';
import { showToast } from '../../lib/toast';
import Paginator from '../UI/Paginator';
import Loading from '../UI/Loading';
import PhotoModal from '../UI/modals/PhotoModal';
import MediaFilterPopover from './MediaFilter';

interface Props {
	mediaCategory: 'worker' | 'job';
	mediaData?: any;
}

const InternalMedia: React.FC<Props> = ({ mediaCategory, mediaData }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [photos, setPhotos] = useState<Array<any>>([]);
	const [page, setPage] = useState<number>(1);
	const [pageMeta, setPageMeta] = useState<number>(0);
	const [filters, setFilters] = useState<any>({});
	const { workerId, jobId }: any = useParams();

	// Note modal state
	const [photoModal, setPhotoModal] = useState<any>({ isOpen: false });
	const [photoModalData, setPhotoModalData] = useState<any>({});

	// Media category setup
	let mainTitle: string = 'Job';
	let emptyGalleryMsg: string = 'This job has no chat media.';
	let url: string = '/api/jobs/jobs_list/job_card/photos/internal';
	let payload: any = {
		per_page: 6,
		job_id: jobId,
		conversation_id: mediaData?.conversation_id
	};

	useEffect(() => {
		loadPhotos(page);
	}, [page]);

	useEffect(() => {
		loadPhotos(page);
	}, [filters]);

	const loadPhotos = (curPage: number) => {
		setIsLoading(true);

		payload.page = curPage;
		Object.keys(filters).forEach((key: any) => {
			payload[key] = typeof filters[key] === 'object' ? filters[key].value : filters[key];
		});

		axios
			.post(url, payload)
			.then((res) => {
				if (res.data.data) {
					const photoData = res.data.data.map((p: any, i: number) => {
						// Build the photo's description
						let photoDescription: Array<string> = [
							p.description,
							DateTime.fromISO(p.created_at).toFormat('dd/MM/yyyy'),
							DateTime.fromISO(p.created_at).toFormat('HH:mm'),
						];

						// Location information
						let locationInfo: Array<string> = [];
						if (p.town) locationInfo.push(p.town);
						if (p.county) locationInfo.push(p.county);
						if (locationInfo.length > 0) photoDescription.push(locationInfo.join(', '));

						if (p?.file_type.indexOf('image/') > -1) {
							return (
								<Photo
									key={i}
									path={p.thumb}
									description={photoDescription}
									imageFunction={() =>
										photoModalOpen({ ...p, photoDescription: photoDescription })
									}
								/>
							);
						} else if (p?.file_type.indexOf('video/') > -1) {
							return (
								<Video
									key={i}
									path={p.thumb}
									description={photoDescription}
									imageFunction={() =>
										photoModalOpen({ ...p, photoDescription: photoDescription })
									}
								/>
							);
						} else {
							return (
								<File
									key={i}
									path={p.path}
									description={photoDescription}
									imageFunction={() =>
										photoModalOpen({ ...p, photoDescription: photoDescription })
									}
								/>
							);
						}
					});
					setPhotos(photoData);
					setPageMeta(res.data.meta);
				}
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const photoModalOpen = (data: any) => {
		setPhotoModalData(data);
		setPhotoModal({ isOpen: true });
	};

	const photoModalOnClose = () => {
		setPhotoModal({ isOpen: false });
	};

	const handleDownloadImage = (data: any) => {
		let link = document.createElement('a');
		link.href = data.path;
		link.download = data.file_name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			<div style={{ minHeight: '763px' }}>
				<IonRow>
					<IonCol size={'8'} style={{ alignSelf: 'center' }}>
						<h4 className='submenu-item-title'>{mainTitle} Internal Media Gallery</h4>
					</IonCol>
					<IonCol size={'4'} className={'text-right'}>
						<MediaFilterPopover filters={filters} onFilter={(filters) => { setFilters(filters); }} />
					</IonCol>
				</IonRow>
				{!isLoading && photos.length === 0 && (
					<IonRow>
						<IonCol size='12'>
							<Alert severity='info'>
								<AlertTitle>Info</AlertTitle>
								{emptyGalleryMsg}
							</Alert>
						</IonCol>
					</IonRow>
				)}

				{photos.length > 0 && (
					<>
						<IonRow>{photos}</IonRow>
						<Paginator page={page} setPage={setPage} pageMeta={pageMeta} />
						<PhotoModal
							isOpen={photoModal.isOpen}
							onClose={photoModalOnClose}
							onDidDismiss={photoModalOnClose}
							initialData={photoModalData}
							handleDownloadImage={handleDownloadImage}
						/>
					</>
				)}
			</div>
		</>
	);
};

export default InternalMedia;

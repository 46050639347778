import { IonCol, IonGrid, IonLabel, IonRow, IonSegment, IonSegmentButton } from '@ionic/react';
import { CalendarNav, CalendarNext, CalendarPrev, CalendarToday } from '@mobiscroll/react';
import { ResourceModeOption, SchedulingState, Views } from '../scheduling-types';
import { ActionType } from '../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import CalendarFilterPopover from './CalendarFilterPopover';

export const Header = (
	views: Views,
	state: SchedulingState,
	dispatch: Function,
	handleResourceModeChange: Function
) => {
	const resourceModeOptions: ResourceModeOption[] = [
		{ label: 'Jobs', value: 'jobs', icon: faClipboard },
		{ label: 'Skills', value: 'skills', icon: faWrench },
		{ label: 'Workers', value: 'workers', icon: faUser },
	];

	return (
		<IonGrid className='mbsc-prop-custom-header p-0'>
			<IonRow>
				<IonCol size='4' className='p-0 d-flex'>
					<CalendarFilterPopover />

					<IonSegment className='segments-resource-mode' value={state.resourceMode}>
						{resourceModeOptions.map((opt: ResourceModeOption, i: number) => {
							return (
								<IonSegmentButton
									key={i}
									value={opt.value}
									onClick={() => handleResourceModeChange(opt.value)}
								>
									<IonLabel>
										<FontAwesomeIcon icon={opt.icon} /> {opt.label}
									</IonLabel>
								</IonSegmentButton>
							);
						})}
					</IonSegment>
				</IonCol>
				<IonCol size='4' className='p-0'>
					<IonSegment className='segments-view-mode' value={state.viewMode}>
						{Object.keys(views).map((view: string, i: number) => {
							return (
								<IonSegmentButton
									key={i}
									value={view}
									onClick={() => dispatch({ type: ActionType.SET_VIEW, payload: view })}
								>
									<IonLabel>{view}</IonLabel>
								</IonSegmentButton>
							);
						})}
					</IonSegment>
				</IonCol>
				<IonCol size='4' className='p-0 text-right'>
					<div className='scheduling-calendar-today'>
						<CalendarPrev />
						<CalendarToday />
						<CalendarNext />
					</div>
					<CalendarNav className='scheduling-calendar-nav' />
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

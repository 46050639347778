import { IonButton } from '@ionic/react';
import axiosMP from '../lib/axiosMp';
import { AxiosProgressEvent } from 'axios';
import { toast } from 'react-toastify';
import { showToast } from '../lib/toast';

export const uploadCell = (
	permissionTo: Function,
	upload: {
		mainId: string;
		subId: string;
		toastId: any;
		setIsLoading: Function;
		setData: Function;
	}
) => {
	return [
		{
			field: 'uploaded',
			headerName: 'Upload',
			cellRenderer: (params: any) => {
				if (params.value === 'pinned-row') return;

				if (
					!params.node.data.hasOwnProperty('enable_uploads') ||
					(params.node.data.hasOwnProperty('enable_uploads') &&
						params.node.data.enable_uploads === true) ||
					params.node.data.enable_uploads === null
				) {
					const uploadId: string = params.node.rowIndex;
					return (
						<>
							{permissionTo('update') && params.node.data.uploaded !== true && (
								<IonButton
									size='small'
									className='ag-grid-row-button'
									onClick={() =>
										(document.getElementById('upload-' + uploadId) as HTMLElement).click()
									}
								>
									<input
										hidden
										id={'upload-' + uploadId}
										type='file'
										onChange={(e: any) =>
											handleUpload(
												e,
												params.node.data[upload.mainId],
												params.node.data[upload.subId],
												upload.toastId,
												upload.setIsLoading,
												upload.setData
											)
										}
									/>
									Upload
								</IonButton>
							)}
							{params.node.data.uploaded === true && params.node.data.file_path && (
								<a href={params.node.data.file_path} download={params.node.data.file_name}>
									<IonButton size='small' className='ag-grid-row-button' color='secondary'>
										View
									</IonButton>
								</a>
							)}
						</>
					);
				} else {
					return <p className='text-muted'>Not required</p>;
				}
			},
			width: 210,
			suppressSizeToFit: true,
		},
	];
};

function handleUpload(
	e: any,
	requestId: string,
	workerId: string,
	toastId: any,
	setIsLoading: Function,
	setData: Function
) {
	const file = e.target.files[0];

	let fd = new FormData();
	fd.append('upload', file);
	fd.append('request_id', requestId);
	fd.append('worker_id', workerId);

	// Reset
	toastId.current = null;

	setIsLoading(true);

	axiosMP
		.post('/api/workers/holidays_and_absences/requests/upload', fd, {
			onUploadProgress: (p: AxiosProgressEvent) => {
				const progress = p.loaded / (p.total ?? 0);

				if (toastId.current === null) {
					toastId.current = toast('Uploading...', { type: toast.TYPE.INFO });
				} else {
					toast.update(toastId.current, {
						progress,
						render: 'Uploading (' + Math.floor(progress * 100) + '%)...',
					});
				}
			},
		})
		.then((res: any) => {
			// Update the dataset
			setData((prevState: any) => {
				return prevState.map((item: any) => {
					if (item.request_id === requestId && item.worker_id === workerId) {
						item.uploaded = true;
						item.file_name = res.data.file_name;
						item.file_path = res.data.file_path;
					}
					return item;
				});
			});

			showToast('success');
		})
		.catch((err: any) => {
			showToast('error');
			console.error(err);
		})
		.finally(() => {
			toast.dismiss(toastId.current);
			setIsLoading(false);
		});
}

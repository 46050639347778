import { Alert } from '@mui/material';
import { useContext } from 'react';
import JobsContext from '../../../contexts/JobsContext';

const JobTitle: React.FC = () => {
	const { jobData } = useContext(JobsContext);

	let msg: Array<string> = [
		`Job Status: ${jobData?.status ?? 'New'}`
	];

	if (jobData?.work_suspended_reason_id) {
		msg.push(`Suspended: ${jobData?.work_suspended_reason}`);
	}

	return (
		<Alert severity="info">
			{msg.join(', ')}
		</Alert>
	);
}

export default JobTitle;

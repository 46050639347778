import { addItem, appendItems, filterItems, highlightList, prependItems, removeItem, replaceList, updateItem } from "./listFunctions";

export const listReducer = (state: any, action: any) => {
	switch (action.type) {
		case 'remove': {
			const newList = removeItem(state.list, action);
			return { ...state, list: newList };
		}
		case 'update': {
			const newList = updateItem(state.list, action);
			return { ...state, list: newList };
		}
		case 'add': {
			const newList = addItem(state.list, action);
			return { ...state, list: newList };
		}
		case 'replace': {
			const newList = replaceList(state.list, action)
			return { ...state, list: newList };
		}
		case 'prepend': {
			const newList = prependItems(state.list, action);
			return { ...state, list: newList };
		}
		case 'append': {
			const newList = appendItems(state.list, action);
			return { ...state, list: newList };
		}
		case 'filter': {
			let originalList = state.list;
			if (state.originalList.length > 0) {
				originalList = state.originalList;
			}
			const newList = filterItems(originalList, action);
			return { ...state, list: newList, originalList: originalList };
		}
		case 'clearFilter': {
			if (state.originalList.length > 0) {
				return { ...state, list: state.originalList, originalList: [] };
			} else {
				return state;
			}
		}
		case 'highlight': {
			const newList = highlightList(state.list, action);
			return { ...state, list: newList };
		}
		default:
			throw new Error();
	}
};

import { useState, useEffect } from 'react';
import { TextField } from '../../../../../components/Forms/FormFields';
import axios from '../../../../../lib/axios';
import Loading from '../../../../../components/UI/Loading';

interface Props {
	uid: string;
	permissionTo: Function;
}

const LicenseDatesAndCosts = (props: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<any>(null);

	useEffect(() => {
		setIsLoading(true);

		axios
			.get('/api/utilities/company_details')
			.then((res) => {
				const d = res.data[0];

				// Parse data
				if (!d?.licenseinfo?.country) d.licenseinfo.country = '';
				if (!d?.licenseinfo?.date_first_subscription) d.licenseinfo.date_first_subscription = '';
				if (!d?.licenseinfo?.date_cannot_cancel) d.licenseinfo.date_cannot_cancel = '';
				if (!d?.licenseinfo?.date_renewal) d.licenseinfo.date_renewal = '';
				if (!d?.licenseinfo?.renewal_cost) d.licenseinfo.renewal_cost = 0;
				if (!d?.storage?.allowance) d.storage.allowance = 0;
				if (!d?.storage?.cost_per_block) d.storage.cost_per_block = 0;
				d.storage.cost_per_block.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });

				setData(d);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<>
			{isLoading && <Loading />}
			{!isLoading && data && (
				<>
					<h5 className='font-bold'>Country of Usage License</h5>
					<TextField useLabels={false} value={data.licenseinfo.country} />

					<hr className='section-break' />

					<h5 className='font-bold'>License Fee per Year</h5>
					<TextField title={'Commencement Date'} value={data.licenseinfo.date_first_subscription} />
					<TextField title={'Cannot Cancel Date'} value={data.licenseinfo.date_cannot_cancel} />
					<TextField title={'Renewal Date'} value={data.licenseinfo.date_renewal} />
					<TextField title={'Renewal Cost'} value={`£${data.licenseinfo.renewal_cost}`} />

					<hr className='section-break' />

					<h5 className='font-bold'>Storage Cost per Year</h5>
					<TextField title={'System Allowance'} value={`${data.storage.allowance}GB`} />
					<TextField title={'Cost per Block'} value={`£${data.storage.cost_per_block}`} />
				</>
			)}
		</>
	);
};

export default LicenseDatesAndCosts;

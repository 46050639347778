import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker } from '@mobiscroll/react';
import { useParams } from 'react-router';
import { DateTime, Info } from 'luxon';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';
import { TotalCol } from '../../Reports/report-types';
import { ExcelStyle } from '@ag-grid-community/core';

interface Props {
	parentRef: any;
	permissionTo: Function;
}

const PhoneUsageIndex: React.FC<Props> = (props: Props) => {
	const { workerId }: any = useParams();
	const [calendarType, setCalendarType] = useState<'year' | 'month' | 'week' | undefined>('week');
	const [marked, setMarked] = useState<Array<any>>([]);
	const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
	const [endDate, setEndDate] = useState<DateTime>(DateTime.now());
	const divRef = useRef<HTMLDivElement>(null);
	const [dayNames, setDayNames] = useState<Array<string>>([]);
	const calcTotalCols: TotalCol[] = [{ id: 'seconds_string' }];

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) weekdays.unshift(last);
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	const colData: Array<any> = [
		{ headerName: 'Application', field: 'app_name', flex: 1 },
		{
			headerName: 'Total Time',
			field: 'seconds_string',
			headerClass: 'ag-right-aligned-header',
			cellClass: 'duration-type',
			type: 'rightAligned',
			flex: 1,
		},
	];

	const handleCellClick = async (e: any) => {
		if (e.value) {
			if (e.value[1] !== null) {
				setStartDate(DateTime.fromJSDate(e.value[0]));
				setEndDate(DateTime.fromJSDate(e.value[1]));
			}
		}
	};

	const loadUsageStatsSummary = async (start: DateTime, end: DateTime) => {
		return new Promise((res, rej) => {
			axios
				.post('/api/workers/workers_list/worker_card/phone_usage', {
					worker_id: workerId,
					start: start.toISO(),
					end: end.toISO(),
					summary: 1,
				})
				.then((result) => {
					if (result.data) {
						setMarked(
							result.data.map((item: any) => {
								let date = DateTime.now().set({
									year: item._id.date.year,
									month: item._id.date.month,
									day: item._id.date.day,
								});
								return { date: date.toJSDate() };
							})
						);
						res(true);
					}
				})
				.catch((e) => {
					showToast('error');
					rej(e);
				});
		});
	};

	const knobClick = () => {
		setCalendarType((prevValue) => {
			if (prevValue === 'week') {
				return 'month';
			} else {
				return 'week';
			}
		});
	};

	const totalRow = function (rowdata: any) {
		let result: any = [{}];

		// Initialise total columns to zero
		calcTotalCols.forEach((params) => {
			result[0][params.id] = 0;
			rowdata.forEach(function (line: any) {
				result[0][params.id] = line[params.id];
			});
		});

		result[0]['app_name'] = 'All Apps';

		// Pin this row
		return result;
	};

	const excelStyles = useMemo(() => {
		const excelStylesArray: ExcelStyle[] = [
			{
				id: 'duration-type',
				alignment: {
					horizontal: 'Right',
				},
			},
			{
				id: 'ag-right-aligned-header',
				alignment: {
					horizontal: 'Right',
				},
			},
		];

		return excelStylesArray;
	}, []);

	return (
		<>
			<div className='flex-grow-container' style={{ height: '100%' }}>
				<div className='date-expand' ref={divRef} style={{ margin: '0 auto', width: '30vw' }}>
					<Datepicker
						dayNamesMin={dayNames}
						select='range'
						calendarSize={1}
						showRangeLabels={false}
						marked={marked}
						calendarType={calendarType}
						controls={['calendar']}
						display='inline'
						defaultValue={
							DateTime.now().toFormat('dd/MM/yy') + ' - ' + DateTime.now().toFormat('dd/MM/yy')
						}
						onChange={(e) => handleCellClick(e)}
						onPageLoading={(e) => {
							let start = DateTime.fromJSDate(e.firstDay);
							let end = DateTime.fromJSDate(e.lastDay);
							loadUsageStatsSummary(start, end);
						}}
						renderCalendarHeader={() => {
							return (
								<Fragment>
									<CalendarPrev className='custom-prev' />
									<CalendarNav className='custom-nav' />
									<CalendarNext className='custom-next' />
								</Fragment>
							);
						}}
					/>
					<div className='date-expand-control'>
						<div className='knob' onClick={knobClick}></div>
					</div>
				</div>

				<div style={{ flex: 1 }}>
					<DataGrid
						title={'Phone Usage'}
						cols={colData}
						dataSource={{
							url: '/api/workers/workers_list/worker_card/phone_usage',
							method: 'POST',
							data: {
								worker_id: workerId,
								start: startDate?.set({ hour: 0, minute: 0, second: 0 }).toISO(),
								end: endDate?.set({ hour: 23, minute: 59, second: 59 }).toISO(),
								restricted: true,
							},
						}}
						useSearch={false}
						excelStyles={excelStyles}
						exportExcelButton={true}
						totalsRow={totalRow}
					/>
				</div>
			</div>
		</>
	);
};

export default PhoneUsageIndex;

import { DateTime } from 'luxon';
import { dateFormatterFullOrdinal } from '../../../helpers/dateFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { convertSecondsToDurationString } from '../../../helpers/times';

const DURATION_UNITS = 'hrs';
export const DURATION_UNITS_OBJ = <span className='duration-units'>{DURATION_UNITS}</span>;
export const EMPTY_DATA_STR = '-';

export const headerRow = (type: 'expected-items' | 'timesheet' | 'behaviour', day: any) => {
	let output: any = {};

	switch (type) {
		case 'expected-items':
			output = {
				col_a: dateFormatterFullOrdinal(day.date),
				col_b: 'Cost Head',
				col_c: 'Start',
				col_d: 'End',
				col_e: 'Paid Time',
				col_f: '',
				col_g: '',
			};
			break;
		case 'timesheet':
			output = {
				col_a: '',
				col_b: 'Cost Head',
				col_c: 'Start',
				col_d: 'End',
				col_e: 'Excluded',
				col_f: 'Included',
				col_g: 'Toggle',
			};
			break;
		case 'behaviour':
			output = {
				col_a: '',
				col_b: '',
				col_c: 'Recorded',
				col_d: 'Expected',
				col_e: '',
				col_f: '',
				col_g: '',
			};
			break;
	}

	output.cellClass = `header-row hr-${type}`;

	return output;
};

export const subHeaderRow = (style: 'alpha' | 'beta', text: string) => {
	let output: any = { col_a: text };

	output.cellClass = `sub-header-row shr-${style}`;

	return output;
};

export const dataRow = (
	type: 'expected' | 'items' | 'ts' | 'behaviour',
	dayId: number,
	data: any,
	isBeforeSubTotalRow?: boolean,
	isLastDataRow?: boolean
) => {
	let output: any = {};

	switch (type) {
		case 'expected':
			output = {
				col_a: data?.description,
				col_b: data?.cost_head,
				col_c: data.hasOwnProperty('start') ? DateTime.fromISO(data.start).toFormat('HH:mm') : '',
				col_d: data.hasOwnProperty('end') ? DateTime.fromISO(data.end).toFormat('HH:mm') : '',
				col_e: (
					<>
						{convertSecondsToDurationString(data?.paid_time)}
						{DURATION_UNITS_OBJ}
					</>
				),
				col_f: null,
				col_g: null,
			};
			break;
		case 'ts':
			// Excluded column duration formatting
			let col_e = data?.to_pay;
			if (col_e && col_e.length > 5) {
				if (col_e.split(':').length === 3) col_e = col_e.substring(0, data.to_pay.lastIndexOf(':'));
			}

			output = {
				col_a: data?.description,
				col_b: { cost_head_id: data?.cost_head_id, cost_head: data?.cost_head },
				col_c: data?.start ? DateTime.fromISO(data.start).toFormat('HH:mm') : '',
				col_d: data?.end ? DateTime.fromISO(data.end).toFormat('HH:mm') : '',
				col_e: col_e,
				col_f: data?.to_pay,
				col_g: data?.included,
				day_id: dayId,
				allocation_id: data?.id,
				is_before_sub_total_row: isBeforeSubTotalRow,
				is_last_data_row: isLastDataRow,
				type: data?.type ?? '',
			};
			break;
		case 'behaviour':
			output = {
				col_a: data?.description,
				col_b: null,
				col_c: (
					<>
						{convertSecondsToDurationString(data?.recorded)}
						{DURATION_UNITS_OBJ}
					</>
				),
				col_d: (
					<>
						{convertSecondsToDurationString(data?.expected)}
						{DURATION_UNITS_OBJ}
					</>
				),
				col_e: data?.status,
				col_f: null,
				col_g: null,
			};
			break;
	}

	// Fill blanks
	Object.keys(output).forEach(function (key, index) {
		let item: any = output[key];
		output[key] = (!item && typeof item !== 'boolean') || item.length == 0 ? EMPTY_DATA_STR : item;
	});

	output.cellClass = `data-row dr-${type}`;

	return output;
};

export const subTotalRow = (dayId: string) => {
	return {
		col_a: 'Sub Totals',
		day_id: dayId,
		cellClass: 'sub-total-row',
	};
};

export const totalRow = (dayId: string) => {
	return {
		col_a: 'Final Payment Hours',
		day_id: dayId,
		cellClass: 'total-row',
	};
};

export const spacerRow = () => {
	let output: any = {
		col_a: '',
		cellClass: 'spacer-row',
	};
	return output;
};

export const getCellClass = (params: any) => {
	const field: string = params.colDef.field;
	let cellClass: string = String(params.data.cellClass);

	switch (cellClass) {
		case 'data-row dr-ts':
			if (['col_f'].includes(field)) cellClass += ' cell-time';
			if (['col_g'].includes(field)) cellClass += ' cell-toggle';
			if (params.data.is_before_sub_total_row === true) cellClass += ' is-before-sub-total-row';
			if (params.data.is_last_data_row === true) cellClass += ' is-last-data-row';
			break;
	}

	return cellClass;
};

export const getColSpan = (params: any) => {
	const cellClass: string = String(params.data.cellClass);
	let output: number = 1;

	switch (cellClass.split(' ')[0]) {
		case 'sub-header-row':
		case 'spacer-row':
			output = 5;
			break;
	}

	return output;
};

export const renderCell = (params: any, renderers?: any) => {
	const cellClass: string = String(params.data.cellClass);
	const field: string = params.colDef.field;
	let output: any = params.value;

	switch (cellClass) {
		case 'header-row hr-expected-items':
			if (['col_a'].includes(field))
				output = (
					<>
						<FontAwesomeIcon icon={faCalendar} />
						{params.value}
					</>
				);
			break;
		case 'data-row dr-ts':
			if (['col_b'].includes(field) && renderers.cellCostHeadRenderer) {
				output = renderers.cellCostHeadRenderer(params);
			}

			if (['col_e'].includes(field) && renderers.cellExcludedRenderer) {
				output = renderers.cellExcludedRenderer(params);
			}

			if (['col_f'].includes(field) && renderers.cellTimeRenderer) {
				output = renderers.cellTimeRenderer(params);
			}

			if (['col_g'].includes(field) && renderers.cellToggleRenderer) {
				output = renderers.cellToggleRenderer(params);
			}
			break;
		case 'data-row dr-behaviour':
			if (['col_e'].includes(field) && renderers.cellBehaviourRenderer) {
				output = renderers.cellBehaviourRenderer(params);
			}
			break;
		case 'sub-total-row':
			if (['col_e', 'col_f'].includes(field) && renderers.cellTotalRenderer) {
				output = renderers.cellTotalRenderer(params);
			}
			break;
		case 'total-row':
			if (['col_f'].includes(field) && renderers.cellTotalRenderer) {
				output = renderers.cellTotalRenderer(params);
			}
			break;
	}

	return output;
};

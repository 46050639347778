import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';

type Props = {
	weekending: string;
	approved: boolean;
};

const WeekendingLabel = (props: Props) => {
	return (
		<>
			<span>Week ending: {DateTime.fromISO(props.weekending).toFormat('dd/MM/yyyy')}</span>
			{props.approved ? (
				<FontAwesomeIcon icon={faCircleCheck} className='week-ending-options-icon' />
			) : (
				<FontAwesomeIcon icon={faCircle} className='week-ending-options-icon pending' />
			)}
		</>
	);
};

export default WeekendingLabel;

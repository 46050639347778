import InternalMedia from './InternalMedia';
import ExternalMedia from './ExternalMedia';

const MediaTabsArray: any = (mediaCategory: 'worker' | 'job', mediaData ?: any) => {
	return [
		{
			id: 'internal',
			title: 'Chat Media',
			page: InternalMedia,
			active: true,
			pageData: { mediaCategory, mediaData },
		},
		{
			id: 'external',
			title: 'Client Media',
			page: ExternalMedia,
			active: false,
			pageData: { mediaCategory, mediaData },
		},
		
	];
}

export default MediaTabsArray;

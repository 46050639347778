export enum ActionType {
	SET_WORKER = 'SET_WORKER',
	UPDATE_WORKER = 'UPDATE_WORKER',
	UPDATE_ACTIVE_SUBNAV_TAB = 'UPDATE_ACTIVE_SUBNAV_TAB',
}

export interface ACTION_SET_WORKER {
	type: ActionType.SET_WORKER;
	payload: any;
}

export interface ACTION_UPDATE_WORKER {
	type: ActionType.UPDATE_WORKER;
	payload: any;
}

export interface ACTION_UPDATE_ACTIVE_SUBNAV_TAB {
	type: ActionType.UPDATE_ACTIVE_SUBNAV_TAB;
	payload: any;
}

export type Actions = ACTION_SET_WORKER | ACTION_UPDATE_WORKER | ACTION_UPDATE_ACTIVE_SUBNAV_TAB;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonCol,
	IonRow,
	IonRadio,
	IonRadioGroup,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { showToast } from '../../../../lib/toast';
import { authContext } from '../../../../contexts/AuthContext';
import { NumberField, SingleDatePicker } from '../../../../components/Forms/FormFields';
import { InvoiceContext } from '../InvoicesProvider';
import { Alert } from '@mui/material';
import { DateTime } from 'luxon';
import Loading from '../../../../components/UI/Loading';

interface PaymentModalProps {
	isOpen: boolean;
	paymentStatus: 'decision' | null;
	onClose: Function;
	onSave: Function;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ isOpen, paymentStatus, onClose, onSave }) => {
	const { state, dispatch } = useContext(InvoiceContext);
	const authCtx: any = useContext(authContext);
	const paymentRef = useRef<any>(null);
	const datePaidRef = useRef<any>(null);
	const resolveRef = useRef<any>(null);
	const [decisionUIClass, setDecisionUIClass] = useState<string>('');

	useEffect(() => {
		setDecisionUIClass(`decisionOptions${paymentStatus === 'decision' ? 'Visible' : 'Hidden'}`);
	}, [paymentStatus]);

	const handleSubmit = () => {
		const paymentValue = Number(paymentRef.current.value);
		const datePaid = datePaidRef.current.value;

		if (!datePaid || datePaid.length === 0) {
			showToast('error', 'Please enter a payment date');
			return;
		}

		if (!paymentValue || paymentValue === 0) {
			showToast('error', 'Please enter a payment gross value');
			return;
		}

		onSave(paymentValue, datePaid, resolveRef.current.value ?? null);
	};

	return (
		<IonModal
			style={{ '--width': '850px', '--height': '440px' }}
			isOpen={isOpen}
			onDidDismiss={() => onClose()}
			className='prop-form invoice-payment-modal'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Payment Received</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{state.isLoading && <Loading overlay={true} />}
				<SingleDatePicker
					title='Payment Date'
					innerRef={datePaidRef}
					value={DateTime.now().startOf('day').toISO()}
					useLabels={true}
					editMode={true}
					noForm={true}
				/>

				<NumberField
					title={`Payment Received Gross (${authCtx.tenant.currency_symbol})`}
					fieldRef={paymentRef}
					value={''}
					useLabels={true}
					editMode={true}
					hideSpinner={true}
					noForm={true}
					decimalLimit={2}
					allowEmpty={true}
				/>

				<div className={decisionUIClass}>
					<Alert severity='warning' className='mt-3 m-1'>
						The sum received does not match the sum claimed
					</Alert>
					<IonRadioGroup ref={resolveRef}>
						<IonRow>
							<IonCol size={'10'}>
								The client is correct, issue credit note, and mark as paid.
							</IonCol>
							<IonCol size={'2'} className='text-center'>
								<IonRadio value='credit' disabled={state.isLoading} />
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size={'10'}>
								We are correct, record paid amount, adjust total to be paid to show balance
								outstanding, leave status as part paid.
							</IonCol>
							<IonCol size={'2'} className='text-center'>
								<IonRadio value='part' disabled={state.isLoading} />
							</IonCol>
						</IonRow>
					</IonRadioGroup>
				</div>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size={'12'} className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()} disabled={state.isLoading}>
								Cancel
							</IonButton>
							<IonButton color='primary' onClick={handleSubmit} disabled={state.isLoading}>
								Submit
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default PaymentModal;

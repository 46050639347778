import { useState, useEffect, useContext } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Alert, AlertTitle } from '@mui/material';
import { TextField, NumberField } from '../../../../components/Forms/FormFields';
import { IonRow, IonCol, IonCard, IonButton, IonInput, useIonAlert } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import FilterMenu from '../../../../components/Menu/FilterMenu';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import { moduleContext } from '../../../../contexts/ModuleContext';
import Form from "../../../../components/Forms/Form";
import './WorkersOvertimeRates.scss';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';

const WorkersOvertimeRatesIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [data, setData] = useState<Array<any>>([]);
	const [dataLoading, setDataLoading] = useState<boolean>(true);
	const [rolesLoading, setRolesLoading] = useState<boolean>(true);
	const [companyDetailsSettingsLoading, setCompanyDetailsSettingsLoading] = useState<boolean>(true);
	const [roleID, setRoleID] = useState<string>('');
	const [roles, setRoles] = useState<Array<any>>([]);
	const [companyDetailsSettings, setCompanyDetailsSettings] = useState<any>();
	const [newRates, setNewRates] = useState<Array<any>>([]);
	const [presentAlert] = useIonAlert();

	const overtimeSalariedValues: Array<any> = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	];

	const rateCategories = [
		{ id: 'weekdays', title: 'Monday to Friday' },
		{ id: 'saturdays', title: 'Saturdays' },
		{ id: 'sundays', title: 'Sundays' },
		{ id: 'bank-holidays', title: 'Bank Holidays' },
	];

	const rateFields = [
		{ id: 'description', title: 'Description' },
		{ id: 'hours_min', title: 'Minimum Hours' },
		{ id: 'hours_max', title: 'Maximum Hours' },
		{ id: 'base_rate_multiplier', title: 'Base Rate Multiplier' },
	];

	useEffect(() => {
		loadRoles();
		loadCompanyDetails();
	}, []);

	useEffect(() => {
		if (rolesLoading === false && companyDetailsSettingsLoading === false) {
			loadData(null, '');
		}
	}, [rolesLoading, companyDetailsSettingsLoading]);

	const loadRoles = () => {
		setRolesLoading(true);

		moduleCtx.getSecuritySettingsTemplatesOptions().then((res: any) => {
			const rowData = res.map((d: any, i: number) => {
				return {
					_id: d.value,
					label: d.label,
					colour: d.colour,
					colourDark: d.colourDark,
				}
			});
			setRoles([{ _id: '', label: 'Default Rates', colour: null }, ...rowData]);
			setRolesLoading(false);
		});
	}

	const loadCompanyDetails = () => {
		setCompanyDetailsSettingsLoading(true);

		axios.get("/api/utilities/company_details/settings").then((res) => {
			setCompanyDetailsSettings(res.data[0]);
		}).catch((err) => {
			console.log(err);
		}).finally(() => {
			setCompanyDetailsSettingsLoading(false);
		});
	}

	const loadData = (event: any, filterRoleID: string) => {
		setData([]);
		setDataLoading(true);
		setRoleID(filterRoleID);

		const payload = (filterRoleID.length > 0) ? { role_id: filterRoleID } : {};

		axios.post('/api/utilities/workers/overtime_rates', payload).then((res) => {
			setData(res.data);
			setDataLoading(false);
		});
	}

	const handleCreate = (catID: string) => {
		const key = `new__${catID}`;
		const description = (newRates.filter((r: any) => r.id === `${key}__description`)[0]?.fieldValue ?? '').trim();
		const hoursMin = (newRates.filter((r: any) => r.id === `${key}__hours_min`)[0]?.fieldValue ?? '').trim();
		const hoursMax = (newRates.filter((r: any) => r.id === `${key}__hours_max`)[0]?.fieldValue ?? '').trim();
		const baseRateMultiplier = (newRates.filter((r: any) => r.id === `${key}__base_rate_multiplier`)[0]?.fieldValue ?? '').trim();

		let payload: any = {
			type: catID,
			description: description,
			hours_min: isNaN(Number(hoursMin)) ? 0 : hoursMin,
			hours_max: isNaN(Number(hoursMax)) ? 0 : hoursMax,
			base_rate_multiplier: isNaN(Number(baseRateMultiplier)) ? 0 : baseRateMultiplier
		}

		if (roleID) payload.role_id = roleID;

		if (
			payload.description.length === 0 ||
			payload.hours_min.length === 0 ||
			payload.hours_max.length === 0 ||
			payload.base_rate_multiplier.length === 0
		) {
			showToast("error", "Please fill-in the entire row");
			return;
		}

		const toastID = toast.loading("Please wait...");

		axios.put('/api/utilities/workers/overtime_rates', payload).then((res) => {
			// Clear the creation row
			setNewRates((prevState: any) => {
				return prevState.filter((item: any) => item.id.substring(0, key.length) !== key);
			});

			// Add the newly created rate to the data array
			setData(prevState => [...prevState, { _id: res.data._id, ...payload }]);

			showToast("saved", null, toastID);
		}).catch((err) => {
			showToast("error", null, toastID);
		});
	}

	const handleNewRateKeyUp = (e: any) => {
		const id = e.target.offsetParent.id;
		const catID = id.split("__")[1];
		const val = e.target.value;
		const newValue = { id: id, fieldValue: val };

		if (e.key.toLowerCase() === 'enter') {
			e.preventDefault();
			handleCreate(catID);
			return;
		}

		if (!newRates.some((item: any) => item.id === id)) {
			// Add new value
			setNewRates([...newRates, newValue]);
		} else {
			// Update value
			setNewRates((prevState: any) => {
				return prevState.map((item: any) => {
					if (item.id === id) return newValue;
					return item;
				});
			});
		}
	}

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	}

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading("Please wait...");

		axios.delete(`/api/utilities/workers/overtime_rates/${id}`).then((res) => {
			setData(prevState => prevState.filter(d => d._id !== id));

			showToast("deleted", null, toastID);
		}).catch((err) => {
			showToast("error", null, toastID);
		});
	}

	const handleUpdate = (id: string, catID: string) => {
		const descriptionParent = (document.getElementById(`description__${id}`) as HTMLElement);
		const description = descriptionParent.getElementsByTagName("input")[0].value.toString();

		const hoursMaxParent = (document.getElementById(`hours_max__${id}`) as HTMLElement);
		const hoursMax = hoursMaxParent.getElementsByTagName("input")[0].value.toString();

		const hoursMinParent = (document.getElementById(`hours_min__${id}`) as HTMLElement);
		const hoursMin = hoursMinParent.getElementsByTagName("input")[0].value.toString();

		const baseRateMultiplierParent = (document.getElementById(`base_rate_multiplier__${id}`) as HTMLElement);
		const baseRateMultiplier = baseRateMultiplierParent.getElementsByTagName("input")[0].value.toString();

		let payload: any = {
			_id: id,
			type: catID,
			description: description,
			hours_min: isNaN(Number(hoursMin)) ? 0 : hoursMin,
			hours_max: isNaN(Number(hoursMax)) ? 0 : hoursMax,
			base_rate_multiplier: isNaN(Number(baseRateMultiplier)) ? 0 : baseRateMultiplier
		}

		if (roleID) payload.role_id = roleID;

		const toastID = toast.loading("Please wait...");

		axios.put(`/api/utilities/workers/overtime_rates/${id}`, payload).then((res) => {
			showToast("saved", null, toastID);
		}).catch((err) => {
			showToast("error", null, toastID);
		});
	}

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonRow>
				<IonCol size={'2'} className="pt-0">
					<FilterMenu loading={rolesLoading || companyDetailsSettingsLoading} items={roles} handleOnClick={loadData} />
				</IonCol>
				<IonCol size={'10'} className="p-0">
					<IonCard className="table-card">
						<h1 className="card-title">{`${routeTitle}: ${roles.filter(a => a._id === roleID)[0]?.label ?? 'Loading...'}`}</h1>
						{roleID.length > 0 && (
							<Alert severity="info">
								<AlertTitle>Info</AlertTitle>
								Any rates entered into this {roles.filter(a => a._id === roleID)[0].label} role
								will override the rates in the Default Rates section.
							</Alert>
						)}
						<IonRow>
							<IonCol size={'12'}>
								{companyDetailsSettingsLoading === false && (
									<Form
										array={[{
											id: "overtime_salaried",
											title: "Include salaried staff",
											type: "dropdown",
											defaultValue: overtimeSalariedValues.filter((o: any) => o.value === companyDetailsSettings.settings.overtime_salaried),
											values: overtimeSalariedValues,
											db: ['settings', 'overtime_salaried'],
											disabled: true
										}]}
										forceEdit={permissionTo('update')}
										noButton={true}
										permissionTo={permissionTo}
										endpoint={'/api/utilities/company_details/settings'}
										endpointID={companyDetailsSettings._id}
									/>
								)}
							</IonCol>
						</IonRow>
						{rateCategories.map((cat: any, i: number) => {
							return (
								<IonRow key={i}>
									<IonCol size={'12'}>
										<h5 className="font-bold">{cat.title}</h5>
										<div className="info-box overtime-rates">
											<Table size="small">
												<TableHead>
													<TableRow>
														{rateFields.map((field: any, j: number) => {
															return (
																<TableCell key={j}>{field.title}</TableCell>
															)
														})}
														<TableCell></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{data.map((d: any, k: number) => {
														let row = null;
														if (d.type === cat.id) {
															row = (
																<TableRow key={k}>
																	<TableCell>
																		<TextField
																			id={`description__${d._id}`}
																			value={d.description}
																			editMode={permissionTo('update')}
																			useLabels={false}
																			disabled={dataLoading}
																			handleUpdateValue={() => handleUpdate(d._id, cat.id)}
																		/>
																	</TableCell>
																	<TableCell>
																		<NumberField
																			id={`hours_min__${d._id}`}
																			value={String(d.hours_min)}
																			editMode={permissionTo('update')}
																			useLabels={false}
																			disabled={dataLoading}
																			handleUpdateValue={() => handleUpdate(d._id, cat.id)}
																		/>
																	</TableCell>
																	<TableCell>
																		<NumberField
																			id={`hours_max__${d._id}`}
																			value={String(d.hours_max)}
																			editMode={permissionTo('update')}
																			useLabels={false}
																			disabled={dataLoading}
																			handleUpdateValue={() => handleUpdate(d._id, cat.id)}
																		/>
																	</TableCell>
																	<TableCell>
																		<NumberField
																			id={`base_rate_multiplier__${d._id}`}
																			value={String(d.base_rate_multiplier)}
																			editMode={permissionTo('update')}
																			useLabels={false}
																			disabled={dataLoading}
																			handleUpdateValue={() => handleUpdate(d._id, cat.id)}
																		/>
																	</TableCell>
																	<TableCell align="right">
																		{permissionTo('delete') && (
																			<IonButton onClick={() => handleDelete(d._id)} color="danger" disabled={dataLoading}>
																				<FontAwesomeIcon className="icon-delete" icon={faCircleXmark} />
																			</IonButton>
																		)}
																	</TableCell>
																</TableRow>
															);
														}
														return row;
													})}
													{permissionTo('create') && (
														<>
															<TableRow className="new-rate">
																{rateFields.map((field: any, j: number) => {
																	const fieldID = `new__${cat.id}__${field.id}`;
																	const fieldData = newRates.filter((r: any) => r.id === fieldID)[0];
																	return (
																		<TableCell key={j}>
																			<IonInput
																				id={fieldID}
																				value={fieldData?.fieldValue}
																				placeholder={`New ${field.title}...`}
																				disabled={dataLoading}
																				onKeyUp={handleNewRateKeyUp}
																			/>
																		</TableCell>
																	)
																})}
																<TableCell align="right">
																	{permissionTo('create') && (
																		<IonButton onClick={() => handleCreate(cat.id)} color="success" disabled={dataLoading}>
																			<FontAwesomeIcon className="icon-add" icon={faCirclePlus} />
																		</IonButton>
																	)}
																</TableCell>
															</TableRow>
														</>
													)}
												</TableBody>
											</Table>
										</div>
									</IonCol>
								</IonRow>
							)
						})}
					</IonCard>
				</IonCol>
			</IonRow>
		</>
	);
};

export default WorkersOvertimeRatesIndex;

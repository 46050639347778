import { useState, useEffect, useContext } from 'react';
import { IonButton, IonCol, IonInput, IonLabel, IonRow } from '@ionic/react';
import { DateTime } from 'luxon';

import axios from '../../lib/axios';
import { authContext } from '../../contexts/AuthContext';
import { googleMapsContext } from '../../contexts/GoogleMapsContext';
import countries from './../../helpers/countries.json';
import timezones from './../../helpers/timezones.json';
import FlagIcons from '../Icons/FlagIcons';
import SelectStyled from '../UI/SelectStyled';

interface AddressLookupProps {
	id: string;
	title: string;
	editMode: boolean;
	placeholder?: string;
	useLabels: boolean;
	disabled?: boolean;
	addressLookupFunction: Function;
	oneLine?: boolean;
}

export const AddressLookup: React.FC<AddressLookupProps> = (props: AddressLookupProps) => {
	const [postcode, setPostcode] = useState<string>('');
	const [addresses, setAddresses] = useState<Array<any>>([]);
	const [selectedAddress, setSelectedAddress] = useState<Array<any>>([]);
	const [selectedCountry, setSelectedCountry] = useState<any>({});
	const [showSelection, setShowSelection] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const authCtx = useContext<any>(authContext);
	const googleMapsCxt = useContext<any>(googleMapsContext);

	const [placesService, setPlacesService] = useState<
		google.maps.places.AutocompleteService | undefined
	>(undefined);
	const [geoCodeService, setGeoCodeService] = useState<google.maps.Geocoder | undefined>(undefined);

	useEffect(() => {
		const timezone: string = DateTime.now().toFormat('z');
		const localTimezones: any = timezones;
		const _country = localTimezones[timezone].c[0];
		//setSelectedCountry(_country);
		const country = countries.find((item) => {
			return item['alpha-2'] === _country;
		});
		if (country) {
			setSelectedCountry({
				value: country['alpha-2'],
				label: country.name,
			});
		}
	}, []);

	useEffect(() => {
		setIsEdit(props.editMode);
	}, [props.editMode]);

	useEffect(() => {
		if (googleMapsCxt.isLoaded) {
			setTimeout(() => {
				initializeService();
			}, 100);
		}
	}, [googleMapsCxt.isLoaded]);

	const initializeService = () => {
		if (!window.google)
			throw new Error('[Address Lookup]: Google script not loaded');
		if (!window.google.maps)
			throw new Error('[Address Lookup]: Google maps script not loaded');
		if (!window.google.maps.places)
			throw new Error('[Address Lookup]: Google maps places script not loaded');

		setPlacesService(new window.google.maps.places.AutocompleteService());
		setGeoCodeService(new window.google.maps.Geocoder());
	};

	const handleFieldKeyup = (e: any) => {
		if (e.key.toLowerCase() === 'enter') {
			e.preventDefault();
		}
		setPostcode(e.target.value);
	};

	const handleLookupClick = async () => {
		if (!postcode || postcode.length === 0) {
			return false;
		}

		setIsLoading(true);

		if (selectedCountry.value === 'GB') {
			ukLookup();
		} else {
			internationalLookup();
		}
	};

	const internationalLookup = async () => {
		let request: google.maps.places.AutocompletionRequest = {
			input: postcode,
			componentRestrictions: { country: selectedCountry.value },
		};

		placesService?.getPlacePredictions(
			request,
			(suggestions: google.maps.places.AutocompletePrediction[] | null) => {
				if (suggestions !== null) {
					let results = suggestions.map((suggestion: google.maps.places.AutocompletePrediction) => {
						return { label: suggestion.description, value: suggestion.place_id };
					});
					// Result message
					const resLength = results.length;
					const resMessage = `${resLength} result${resLength !== 1 ? 's' : ''}`;
					setSelectedAddress([{ value: 0, label: resMessage }]);

					setAddresses(results);

					setShowSelection(true);
					setIsLoading(false);
					
				} else {
					setShowSelection(true);
					setAddresses([]);
					setSelectedAddress([{ value: 0, label: 'No Results' }]);
					setIsLoading(false);
				}
			}
		);
	}

	const ukLookup = async () => {
		const apikey = process.env.REACT_APP_POSTCODER_API_KEY;
		const postcodeString = encodeURIComponent(postcode);
		const endpoint =
			`https://ws.postcoder.com/pcw/${apikey}/address/` +
			`GB/${postcodeString}?format=json&lines=2&addtags=latitude,longitude` +
			`&identifier=${authCtx.tenant.id}`;

		if (!postcode || postcode.length === 0) {
			return false;
		}

		setIsLoading(true);

		await axios({
			method: 'get',
			url: endpoint,
			withCredentials: false,
		})
			.then((res) => res.data)
			.then((res) => {
				let results = res.map((suggestion: any, i: number) => {
					return { label: suggestion.summaryline, value: i, suggestion };
				});
				// Result message
				const resLength = results.length;
				const resMessage = `${resLength} result${resLength !== 1 ? 's' : ''}`;
				setSelectedAddress([{ value: 0, label: resMessage }]);

				setAddresses(results);

				setShowSelection(true);
			})
			.catch(() => {
				setShowSelection(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	const onChange = async (e: any) => {
		let address: any = {};
		
		if (Number.isInteger(e.value)) {
			props.addressLookupFunction(addresses[e.value].suggestion, props.id);
		} else {
			let addressObj: google.maps.GeocoderResult = await new Promise((res1, rej1) => {
				geoCodeService?.geocode(
					{
						placeId: e.value,
					},
					(responses: google.maps.GeocoderResult[] | null) => {
						if (responses !== null) {
							res1(responses[0]);
						}
					}
				);
			});
			let parts: any = {
				house_no: '',
				street: '',
				address2: '',
				town: '',
				county: '',
				postcode: '',
			};
			if (addressObj.hasOwnProperty('address_components')) {
				await addressObj.address_components.forEach(
					(component: google.maps.GeocoderAddressComponent) => {
						component.types.forEach((type: string) => {
							switch (type) {
								case 'street_number':
									parts.house_no = component.long_name;
									break;
								case 'route':
									parts.street = component.long_name;
									break;
								case 'sublocality':
									parts.address2 = component.long_name;
									break;
								case 'postal_town':
									parts.town = component.long_name;
									break;
								case 'administrative_area_level_2':
									parts.county = component.long_name;
									break;
								case 'administrative_area_level_1':
									parts.county += ', ' + component.long_name;
									break;
								case 'postal_code':
									parts.postcode = component.long_name;
									break;
								case 'postal_code_suffix':
									parts.postcode += ' ' + component.long_name;
									break;
							}
						});
					}
				);

				address = {
					addressline1: parts.house_no + ' ' + parts.street,
					addressline2: parts.address2,
					posttown: parts.town === '' ? parts.county : parts.town,
					county: parts.county,
					postcode: parts.postcode,
					latitude: addressObj.geometry.location.lat(),
					longitude: addressObj.geometry.location.lng(),
				};
				props.addressLookupFunction(address, props.id);
			}
		}
		setSelectedAddress(e ? e : null);
	};

	const postcodeInput = (
		<IonInput
			id={props.id}
			name={props.title}
			placeholder={props.placeholder ? props.placeholder : 'Enter here'}
			onKeyUp={(e) => handleFieldKeyup(e)}
			disabled={props.disabled}
		/>
	);

	const btnFind = () => {
		let style: any = {
			margin: '0 0 0 8px',
		};

		if (props.oneLine) {
			style.height = '33px';
		}

		return (
			<div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6 }}>
				<div style={{ flex: 1 }}>
					<SelectStyled
						isSearchable={true}
						onChange={(e: any) => {
							setSelectedCountry(e);
						}}
						formatOptionLabel={(country: any) => (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<FlagIcons height={'12px'} countryCode={country.value} />
								<span style={{ marginLeft: 6 }}>
									{props.oneLine ? country.value : country.label}
								</span>
							</div>
						)}
						value={selectedCountry}
						options={countries.map((country: any, i: number) => {
							return {
								value: country['alpha-2'],
								label: country.name,
							};
						})}
					/>
				</div>
				<IonButton
					color='primary'
					size='small'
					disabled={props.disabled}
					onClick={() => handleLookupClick()}
					style={style}
				>
					Find Address
				</IonButton>
			</div>
		);
	};

	return isEdit ? (
		<>
			<IonRow className='prop-form prop-form-edit'>
				{props.useLabels !== false && (
					<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
						<IonLabel position='floating'>{props.title}</IonLabel>
					</IonCol>
				)}
				<IonCol
					size={'12'}
					sizeMd={props.useLabels === false ? '12' : '6'}
					sizeLg={props.useLabels === false ? '12' : '9'}
				>
					{props.oneLine === true && (
						<IonRow>
							<IonCol className='ps-0 py-0 col-6' style={{paddingRight: 3}}>
								{postcodeInput}
							</IonCol>
							<IonCol className='pe-0 py-0' style={{ flexGrow: 1, marginTop: -6 }}>
								{btnFind()}
							</IonCol>
						</IonRow>
					)}
					{props.oneLine !== true && (
						<>
							{postcodeInput}
							{btnFind()}
						</>
					)}
				</IonCol>
			</IonRow>
			<IonRow
				className='prop-form prop-form-edit'
				style={{ display: `${showSelection ? 'flex' : 'none'}` }}
			>
				{props.useLabels !== false && (
					<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
						<IonLabel position='floating'>&nbsp;</IonLabel>
					</IonCol>
				)}
				<IonCol
					size={'12'}
					sizeMd={props.useLabels === false ? '12' : '6'}
					sizeLg={props.useLabels === false ? '12' : '9'}
				>
					<SelectStyled
						isLoading={isLoading}
						isSearchable={true}
						onChange={(e: any) => {
							onChange(e);
						}}
						value={selectedAddress}
						options={addresses.map((address: any, i: number) => {
							return { value: address.value, label: address.label };
						})}
					/>
				</IonCol>
			</IonRow>
		</>
	) : null;
};
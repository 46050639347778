import { createContext } from "react";

import useGoogleMapsHandler from '../utils/custom-hooks/GoogleMapsHandler';

export const googleMapsContext = createContext({});

const { Provider } = googleMapsContext;

const GoogleMapsProvider = ({ children, ...props }: any) => {
	const { isLoaded } =
		useGoogleMapsHandler();

	return (
		<Provider value={{ isLoaded }}>
			{children}
		</Provider>
	);
};

export default GoogleMapsProvider;
import { createContext } from "react";
import useAuthHandler from "../utils/custom-hooks/AuthHandler";

export const authContext = createContext({});

const { Provider } = authContext;

const AuthProvider = ({ children, ...props }: any) => {
	const { isAuthenticated, user, tenant, navData, setAuthStatus, setUnauthStatus, setUserData } =
		useAuthHandler(props.initialState);

	return (
		<Provider
			value={{ isAuthenticated, user, tenant, navData, setAuthStatus, setUnauthStatus, setUserData }}
		>
			{children}
		</Provider>
	);
};

export default AuthProvider;
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonItem,
	IonLabel,
} from '@ionic/react';
import {
	faCircleDown,
	faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import sanitize from 'sanitize-html';
import { MessageInterface } from '../../../interfaces/Chat/MessageInterface';
import './MessageItem.scss';
import { humanFileSize } from '../../../helpers/file';

interface Props {
	item: MessageInterface;
	index: number;
	preview?: boolean;
	group?: boolean;
	participants?: Array<any>;
}

const PreviewItem: React.FC<Props> = (props: Props) => {
	const message: MessageInterface | null = useMemo(() => {
		return props.item !== undefined ? props.item : null;
	}, [props.item]);

	return (
		<>
			<div
				key={message?._id}
				id={message?._id}
				className={`message ${message?.own ? 'own' : ''} ${
					message?.isRead === false ? 'not-read' : ''
				} ${
					props.preview === undefined || props.preview === null || props.preview === false
						? ''
						: 'preview'
				}`}
			>
				<IonCard>
					<IonCardHeader>
						<IonItem>
							<IonLabel>
								<h4>{message?.created_by}</h4>
							</IonLabel>
						</IonItem>
					</IonCardHeader>
					{message?.file !== undefined &&
						message?.file_type !== undefined &&
						message?.file_type.indexOf('image/') > -1 && (
							<div
								className='thumbnail'
								style={{ backgroundImage: `url(${message.file_thumb})` }}
							></div>
						)}
					{message?.file !== undefined &&
						message?.file_type !== undefined &&
						message?.file_type.indexOf('video/') > -1 && (
							<div
								className='thumbnail'
								style={{ backgroundImage: `url(${message.file_thumb})` }}
							>
								<div className='play-icon'>
									<FontAwesomeIcon icon={faPlay} />
								</div>
							</div>
						)}
					{message?.file !== undefined &&
						message?.file_type !== undefined &&
						message.file_type.indexOf('image/') === -1 &&
						message.file_type.indexOf('video/') === -1 && (
							<a href={message.file} download={message.file_name} className='doc-link'>
								<div>
									<div>{message.file_name}</div>
									<div className='file-size'>{humanFileSize(message.file_size ?? 0)}</div>
								</div>
								<div className='file-icon'>
									<FontAwesomeIcon icon={faCircleDown} />
								</div>
							</a>
						)}
					<IonCardContent>
						<IonLabel>
							<p
								dangerouslySetInnerHTML={{
									__html: sanitize(props.item?.message ?? '', {
										allowedTags: ['mark'],
										disallowedTagsMode: 'escape',
									}),
								}}
							></p>
						</IonLabel>
					</IonCardContent>
				</IonCard>
			</div>
		</>
	);
};

export default PreviewItem;

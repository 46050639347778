import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

const useErrorHandler = (initialState: any) => {
	const [error, setError] = useState(initialState);

	useEffect(() => {
		const timeoutId = window.setTimeout(() => {
			setError(null);
		}, 3000);

		return () => window.clearTimeout(timeoutId);
	}, [error]);

	const showError = (errorMessage: any) => {
		setError({ ts: DateTime.now(), errorMessage });
	};
	return { error, showError };
};

export default useErrorHandler;

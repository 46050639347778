import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { Eventcalendar } from '@mobiscroll/react';
import { DateTime } from 'luxon';

interface DayScheduleModalProps {
	isOpen: boolean;
	selectedDate: DateTime;
	events: Array<any>;
	onClose: Function;
	onDidDismiss: Function;
}

const DayScheduleModal: React.FC<DayScheduleModalProps> = ({
	isOpen,
	selectedDate,
	events,
	onClose,
	onDidDismiss,
}) => {
	return (
		<IonModal
			class='worker-diary-day-schedule-modal'
			style={{ '--width': '90vw', '--height': '90vh' }}
			isOpen={isOpen}
			onDidDismiss={() => onDidDismiss()}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Day Schedule for {selectedDate.toFormat('cccc dd/MM/yyyy')}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<Eventcalendar
					data={events}
					height={'calc(100% + 130px)'}
					defaultSelectedDate={selectedDate}
					view={{
						schedule: {
							type: 'day',
							allDay: false,
						},
					}}
					renderHeader={() => {
						return null;
					}}
				/>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default DayScheduleModal;

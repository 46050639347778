import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

const AuthenticatingMessage = () => {
	return (
		<div className="authenticating-message">
			<div><FontAwesomeIcon icon={faCog} className="fa-spin" /></div>
			<div>Securing your connection, please wait...</div>
		</div>
	)
}

export default AuthenticatingMessage
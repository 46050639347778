import { useEffect, useState } from 'react';
import { publish, useSubscribe } from '../../helpers/events';
import { useInterval } from './useInterval';

const useWebSocketHandler = () => {
	const [conn, setConn] = useState < WebSocket | null>(null);
	const [attempts, setAttempts] = useState(0);
	const [delay, setDelay] = useState<number | null>(null);
	const [isConnected, setIsConnected] = useState<boolean>(false);

	useInterval(() => {
		if (attempts < 5) {
			websocketLogin();
			setAttempts((prevValue) => prevValue + 1);
		} else {
			setDelay(null);
			setAttempts(0);
		}
	}, delay);

	const login = (data: any) => {
		websocketLogin();
	};

	useSubscribe('login', login, []);
	useSubscribe(
		'logout',
		(data: any) => {
			conn?.close();
			setConn(null);
		},
		[]
	);
	useSubscribe('websocket:reconnect', login, []);
	useSubscribe(
		'websocket:message',
		(data: any) => {
			conn?.send(data);
		},
		[conn]
	);

	useEffect(() => {
		if (conn !== null) {
			conn.onopen = (e: any) => {
				setDelay(null);
				setAttempts(0);
				setIsConnected(true);
				console.log('Connection established!');
			};

			conn.onerror = (e: any) => {
				console.log(e);
			};

			conn.onmessage = (e: any) => {
				let data = JSON.parse(e.data);
				switch (data.type) {
					case 'Sync:syncItem':
						publish('database:sync:Item', data.data);
						break;
					case 'User:online':
						publish('participantOnline', data.data);
						break;
					case 'User:lastOnline':
						publish('userLastOnline', data.data);
						break;
					default:
						publish(data.type, data.data);
						break;
				}
			};

			conn.onclose = (e: any) => {
				console.log('Connection lost!');
				conn?.close();
				setConn(null);
				setIsConnected(false);
				setDelay(5000);
			};
		}
	}, [conn])

	const websocketLogin = () => {
		let URL = process.env.REACT_APP_WS_URL ?? '';
		if (conn === null) {
			setConn(new WebSocket(URL));
		}
	};

	const sendMessage = (data: string) => {
		conn?.send(data);
	}

	return {
		isConnected,
		sendMessage
	};
};

export default useWebSocketHandler;

import { IonBadge } from '@ionic/react';
import chroma from 'chroma-js';

interface Props {
	mode: 'inline' | 'positioned';
	roleName?: string;
	roleColour?: string;
	style?: any;
}

const RoleBadge = (props: Props) => {
	const className = 'role-badge';
	const fontColour = chroma.contrast(`#${props.roleColour}`, 'white') > 2 ? 'white' : 'black';
	const icon: any = (
		<IonBadge
			style={{
				...props.style,
				'--background': `#${props.roleColour}`,
				'--color': fontColour,
			}}
		>
			{props.roleName}
		</IonBadge>
	);
	let badge: JSX.Element = icon;

	if (props.mode === 'inline') {
		badge = <span className={className}>{icon}</span>;
	} else if (props.mode === 'positioned') {
		badge = <div className={className}>{icon}</div>;
	}

	return badge;
};

export default RoleBadge;

import { isVisible } from './ui';

export function onMenuOpen() {
	/**
	 * Scroll the selected value into view - fixes ongoing bug with react-select:
	 * https://github.com/JedWatson/react-select/issues/3769
	 * Use as the function call for onMenuOpen={} prop
	 */
	setTimeout(() => {
		const selectedEl = document.getElementsByClassName('react-select__option--is-selected')[0];
		const menuEl = document.getElementsByClassName('react-select__menu')[0];

		if (selectedEl && menuEl && !isVisible(selectedEl, menuEl)) {
			selectedEl.scrollIntoView();
		}
	}, 15);
}

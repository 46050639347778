import { IonBadge, IonCard, useIonAlert } from '@ionic/react';
import { menuController } from '@ionic/core/components';
import Timeline from '../../components/Calendars/Timeline';
import TitleBar from '../../components/TitleBar/TitleBar';
import { RouteIndexComponent } from '../../interfaces/Pages/RouteIndexComponent';
import { Header } from './components/Header';
import { Resource, ResourceFooter } from './components/Resource';
import { Footer } from './components/Footer';
import { ScheduleEvent } from './components/ScheduleEvent';
import { createRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
	FooterData,
	ResourceMode,
	ViewMode,
	Views,
	DayScheduleModalDefaults,
	SchedulingEvent,
	EventType,
	SideMenuAction,
} from './scheduling-types';
import { SchedulingContext } from './SchedulingProvider';
import { moduleContext } from '../../contexts/ModuleContext';
import Loading from '../../components/UI/Loading';
import {
	Eventcalendar,
	MbscCalendarEvent,
	MbscCellClickEvent,
	MbscPageChangeEvent,
	MbscResource,
} from '@mobiscroll/react';
import { ActionType } from './actions';
import { createPortal } from 'react-dom';
import { DateTime, Interval } from 'luxon';
import SideMenu from './components/SideMenu';
import { baseColours } from '../../components/Calendars/Defaults';
import { showToast } from '../../lib/toast';
import axios from '../../lib/axios';
import { getTimezone } from '../../lib/functions';
import CalendarContextMenu from '../../components/Calendars/CalendarContextMenu';
import DayScheduleModal from './modals/DayScheduleModal';
import useCalendarContextMenu from './hooks/useCalendarContextMenu';
import EventTip from '../../components/Calendars/EventTip';
import { JobStatus } from '../Jobs/Jobs/job-types';
import { cloneDeep, uniqBy } from 'lodash';
import { loadBankHolidays, parseCalendarEvent } from '../../helpers/calendar';
import { useHistory, useLocation } from 'react-router';
import { getCalCellLocationClass } from './scheduling-functions';

const Scheduling: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { state, dispatch } = useContext(SchedulingContext);
	const moduleCtx = useContext<any>(moduleContext);
	const [presentAlert] = useIonAlert();
	const location = useLocation();
	let history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const [goUpdateAvailableWorkers, setGoUpdateAvailableWorkers] = useState<any>({});
	const [calInst, setCalInst] = useState<Eventcalendar>();
	const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [eventIsLoading, setEventIsLoading] = useState<boolean>(false);
	const [events, setEvents] = useState<Array<any>>([]);
	const [resources, setResources] = useState<Array<any>>([]);
	const [resourceModeOptions, setResourceModeOptions] = useState<any>({});
	const [resourcesSelfAvailability, setResourcesSelfAvailability] = useState<Array<any>>([]);
	const [colours, setColours] = useState<Array<any>>([]);
	const [coloursSelf, setColoursSelf] = useState<Array<any>>([]);
	const [invalid, setInvalid] = useState<Array<any>>([]);
	const [invalidSelf, setInvalidSelf] = useState<Array<any>>([]);
	const [bankHolidays, setBankHolidays] = useState<Array<any>>([]);
	const [resourceRefs, setResourceRefs] = useState<Array<any>>([]);
	const views: Views = {
		month: {
			timeline: {
				type: ViewMode.MONTH,
				resolution: 'day',
				eventList: false,
				startDay: 1,
				endDay: 0,
				virtualScroll: false,
			},
		},
		week: {
			timeline: {
				type: ViewMode.WEEK,
				resolution: 'day',
				eventList: false,
				startDay: 1,
				endDay: 0,
				virtualScroll: false,
			},
		},
	};

	// Modals
	const dayScheduleModalDefaults: DayScheduleModalDefaults = {
		isOpen: false,
		selectedDate: DateTime.now(),
		events: [],
		resource: null,
	};
	const [dayScheduleModal, setDayScheduleModal] =
		useState<DayScheduleModalDefaults>(dayScheduleModalDefaults);

	// Right-click context menu
	const [contextMenuInst, setContextMenuInst] = useState<any>();
	const [contextMenuAnchor, setContextMenuAnchor] = useState<any>();
	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
	const [contextMenuRightClickedEvent, setContextMenuRightClickedEvent] = useState<any>(null);
	const contextMenuOptions: Array<any> = useCalendarContextMenu(
		permissionTo,
		contextMenuRightClickedEvent,
		state,
		events,
		resources,
		{
			setDayScheduleModal,
			handleDeleteEvent,
		}
	);

	// Open the side-menu
	useEffect(() => {
		if (state.tempEvent?.start && state.tempEvent?.end) {
			handleSideMenuOpen();
		}
	}, [state.tempEvent]);

	// Initial load
	useEffect(() => {
		// Set resources
		if (location && location.hash) {
			// Use the provided hash as the resource mode
			handleResourceModeChange(getResourceModeHash());
		} else {
			// Use the initial state as the resource mode
			handleResourceModeChange(state.resourceMode);
		}
	}, []);

	// Filter change re-load
	useEffect(() => {
		// Check if the menu is open
		menuController.getOpen().then((res: any) => {
			if (res === undefined && calInst) {
				// Re-load the resource view as long as the side menu is closed
				handleResourceModeChange(state.resourceMode);
			}
		});
	}, [state.filter]);

	// Available workers with abort controller
	useEffect(() => {
		const abortController = new AbortController();

		const updateAvailableWorkers = (fromDay: Date, toDay: Date, resourceMode: ResourceMode) => {
			// Show footer totals as loading
			setResourceModeOptions({
				renderResourceFooter: () => ResourceFooter(true),
				renderDayFooter: (data: FooterData) => Footer(data, []),
			});

			let payload: any = { start: fromDay, end: toDay };

			// Send the boss ID if filtering by a leader to get their team availability values back
			if (state.filter?.leader) payload.boss_id = state.filter.leader.value;

			axios
				.post('/api/scheduling/available_workers', payload, {
					signal: abortController.signal,
				})
				.then((res: any) => {
					if (res && res.data) {
						// Re-apply the footer each time
						setResourceModeOptions(() => ({
							renderResourceFooter: () => ResourceFooter(false),
							renderDayFooter: (data: FooterData) => Footer(data, res.data),
						}));
					}
				})
				.catch((err: any) => {
					if (err && err.code && err.code !== 'ERR_CANCELED') {
						showToast('error');
					}
				});
		};

		if (
			calInst &&
			(state.resourceMode === ResourceMode.JOBS || state.resourceMode === ResourceMode.SKILLS)
		) {
			updateAvailableWorkers(calInst._firstDay, calInst._lastDay, state.resourceMode);

			// clean up function when unmounted to avoid getData fired twice problem in React 18
			return () => abortController.abort();
		}
	}, [goUpdateAvailableWorkers, state.resourceMode]);

	// Apply the calendar's colour properties
	useEffect(() => {
		// Reset
		if (isLoading === true) setColours([]);

		if (isLoading === false && resources.length > 0) {
			let calendarColours: Array<any> = [];
			let calendarInvalid: Array<any> = [];

			// Set the resource location icons and colours
			if (state.resourceMode === ResourceMode.WORKERS) {
				resources.map((item: any) => {
					if (item.type_of_engagement_enum === 'directly') {
						// Directly-employed workers
						if (item.hasOwnProperty('working_hours') && Array.isArray(item.working_hours)) {
							// Non-working days for each worker
							const workingDays: Array<string> = item.working_hours.reduce((acc: any, cur: any) => {
								if (!Array.isArray(acc)) acc = [];
								acc.push(cur.day_of_week.toUpperCase().slice(0, -1));
								return acc;
							}, {});

							calendarInvalid.push({
								resource: item.id,
								allDay: true,
								recurring: {
									repeat: 'weekly',
									weekDays: ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
										.filter((wd: string) => workingDays.indexOf(wd) === -1)
										.join(','),
								},
								cssClass: 'bg-cal-cell bg-cal-cell-not-working-day',
							});

							// For the calendar icons, start by getting all unique locations for this resource
							uniqBy(item.working_hours, 'location').map((u: any) => {
								// Now get the weekdays applicable to each unique location
								let weekDays: Array<string> = item.working_hours
									.filter((wh: any) => wh.location === u.location)
									.map((d: any) => d.day_of_week.substring(0, 2).toUpperCase());

								// Create an array entry object for each unique location with the correct weekdays
								calendarColours.push({
									resource: item.id,
									allDay: true,
									recurring: {
										repeat: 'weekly',
										weekDays: weekDays.join(','),
									},
									cssClass: getCalCellLocationClass(u.location),
								});
							});
						}
					}
				});
			}

			// Set the job target date colours and event counts
			if (state.resourceMode === ResourceMode.JOBS || state.resourceMode === ResourceMode.SKILLS) {
				// Reduce the event array into job IDs and date groups with counts
				const eventsGroup = events.reduce((acc, cur) => {
					if (cur.hasOwnProperty('job_id')) {
						const theDate: string = cur.start.toFormat('yyyy-MM-dd');

						if (!acc[cur.job_id]) acc[cur.job_id] = [];
						if (!acc[cur.job_id][theDate]) acc[cur.job_id][theDate] = 0;
						acc[cur.job_id][theDate] += 1;
					}

					return acc;
				}, {});

				resources.forEach((item: any) => {
					if (
						item.hasOwnProperty('targetStartDate') &&
						item.hasOwnProperty('targetCompletionDate') &&
						item.targetStartDate !== null &&
						item.targetCompletionDate !== null
					) {
						calendarColours.push({
							resource: item.id,
							start: DateTime.fromISO(item.targetStartDate).toJSDate(),
							end: DateTime.fromISO(item.targetCompletionDate).toJSDate(),
							allDay: true,
							cssClass: 'bg-job-target-dates',
						});
					}

					// Set the job event counts
					if (state.resourceMode === ResourceMode.SKILLS && calInst) {
						if (Array.isArray(eventsGroup[item.id])) {
							Object.keys(eventsGroup[item.id]).forEach((groupDate: string) => {
								calendarColours.push({
									resource: item.id,
									title: (
										<div className='events-count-container'>
											<IonBadge mode='ios'>{eventsGroup[item.id][groupDate]}</IonBadge>
										</div>
									),
									date: DateTime.fromFormat(groupDate, 'yyyy-MM-dd').toJSDate(),
								});
							});
						}
					}
				});
			}

			// Finally set the calendar's properties
			if (calendarColours.length > 0) setColours(baseColours.concat(calendarColours));
			if (calendarInvalid.length > 0) setInvalid(calendarInvalid);
		}
	}, [isLoading, events, resources, state.resourceMode]);

	// Set the self-employed icons and colours
	useEffect(() => {
		if (state.resourceMode === ResourceMode.WORKERS) {
			let calendarColours: Array<any> = [];
			let calendarInvalid: Array<any> = [];
			let invalidRangeStart: any = null;
			let invalidRangeEnd: any = null;

			// Get the full week/month when the self-employed worker could be unavailable
			if (calInst) {
				invalidRangeStart = DateTime.fromJSDate(calInst._firstDay);
				invalidRangeEnd = DateTime.fromJSDate(calInst._lastDay);
			} else {
				if (state.viewMode === ViewMode.WEEK) {
					invalidRangeStart = DateTime.now().startOf('week');
					invalidRangeEnd = DateTime.now().endOf('week').plus({ days: 1 });
				} else if (state.viewMode === ViewMode.MONTH) {
					invalidRangeStart = DateTime.now().startOf('month');
					invalidRangeEnd = DateTime.now().endOf('month').plus({ days: 1 });
				}
			}

			// Check each resources availability/unavailability
			resources.forEach((item: any) => {
				if (item.type_of_engagement_enum === 'self') {
					// Set the days of the week that each self-employed worker is available
					let availableDates: Array<string> = [];
					resourcesSelfAvailability.forEach((avail: any) => {
						Object.keys(avail).some((availWorkerId: any) => {
							const arrAvailability: Array<any> = avail[availWorkerId];
							if (item.id === availWorkerId && arrAvailability.length > 0) {
								arrAvailability.forEach((availableDay: any) => {
									// Keep track of the available dates to negate from the invalid dates
									availableDates.push(DateTime.fromISO(availableDay.start).toFormat('dd/MM/yyyy'));

									// Set the calendar icons
									calendarColours.push({
										resource: item.id,
										start: DateTime.fromISO(availableDay.start).startOf('day'),
										end: DateTime.fromISO(availableDay.start).endOf('day'),
										cssClass: getCalCellLocationClass(availableDay.location),
									});
								});

								return true; // break
							}
						});
					});

					// Set remaining days to invalid
					Interval.fromDateTimes(invalidRangeStart, invalidRangeEnd)
						.splitBy({ days: 1 })
						.map((d: any) => d.start)
						.filter((d: DateTime) => !availableDates.includes(d.toFormat('dd/MM/yyyy')))
						.forEach((d: DateTime) => {
							calendarInvalid.push({
								resource: item.id,
								start: d.startOf('day'),
								end: d.endOf('day'),
								cssClass: 'bg-cal-cell bg-cal-cell-not-working-day',
							});
						});
				}
			});

			// Finally set the calendar's properties
			if (calendarColours.length > 0) setColoursSelf(calendarColours);
			if (calendarInvalid.length > 0) setInvalidSelf(calendarInvalid);
		}
	}, [resourcesSelfAvailability]);

	// Re-load the self-employed worker availability on initial resources/page load only
	useEffect(() => {
		if (
			state.resourceMode === ResourceMode.WORKERS &&
			resources &&
			Array.isArray(resources) &&
			resources.length > 0
		) {
			loadSelfEmployedAvailability();
		}
	}, [resources]);

	// Highlight resource if necessary
	useEffect(() => {
		if (!isLoading && firstPageLoaded && resourceRefs.length > 0) highlightResource();
	}, [isLoading, firstPageLoaded, resourceRefs]);

	const getResourceModeHash = useCallback(() => {
		// Use the provided hash as the resource mode
		let resourceModeHash: ResourceMode | null = null;
		switch (location.hash.replace('#', '')) {
			case 'jobs':
				resourceModeHash = ResourceMode.JOBS;
				break;
			case 'skills':
				resourceModeHash = ResourceMode.SKILLS;
				break;
			case 'workers':
			default:
				resourceModeHash = ResourceMode.WORKERS;
				break;
		}
		return resourceModeHash as ResourceMode;
	}, [location]);

	const updateResourceModeState = useCallback(
		(resourceMode: ResourceMode) => {
			// Update state after loading to avoid resource avatar change delay
			if (calInst) loadEvents(calInst._firstDay, calInst?._lastDay, resourceMode);
			dispatch({ type: ActionType.SET_RESOURCE_MODE, payload: resourceMode });
		},
		[calInst]
	);

	const handleResourceModeChange = useCallback(
		(resourceMode: ResourceMode) => {
			setIsLoading(true);
			setEvents([]);

			let payload: any = {};

			switch (resourceMode) {
				case ResourceMode.WORKERS:
					// Filter
					if (state.filter?.leader) payload.boss_id = state.filter.leader.value;

					// Get URL params
					const highlightWorkerId: string | null = urlParams.get('worker_id') ?? null;

					// Clean the URL
					history.replace(location.pathname + '#workers');

					// Get the workers as resources for the calendar
					moduleCtx
						.getWorkersList(payload.boss_id)
						.then((res: any) => {
							let refsTmp: any = null;

							if (highlightWorkerId) {
								refsTmp = res.map(() => createRef());
								setResourceRefs(refsTmp);
							}

							setResources(
								res.map((item: any, index: number) => ({
									id: item.id,
									name: item.name,
									description: item.job_title,
									worker_photo: item.worker_photo,
									type_of_engagement_enum: item.type_of_engagement_enum,
									working_hours: item.working_hours,
									skills: item.skills,
									resourceRef: refsTmp ? refsTmp[index] : null,
									background:
										highlightWorkerId === item.id ? 'var(--ion-color-theme-beta-tertiary)' : null,
								}))
							);

							setResourceModeOptions({});
						})
						.catch(() => showToast('error'))
						.finally(() => updateResourceModeState(resourceMode));
					break;
				case ResourceMode.JOBS:
					// Filter
					if (state.filter?.leader) payload.manager_id = state.filter.leader.value;

					// Get URL params
					const highlightJobId: string | null = urlParams.get('job_id') ?? null;

					// Clean the URL
					history.replace(location.pathname + '#jobs');

					moduleCtx
						.getJobs(null, payload.manager_id, JobStatus.IN_PROGRESS)
						.then((res: any) => {
							let refsTmp: any = null;

							if (highlightJobId) {
								refsTmp = res.map(() => createRef());
								setResourceRefs(refsTmp);
							}

							setResources(
								res.map((item: any, index: number) => ({
									id: item.id,
									name: item.name,
									description: item.postcode,
									targetStartDate: item.target_start_date,
									targetCompletionDate: item.target_completion_date,
									manager: item.manager,
									resourceRef: refsTmp ? refsTmp[index] : null,
									background:
										highlightJobId === item.id ? 'var(--ion-color-theme-beta-tertiary)' : null,
								}))
							);

							setResourceModeOptions({
								renderResourceFooter: () => ResourceFooter(false),
								renderDayFooter: () => {}, // Apply the day footer each time the events are loaded
							});
						})
						.catch(() => showToast('error'))
						.finally(() => updateResourceModeState(resourceMode));
					break;
				case ResourceMode.SKILLS:
					// Filter
					if (state.filter?.leader) payload.manager_id = state.filter.leader.value;

					// Clean the URL
					history.replace(location.pathname + '#skills');

					moduleCtx
						.getJobs(true, payload.manager_id, JobStatus.IN_PROGRESS)
						.then((res: any) => {
							setResources(
								res
									.filter((item: any) => item.resources.length > 0)
									.map((item: any) => ({
										id: item.id,
										name: item.name,
										description: item.postcode,
										targetStartDate: item.target_start_date,
										targetCompletionDate: item.target_completion_date,
										eventCreation: false,
										collapsed: true,
										manager: item.manager,
										children: item.resources
											.map((resource: any) => ({
												id: resource.id,
												jobSkillId: resource.job_skill_id,
												name: resource.job_skill,
												scheduledHours: resource.scheduled_hours,
												manHours: resource.man_hours,
											}))
											.sort((a: any, b: any) => (a.name > b.name ? 1 : -1)),
									}))
							);

							setResourceModeOptions({});
							setResourceRefs([]);
						})
						.catch(() => showToast('error'))
						.finally(() => updateResourceModeState(resourceMode));
					break;
			}
		},
		[updateResourceModeState, state]
	);

	const handleSideMenuSuccess = useCallback(
		async (mode: string, resourceId: string) => {
			setIsLoading(true);

			updateManHours(resourceId);

			await handleSideMenuClose();

			// Reload current events
			if (calInst) {
				loadEvents(calInst._firstDay, calInst._lastDay, state.resourceMode, () => {
					switch (mode) {
						case 'created':
							showToast('success', 'Appointment created successfully');
							break;
						case 'updated':
							showToast('success', 'Appointment updated successfully');
							break;
						default:
							console.error('Unknown');
							break;
					}
				});
			}
		},
		[calInst, state]
	);

	const loadEvents = useCallback(
		(fromDay: Date, toDay: Date, resourceMode: ResourceMode, onSuccess?: Function) => {
			setIsLoading(true);

			// Get the available workers day counts by forcing a state update
			if (resourceMode === ResourceMode.JOBS || resourceMode === ResourceMode.SKILLS) {
				setGoUpdateAvailableWorkers((prevState: any) => ({ ...prevState }));
			}

			// Re-load the self-employed worker availability
			if (resourceMode === ResourceMode.WORKERS && firstPageLoaded === true) {
				loadSelfEmployedAvailability(fromDay, toDay);
			}

			let payload: any = {
				view: resourceMode,
				start: fromDay,
				end: toDay,
				...getTimezone(),
			};

			loadBankHolidays(
				DateTime.fromJSDate(fromDay).month,
				DateTime.fromJSDate(fromDay).year,
				setBankHolidays
			);

			axios
				.post('/api/scheduling', payload)
				.then((res: any) => {
					setEvents(() => {
						if (onSuccess) onSuccess();
						return res.data.map((event: any) =>
							parseCalendarEvent({ event, allDay: true, source: 'scheduling', resourceMode })
						);
					});
				})
				.catch(() => {})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[resources, firstPageLoaded]
	);

	const loadSelfEmployedAvailability = useCallback(
		(start?: Date, end?: Date) => {
			// Get the self-employed worker details
			const selfEmployedWorkerIds: any = resources
				.filter((item: any) => item.type_of_engagement_enum === 'self')
				.map((item: any) => item.id);

			const payload: any = {
				ids: selfEmployedWorkerIds,
				start: undefined,
				end: undefined,
			};

			if (start && end) {
				// Use provided dates
				payload.start = DateTime.fromJSDate(start).toISODate();
				payload.end = DateTime.fromJSDate(end).toISODate();
			} else {
				// Use the current week if the calendar is not yet ready
				payload.start = DateTime.now().startOf('week').toISODate();
				payload.end = DateTime.now().endOf('week').plus({ days: 1 }).toISODate();
			}

			axios
				.post('/api/workers/workers_list/worker_card/work_calendar/list', payload)
				.then((res: any) => {
					setResourcesSelfAvailability(res.data);
				})
				.catch(() => showToast('error'));
		},
		[resources]
	);

	const handleUpdateEvent = useCallback(
		(event: any, showLoading: Function) => {
			const calEvent = event.event;

			// Stop the event from spanning over a day
			if (DateTime.fromJSDate(calEvent.start).day !== DateTime.fromJSDate(calEvent.end).day) {
				showToast('error', 'Appointments cannot span days');
				return false;
			}

			// Stop the event from moving to a different resource
			if (calEvent.resource !== event.oldEvent.resource) {
				showToast('error', 'Appointments cannot be re-assigned');
				return false;
			}

			showLoading(true);

			let payload: any = {
				start: DateTime.fromJSDate(calEvent.start).toISO(),
				end: DateTime.fromJSDate(calEvent.end).toISO(),
			};

			axios
				.put(`/api/scheduling/events/${calEvent.id}`, payload)
				.then(() => {
					if (calInst)
						loadEvents(calInst?._firstDay, calInst?._lastDay, state.resourceMode, () => {
							updateManHours(calEvent.resource_orig);
							showToast('success', 'Appointment successfully updated');
						});
				})
				.catch(() => {
					showToast('error');
				})
				.finally(() => {
					showLoading(false);
				});
		},
		[calInst, state]
	);

	function handleDeleteEvent(
		requestId: string,
		resourceId: string,
		fromDayScheduleModal: boolean = false,
		propMenuActionDefault?: SideMenuAction,
		propSetMenuAction?: Function,
		eventId?: string,
		singleDeletion?: boolean,
		setIsLoadingSideMenu?: Function
	) {
		if (!permissionTo('delete')) {
			showToast('permission');
			return false;
		}

		let alertHeaderText: string = 'Delete Appointment Series';
		let alertHeaderMsg: string = 'Are you sure you want to delete this series of appointments?';

		if (fromDayScheduleModal === true || singleDeletion === true) {
			alertHeaderText = 'Delete Single Appointment';
			alertHeaderMsg = 'Are you sure you want to delete this single appointment?';
		}

		presentAlert({
			cssClass: 'alert-scheduling-appointment-deletion',
			header: alertHeaderText,
			message: alertHeaderMsg,
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
						// If invoked from the side menu then cancel the menu action
						if (propMenuActionDefault && propSetMenuAction) {
							propSetMenuAction(propMenuActionDefault);
						}
					},
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						let deletionUrl = `/api/scheduling/requests/${requestId}`;
						if (fromDayScheduleModal || singleDeletion === true) {
							deletionUrl = `/api/scheduling/events/${eventId}`;

							if (fromDayScheduleModal) {
								setDayScheduleModal((prevState: any) => ({ ...prevState, isOpen: false }));
							}
						}

						setIsLoading(true);
						if (setIsLoadingSideMenu) setIsLoadingSideMenu(true);

						axios
							.delete(deletionUrl)
							.then(() => {
								if (calInst) {
									updateManHours(resourceId);

									loadEvents(calInst._firstDay, calInst._lastDay, state.resourceMode, () => {
										handleSideMenuClose();
										showToast('deleted', 'Appointment successfully deleted');
									});
								}
							})
							.catch(() => {
								showToast('error');
							});
					},
				},
			],
		});
	}

	const updateManHours = useCallback(
		(resourceId: string) => {
			if (state.resourceMode !== ResourceMode.SKILLS) return false;

			axios
				.post('/api/scheduling/resource_hours', { resource_id: resourceId })
				.then((res: any) => {
					// Update the relevant resource with the updated scheduled hours value
					setResources((prevState: any) => {
						// Clone the current state to ensure an update
						const currentResources: any = cloneDeep(prevState);
						currentResources.some((item: any) => {
							if (item.hasOwnProperty('children') && Array.isArray(item.children)) {
								const jobResource = item.children.filter((child: any) => child.id === resourceId);
								if (Array.isArray(jobResource) && jobResource.length === 1) {
									jobResource[0].scheduledHours = res.data.scheduled_hours;
									return item;
								}
							}
						});
						return currentResources;
					});
				})
				.catch(() => {
					showToast('error');
				});
		},
		[state, events]
	);

	// Timeline handlers
	const extendDefaultEvent = (e: any) => {
		// End date should not be midnight otherwise it is classed as the next day
		return {
			...e,
			end: DateTime.fromJSDate(e.start).endOf('day').toJSDate(),
			allDay: true,
		};
	};

	const handleOnInit = useCallback((e: MbscCellClickEvent, inst: Eventcalendar) => {
		setCalInst(inst);
	}, []);

	const handleOnPageLoading = useCallback(
		(e: MbscPageChangeEvent, inst: Eventcalendar) => {
			// Set a timeout on the first load to avoid the component update warning
			setTimeout(
				() => {
					let initialResourceMode: ResourceMode = state.resourceMode;
					if (location && location.hash) {
						// Use the provided hash as the resource mode
						initialResourceMode = getResourceModeHash();
					}

					loadEvents(inst._firstDay, inst._lastDay, initialResourceMode);
				},
				firstPageLoaded ? 0 : 200
			);
		},
		[state, location]
	);

	const handleOnPageLoaded = useCallback(() => {
		setFirstPageLoaded(true);
	}, []);

	const handleOnTempEventCreate = useCallback(
		(e: MbscCalendarEvent, inst: Eventcalendar) => {
			if (!permissionTo('create')) {
				showToast('permission');
				return false;
			}

			// Debounce - stops ability to quickly click-add multiple new events
			if (state.hasOwnProperty('tempEvent')) return false;

			// Cancel creating an event if the context menu was open
			if (contextMenuInst && contextMenuOpen === true) {
				setEvents([...events]);
				return false;
			}

			// Disallow creating dates in the past
			if (DateTime.fromJSDate(e.event.start).startOf('day') < DateTime.now().startOf('day')) {
				showToast('error', 'Sorry, you cannot schedule events in the past');
				return false;
			}

			const dayOfWeek = DateTime.fromJSDate(e.event.start).toFormat('ccc').toLowerCase();

			// Create the correct initial time
			let eventTime: Array<string> = ['09:00 - 17:00'];
			switch (state.resourceMode) {
				case ResourceMode.WORKERS:
					let tmpWorkingHours: any = null;
					let tmpResource: any = resources.filter(
						(resource: any) => resource.id === e.event.resource
					);

					if (
						Array.isArray(tmpResource) &&
						tmpResource.length === 1 &&
						tmpResource[0].hasOwnProperty('working_hours') &&
						Array.isArray(tmpResource[0].working_hours) &&
						tmpResource[0].working_hours.length > 0
					) {
						tmpWorkingHours = tmpResource[0].working_hours.filter(
							(wh: any) => wh.day_of_week === dayOfWeek
						);

						if (Array.isArray(tmpWorkingHours) && tmpWorkingHours.length === 1) {
							eventTime = [`${tmpWorkingHours[0].hours_start} - ${tmpWorkingHours[0].hours_end}`];
						}
					}
					break;
			}

			let payload: SchedulingEvent = {
				isNew: true,
				id: e.event.id,
				start: DateTime.fromJSDate(e.event.start),
				end: DateTime.fromJSDate(e.event.end).minus({ hour: 1, second: 1 }),
				time: eventTime,
				workers: [],
			};

			switch (state.resourceMode) {
				case ResourceMode.WORKERS:
					const selectedWorker: any = resources.filter(
						(resource: any) => resource.id === e.event.resource
					);
					payload.workers = selectedWorker[0].id;
					payload.workerName = selectedWorker[0].name;
					payload.locationId = selectedWorker[0].working_hours.filter(
						(wh: any) => wh.day_of_week === payload.start.weekdayShort.toLowerCase()
					)[0]?.location;
					break;
				case ResourceMode.JOBS:
					payload.job = resources
						.filter((resource: any) => resource.id === e.event.resource)
						.map((resource: any) => ({
							label: resource.name,
							value: resource.id,
							manager: resource.manager,
						}))[0];
					break;
				case ResourceMode.SKILLS:
					// Extract the job and skill
					resources.every((item: any) => {
						let child: any = item.children.filter((skill: any) => skill.id === e.event.resource);

						if (Array.isArray(child) && child.length === 1 && child[0].hasOwnProperty('id')) {
							payload.job = { label: item.name, value: item.id, manager: item.manager };
							payload.skill = {
								label: child[0].name,
								value: child[0].jobSkillId,
							};

							// Break the loop
							return false;
						} else {
							// Keep the loop running
							return true;
						}
					});
					break;
			}

			// Create a temporary event from the cell that was clicked to open the side-menu
			dispatch({
				type: ActionType.CREATE_TEMP_EVENT,
				payload,
			});
		},
		[contextMenuInst, events, setEvents, state, contextMenuOpen]
	);

	const handleOnEventDragStart = useCallback(
		async (e: MbscCalendarEvent, inst: Eventcalendar) => {
			// Close the context menu if drag-creating a new event
			if (contextMenuInst && contextMenuOpen === true) {
				contextMenuInst.close();
			}
		},
		[contextMenuInst, contextMenuOpen]
	);

	const handleOnEventClick = useCallback(
		(e: MbscCalendarEvent, inst: Eventcalendar) => {
			if (!permissionTo('update')) {
				showToast('permission');
				return false;
			}

			// Close the context menu
			setContextMenuOpen(false);

			if (e.event.enumEventType === EventType.APPOINTMENT) {
				setEventIsLoading(true);

				// Get the original event details
				axios
					.get(`/api/scheduling/requests/${e.event.request_id}`)
					.then((res) => {
						const data: any = res.data;
						const start: DateTime = DateTime.fromISO(data.start);
						const end: DateTime = DateTime.fromISO(data.end);

						let payload: SchedulingEvent = {
							isNew: false,
							id: e.event.id,
							requestId: e.event.request_id,
							start,
							end,
							time: [`${start.toFormat('HH:mm')} - ${end.toFormat('HH:mm')}`],
							job: { label: data.job_name, value: data.job_id, manager: data.job_manager },
							skill: {
								label: data.skill_name,
								value: data.skill_id,
							},
						};

						// Resource mode specifics
						switch (state.resourceMode) {
							case ResourceMode.WORKERS:
								payload.workers = data.attendees.map((attendee: any) => attendee.value)[0];

								// Get the worker's name for the correct resource mode form banner
								payload.workerName = data.attendees.map((attendee: any) => attendee.label)[0];
								break;
							case ResourceMode.JOBS:
							case ResourceMode.SKILLS:
								payload.workers = [
									data.attendees.map((attendee: any) => attendee.value) + ',' + data.skill_id,
								];
								break;
						}

						// Create a temporary event from the existing event that was clicked to open the side-menu
						dispatch({
							type: ActionType.CREATE_TEMP_EVENT,
							payload,
						});
					})
					.catch(() => {})
					.finally(() => setEventIsLoading(false));
			}
		},
		[state.resourceMode]
	);

	const handleOnEventCreateFailed = useCallback((e: MbscCalendarEvent, inst: Eventcalendar) => {
		// Don't allow creation of temporary event
		dispatch({
			type: ActionType.DELETE_TEMP_EVENT,
			payload: null,
		});

		showToast('error', 'The selected worker does not work on those days');
	}, []);

	const handleOnCellRightClick = useCallback(
		(e: any, inst: Eventcalendar) => {
			// Disable right-clicks on resource parents
			if (
				state.resourceMode === ResourceMode.SKILLS &&
				Object.keys(e.inst._resourcesMap).includes(e.resource)
			) {
				return false;
			}

			e.domEvent.preventDefault();

			// Open the context menu
			setContextMenuAnchor(e.domEvent.target);
			setContextMenuRightClickedEvent(e);
			setTimeout(() => setContextMenuOpen(true));
		},
		[state]
	);

	const handleOnEventRightClick = useCallback((e: any, inst: Eventcalendar) => {
		e.domEvent.preventDefault();

		// Disable right-clicks on other events
		if (e.event.enumEventType !== EventType.APPOINTMENT) return false;

		// Close the event tip
		setEventTipIsOpen(false);

		// Open the context menu
		setContextMenuAnchor(e.domEvent.target);
		setContextMenuRightClickedEvent(e);
		setTimeout(() => setContextMenuOpen(true));
	}, []);

	const highlightResource = useCallback(() => {
		if (resourceRefs.length > 0) {
			// Get the selected resource
			const res: any = resourceRefs.filter((ref: any) =>
				ref.current.classList.contains('highlighted-resource')
			)[0];

			// Scroll-to (only once) and highlight the resource
			if (res && !res.current.classList.contains('resource-scrolled')) {
				res.current.classList.add('resource-scrolled');
				res.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}

			setResourceRefs([]);
		}
	}, [resourceRefs]);

	// Side-menu handlers
	const handleSideMenuOpen = useCallback(async () => {
		// Open the side-menu
		await menuController.enable(true, 'schedulingSideMenu');
		await menuController.open('schedulingSideMenu');
	}, []);

	const handleSideMenuClose = useCallback(async () => {
		// Close the side-menu
		await menuController.close('schedulingSideMenu');
	}, []);

	const handleSideMenuWillClose = useCallback(
		async (menuAction: string) => {
			// Reset events to remove temporary event creation if required
			if (menuAction === 'cancel') setEvents([...events]);

			// Reset side-menu data
			dispatch({
				type: ActionType.DELETE_TEMP_EVENT,
				payload: null,
			});
		},
		[events, setEvents]
	);

	// Event Tip
	const [eventTipIsOpen, setEventTipIsOpen] = useState(false);
	const [eventTipAnchor, setEventTipAnchor] = useState(undefined);
	const [currentEvent, setCurrentEvent] = useState<any>(null);
	const eventTipTimerRef = useRef<any>(null);
	const onEventHoverIn = useCallback(
		(args: any) => {
			if (contextMenuOpen) return false;

			// Highlight connected events
			const connectedEvents = document.getElementsByClassName('sch-conn-' + args.event.request_id);
			if (connectedEvents.length > 1) {
				Array.from(connectedEvents).forEach((ev) => ev.classList.add('connected-highlight'));
			}

			setCurrentEvent(args.event);
			if (eventTipTimerRef.current) clearTimeout(eventTipTimerRef.current);
			setEventTipAnchor(args.domEvent.target);
			setEventTipIsOpen(true);
		},
		[contextMenuOpen]
	);

	const onEventHoverOut = useCallback((args: any) => {
		// De-highlight connected events
		const connectedEvents = document.getElementsByClassName('sch-conn-' + args.event.request_id);
		if (connectedEvents.length > 1) {
			Array.from(connectedEvents).forEach((ev) => ev.classList.remove('connected-highlight'));
		}

		eventTipTimerRef.current = setTimeout(() => setEventTipIsOpen(false), 200);
	}, []);

	const onMouseEnter = useCallback(() => {
		if (eventTipTimerRef.current) clearTimeout(eventTipTimerRef.current);
	}, []);

	const onMouseLeave = useCallback(() => {
		eventTipTimerRef.current = setTimeout(() => setEventTipIsOpen(false), 200);
	}, []);

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonCard className='table-card full-height-card'>
				{(isLoading || eventIsLoading) && <Loading overlay={true} />}
				<Timeline
					className={`scheduler-timeline resource-mode-${state.resourceMode} view-mode-${state.viewMode}`}
					view={views[state.viewMode]}
					data={events}
					resources={resources}
					defaultSelectedDate={DateTime.now().startOf('day').toJSDate()}
					extendDefaultEvent={extendDefaultEvent}
					renderHeader={() => Header(views, state, dispatch, handleResourceModeChange)}
					renderResource={(resource: MbscResource) => Resource(resource, state)}
					renderScheduleEvent={(event: any) => ScheduleEvent(event)}
					colors={[...colours, ...coloursSelf, ...bankHolidays]}
					invalid={[...invalid, ...invalidSelf]}
					clickToCreate={'single'}
					dragToCreate={true}
					dragTimeStep={1440}
					onInit={handleOnInit}
					onPageLoading={handleOnPageLoading}
					onPageLoaded={handleOnPageLoaded}
					onEventCreate={handleOnTempEventCreate}
					onEventDragStart={handleOnEventDragStart}
					onEventClick={handleOnEventClick}
					onEventCreateFailed={handleOnEventCreateFailed}
					onCellRightClick={handleOnCellRightClick}
					onEventRightClick={handleOnEventRightClick}
					onEventHoverIn={onEventHoverIn}
					onEventHoverOut={onEventHoverOut}
					{...resourceModeOptions}
				/>

				<CalendarContextMenu
					options={contextMenuOptions}
					contextMenuOpen={contextMenuOpen}
					contextMenuAnchor={contextMenuAnchor}
					setContextMenuInst={setContextMenuInst}
					setContextMenuOpen={setContextMenuOpen}
					setContextMenuRightClickedEvent={setContextMenuRightClickedEvent}
				/>

				<EventTip
					eventTipIsOpen={eventTipIsOpen}
					eventTipAnchor={eventTipAnchor}
					currentEvent={currentEvent}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				/>
			</IonCard>

			{createPortal(
				<SideMenu
					permissionTo={permissionTo}
					handleSideMenuClose={handleSideMenuClose}
					handleSideMenuWillClose={handleSideMenuWillClose}
					handleOnSuccess={handleSideMenuSuccess}
					handleDeleteEvent={handleDeleteEvent}
				/>,
				/*
					Put it where many other portalled Ionic components go by default so that it doesn't hog
					the top of the stack.
				*/
				document.getElementsByTagName('ion-app')[0]
			)}

			<DayScheduleModal
				isOpen={dayScheduleModal.isOpen}
				selectedDate={dayScheduleModal.selectedDate}
				events={dayScheduleModal.events}
				resource={dayScheduleModal.resource}
				permissionTo={permissionTo}
				handleUpdateEvent={handleUpdateEvent}
				handleDeleteEvent={handleDeleteEvent}
				onClose={() => {
					setDayScheduleModal((prevState: any) => ({ ...prevState, isOpen: false }));
				}}
				onDidDismiss={() => {
					setDayScheduleModal(dayScheduleModalDefaults);
				}}
			/>
		</>
	);
};

export default Scheduling;

import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Messages from '../../../../components/Chat/Messages/Messages';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import JobsContext from '../../../../contexts/JobsContext';
import { authContext } from '../../../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';

const MessagesIndex: React.FC = () => {
	const { jobId }: any = useParams();
	const { jobData } = useContext(JobsContext);
	const authCtx: any = useContext(authContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [conversation, setConversation] = useState<any>(null);
	const [isParticipant, setIsParticipant] = useState<boolean | null>(null);
	const conversationId: string = jobData?.conversation_id ? jobData?.conversation_id : '';

	useEffect(() => {
		if (conversationId.length > 0) {
			setIsLoading(true);
			axios
				.post(`/api/conversations/${conversationId}`, { job_id: jobId })
				.then((res: any) => {
					const tmpIsParticipant: boolean = res.data.participants.some(
						(user: any) => user.user_id === authCtx.user.id
					);
					setIsParticipant(tmpIsParticipant);

					if (tmpIsParticipant) setConversation(res.data);
				})
				.catch(() => showToast('error'))
				.finally(() => setIsLoading(false));
		} else {
			showToast('info', 'This job does not yet have any messages');
		}
	}, []);

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			{!isLoading && conversationId.length > 0 && conversation && (
				<Messages
					isVisible={true}
					conversationId={conversationId}
					conversation={conversation}
					onWillDismiss={() => {}}
					readOnly={false}
					showTitle={false}
					jobId={jobId}
				></Messages>
			)}
			{!isLoading && isParticipant === false && (
				<div className='no-data-placeholder mt-3'>
					<div className='no-data-placeholder-title'>
						Sorry, you are not part of this conversation
					</div>
					<FontAwesomeIcon icon={faCommentSlash} />
				</div>
			)}
		</>
	);
};

export default MessagesIndex;

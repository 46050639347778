import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import { JobsProvider } from '../../../contexts/JobsContext';
import { useParams } from 'react-router-dom';
import JobCardLayout from './JobCardLayout';

const JobCardIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { jobId } = useParams<{ jobId: string }>();

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<JobsProvider jobId={jobId}>
					<JobCardLayout routeTitle={routeTitle} permissionTo={permissionTo} />
				</JobsProvider>
			</div>
		</>
	);
}

export default JobCardIndex;

import { faRotateLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonLabel,
	IonModal,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Alert } from '@mui/material';

import SelectStyled from '../UI/SelectStyled';
import { showToast } from '../../lib/toast';
import LocateOnMap from '../Maps/LocateOnMap';
import { Coordinates, MapControls } from '../../interfaces/Maps/MapsInterface';
import RadioButtons from '../UI/RadioButtons/RadioButtons';

interface BoundaryProps {
	id: string;
	title: string;
	editMode: boolean;
	placeholder?: string;
	useLabels: boolean;
	disabled?: boolean;
	handleUpdateSelect?: Function;
	isMandatory?: boolean;
	custom?: boolean;
	value: any;
	noForm?: boolean;
}

type LocateOptions = {
	initialCentre: Coordinates;
	markerTitle: string;
	radius?: number;
};

type BoundaryOptions = {
	label: string;
	value: string;
	radius?: number;
};

export const Boundary = forwardRef<any, BoundaryProps>((props: BoundaryProps, ref) => {
	let noEditReturnValue = 'Nothing selected';
	const mapRef: any = useRef();

	const [options, setOptions] = useState<BoundaryOptions[]>([
		{ label: 'Small', value: 'small', radius: 25 },
		{ label: 'Medium', value: 'medium', radius: 50 },
		{ label: 'Large', value: 'large', radius: 100 },
	]);

	useEffect(() => {
		let index = options.findIndex((option) => option.value === 'custom');
		if (props.custom === true && index < 0) {
			setOptions((prevValue) => {
				prevValue.push({ label: 'Custom', value: 'custom' });
				return prevValue;
			});
		} else if ((props.custom === false || props.custom === undefined) && index > -1) {
			setOptions((prevValue) => {
				prevValue.splice(index, 1);
				return prevValue;
			});
		}
	}, [props.custom]);

	const getBoundaryValue = (value: any) => {
		let v = options.find((option) => option.value === value.boundary_size) ?? options[0];
		return v;
	};

	const getCoordinates = (value: any): Coordinates => {
		let coords: Coordinates = {
			lat: Number(value.latitude),
			lng: Number(value.longitude),
		};
		return coords;
	};

	const getControls = (value: any) => {
		if (value.boundary_size === 'custom') {
			setControls([
				{
					position: 'TOP_LEFT',
					component: (
						<RadioButtons options={[{ value: 'point', label: 'Marker' }, { value: 'polygon', label: 'Boundary' }]} onChange={setSelected} value={selected} />
					),
					data: {},
				},
			]);
		} else {
			setControls([]);
		}
	};

	const getBounds = (value: any) => {
		const coords: Array<google.maps.LatLng> = [];
		if (value.bounds) {
			value.bounds[0].forEach((hmd: any, index: number) => {
				coords.push(new google.maps.LatLng(
					hmd[1],
					hmd[0]
				));
			});
		}
		return coords;
	};

	const [mapOptions, setMapOptions] = useState<LocateOptions>({
		initialCentre: getCoordinates(props.value),
		markerTitle: 'Location',
		radius: (options.find((option) => option.value === props.value.boundary_size) ?? options[0])
			.radius,
	});

	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [boundaryValue, setBoundaryValue] = useState<any>();
	const [originalValue, setOriginalValue] = useState<any>({
		boundary_size: props.value.boundary_size ?? 'small',
		latitude: props.value.latitude,
		longitude: props.value.longitude,
		bounds: props.value.bounds,
	});
	const [showUndo, setShowUndo] = useState<boolean>(false);
	const [undoVal, setUndoVal] = useState<any>(null);
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [markerPosition, setMarkerPosition] = useState<Coordinates>();
	const [bounds, setBounds] = useState<Array<google.maps.LatLng>>();
	const [controls, setControls] = useState<Array<MapControls>>([]);
	const [selected, setSelected] = useState<Array<any>>(['polygon']);

	useImperativeHandle(ref, () => ({
		get value() {
			return originalValue;
		},
		set value(value: any) {
			setOriginalValue((prevValue: any) => {
				let newValue = { ...prevValue };
				newValue.boundary_size = value.boundary_size;
				newValue.latitude = value.latitude;
				newValue.longitude = value.longitude;
				newValue.bounds = value.bounds;
				return newValue;
			});
		},
		setProperty(property: string, value: any) {
			setOriginalValue((prevValue: any) => {
				let newValue = { ...prevValue };
				newValue[property] = value;
				return newValue;
			});
		},
	}));

	useEffect(() => {
		setBoundaryValue(getBoundaryValue(originalValue));
		setBounds(getBounds(originalValue));
		setMarkerPosition(getCoordinates(originalValue));
		getControls(originalValue);
		setMapOptions((prevValue) => {
			let newValue = { ...prevValue };
			if (newValue !== undefined) {
				newValue.radius = (
					options.find((option) => option.value === originalValue.boundary_size) ?? options[0]
				).radius;
			}
			return newValue;
		});
	}, [originalValue]);

	useEffect(() => {
		if (props.editMode) {
			setIsEdit(props.editMode);
		}
	}, [props.editMode]);

	useEffect(() => {
		setMapOptions((prevValue) => {
			let newValue = { ...prevValue };
			if (newValue !== undefined) {
				newValue.initialCentre = markerPosition ?? { lat: 0, lng: 0 };
			}
			return newValue;
		});
	}, [markerPosition]);

	useEffect(() => {
		getControls(originalValue);
	}, [selected]);

	const onUndoClick = () => {
		if (props.disabled) return false;
		if (props.handleUpdateSelect) {
			if (undoVal !== undefined) {
				setOriginalValue((prevValue: any) => {
					let newValue = { ...prevValue };
					newValue = undoVal;
					setTimeout(() => {
						if (props.handleUpdateSelect) {
							props.handleUpdateSelect(newValue);
						}
					}, 10);
					return newValue;
				});
				setUndoVal(null);
				setShowUndo(false);
			} else {
				showToast('error', "Sorry, you can't undo back to no selection");
			}
		}
	};

	const checkTempValue = (val: any) => {
		if (originalValue === undefined) return true;

		if (val[0].value === originalValue.boundary_size) {
			return false;
		} else {
			return true;
		}
	};

	const handleFieldChange = (e: any) => {
		let curVal: Array<any> = Array.isArray(e) ? e : [e];
		let valid = true;
		let change = checkTempValue(curVal);

		if (valid === true) {
			if (change === true) {
				if (props.noForm !== true) {
					setUndoVal(originalValue);
					setShowUndo(true);
				}
				// We must set this before returning in case of no-form mode
				setOriginalValue((prevValue: any) => {
					let newValue = { ...prevValue };
					newValue.boundary_size = curVal[0].value;
					setTimeout(() => {
						if (props.handleUpdateSelect) {
							props.handleUpdateSelect(newValue);
						}
					}, 10);
					return newValue;
				});
			}
		}
	};

	const handleButtonClick = () => {
		setModalOpen(true);
	};

	const onClose = () => {
		setModalOpen(false);
		setMarkerPosition(getCoordinates(originalValue));
	};

	const onSave = () => {
		setOriginalValue((prevValue: any) => {
			let newValue = { ...prevValue };
			let mapBounds = mapRef.current.getBounds();
			let position = mapRef.current.getPosition();
			newValue.latitude = position.lat;
			newValue.longitude = position.lng;
			if (mapBounds.length > 0) {
				newValue.bounds = reverseBounds(mapBounds);
			}
			if (props.handleUpdateSelect) {
				props.handleUpdateSelect(newValue);
			}
			return newValue;
		});
		if (props.noForm !== true) {
			setUndoVal(originalValue);
			setShowUndo(true);
		}
		setModalOpen(false);
	};

	const reverseBounds = (mapBounds: Array<google.maps.LatLng>) => {
		const coords: Array<any> = [];
		mapBounds.forEach((hmd: google.maps.LatLng, index: number) => {
			coords.push([hmd.lng(), hmd.lat()]);
		});
		coords.push(coords[0]);
		return [coords];
	}

	let style: any = {
		margin: '0 0 0 8px',
	};

	return (
		<>
			{isEdit ? (
				<IonRow className='prop-form prop-form-edit'>
					{props.useLabels !== false && (
						<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
							<IonLabel
								position='floating'
								className={`${props.isMandatory ? 'is-mandatory' : ''}`}
							>
								<span className='field-title'>{props.title}</span>
							</IonLabel>
						</IonCol>
					)}
					<IonCol
						size={'12'}
						sizeMd={props.useLabels === false ? '12' : '6'}
						sizeLg={props.useLabels === false ? '12' : '9'}
					>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
							{showUndo && (
								<FontAwesomeIcon
									icon={faRotateLeft}
									className='icon-primary undo-icon'
									onClick={() => onUndoClick()}
								/>
							)}
							<div style={{ flex: 1 }}>
								<SelectStyled
									id={props.id && props.id}
									defaultValue={boundaryValue}
									value={boundaryValue}
									isSearchable={true}
									name={props.title}
									options={options}
									onChange={(e: any) => {
										handleFieldChange(e);
									}}
									menuPortalTarget={document.body}
									isDisabled={props.disabled}
									isMulti={false}
									isClearable={false}
								/>
							</div>
							<IonButton
								color='primary'
								size='small'
								disabled={props.disabled}
								onClick={() => handleButtonClick()}
								style={style}
							>
								Adjust Location
							</IonButton>
						</div>
					</IonCol>
				</IonRow>
			) : (
				<IonRow className='prop-form'>
					{props.useLabels !== false && (
						<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
							<IonLabel>{props.title}</IonLabel>
						</IonCol>
					)}
					<IonCol
						size={'12'}
						sizeMd={props.useLabels === false ? '12' : '6'}
						sizeLg={props.useLabels === false ? '12' : '9'}
					>
						<p>{noEditReturnValue}</p>
					</IonCol>
				</IonRow>
			)}
			<IonModal
				style={{ '--width': '70vw', '--height': '70vh' }}
				isOpen={isModalOpen}
				className='prop-form'
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{`Set Location`}</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton onClick={() => onClose()}>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className='flex-grow-container' style={{ height: '100%' }}>
						{originalValue.boundary_size === 'custom' && selected[0] === 'polygon' ? (
							<Alert severity='info' style={{ margin: '0 0 8px 0' }}>
								Left-click to draw each point of a polygon on the map below to refine the boundary
							</Alert>
						) : (
							<Alert severity='info' style={{ margin: '0 0 8px 0' }}>
								Left-click and drag the marker to move the location
							</Alert>
						)}
						<div style={{ flex: 1, overflow: 'hidden' }}>
							<LocateOnMap
								ref={mapRef}
								mode={originalValue.boundary_size === 'custom' ? selected[0] : 'point'}
								options={mapOptions}
								markerPosition={markerPosition}
								polygon={originalValue.boundary_size === 'custom' ? bounds : undefined}
								controls={controls}
								showRadius={originalValue.boundary_size !== 'custom'}
							/>
						</div>
					</div>
				</IonContent>
				<IonFooter>
					<IonToolbar>
						<IonRow>
							<IonCol size='12' className='text-right'>
								<IonButton color='secondary' onClick={() => onClose()}>
									Close
								</IonButton>
								<IonButton color='success' onClick={() => onSave()}>
									Save
								</IonButton>
							</IonCol>
						</IonRow>
					</IonToolbar>
				</IonFooter>
			</IonModal>
		</>
	);
});

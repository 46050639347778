import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { AutoComplete, InfoBox } from '../../../../components/Forms/FormFields';
import { useContext, useEffect, useRef, useState } from 'react';
import { DateTime, Info } from 'luxon';
import { JobsOptionsEstimates } from '../../../../utils/autocomplete';
import { IonInput, IonLabel } from '@ionic/react';
import { authContext } from '../../../../contexts/AuthContext';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { EstimateContext } from '../EstimatesProvider';
import { ActionType } from '../actions';
import { showToast } from '../../../../lib/toast';
import { buildClientDetails, calculateEstimateAmount } from '../estimate-functions';
import debounce from 'lodash.debounce';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { Datepicker, MbscDatepickerChangeEvent } from '@mobiscroll/react';
import { uniqueId } from 'lodash';
import EstimateTitle from './EstimateTitle';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	jobId?: string;
	estimateId?: string;
}

const EstimateHeader: React.FC<Props> = ({ permissionTo, isNew, jobId, estimateId }) => {
	const { state, dispatch } = useContext(EstimateContext);
	const authCtx = useContext<any>(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const clientIdRef = useRef<any>(null);
	const issueDateRef = useRef<any>(null);
	const expiryDateRef = useRef<any>(null);
	const amountsAreValues: Array<{ label: string; value: string }> = [
		{ label: 'Tax Exclusive', value: 'exclusive' },
		{ label: 'Tax Inclusive', value: 'inclusive' },
	];

	// Use a local state for responsive user inputs (then debounce to main state)
	const [localState, setLocalState] = useState({
		title: '',
		summary: '',
		reference: '',
	});
	const [dayNames, setDayNames] = useState<Array<string>>([]);

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	useEffect(() => {
		if (isNew) {
			// Reset estimate with default form settings
			const issueDateVal = DateTime.utc().toISO();
			const expiryDateVal = DateTime.utc().plus({ days: 30 }).toISO();

			issueDateRef.current.setVal(issueDateVal);
			expiryDateRef.current.setVal(expiryDateVal);

			new Promise<void>((resolve, reject) => {
				// Reset estimate
				dispatch({ type: ActionType.RESET, payload: { vatRegistered: false } });
				resolve();
			}).then(() => {
				// Then add the default settings to state
				dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

				moduleCtx
					.getSettings()
					.then((res: any) => {
						dispatch({
							type: ActionType.UPDATE_ESTIMATE_DATA,
							payload: {
								issueDate: issueDateVal,
								expiryDate: expiryDateVal,
								issuedByName: `${authCtx.user.first_name} ${authCtx.user.last_name}`,
								vatRegistered: res.vat_registered,
							},
						});
					})
					.catch(() => {
						showToast('error');
					})
					.finally(() => {
						dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
					});
			});
		}

		if (jobId) {
			loadJob(jobId);
		} else if (estimateId) {
			dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

			// Fetch estimate and settings data
			Promise.all([moduleCtx.getEstimate(estimateId), moduleCtx.getSettings()])
				.then((res: any) => {
					loadEstimate(res[0], res[1]);
				})
				.catch(() => {
					showToast('error');
				})
				.finally(() => {
					dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
				});
		}
	}, []);

	const loadJob = (jobId: string) => {
		dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

		moduleCtx
			.getEstimateJob(jobId)
			.then((res: any) => {
				handleSelectJob({ value: res });
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
			});
	};

	const handleSelectJob = (res: any) => {
		const result = res.value;

		if (isNew) result.jobId = result.id;

		// Build the client details
		result.clientDetails = buildClientDetails(result);

		// Build the estimate number
		if (isNew) result.estimateNumber = `${result.number}/${result.revision}`;

		// Clean-up data
		delete result.id;
		delete result.client;

		dispatch({ type: ActionType.UPDATE_ESTIMATE_DATA, payload: result });
	};

	const loadEstimate = (dataEstimate: any, dataSettings: any) => {
		// Build the payload to update the estimate's state
		let payload: any = {
			estimateId: estimateId,
			jobId: dataEstimate.job_id,
			status: dataEstimate.status,
			name: dataEstimate.name,
			clientDetails: buildClientDetails(dataEstimate),
			issueDate: dataEstimate.issue_date,
			expiryDate: dataEstimate.expiry_date,
			title: dataEstimate.title,
			summary: dataEstimate.summary,
			showTitleSummary:
				(dataEstimate.title && dataEstimate.title.trim().length > 0) ||
				(dataEstimate.summary && dataEstimate.summary.trim().length > 0),
			reference: dataEstimate.reference,
			estimateNumber: dataEstimate.number,
			issuedByName: dataEstimate.issued_by,
			amountsAre: amountsAreValues.filter(
				(item: any) =>
					item.value === (dataEstimate.tax_exclusive === true ? 'exclusive' : 'inclusive')
			),
			vatRegistered: dataSettings.vat_registered,
		};

		// Set the date fields
		if (isNew) {
			issueDateRef.current.setVal(DateTime.fromISO(dataEstimate.issue_date));
			expiryDateRef.current.setVal(DateTime.fromISO(dataEstimate.expiry_date));
		}

		// Update the main state - estimate data
		dispatch({ type: ActionType.UPDATE_ESTIMATE_DATA, payload: payload });

		// Update the main state - estimate items
		const items = dataEstimate.items.map((item: any) => {
			item._uid = uniqueId('row_');

			// Ensure prices are formatted
			item.price = currencyFormatter({ value: item.price }, true);

			// Ensure no tax rate if not vat registered
			if (dataSettings.vat_registered === true) {
				item.taxRateId = item.tax_rate_id;
			} else {
				item.taxRateId = '0';
			}

			delete item.tax_rate_id;
			return item;
		});
		dispatch({ type: ActionType.SET_ITEMS, payload: items });
		calculateEstimateAmount(dataEstimate.tax_exclusive, items, dispatch);

		// Update the local state
		setLocalState((prevState: any) => {
			return {
				...prevState,
				title: dataEstimate.title,
				summary: dataEstimate.summary,
				reference: dataEstimate.reference,
			};
		});
	};

	const handleDateChange = (id: string, date: any) => {
		const theDate: string = date.value;
		let payload: any = {};
		payload[id] = DateTime.fromFormat(theDate, 'yyyy-MM-dd').toISO();

		switch (id) {
			case 'issueDate':
				issueDateRef.current.setVal(theDate);
				break;
			case 'expiryDate':
				expiryDateRef.current.setVal(theDate);
				break;
		}

		dispatch({ type: ActionType.UPDATE_ESTIMATE_DATA, payload: payload });
	};

	const handleReferenceChange = (event: any) => {
		setLocalState((prevState: any) => {
			const newValue: any = {};
			newValue['reference'] = event.target.value;
			return { ...prevState, ...newValue };
		});
	};

	const handleTitleSummaryChange = (id: string, event: any) => {
		setLocalState((prevState: any) => {
			const newValue: any = {};
			newValue[id] = event.target.value;
			return { ...prevState, ...newValue };
		});
	};

	const toggleShowTitleSummary = () => {
		// Note how we clear the title and summary if hiding this part of the UI
		const showTitleSummary: boolean = !state.estimateData?.showTitleSummary;
		let title = state.estimateData?.title;
		let summary = state.estimateData?.summary;

		if (!showTitleSummary) {
			title = '';
			summary = '';
		}

		// Set the local state
		setLocalState((prevState: any) => {
			return { ...prevState, title: title, summary: summary };
		});

		// Set the estimate state
		dispatch({
			type: ActionType.UPDATE_ESTIMATE_DATA,
			payload: {
				showTitleSummary: !state.estimateData?.showTitleSummary,
				title: title,
				summary: summary,
			},
		});
	};

	const handleAmountsAreChange = (data: any) => {
		dispatch({
			type: ActionType.UPDATE_ESTIMATE_DATA,
			payload: {
				amountsAre: amountsAreValues.filter((item: any) => item.value === data.value),
			},
		});

		calculateEstimateAmount(data.value === 'exclusive', state.items, dispatch);
	};

	// De-bounced function to update the main state
	const onItemChange = useRef(
		debounce((field: string, dispatch: any, localState: any) => {
			switch (field) {
				case 'title':
				case 'summary':
				case 'reference':
					const payload: any = {};
					payload[field] = localState[field];
					dispatch({ type: ActionType.UPDATE_ESTIMATE_DATA, payload: payload });
					break;
			}
		}, 600)
	).current;

	return (
		<>
			<EstimateTitle />
			<Table className='tbl-estimate' size='small'>
				<TableHead>
					<TableRow>
						<TableCell width={'50%'}>Contact</TableCell>
						<TableCell width={'15%'}>Issue Date</TableCell>
						<TableCell width={'15%'}>Expiry Date</TableCell>
						<TableCell width={'20%'}>Estimate Number</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							{(state.estimateData?.jobId || state.estimateData?.estimateId) &&
								(!isNew || jobId) && (
									<InfoBox
										title='Contact'
										value={state.estimateData?.name}
										useLabels={false}
										showBorder={true}
										showBackground={true}
									/>
								)}
							{((!state.estimateData?.jobId && !state.estimateData?.estimateId) ||
								(isNew && !jobId)) && (
								<AutoComplete
									id='client_id'
									forwardRef={clientIdRef}
									onChangeCallback={handleSelectJob}
									placeholder='Please start typing the name of a client, contact, or job...'
									loadOptions={(inputValue: any, callback: any) => {
										JobsOptionsEstimates(inputValue, callback, state.estimateData?.jobId);
									}}
									useLabels={false}
									editMode={true}
								/>
							)}
						</TableCell>
						<TableCell>
							{isNew && (
								<Datepicker
									dayNamesMin={dayNames}
									ref={issueDateRef}
									onChange={(args: MbscDatepickerChangeEvent) =>
										handleDateChange('issueDate', args)
									}
									controls={['calendar']}
									inputComponent='input'
									inputProps={{
										placeholder: 'Please select...',
										className: 'prop-form-date',
									}}
									returnFormat={'iso8601'}
								/>
							)}
							{!isNew && state.estimateData?.issueDate && (
								<InfoBox
									title='Issue Date'
									value={DateTime.fromISO(state.estimateData.issueDate).toFormat('dd/MM/yyyy')}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							)}
						</TableCell>
						<TableCell>
							{isNew && (
								<Datepicker
									dayNamesMin={dayNames}
									ref={expiryDateRef}
									onChange={(args: MbscDatepickerChangeEvent) =>
										handleDateChange('expiryDate', args)
									}
									controls={['calendar']}
									inputComponent='input'
									inputProps={{
										placeholder: 'Please select...',
										className: 'prop-form-date',
									}}
									returnFormat={'iso8601'}
								/>
							)}
							{!isNew && state.estimateData?.expiryDate && (
								<InfoBox
									title='Expiry Date'
									value={DateTime.fromISO(state.estimateData.expiryDate).toFormat('dd/MM/yyyy')}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							)}
						</TableCell>
						<TableCell>
							<InfoBox
								title='Estimate No'
								value={state.estimateData?.estimateNumber}
								useLabels={false}
								showBorder={true}
								showBackground={true}
							/>
						</TableCell>
					</TableRow>
					<TableRow
						className={`row-title-summary ${state.estimateData?.showTitleSummary ? '' : 'hidden'}`}
					>
						<TableCell variant='head'>Estimate Title</TableCell>
						<TableCell variant='head' colSpan={3}>
							Estimate Summary
						</TableCell>
					</TableRow>
					<TableRow
						className={`row-title-summary ${state.estimateData?.showTitleSummary ? '' : 'hidden'}`}
					>
						<TableCell>
							<IonInput
								className='ion-propeller'
								placeholder='Enter an estimate title...'
								value={localState.title}
								onInput={(e: any) => handleTitleSummaryChange('title', e)}
								onIonChange={() => onItemChange('title', dispatch, localState)}
							/>
						</TableCell>
						<TableCell colSpan={3}>
							<IonInput
								className='ion-propeller'
								placeholder='Enter an estimate smmary...'
								value={localState.summary}
								onInput={(e: any) => handleTitleSummaryChange('summary', e)}
								onIonChange={() => onItemChange('summary', dispatch, localState)}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell rowSpan={3} colSpan={3} className='cell-client-details'>
							<span onClick={toggleShowTitleSummary} className='link-button btn-show-title-summary'>
								{state.estimateData?.showTitleSummary ? (
									<FontAwesomeIcon icon={faMinusCircle} />
								) : (
									<FontAwesomeIcon icon={faPlusCircle} />
								)}{' '}
								{state.estimateData?.showTitleSummary ? 'Remove the' : 'Add a'} title and summary
							</span>
							<InfoBox
								id='client-details'
								title='Client Details'
								value={state.estimateData?.clientDetails ?? 'Client Details'}
								useLabels={false}
								showBorder={true}
								multiLine={true}
								showBackground={true}
							/>
						</TableCell>
						<TableCell>
							<IonLabel>Reference</IonLabel>
							<IonInput
								className='ion-propeller'
								placeholder='Enter a reference...'
								value={localState.reference}
								onInput={handleReferenceChange}
								onIonChange={() => onItemChange('reference', dispatch, localState)}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<IonLabel>Amounts are</IonLabel>
							<SelectStyled
								value={state.estimateData?.amountsAre}
								isSearchable={false}
								options={amountsAreValues}
								onChange={(data: any) => {
									handleAmountsAreChange(data);
								}}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<IonLabel>Issued by</IonLabel>
							<InfoBox
								title='Issued By'
								value={state.estimateData?.issuedByName}
								useLabels={false}
								showBorder={true}
								showBackground={true}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</>
	);
};

export default EstimateHeader;

import { Alert } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { TimesheetsContext } from '../../TimesheetsProvider';
import { IonButton } from '@ionic/react';
import { DateTime } from 'luxon';
import { clone } from 'lodash';
import { ActionType } from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBackwardStep,
	faClockRotateLeft,
	faForwardStep,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { dateFormatterFullOrdinal } from '../../../../helpers/dateFormatter';
import { myTeamOptions } from '../../timesheets-constants';

type Props = {
	setIsLoading: Function;
	setCalLoading?: Function;
	setYearChange: Function;
	loadWeekEndings: Function;
	weekEndingOptions: Array<any>;
	yearSelectOptions: Array<any>;
	approvesAllTimesheets: boolean;
	DEFAULT_DATE_FORMAT: string;
};

const TimesheetHeader = (props: Props) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const yearRef: any = useRef(state.year);
	const [btnPrevDisabled, setBtnPrevDisabled] = useState<boolean>(true);
	const [btnNextDisabled, setBtnNextDisabled] = useState<boolean>(true);
	const [teamOptionsDisabled, setTeamOptionsDisabled] = useState<boolean>(true);

	useEffect(() => {
		const theYear = yearRef.current;
		if (theYear) {
			dispatch({
				type: ActionType.SET_YEAR,
				payload: theYear,
			});
		}
	}, [props.weekEndingOptions]);

	// Calculate end-stops for prev/next buttons
	useEffect(() => {
		const weeksIndex: number = props.weekEndingOptions.findIndex(
			(item: any) => item.value === state.weekEnding.toFormat(props.DEFAULT_DATE_FORMAT)
		);

		if (weeksIndex > -1 && props.weekEndingOptions.length > 0) {
			// Year start/end checks
			const yearsIndex: number = props.yearSelectOptions.findIndex(
				(item: any) => item.value === state.year
			);
			const atYearsStart: boolean = yearsIndex === 0;
			const atYearsEnd: boolean = yearsIndex >= props.yearSelectOptions.length - 1;

			// Week start/end checks
			const weeksIndex: number = props.weekEndingOptions.findIndex(
				(item: any) => item.value === state.weekEnding.toFormat(props.DEFAULT_DATE_FORMAT)
			);
			let prevWeekYear: number = state.year;
			if (weeksIndex > 0) {
				prevWeekYear = DateTime.fromFormat(
					props.weekEndingOptions[weeksIndex - 1].value,
					props.DEFAULT_DATE_FORMAT
				).year;
			}
			let nextWeekYear: number = state.year;
			if (weeksIndex + 1 <= props.weekEndingOptions.length - 1) {
				nextWeekYear = DateTime.fromFormat(
					props.weekEndingOptions[weeksIndex + 1].value,
					props.DEFAULT_DATE_FORMAT
				).year;
			}
			const atWeeksStart: boolean = weeksIndex === 0 || prevWeekYear !== state.year;
			const atWeeksEnd: boolean =
				weeksIndex >= props.weekEndingOptions.length - 1 || nextWeekYear !== state.year;

			// Button checks
			setBtnPrevDisabled(atYearsStart && atWeeksStart);
			setBtnNextDisabled(atYearsEnd && atWeeksEnd);
		}
	}, [state.weekEnding, props.weekEndingOptions]);

	useEffect(() => {
		setTeamOptionsDisabled(!props.approvesAllTimesheets);
	}, [props.approvesAllTimesheets]);

	const handleWeekButtonClick = (action: 'prev' | 'next') => {
		props.setIsLoading(true);
		props.setCalLoading && props.setCalLoading(true);
		let curWeekEnding: DateTime = clone(state.weekEnding);

		switch (action) {
			case 'prev':
				curWeekEnding = curWeekEnding.minus({ weeks: 1 });
				break;
			case 'next':
				curWeekEnding = curWeekEnding.plus({ weeks: 1 });
				break;
		}

		// Cross over year
		if (curWeekEnding.year !== state.year) {
			handleYearChange({ value: curWeekEnding.year });
		}

		dispatch({
			type: ActionType.SET_WEEK_ENDING,
			payload: curWeekEnding,
		});
	};

	const handleResetWeekending = () => {
		props.setIsLoading(true);
		const tmp: DateTime = DateTime.now().endOf('week');
		handleYearChange({ value: tmp.year });
		dispatch({
			type: ActionType.SET_WEEK_ENDING,
			payload: tmp,
		});
	};

	const handleYearChange = (sel: any) => {
		if (sel.value !== yearRef.current) {
			props.setYearChange(true);
			yearRef.current = sel.value;
			props.loadWeekEndings(yearRef.current);
		}
	};

	const handleMyTeamChange = (sel: any) => {
		if (sel.value !== state.showMyTeam) {
			props.setIsLoading(true);
			dispatch({
				type: ActionType.SET_SHOW_MY_TEAM,
				payload: sel.value,
			});
		}
	};

	return (
		<div className='timesheets-header-container'>
			<Alert severity='info' className='m-0 mb-2'>
				Timesheet entry for week-ending:{' '}
				<strong>{dateFormatterFullOrdinal(state.weekEnding)}</strong>
			</Alert>
			<div className='timesheets-header-controls-container'>
				<IonButton onClick={() => handleWeekButtonClick('prev')} disabled={btnPrevDisabled}>
					<FontAwesomeIcon icon={faBackwardStep} className='pe-2' />
					Previous Week
				</IonButton>

				<div className='timesheet-header-controls'>
					<Select
						options={myTeamOptions}
						value={myTeamOptions.find((item: any) => item.value === state.showMyTeam)}
						onChange={(sel: any) => handleMyTeamChange(sel)}
						className='sel-my-team'
						isSearchable={false}
						isDisabled={teamOptionsDisabled}
					/>
					<Select
						options={props.yearSelectOptions}
						value={props.yearSelectOptions.find((item: any) => item.value === state.year)}
						onChange={(sel: any) => handleYearChange(sel)}
						className='sel-years'
						isSearchable={false}
					/>
					<Select
						options={props.weekEndingOptions}
						value={props.weekEndingOptions.find(
							(item: any) => item.value === state.weekEnding.toFormat(props.DEFAULT_DATE_FORMAT)
						)}
						onChange={(sel: any) => {
							dispatch({
								type: ActionType.SET_WEEK_ENDING,
								payload: DateTime.fromFormat(
									props.weekEndingOptions.filter((item: any) => item.value === sel.value)[0].value,
									props.DEFAULT_DATE_FORMAT
								),
							});
						}}
						className='sel-weeks'
						classNamePrefix='timesheet-sel-weeks-react-select'
						isSearchable={false}
					/>
					<IonButton
						onClick={handleResetWeekending}
						color='secondary'
						title='Reset to current week'
						disabled={state.weekEnding.equals(DateTime.now().endOf('week'))}
					>
						<FontAwesomeIcon icon={faClockRotateLeft} />
					</IonButton>
				</div>

				<IonButton onClick={() => handleWeekButtonClick('next')} disabled={btnNextDisabled}>
					Next Week
					<FontAwesomeIcon icon={faForwardStep} className='ps-2' />
				</IonButton>
			</div>
		</div>
	);
};

export default TimesheetHeader;

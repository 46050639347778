import TimesheetsProvider from './TimesheetsProvider';
import TitleBar from '../../components/TitleBar/TitleBar';
import Subnav from '../../components/Menu/Subnav';
import TabsArray from './TimesheetsAllocations/TabsArray';
import { IonCard } from '@ionic/react';

const TimesheetsAllocations = () => {
	return (
		<TimesheetsProvider>
			<TitleBar title='Timesheets and Time Allocations' />
			<IonCard className='propeller-content-card full-height-card'>
				<Subnav array={TabsArray()} hideOverflow={false} />
			</IonCard>
		</TimesheetsProvider>
	);
};

export default TimesheetsAllocations;

import { useState } from 'react';
import { IonRow, IonCol, IonButton, IonCard } from '@ionic/react';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { useParams } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import ManagementStructureIndex from '../../../Utilities/Workers/ManagementStructure/ManagementStructureIndex';
import WorkerControls from '../WorkerControls';

interface Props {
	nextFunction?: Function;
	prevFunction?: Function;
}

const ManagementStructure: React.FC<Props> = (props: Props) => {
	const tabId = 'management_structure';
	const componentUID = `utilities.${tabId}`;
	const permissionTo = usePermissionTo(componentUID);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { workerId }: any = useParams();

	return (
		<>
			<IonRow>
				<IonCol size={'12'}>
					<IonCard className='table-card full-height-card component-utilities-management_structure'>
						{!permissionTo('read') && (
							<Alert severity='info'>
								<AlertTitle>Info</AlertTitle>
								Sorry, you do not have permission to use this item
							</Alert>
						)}

						{permissionTo('read') && (
							<ManagementStructureIndex
								uid={componentUID}
								routeTitle=''
								permissionTo={permissionTo}
								usageMode='worker_tabs'
								workerId={workerId}
							/>
						)}
					</IonCard>
				</IonCol>
			</IonRow>

			<WorkerControls tabId={tabId} workerId={workerId} setIsLoading={setIsLoading} />
		</>
	);
};

export default ManagementStructure;

import { Popup } from '@mobiscroll/react';
import { DateTime } from 'luxon';
import { MouseEventHandler } from 'react';
import Avatar from './Avatar';

interface Props {
	eventTipIsOpen: boolean;
	eventTipAnchor: any;
	currentEvent: any;
	onMouseEnter: MouseEventHandler;
	onMouseLeave: MouseEventHandler;
}

const EventTip = (props: Props) => {
	// Disable tooltip on part-created events
	if (
		!(props.currentEvent?.start instanceof DateTime) ||
		!(props.currentEvent?.end instanceof DateTime)
	) {
		return <></>;
	}

	return (
		<Popup
			display='anchored'
			isOpen={props.eventTipIsOpen}
			anchor={props.eventTipAnchor}
			touchUi={false}
			showOverlay={false}
			contentPadding={false}
			closeOnOverlayClick={false}
			width={350}
			cssClass='prop-tooltip'
		>
			<div onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
				<div className='prop-tooltip-header'>{props.currentEvent?.title}</div>
				<div className='prop-tooltip-info'>
					{props.currentEvent?.name && (
						<>
							<span className='prop-tooltip-text fw-bold'>{props.currentEvent.name}</span>
							<br />
						</>
					)}

					{props.currentEvent?.description && props.currentEvent.description.length > 0 && (
						<>
							<span className='prop-tooltip-text'>
								<span className='fw-bold'>Details: </span>
								{props.currentEvent.description}
							</span>
							<br />
						</>
					)}

					{props.currentEvent?.attendees && props.currentEvent.attendees.length > 0 && (
						<>
							<span className='prop-tooltip-text'>
								<span className='fw-bold'>Workers on this job: </span>
								<ul className='avatars'>
									{props.currentEvent.attendees.map((attendee: any) => (
										<Avatar key={attendee.worker_id} data={attendee} includeTitle={true} />
									))}
								</ul>
							</span>
						</>
					)}

					<span className='prop-tooltip-text'>
						{props.currentEvent?.start instanceof DateTime &&
							props.currentEvent?.end instanceof DateTime && (
								<>
									Scheduled: {props.currentEvent.start.toFormat('HH:mm')} to{' '}
									{props.currentEvent.end.toFormat('HH:mm')}
								</>
							)}
						{props.currentEvent?.requested === true ? (
							<>
								<br />
								<span className='prop-tooltip-text'> (Request pending approval)</span>
							</>
						) : (
							''
						)}
					</span>
				</div>
			</div>
		</Popup>
	);
};

export default EventTip;

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { authContext } from '../../contexts/AuthContext';
import { moduleContext } from '../../contexts/ModuleContext';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import TitleBar from '../../components/TitleBar/TitleBar';
import Widget from './widgets/Widget';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import './Dashboard.scss';
import { showToast } from '../../lib/toast';
import Loading from '../../components/UI/Loading';
import axios from '../../lib/axios';
import { cloneDeep, isEqual, has } from 'lodash';
import { Alert, AlertTitle } from '@mui/material';
import { Defaults, layoutDefaults } from './LayoutDefaults';

interface LayoutPayload {
	widget_positions: {
		[k: string]: Array<any>;
	};
}

const Dashboard: React.FC = () => {
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [widgetSettingsIsLoading, setWidgetSettingsIsLoading] = useState<boolean>(true);
	const [widgetSettings, setWidgetSettings] = useState<any>(null);
	const [systemSettingsIsLoading, setSystemSettingsIsLoading] = useState<boolean>(true);
	const [systemSettings, setSystemSettings] = useState<any>(null);
	const [dlcClass, setDLCClass] = useState<string>('');

	// Layout setup
	const cols = { lg: 12, sm: 6 };
	const defaults: Defaults = layoutDefaults(cols);

	// Set the dashboard layout container className to initialise the grid otherwise it may not show
	useEffect(() => {
		setDLCClass('dashboard-layout-container');
		loadWidgetSettings();
		loadSystemSettings();
		window.addEventListener('reloadWidgets', loadWidgetSettings, false);
	}, []);

	// General is-loading state
	useEffect(() => {
		setIsLoading(widgetSettingsIsLoading === true || systemSettingsIsLoading === true);
	}, [widgetSettingsIsLoading, systemSettingsIsLoading]);

	const loadWidgetSettings = () => {
		setWidgetSettingsIsLoading(true);

		// Important to clear all widget settings to force a layout reset
		setWidgetSettings(null);

		axios
			.get('/api/settings')
			.then((res: any) => {
				let layouts: any = null;
				let security: any = null;

				if (res.data) {
					// Get the security settings
					if (
						res.data.hasOwnProperty('widgets') &&
						res.data.widgets &&
						Array.isArray(res.data.widgets) &&
						authCtx.user.security.dashboard
					) {
						// Filter security items based on Utilities > Security Settings in case of changes
						security = res.data.widgets.filter((securityItem: any) => {
							const path = securityItem.split('.');
							return (
								has(authCtx.user.security, path) &&
								authCtx.user.security.dashboard[path[1]][path[2]].read === true
							);
						});
					}

					// Get the layout settings
					if (res.data.hasOwnProperty('widget_positions') && res.data.widget_positions) {
						// Use the latest settings saved by the user - blended with the defaults
						layouts = blendSettings(defaults, res.data.widget_positions);
					} else {
						// Use the default settings
						layouts = defaults;
					}
				}

				setWidgetSettings({ security, layouts });
			})
			.catch(() => showToast('error', 'Sorry, there was a problem loading your layout'))
			.finally(() => {
				setWidgetSettingsIsLoading(false);
			});
	};

	const loadSystemSettings = () => {
		setSystemSettingsIsLoading(true);
		moduleCtx
			.getSettings()
			.then((res: any) => {
				setSystemSettings(res);
			})
			.catch(() => {
				showToast('error', 'Failed to load system settings');
			})
			.finally(() => {
				setSystemSettingsIsLoading(false);
			});
	};

	const handleOnLayoutChange = useCallback(
		(allLayouts: Layouts) => {
			let allLayoutsTmp: Layouts = allLayouts;

			// Look for undefined widget layouts, e.g. for previously hidden widgets
			Object.entries(allLayoutsTmp).map(([size, layout]: any) => {
				layout.forEach((widgetLayout: any, index: number) => {
					// If one is found then replace it with the default for that size/widget combination
					if (widgetLayout.maxW === undefined) {
						// Replace this 'new' layout with the default for this widget
						const defaultLayout = defaults[size].filter(
							(defaultLayout: any) => defaultLayout.i === widgetLayout.i
						)[0];
						allLayoutsTmp[size][index] = defaultLayout;
					}
				});
			});

			// Compare the old and new layouts
			const oldLayout = Object.fromEntries(
				Object.entries(widgetSettings.layouts).map(([size, layout]: any) => [
					size,
					layout.map((widget: any) => ({
						i: widget.i,
						x: widget.x,
						y: widget.y,
						w: widget.w,
						h: widget.h,
					})),
				])
			);
			const newLayout = Object.fromEntries(
				Object.entries(allLayoutsTmp).map(([size, layout]: any) => [
					size,
					layout.map((widget: any) => ({
						i: widget.i,
						x: widget.x,
						y: widget.y,
						w: widget.w,
						h: widget.h,
					})),
				])
			);

			// Skip server if old and new layouts are the same
			if (isEqual(oldLayout, newLayout)) return;

			const payload: LayoutPayload = {
				widget_positions: newLayout,
			};

			axios
				.post('/api/settings', payload)
				.then(() => {
					// Update the widget settings state
					setWidgetSettings((prevState: any) => ({
						...prevState,
						layouts: blendSettings(defaults, newLayout),
					}));
				})
				.catch(() => showToast('error', 'Sorry, there was a problem saving your new layout'))
				.finally(() => {});
		},
		[widgetSettings, defaults]
	);

	function blendSettings(masterObject: any, changedObject: any) {
		let newLayout: any = cloneDeep(masterObject);
		newLayout = Object.fromEntries(
			// Get down the the level where we need to blend using a spread
			Object.entries(newLayout).map(([size, layout]: any) => {
				// Create a default and changed values blend
				const layoutBlend: any = layout.map((layoutDefault: any) => {
					// Filter the changed widget by layout size and identifier
					const widgetLayoutChanges = changedObject[size].filter(
						(widgetLayout: any) => widgetLayout.i === layoutDefault.i
					)[0];

					// Blend the defaults with the change at this level
					return { ...layoutDefault, ...widgetLayoutChanges };
				});

				// This widgets layout for this size is now blended with the defaults
				return [size, layoutBlend];
			})
		);

		return newLayout;
	}

	function BuildGrid() {
		// Width provider for responsive layout (useMemo to stop re-renders on layout state changes)
		const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);

		// Pre-build the widgets when all required data has been loaded
		const widgets = useMemo(() => {
			if (widgetSettings && Array.isArray(widgetSettings.security) && systemSettings) {
				return widgetSettings.security.map((widgetId: string) => {
					const hasWidgetLayoutLg = widgetSettings.layouts.lg.some(
						(item: any) => item.i === widgetId
					);
					const hasWidgetLayoutSm = widgetSettings.layouts.sm.some(
						(item: any) => item.i === widgetId
					);

					// Don't render the widget if its missing its layout (i.e. switching branches)
					if (!hasWidgetLayoutLg || !hasWidgetLayoutSm) return null;

					return (
						<div key={widgetId} className='widget'>
							<Widget widgetId={widgetId} systemSettings={systemSettings} />
						</div>
					);
				});
			}
		}, [widgetSettings, systemSettings]);

		if (widgets && widgets.length > 0) {
			return (
				<ResponsiveGridLayout
					className='dashboard-layout'
					measureBeforeMount={true}
					useCSSTransforms={false}
					layouts={widgetSettings.layouts}
					breakpoints={{ lg: 1400, sm: 0 }}
					cols={cols}
					rowHeight={30}
					containerPadding={[0, 0]}
					draggableHandle='.widget-header'
					onLayoutChange={(currentLayout: Layout[], allLayouts: Layouts) => {
						handleOnLayoutChange(allLayouts);
					}}
				>
					{widgets}
				</ResponsiveGridLayout>
			);
		} else {
			if (!isLoading) {
				return (
					<Alert severity='info'>
						<AlertTitle>Dashboard Widgets</AlertTitle>
						Please select some widgets from your settings by clicking on your avatar
					</Alert>
				);
			}
		}
	}

	return (
		<>
			<TitleBar title={authCtx.tenant.tenant_name} />
			{isLoading && <Loading overlay={true} background={false} />}
			<div className={dlcClass}>{BuildGrid()}</div>
		</>
	);
};

export default Dashboard;

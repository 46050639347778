export enum ActionType {
	RESET = 'RESET',
	SET_ITEMS = 'SET_ITEMS',
	UPDATE_ESTIMATE_DATA = 'UPDATE_ESTIMATE_DATA',
	SET_IS_LOADING = 'SET_IS_LOADING',
	ADD_ITEM_ROW = 'ADD_ITEM_ROW',
	DELETE_ITEM_ROW = 'DELETE_ITEM_ROW',
	UPDATE_ITEM_ROW = 'UPDATE_ITEM_ROW',
	UPDATE_ITEM_ROW_AMOUNT = 'UPDATE_ITEM_ROW_AMOUNT',
	UPDATE_ESTIMATE_TOTALS = 'UPDATE_ESTIMATE_TOTALS',
	SET_ESTIMATE_TOTALS_LOADING = 'SET_ESTIMATE_TOTALS_LOADING',
}

export interface ACTION_RESET {
	type: ActionType.RESET;
	payload: any;
}

export interface ACTION_SET_ITEMS {
	type: ActionType.SET_ITEMS;
	payload: any;
}

export interface ACTION_UPDATE_ESTIMATE_DATA {
	type: ActionType.UPDATE_ESTIMATE_DATA;
	payload: any;
}

export interface ACTION_SET_IS_LOADING {
	type: ActionType.SET_IS_LOADING;
	payload: any;
}

export interface ACTION_ADD_ITEM_ROW {
	type: ActionType.ADD_ITEM_ROW;
	payload: any;
}

export interface ACTION_UPDATE_ITEM_ROW {
	type: ActionType.UPDATE_ITEM_ROW;
	payload: any;
}

export interface ACTION_UPDATE_ITEM_ROW_AMOUNT {
	type: ActionType.UPDATE_ITEM_ROW_AMOUNT;
	payload: any;
}

export interface ACTION_DELETE_ITEM_ROW {
	type: ActionType.DELETE_ITEM_ROW;
	payload: any;
}

export interface ACTION_UPDATE_ESTIMATE_TOTALS {
	type: ActionType.UPDATE_ESTIMATE_TOTALS;
	payload: any;
}

export interface ACTION_SET_ESTIMATE_TOTALS_LOADING {
	type: ActionType.SET_ESTIMATE_TOTALS_LOADING;
	payload: any;
}

export type Actions =
	| ACTION_RESET
	| ACTION_SET_ITEMS
	| ACTION_UPDATE_ESTIMATE_DATA
	| ACTION_SET_IS_LOADING
	| ACTION_ADD_ITEM_ROW
	| ACTION_UPDATE_ITEM_ROW
	| ACTION_UPDATE_ITEM_ROW_AMOUNT
	| ACTION_DELETE_ITEM_ROW
	| ACTION_UPDATE_ESTIMATE_TOTALS
	| ACTION_SET_ESTIMATE_TOTALS_LOADING;

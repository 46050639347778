import { useContext, useRef } from 'react';
import { EstimateContext } from '../EstimatesProvider';
import Loading from '../../../../components/UI/Loading';
import EstimateHeader from './EstimateHeader';
import EstimateItems from './EstimateItems';
import EstimateFooter from './EstimateFooter';
import UploadsModal from '../../shared/estimates-invoices/UploadsModal';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	jobId?: string;
	estimateId?: string;
}

const EstimateCardLayout: React.FC<Props> = ({ permissionTo, isNew, jobId, estimateId }) => {
	const { state, dispatch } = useContext(EstimateContext);
	const uploadsModalRef = useRef<any>(null);

	const handleUploadsModalOpen = () => {
		if (uploadsModalRef.current) {
			uploadsModalRef.current.isOpen = true;
			uploadsModalRef.current.type = 'estimate';
			uploadsModalRef.current.typeId = estimateId;
		}
	};

	const handleUploadsModalClose = () => {
		if (uploadsModalRef.current) uploadsModalRef.current.isOpen = false;
	};

	return (
		<>
			{state.isLoading && <Loading overlay={true} />}
			<EstimateHeader
				permissionTo={permissionTo}
				isNew={isNew}
				jobId={jobId}
				estimateId={estimateId}
			/>
			<EstimateItems permissionTo={permissionTo} />
			<EstimateFooter
				permissionTo={permissionTo}
				isNew={isNew}
				estimateId={estimateId}
				handleUploadsModalOpen={handleUploadsModalOpen}
			/>
			<UploadsModal
				ref={uploadsModalRef}
				onClose={handleUploadsModalClose}
				permissionTo={permissionTo}
			/>
		</>
	);
};

export default EstimateCardLayout;

import { MbscCalendarColor } from '@mobiscroll/react';
import { DateTime } from 'luxon';

// Note: the different css class keys relate to different calendar view types
export const baseColours: MbscCalendarColor[] = [
	{
		cellCssClass: 'bg-cal-cell-today',
		cssClass: 'bg-cal-cell-today',
		date: DateTime.now(),
	},
	{
		cellCssClass: 'bg-cal-cell-weekend',
		cssClass: 'bg-cal-cell-weekend',
		recurring: { repeat: 'weekly', weekDays: 'SA' },
	},
	{
		cellCssClass: 'bg-cal-cell-weekend',
		cssClass: 'bg-cal-cell-weekend',
		recurring: { repeat: 'weekly', weekDays: 'SU' },
	},
];

import { faCircleCheck, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonAvatar, IonItem, IonLabel } from '@ionic/react';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';

import UserInterface from '../../../interfaces/Chat/UserInterface';
import { useSubscribe } from '../../../helpers/events';
import { webSocketContext } from '../../../contexts/WebSocketContext';

import './UserItem.scss';

interface Props {
	item: UserInterface;
	index: number;
	click: Function;
}

const UserItem: React.FC<Props> = (props: Props) => {
	const webSocketCtx: any = useContext(webSocketContext);
	const [online, setIsOnline] = useState<any>(null);

	useSubscribe(
		'userLastOnline',
		(data: any) => {
			if (data.user && data.user === props.item._id) {
				let online: any = data.isOnline;
				if (data.isOnline === null ) {
					online = false;
				} else if (typeof data.isOnline !== 'boolean') {
					online = DateTime.fromISO(data.isOnline).toFormat('dd LLL yyyy HH:mm');
				}
				setIsOnline(online);
			}
		},
		[]
	);

	useEffect(() => {
		webSocketCtx.sendMessage(
			JSON.stringify({
				type: 'User:lastOnline',
				data: { user_id: props.item._id },
			})
		);
	}, []);

	return (
		<>
			<div>
				<IonItem
					onClick={() => {
						props?.click(props.item._id);
					}}
				>
					<IonAvatar slot='start'>
						{props.item.file !== undefined && (
							<img src={props.item.file} alt='Staff member photograph' />
						)}
						{props.item.file === undefined && (
							<div className='icon-surround'>
								<FontAwesomeIcon icon={faUser} />
							</div>
						)}
					</IonAvatar>
					<IonLabel>
						<h2>{props.item?.first_name + ' ' + props.item?.last_name}</h2>
						<p className={`${online === true ? 'text-success' : 'text-danger'}`}>
							{online === null ? (
								<FontAwesomeIcon icon={faSpinner} />
							) : online === true ? (
								'online'
							) : online ? (
								'last online ' + online
							) : (
								'never been online'
							)}
						</p>
					</IonLabel>
					{props.item?.selected === true && (
						<FontAwesomeIcon className='text-primary' icon={faCircleCheck}></FontAwesomeIcon>
					)}
					{props.item?.selected === false && (
						<FontAwesomeIcon className='text-primary' icon={faCircle}></FontAwesomeIcon>
					)}
				</IonItem>
			</div>
		</>
	);
};

export default UserItem;

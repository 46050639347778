import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const faMapMarker: IconDefinition = {
	prefix: 'fas',
	iconName: 'map',
	icon: [
		240,
		240,
		[] as string[],
		'',
		'M 120.74975,224.04436 52.329391,119.29309 c -5.526754,-11.09635 -8.225865,-22.535449 -8.225865,-34.445818 0,-42.07188 34.231601,-76.303482 76.346324,-76.303482 42.11472,0 76.30347,34.231602 76.30347,76.303482 0,11.010665 -2.31352,21.678588 -6.89772,31.703858 z m -0.2999,-206.846272 c -37.273458,0 -67.606341,30.332882 -67.606341,67.649184 0,10.539393 2.399211,20.693198 7.069103,30.118668 l 60.794298,93.09796 61.56546,-95.66854 c 3.77019,-8.31156 5.82665,-17.779871 5.82665,-27.548088 C 188.0561,47.573813 157.7233,17.198088 120.44985,17.198088 Z m 0,108.607142 c -22.921034,0 -41.600608,-18.67957 -41.600608,-41.600603 0,-22.921035 18.679574,-41.600607 41.600608,-41.600607 22.92103,0 41.6006,18.679572 41.6006,41.600607 0,22.921033 -18.67957,41.600603 -41.6006,41.600603 z m 0,-74.50407 c -18.12262,0 -32.903466,14.780854 -32.903466,32.903467 0,18.122613 14.780846,32.903463 32.903466,32.903463 18.12261,0 32.90346,-14.78085 32.90346,-32.903463 0,-18.122613 -14.78085,-32.903467 -32.90346,-32.903467 z m 79.60239,159.89029 h -56.98126 l -20.26477,20.26476 h 77.24603 c 5.61245,0 10.11097,-4.54136 10.11097,-10.11096 v 0 c 0,-5.61245 -4.54136,-10.1538 -10.11097,-10.1538 z m -160.104492,0 c -5.61244,0 -10.110961,4.54135 -10.110961,10.11096 v 0 c 0,5.61244 4.541364,10.11096 10.110961,10.11096 H 117.53652 L 97.271757,211.14861 H 39.947748 Z',
	],
};

import { useContext, useEffect, useState } from 'react';
import TitleBar from '../../../components/TitleBar/TitleBar';
import EditJobSubmenuArray from './EditJobSubmenuArray';
import Submenu from '../../../components/Menu/Submenu';
import JobsContext from '../../../contexts/JobsContext';
import { useSubscribe } from '../../../helpers/events';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';

interface Props {
	routeTitle: string;
	permissionTo: Function;
}

const JobCardLayout: React.FC<Props> = (props: Props) => {
	const { jobData } = useContext(JobsContext);
	const [jobMessagesNotRead, setJobMessagesNotRead] = useState<number>(0);

	// Get the conversation data
	useEffect(() => {
		axios
			.post(`/api/conversations/${jobData?.conversation_id}`)
			.then((res: any) => {
				setJobMessagesNotRead(res.data.notRead);
			})
			.catch(() => showToast('error', "Could not get this Job's messages"));
	}, []);

	// Update the job messages not read value
	useSubscribe(
		'database:new:conversations',
		(data: any) => {
			if (data.conversation_id === jobData?.conversation_id) {
				setJobMessagesNotRead(data.notRead);
			}
		},
		[]
	);

	return (
		<>
			<TitleBar title={props.routeTitle + ' Job - ' + jobData?.number + ': ' + jobData?.name} />

			<Submenu
				array={EditJobSubmenuArray(props.permissionTo, jobMessagesNotRead)}
				extraOptions={{ jobMessagesNotRead }}
			/>
		</>
	);
};

export default JobCardLayout;

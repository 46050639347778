import { ActionType } from './actions';
import axios from '../../../lib/axios';
import { getTimezone } from '../../../lib/functions';

export const updateSubmenuBadgeCounts = (dispatch: any): void => {
	let payload: any = { ...getTimezone() };

	axios.post('/api/workers/holidays_and_absences/requests/count', payload).then((res: any) => {
		dispatch({ type: ActionType.UPDATE_MENU_COUNTS, payload: res.data });
	});
};

import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
	IonTextarea,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useContext, useRef, useState } from 'react';
import axios from '../../../lib/axios';
import Loading from '../Loading';
import { showToast } from '../../../lib/toast';
import { authContext } from '../../../contexts/AuthContext';

interface ClientTextMessage {
	dataType: 'client';
	clientId: string | undefined;
	jobId: string | undefined;
}

interface WorkerTextMessage {
	dataType: 'worker';
	workerId: string | undefined;
}

type ModalData = ClientTextMessage | WorkerTextMessage;

type Props = {
	title: string;
	modalData: ModalData;
	onClose: Function;
};

const TextMessageModal = (props: Props, ref: any) => {
	const authCtx: any = useContext(authContext);
	const textMessageRef: any = useRef<HTMLIonTextareaElement>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [didPresent, setDidPresent] = useState<boolean>(false);
	const [curMsg, setCurMsg] = useState<string>('');
	const [curMsgLen, setCurMsgLen] = useState<number>(0);
	const MAX_MSG_LEN = 160;

	const handleOnDidPresent = () => {
		setDidPresent(true);

		const defaultMsg = 'From ' + authCtx.tenant.tenant_name + ': ';
		setCurMsg(defaultMsg);
		setCurMsgLen(defaultMsg.length);

		// Focus the text message entry
		setTimeout(() => {
			if (textMessageRef.current) textMessageRef.current.setFocus();
		}, 100);
	};

	const onClose = () => {
		// Reset
		setIsLoading(false);
		setDidPresent(false);
		setCurMsgLen(0);
		props.onClose();
	};

	const handleTextMessageInput = (e: any) => {
		const len: number = e.target.value.length;
		setCurMsg(e.target.value);
		setCurMsgLen(len);

		// Limit maximum message size
		if (len >= MAX_MSG_LEN) {
			textMessageRef.current.value = textMessageRef.current.value.substring(0, MAX_MSG_LEN);
			setCurMsg(textMessageRef.current.value);
			setCurMsgLen(MAX_MSG_LEN);
		}
	};

	const handleTextMessageOnFocus = (e: any) => {
		// Move caret to end
		e.target.nativeInput.setSelectionRange(e.target.value.length, e.target.value.length);
	};

	const handleSendTextMessage = () => {
		let payload: any = { message: textMessageRef.current.value };

		switch (props.modalData.dataType) {
			case 'client':
				payload.client_id = props.modalData.clientId;
				payload.job_id = props.modalData.jobId;
				break;
		}

		setIsLoading(true);
		axios
			.put('/api/jobs/clients_list/client_card/messages', payload)
			.then(() => showToast('success', 'Text message sent'))
			.catch(() => showToast('error', 'There was a problem sending the text message'))
			.finally(() => {
				setIsLoading(false);
				onClose();
			});
	};

	return (
		<IonModal
			ref={ref}
			class='text-message-modal'
			style={{ '--width': '600px', '--height': '280px' }}
			onDidPresent={handleOnDidPresent}
			onWillDismiss={() => onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{props.title}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{didPresent && (
					<>
						{isLoading && <Loading overlay={true} />}
						<IonTextarea
							ref={textMessageRef}
							rows={3}
							value={curMsg}
							onIonInput={handleTextMessageInput}
							onIonFocus={handleTextMessageOnFocus}
						/>
						<div className={`char-count ${curMsgLen === MAX_MSG_LEN ? 'text-danger' : ''}`}>
							Message length: {`${curMsgLen}/${MAX_MSG_LEN}`}
						</div>
					</>
				)}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton
								color='primary'
								onClick={handleSendTextMessage}
								disabled={curMsgLen <= 0 || isLoading}
							>
								Send Text Message
							</IonButton>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default forwardRef(TextMessageModal);

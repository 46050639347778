import InvoicesProvider from '../InvoicesProvider';
import InvoiceCardLayout from './InvoiceCardLayout';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	estimateId: string;
	jobId: string;
	invoiceId?: string;
}

const InvoiceCard: React.FC<Props> = ({ permissionTo, isNew, estimateId, jobId, invoiceId }) => {
	return (
		<InvoicesProvider>
			<InvoiceCardLayout
				permissionTo={permissionTo}
				isNew={isNew}
				estimateId={estimateId}
				jobId={jobId}
				invoiceId={invoiceId}
			/>
		</InvoicesProvider>
	);
};

export default InvoiceCard;

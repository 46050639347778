import { subtleGreyScale } from '../../../components/Maps/MapStyles';
import { useContext, useEffect, useState } from 'react';
import { PropCluster, PropMarker } from '../../../interfaces/Maps/MapsInterface';
import { useSubscribe } from '../../../helpers/events';
import { webSocketContext } from '../../../contexts/WebSocketContext';
import Map from '../../../components/Maps/Map';
import Loading from '../../../components/UI/Loading';
import {
	Subordinate,
	locations,
	removeMarker,
	removeWorker,
	subscribeGPSWorkerStartBreak,
	subscribeGPSWorkerUpdate,
} from '../../../helpers/liveLocations';
import { authContext } from '../../../contexts/AuthContext';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const TeamLocations: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const webSocketCtx: any = useContext(webSocketContext);
	const [mapCenter, setMapCenter] = useState({ lat: 54.093409058179, lng: -2.8947900255456 });
	const [markers, setMarkers] = useState<Array<PropMarker>>([]);
	const [clusters, setClusters] = useState<Array<PropCluster>>([]);
	const [zoom, setZoom] = useState<number>(6);
	const [idleFunc, setIdleFunc] = useState<Function>(() => {});
	const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
	const [locationData, setLocationData] = useState<any>({});
	const [loading, setLoading] = useState<boolean>(false);

	// Listen to user location updates
	useEffect(() => {
		if (webSocketCtx.isConnected) {
			webSocketCtx.sendMessage(
				JSON.stringify({
					type: 'User:locationUpdates',
					data: true,
				})
			);
		}
	}, [webSocketCtx.isConnected]);

	// Stop listening to location updates on exit
	useEffect(() => {
		return () => {
			webSocketCtx.sendMessage(
				JSON.stringify({
					type: 'User:locationUpdates',
					data: false,
				})
			);
		};
	}, []);

	useEffect(() => {
		if (mapRef) {
			locations(
				mapRef,
				setLoading,
				setMapCenter,
				setZoom,
				setIdleFunc,
				setMarkers,
				setClusters,
				true
			);
		}
	}, [mapRef]);

	useSubscribe(
		'GPS:workerStartBreak',
		(data: any) => {
			subscribeGPSWorkerStartBreak(data, locationData, setLocationData, clusters, setClusters);
		},
		[locationData, markers]
	);

	useSubscribe(
		'GPS:workerStopBreak',
		(data: any) => {
			let localData = removeWorker(data.item._id, locationData);
			setLocationData(localData);
		},
		[locationData]
	);

	useSubscribe(
		'GPS:workerStop',
		(data: any) => {
			let localData = removeWorker(data.item._id, locationData);
			setLocationData(localData);

			removeMarker(data.item._id, clusters, setClusters);
		},
		[locationData, markers]
	);

	useSubscribe(
		'GPS:workerUpdate',
		(data: any) => {
			let subordinates: Array<string> = [];
			if (authCtx.user && authCtx.user.hasOwnProperty('subordinates')) {
				subordinates = authCtx.user.subordinates.map(
					(subordinate: Subordinate) => subordinate.worker_id
				);
			}

			subscribeGPSWorkerUpdate(
				data,
				clusters,
				setClusters,
				locationData,
				setLocationData,
				true,
				authCtx.user.worker_id,
				subordinates
			);
		},
		[markers]
	);

	return (
		<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
			<div className='widget-header'>My Team Locations</div>
			<div className='widget-content'>
				{process.env.NODE_ENV === 'production' && loading && <Loading overlay={true} />}
				<Map
					mapStyle={subtleGreyScale}
					center={mapCenter}
					markers={markers}
					markerClusters={clusters}
					zoom={zoom}
					onMapZoom={(zoom: number) => {
						setZoom(zoom);
					}}
					onMapLoad={(map: google.maps.Map) => {
						setMapRef(map);
					}}
					onMapIdle={idleFunc}
				/>
			</div>
		</div>
	);
};

export default TeamLocations;

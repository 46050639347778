import KeyDetails from './JobTabs/KeyDetails';

const NewJobTabsArray: any = (
	permissionTo: Function,
	createJob: Function,
	nextFunction: Function
) => {
	return [
		{
			title: 'Key Details',
			page: KeyDetails,
			pageData: {newJob: true, nextFunction, createJob, permissionTo},
			active: true,
		},
		{ title: 'Job Targets and Skills' },
		{ title: 'Job Dates' },
	];
};

export default NewJobTabsArray;

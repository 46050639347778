import { IonRow, IonCol, IonButton } from '@ionic/react';
import { useParams } from 'react-router-dom';
import Resources from '../../components/Resources';
import JobControls from '../JobControls';
import JobTitle from '../JobTitle';

interface Props {
	permissionTo: Function;
}

const TargetsAndSkills: React.FC<Props> = (props: Props) => {
	const { jobId }: any = useParams();

	return (
		<>
			<JobTitle />

			<Resources jobId={jobId} permissionTo={props.permissionTo} />

			<IonRow className='mt-4'>
				<IonCol size={'12'} className='text-right'>
					<JobControls permissionTo={props.permissionTo} jobId={jobId} />
				</IonCol>
			</IonRow>
		</>
	);
};

export default TargetsAndSkills;

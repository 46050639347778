import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
	useIonAlert,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useRef, useState } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import { FileUpload } from '../../../../components/Forms/FormFields';
import { showToast } from '../../../../lib/toast';
import { toast } from 'react-toastify';
import axiosMP from '../../../../lib/axiosMp';
import { AxiosProgressEvent } from 'axios';
import axios from '../../../../lib/axios';
import Loading from '../../../../components/UI/Loading';

type Props = {
	onClose: Function;
	permissionTo: Function;
};

const UploadsModal = (props: Props, ref: any) => {
	const gridRef: any = useRef<any>();
	const toastId = useRef<any>(null);
	const [presentAlert] = useIonAlert();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [data, setData] = useState<any>(null);
	const [didPresent, setDidPresent] = useState<boolean>(false);
	const [gridUrl, setGridUrl] = useState<string>('');
	const [gridPayload, setGridPayload] = useState<any>(null);
	const [uploadUrl, setUploadUrl] = useState<string>('');
	const [typeId, setTypeId] = useState<string>('');
	const cols = [
		{
			headerName: 'File name',
			field: 'file_name',
			flex: 3,
		},
		{
			headerName: 'Date',
			field: 'date',
			valueFormatter: (params: any) => dateFormatter(params, true),
			minWidth: 180,
			maxWidth: 180,
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellRenderer: (params: any) => (
				<>
					<IonButton
						color='danger'
						onClick={() => handleDeleteFile(params.data._id)}
						className='ag-grid-row-button me-2'
					>
						Delete
					</IonButton>
					<IonButton
						color='primary'
						onClick={() => handleDownloadFile(params.data)}
						className='ag-grid-row-button'
					>
						Download
					</IonButton>
				</>
			),
			minWidth: 270,
			maxWidth: 270,
			type: 'rightAligned',
			flex: 1,
		},
	];

	const handleOnWillPresent = () => {
		if (ref.current) {
			setTypeId(ref.current.typeId);

			switch (ref.current.type) {
				case 'estimate':
					setGridUrl('/api/jobs/estimates_list/estimates_card/uploads');
					setUploadUrl(`/api/jobs/estimates_list/estimates_card/${ref.current.typeId}/upload`);
					setGridPayload({ estimate_id: ref.current.typeId });
					break;
				case 'invoice':
					setGridUrl('/api/jobs/invoices_list/invoices_card/uploads');
					setUploadUrl(`/api/jobs/invoices_list/invoices_card/${ref.current.typeId}/upload`);
					setGridPayload({ invoice_id: ref.current.typeId });
					break;
			}
		}
	};

	const handleOnDidPresent = () => {
		setDidPresent(true);
		loadGridData();
	};

	const onClose = () => {
		// Reset
		setDidPresent(false);
		setData(null);
		setGridUrl('');
		setGridPayload(null);
		setTypeId('');
		setUploadUrl('');
		setIsLoading(false);
		setGridLoading(false);
		props.onClose();
	};

	const loadGridData = () => {
		setData([]);
		setGridLoading(true);

		axios
			.post(gridUrl, gridPayload)
			.then((res: any) => {
				setData(res.data);
			})
			.catch(() => showToast('error'))
			.finally(() => setGridLoading(false));
	};

	const handleFileUpload = async (e: any, fileId: string) => {
		const file = e.target.files[0];

		let fd = new FormData();
		fd.append('_method', 'PUT');
		fd.append('upload', file);

		// Reset
		toastId.current = null;

		setIsLoading(true);

		await axiosMP
			.post(uploadUrl, fd, {
				onUploadProgress: (p: AxiosProgressEvent) => {
					const progress = p.loaded / (p.total ?? 0);

					if (toastId.current === null) {
						toastId.current = toast('Uploading file...', { type: toast.TYPE.INFO });
					} else {
						toast.update(toastId.current, {
							progress,
							render: 'Uploading file (' + Math.floor(progress * 100) + '%)...',
						});
					}
				},
			})
			.then(() => {
				showToast('success');
				loadGridData();
			})
			.catch(() => showToast('error'))
			.finally(() => {
				toast.dismiss(toastId.current);
				setIsLoading(false);
			});

		return true;
	};

	const handleDownloadFile = (fileData: any) => {
		let link = document.createElement('a');
		link.href = fileData.file_path;
		link.download = fileData.file_name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleDeleteFile = (uploadId: string) => {
		if (props.permissionTo('delete')) {
			presentAlert({
				header: 'Delete Estimate',
				message: 'Are you sure you want to delete this file?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							setIsLoading(true);
							axios
								.delete(`${uploadUrl}/${uploadId}`)
								.then(() => {
									showToast('deleted');
									loadGridData();
								})
								.catch(() => showToast('error'))
								.finally(() => setIsLoading(false));
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	return (
		<IonModal
			ref={ref}
			class='estimates-invoices-uploads-modal'
			style={{ '--width': '70vw', '--height': '80vh' }}
			onWillPresent={handleOnWillPresent}
			onDidPresent={handleOnDidPresent}
			onWillDismiss={() => onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Upload Files</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{(isLoading || gridLoading) && <Loading overlay={true} />}
				{didPresent && gridUrl.length > 0 && gridPayload && (
					<DataGrid ref={gridRef} useSearch={false} title={null} cols={cols} data={data} />
				)}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							{typeId.length > 0 && props.permissionTo('update') && (
								<FileUpload
									id={'upload'}
									db={'upload'}
									title={''}
									useLabels={false}
									editMode={true}
									miniUi={true}
									handleFileUpload={handleFileUpload}
									disabled={isLoading}
								/>
							)}
							<IonButton color='secondary' onClick={() => onClose()} disabled={isLoading}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default forwardRef(UploadsModal);

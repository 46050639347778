import { useState, useRef } from 'react';
import { IonAccordion, IonAccordionGroup, IonButton, IonItem } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Loading from '../UI/Loading';

interface Props {
	loading: boolean;
	items: Array<any>;
	children?: Array<any>;
	handleOnClick: Function;
	handleAccordionChange?: Function;
}

const FilterMenu: React.FC<Props> = (props: Props) => {
	const accordionRef = useRef<null | HTMLIonAccordionGroupElement>(null);
	const [selected, setSelected] = useState<any>(null);
	const [selectedAccordion, setSelectedAccordion] = useState('0');
	const items = props.items;

	const buildMenuItems = () => {
		let isSelected = false;
		let colour = "";

		let menuItems = items.map((item: any, i: number) => {
			let menuItemOutput = null;
			let contentParent = (
				<div className="filter-menu-container">
					<div className="label">{item.label}</div>
					{item.colour && (
						<div className="label colour-block-container">
							<div className="colour-block" style={{ backgroundColor: `#${item.colour}` }}></div>
						</div>
					)}
				</div>
			);

			if (props.children && props.children.length > 0) {
				// Accordion-based menu item
				let contentChild: Array<any> = [];
				props.children.forEach((c: any, j: number) => {
					if (c.parentID === item._id) {
						isSelected = selected === c.id;
						colour = isSelected ? "secondary" : "alt";

						contentChild.push((
							<IonButton
								key={j}
								color={colour}
								expand={'full'}
								onClick={(e: any) => {
									props.handleOnClick(
										e,
										{
											childID: c.id,
											title: `${props.items.filter((_item: any) => _item._id === item._id)[0].label}: ${c.name}`,
										}
									);
									setSelected(c.id);
								}}>
								<div className="filter-menu-container button">
									<div className="label">
										{c.name}
									</div>
									<FontAwesomeIcon icon={faUser} />
								</div>
							</IonButton>
						));
					};
				});

				menuItemOutput = (
					<IonAccordion key={i} value={item._id}>
						<IonItem slot="header">
							{contentParent}
						</IonItem>
						<div className="ion-padding" slot="content">
							{contentChild.length > 0 && contentChild}
						</div>
					</IonAccordion>
				);
			} else {
				// Standard button-based menu item
				isSelected = (!selected && i === 0) || selected === item._id;
				colour = isSelected ? "secondary" : "alt";

				menuItemOutput = (
					<IonButton
						key={i}
						color={colour}
						expand={'full'}
						onClick={(e: any) => {
							props.handleOnClick(e, item._id);
							setSelected(item._id);
						}}>
						{contentParent}
					</IonButton>
				);
			}

			return menuItemOutput;
		});

		return menuItems;
	}

	const buildMenu = () => {
		let menuStructure: any = buildMenuItems();

		if (props.children && props.children.length > 0) {
			menuStructure = (
				<IonAccordionGroup
					animated={false}
					className="filter-menu-accordion"
					ref={accordionRef}
					value={selectedAccordion}
					onIonChange={handleAccordionChange}
				>
					{menuStructure}
				</IonAccordionGroup>
			);
		}

		return menuStructure;
	}

	const handleAccordionChange = (e: any) => {
		setSelected(null);

		if (!e.detail.value) {
			// Don't allow a state where no accordion items are selected
			if (accordionRef.current) {
				accordionRef.current.value = selectedAccordion;
			}
		} else {
			setSelectedAccordion(e.detail.value);
			if (props.handleAccordionChange) {
				props.handleAccordionChange(
					e,
					{
						parentID: e.detail.value,
						title: props.items.filter((item: any) => item._id === e.detail.value)[0].label,
					}
				);
			}
		}
	}

	return (
		<>
			{props.loading && <Loading width={100} height={100} />}
			{!props.loading && buildMenu()}
		</>
	);
};

export default FilterMenu;

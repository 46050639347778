import { useState, useEffect, useCallback, useMemo } from 'react';
import { Eventcalendar, setOptions, localeEnGB, MbscEventcalendarView } from '@mobiscroll/react';
import { IonCol, IonRow } from '@ionic/react';
import Loading from '../UI/Loading';
import { DateTime } from 'luxon';
import { showToast } from '../../lib/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CalendarContextMenu from './CalendarContextMenu';

setOptions({
	locale: localeEnGB,
	theme: 'material',
	themeVariant: 'light',
});

interface Props {
	calData: Array<any>;
	loading?: boolean;
	showBreaktimes?: boolean;
	showLocations?: boolean;
	useContextMenu?: boolean;
	allowScrollToEvent: boolean;
	breaktimeDropdownData: Array<any>;
	locationsDropdownData: Array<any>;
	setAllowScrollToEvent: Function;
	permissionTo?: Function;
	onEventCreate?: Function;
	onEventUpdate?: Function;
	onEventDragStart?: Function;
	onEventDelete?: Function;
	clearUndoData?: Function;
}

const WorkingHoursCalendar: React.FC<Props> = (props: Props) => {
	const [myEvents, setMyEvents] = useState<Array<any>>([]);
	const [allowScrollToEventInternal, setAllowScrollToEventInternal] = useState<boolean>(true);
	const weekdaysIso = useMemo(() => {
		return ['', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
	}, []);

	// Right-click context menu
	const [contextMenuInst, setContextMenuInst] = useState<any>();
	const [contextMenuAnchor, setContextMenuAnchor] = useState<any>();
	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
	const [contextMenuRightClickedEvent, setContextMenuRightClickedEvent] = useState<any>(null);
	const contextMenuOptions: Array<any> = [
		{
			text: (
				<>
					<FontAwesomeIcon icon={faTrash} className='pe-2' />
					Delete
				</>
			),
			value: 'delete',
			action: () => {
				if (props.onEventDelete) props.onEventDelete(contextMenuRightClickedEvent);
			},
			hasPermission: !props.permissionTo || !props.permissionTo('delete') ? false : true,
		},
	];

	const view: MbscEventcalendarView = {
		schedule: {
			type: 'week',
			startDay: 1,
			endDay: 0,
			startTime: '00:00',
			endTime: '00:00',
		},
	};

	const contextMenu = [
		{
			text: 'Delete',
			value: 'delete',
		},
	];

	useEffect(() => {
		setAllowScrollToEventInternal(props.allowScrollToEvent);
	}, [props]);

	useEffect(() => {
		if (props.calData.length > 0) {
			const events: any = props.calData.map((d: any) => {
				const dStart = DateTime.fromFormat(`2018-01 ${d.hours_start}`, 'yyyy-MM HH:mm').set({
					weekday: weekdaysIso.indexOf(d.day_of_week),
				});
				const dEnd = DateTime.fromFormat(`2018-01 ${d.hours_end}`, 'yyyy-MM HH:mm').set({
					weekday: weekdaysIso.indexOf(d.day_of_week),
				});

				return {
					_id: d._id,
					start: dStart.toJSDate(),
					end: dEnd.toJSDate(),
					title: 'Working Hours',
					recurring: {
						repeat: 'weekly',
						weekDays: d.day_of_week.substring(0, 2).toUpperCase(),
					},
				};
			});

			setMyEvents(events);
		} else {
			setMyEvents([]);
		}
	}, [props.calData, weekdaysIso]);

	const dayHasEvents = useCallback(
		(day: string, excludeId: string = '') => {
			return (
				myEvents.filter(
					(d: any) => DateTime.fromJSDate(d.start).toFormat('ccc').toLowerCase() === day
				).length > 0
			);
		},
		[myEvents]
	);

	const hasDayConflict = useCallback((args: any, inst: any, excludeId: string = '') => {
		const evOrig = args.event;
		const ev = args.newEvent;

		// Ignore same-day changes
		if (
			DateTime.fromJSDate(evOrig.start).toFormat('ccc') ===
			DateTime.fromJSDate(ev.start).toFormat('ccc')
		) {
			return false;
		}

		const rangeStart = DateTime.fromJSDate(ev.start)
			.set({ hour: 0, minute: 0, second: 0 })
			.toJSDate();
		const rangeEnd = DateTime.fromJSDate(ev.end)
			.set({ hour: 23, minute: 59, second: 59 })
			.toJSDate();
		const events = inst.getEvents(rangeStart, rangeEnd).filter((e: any) => e.id !== ev.id);

		return events.length > 0;
	}, []);

	const onEventCreate = useCallback(
		(event: any, inst: any) => {
			// This function is also called on updating events which we don't want
			if (event.hasOwnProperty('originEvent')) return false;

			const dayOfWeek = DateTime.fromJSDate(event.event.start).toFormat('ccc').toLowerCase();

			// Allow only one event per day
			if (dayHasEvents(dayOfWeek, event.event._id)) {
				showToast('error', 'Only one block per day is allowed');
				if (props.clearUndoData) props.clearUndoData();
				return false;
			}

			if (props.onEventCreate) {
				props.onEventCreate(event);
			}
		},
		[props, dayHasEvents]
	);

	const onEventUpdate = useCallback(
		(event: any, inst: any) => {
			// Allow only one event per day
			if (hasDayConflict(event, inst)) {
				showToast('error', 'Only one block per day is allowed');
				if (props.clearUndoData) props.clearUndoData();
				return false;
			}

			if (props.onEventUpdate) props.onEventUpdate(event);
		},
		[props, hasDayConflict]
	);

	const onEventDragStart = useCallback(
		(event: any, inst: any) => {
			if (props.onEventDragStart) props.onEventDragStart(event);
		},
		[props, hasDayConflict]
	);

	const onPageLoaded = useCallback(
		(event: any, inst: any) => {
			// Only allow an initial scroll
			if (allowScrollToEventInternal === false) return false;

			const events = inst.getEvents();

			if (events.length > 0) {
				events.sort(function (a: any, b: any) {
					return a.start - b.start;
				});

				inst.navigateToEvent(events[0]);
			}

			props.setAllowScrollToEvent(false);
		},
		[allowScrollToEventInternal, props]
	);

	const onEventRightClick = useCallback((e: any, inst: Eventcalendar) => {
		e.domEvent.preventDefault();

		// Open the context menu
		setContextMenuAnchor(e.domEvent.target);
		setContextMenuRightClickedEvent(e);
		setTimeout(() => setContextMenuOpen(true));
	}, []);

	return (
		<>
			<div className='label-breaktimes'>Breaktimes</div>
			<div className='label-locations'>Locations</div>
			<IonRow>
				<IonCol size={'12'} className='working-hours-calendar pt-0 pb-0'>
					{props.loading === true && <Loading overlay={true} />}
					<Eventcalendar
						refDate={new Date(2018, 1, 1, 0, 0)}
						clickToCreate={props.permissionTo && props.permissionTo('create')}
						dragToCreate={props.permissionTo && props.permissionTo('create')}
						dragToMove={props.permissionTo && props.permissionTo('update')}
						dragToResize={props.permissionTo && props.permissionTo('update')}
						dragTimeStep={15}
						data={myEvents}
						view={view}
						onPageLoaded={onPageLoaded}
						onEventCreate={onEventCreate}
						onEventUpdate={onEventUpdate}
						onEventRightClick={onEventRightClick}
						onEventDragStart={onEventDragStart}
						allDay={false}
						renderHeader={() => {
							return null;
						}}
					/>
					{props.useContextMenu && (
						<CalendarContextMenu
							options={contextMenuOptions}
							contextMenuOpen={contextMenuOpen}
							contextMenuAnchor={contextMenuAnchor}
							setContextMenuInst={setContextMenuInst}
							setContextMenuOpen={setContextMenuOpen}
							setContextMenuRightClickedEvent={setContextMenuRightClickedEvent}
						/>
					)}
				</IonCol>
			</IonRow>
			{props.showBreaktimes !== false && (
				<IonRow>
					<IonCol size={'12'} className='pt-0 pb-0'>
						<div className='mbsc-flex mbsc-flex-1-1 mbsc-schedule-grid-wrapper mbsc-material prop-table'>
							<div className='mbsc-flex-col mbsc-schedule-time-col mbsc-schedule-time-cont mbsc-material mbsc-ltr prop-col-resize'>
								&nbsp;
							</div>
							<div className='mbsc-flex-col mbsc-flex-1-1 mbsc-material'>
								<div className='mbsc-flex mbsc-flex-1-1'>
									<div className='mbsc-flex mbsc-flex-1-0 mbsc-schedule-grid'>
										<div className='mbsc-flex mbsc-flex-1-0 mbsc-schedule-resource-group mbsc-material mbsc-ltr'>
											{props.breaktimeDropdownData.map((dd: any, i: number) => {
												return (
													<div
														key={i}
														className='mbsc-flex-col mbsc-flex-1-0 mbsc-schedule-column mbsc-schedule-col-width mbsc-material mbsc-ltr prop-breaktime'
													>
														{dd.dropdownForm}
													</div>
												);
											})}
											<div className='scrollbar-padding prop-breaktime'></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</IonCol>
				</IonRow>
			)}
			{props.showLocations !== false && (
				<IonRow>
					<IonCol size={'12'} className='pt-0 pb-0'>
						<div className='mbsc-flex mbsc-flex-1-1 mbsc-schedule-grid-wrapper mbsc-material prop-table'>
							<div className='mbsc-flex-col mbsc-schedule-time-col mbsc-schedule-time-cont mbsc-material mbsc-ltr prop-col-resize'>
								&nbsp;
							</div>
							<div className='mbsc-flex-col mbsc-flex-1-1 mbsc-material'>
								<div className='mbsc-flex mbsc-flex-1-1'>
									<div className='mbsc-flex mbsc-flex-1-0 mbsc-schedule-grid'>
										<div className='mbsc-flex mbsc-flex-1-0 mbsc-schedule-resource-group mbsc-material mbsc-ltr'>
											{props.locationsDropdownData.map((dd: any, i: number) => {
												return (
													<div
														key={i}
														className='mbsc-flex-col mbsc-flex-1-0 mbsc-schedule-column mbsc-schedule-col-width mbsc-material mbsc-ltr prop-locations'
													>
														{dd.dropdownForm}
													</div>
												);
											})}
											<div className='scrollbar-padding prop-locations'></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</IonCol>
				</IonRow>
			)}
		</>
	);
};

export default WorkingHoursCalendar;

export const faScheduling = {
	prefix: 'fas',
	iconName: 'scheduling',
	icon: [
		100,
		100,
		[] as string[],
		null,
		'M81,14.69h-2.35v-4.21c0-1.19-.97-2.15-2.15-2.15s-2.15,.97-2.15,2.15v4.21h-13.1v-4.21c0-1.19-.97-2.15-2.15-2.15s-2.15,.97-2.15,2.15v4.21h-13.1v-4.21c0-1.19-.97-2.15-2.15-2.15s-2.15,.97-2.15,2.15v4.21h-13.1v-4.21c0-1.19-.97-2.15-2.15-2.15s-2.15,.97-2.15,2.15v4.21h-2.62c-5.36,0-9.72,4.36-9.72,9.72v54.83c0,5.36,4.36,9.72,9.72,9.72h61.51c5.36,0,9.72-4.36,9.72-9.72V24.41c0-5.36-4.36-9.72-9.72-9.72Zm-61.51,4.37h2.62v4.73c0,1.19,.97,2.15,2.15,2.15s2.15-.97,2.15-2.15v-4.73h13.1v4.73c0,1.19,.97,2.15,2.15,2.15s2.15-.97,2.15-2.15v-4.73h13.1v4.73c0,1.19,.97,2.15,2.15,2.15s2.15-.97,2.15-2.15v-4.73h13.1v4.73c0,1.19,.97,2.15,2.15,2.15s2.15-.97,2.15-2.15v-4.73h2.35c2.95,0,5.35,2.4,5.35,5.35v10.92H14.13v-10.92c0-2.95,2.4-5.35,5.35-5.35Zm61.51,65.53H19.48c-2.95,0-5.35-2.4-5.35-5.35V39.7H86.34v39.53c0,2.95-2.4,5.35-5.35,5.35Zm-4.26-30.01c0,1.51-1.22,2.73-2.73,2.73h-6.86c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73h6.86c1.51,0,2.73,1.22,2.73,2.73Zm-20.1,0c0,1.51-1.22,2.73-2.73,2.73h-6.86c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73h6.86c1.51,0,2.73,1.22,2.73,2.73Zm-20.1,0c0,1.51-1.22,2.73-2.73,2.73h-6.86c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73h6.86c1.51,0,2.73,1.22,2.73,2.73Zm40.21,14.45c0,1.51-1.22,2.73-2.73,2.73h-6.86c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73h6.86c1.51,0,2.73,1.22,2.73,2.73Zm-20.1,0c0,1.51-1.22,2.73-2.73,2.73h-6.86c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73h6.86c1.51,0,2.73,1.22,2.73,2.73Zm-20.1,0c0,1.51-1.22,2.73-2.73,2.73h-6.86c-1.51,0-2.73-1.22-2.73-2.73s1.22-2.73,2.73-2.73h6.86c1.51,0,2.73,1.22,2.73,2.73Z',
	],
};

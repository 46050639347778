import { Alert } from '@mui/material';
import { useContext } from 'react';
import { InvoiceContext } from '../InvoicesProvider';

const InvoiceTitle: React.FC = () => {
	const { state, dispatch } = useContext(InvoiceContext);

	let statusText = 'New';

	if (state.isLoading) statusText = 'Loading...';

	if (state.invoiceData?.status) statusText = state.invoiceData.status;

	let msg: Array<string> = [`Invoice Status: ${statusText}`];

	return <Alert severity='info'>{msg.join(', ')}</Alert>;
};

export default InvoiceTitle;

import {
	faCircleCheck,
	faCircleExclamation,
	faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const returnSubmenuBadge = (item: any) => {
	if (item.badge) {
		if (item.badge === 'success') {
			return <FontAwesomeIcon icon={faCircleCheck} className='badge icon-success submenu-badge' />;
		} else if (item.badge === 'warning') {
			return (
				<FontAwesomeIcon icon={faCircleExclamation} className='badge icon-warning submenu-badge' />
			);
		} else if (item.badge === 'danger') {
			return <FontAwesomeIcon icon={faCircleXmark} className='badge icon-danger submenu-badge' />;
		} else {
			console.log('Submenu badge not found');
		}
	} else if (!isNaN(Number(item.badgeNumber)) && Number(item.badgeNumber) > 0) {
		return (
			<div className='badge submenu-badge badge-number'>
				{Number(item.badgeNumber) > 99 ? 99 : Number(item.badgeNumber)}
			</div>
		);
	} else if (item.badgeColor) {
		return <div className={'badge submenu-badge badge-color badge-' + item.badgeColor}>&nbsp;</div>;
	}
};

import { DateTime } from 'luxon';
import { BoundarySize } from '../../../../components/Maps/map-types';

export enum EventType {
	TRAINING = 'training',
	OTHER = 'other',
}

export interface EventLocation {
	address1: string;
	address2: string;
	town: string;
	postcode: string;
	latitude: string;
	longitude: string;
	boundarySize: BoundarySize;
}

export interface EventLocationPayload {
	address_1: string;
	address_2: string;
	town: string;
	postcode: string;
	latitude: string;
	longitude: string;
	boundary_size: BoundarySize;
}

export interface EventDate {
	start: string;
	end: string;
}

export interface EventData {
	requestId: string | undefined;
	selectedDate: Date | undefined;
	multiDay: boolean;
	startDate?: DateTime;
	endDate?: DateTime;
	time: Array<any>;
	type?: EventType;
	description: string;
	companyName?: string;
	contactName?: string;
	contactNumber?: string;
	attendees?: { label: string; value: string }[];
	location?: EventLocation;
	certificate: boolean;
}

export interface EventPayload {
	dates: EventDate[];
	event_type: EventType;
	description: string;
	company_name?: string;
	contact_name?: string;
	contact_number?: string;
	worker_ids: Array<string>;
	location: EventLocationPayload;
	enable_uploads: boolean;
}

import HolidayChart from './HolidayChart';
import Requests from './Requests/RequestsIndex';
import SickIndex from './Sick/SickIndex';
import TrainingEventIndex from './TrainingEvent/TrainingEventIndex';
import { MenuCounts } from './hat-types';

const HatTabsArray: any = (menuCounts: MenuCounts) => {
	return [
		{
			id: 'submenu-hatholidaychart',
			uid: 'workers.holidays_and_absences.holiday_chart',
			title: 'Holiday Chart',
			page: HolidayChart,
			hash: 'holiday-chart',
			active: true,
			fullHeight: true,
		},
		{
			id: 'submenu-hatrequests',
			uid: 'workers.holidays_and_absences.requests',
			title: 'Requests',
			page: Requests,
			hash: 'requests',
			active: false,
			fullHeight: true,
			badgeNumber: menuCounts.requests,
		},
		{
			id: 'submenu-hatsick',
			uid: 'workers.holidays_and_absences.sick',
			title: 'Sick',
			page: SickIndex,
			hash: 'sick',
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-hattrainingevent',
			uid: 'workers.holidays_and_absences.training_or_event',
			title: 'Training or Event',
			page: TrainingEventIndex,
			hash: 'training-event',
			active: false,
			fullHeight: true,
		},
	];
};

export default HatTabsArray;

import { IonRow, IonCol, IonLabel } from '@ionic/react';
import { useContext, useState, useRef, useEffect } from 'react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';

interface SFProps {
	defaultSelected: { label: string; payload: { status: Array<string> } };
	onChange?: Function;
}

const ListFilterPaymentRegister: React.FC<SFProps> = ({ defaultSelected, onChange }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [statuses, setStatuses] = useState<any>();
	const [workers, setWorkers] = useState<any>();

	const filterByInvoiceStatusRef = useRef<any>();
	const filterByIssuedByRef = useRef<any>();
	const [filterStatus, setFilterStatus] = useState<any>({
		label: defaultSelected.label,
		value: defaultSelected.payload.status,
	});
	const [filterIssuedBy, setFilterIssuedBy] = useState<any>({ label: '', value: '' });

	useEffect(() => {
		setStatuses(moduleCtx.getInvoicePaymentRegisterOptions());

		// Set the default option
		if (filterByInvoiceStatusRef.current) {
			filterByInvoiceStatusRef.current.setValue({
				label: defaultSelected.label,
				value: defaultSelected.payload.status,
			});
		}

		moduleCtx.getWorkersOptions().then((res: any) => {
			setWorkers(res);
		});
	}, []);

	useEffect(() => {
		applyFilters();
	}, [filterStatus, filterIssuedBy]);

	const setFilters = (newValue: any, filterName: string) => {
		switch (filterName) {
			case 'status':
				if (!newValue) newValue = { label: '', value: '' };
				if (newValue.value !== filterStatus.value) setFilterStatus(newValue);
				break;
			case 'worker_id':
				setFilterIssuedBy(newValue);
				break;
		}
	};

	const applyFilters = () => {
		let label: string = 'All';
		let payload: any = { status: '', worker_id: '' };

		if (filterStatus.value) {
			label = filterStatus.label;
			payload.status = filterStatus.value;
		}

		if (filterIssuedBy) payload.worker_id = filterIssuedBy.value;

		if (onChange) onChange(label, payload);
	};

	return (
		<div className='d-flex w-100 align-items-center'>
			<div className='pe-3 text-nowrap'>
				<IonLabel>Invoice Status</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={statuses}
					onChange={(newValue: any) => setFilters(newValue, 'status')}
					forwardRef={filterByInvoiceStatusRef}
					isClearable={false}
				/>
			</div>
			<div className='ps-4 pe-3 text-nowrap'>
				<IonLabel>Issued By</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={workers}
					onChange={(newValue: any) => setFilters(newValue, 'worker_id')}
					forwardRef={filterByIssuedByRef}
					isClearable={true}
				/>
			</div>
		</div>
	);
};

export default ListFilterPaymentRegister;

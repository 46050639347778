const useMyDetailsFormArray = () => {
	let myDetails: Array<any> = [
		{
			title: 'Type of Worker',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'type_of_engagement',
			title: 'Status',
			type: 'dropdown',
			db: ['key_details', 'type_of_engagement'],
		},
		{
			title: 'Name',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'title',
			title: 'Title',
			type: 'text',
			db: ['user', 'title'],
		},
		{
			id: 'first_name',
			title: 'Name',
			type: 'text',
			db: ['user', 'first_name'],
		},
		{
			id: 'last_name',
			title: 'Surname',
			type: 'text',
			db: ['user', 'last_name'],
		},
		{
			title: 'Contact Details and Security',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'mobile',
			title: 'Mobile Number',
			type: 'phone',
			db: ['user', 'mobile'],
		},
		{
			id: 'email',
			title: 'Email Address',
			type: 'email',
			db: ['user', 'email'],
		},
		{
			id: 'role_id',
			title: 'Security Setting',
			type: 'dropdown',
			db: ['key_details', 'role_id'],
		},
		{
			title: 'Title and Skills',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'job_title',
			title: 'Job Title',
			type: 'text',
			db: ['key_details', 'job_title'],
		},
		{
			id: 'job_skills',
			title: 'Job Skills',
			type: 'dropdownAsync',
			db: ['key_details', 'job_skills'],
		},
		{
			title: 'Address',
			type: 'title',
			style: 'alpha',
		},
		{
			title: 'Address 1',
			type: 'text',
			db: ['key_details', 'address', 'address_1'],
		},
		{
			title: 'Address 2',
			type: 'text',
			db: ['key_details', 'address', 'address_2'],
		},
		{
			title: 'Town',
			type: 'text',
			db: ['key_details', 'address', 'town'],
		},
		{
			title: 'Postcode',
			type: 'postcode',
			db: ['key_details', 'address', 'postcode'],
		},
		{
			title: 'Latitude',
			type: 'text',
			db: ['key_details', 'address', 'latitude'],
		},
		{
			title: 'Longitude',
			type: 'text',
			db: ['key_details', 'address', 'longitude'],
		},
		{
			title: 'Next of Kin',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'name',
			title: 'Next of Kin Name',
			type: 'text',
			db: ['next_of_kin', 'name'],
		},
		{
			id: 'contact_number',
			title: 'Next of Kin Contact Number',
			type: 'phone',
			db: ['next_of_kin', 'contact_number'],
		},
		{
			id: 'email_address',
			title: 'Next of Kin Email Address',
			type: 'email',
			db: ['next_of_kin', 'email_address'],
		},
		{
			id: 'relationship',
			title: 'Relationship',
			type: 'dropdown',
			db: ['next_of_kin', 'relationship'],
		},
		{
			title: 'Next of Kin Address',
			type: 'title',
			style: 'alpha',
		},
		{
			title: 'Address 1',
			type: 'text',
			group: 'kin',
			db: ['next_of_kin', 'address', 'address_1'],
		},
		{
			title: 'Address 2',
			type: 'text',
			group: 'kin',
			db: ['next_of_kin', 'address', 'address_2'],
		},
		{
			title: 'Town',
			type: 'text',
			group: 'kin',
			db: ['next_of_kin', 'address', 'town'],
		},
		{
			title: 'Postcode',
			type: 'postcode',
			group: 'kin',
			db: ['next_of_kin', 'address', 'postcode'],
		},
	];

	return { myDetails };
};

export default useMyDetailsFormArray;

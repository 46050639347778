export const faUtilities = {
	prefix: 'fas',
	iconName: 'utilities',
	icon: [
		100,
		100,
		[] as string[],
		null,
		'M84.94,40.53h-4.73c-.64-1.93-1.44-3.77-2.41-5.52l3.11-3.11c1.17-1.17,1.17-3.06,0-4.23l-8.95-8.95c-.56-.56-1.32-.88-2.12-.88s-1.56,.32-2.12,.88l-3.36,3.36c-1.81-.93-3.68-1.69-5.59-2.25v-4.36c0-1.65-1.34-2.99-2.99-2.99h-12.65c-1.65,0-2.99,1.34-2.99,2.99v4.54c-1.98,.62-3.89,1.42-5.71,2.41l-2.92-2.92c-.56-.56-1.32-.88-2.12-.88s-1.56,.32-2.12,.88l-8.95,8.95c-1.17,1.17-1.17,3.06,0,4.23l2.99,2.99c-1.02,1.91-1.83,3.91-2.44,5.96h-3.81c-1.65,0-2.99,1.34-2.99,2.99v12.65c0,1.65,1.34,2.99,2.99,2.99h3.99c.66,2.12,1.53,4.15,2.6,6.07l-2.56,2.56c-.56,.56-.88,1.32-.88,2.12,0,.79,.32,1.56,.88,2.12l8.95,8.95c.56,.56,1.32,.88,2.12,.88s1.56-.32,2.12-.88l2.81-2.81c1.97,1.02,4.03,1.82,6.14,2.4v3.66c0,1.65,1.34,2.99,2.99,2.99h12.65c1.65,0,2.99-1.34,2.99-2.99v-4.18c2.06-.68,4.02-1.55,5.87-2.61l2.76,2.76c.58,.58,1.35,.88,2.12,.88s1.53-.29,2.12-.88l8.95-8.95c.56-.56,.88-1.32,.88-2.12s-.32-1.56-.88-2.12l-3.17-3.17c.93-1.86,1.67-3.79,2.21-5.78h4.21c1.65,0,2.99-1.34,2.99-2.99v-12.66c0-1.65-1.34-2.99-2.99-2.99Zm0,15.65h-6.59c-.64,3.34-1.86,6.47-3.54,9.31l4.76,4.76-8.95,8.95-4.44-4.44c-2.79,1.91-5.93,3.32-9.3,4.17v6.42h-12.65v-6.03c-3.48-.67-6.73-1.96-9.65-3.75l-4.41,4.41-8.95-8.95,4.22-4.22c-1.9-2.86-3.29-6.07-4.09-9.52h-6.28v-12.65h6.14c.72-3.41,2.05-6.59,3.85-9.44l-4.62-4.62,8.95-8.95,4.57,4.57c2.77-1.79,5.86-3.11,9.18-3.88v-6.83h12.65v6.69c3.28,.69,6.33,1.95,9.1,3.64l4.96-4.96,8.95,8.95-4.77,4.77c1.8,2.7,3.13,5.73,3.95,8.97h6.97v12.66ZM50,31.91c-10.32,0-18.72,8.4-18.72,18.72s8.4,18.72,18.72,18.72,18.72-8.4,18.72-18.72-8.4-18.72-18.72-18.72Zm0,32.98c-7.86,0-14.26-6.39-14.26-14.26s6.4-14.26,14.26-14.26,14.26,6.4,14.26,14.26-6.4,14.26-14.26,14.26Z',
	],
};

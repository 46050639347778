import { createContext } from 'react';
import axios from '../lib/axios';
import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import { useIonAlert } from '@ionic/react';
import { toast } from 'react-toastify';
import { showToast } from '../lib/toast';
import { useHistory } from 'react-router-dom';
import usePermissionTo from '../utils/custom-hooks/PermissionTo';
import Client from '../interfaces/Jobs/Client';
import { JobSkills, JobSkillsOptions } from '../interfaces/Utilities/JobSkills';
import {
	WorkSuspendedReasonOption,
	WorkSuspendedReasonOptionGroup,
} from '../interfaces/Jobs/WorkSuspendedReason';
import { AxiosResponse } from 'axios';
import { Settings } from '../types/module-context.d';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCar, faHome } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

export const moduleContext = createContext({});

const { Provider } = moduleContext;

const ModuleProvider = ({ children, ...props }: any) => {
	let providerValues: any = {};
	let history = useHistory();
	const [presentAlert] = useIonAlert();
	const permissionTo = usePermissionTo('workers.workers_list.worker_card');

	switch (props.moduleName) {
		case 'workers':
			providerValues['handleLeaveEmployment'] = (workerId: string, setIsLoading: Function) => {
				if (permissionTo('delete')) {
					presentAlert({
						header: 'Leave Employment',
						message: 'Are you sure you remove this worker from your employment?',
						buttons: [
							{
								text: 'Cancel',
								role: 'cancel',
							},
							{
								text: 'OK',
								role: 'confirm',
								handler: () => {
									const toastID = toast.loading('Please wait...');
									setIsLoading(true);

									axios
										.delete(`/api/workers/workers_list/worker_card/${workerId}`)
										.then((res) => {
											showToast('deleted', null, toastID);
											history.replace('/workers/workers_list');
										})
										.catch((err) => {
											showToast('error', null, toastID);
										});
								},
							},
						],
					});
				} else {
					showToast('permission');
				}
			};
			break;
		default:
			// No module-specific constants
			break;
	}

	// Globally available items
	providerValues['getSecuritySettingsTemplatesOptions'] = async (workerID: string = '') => {
		let roles: Array<any> = [];
		let url = `/api/utilities/security_settings/roles`;
		url = workerID.length > 0 ? `${url}/${workerID}` : url;

		await axios.get(url).then((d: any) => {
			for (let i in d.data) {
				let colourLight = chroma(d.data[i].colour).brighten().hex();
				colourLight = colourLight.substring(colourLight.indexOf('#') + 1);

				let colourDark = chroma(d.data[i].colour).darken(1).saturate(3).hex();
				colourDark = colourDark.substring(colourDark.indexOf('#') + 1);

				roles.push({
					label: d.data[i].name,
					value: d.data[i].id,
					colour: d.data[i].colour,
					colourLight: colourLight,
					colourDark: colourDark,
					colourContrast: '000000',
				});
			}
		});

		return roles;
	};

	providerValues['getUDFFieldTypeOptions'] = async () => {
		let fieldTypes: Array<any> = [];
		await axios.get('/api/utilities/general/user_defined_fields/field_types').then((res: any) => {
			fieldTypes = res.data;
		});

		return fieldTypes;
	};

	providerValues['getUDFTypeOptions'] = async () => {
		let types: Array<any> = [];
		await axios.get('/api/utilities/general/user_defined_fields/types').then((res: any) => {
			types = res.data;
		});

		return types;
	};

	// This function should be used sparingly as its more for getting security information on workers
	// (see function getWorkersList below for getting a normal list of workers in the system)
	providerValues['getWorkers'] = async () => {
		let workers: Array<any> = [];
		await axios.get('/api/utilities/security_settings').then((res: any) => {
			if (res.data && res.data.length > 0) {
				res.data.forEach((d: any) => {
					const role = d.roles.filter((r: any) => r.selected === true);
					workers.push({
						id: d.id,
						name: String(`${d.first_name} ${d.last_name}`).trim(),
						first_name: d.first_name,
						last_name: d.last_name,
						job_title: d.job_title ? d.job_title : '',
						email: d.email ? d.email : '',
						role_id: role && role[0] ? role[0].id : '',
						role_colour: role && role[0] ? role[0].colour : '',
						role_name: role && role[0] ? role[0].name : '',
						worker_photo: d.worker_photo,
						working_hours: d.hasOwnProperty('working_hours') ? d.working_hours : [],
						type_of_engagement: d.type_of_engagement,
						appears_on_organisation_chart: d.appears_on_organisation_chart,
					});
				});
			}
		});

		// Sort
		workers = workers.sort((a: any, b: any) => {
			return a.last_name > b.last_name ? 1 : -1;
		});

		return workers;
	};

	// This is the main function for getting a list of workers in the system
	providerValues['getWorkersList'] = async (
		bossId?: string,
		leaders?: boolean,
		jobManagers?: boolean,
		approvers?: boolean,
		hasTarget?: boolean
	) => {
		let workers: Array<any> = [];
		let payload: any = {};

		if (bossId && bossId.length > 0) payload.boss_id = bossId;
		if (leaders === true) payload.leaders = true;
		if (jobManagers === true) payload.job_managers = true;
		if (approvers === true) payload.approvers = true;
		if (hasTarget === true) payload.has_target = true; // Sales target

		await axios.post('/api/workers/workers_list', payload).then((res: any) => {
			if (res.data && res.data.length > 0) {
				res.data.forEach((d: any) => {
					workers.push({
						id: d.id,
						name: String(`${d.first_name} ${d.last_name}`).trim(),
						first_name: d.first_name,
						last_name: d.last_name,
						job_title: d.job_title ? d.job_title : '',
						email: d.email ? d.email : '',
						role_id: d.hasOwnProperty('role') ? d.role?.id : '',
						role_colour: d.hasOwnProperty('role') ? d.role?.colour : '',
						role_name: d.hasOwnProperty('role') ? d.role?.name : '',
						worker_photo: d.worker_photo,
						working_hours: d.hasOwnProperty('working_hours') ? d.working_hours : [],
						skills: d.hasOwnProperty('skills') ? d.skills : [],
						type_of_engagement_enum: d.hasOwnProperty('type_of_engagement_enum')
							? d.type_of_engagement_enum
							: '',
					});
				});
			}
		});

		// Sort
		workers = workers.sort((a: any, b: any) => {
			return a.last_name > b.last_name ? 1 : -1;
		});

		return workers;
	};

	providerValues['getWorker'] = async (workerId: string) => {
		let worker: Array<any> = [];
		await axios.get(`/api/workers/workers_list/worker_card/${workerId}`).then((res: any) => {
			worker = res.data;
		});

		return worker;
	};

	providerValues['getJobSkills'] = async (includeCodes: boolean = false) => {
		return await axios
			.get('/api/utilities/workers/job_skills')
			.then((res) => res.data)
			.then((res) => {
				return res.map((item: JobSkills) => {
					let skill = item.skill;

					if (skill) {
						if (includeCodes && item.code) skill = `${item.code} - ${skill}`;
					} else {
						skill = 'Undefined Skill';
					}

					item.skill = skill;

					return item;
				});
			})
			.then((res) => {
				return res.sort((a: any, b: any) => {
					return a.skill > b.skill ? 1 : -1;
				});
			});
	};

	providerValues['getJobSkillsOptions'] = async (
		includeCodes: boolean = false
	): Promise<JobSkillsOptions[]> => {
		return providerValues['getJobSkills'](includeCodes).then((res: any) => {
			return res.map((option: JobSkills) => {
				return {
					label: option.skill,
					value: option._id,
					hourlyRate: option.hourly_rate,
				};
			});
		});
	};

	providerValues['getWorkerTypesOfEngagementOptions'] = async () => {
		let typesOfEngagement: Array<any> = [];
		await axios.get('/api/workers/workers_list/types_of_engagement').then((res: any) => {
			typesOfEngagement = res.data;
		});

		return typesOfEngagement;
	};

	providerValues['getPaymentMethodsOptions'] = async (props: { workerId?: string }) => {
		let paymentMethods: Array<any> = [];
		let payload: any = {};

		if (props && props.workerId) payload.worker_id = props.workerId;

		await axios.post('/api/workers/workers_list/payment_methods', payload).then((res: any) => {
			paymentMethods = res.data;
		});

		return paymentMethods;
	};

	providerValues['getNIRate'] = async () => {
		let rate: Array<any> = [];
		await axios.get('/api/utilities/general/national_insurance_rates').then((res: any) => {
			if (res.data && res.data.length > 0) {
				rate = res.data[0];
			}
		});

		return rate;
	};

	providerValues['getKinRelationshipOptions'] = async () => {
		let relationships: Array<any> = [];
		await axios.get('/api/workers/workers_list/relationships').then((res: any) => {
			relationships = res.data;
		});

		return relationships;
	};

	providerValues['getJobTypeOptions'] = async () => {
		let jobTypes: Array<any> = [];
		await axios.get('/api/utilities/jobs/job_types').then((res: any) => {
			for (let i in res.data) {
				jobTypes.push({
					label: res.data[i].type,
					value: res.data[i]._id,
				});
			}

			// Sort
			jobTypes = jobTypes.sort((a: any, b: any) => {
				return a.label > b.label ? 1 : -1;
			});
		});

		return jobTypes;
	};

	providerValues['getWorkersOptions'] = async () => {
		let workersOptions: Array<any> = [];
		await providerValues['getWorkers']().then((res: any) => {
			for (let i in res) {
				workersOptions.push({
					label: res[i].name,
					value: res[i].id,
				});
			}
		});

		return workersOptions;
	};

	providerValues['getClients'] = async (): Promise<any> => {
		return await axios.post('/api/jobs/clients_list').then((res) =>
			res.data.map((client: any) => {
				let client_name_full: string = '';

				if (client.company_name) {
					client_name_full = `${client.company_name} (${client.first_name} ${client.last_name})`;
				} else {
					client_name_full = `${client.first_name} ${client.last_name}`;
				}

				client.client_name_full = client_name_full;
				return client;
			})
		);
	};

	providerValues['getClient'] = async (clientId: string): Promise<Client> => {
		return await axios
			.get<Client>(`/api/jobs/clients_list/client_card/${clientId}`)
			.then((res) => res.data);
	};

	providerValues['getWorkSuspendedReasonsOptions'] = async (
		jobTypeId: string
	): Promise<WorkSuspendedReasonOptionGroup[]> => {
		let options: WorkSuspendedReasonOptionGroup[] = [];
		await axios
			.post('/api/jobs/jobs_list/job_card/work_suspended_reasons', { job_type_id: jobTypeId })
			.then((res) => res.data)
			.then((res) => {
				res.forEach((element: WorkSuspendedReasonOptionGroup) => {
					// Convert the sub-options to a sorted array
					let subOptions: WorkSuspendedReasonOption[] = Object.keys(element.options)
						.map((optionIndex: string) => {
							return element.options[Number(optionIndex)];
						})
						.sort((a: WorkSuspendedReasonOption, b: WorkSuspendedReasonOption) => {
							return a.label > b.label ? 1 : -1;
						});

					// Gather the grouped options
					options.push({
						label: element.label,
						options: subOptions,
					});
				});
			});
		return options;
	};

	providerValues['getSettings'] = async () => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse<Settings>>(
				'/api/utilities/company_details/settings'
			);
			return response.data[0].settings;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getEstimateStatusOptions'] = async () => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>('/api/jobs/estimates_list/statuses');
			return response.data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getEstimateJob'] = async (jobId: string) => {
		let response: AxiosResponse;
		try {
			response = await axios.post<AxiosResponse>('/api/jobs/estimates_list/estimates_card/search', {
				job_id: jobId,
			});
			return response.data[0];
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getJobs'] = async (
		includeResources?: boolean,
		managerId?: string,
		status?: string
	) => {
		let response: AxiosResponse;
		let payload: any = {};

		if (includeResources === true) payload.include_resources = true;
		if (managerId && managerId.length > 0) payload.manager_id = managerId;
		if (status && status.length > 0) payload.status = status;

		try {
			response = await axios.post<AxiosResponse>('/api/jobs/jobs_list', payload);
			return response.data.sort((a: any, b: any) => {
				return a.name > b.name ? 1 : -1;
			});
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getJob'] = async (jobId: string) => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>(`/api/jobs/jobs_list/job_card/${jobId}`);
			return response.data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getVatRates'] = async () => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>('/api/jobs/items/vat_rates');
			return response.data.sort((a: any, b: any) => {
				return a.description > b.description ? 1 : -1;
			});
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getEstimate'] = async (estimateId: string, mode: 'invoice' | undefined) => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>(
				`/api/jobs/estimates_list/estimates_card/${estimateId}${mode ? `/${mode}` : ''}`
			);
			return response.data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getCatalogueItems'] = async () => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>('/api/utilities/jobs/catalogue');
			return response.data.sort((a: any, b: any) => {
				return `${a.code} - ${a.name}` > `${b.code} - ${b.name}` ? 1 : -1;
			});
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getBoundaryOptions'] = () => {
		return [
			{ label: 'Small', value: 'small' },
			{ label: 'Medium', value: 'medium' },
			{ label: 'Large', value: 'large' },
		];
	};

	providerValues['getWorkerLocationOptions'] = async () => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>('/api/utilities/office_locations');
			let data = response.data
				.sort((a: any, b: any) => {
					return a.name > b.name ? 1 : -1;
				})
				.map((item: any) => {
					return {
						name: item.name,
						label: (
							<>
								<FontAwesomeIcon icon={faBuilding} className='button-icon' />
								<span>{item.name}</span>
							</>
						),
						value: item._id,
					};
				});

			// Prepend the default values
			return [
				{
					id: 'defaults',
					label: 'Defaults',
					options: [
						{
							name: 'Schedule',
							label: (
								<>
									<FontAwesomeIcon icon={faCalendar} className='button-icon' />
									<span>Schedule</span>
								</>
							),
							value: '0',
						},
						{
							name: 'Home',
							label: (
								<>
									<FontAwesomeIcon icon={faHome} className='button-icon' />
									<span>Home</span>
								</>
							),
							value: '1',
						},
						{
							name: 'Roaming',
							label: (
								<>
									<FontAwesomeIcon icon={faCar} className='button-icon' />
									<span>Roaming</span>
								</>
							),
							value: '2',
						},
					],
				},
				{
					id: 'offices',
					label: 'Office Locations',
					options: [...data],
				},
			];
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getInvoiceStatusOptions'] = async () => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>('/api/jobs/invoices_list/statuses');
			return response.data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getJobStatusOptions'] = async () => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>('/api/jobs/jobs_list/statuses');
			return response.data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getInvoiceNumberFromEstimate'] = async (estimateId: string) => {
		let response: AxiosResponse;
		try {
			response = await axios.post<AxiosResponse>('/api/jobs/invoices_list/invoices_card/number', {
				estimate_id: estimateId,
			});
			return response.data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getInvoice'] = async (invoiceId: string) => {
		let response: AxiosResponse;
		try {
			response = await axios.get<AxiosResponse>(
				`/api/jobs/invoices_list/invoices_card/${invoiceId}`
			);
			return response.data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	providerValues['getInvoicePaymentRegisterOptions'] = () => {
		return [
			{ label: 'All', value: ['paid', 'part_paid'] },
			{ label: 'Paid', value: 'paid' },
			{ label: 'Part Paid', value: 'part_paid' },
		];
	};

	providerValues['setJobComplete'] = async (jobId: string, isComplete: boolean) => {
		let payload: any = {};

		if (isComplete) {
			payload = {
				status: 'completed',
				work_completed_date: DateTime.now().toISO(),
			};
		} else {
			payload = {
				uncompleted: true,
			};
		}

		const toastID = toast.loading('Please wait...');

		try {
			await axios.put<AxiosResponse>(`/api/jobs/jobs_list/job_card/${jobId}`, payload);
			showToast('success', `Job successfully ${!isComplete ? 'un-' : ''}completed`, toastID);
			return true;
		} catch (error: any) {
			console.error('API error', error);
			showToast('error', null, toastID);
			return false;
		}
	};

	providerValues['getHalfDayOptions'] = async (workerId: string) => {
		let response: AxiosResponse;
		let payload: any = {};
		if (workerId && workerId.length > 0) payload.worker_id = workerId;

		try {
			response = await axios.post<AxiosResponse>(`/api/utilities/workers/working_hours`, payload);

			let data = response.data;

			// Create date objects for each day of the week
			data.map((d: any) => {
				d.day_of_week_date = DateTime.fromFormat(d.day_of_week, 'ccc');
			});

			// Sort by day of week
			data = data.sort((a: any, b: any) => {
				return a.day_of_week_date.valueOf() > b.day_of_week_date.valueOf() ? 1 : -1;
			});

			return data;
		} catch (error: any) {
			console.error('API error', error);
			throw new Error(error);
		}
	};

	return <Provider value={providerValues}>{children}</Provider>;
};

export default ModuleProvider;

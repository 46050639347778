import { useRef } from 'react';

const useClientDetailsArray = (
	newClient: boolean,
	allowRefs: boolean = false,
	includeLookup: boolean = false,
	usageMode: string = ''
) => {
	const clientLookupRef = useRef();

	let clientDetails: Array<any> = [
		{
			title: 'Client Details',
			type: 'title',
			style: 'alpha',
		},
	];

	if (includeLookup === true) {
		clientDetails = clientDetails.concat([
			{
				id: 'client_id',
				title: 'Client Lookup',
				type: 'autocomplete',
				ref: clientLookupRef,
				onChangeCallback: null,
				placeholder: 'Please start typing the name of a client...',
				loadOptions: null,
				addNewLabel: 'Add New Client',
				handleAddNew: null,
				db: ['id'],
			},
		]);
	}

	clientDetails = clientDetails.concat([
		{
			id: 'company_name',
			title: 'Company Name',
			type: 'text',
			ref: useRef(),
			noForm: newClient,
			db: ['company_name'],
		},
		{
			id: 'first_name',
			title: 'Contact First Name',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: newClient,
			db: ['first_name'],
		},
		{
			id: 'last_name',
			title: 'Contact Last Name',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: newClient,
			db: ['last_name'],
		},
		{
			id: 'email',
			title: 'Contact Email',
			type: 'email',
			ref: useRef(),
			noForm: newClient,
			db: ['email'],
		},
		{
			id: 'mobile',
			title: 'Contact Mobile',
			type: 'phone',
			ref: useRef(),
			noForm: newClient,
			db: ['mobile'],
		},
		{
			title: 'Client Address',
			type: 'title',
			style: 'alpha',
		},
		{
			// Address look-up fields must have auto-assigned IDs
			title: 'Address Lookup',
			type: 'addressLookup',
			id: `address-lookup-client${usageMode}`,
		},
		{
			title: 'Address 1',
			type: 'text',
			ref: useRef(),
			group: `client${usageMode}`,
			isMandatory: true,
			noForm: newClient,
			db: ['address', 'address_1'],
			subType: 'address_1',
		},
		{
			title: 'Address 2',
			type: 'text',
			ref: useRef(),
			group: `client${usageMode}`,
			isMandatory: false,
			noForm: newClient,
			db: ['address', 'address_2'],
			subType: 'address_2',
		},
		{
			title: 'Town',
			type: 'text',
			ref: useRef(),
			group: `client${usageMode}`,
			isMandatory: true,
			noForm: newClient,
			db: ['address', 'town'],
			subType: 'town',
		},
		{
			title: 'Postcode',
			type: 'postcode',
			ref: useRef(),
			group: `client${usageMode}`,
			isMandatory: true,
			noForm: newClient,
			db: ['address', 'postcode'],
			subType: 'postcode',
		},
		{
			title: 'Latitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting latitude',
			group: `client${usageMode}`,
			isMandatory: false,
			disabled: true,
			noForm: newClient,
			db: ['address', 'latitude'],
			subType: 'latitude',
		},
		{
			title: 'Longitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting longitude',
			group: `client${usageMode}`,
			isMandatory: false,
			disabled: true,
			noForm: newClient,
			db: ['address', 'longitude'],
			subType: 'longitude',
		},
		{
			title: 'Boundary Size',
			type: 'boundary',
			isMandatory: true,
			ref: useRef(),
			group: `client${usageMode}`,
			db: ['address'],
			noForm: newClient,
			custom: true,
			subType: 'boundary',
		},
	]);

	/*if (newClient !== true && allowRefs === false) {
		// Null-out all the ref fields because we only use them in the creation form
		clientDetails.forEach((k: any) => {
			delete k.ref;
		});
	}*/

	return { clientDetails };
};

export default useClientDetailsArray;

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { WorkSuspendedReasonOptionGroup } from '../../../../interfaces/Jobs/WorkSuspendedReason';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';

interface SuspendWorkModalProps {
	isOpen: boolean;
	onClose: Function;
	permissionTo: Function;
	jobId: string;
	handleSuspendWork?: Function;
}

const SuspendWorkModal: React.FC<SuspendWorkModalProps> = ({ isOpen, onClose, permissionTo, jobId, handleSuspendWork }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [reasonId, setReasonId] = useState<string>('');
	const [jobLoading, setJobLoading] = useState<boolean>(true);
	const [reasonsLoading, setReasonsLoading] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [workSuspendedReasonOptions, setWorkSuspendedReasonOptions] = useState<WorkSuspendedReasonOptionGroup[]>([]);

	useEffect(() => {
		if (isOpen === true) {
			loadJobDetails();
		}
	}, [isOpen]);

	useEffect(() => {
		setIsLoading(jobLoading || reasonsLoading);
	}, [jobLoading, reasonsLoading])

	const loadJobDetails = () => {
		setJobLoading(true);
		axios.get(`/api/jobs/jobs_list/job_card/${jobId}`).then((res: any) => {
			loadWorkSuspendedReasons(res.data.job_type_id);
		}).catch(() => {
			showToast("error");
		}).finally(() => {
			setJobLoading(false);
		});
	}

	const loadWorkSuspendedReasons = (jobTypeId: string) => {
		setReasonsLoading(true);
		moduleCtx.getWorkSuspendedReasonsOptions(jobTypeId).then((res: WorkSuspendedReasonOptionGroup[]) => {
			setWorkSuspendedReasonOptions(res);
		}).finally(() => {
			setReasonsLoading(false);
		});
	}

	const onSave = async () => {
		if (permissionTo('update')) {
			if (handleSuspendWork) handleSuspendWork(reasonId, setIsLoading, onModalClose);
		} else {
			showToast("permission");
		}
	}

	const onModalClose = (): void => {
		setReasonId('');
		onClose();
	}

	const handleReasonChanged = (e: any) => {
		setReasonId(e.value);
	}

	return (
		<IonModal class="note-modal" isOpen={isOpen} onDidDismiss={onModalClose} className="prop-form" style={{ '--height': '256px' }}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Suspend Work</IonTitle>
					<IonButtons slot="end" className="ion-modal-buttons">
						<IonButton onClick={onModalClose}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<p>
					Please select a suspended reason:
				</p>
				<SelectStyled
					options={workSuspendedReasonOptions}
					onChange={handleReasonChanged}
				/>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size="12" className="text-right">
							<IonButton color="secondary" onClick={onModalClose} disabled={isLoading}>
								Close
							</IonButton>
							{permissionTo('update') && (
								<IonButton color="primary" onClick={() => onSave()} disabled={!reasonId || isLoading}>
									Save
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
}

export default SuspendWorkModal;

import debounce from 'lodash.debounce';
import axios from '../../lib/axios';

const CatalogueItemsOptions = debounce((inputValue: string, callback: any) => {
	axios({
		method: 'post',
		url: '/api/utilities/jobs/catalogue/search',
		data: {
			search: inputValue,
		},
	}).then((res) => {
		const options = res.data
			.map((r: any) => {
				return { label: `${r.code} - ${r.name}`, value: r.code, data: r };
			})
			.sort((a: any, b: any) => {
				return a.label > b.label ? 1 : -1;
			});

		callback(options);
	});
}, 600);

export default CatalogueItemsOptions;

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import { toast } from 'react-toastify';

import LocateOnMap from '../../../../components/Maps/LocateOnMap';
import { moduleContext } from '../../../../contexts/ModuleContext';
import Loading from '../../../../components/UI/Loading';
import { showToast } from '../../../../lib/toast';
import axios from '../../../../lib/axios';
import { MapControls } from '../../../../interfaces/Maps/MapsInterface';
import { publish, useSubscribe } from '../../../../helpers/events';

interface Props {
	permissionTo: Function;
}

const MapIndex: React.FC<Props> = (props: Props) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [options, setOptions] = useState<any>({});
	const [worker, setWorker] = useState<any>({});
	const [markerPosition, setMarkerPosition] = useState<any>(null);
	const { workerId }: any = useParams();
	const [controls, setControls] = useState<Array<MapControls>>([]);

	useEffect(() => {
		setControls([
			{
				position: 'BOTTOM_CENTER',
				component: (
					<IonButton disabled={isSaving} onClick={saveClick} style={{marginRight: 10}}>
						Save Location
					</IonButton>
				),
				data: {},
			},
		]);
	}, []);

	useEffect(() => {
		loadWorker(workerId);
	}, []);

	const loadWorker = (workerId: string) => {
		setIsLoading(true);

		moduleCtx
			.getWorker(workerId)
			.then((res: any) => {
				const initialCentre = {
					lat: Number(res.key_details.address.latitude),
					lng: Number(res.key_details.address.longitude),
				};
				setMarkerPosition(initialCentre);
				setOptions({
					initialCentre: initialCentre,
					markerTitle: `${res.user.first_name} ${res.user.last_name}`,
				});
				setWorker(res);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleSaveLocation = useCallback(() => {
		if (!props.permissionTo('update')) {
			showToast('permission');
			return;
		}

		let payload: any = {
			key_details: {
				address: {
					latitude: markerPosition.lat,
					longitude: markerPosition.lng,
				},
			},
		};

		const toastID = toast.loading('Please wait...');
		setIsSaving(true);

		axios
			.post(`/api/workers/workers_list/worker_card/${workerId}`, payload)
			.then(() => {
				showToast('saved', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			})
			.finally(() => {
				setIsSaving(false);
			});
	}, [props, workerId, markerPosition]);

	const saveClick = () => {
		publish('map:index:save', true);
	}

	useSubscribe('map:index:save', handleSaveLocation, [markerPosition, workerId]);

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<div>
				<IonRow>
					<IonCol size={'12'}>
						<h4 className='submenu-item-title'>Map</h4>
					</IonCol>
				</IonRow>
				<Alert severity='info' style={{ margin: '0 0 8px 0' }}>
					Left-click on the map below to refine set this worker's home location
				</Alert>
			</div>
			{isLoading && <Loading />}
			{!isLoading && worker && markerPosition && (
				<LocateOnMap
					options={options}
					markerPosition={markerPosition}
					controls={controls}
				/>
			)}
		</div>
	);
};

export default MapIndex;

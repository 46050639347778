import axios from '../../../../lib/axios';
import { EngagementTypes, WorkersState } from '../workers-types';

const useUserDefinedFieldsArray = (state: WorkersState) => {
	const getFields = async () => {
		let userDefinedFields: Array<any> = [
			{
				title: 'User Defined Fields',
				type: 'title',
				style: 'alpha',
			},
		];

		// Type of UDFs
		let type: string = '';
		switch (state.worker?.key_details.type_of_engagement) {
			case EngagementTypes.DIRECTLY:
				type = 'workers';
				break;
			case EngagementTypes.SELF:
				type = EngagementTypes.SELF;
				break;
		}

		return await axios
			.post('/api/utilities/general/user_defined_fields', { type })
			.then((res: any) => {
				if (res.data && res.data.length > 0) {
					// Sort
					res.data = res.data.sort((a: any, b: any) => {
						return a.description > b.description ? 1 : -1;
					});

					res.data.forEach((f: any) => {
						let field: any = {
							title: f.description,
							db: ['udfs', f._id],
						};

						switch (f.config.field_type) {
							case 'dropdown':
								field.type = 'dropdown';

								type optionsType = {
									[key: string]: string | undefined;
									val: string | undefined;
								};

								const options: optionsType = f.config.values;

								field.values = Object.entries(options).map(([key, val]) => {
									return { label: val, value: key };
								});
								break;
							case 'choice':
								field.type = 'dropdown';
								field.values = [
									{ label: 'Yes', value: 'Yes' },
									{ label: 'No', value: 'No' },
								];
								break;
							case 'telephone':
								field.type = 'phone';
								break;
							case 'upload':
								field.id = f._id;
								field.type = 'fileUpload';
								break;
							default:
								field.type = f.config.field_type;
								break;
						}

						userDefinedFields.push(field);
					});
				} else {
					userDefinedFields.push({
						title: 'There are currently no user defined fields.',
						type: 'title',
						style: 'beta',
					});
				}

				return userDefinedFields;
			});
	};

	return { getFields };
};

export default useUserDefinedFieldsArray;

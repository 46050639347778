import { useState, useEffect, useContext } from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { DateTime } from 'luxon';
import Form from '../../../../components/Forms/Form';
import { useParams } from 'react-router-dom';
import usePayrollDetailsArray from '../arrays/payrollDetailsArray';
import { moduleContext } from '../../../../contexts/ModuleContext';
import axios from '../../../../lib/axios';
import { db2Form } from '../../../../api/forms';
import Loading from '../../../../components/UI/Loading';
import RatesOfPay from '../../components/RatesOfPay';
import WorkerControls from '../WorkerControls';
import { WorkersContext } from '../WorkersProvider';
import { ActionType } from '../actions';

interface Props {
	nextFunction?: Function;
	prevFunction?: Function;
	permissionTo: Function;
}

const PayrollDetails: React.FC<Props> = (props: Props) => {
	const tabId = 'payroll_details';
	const { state, dispatch } = useContext(WorkersContext);
	const moduleCtx = useContext<any>(moduleContext);
	const [items, setItems] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState<Array<any>>([]);
	const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(true);
	const [payType, setPayType] = useState<any>(null);
	const [workerData, setWorkerData] = useState<any>(null);
	const [workerLocationsLoading, setWorkerLocationsLoading] = useState<boolean>(true);
	const [locationsOptions, setLocationsOptions] = useState<Array<any>>([]);
	const { workerId }: any = useParams();
	const endpoint = '/api/workers/workers_list/worker_card';
	const endpointId = workerId;
	const formDataArray = usePayrollDetailsArray();

	useEffect(() => {
		setIsLoading(true);
		loadPaymentMethods();
		loadWorkerLocations();
	}, []);

	useEffect(() => {
		if (!paymentMethodsLoading && !workerLocationsLoading) {
			loadForm();
			setIsLoading(false);
		}
	}, [paymentMethodsLoading, workerLocationsLoading]);

	const loadPaymentMethods = () => {
		setPaymentMethodsLoading(true);

		moduleCtx
			.getPaymentMethodsOptions({ workerId })
			.then((res: any) => {
				setPaymentMethods(res);
			})
			.finally(() => {
				setPaymentMethodsLoading(false);
			});
	};

	const loadWorkerLocations = () => {
		setWorkerLocationsLoading(true);

		moduleCtx.getWorkerLocationOptions().then((res: any) => {
			setLocationsOptions(res);
			setWorkerLocationsLoading(false);
		});
	};

	const loadForm = () => {
		let formData: any = formDataArray.payrollDetails;

		// Form setup
		formData.map((fd: any) => {
			if (fd.id === 'date_of_employment') fd.onChangeCallback = handleUpdateEmpLen;
			if (fd.id === 'payment_method') {
				fd.values = paymentMethods;
				fd.onChangeCallback = handleUpdatePaymentMethod;
			}
			if (fd.id === 'schedule_default_location') fd.values = locationsOptions;
			return fd;
		});

		setIsLoading(true);

		axios
			.get(`${endpoint}/${endpointId}`)
			.then((res) => res.data)
			.then((dbData) => {
				setWorkerData(dbData);

				// Filter the form fields based on engagement type
				formData = formData.filter(
					(item: any) =>
						!item.hasOwnProperty('engagementType') ||
						item.engagementType === dbData.key_details.type_of_engagement
				);

				formData = db2Form(formData, dbData);
				setItems(formData);

				// Trigger the employment length calculation
				const empLen = calculateEmploymentLength(dbData.payroll_details.date_of_employment);
				updateEmpLenField(empLen);

				// Trigger the payment method type setting
				handleUpdatePaymentMethod({ value: dbData.payroll_details.payment_method });
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const calculateEmploymentLength = (value: any): string => {
		let empLen = '';
		if (!value || value.indexOf('Invalid') > -1) {
			empLen = 'Auto-calculated when date of employment entered';
			return empLen;
		}

		// Parse the date
		if (value && value.indexOf('T') > -1) {
			// ISO date
			value = DateTime.fromISO(value);
		} else {
			// Normal date
			value = DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss');
		}

		let length = Math.floor(DateTime.now().diff(value, 'days').days);

		if (length < 31) {
			empLen = `${length} day${length !== 1 ? 's' : ''}`;
		} else if (length <= 365) {
			length = Math.floor(DateTime.now().diff(value, 'months').months);
			empLen = `${length} month${length !== 1 ? 's' : ''}`;
		} else {
			length = Math.floor(DateTime.now().diff(value, 'years').years);
			empLen = `${length} year${length !== 1 ? 's' : ''}`;
		}

		return empLen;
	};

	const handleUpdateEmpLen = (date: any) => {
		const empLen: string = calculateEmploymentLength(date);
		updateEmpLenField(empLen);
	};

	const updateEmpLenField = (empLen: string) => {
		setItems((prevState: any) =>
			prevState.map((item: any) => {
				if (item.id === 'period_of_employment') {
					item.value = empLen;
				}
				return item;
			})
		);
	};

	const handleUpdatePaymentMethod = (option: any) => {
		setPayType(option.value);
	};

	const handleFormSuccess = (res: any) => {
		// Get success values
		let orgChart: any = undefined;
		let orgChartRef: any = res.data.filter(
			(item: any) => item.id === 'appears_on_organisation_chart'
		)[0];

		if (orgChartRef && orgChartRef.hasOwnProperty('ref')) {
			orgChartRef = orgChartRef.ref;

			if (orgChartRef.current) {
				orgChart = orgChartRef.current.getValue()[0];
				if (orgChart && orgChart.hasOwnProperty('value')) {
					orgChart = orgChart.value;
				}
			}
		}

		let schedDefault: any = '0';
		let schedDefaultRef: any = res.data.filter(
			(item: any) => item.id === 'schedule_default_location'
		)[0];

		if (schedDefaultRef && schedDefaultRef.hasOwnProperty('ref')) {
			schedDefaultRef = schedDefaultRef.ref;

			if (schedDefaultRef.current) {
				schedDefault = schedDefaultRef.current.getValue()[0];
				if (schedDefault && schedDefault.hasOwnProperty('value')) {
					schedDefault = schedDefault.value;
				}
			}
		}

		// Build the payload
		let payload: any = {
			payroll_details: {
				...state.worker?.payroll_details,
				appears_on_organisation_chart: orgChart,
				schedule_default_location: schedDefault,
			},
		};

		// Update the state
		dispatch({ type: ActionType.UPDATE_WORKER, payload });
	};

	return (
		<div style={{ minHeight: '600px' }}>
			{isLoading && <Loading height={600} />}
			{!isLoading && items && items.length > 0 && (
				<>
					<IonRow className='mb-4'>
						<IonCol size={'12'}>
							<Form
								method='POST'
								array={items}
								permissionTo={props.permissionTo}
								forceEdit={props.permissionTo('update')}
								noButton={true}
								onChangeCallback={handleFormSuccess}
								endpoint={endpoint}
								endpointID={endpointId}
							/>
						</IonCol>
					</IonRow>

					{workerData && workerData.key_details && (
						<RatesOfPay
							workerId={workerId}
							payType={payType}
							typeOfEngagement={workerData.key_details.type_of_engagement}
							permissionTo={props.permissionTo}
						/>
					)}

					<WorkerControls tabId={tabId} workerId={workerId} setIsLoading={setIsLoading} />
				</>
			)}
		</div>
	);
};

export default PayrollDetails;

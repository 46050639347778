import Submenu from '../../../../components/Menu/Submenu';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import StylesSubmenuArray from './pages/arrays/StylesSubmenuArray';
import './UtilitiesStyles.scss';

const StylesIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />

				<Submenu array={StylesSubmenuArray(uid)} />
			</div>
		</>
	);
};

export default StylesIndex;

import { useState, useEffect } from 'react';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import { useParams } from 'react-router-dom';
import axios from '../../../../lib/axios';
import useClientDetailsArray from '../arrays/clientDetailsArray';
import Form from '../../../../components/Forms/Form';
import Loading from '../../../../components/UI/Loading';
import { db2Form } from '../../../../api/forms';

interface Props {
	newClient: boolean;
	createClient?: Function;
	permissionTo: Function;
	usageMode?: string | undefined;
	triggerSave?: number | undefined;
}

const ClientDetails: React.FC<Props> = (props: Props) => {
	const [items, setItems] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const { clientId }: any = useParams();
	const endpoint = '/api/jobs/clients_list/client_card';
	const endpointId = clientId;

	// Load the form array with refs to help with mandatory field values on-screen
	// (as opposed to using the hidden undo logic values)
	const formDataArray = useClientDetailsArray(props.newClient, false, false, props.usageMode);

	useEffect(() => {
		loadForm();
	}, []);

	useEffect(() => {
		if (props.triggerSave && props.triggerSave > 0) {
			if (props.createClient) props.createClient(null, items);
		}
	}, [props.triggerSave]);

	const loadForm = () => {
		let formData: any = formDataArray.clientDetails;

		// Form setup
		formData.map((fd: any) => {
			return fd;
		});

		if (props.newClient === true) {
			setItems(formData);
		} else {
			// Get the existing worker's data
			setIsLoading(true);
			axios
				.get(`${endpoint}/${endpointId}`)
				.then((res) => res.data)
				.then((dbData) => {
					formData = db2Form(formData, dbData);
					setItems(formData);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	return (
		<div className='client-details-form'>
			{isLoading && <Loading height={600} />}
			<IonRow>
				<IonCol size={'12'}>
					{!isLoading && items && items.length > 0 && (
						<Form
							array={items}
							permissionTo={props.permissionTo}
							forceEdit={props.permissionTo('update')}
							noButton={true}
							formSkipServerUpdate={props.newClient}
							endpoint={endpoint}
							endpointID={endpointId}
						/>
					)}
				</IonCol>
			</IonRow>
			{props.newClient === true && props.usageMode !== 'modal' && (
				<IonRow>
					<>
						{!isLoading && items && items.length > 0 && props.permissionTo('create') && (
							<IonCol size={'12'} className='text-right'>
								<IonButton
									className='mr-3'
									color='success'
									onClick={(e) => {
										if (props.createClient) {
											props.createClient(e, items);
										}
									}}
								>
									Create Client
								</IonButton>
							</IonCol>
						)}
					</>
				</IonRow>
			)}
		</div>
	);
};

export default ClientDetails;

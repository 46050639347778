import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { initialsFromString } from '../../helpers/strings';
import { Link } from 'react-router-dom';

interface Props {
	data: any;
	includeTitle?: boolean;
	titleLink?: string;
	extraInfo?: JSX.Element | string;
}

const Avatar = (props: Props) => {
	let initials: any = '??';
	if (props.data.name) initials = initialsFromString(props.data.name);

	let isSelfEmployed: boolean = false;
	if (props.data.type_of_engagement_enum === 'self') isSelfEmployed = true;

	let avatarStyle = {};
	if (props.data.worker_photo) {
		avatarStyle = {
			backgroundImage: `url(${process.env.REACT_APP_API_URL}/api/thumbs/50/50/${props.data.worker_photo})`,
		};
	}

	let title: string | JSX.Element | null = null;
	if (props.includeTitle) title = props.data.name;
	if (props.titleLink && props.titleLink.length > 0)
		title = (
			<Link className='prop-link' to={`/workers/workers_list/worker_card/${props.data.id}`}>
				{title}
			</Link>
		);

	// Check how to align items after the avatar circle
	let alignmentClass: string = 'align-items-center';
	if (props.includeTitle === true && props.extraInfo) alignmentClass = 'align-items-start';

	// Check if this worker is self-employed
	let selfEmployedClass: string = '';
	if (isSelfEmployed === true) selfEmployedClass = 'self-employed';

	return (
		<div
			className={`resource-avatar-container ${alignmentClass}`}
			title={isSelfEmployed ? 'Self-employed' : ''}
		>
			{isSelfEmployed && <FontAwesomeIcon icon={faCircleDot} className='icon-self-employed' />}
			<div className={`resource-avatar-outer ${selfEmployedClass}`}>
				<div className='resource-avatar' style={avatarStyle}>
					{!props.data.worker_photo && initials}
				</div>
			</div>

			<div className='resource-sub-container'>
				{props.includeTitle === true && <div className='resource-avatar-title'>{title}</div>}
				{props.extraInfo && <div className='resource-avatar-extra-info'>{props.extraInfo}</div>}
			</div>
		</div>
	);
};

export default Avatar;

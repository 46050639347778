import debounce from 'lodash.debounce';
import axios from '../../lib/axios';

const JobTypesOptions = debounce((inputValue: string, callback: any) => {
	axios({
		method: 'get',
		url: '/api/utilities/jobs/job_types',
		data: {
			name: inputValue,
		},
	}).then((res) => {
		const options = res.data
			.map((r: any) => {
				return { label: r.type, value: r._id };
			})
			.sort((a: any, b: any) => {
				return a.label > b.label ? 1 : -1;
			});

		callback(options);
	});
}, 600);

export default JobTypesOptions;

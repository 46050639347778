import { useContext } from 'react'
import { Route } from 'react-router-dom';
import { authContext } from '../../contexts/AuthContext';
import ModuleContextProvider from '../../contexts/ModuleContext';
import { publish } from '../../helpers/events';
import usePermissionTo from '../../utils/custom-hooks/PermissionTo';

function PrivateRoute({ component: Component, ...rest }: any) {
	const authCtx = useContext<any>(authContext);
	const permissionTo = usePermissionTo(rest.uid);

	if (!Component) return null;

	return (
		<Route {...rest} render={props => {
			// Not logged-in so redirect to login page with the return URL
			if (!authCtx.isAuthenticated && rest.noAuthCheck !== true) {
				publish('logout', true);
			}

			// Pass extra props to the component
			const finalProps = { ...props, uid: rest.uid, routeTitle: rest.routeTitle, permissionTo: permissionTo }

			// Authorised so return component, wrapped in a module context provider
			return (
				<ModuleContextProvider moduleName={rest.moduleName}>
					<Component {...finalProps} />
				</ModuleContextProvider>
			);
		}} />
	);
}

export { PrivateRoute };

import { useRef } from 'react';
import { EngagementTypes } from '../workers-types';

const usePayrollDetailsArray = () => {
	let payrollDetails: Array<any> = [
		{
			title: 'Personal Details',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'date_of_birth',
			title: 'Date of Birth',
			type: 'date',
			engagementType: EngagementTypes.DIRECTLY,
			db: ['payroll_details', 'date_of_birth'],
		},
		{
			id: 'date_of_employment',
			title: 'Date of Employment',
			type: 'date',
			onChangeCallback: null,
			engagementType: EngagementTypes.DIRECTLY,
			db: ['payroll_details', 'date_of_employment'],
		},
		{
			id: 'period_of_employment',
			title: 'Period of Employment',
			type: 'infoBox',
			engagementType: EngagementTypes.DIRECTLY,
		},
		{
			id: 'payroll_number',
			title: 'Payroll Number',
			type: 'text',
			engagementType: EngagementTypes.DIRECTLY,
			db: ['payroll_details', 'payroll_number'],
		},
		{
			id: 'national_insurance_number',
			title: 'National Insurance Number',
			type: 'text',
			engagementType: EngagementTypes.DIRECTLY,
			db: ['payroll_details', 'national_insurance_number'],
		},
		{
			id: 'tax_registration_scheme_number',
			title: 'Tax Registration Scheme Number',
			type: 'text',
			engagementType: EngagementTypes.SELF,
			db: ['payroll_details', 'tax_registration_scheme_number'],
		},
		{
			id: 'payment_method',
			title: 'Method for Calculating Payment',
			type: 'dropdown',
			values: null,
			onChangeCallback: null,
			db: ['payroll_details', 'payment_method'],
		},
		{
			id: 'holiday_allowance',
			title: 'Holiday Days Allowance',
			type: 'number',
			engagementType: EngagementTypes.DIRECTLY,
			db: ['payroll_details', 'holiday_allowance', 'holiday_allowance'],
		},
		{
			id: 'absence_allowance',
			title: 'Absence Days Allowance',
			type: 'number',
			engagementType: EngagementTypes.DIRECTLY,
			db: ['payroll_details', 'holiday_allowance', 'absence_allowance'],
		},
		{
			id: 'appears_on_organisation_chart',
			title: 'Appears on Organisation Chart',
			type: 'dropdown',
			ref: useRef(),
			values: [
				{ label: 'Yes', value: true },
				{ label: 'No', value: false },
			],
			engagementType: EngagementTypes.SELF,
			db: ['payroll_details', 'appears_on_organisation_chart'],
		},
		{
			id: 'schedule_default_location',
			title: 'Schedule Default Setting',
			type: 'dropdown',
			ref: useRef(),
			values: [],
			engagementType: EngagementTypes.SELF,
			db: ['payroll_details', 'schedule_default_location'],
		},
		{
			id: 'break',
			title: 'Paid breaktimes (self-employed)',
			type: 'yesNoValue',
			ref: useRef(),
			engagementType: EngagementTypes.SELF,
			db: ['payroll_details', 'break'],
		},
		{
			id: 'break',
			title: 'Paid breaktimes (directly employed)',
			type: 'dropdown',
			ref: useRef(),
			values: [
				{ label: 'Yes', value: '1,0:00' },
				{ label: 'No', value: '0,0:00' },
			],
			engagementType: EngagementTypes.DIRECTLY,
			db: ['payroll_details', 'break'],
		},
	];

	return { payrollDetails };
};

export default usePayrollDetailsArray;

import { useContext, useState, useEffect, useRef } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import {
	IonCard,
	IonRow,
	IonCol,
	IonButton,
	IonLabel,
	IonModal,
	IonHeader,
	IonFooter,
	IonToolbar,
	IonTitle,
	IonContent,
	IonInput,
	useIonAlert,
} from '@ionic/react';
import { TextField } from '../../../../components/Forms/FormFields';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { moduleContext } from '../../../../contexts/ModuleContext';
import axios from '../../../../lib/axios';
import FilterMenu from '../../../../components/Menu/FilterMenu';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faTableList, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Form from '../../../../components/Forms/Form';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';

const UserDefinedFieldsIndex: React.FC<RouteIndexComponent> = ({
	uid,
	routeTitle,
	permissionTo,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const gridRef: any = useRef<any>();
	const descriptionRef = useRef<any>(null);
	const fieldTypeRef = useRef<any>(null);
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [fieldTypes, setFieldTypes] = useState<Array<any>>([]);
	const [fieldTypesLoading, setFieldTypesLoading] = useState<boolean>(true);
	const [types, setTypes] = useState<Array<any>>([]);
	const [typesLoading, setTypesLoading] = useState<boolean>(true);
	const [typeID, setTypeID] = useState<string>('');
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();

	// Options modal state
	const [optionsModal, setOptionsModal] = useState<any>({ isOpen: false });
	const [optionsModalData, setOptionsModalData] = useState<any>({});

	useEffect(() => {
		loadTypes();
		loadFieldTypes();
	}, []);

	useEffect(() => {
		if (gridReady === true && typesLoading === false && fieldTypesLoading === false) {
			loadGridData(null, types[0]._id);
		}
	}, [gridReady, typesLoading, fieldTypesLoading]);

	const onGridReady = () => {
		setGridReady(true);
	};

	const columns = [
		{
			field: 'description',
			sortable: true,
			sort: 'asc',
			flex: 1,
			cellRenderer: (params: any) => {
				return (
					<Form
						array={[
							{
								id: `description-${params.data._id}`,
								title: 'Description',
								type: 'text',
								value: params.data.description,
								db: ['description'],
							},
						]}
						forceEdit={permissionTo('update')}
						noButton={true}
						tableMode={true}
						permissionTo={permissionTo}
						endpoint={`/api/utilities/general/user_defined_fields`}
						endpointID={params.data._id}
						onChangeCallback={() => {
							updateRow([]);
						}}
					/>
				);
			},
		},
		{
			headerName: 'Type',
			field: 'config',
			sortable: false,
			flex: 1,
			valueFormatter: (params: any) =>
				fieldTypes.filter((t: any) => t.value === params.data.config.field_type)[0].label,
			cellRenderer: (params: any) => {
				if (permissionTo('update')) {
					return (
						<SelectStyled
							id={`fieldType-${params.data._id}`}
							className='in-cell'
							options={fieldTypes}
							defaultValue={fieldTypes.filter(
								(t: any) => t.value === params.data.config.field_type
							)}
							onChange={(option: any) => handleGridSave(option, params.data._id)}
							menuPortalTarget={document.body}
						/>
					);
				} else {
					return fieldTypes.filter((t: any) => t.value === params.data.config.field_type)[0].label;
				}
			},
		},
		{
			headerName: '',
			field: 'editOptions',
			cellClass: 'icon-grid',
			width: 44,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				if (params.data.config.field_type === 'dropdown') {
					return (
						permissionTo('update') && (
							<FontAwesomeIcon
								icon={faTableList}
								className='icon-primary icon-grid'
								onClick={() => optionModalOpen(params.data)}
							/>
						)
					);
				} else {
					return null;
				}
			},
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 50,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data._id)}
						/>
					)
				);
			},
		},
	];

	const optionModalOpen = async (params: any) => {
		setOptionsModalData({
			rowID: params._id,
			itemName: params.description,
			itemType: params.config.field_type,
			values: params.config.values,
		});
		setOptionsModal({ isOpen: true });
	};

	const optionsModalOnClose = () => {
		setOptionsModal({ isOpen: false });
		setOptionsModalData((prevState: any) => {
			const { values, ...rest } = prevState;
			return rest;
		});
	};

	const optionsModalOnSave = (values: any, rowID: string, itemType: string) => {
		const option = fieldTypes.filter((t: any) => t.value === itemType)[0];

		handleGridSave(option, rowID, values);

		optionsModalOnClose();
	};

	const loadTypes = () => {
		setTypesLoading(true);

		moduleCtx.getUDFTypeOptions().then((res: any) => {
			const result = res.map((d: any) => {
				return {
					_id: d.value,
					label: d.label,
				};
			});
			setTypes(result);
			setTypesLoading(false);
		});
	};

	const loadFieldTypes = () => {
		setFieldTypesLoading(true);

		moduleCtx.getUDFFieldTypeOptions().then((res: any) => {
			setFieldTypes(res);
			setFieldTypesLoading(false);
		});
	};

	const loadGridData = (event: any, filterTypeID: any) => {
		setData([]);
		setGridLoading(true);
		setTypeID(filterTypeID);

		// Reset the search
		gridRef.current.clearSearch();

		const payload = filterTypeID.length > 0 ? { type: filterTypeID } : {};

		axios.post('/api/utilities/general/user_defined_fields', payload).then((res) => {
			setData(res.data);
			setGridLoading(false);
		});
	};

	const handleCreate = () => {
		const newDescription = descriptionRef.current;
		let newDescriptionValue = newDescription.value.trim();

		const newFieldType = fieldTypeRef.current;
		let newFieldTypeValue = newFieldType.getValue()[0]?.value.trim();

		if (newDescriptionValue.length === 0 || !newFieldTypeValue) {
			showToast('error', 'Please fill-in all fields');
			return;
		}

		const toastID = toast.loading('Please wait...');

		let configPayload: any = {
			field_type: newFieldTypeValue,
		};

		if (newFieldTypeValue === 'dropdown') {
			configPayload.values = [];
		}

		let payload: any = {
			description: newDescriptionValue,
			config: configPayload,
		};

		if (typeID.length > 0) payload = { ...payload, type: typeID };

		axios
			.put('/api/utilities/general/user_defined_fields', payload)
			.then((res) => {
				// Capture any grid changes and update state with those and the new item
				let rowData: Array<any> = data;

				// Update grid row items to prevent value flipping
				rowData = updateRow(rowData);

				// Add item
				setData([
					...rowData,
					{
						_id: res.data._id,
						description: newDescriptionValue,
						config: configPayload,
					},
				]);

				// Clear the form
				newFieldType.clearValue();

				showToast('saved', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const updateRow = (rowData: Array<any>) => {
		if (permissionTo('update')) {
			if (rowData.length === 0) rowData = data;

			rowData.map((a: any) => {
				const gridElement = document.getElementById(`description-${a._id}`) as HTMLElement;
				const gridElementValue = gridElement.getElementsByTagName('input')[0].value.toString();
				a.description = gridElementValue;
				return a;
			});
		}

		return rowData;
	};

	const handleGridSave = async (option: any, rowID: string, configValues: any = null) => {
		const endpointID = rowID;
		const config = option.value;

		const toastID = toast.loading('Please wait...');

		let configPayload: any = {
			field_type: config,
		};

		if (config === 'dropdown') {
			configPayload.values = configValues ? configValues : [];
		}

		let payload: any = {
			config: configPayload,
		};

		axios
			.put(`/api/utilities/general/user_defined_fields/${endpointID}`, payload)
			.then((res) => {
				showToast('saved', null, toastID);

				// Update the grid data
				updateGridSelect(option, rowID, configValues);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const updateGridSelect = (option: any, rowID: string, configValues: any = null) => {
		const dataUpdated: Array<any> = data.map((arr: any) => {
			if (arr._id === rowID) {
				// Set the relevant item as selected
				arr.config.field_type = option.value;

				// Set the config values if necessary
				if (option.value === 'dropdown') {
					arr.config.values = configValues ? configValues : [];
				}
			}
			return arr;
		});

		setData(dataUpdated);
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		axios
			.delete(`/api/utilities/general/user_defined_fields/${id}`)
			.then((res) => {
				// Capture any grid changes and update state with those and the deletion
				let rowData: Array<any> = data;

				// Update grid row items to prevent value flipping
				rowData = updateRow(rowData);

				// Remove item
				rowData = rowData.filter((d) => d._id !== id);

				setData(rowData);

				showToast('deleted', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonRow>
				<IonCol size={'2'} className='pt-0'>
					<FilterMenu
						loading={typesLoading || fieldTypesLoading}
						items={types}
						handleOnClick={loadGridData}
					/>
				</IonCol>
				<IonCol size={'10'} className='p-0'>
					<IonCard className='table-card filter-data-table full-height-card'>
						<DataGrid
							onGridReady={onGridReady}
							ref={gridRef}
							title={`${routeTitle}: ${
								types.filter((a) => a._id === typeID)[0]?.label ?? 'Loading...'
							}`}
							cols={columns}
							data={data}
							autoSize={true}
							extraFooter={
								permissionTo('create') && (
									<IonRow className='prop-form prop-form-edit'>
										<IonCol size={'5'} className='ps-0'>
											<IonRow>
												<IonCol size={'12'}>
													<TextField
														forwardRef={descriptionRef}
														id='description'
														title={'Description'}
														value={''}
														editMode={true}
														noForm={true}
													/>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={'5'}>
											<IonRow>
												<IonCol size={'3'}>
													<IonLabel position='floating'>UDF Type</IonLabel>
												</IonCol>
												<IonCol size={'9'}>
													<SelectStyled
														forwardRef={fieldTypeRef}
														id='field_type'
														title={'UDF Type'}
														options={fieldTypes}
													/>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={'2'} className='text-right pe-0'>
											<IonButton
												color='success'
												onClick={() => handleCreate()}
												disabled={gridLoading}
											>
												Create
											</IonButton>
										</IonCol>
									</IonRow>
								)
							}
						/>
					</IonCard>
				</IonCol>
			</IonRow>

			<OptionsModal
				isOpen={optionsModal.isOpen}
				onClose={optionsModalOnClose}
				onSave={optionsModalOnSave}
				onDidDismiss={optionsModalOnClose}
				initialData={optionsModalData}
				permissionTo={permissionTo}
			/>
		</>
	);
};

export default UserDefinedFieldsIndex;

interface OptionsModalProps {
	isOpen: boolean;
	onClose: Function;
	onSave: Function;
	onDidDismiss: Function;
	initialData: any;
	permissionTo: Function;
}

const OptionsModal: React.FC<OptionsModalProps> = ({
	isOpen,
	onClose,
	onSave,
	onDidDismiss,
	initialData,
	permissionTo,
}) => {
	const newOptionRef = useRef<any>(null);
	const [values, setValues] = useState<any>([]);

	useEffect(() => {
		setValues(initialData.values);
	}, [initialData.values]);

	const handleItemAdd = () => {
		let value: string = '';

		if (newOptionRef.current) {
			value = newOptionRef.current.value.trim();
		}

		if (value.length > 0) {
			setValues((prevState: any) => {
				if (prevState.length > 0) {
					return [...prevState, value];
				} else {
					return [value];
				}
			});

			// Clear the form
			newOptionRef.current.value = '';
		}
	};

	const handleItemDelete = (i: number) => {
		setValues((prevState: any) => prevState.filter((arr: any, index: number) => index !== i));
	};

	const handleItemEdit = (e: any, i: number) => {
		setValues((prevState: any) =>
			prevState.map((arr: any, index: number) => {
				if (index === i) {
					arr = e.target.value;
				}
				return arr;
			})
		);
	};

	return (
		<IonModal isOpen={isOpen} onDidDismiss={() => onDidDismiss()} className='prop-form'>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Dropdown Options</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<p>
					Editing options for: <i>{initialData.itemName}</i>
				</p>
				<ul className='p-0'>
					{values &&
						values.map((v: any, i: number) => {
							return (
								<li key={i}>
									<IonRow>
										<IonCol size='10' className='px-0 py-1'>
											<IonInput onKeyUp={(e) => handleItemEdit(e, i)} value={v} />
										</IonCol>
										<IonCol size='2' className='p-0 px-2'>
											{permissionTo('delete') && (
												<IonButton onClick={() => handleItemDelete(i)} color='danger'>
													<FontAwesomeIcon className='icon-delete' icon={faCircleXmark} />
												</IonButton>
											)}
										</IonCol>
									</IonRow>
								</li>
							);
						})}
				</ul>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='6' className='p-2'>
							{permissionTo('create') && (
								<IonInput
									onKeyDown={(e) => {
										if (e.key.toLowerCase() === 'enter' && permissionTo('create')) {
											handleItemAdd();
										}
									}}
									ref={newOptionRef}
									placeholder='Enter a new option...'
								/>
							)}
						</IonCol>
						<IonCol size='2' className='p-1'>
							{permissionTo('create') && (
								<IonButton onClick={() => handleItemAdd()} color='success'>
									<FontAwesomeIcon className='icon-add' icon={faCirclePlus} />
								</IonButton>
							)}
						</IonCol>
						<IonCol size='4' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Cancel
							</IonButton>
							<IonButton
								color='primary'
								onClick={() => onSave(values, initialData.rowID, initialData.itemType)}
							>
								Save
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

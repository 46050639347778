import { Alert } from '@mui/material';
import { useContext } from 'react';
import { EstimateContext } from '../EstimatesProvider';

const EstimateTitle: React.FC = () => {
	const { state, dispatch } = useContext(EstimateContext);

	let statusText = 'New';

	if (state.isLoading) statusText = 'Loading...';

	if (state.estimateData?.status) statusText = state.estimateData.status;

	let msg: Array<string> = [`Estimate Status: ${statusText}`];

	return <Alert severity='info'>{msg.join(', ')}</Alert>;
};

export default EstimateTitle;

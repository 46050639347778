import {
	IonBadge,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonLabel,
	IonPopover,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState } from 'react';
import { AutoComplete } from '../Forms/AutoComplete';
import { WorkersOptions } from '../../utils/autocomplete';

interface Props {
	filters?: any;
	onFilter: (filters: any) => void;
}

export const HolidayChartFilterPopover: React.FC<Props> = (props) => {
	const popover = useRef<HTMLIonPopoverElement>(null);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [localState, setLocalState] = useState<any>({});

	const handleFilterChange = (item: string, value: unknown) => {
		setLocalState((prevState: any) => {
			let newState: any = prevState;

			if (value !== null) {
				// Set or update value
				newState = { ...prevState, [item]: value };
			} else {
				// Remove value and its key
				const { [item]: value, ...rest } = newState;
				newState = rest;
			}
			return newState;
		});
	};

	// Popover functions
	const openPopover = (e: any) => {
		popover.current!.event = e;
		setPopoverOpen(true);
	};

	const onDidDismiss = () => {
		setPopoverOpen(false);
	};

	const applyFilters = () => {
		// Set the parent state to match the local state
		props.onFilter(localState);
		setPopoverOpen(false);
	};

	const clearFilters = () => {
		setLocalState({});
		props.onFilter({});
		setPopoverOpen(false);
	};

	return (
		<>
			<IonButton
				onClick={openPopover}
				className='btn-filter-popover me-3'
				title='Filter'
				color='primary'
			>
				{localState && Object.keys(localState).length > 0 && (
					<IonBadge color='danger'>{Object.keys(localState).length}</IonBadge>
				)}
				<FontAwesomeIcon icon={faFilter} style={{ pointerEvents: 'none' }} />
			</IonButton>
			<IonPopover
				ref={popover}
				className='filter-popover filter-popover-start-right'
				isOpen={popoverOpen}
				onDidDismiss={onDidDismiss}
				alignment='start'
				side='right'
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Calendar Filter</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonGrid className='p-0'>
						<IonRow>
							<IonCol size='3' className='ps-0 d-flex align-items-center'>
								<IonLabel>Leader:</IonLabel>
							</IonCol>
							<IonCol size='9' className='pe-0'>
								<AutoComplete
									onChangeCallback={(newValue: unknown) => handleFilterChange('leader', newValue)}
									placeholder='Leader name...'
									loadOptions={(inputValue: any, callback: any) => {
										WorkersOptions(inputValue, callback, true);
									}}
									value={localState.leader}
									isClearable={true}
									defaultOptions={true}
									useLabels={false}
									editMode={true}
								/>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size='6' className='ps-0'>
								<IonButton onClick={clearFilters} className='ps-0' size='small' color='primary'>
									Clear Filters
								</IonButton>
							</IonCol>
							<IonCol size='6' className='pe-0 d-flex justify-content-end'>
								<IonButton
									onClick={() => setPopoverOpen(false)}
									size='small'
									color='secondary'
									className='pe-1'
								>
									Close
								</IonButton>
								<IonButton onClick={applyFilters} size='small' color='success'>
									Apply
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPopover>
		</>
	);
};

export default HolidayChartFilterPopover;

import { useState, useEffect, useRef } from 'react';
import { IonButton, IonCard, useIonAlert } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import axios from '../../../../lib/axios';
import Form from '../../../../components/Forms/Form';
import { db2Form, updateForm } from '../../../../api/forms';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { Alert, AlertTitle } from '@mui/material';
import './Numbering.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

const Numbering: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const [items, setItems] = useState<Array<any>>([]);
	const [endpointID, setEndpointID] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingOverlay, setIsLoadingOverlay] = useState<boolean>(false);
	const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();
	const jobNumberingRef = useRef<any>();
	const invoiceNumberingRef = useRef<any>();
	const endpoint = '/api/utilities/company_details/settings/';

	useEffect(() => {
		loadForm();
	}, []);

	const loadForm = () => {
		setIsLoading(true);
		setItems([]);

		let formData: any = [
			{
				title: 'Numbering',
				type: 'title',
				style: 'alpha',
			},
			{
				title: "Set the rules for the system's numbering",
				type: 'title',
				style: 'beta',
			},
			{
				id: 'job_numbering_start',
				ref: jobNumberingRef,
				type: 'number',
				title: 'Estimate Numbering Start',
				disableEnterKey: true,
				disableNegatives: true,
				hideSpinner: true,
				disabled: true,
				noForm: true,
				db: ['settings', 'job_numbering_start'],
			},
			{
				id: 'invoice_numbering_start',
				ref: invoiceNumberingRef,
				type: 'number',
				title: 'Invoice Numbering Start',
				disableEnterKey: true,
				disableNegatives: true,
				hideSpinner: true,
				disabled: true,
				noForm: true,
				db: ['settings', 'invoice_numbering_start'],
			},
		];

		axios
			.get('/api/utilities/company_details/settings')
			.then((res) => res.data[0])
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				formData = controlFields(formData);
				setItems(formData);
				setEndpointID(dbData._id);
			})
			.catch((err) => {
				showToast('error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const controlFields = (formData: Array<any>) => {
		const fields = ['job_numbering_start', 'invoice_numbering_start'];

		formData.forEach((fd: any) => {
			if (fields.includes(fd.id)) {
				if (isNaN(Number(fd.value)) || Number(fd.value) === 0) {
					fd.disabled = false;
				} else {
					fd.disabled = true;
				}
			}
		});

		// Disable the save button if all relevant fields have been disabled
		const disabledFieldsCount = formData.reduce(
			(acc, cur) => (cur.disabled === true && fields.includes(cur.id) ? ++acc : acc),
			0
		);
		setSaveButtonDisabled(disabledFieldsCount === fields.length);

		return formData;
	};

	const handleSaveNumbering = async () => {
		if (permissionTo('update')) {
			presentAlert({
				header: 'Finalise Numbering',
				message: 'Are you sure you finalise the numbering?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleSaveNumberingGo();
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleSaveNumberingGo = async () => {
		// Disable fields that are no longer unset
		disableFields();

		// Grab values
		items.forEach((item: any) => {
			switch (item.id) {
				case 'job_numbering_start':
					item.value = jobNumberingRef.current.value;
					break;
				case 'invoice_numbering_start':
					item.value = invoiceNumberingRef.current.value;
					break;
			}
		});

		await updateForm(items, endpoint, endpointID, 'PUT', setIsLoadingOverlay);
	};

	const disableFields = () => {
		// Disable fields that are no longer unset
		const jobNumberingIsDisabled = !(
			isNaN(Number(jobNumberingRef.current.value)) || Number(jobNumberingRef.current.value) === 0
		);
		jobNumberingRef.current.disabled = jobNumberingIsDisabled;

		const invoiceNumberingIsDisabled = !(
			isNaN(Number(invoiceNumberingRef.current.value)) ||
			Number(invoiceNumberingRef.current.value) === 0
		);
		invoiceNumberingRef.current.disabled = invoiceNumberingIsDisabled;

		// Disable the save button if all fields are no longer unset
		if (jobNumberingIsDisabled && invoiceNumberingIsDisabled) {
			setSaveButtonDisabled(true);
		}
	};

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonCard className='table-card'>
				{isLoading && <Loading height={329} />}
				{isLoadingOverlay && <Loading overlay={true} />}
				{!isLoading && items && items.length > 0 && (
					<>
						<Alert severity='warning'>
							<AlertTitle>Warning</AlertTitle>
							After the initial numbering has been set above zero, it cannot be changed.
						</Alert>
						<div className='utility-form-padding'>
							<Form
								array={items}
								permissionTo={permissionTo}
								forceEdit={permissionTo('update')}
								noButton={true}
								endpoint={endpoint}
								endpointID={endpointID}
							/>
							<div className='text-right mt-2'>
								<IonButton
									color='danger'
									onClick={handleSaveNumbering}
									disabled={saveButtonDisabled}
								>
									<FontAwesomeIcon icon={faWarning} />
									&nbsp;Finalise Numbering
								</IonButton>
							</div>
						</div>
					</>
				)}
			</IonCard>
		</>
	);
};

export default Numbering;

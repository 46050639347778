import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { InfoBox } from '../../../../components/Forms/FormFields';
import { useContext, useEffect, useRef, useState } from 'react';
import { DateTime, Info } from 'luxon';
import { IonInput, IonLabel } from '@ionic/react';
import { authContext } from '../../../../contexts/AuthContext';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InvoiceContext } from '../InvoicesProvider';
import { ActionType } from '../actions';
import { showToast } from '../../../../lib/toast';
import { buildClientDetails, buildClientName, calculateInvoiceAmount } from '../invoice-functions';
import debounce from 'lodash.debounce';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { Datepicker, MbscDatepickerChangeEvent } from '@mobiscroll/react';
import { uniqueId } from 'lodash';
import InvoiceTitle from './InvoiceTitle';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	estimateId: string;
	jobId: string;
	invoiceId?: string;
}

const InvoiceHeader: React.FC<Props> = ({ permissionTo, isNew, estimateId, jobId, invoiceId }) => {
	const { state, dispatch } = useContext(InvoiceContext);
	const authCtx = useContext<any>(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const issueDateRef = useRef<any>(null);
	const dueDateRef = useRef<any>(null);
	const amountsAreValues: Array<{ label: string; value: string }> = [
		{ label: 'Tax Exclusive', value: 'exclusive' },
		{ label: 'Tax Inclusive', value: 'inclusive' },
	];

	// Use a local state for responsive user inputs (then debounce to main state)
	const [localState, setLocalState] = useState({
		title: '',
		summary: '',
		reference: '',
	});
	const [dayNames, setDayNames] = useState<Array<string>>([]);

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	useEffect(() => {
		if (isNew) {
			// Reset invoice with default form settings
			const issueDateVal = DateTime.utc().toISO();
			const dueDateVal = DateTime.utc().plus({ days: 30 }).toISO();

			issueDateRef.current.setVal(issueDateVal);
			dueDateRef.current.setVal(dueDateVal);

			new Promise<void>((resolve, reject) => {
				// Reset invoice
				dispatch({ type: ActionType.RESET, payload: { vatRegistered: false } });
				resolve();
			}).then(() => {
				// Then add the default settings to state
				dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

				moduleCtx
					.getSettings()
					.then((res: any) => {
						dispatch({
							type: ActionType.UPDATE_INVOICE_DATA,
							payload: {
								issueDate: issueDateVal,
								dueDate: dueDateVal,
								issuedByName: `${authCtx.user.first_name} ${authCtx.user.last_name}`,
								vatRegistered: res.vat_registered,
							},
						});
					})
					.catch(() => {
						showToast('error');
					})
					.finally(() => {
						dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
					});
			});
		}

		if (isNew) {
			// Creating a new invoice from an existing estimate - requires estimateId
			dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

			// Fetch invoice and settings data
			Promise.all([
				moduleCtx.getEstimate(estimateId, 'invoice'),
				moduleCtx.getInvoiceNumberFromEstimate(estimateId),
				moduleCtx.getSettings(),
			])
				.then((res: any) => {
					loadEstimate(res[0], res[1], res[2]);
				})
				.catch(() => {
					showToast('error');
				})
				.finally(() => {
					dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
				});
		} else {
			// Editing an existing invoice - requires invoiceId
			dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

			// Fetch invoice and settings data
			Promise.all([moduleCtx.getInvoice(invoiceId), moduleCtx.getSettings()])
				.then((res: any) => {
					loadInvoice(res[0], res[1]);
				})
				.catch(() => {
					showToast('error');
				})
				.finally(() => {
					dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
				});
		}
	}, []);

	const loadEstimate = (dataEstimate: any, dataInvoice: any, dataSettings: any) => {
		// Build the payload to update the invoice's state
		let payload: any = {
			estimateId: estimateId,
			jobId: jobId,
			status: 'New',
			name: dataEstimate.name,
			clientName: buildClientName(dataEstimate),
			clientDetails: buildClientDetails(dataEstimate),
			clientContactName: `${dataEstimate.client?.first_name} ${dataEstimate.client?.last_name}`,
			title: dataEstimate.title,
			summary: dataEstimate.summary,
			showTitleSummary:
				(dataEstimate.title && dataEstimate.title.trim().length > 0) ||
				(dataEstimate.summary && dataEstimate.summary.trim().length > 0),
			reference: dataEstimate.reference,
			invoiceNumber: dataInvoice.number,
			amountsAre: amountsAreValues.filter(
				(item: any) =>
					item.value === (dataEstimate.tax_exclusive === true ? 'exclusive' : 'inclusive')
			),
			vatRegistered: dataSettings.vat_registered,
		};

		// Update the main state - invoice data
		dispatch({ type: ActionType.UPDATE_INVOICE_DATA, payload: payload });

		// Update the main state - invoice items
		const items = dataEstimate.items.map((item: any) => {
			item._uid = uniqueId('row_');

			// Map the percentage complete fields
			item.percentageComplete = item.hasOwnProperty('percentage_complete')
				? item.percentage_complete
				: 100;
			item.percentageCompleted = item.hasOwnProperty('percentage_completed')
				? item.percentage_completed
				: 0;

			// Ensure prices are formatted
			item.price = currencyFormatter({ value: item.price }, true);

			// Ensure no tax rate if not vat registered
			if (dataSettings.vat_registered === true) {
				item.taxRateId = item.tax_rate_id;
			} else {
				item.taxRateId = '0';
			}

			// Clean the item keys
			const { tax_rate_id, percentage_complete, percentage_completed, ...itemClean } = item;

			return itemClean;
		});
		dispatch({ type: ActionType.SET_ITEMS, payload: items });
		calculateInvoiceAmount(dataEstimate.tax_exclusive, items, dispatch);

		// Update the local state
		setLocalState((prevState: any) => {
			return {
				...prevState,
				title: dataEstimate.title,
				summary: dataEstimate.summary,
				reference: dataEstimate.reference,
			};
		});
	};

	const loadInvoice = (dataInvoice: any, dataSettings: any) => {
		// Build the payload to update the invoice's state
		let payload: any = {
			invoiceId: invoiceId,
			estimateId: estimateId,
			jobId: jobId,
			status: dataInvoice.status,
			statusEnum: dataInvoice.status_enum,
			name: dataInvoice.name,
			clientName: buildClientName(dataInvoice),
			clientDetails: buildClientDetails(dataInvoice),
			clientContactName: `${dataInvoice.client?.first_name} ${dataInvoice.client?.last_name}`,
			issueDate: dataInvoice.issue_date,
			dueDate: dataInvoice.due_date,
			title: dataInvoice.title,
			summary: dataInvoice.summary,
			showTitleSummary:
				(dataInvoice.title && dataInvoice.title.trim().length > 0) ||
				(dataInvoice.summary && dataInvoice.summary.trim().length > 0),
			reference: dataInvoice.reference,
			invoiceNumber: dataInvoice.number,
			issuedByName: dataInvoice.issued_by,
			amountsAre: amountsAreValues.filter(
				(item: any) =>
					item.value === (dataInvoice.tax_exclusive === true ? 'exclusive' : 'inclusive')
			),
			vatRegistered: dataSettings.vat_registered,
		};

		// Set the date fields
		if (isNew) {
			issueDateRef.current.setVal(DateTime.fromISO(dataInvoice.issue_date));
			dueDateRef.current.setVal(DateTime.fromISO(dataInvoice.due_date));
		}

		// Update the main state - invoice data
		dispatch({ type: ActionType.UPDATE_INVOICE_DATA, payload: payload });

		// Update the main state - invoice items
		const items = dataInvoice.items.map((item: any) => {
			item._uid = uniqueId('row_');

			// Ensure prices are formatted
			item.price = currencyFormatter({ value: item.price }, true);

			// Ensure no tax rate if not vat registered
			if (dataSettings.vat_registered === true) {
				item.taxRateId = item.tax_rate_id;
			} else {
				item.taxRateId = '0';
			}

			// Map the percentage complete fields
			item.percentageComplete = item.percentage_complete ? item.percentage_complete : 100;
			item.percentageCompleted = item.percentage_completed ? item.percentage_completed : 0;

			// Clean the item keys
			const { tax_rate_id, percentage_complete, percentage_completed, ...itemClean } = item;

			return itemClean;
		});
		dispatch({ type: ActionType.SET_ITEMS, payload: items });
		calculateInvoiceAmount(dataInvoice.tax_exclusive, items, dispatch);

		// Update the local state
		setLocalState((prevState: any) => {
			return {
				...prevState,
				title: dataInvoice.title,
				summary: dataInvoice.summary,
				reference: dataInvoice.reference,
			};
		});
	};

	const handleDateChange = (id: string, date: any) => {
		const theDate: string = date.value;
		let payload: any = {};
		payload[id] = DateTime.fromFormat(theDate, 'yyyy-MM-dd').toISO();

		switch (id) {
			case 'issueDate':
				issueDateRef.current.setVal(theDate);
				break;
			case 'dueDate':
				dueDateRef.current.setVal(theDate);
				break;
		}

		dispatch({ type: ActionType.UPDATE_INVOICE_DATA, payload: payload });
	};

	const handleReferenceChange = (event: any) => {
		setLocalState((prevState: any) => {
			const newValue: any = {};
			newValue['reference'] = event.target.value;
			return { ...prevState, ...newValue };
		});
	};

	const handleTitleSummaryChange = (id: string, event: any) => {
		setLocalState((prevState: any) => {
			const newValue: any = {};
			newValue[id] = event.target.value;
			return { ...prevState, ...newValue };
		});
	};

	const toggleShowTitleSummary = () => {
		// Note how we clear the title and summary if hiding this part of the UI
		const showTitleSummary: boolean = !state.invoiceData?.showTitleSummary;
		let title = state.invoiceData?.title;
		let summary = state.invoiceData?.summary;

		if (!showTitleSummary) {
			title = '';
			summary = '';
		}

		// Set the local state
		setLocalState((prevState: any) => {
			return { ...prevState, title: title, summary: summary };
		});

		// Set the invoice state
		dispatch({
			type: ActionType.UPDATE_INVOICE_DATA,
			payload: {
				showTitleSummary: !state.invoiceData?.showTitleSummary,
				title: title,
				summary: summary,
			},
		});
	};

	const handleAmountsAreChange = (data: any) => {
		dispatch({
			type: ActionType.UPDATE_INVOICE_DATA,
			payload: {
				amountsAre: amountsAreValues.filter((item: any) => item.value === data.value),
			},
		});

		calculateInvoiceAmount(data.value === 'exclusive', state.items, dispatch);
	};

	// De-bounced function to update the main state
	const onItemChange = useRef(
		debounce((field: string, dispatch: any, localState: any) => {
			switch (field) {
				case 'title':
				case 'summary':
				case 'reference':
					const payload: any = {};
					payload[field] = localState[field];
					dispatch({ type: ActionType.UPDATE_INVOICE_DATA, payload: payload });
					break;
			}
		}, 600)
	).current;

	return (
		<>
			<InvoiceTitle />
			<Table className='tbl-invoice' size='small'>
				<TableHead>
					<TableRow>
						<TableCell width={'50%'}>Contact</TableCell>
						<TableCell width={'15%'}>Issue Date</TableCell>
						<TableCell width={'15%'}>Due Date</TableCell>
						<TableCell width={'20%'}>Invoice Number</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<InfoBox
								title='Contact'
								value={state.invoiceData?.name}
								useLabels={false}
								showBorder={true}
								showBackground={true}
							/>
						</TableCell>
						<TableCell>
							{isNew && (
								<Datepicker
									dayNamesMin={dayNames}
									ref={issueDateRef}
									onChange={(args: MbscDatepickerChangeEvent) =>
										handleDateChange('issueDate', args)
									}
									controls={['calendar']}
									inputComponent='input'
									inputProps={{
										placeholder: 'Please select...',
										className: 'prop-form-date',
									}}
									returnFormat={'iso8601'}
								/>
							)}
							{!isNew && state.invoiceData?.issueDate && (
								<InfoBox
									title='Issue Date'
									value={DateTime.fromISO(state.invoiceData.issueDate).toFormat('dd/MM/yyyy')}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							)}
						</TableCell>
						<TableCell>
							{isNew && (
								<Datepicker
									dayNamesMin={dayNames}
									ref={dueDateRef}
									onChange={(args: MbscDatepickerChangeEvent) => handleDateChange('dueDate', args)}
									controls={['calendar']}
									inputComponent='input'
									inputProps={{
										placeholder: 'Please select...',
										className: 'prop-form-date',
									}}
									returnFormat={'iso8601'}
								/>
							)}
							{!isNew && state.invoiceData?.dueDate && (
								<InfoBox
									title='Expiry Date'
									value={DateTime.fromISO(state.invoiceData.dueDate).toFormat('dd/MM/yyyy')}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							)}
						</TableCell>
						<TableCell>
							<InfoBox
								title='Invoice No'
								value={state.invoiceData?.invoiceNumber}
								useLabels={false}
								showBorder={true}
								showBackground={true}
							/>
						</TableCell>
					</TableRow>
					<TableRow
						className={`row-title-summary ${state.invoiceData?.showTitleSummary ? '' : 'hidden'}`}
					>
						<TableCell variant='head'>Invoice Title</TableCell>
						<TableCell variant='head' colSpan={3}>
							Invoice Summary
						</TableCell>
					</TableRow>
					<TableRow
						className={`row-title-summary ${state.invoiceData?.showTitleSummary ? '' : 'hidden'}`}
					>
						<TableCell>
							<IonInput
								className='ion-propeller'
								placeholder='Enter an invoice title...'
								value={localState.title}
								onInput={(e: any) => handleTitleSummaryChange('title', e)}
								onIonChange={() => onItemChange('title', dispatch, localState)}
							/>
						</TableCell>
						<TableCell colSpan={3}>
							<IonInput
								className='ion-propeller'
								placeholder='Enter an invoice smmary...'
								value={localState.summary}
								onInput={(e: any) => handleTitleSummaryChange('summary', e)}
								onIonChange={() => onItemChange('summary', dispatch, localState)}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell rowSpan={3} colSpan={3} className='cell-client-details'>
							<span onClick={toggleShowTitleSummary} className='link-button btn-show-title-summary'>
								{state.invoiceData?.showTitleSummary ? (
									<FontAwesomeIcon icon={faMinusCircle} />
								) : (
									<FontAwesomeIcon icon={faPlusCircle} />
								)}{' '}
								{state.invoiceData?.showTitleSummary ? 'Remove the' : 'Add a'} title and summary
							</span>
							<InfoBox
								id='client-details'
								title='Client Details'
								value={state.invoiceData?.clientDetails ?? 'Client Details'}
								useLabels={false}
								showBorder={true}
								multiLine={true}
								showBackground={true}
							/>
						</TableCell>
						<TableCell>
							<IonLabel>Reference</IonLabel>
							<IonInput
								className='ion-propeller'
								placeholder='Enter a reference...'
								value={localState.reference}
								onInput={handleReferenceChange}
								onIonChange={() => onItemChange('reference', dispatch, localState)}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<IonLabel>Amounts are</IonLabel>
							<SelectStyled
								value={state.invoiceData?.amountsAre}
								isSearchable={false}
								options={amountsAreValues}
								onChange={(data: any) => {
									handleAmountsAreChange(data);
								}}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<IonLabel>Issued by</IonLabel>
							<InfoBox
								title='Issued By'
								value={state.invoiceData?.issuedByName}
								useLabels={false}
								showBorder={true}
								showBackground={true}
							/>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</>
	);
};

export default InvoiceHeader;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties } from 'react';

interface Props {
	id?: string;
	btnType?: 'button' | 'submit' | 'reset' | 'logout' | undefined;
	children: any;
	btnTypeClassOverride?: string;
	loadingMessage?: string;
	loading?: boolean;
	onClick?: Function;
	className?: string;
	style?: CSSProperties | undefined;
}

const AuthButton: React.FC<Props> = (props: Props) => {
	let buttonTypeClass = '';
	let buttonClasses = 'btn';
	let buttonType: 'button' | 'submit' | 'reset' | undefined = undefined;
	let loadingMessageString: string =
		props.loadingMessage !== undefined ? props.loadingMessage : 'PLEASE WAIT';

	switch (props.btnType) {
		case 'logout':
			buttonTypeClass = 'btn-primary';
			buttonType = 'button';
			break;
		case 'button':
			buttonTypeClass =
				props.btnTypeClassOverride !== undefined &&
				props.btnTypeClassOverride.length > 0
					? props.btnTypeClassOverride
					: 'btn-secondary';
			buttonClasses += ' w-100';
			buttonType = 'button';
			break;
		default:
		case 'submit':
			buttonTypeClass = 'btn-primary';
			buttonClasses += ' w-100';
			buttonType = 'submit';
			break;
	}

	buttonClasses += ` ${buttonTypeClass} ${props.className}`;

	return (
		<button
			className={buttonClasses}
			type={buttonType}
			disabled={props.loading}
			onClick={(e) => {if (props.onClick) { props.onClick(e)}}}
			style={props.style}
		>
			{props.loading ? (
				<>
					{loadingMessageString.length > 0 && (
						<span style={{ paddingRight: '6px' }}>{loadingMessageString}</span>
					)}
					<FontAwesomeIcon icon={faCog} className="fa-spin" />
				</>
			) : (
				props.children
			)}
		</button>
	);
};

export default AuthButton;

import React, { useEffect, useState } from 'react';
import { IonContent } from '@ionic/react';
import { Select } from '@mobiscroll/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../../components/UI/Loading';
import { loadWorkLocations } from '../scheduling-functions';

type Props = {
	locationPopoverData: any;
};

const LocationPopover: React.FC<Props> = (props: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<any>>([]);
	const [componentProps, setComponentProps] = useState<any>(null);
	const [selectedLocation, setSelectedLocation] = useState<string>(
		props.locationPopoverData.locationId
	);

	useEffect(() => {
		loadWorkLocations(setIsLoading, setData);
	}, []);

	useEffect(() => {
		if (data.length > 0)
			setComponentProps({ defaultSelection: selectedLocation, value: selectedLocation });
	}, [data, selectedLocation]);

	const renderItem = (item: any) => {
		let option: any = null;

		option = (
			<div className='location-option'>
				<div className='location-option-icon'>
					<FontAwesomeIcon icon={item.data.icon} className='pe-2' />
				</div>
				<div>{item.data.text}</div>
			</div>
		);

		return option;
	};

	const handleLocationChange = (e: any) => {
		setSelectedLocation(e.value);

		// Update the workers list
		props.locationPopoverData.handleUpdateWorkerLocation(
			props.locationPopoverData.workerValue,
			e.value,
			e.valueText
		);

		// Highlight the affected row
		var buttonContainer = props.locationPopoverData.e.target.closest('.mbsc-scroller-wheel-item');
		if (buttonContainer) {
			buttonContainer.classList.remove('highlight-effect');
			setTimeout(() => {
				buttonContainer.classList.add('highlight-effect');
			}, 100);
		}

		// Dismiss the popover
		props.locationPopoverData.dismiss();
	};

	return (
		<IonContent className='scheduling-location-popover-content'>
			{isLoading && <Loading overlay={true} />}
			<h6>Working from:</h6>
			<Select
				display='inline'
				onChange={handleLocationChange}
				selectMultiple={false}
				theme='ios'
				themeVariant='auto'
				touchUi={false}
				renderItem={renderItem}
				rows={5}
				data={data}
				{...componentProps}
			/>
		</IonContent>
	);
};

export default LocationPopover;

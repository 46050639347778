import { DateTime } from 'luxon';
import { FooterData } from '../scheduling-types';

export const Footer = (data: FooterData, availableWorkersDayCounts: Array<any>) => {
	const theDate: any = DateTime.fromJSDate(data.date).toFormat('yyyy-MM-dd');
	let val: string = '-';

	if (availableWorkersDayCounts && availableWorkersDayCounts.hasOwnProperty(theDate)) {
		val = String(availableWorkersDayCounts[theDate]);
	}

	return <div className='footer-cell'>{val}</div>;
};

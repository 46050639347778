import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIonAlert, IonRow, IonCol, IonButton } from '@ionic/react';
import axios from '../../../../lib/axios';
import { DateTime } from 'luxon';
import { useContext, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { authContext } from '../../../../contexts/AuthContext';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import { showToast } from '../../../../lib/toast';
import ListFilterDebtors from '../components/ListFilterDebtors';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { getTimezone, underscoredToUppercasedWords } from '../../../../lib/functions';

interface Props {
	componentMode?: string;
	componentModeId?: string;
}

const DebtorsList: React.FC<Props> = ({ componentMode, componentModeId }) => {
	const authCtx: any = useContext(authContext);
	const defaultFilter = { label: 'All', payload: { status: '' } };
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [tableTitle, setTableTitle] = useState<string>('');
	const [curMonth, setCurMonth] = useState<number>(Number(DateTime.now().toFormat('MM')));
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const [curFilterPayload, setCurFilterPayload] = useState<any>(null);
	const [invoiceId, setInvoiceId] = useState<string | null>(null);
	const [jobId, setJobId] = useState<string | null>(null);
	const [presentAlert] = useIonAlert();
	const highlightRowRules = {
		'highlight-row': (params: any) => params.data.status_enum === 'overdue',
	};
	const permissionTo = usePermissionTo('jobs.invoices_list');
	let history = useHistory();

	// Mode logic
	useEffect(() => {
		let curFilterPayloadMod = { ...curFilterPayload };

		switch (componentMode) {
			case 'job_card':
				setTableTitle('Invoices');

				// Set modify the current payload
				if (!curFilterPayloadMod.hasOwnProperty('job_id')) {
					curFilterPayloadMod.job_id = componentModeId;
				}
				setCurFilterPayload((prevState: any) => ({ ...prevState, ...curFilterPayloadMod }));

				loadGridData(0, 0, curFilterPayloadMod);
				break;
			case 'client_card':
				setTableTitle('Invoices');

				// Set modify the current payload
				if (!curFilterPayloadMod.hasOwnProperty('client_id')) {
					curFilterPayloadMod.client_id = componentModeId;
				}
				setCurFilterPayload((prevState: any) => ({ ...prevState, ...curFilterPayloadMod }));

				loadGridData(0, 0, curFilterPayloadMod);
				break;
		}
	}, []);

	const loadGridData = (month: number, year: number, payload: any = null) => {
		setGridLoading(true);
		setJobId(null);
		setInvoiceId(null);
		setData([]);

		if (payload === null) payload = curFilterPayload;

		axios
			.post('/api/jobs/invoices_list', {
				month: month,
				year: year,
				...getTimezone(),
				...payload,
			})
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleListFilterChange = (label: string, payload: any) => {
		setCurFilterPayload(payload);
		loadGridData(curMonth, curYear, payload);
		setTableTitle(`Debtors: Invoices - ${label}`);
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			params.node.setSelected(true);
			setInvoiceId(params.data.id);
			setJobId(params.data.job_id);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			handleCellClicked(params);
			handleEditInvoice();
		}
	};

	const handleEditInvoice = () => {
		if (permissionTo('update', 'jobs.invoices_list.debtors')) {
			const path = `/jobs/invoices_list/debtors/${invoiceId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleGoToJob = () => {
		if (permissionTo('update', 'jobs.jobs_list.job_card')) {
			const path = `/jobs/jobs_list/job_card/${jobId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleDelete = (id: string) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Invoice',
				message: 'Are you sure you want to delete this invoice?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleDeleteGo(id);
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		setJobId(null);
		setInvoiceId(null);

		axios
			.delete(`/api/jobs/invoices_list/invoices_card/${id}`)
			.then(() => {
				loadGridData(curMonth, curYear, curFilterPayload);
				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const columns = [
		{
			field: 'number',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: 'Invoice Number',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'job_name',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: 'Job Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'issue_date',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: 'Issue Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
			sort: 'desc',
		},
		{
			field: 'due_date',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: 'Due Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 120,
			suppressSizeToFit: true,
		},
		{
			field: 'client_name',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: 'Client Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'value',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: `Value Gross (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value) {
					return params.data.value.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'value_paid',
			headerName: `Value Paid (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value_paid) {
					return params.data.value_paid.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 135,
			suppressSizeToFit: true,
		},
		{
			field: 'value_remaining',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: `Remaining (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value_remaining) {
					return params.data.value_remaining.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 135,
			suppressSizeToFit: true,
		},
		{
			field: 'issued_by',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: 'Issued By',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'status',
			cellClassRules: {
				...highlightRowRules,
			},
			headerName: 'Status',
			sortable: true,
			resizable: true,
			cellRenderer: (params: any) => {
				const statusWord = params.getValue();
				if (statusWord) {
					return underscoredToUppercasedWords(statusWord);
				}
			},
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClassRules: {
				...highlightRowRules,
			},
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') &&
					params.getValue() !== false && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const calcTotalCols = ['value', 'value_paid', 'value_remaining'];
	const totalRow = function (rowdata: any) {
		let result: any = [{}];

		// Calculate total columns
		calcTotalCols.forEach(function (params) {
			result[0][params] = 0;
			rowdata.forEach(function (line: any) {
				result[0][params] += line[params];
			});
		});

		// Clean-up row output
		result[0]['issue_date'] = false;
		result[0]['due_date'] = false;
		result[0]['client_name'] = `Totals (${authCtx.tenant.currency_symbol}):`;
		result[0]['delete'] = false;

		return result;
	};

	// Mode logic
	let showFilterButtons = true;
	let defaultAllTime = false;
	const btnEdit = (
		<IonButton color='primary' disabled={invoiceId === null} onClick={() => handleEditInvoice()}>
			Edit Invoice
		</IonButton>
	);
	let gridControls = (
		<IonRow>
			<IonCol size='8' className='row-lower-controls-left ps-0'>
				<ListFilterDebtors defaultSelected={defaultFilter} onChange={handleListFilterChange} />
			</IonCol>
			<IonCol size='4' className='row-lower-controls-right pe-0'>
				<div>
					<IonButton color='secondary' disabled={jobId === null} onClick={() => handleGoToJob()}>
						Go To Job
					</IonButton>
					{btnEdit}
				</div>
			</IonCol>
		</IonRow>
	);

	switch (componentMode) {
		case 'job_card':
			showFilterButtons = false;
			defaultAllTime = false;
			gridControls = (
				<IonRow className='text-right'>
					<IonCol size='12' className='p-0'>
						{btnEdit}
					</IonCol>
				</IonRow>
			);
			break;
		case 'client_card':
			showFilterButtons = true;
			defaultAllTime = true;
			gridControls = <div className='btn-edit-clients-invoices'>{btnEdit}</div>;
			break;
	}

	return (
		<>
			<DataGrid
				title={tableTitle}
				cols={columns}
				data={data}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				rowCursorPointer={permissionTo('update')}
				filterButtons={showFilterButtons}
				filterOptions={{
					displayCurrentMonth: loadGridData,
					defaultAllTime: defaultAllTime,
				}}
				totalsRow={totalRow}
				extraFooter={gridControls}
			/>
		</>
	);
};

export default DebtorsList;

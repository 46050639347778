import { useRef } from 'react';

const useFormFieldsArray = () => {
	let formFields: Array<any> = [
		{
			title: 'Office Name',
			type: 'text',
			ref: useRef(),
			isMandatory: true,
			noForm: true,
			db: ['name'],
		},
		{
			// Address look-up fields must have auto-assigned IDs
			title: 'Address Lookup',
			type: 'addressLookup',
			id: 'address-lookup-officelocation',
		},
		{
			title: 'Address 1',
			type: 'text',
			ref: useRef(),
			group: 'officelocation',
			isMandatory: true,
			noForm: true,
			db: ['address', 'address_1'],
			subType: 'address_1',
		},
		{
			title: 'Address 2',
			type: 'text',
			ref: useRef(),
			group: 'officelocation',
			isMandatory: false,
			noForm: true,
			db: ['address', 'address_2'],
			subType: 'address_2',
		},
		{
			title: 'Town',
			type: 'text',
			ref: useRef(),
			group: 'officelocation',
			isMandatory: true,
			noForm: true,
			db: ['address', 'town'],
			subType: 'town',
		},
		{
			title: 'Postcode',
			type: 'postcode',
			ref: useRef(),
			group: 'officelocation',
			isMandatory: true,
			noForm: true,
			db: ['address', 'postcode'],
			subType: 'postcode',
		},
		{
			title: 'Latitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting latitude',
			group: 'officelocation',
			isMandatory: false,
			disabled: true,
			noForm: true,
			db: ['address', 'latitude'],
			subType: 'latitude',
		},
		{
			title: 'Longitude',
			type: 'text',
			ref: useRef(),
			placeholder: 'Awaiting longitude',
			group: 'officelocation',
			isMandatory: false,
			disabled: true,
			noForm: true,
			db: ['address', 'longitude'],
			subType: 'longitude',
		},
		{
			title: 'Boundary Size',
			type: 'boundary',
			ref: useRef(),
			isMandatory: true,
			group: 'officelocation',
			db: ['address'],
			noForm: true,
			custom: true,
			subType: 'boundary',
		},
	];

	return { formFields };
};

export default useFormFieldsArray;

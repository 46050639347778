import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { faWorkers } from '../../../components/Icons/custom-icons.module';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const CreateWorker: React.FC<Props> = (props) => {
	let history = useHistory();

	const handleCreateWorker = () => {
		history.push('/workers/workers_list/add_new');
	};

	return (
		<>
			<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
				<div className='widget-header'>Create Worker</div>
				<div className='widget-content'>
					<IonButton className='btn-widget-plaque'>
						<div className='widget-plaque' onClick={handleCreateWorker}>
							<FontAwesomeIcon icon={faWorkers as IconDefinition} />
						</div>
					</IonButton>
				</div>
				<div className='widget-footer justify-content-end'>
					<IonButton size='small' onClick={handleCreateWorker}>
						Create Worker
					</IonButton>
				</div>
			</div>
		</>
	);
};

export default CreateWorker;

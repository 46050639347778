import { useState, useEffect, useContext } from 'react';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import { moduleContext } from '../../../../contexts/ModuleContext';
import useKeyDetailsArray from '../arrays/keyDetailsArray';
import Form from '../../../../components/Forms/Form';
import Loading from '../../../../components/UI/Loading';
import { db2Form, updateForm } from '../../../../api/forms';
import { ClientsOptions } from '../../../../utils/autocomplete';
import AddClientModal from '../../Clients/modals/AddClientModal';
import AddJobTypeModal from '../../../Utilities/Jobs/modals/AddJobTypeModal';
import Client from '../../../../interfaces/Jobs/Client';
import JobControls from '../JobControls';
import JobsContext from '../../../../contexts/JobsContext';
import JobTitle from '../JobTitle';
import JobTypesOptions from '../../../../utils/autocomplete/JobTypesOptions';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';

interface Props {
	newJob: boolean;
	nextFunction?: Function;
	createJob?: Function;
	permissionTo: Function;
}

const KeyDetails: React.FC<Props> = (props: Props) => {
	const moduleCtx = useContext<any>(moduleContext);
	const { jobId, jobData, reloadJob } = useContext(JobsContext);
	const [items, setItems] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);
	const [jobTypesLoading, setJobTypesLoading] = useState<boolean>(true);
	const [jobTypes, setJobTypes] = useState<Array<any>>([]);
	const [jobManagersLoading, setJobManagersLoading] = useState<boolean>(true);
	const [jobManagers, setJobManagers] = useState<Array<any>>([]);
	const endpoint = '/api/jobs/jobs_list/job_card';
	const endpointId = jobId;

	// Modals
	const [addClientModal, setAddClientModal] = useState<{ isOpen: boolean }>({ isOpen: false });
	const [addJobTypeModal, setAddJobTypeModal] = useState<{ isOpen: boolean }>({ isOpen: false });

	// Load the form array with refs to help with mandatory field values on-screen
	// (as opposed to using the hidden undo logic values)
	const formDataArray = useKeyDetailsArray(props.newJob);

	// Permissions
	const permissionToJobTypes = usePermissionTo('utilities.job_types');
	const permissionToClients = usePermissionTo('jobs.clients_list.add_new');

	useEffect(() => {
		setIsLoading(true);
		loadJobTypes();
		loadJobManagers();
	}, []);

	useEffect(() => {
		if (jobTypesLoading === false && jobManagersLoading === false) {
			loadForm();
			setIsLoading(false);
		}
	}, [jobTypesLoading, jobManagersLoading]);

	const loadJobTypes = () => {
		setJobTypesLoading(true);

		moduleCtx
			.getJobTypeOptions()
			.then((res: any) => {
				setJobTypes(res);
			})
			.finally(() => {
				setJobTypesLoading(false);
			});
	};

	const loadJobManagers = () => {
		setJobManagersLoading(true);

		moduleCtx
			.getWorkersOptions()
			.then((res: any) => {
				setJobManagers(res);
			})
			.finally(() => {
				setJobManagersLoading(false);
			});
	};

	const setClientFields = (data: any) => {
		const noData: boolean = !data;

		let fields = formDataArray.keyDetails.filter((field) => field.group === 'client');
		fields.forEach((field) => {
			let val = data;
			field.db.forEach((key: string) => {
				if (val[key] !== undefined) {
					val = val[key];
				}
			});
			switch (field.type) {
				default:
					if (field.ref.current) field.ref.current.value = val;
					break;
			}

			field.value = val;
		});

		const clientFields: Array<any> = [
			'client-id',
			'client-company_name',
			'client-first_name',
			'client-last_name',
			'client-email',
			'client-mobile'
		];

		if (!data) {
			data = {};
			clientFields.forEach((id: string) => {
				const dataId = id.split('client-')[1];
				data[dataId] = null;
			});
		}

		// Set the ref values (for screen) and form values (for updateForm)
		clientFields.forEach((id: string) => {
			const cf: any = formDataArray.keyDetails.filter(
				(field: any) => {
					return field.hasOwnProperty('db') && field.db.join('-') === id;
				}
			)[0];
			const dataId: string = id.substring(id.lastIndexOf('-') + 1);
			let val: any = null;

			if (id.indexOf('-address-') >= 0) {
				val = data['address'][dataId];
			} else {
				val = data[dataId];
			}

			switch (cf.type) {
				default:
					if (cf.ref.current) cf.ref.current.value = val;
					break;
			}

			cf.value = val;
		});

		if (noData === false && props.newJob === false) {
			updateForm(formDataArray.keyDetails, endpoint, endpointId, 'PUT', setIsLoadingOverlay);
		}
	};

	const loadForm = () => {
		let formData: any = formDataArray.keyDetails;

		// Form setup - add/edit
		formData.map((fd: any) => {
			if (fd.id === 'job_type_id') {
				// Set the values immediately
				fd.values = jobTypes;
				fd.loadOptions = JobTypesOptions;
				if (permissionToJobTypes(['read', 'create'])) {
					fd.handleAddNew = () => {
						setAddJobTypeModal({ isOpen: true });
					};
				}
			}
			if (fd.id === 'manager_id') fd.values = jobManagers;
			if (fd.id === 'client_id') {
				fd.loadOptions = ClientsOptions;
				if (permissionToClients(['read', 'create'])) {
					fd.handleAddNew = () => {
						setAddClientModal({ isOpen: true });
					};
				}
				fd.onChangeCallback = (option: { label: string; value: string }) => {
					if (option) {
						moduleCtx.getClient(option.value).then((res: Client) => {
							setClientFields(res);
						});
					} else {
						setClientFields(null);
					}
				};
			}
			return fd;
		});

		if (props.newJob === true) {
			// Form setup - add
			formData.map((fd: any) => {
				// Here we use the ref-based function to avoid form re-generations
				//if (fd.id === 'copy_job_address') fd.handleCopyAddress = setAddressFields;
				return fd;
			});

			setItems(formData);
		} else {
			// Get the existing job's data
			formData = db2Form(formData, jobData);
			setItems(formData);
		}
	};

	const handleFormChangeCallback = () => {
		reloadJob();
	};

	const reloadJobTypesOptions = () => {
		items.some((item: any) => {
			if (item.id === 'job_type_id') {
				// Trigger options reload
				item.ref.current.clearValue();
			}

			// Break loop
			return item.id === 'job_type_id';
		});
	};

	return (
		<div style={{ minHeight: '1000px' }}>
			<JobTitle />
			{isLoading && <Loading height={600} />}
			{isLoadingOverlay && <Loading overlay={true} />}
			<IonRow>
				<IonCol size={'12'}>
					{!isLoading && items && items.length > 0 && (
						<Form
							array={items}
							permissionTo={props.permissionTo}
							forceEdit={props.permissionTo('update')}
							noButton={true}
							formSkipServerUpdate={props.newJob}
							endpoint={endpoint}
							endpointID={endpointId}
							onChangeCallback={handleFormChangeCallback}
						/>
					)}
				</IonCol>
			</IonRow>
			<IonRow>
				{props.newJob === true ? (
					<>
						{!isLoading && items && items.length > 0 && props.permissionTo('create') && (
							<IonCol size={'12'} className='text-right'>
								<IonButton
									className='mr-3'
									color='alt'
									onClick={(e) => {
										if (props.nextFunction) {
											props.nextFunction(e, items, 'createEstimate');
										}
									}}
								>
									Add Estimate
								</IonButton>
								<IonButton
									className='mr-3'
									color='success'
									onClick={(e) => {
										if (props.createJob) {
											props.createJob(e, items);
										}
									}}
								>
									Create Job
								</IonButton>
								{props.permissionTo('update', 'jobs.jobs_list.job_card') && (
									<IonButton
										color='primary'
										onClick={(e) => {
											if (props.nextFunction) {
												props.nextFunction(e, items, 'next');
											}
										}}
									>
										Next Step
									</IonButton>
								)}
							</IonCol>
						)}
					</>
				) : (
					<>
						{!isLoading && items && items.length > 0 && (
							<>
								<IonCol size={'12'} className='text-right'>
									<JobControls permissionTo={props.permissionTo} jobId={jobId} />
								</IonCol>
							</>
						)}
					</>
				)}
			</IonRow>

			<AddClientModal
				isOpen={addClientModal.isOpen}
				onClose={() => setAddClientModal({ isOpen: false })}
			/>

			<AddJobTypeModal
				isOpen={addJobTypeModal.isOpen}
				onClose={() => {
					setAddJobTypeModal({ isOpen: false });
				}}
				onSuccess={reloadJobTypesOptions}
			/>
		</div>
	);
};

export default KeyDetails;

import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import { useParams } from 'react-router-dom';
import WorkersProvider from './WorkersProvider';
import WorkerCardContainer from './WorkerCardContainer';

const WorkerCardIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { workerId }: any = useParams();

	return (
		<WorkersProvider workerId={workerId}>
			<WorkerCardContainer uid={uid} routeTitle={routeTitle} permissionTo={permissionTo} />
		</WorkersProvider>
	);
};

export default WorkerCardIndex;

import { IonSpinner } from '@ionic/react';

interface Props {
	width?: number | string;
	height?: number | string;
	overlay?: boolean;
	fixed?: boolean;
	background?: boolean;
}

const Loading = (props: Props) => {
	let style: any = {};

	style.width = props.width ? props.width + (typeof props.width === 'string' ? '' : 'px') : '';
	style.height = props.height ? props.height + (typeof props.width === 'string' ? '' : 'px') : '';

	if (props.overlay === true) {
		style.position = 'absolute';
		style.zIndex = 9000;
		style.width = '100%';
		style.height = '100%';
		style.top = '0';
		style.left = '0';
	}

	return (
		<div className='prop-loading' style={style}>
			{props.overlay === true && (
				<div
					className={`prop-loading-overlay${props.fixed ? ' fixed' : ''}${
						props.background === false ? ' no-background' : ''
					}`}
				></div>
			)}
			<IonSpinner color={'primary'}></IonSpinner>
		</div>
	);
};

export default Loading;

import {
	IonTextarea,
	IonButton,
	IonItem,
	IonToolbar,
	IonButtons,
	IonPopover,
	useIonAlert,
	IonSearchbar,
	IonProgressBar,
	IonRow,
	IonCol,
} from '@ionic/react';
import {
	faPaperPlane,
	faPaperclip,
	faPhotoFilm,
	faUserPlus,
	faXmark,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useRef, useReducer, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import { AxiosProgressEvent } from 'axios';
import { DateTime } from 'luxon';

import { listReducer } from '../../../helpers/listReducer';
import { publish, useSubscribe } from '../../../helpers/events';
import { csrf } from '../../../helpers/csrf';
import InfinteScroll from '../../InfiniteScroll/InfiniteScroll';
import MessageItem from '../MessageItem/MessageItem';
import { MessageInterface } from '../../../interfaces/Chat/MessageInterface';
import axios from '../../../lib/axios';
import axiosMP from '../../../lib/axiosMp';
import TitleBar from '../../TitleBar/TitleBar';
import NewConversation from '../NewConversation/NewConversation';
import Media from '../Media/Media';
import FileIcon from '../../Icons/FileIcon';
import SearchMenu from '../SearchMenu/SearchMenu';
import { humanFileSize } from '../../../helpers/file';
import { highlightList } from '../../../helpers/listFunctions';
import Loading from '../../UI/Loading';
import { webSocketContext } from '../../../contexts/WebSocketContext';

import './Messages.scss';

interface Props {
	isVisible: boolean;
	conversationId: string;
	conversation: any;
	onWillDismiss: Function;
	readOnly?: boolean;
	showTitle?: boolean;
	workerId?: string;
	jobId?: string;
}

const Messages: React.FC<Props> = (props: Props) => {
	const webSocketCtx: any = useContext(webSocketContext);
	const [messageData, dispatchMessageData] = useReducer(listReducer, {
		list: [],
		isShowList: true,
	});
	const [uploadProgress, setUploadProgress] = useReducer(listReducer, {
		list: [],
		isShowList: true,
	});

	const itemsPerPage = 100;
	const [presentAlert] = useIonAlert();
	const formElm = useRef<HTMLFormElement>(null);
	const fileElm = useRef<HTMLInputElement>(null);
	const messageElm = useRef<any>(null);
	const popoverRef: any = useRef<HTMLIonPopoverElement>(null);
	const textareaRef: any = useRef<HTMLIonTextareaElement>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [sending, setSending] = useState<boolean>(false);
	const [fetching, setFetching] = useState<boolean>(false);
	const [mediaLoading, setMediaLoading] = useState<boolean>(false);

	const [firstItemIndex, setFirstItemIndex] = useState<number>(0);
	const [lastItemIndex, setLastItemIndex] = useState<number>(0);
	const [totalMessages, setTotalMessages] = useState<number>(0);
	const [initalTopMostItemIndex, setInitalTopMostItemIndex] = useState<number>(10);
	const [message, setMessage] = useState<string>('');
	const [media, setMedia] = useState<Array<any>>([]);
	const [receivedMessage, setReceivedMessage] = useState<any>({});
	const [isOnline, setIsOnline] = useState<boolean>(false);
	const [isGroup, setIsGroup] = useState<boolean>(false);
	const [isJob, setIsJob] = useState<boolean>(false);
	const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
	const [replyItem, setReplyItem] = useState<MessageInterface | undefined>(undefined);
	const [search, setSearch] = useState<string>('');
	const [privateReplyItem, setPrivateReplyItem] = useState<MessageInterface | undefined>(undefined);
	const [textCursorPosition, setTextCursorPosition] = useState<number>(0);
	const [jumpMessage, setJumpMessage] = useState<string | null>(null);
	const [reactToMessage, setReactToMessage] = useState<any | null>(null);
	const [deleteToMessage, setDeleteToMessage] = useState<string | null>(null);
	const [deleteMessage, setDeleteMessage] = useState<string | null>(null);
	const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
	const [mediaVisible, setMediaVisible] = useState<boolean>(false);
	const [participants, setParticipants] = useState<Array<any>>([]);

	useSubscribe(
		'participantOnline',
		(data: any) => {
			if (data.user == participants[0]) {
				setIsOnline(data.isOnline);
			}
		},
		[participants]
	);
	useSubscribe(
		'message:reply',
		(data: any) => {
			setReplyItem(data);
		},
		[]
	);
	useSubscribe(
		'message:privateReply',
		(data: any) => {
			setPrivateReplyItem(data);
		},
		[]
	);
	useSubscribe(
		'message:jump',
		(data: any) => {
			setJumpMessage(data);
		},
		[]
	);
	useSubscribe(
		'message:react',
		(data: any) => {
			setReactToMessage(data);
		},
		[]
	);
	useSubscribe(
		'message:delete',
		(data: any) => {
			setDeleteToMessage(data);
		},
		[]
	);

	useSubscribe(
		'database:new:messages',
		(data: any) => {
			if (data.conversation_id === props.conversationId) {
				dispatchMessageData({
					type: 'add',
					data: data,
					position: 'top',
					postFunction: postFunction,
				});
				if (!data.isRead) {
					markAsRead(data._id, data.conversation_id);
				}
				setTotalMessages((prevValue) => prevValue + 1);
				setLastItemIndex((prevValue) => prevValue + 1);
				//messageElm.current.scrollToBottom();
			}
		},
		[messageElm]
	);

	useSubscribe(
		'database:delete:messages',
		(data: any) => {
			if (data.conversation_id === props.conversationId) {
				dispatchMessageData({
					type: 'remove',
					data: data,
					postFunction: postFunction,
				});
				setTotalMessages((prevValue) => prevValue - 1);
			}
		},
		[]
	);

	useEffect(() => {
		dispatchMessageData({
			type: 'replace',
			data: [],
		});
		csrf().then(() => {
			axios
				.get('/api/conversations/' + props.conversationId + '/messages/count')
				.then((data: any) => {
					setTotalMessages(data.data);
					setLastItemIndex(data.data - itemsPerPage);
					endReached();
				})
				.catch((error: any) => {
					console.error(error);
				});
		});
	}, [props.isVisible, props.conversationId]);

	useEffect(() => {
		setIsGroup(false);
		setIsJob(false);
		if (props.conversation.isGroup) {
			setIsGroup(true);
		} else if (props.conversation.isJob) {
			setIsJob(true);
		} else {
			if (participants !== undefined) {
				if (participants.length === 1) {
					webSocketCtx.sendMessage(
						JSON.stringify({
							type: 'User:online',
							data: { user_id: participants[0] },
						})
					);
				}
			}
		}
	}, [participants]);

	useEffect(() => {
		if (privateReplyItem !== undefined) {
			if (privateReplyItem !== null) {
				let lParticipants: Array<string> = [privateReplyItem?.from];
				csrf().then(() => {
					axios
						.put('/api/conversations', {
							name: '',
							participants: lParticipants,
						})
						.then((data: any) => {
							publish('switchConversation', data.data._id);
							setReplyItem(privateReplyItem);
						})
						.catch((error) => {
							console.error(error);
						});
				});
			}
			setPrivateReplyItem(undefined);
		}
	}, [privateReplyItem]);

	useEffect(() => {
		if (deleteMessage !== null) {
			dispatchMessageData({
				type: 'remove',
				data: deleteMessage,
				postFunction: postFunction,
			});
			setDeleteMessage(null);
			setTotalMessages((preValue) => preValue - 1);
		}
	}, [deleteMessage]);

	useEffect(() => {
		if (search === '' && totalMessages > 0) {
			if (lastItemIndex !== totalMessages - itemsPerPage) {
				dispatchMessageData({
					type: 'replace',
					data: [],
				});
				setTimeout(() => {
					//setLastItemIndex(totalMessages - itemsPerPage);
					setFirstItemIndex(0);
					endReached();
				}, 500);
			}
		}
	}, [search]);

	const handleSearchChange = (ev: any) => {
		let query: string = '';
		const target = ev.target as HTMLIonSearchbarElement;
		if (target) query = target.value!.toLowerCase();
		setSearch(query);
	};

	useEffect(() => {
		if (deleteToMessage !== null) {
			const message_id = deleteToMessage;
			csrf().then(() => {
				axios
					.delete('/api/messages/' + message_id)
					.then((data: any) => {
						let foundMessage = messageData.list.find((message: MessageInterface) => {
							return message?._id === message_id;
						});
						foundMessage.obj = data.data;
						dispatchMessageData({
							type: 'remove',
							data: foundMessage,
							postFunction: postFunction,
						});
						setDeleteToMessage(null);
						setTotalMessages((preValue) => preValue - 1);
					})
					.catch((error) => {
						console.error(error);
					});
			});
		}
	}, [deleteToMessage]);

	useEffect(() => {
		if (reactToMessage !== null) {
			const message_id = reactToMessage.item._id;
			const data = {
				message_id,
				reaction: reactToMessage.unified,
			};
			csrf().then(() => {
				axios
					.put('/api/messages/react', data)
					.then((data: any) => {
						setReactToMessage(null);
					})
					.catch((error) => {
						console.error(error);
					});
			});
		}
	}, [reactToMessage]);

	const getMedia = () => {
		setMediaLoading(true);
		csrf().then(() => {
			axios
				.get('/api/conversations/' + props.conversationId + '/messages/media')
				.then((data: any) => {
					const media = data.data.map((message: any) => {
						if (message.original !== undefined) {
							if (message.type !== undefined) {
								if (message.type.indexOf('video/') > -1) {
									message['embedUrl'] = message.original;
									message['renderItem'] = _renderVideo.bind(this);
								} else if (message.type.indexOf('image/') > -1) {
									message['originalClass'] = 'featured-slide';
									message['thumbnailClass'] = 'featured-thumb';
									message['renderItem'] = _renderImage.bind(this);
								} else if (message.original !== undefined) {
									message['renderThumbInner'] = _renderThumbIcon.bind(this);
									message['renderItem'] = _renderIcon.bind(this);
								}
							}

							return message;
						}
						return {};
					});
					setMedia(media);
					setMediaLoading(false);
				})
				.catch((error: any) => {
					console.error(error);
				});
		});
	};

	useEffect(() => {
		if (jumpMessage !== null) {
			let index = messageData.list.findIndex((message: MessageInterface) => {
				return message?._id === jumpMessage;
			});
			if (messageElm && messageElm.current) {
				messageElm.current.scrollToIndex(index);
			}

			setJumpMessage(null);
		}
	}, [jumpMessage]);

	useEffect(() => {
		if (Object.keys(receivedMessage).length > 0) {
			setReceivedMessage({});
			newMessage(receivedMessage);
		}
	}, [receivedMessage]);

	const postFunction = (messages: Array<MessageInterface>) => {
		//var startTime = performance.now();
		let previousDate = '';
		let loopMessages = [...messages];
		loopMessages.reverse().forEach((message: MessageInterface) => {
			let date = DateTime.fromISO(message.created_at);
			message.date = date.toFormat('DDDD');
			message.time = date.toFormat('HH:mm');
			if (message !== null) {
				if (previousDate !== message.date) {
					message.has_heading = true;
				} else {
					message.has_heading = false;
				}
				previousDate = message.date;
			}
		});

		//var endTime = performance.now();
		//console.log(
		//	`Call to postFunction took ${endTime - startTime} milliseconds`
		//);

		return loopMessages.reverse();
	};

	const handleScroll = (message: MessageInterface) => {
		if (message.isRead !== true) {
			markAsRead(message._id, props.conversationId);
		}
	};

	const markAsRead = useCallback(
		(message_id: string, conversation_id: string) => {
			if (props.readOnly === undefined || props.readOnly === false) {
				csrf().then(() => {
					axios
						.post('/api/messages/read', {
							conversation_id: conversation_id,
							message_id: message_id,
						})
						.then((data: any) => {
							let foundMessage = messageData.list.find((message: MessageInterface) => {
								return message?._id === message_id;
							});
							foundMessage = data.data;
							dispatchMessageData({
								type: 'update',
								data: foundMessage,
								postFunction: postFunction,
							});
						})
						.catch((error) => {
							console.error(error);
						});
				});
			}
		},
		[messageData.list]
	);

	const newMessage = (data: MessageInterface) => {
		if (data.conversation_id === props.conversationId) {
			dispatchMessageData({
				type: 'add',
				data: data,
				postFunction: postFunction,
			});
			if (!data.isRead) {
				markAsRead(data._id, data.conversation_id);
				setTotalMessages((preValue) => preValue + 1);
			}
			messageElm.current.scrollToBottom();
		}
	};

	const sendMessage = () => {
		if (message !== '' || uploadProgress.list.length > 0) {
			setSending(true);
			if (uploadProgress.list.length > 0) {
				let promises: Array<any> = [];
				uploadProgress.list.forEach((file: any, index: number) => {
					promises.push(
						new Promise((res, rej) => {
							csrf()
								.then(() => {
									let fd = new FormData();
									fd.append('message', index === 0 ? message : '');
									fd.append('conversation_id', props.conversationId);
									fd.append('_method', 'PUT');
									fd.append('attachment', file.file);
									if (replyItem !== undefined) {
										fd.append('reply_to', replyItem._id);
									}

									axiosMP
										.post('/api/messages', fd, {
											onUploadProgress: (progressEvent: AxiosProgressEvent) => {
												if (progressEvent !== undefined && progressEvent.total !== undefined) {
													let percentCompleted = Math.round(
														(progressEvent.loaded * 100) / progressEvent.total
													);
													let foundProgess = uploadProgress.list.find((item: any) => {
														return item._id === file._id;
													});
													foundProgess.percentageCompleted = percentCompleted;
													setUploadProgress({
														type: 'update',
														data: foundProgess,
													});
												}
											},
										})
										.then((data) => {
											setSending(false);
											setMessage('');
											if (fileElm && fileElm.current) {
												fileElm.current.value = '';
											}
											setReplyItem(undefined);
											res(true);
										})
										.catch((error) => {
											setSending(false);
											setMessage('');
											if (fileElm && fileElm.current) {
												fileElm.current.value = '';
											}
											setReplyItem(undefined);
											console.error(error);
											res(true);
										});
								})
								.catch((error) => {
									setSending(false);
									setMessage('');
									if (fileElm && fileElm.current) {
										fileElm.current.value = '';
									}
									setReplyItem(undefined);
									console.error(error);
									res(true);
								});
						})
					);
				});
				Promise.all(promises).then(() => {
					setUploadProgress({
						type: 'replace',
						data: [],
					});
				});
			} else {
				doSend().then(() => {
					setSending(false);
					setMessage('');
					if (fileElm && fileElm.current) {
						fileElm.current.value = '';
					}
					setReplyItem(undefined);
				});
			}
		} else {
			presentAlert({
				header: 'Missing information',
				message: 'A message or file is required',
				buttons: ['OK'],
			});
		}
	};

	const doSend = () => {
		return new Promise((res, rej) => {
			csrf().then(() => {
				let fd = new FormData();
				fd.append('message', message);
				fd.append('conversation_id', props.conversationId);
				fd.append('_method', 'PUT');
				if (replyItem !== undefined) {
					fd.append('reply_to', replyItem._id);
				}
				axiosMP
					.post('/api/messages', fd)
					.then((data) => {
						res(data);
					})
					.catch((error) => {
						setMessage('');
						if (fileElm && fileElm.current) {
							fileElm.current.value = '';
						}
						setReplyItem(undefined);
						console.error(error);
					});
			});
		});
	};

	const handleImagePreview = (e: any) => {
		const objs: Array<any> = [];
		let promises: Array<any> = [];
		Array.from(e.target.files).forEach((file: any, index: number) => {
			promises.push(
				new Promise((res, rej) => {
					objs.push({
						_id: file.name + '-' + file.size,
						percentageCompleted: 0,
						size: file.size,
						name: file.name,
						file,
					});
					res(true);
				})
			);
		});
		Promise.all(promises).then(() => {
			setUploadProgress({
				type: 'append',
				data: objs,
			});
		});
	};

	const selectEmoji = (emojiData: EmojiClickData, event: MouseEvent) => {
		let textBeforeCursorPosition = textareaRef.current.value.substring(0, textCursorPosition);
		let textAfterCursorPosition = textareaRef.current.value.substring(
			textCursorPosition,
			textareaRef.current.value.length
		);
		setMessage(textBeforeCursorPosition + emojiData.emoji + textAfterCursorPosition);
		setPopoverOpen(false);
	};

	const emojiOpen = (e: any) => {
		textareaRef.current.getInputElement().then((input: HTMLTextAreaElement) => {
			setTextCursorPosition(input.selectionStart);
		});
		popoverRef.current!.event = e;
		setPopoverOpen((previousVal: boolean) => !previousVal);
	};

	const onNewWillDismiss = (ev: any) => {
		if (ev.name !== undefined) {
			setParticipants(ev.participants);
		}
		setIsNewModalOpen(false);
	};

	const onMediaDismiss = (ev: any) => {
		setMediaVisible(false);
	};

	const _renderVideo = (item: any) => {
		return (
			<video controls className='gallery-photo'>
				<source src={item.embedUrl} type='video/mp4' />
			</video>
		);
	};

	const _renderThumbIcon = (item: any) => {
		return <FileIcon height={'46px'} filename={item.description} />;
	};

	const _renderIcon = (item: any) => {
		return (
			<a href={item.original} download={item.description} className='media-link'>
				<FileIcon height={'100%'} filename={item.description} />
			</a>
		);
	};

	const _renderImage = (item: any) => {
		return (
			<a href={item.original} download={item.description} className='media-link'>
				<img className='image-gallery-image' alt='' src={item.original} />
			</a>
		);
	};

	const searchGoTo = (id: string) => {
		setFetching(true);
		csrf().then(() => {
			axios
				.post('/api/conversations/' + props.conversationId + '/messages', {
					_id: id,
				})
				.then((data: any) => {
					setInitalTopMostItemIndex(data.data.indexes.item);
					// @todo The below line is causing flushSync warning on first load, but not subsiquent loads.
					setFirstItemIndex(data.data.indexes.start - 1);
					setLastItemIndex(data.data.indexes.end - itemsPerPage);
					dispatchMessageData({
						type: 'replace',
						data: highlightList(data.data.messages, { data: { text: search } }),
						postFunction: postFunction,
					});
					setTimeout(() => {
						let index = data.data.messages.findIndex((message: MessageInterface) => {
							return message._id === id;
						});
						if (messageElm && messageElm.current) {
							messageElm.current.scrollToIndex(index);
						}
						setFetching(false);
					}, 500);
				});
		});
	};

	const removeUpload = (item: any) => {
		setUploadProgress({
			type: 'remove',
			data: item,
		});
	};

	const post = (skip: number, take: number) => {
		return new Promise((res, rej) => {
			csrf().then(() => {
				if (props.workerId !== undefined) {
					axios
						.post('/api/conversations/' + props.conversationId, {
							worker_id: props.workerId,
						})
						.then((convoData: any) => {
							axios
								.post('/api/conversations/' + props.conversationId + '/messages', {
									skip,
									take,
									worker_id: props.workerId,
								})
								.then((data: any) => {
									convoData.data.messages = data.data.messages ?? data.data;
									res(convoData);
								});
						});
				} else if (props.jobId !== undefined) {
					axios
						.post('/api/conversations/' + props.conversationId, {
							job_id: props.jobId,
						})
						.then((convoData: any) => {
							axios
								.post('/api/conversations/' + props.conversationId + '/messages', {
									skip,
									take,
									job_id: props.jobId,
								})
								.then((data: any) => {
									convoData.data.messages = data.data.messages ?? data.data;
									res(convoData);
								});
						});
				} else {
					axios.get('/api/conversations/' + props.conversationId).then((convoData: any) => {
						axios
							.post('/api/conversations/' + props.conversationId + '/messages', {
								skip,
								take,
							})
							.then((data: any) => {
								convoData.data.messages = data.data.messages ?? data.data;
								res(convoData);
							});
					});
				}
			});
		});
	};

	const startReached = (index?: number) => {
		if (!fetching) {
			setFetching(true);

			let skip: number = firstItemIndex;
			//let skip: number = firstItemIndex + itemsPerPage;
			let take: number = itemsPerPage;
			/*if (index !== undefined) {
				skip = index - itemsPerPage;
				take = itemsPerPage;
				if (index <= itemsPerPage) {
					skip = 0;
					take = index;
				}
			}*/
			/*if (skip < 0 && firstItemIndex > 0) {
				skip = 0;
			}*/
			if (skip > totalMessages) {
				skip = totalMessages;
			}
			if (skip >= 0) {
				if (index !== undefined) {
					setLoading(true);
				}
				post(skip, take)
					.then((data: any) => {
						if (data.data.other_participants.length > 0) {
							setParticipants(data.data.other_participants);
						}
						if (data.data.messages.length > 0) {
							setInitalTopMostItemIndex(take);
							// @todo The below line is causing flushSync warning on first load, but not subsiquent loads.
							/*setFirstItemIndex((prevValue) => {
								return (index ?? prevValue) - data.data.messages.length;
							});*/
							setFirstItemIndex((prevValue) => {
								return prevValue + data.data.messages.length;
							});
							dispatchMessageData({
								//type: 'prepend',
								type: 'append',
								data: data.data.messages,
								postFunction: postFunction,
							});
						}
						setTimeout(() => {
							setFetching(false);
						}, 500);
						if (index !== undefined) {
							setTimeout(() => {
								setLoading(false);
							}, 100);
						}
					})
					.catch((error: any) => {
						console.error(error);
					});
			} else {
				setFetching(false);
			}
		}
	};

	const endReached = () => {
		if (!fetching) {
			setFetching(true);
			//let skip: number = lastItemIndex + itemsPerPage;
			let skip: number = firstItemIndex;
			let take: number = itemsPerPage;
			/*if (skip < 0 && firstItemIndex > 0) {
				skip = 0;
			}*/
			if (skip > totalMessages) {
				skip = totalMessages;
			}
			if (skip >= 0) {
				post(skip, take)
					.then((data: any) => {
						if (data.data.other_participants.length > 0) {
							setParticipants(data.data.other_participants);
						}
						if (data.data.messages.length > 0) {
							setInitalTopMostItemIndex(lastItemIndex);
							// @todo The below line is causing flushSync warning on first load, but not subsiquent loads.
							/*setLastItemIndex(
								(prevValue) => prevValue + data.data.messages.length
							);*/
							setFirstItemIndex((prevValue) => prevValue + data.data.messages.length);
							dispatchMessageData({
								type: 'append',
								data: data.data.messages,
								postFunction: postFunction,
							});
							setTimeout(() => {
								setFetching(false);
							}, 500);
						}
					})
					.catch((error: any) => {
						console.error(error);
					});
			} else {
				setFetching(false);
			}
		}
	};

	return (
		<>
			{mediaVisible && (
				<Media
					isLoading={mediaLoading}
					isVisible={mediaVisible}
					media={media}
					onWillDismiss={onMediaDismiss}
				/>
			)}
			{!isNewModalOpen && (
				<div className='messages'>
					{(props.showTitle === true || props.showTitle === undefined) && (
						<TitleBar
							title={props.conversation.name}
							goBack={() => {
								props.onWillDismiss({});
							}}
						>
							{participants.length === 1 ? (
								isOnline ? (
									<span className='badge badge-success'>&nbsp;</span>
								) : (
									<span className='badge badge-danger'>&nbsp;</span>
								)
							) : (
								<></>
							)}
						</TitleBar>
					)}
					<IonToolbar color={'primary'}>
						<IonButtons slot='start'>
							{isGroup && (
								<IonButton
									className='icon-only'
									onClick={() => {
										setIsNewModalOpen(true);
									}}
								>
									<FontAwesomeIcon icon={faUserPlus} />
								</IonButton>
							)}
							<IonButton
								className='icon-only'
								onClick={() => {
									getMedia();
									setMediaVisible(true);
								}}
							>
								<FontAwesomeIcon icon={faPhotoFilm} />
							</IonButton>
						</IonButtons>
						<div slot='end'>
							<IonSearchbar
								onIonClear={(ev) => setSearch('')}
								onKeyUp={(e) => (e.key === 'Enter' ? handleSearchChange(e) : null)}
								onIonBlur={(ev) => handleSearchChange(ev)}
								value={search}
							/>
						</div>
					</IonToolbar>

					{(props.readOnly === undefined || props.readOnly === false) && (
						<IonToolbar>
							<IonItem className='chat'>
								<div className='message-row'>
									{replyItem !== undefined && (
										<div className='reply'>
											<MessageItem index={0} item={replyItem} preview={true} />

											<IonButton
												fill='solid'
												color={'danger'}
												className='reply-close round'
												onClick={() => {
													setReplyItem(undefined);
												}}
											>
												<FontAwesomeIcon icon={faXmark} />
											</IonButton>
										</div>
									)}
									{uploadProgress.list.length > 0 && (
										<div className='progress-section'>
											{uploadProgress.list.map((progress: any, index: number) => (
												<div className='progress-item' key={index}>
													<IonRow className='centered'>
														<IonCol size={'8'}>{progress.name}</IonCol>
														<IonCol size={'4'} className='text-right'>
															{humanFileSize(progress.size)}
															<IonButton
																color={'danger'}
																className='round ms-2'
																hidden={progress.percentageCompleted > 0}
																onClick={() => {
																	removeUpload(progress);
																}}
															>
																<FontAwesomeIcon icon={faTimes} />
															</IonButton>
														</IonCol>
													</IonRow>
													<IonRow>
														<IonProgressBar
															value={progress.percentageCompleted / 100}
														></IonProgressBar>
													</IonRow>
												</div>
											))}
										</div>
									)}
									<div className='message-box'>
										<IonTextarea
											id='message'
											ref={textareaRef}
											value={message}
											onIonChange={(e) => setMessage(e.detail.value!)}
											autoGrow={true}
											rows={1}
											placeholder='Type a message'
										></IonTextarea>
										<form ref={formElm}>
											<IonButton onClick={emojiOpen} expand='block' color='medium' fill='clear'>
												<FontAwesomeIcon icon={faFaceSmile} />
											</IonButton>

											<IonPopover
												className='emoji-popover'
												ref={popoverRef}
												isOpen={popoverOpen}
												side='bottom'
												alignment='center'
											>
												<EmojiPicker
													onEmojiClick={selectEmoji}
													autoFocusSearch={false}
													emojiStyle={EmojiStyle.NATIVE}
												/>
											</IonPopover>

											<IonButton
												onClick={() => {
													(fileElm.current as HTMLElement).click();
												}}
												expand='block'
												color='medium'
												fill='clear'
											>
												<input
													hidden
													ref={fileElm}
													multiple={true}
													type='file'
													id='attachment'
													name='attachment'
													onChange={handleImagePreview}
												/>
												<FontAwesomeIcon icon={faPaperclip} />
											</IonButton>
											<div className='divider'></div>
											<IonButton
												color='medium'
												fill='clear'
												onClick={sendMessage}
												disabled={sending}
												className='me-2'
											>
												<FontAwesomeIcon icon={faPaperPlane} />
											</IonButton>
										</form>
									</div>
								</div>
							</IonItem>
						</IonToolbar>
					)}

					<div className='messageBlock'>
						<div className='content'>
							{loading && <Loading></Loading>}
							<InfinteScroll
								hidden={loading}
								ref={messageElm}
								items={messageData.list}
								itemTemplate={MessageItem}
								handledScroll={handleScroll}
								//startReached={startReached}
								endReached={endReached}
								//overscan={itemsPerPage}
								firstItemIndex={firstItemIndex}
								initialTopMostItemIndex={initalTopMostItemIndex}
								extraProps={{
									group: isGroup,
									job: isJob,
									participants: participants,
									readOnly: props.readOnly ?? false,
								}}
							></InfinteScroll>
						</div>
						<SearchMenu
							isMenuOpen={search !== ''}
							conversationId={props.conversationId}
							participants={participants}
							searchTerm={search}
							clickFunction={searchGoTo}
						/>
					</div>
				</div>
			)}
			{isNewModalOpen && (
				<NewConversation
					conversationId={props.conversationId}
					conversationName={props.conversation.name}
					participants={participants}
					isVisible={isNewModalOpen}
					onWillDismiss={onNewWillDismiss}
				></NewConversation>
			)}
		</>
	);
};

export default Messages;

import { Route } from 'react-router-dom';
import { PrivateRoute } from './components/Menu/PrivateRoute';
import { useContext } from 'react';
import { authContext } from './contexts/AuthContext';
import {
	faDashboard,
	faMessages,
	faWorkers,
	faJobs,
	faScheduling,
	faUtilities,
	faMapMarker,
	faClock,
} from './components/Icons/custom-icons.module';
import { RoutesAuth, RoutesExternal, RoutesMetaData } from './types/routes-type';

// Auth components
import ForgottenPassword from './pages/Auth/ForgottenPassword';
import PasswordReset from './pages/Auth/PasswordReset';

// Route components
import GenericNotFound from './pages/Other/GenericNotFound';

// Dashboard
import Dashboard from './pages/Dashboard/Dashboard';

// Workers
import WorkersIndex from './pages/Workers/WorkersIndex';
import WorkersListIndex from './pages/Workers/Workers/WorkersListIndex';
import WorkerCard from './pages/Workers/Workers/WorkerCardIndex';
import { default as WorkersReportsIndex } from './pages/Workers/Reports/ReportsIndex';
import AddWorker from './pages/Workers/Workers/AddWorker';
import ImportWorkerIndex from './pages/Workers/Workers/ImportWorker/ImportWorkerIndex';
import HATIndex from './pages/Workers/HolidayAbsenceTraining/HATIndex';

// Jobs
import JobsIndex from './pages/Jobs/JobsIndex';
import JobsList from './pages/Jobs/Jobs/JobsList';
import JobCard from './pages/Jobs/Jobs/JobCard';
import AddJob from './pages/Jobs/Jobs/AddJob';
import ClientsList from './pages/Jobs/Clients/ClientsList';
import AddClient from './pages/Jobs/Clients/AddClient';
import ClientCard from './pages/Jobs/Clients/ClientCardIndex';
import ImportClientIndex from './pages/Jobs/Clients/ImportClient/ImportClientIndex';
import EstimatesList from './pages/Jobs/Estimates/EstimatesList';
import AddEstimate from './pages/Jobs/Estimates/AddEstimate';
import EditEstimate from './pages/Jobs/Estimates/EditEstimate';
import InvoicesList from './pages/Jobs/Invoices/InvoicesList';
import AddInvoice from './pages/Jobs/Invoices/AddInvoice';
import EditInvoice from './pages/Jobs/Invoices/EditInvoice';
import ConversationsBadge from './components/Chat/ConversationBadge/ConversationsBadge';
import ImportJobIndex from './pages/Jobs/Jobs/ImportJob/ImportJobIndex';
import { default as JobsReportsIndex } from './pages/Jobs/Reports/ReportsIndex';

// Live locations
import CurrentLocationsIndex from './pages/Locations/CurrentLocationsIndex';

// Scheduling
import SchedulingIndex from './pages/Scheduling/SchedulingIndex';

// Timesheets
import TimesheetsIndex from './pages/Timesheets/TimesheetsIndex';
import TimesheetsAllocations from './pages/Timesheets/TimesheetsAllocations';
import ReportsExports from './pages/Timesheets/ReportsExports';

// Utilities
import UtilitiesIndex from './pages/Utilities/UtilitiesIndex';
import SystemUsageIndex from './pages/Utilities/Company/SystemUsage/SystemUsageIndex';
import OfficeLocationsIndex from './pages/Utilities/Company/OfficeLocations/OfficeLocationsIndex';
import JobSkillsIndex from './pages/Utilities/Workers/JobSkills/JobSkillsIndex';
import ManagementStructureIndex from './pages/Utilities/Workers/ManagementStructure/ManagementStructureIndex';
import SecuritySettingsIndex from './pages/Utilities/Workers/SecuritySettings/SecuritySettingsIndex';
import WorkingHolidayEntitlementIndex from './pages/Utilities/Workers/WorkingHolidayEntitlement/WorkingHolidayEntitlementIndex';
import WorkingHolidayYearIndex from './pages/Utilities/Workers/WorkingHolidayYear/WorkingHolidayYearIndex';
import ManageSecuritySettings from './pages/Utilities/Workers/SecuritySettings/ManageSecuritySettings';
import WorkersOvertimeRatesIndex from './pages/Utilities/Workers/WorkersOvertimeRates/WorkersOvertimeRatesIndex';
import JobTypesIndex from './pages/Utilities/Jobs/JobTypes/JobTypesIndex';
import WorkSuspendedReasonsIndex from './pages/Utilities/Jobs/WorkSuspendedReasons/WorkSuspendedReasonsIndex';
import ContractualDatesIndex from './pages/Utilities/Jobs/ContractualDates/ContractualDatesIndex';
import Numbering from './pages/Utilities/Jobs/Numbering/NumberingIndex';
import UserDefinedFieldsIndex from './pages/Utilities/General/UserDefinedFields/UserDefinedFieldsIndex';
import NationalInsuranceRatesIndex from './pages/Utilities/General/NationalInsuranceRates/NationalInsuranceRatesIndex';
import AuditLogIndex from './pages/Utilities/General/AuditLog/AuditLogIndex';
import BankHolidaysIndex from './pages/Utilities/General/BankHolidays/BankHolidaysIndex';
import WorkingHoursAndBreaktimesIndex from './pages/Utilities/Workers/WorkingHoursAndBreaktimes/WorkingHoursAndBreaktimesIndex';
import AccountDetailsIndex from './pages/Utilities/Company/AccountDetails/AccountDetailsIndex';
import CompanyDetails from './pages/Utilities/Company/AccountDetails/pages/CompanyDetails';
import KeyUserDetails from './pages/Utilities/Company/AccountDetails/pages/KeyUserDetails';
import LicenseDatesAndCosts from './pages/Utilities/Company/AccountDetails/pages/LicenseDatesAndCosts';
import LicenseAgreement from './pages/Utilities/Company/AccountDetails/pages/LicenseAgreement';
import Messages from './pages/Utilities/Company/AccountDetails/pages/Messages';
import StorageUse from './pages/Utilities/Company/AccountDetails/pages/StorageUse';
import StylesIndex from './pages/Utilities/Company/Styles/StylesIndex';
import DefaultStyles from './pages/Utilities/Company/Styles/pages/DefaultStyles';
import TermsAndConditions from './pages/Utilities/Company/Styles/pages/TermsAndConditions';
import VATRatesIndex from './pages/Utilities/General/VATRates/VATRatesIndex';
import ItemCatalogueIndex from './pages/Utilities/Jobs/ItemCatalogue/ItemCatalogueIndex';
import WorkersImportXLSXIndex from './pages/Utilities/Workers/WorkersImportXLSX/WorkersImportXLSXIndex';

// Messages
import ConversationsPage from './pages/Chat/Conversations/Conversations';

// External
import MyPageIndex from './pages/External/Client/MyPage/MyPageIndex';
import KPIRatingIndex from './pages/External/Client/KPIRating/KPIRatingIndex';

const routesAuth: Array<RoutesAuth> = [
	{
		title: 'Forgotten Password',
		pathname: '/forgotten-password',
		component: ForgottenPassword,
		toolbar: false,
	},
	{
		title: 'Password Reset',
		pathname: '/password-reset/*',
		component: PasswordReset,
		toolbar: false,
	},
];

const routesExternal: Array<RoutesExternal> = [
	{
		title: 'Client: My Page',
		pathname: '/external/client/my-page/:externalId/:jobId',
		component: MyPageIndex,
		toolbar: false,
	},
	{
		title: 'Client: KPI Rating',
		pathname: '/external/client/kpi-rating/:externalId/:jobId',
		component: KPIRatingIndex,
		toolbar: false,
	},
];

const routesMetaData: Array<RoutesMetaData> = [
	// Dashboard
	{ uid: 'dashboard', icon: faDashboard, component: Dashboard },
	// Workers
	{ uid: 'workers', icon: faWorkers, component: WorkersIndex },
	{ uid: 'workers.workers_list', icon: null, component: WorkersListIndex },
	{
		uid: 'workers.workers_list.worker_card',
		pathIds: [':workerId'],
		icon: null,
		component: WorkerCard,
	},
	{ uid: 'workers.workers_list.add_new', icon: null, component: AddWorker },
	{ uid: 'workers.workers_list.import', icon: null, component: ImportWorkerIndex },
	{ uid: 'workers.reports', icon: null, component: WorkersReportsIndex },
	{ uid: 'workers.holidays_and_absences', icon: null, component: HATIndex },
	// Jobs
	{ uid: 'jobs', icon: faJobs, component: JobsIndex },
	{ uid: 'jobs.jobs_list', icon: null, component: JobsList },
	{ uid: 'jobs.jobs_list.job_card', pathIds: [':jobId'], icon: null, component: JobCard },
	{ uid: 'jobs.jobs_list.add_new', icon: null, component: AddJob },
	{ uid: 'jobs.jobs_list.import', icon: null, component: ImportJobIndex },
	{ uid: 'jobs.clients_list', icon: null, component: ClientsList },
	{
		uid: 'jobs.clients_list.client_card',
		pathIds: [':clientId'],
		icon: null,
		component: ClientCard,
	},
	{ uid: 'jobs.clients_list.add_new', icon: null, component: AddClient },
	{ uid: 'jobs.clients_list.import', icon: null, component: ImportClientIndex },
	{ uid: 'jobs.estimates_list', icon: null, component: EstimatesList },
	{
		uid: 'jobs.estimates_list.add_new',
		pathIds: [':jobId?'],
		icon: null,
		component: AddEstimate,
	},
	{
		uid: 'jobs.estimates_list.estimates_card',
		pathIds: [':estimateId?'],
		icon: null,
		component: EditEstimate,
	},
	{ uid: 'jobs.invoices_list', icon: null, component: InvoicesList },
	{
		uid: 'jobs.invoices_list.add_invoice',
		pathIds: [':estimateId?'],
		icon: null,
		component: AddInvoice,
	},
	{
		uid: 'jobs.invoices_list.debtors',
		pathIds: [':invoiceId?'],
		icon: null,
		component: EditInvoice,
	},
	{
		uid: 'jobs.invoices_list.credit_notes',
		pathIds: [':invoiceId?'],
		icon: null,
		component: EditInvoice,
	},
	{
		uid: 'jobs.invoices_list.payment_register',
		pathIds: [':invoiceId?'],
		icon: null,
		component: EditInvoice,
	},
	{ uid: 'jobs.reports', icon: null, component: JobsReportsIndex },
	// Scheduling
	{ uid: 'scheduling', icon: faScheduling, component: SchedulingIndex },
	{ uid: 'scheduling.scheduling_board', icon: null, component: null },
	// Live Locations
	{ uid: 'locations', icon: faMapMarker, component: CurrentLocationsIndex },
	// Timesheets
	{ uid: 'timesheets', icon: faClock, component: TimesheetsIndex },
	{ uid: 'timesheets.timesheets_and_allocations', icon: null, component: TimesheetsAllocations },
	{ uid: 'timesheets.reports_and_exports', icon: null, component: ReportsExports },
	// Utilities
	{ uid: 'utilities', icon: faUtilities, component: UtilitiesIndex },
	{ uid: 'utilities.account_details', icon: null, component: AccountDetailsIndex },
	{
		uid: 'utilities.account_details.company_details',
		icon: null,
		component: CompanyDetails,
		submenu: true,
	},
	{
		uid: 'utilities.account_details.key_user_details',
		icon: null,
		component: KeyUserDetails,
		submenu: true,
	},
	{
		uid: 'utilities.account_details.license_dates_and_costs',
		icon: null,
		component: LicenseDatesAndCosts,
		submenu: true,
	},
	{
		uid: 'utilities.account_details.license_agreement',
		icon: null,
		component: LicenseAgreement,
		submenu: true,
	},
	{ uid: 'utilities.account_details.messages', icon: null, component: Messages, submenu: true },
	{
		uid: 'utilities.account_details.storage_use',
		icon: null,
		component: StorageUse,
		submenu: true,
	},
	{ uid: 'utilities.styles', icon: null, component: StylesIndex },
	{
		uid: 'utilities.styles.default_styles',
		icon: null,
		component: DefaultStyles,
		submenu: true,
	},
	{
		uid: 'utilities.styles.terms_and_conditions',
		icon: null,
		component: TermsAndConditions,
		submenu: true,
	},
	{ uid: 'utilities.system_usage', icon: null, component: SystemUsageIndex },
	{ uid: 'utilities.office_locations', icon: null, component: OfficeLocationsIndex },
	{ uid: 'utilities.job_skills', icon: null, component: JobSkillsIndex },
	{ uid: 'utilities.management_structure', icon: null, component: ManagementStructureIndex },
	{ uid: 'utilities.security_settings', icon: null, component: SecuritySettingsIndex },
	{ uid: 'utilities.security_settings.manage', icon: null, component: ManageSecuritySettings },
	{
		uid: 'utilities.working_holiday_entitlement',
		icon: null,
		component: WorkingHolidayEntitlementIndex,
	},
	{ uid: 'utilities.working_holiday_year', icon: null, component: WorkingHolidayYearIndex },
	{
		uid: 'utilities.working_hours_and_breaktimes',
		icon: null,
		component: WorkingHoursAndBreaktimesIndex,
	},
	{ uid: 'utilities.import', icon: null, component: WorkersImportXLSXIndex },
	{ uid: 'utilities.workers_overtime_rates', icon: null, component: WorkersOvertimeRatesIndex },
	{ uid: 'utilities.job_types', icon: null, component: JobTypesIndex },
	{ uid: 'utilities.work_suspended_reasons', icon: null, component: WorkSuspendedReasonsIndex },
	{ uid: 'utilities.contractual_dates', icon: null, component: ContractualDatesIndex },
	{ uid: 'utilities.numbering', icon: null, component: Numbering },
	{ uid: 'utilities.item_catalogue', icon: null, component: ItemCatalogueIndex },
	{ uid: 'utilities.audit_log', icon: null, component: AuditLogIndex },
	{ uid: 'utilities.bank_holidays', icon: null, component: BankHolidaysIndex },
	{ uid: 'utilities.user_defined_fields', icon: null, component: UserDefinedFieldsIndex },
	{ uid: 'utilities.national_insurance_rates', icon: null, component: NationalInsuranceRatesIndex },
	{ uid: 'utilities.vat_rates', icon: null, component: VATRatesIndex },
	// Messages
	{ uid: 'messages', icon: faMessages, component: ConversationsPage, badge: ConversationsBadge },
];

// Recursive function to flatten the database navigation data and build the paths
var item_group: string = '';
var path: Array<string> = [];
var uid: Array<string> = [];
function flat(array: Array<any>, level: number): Array<any> {
	var result: any = [];

	array.forEach((a: any) => {
		// Check if at lowest level
		if (a.hasOwnProperty('module_name')) {
			path = [a.security_key];
			uid = [a.security_key];
			level = 0;
		}

		if (a.hasOwnProperty('item_group')) {
			// If the group changes then replace in the path
			if (a.item_group !== item_group) {
				if (path.length > 1) {
					path.pop();
				}

				path.push(a.item_group);
			}

			// Keep track of the group
			item_group = a.item_group;
		}

		// Build the path and UID
		if (level > 0) {
			path.push(a.security_key);
			uid.push(a.security_key);
		}

		// Increment the level
		level = level + 1;

		result.push({
			title: a.module_name ? a.module_name : a.item_name,
			pathname: '/' + path.join('/').toLowerCase(),
			uid: uid.join('.').toLowerCase(),
			toolbar: a.hasOwnProperty('module_name'),
			security_key: a.security_key,
			item_group: item_group.toLowerCase(),
			level: level,
			badge: a.badge,
			status: a.status, // Example: Utilities index page button status icons
		});

		// Deeper level check
		if (Array.isArray(a.items)) {
			result = result.concat(flat(a.items, level));
		}

		// Pop the path and UID each time we come down a level
		level = level - 1;
		path.pop();
		uid.pop();
	});

	return result;
}

// Merge the route meta data (icons, components, etc.) after being flattened
const mergeMetaData = (array: Array<any>): Array<any> => {
	array.forEach(function (a: any) {
		// Match and merge meta data
		let rmd = routesMetaData.filter((d) => d.uid === a.uid);

		a.icon = rmd[0] && rmd[0].hasOwnProperty('icon') ? rmd[0].icon : null;
		a.component = rmd[0] && rmd[0].hasOwnProperty('component') ? rmd[0].component : null;
		a.moduleName = a.uid.indexOf('.') > -1 ? a.uid.substring(0, a.uid.indexOf('.')) : a.uid;
		a.badge = rmd[0] && rmd[0].hasOwnProperty('badge') ? rmd[0].badge : null;
		a.submenu = rmd[0] && rmd[0].hasOwnProperty('submenu') ? rmd[0].submenu : false;

		// Add path IDs where necessary
		a.pathname =
			rmd[0] && rmd[0].hasOwnProperty('pathIds') && Array.isArray(rmd[0].pathIds)
				? a.pathname + '/' + rmd[0].pathIds.join('/')
				: a.pathname;
	});

	return array;
};

const resolvePath = (obj: any, path: any) => {
	return path.split('.').reduce((o: any, p: any) => o?.[p], obj);
};

// Custom hooks
const useGetRoutesPublic = () => {
	return [...routesAuth, ...routesExternal];
};

const useGetRoutesPrivate = (excludeSubmenuRoutes: boolean = false) => {
	const authCtx: any = useContext(authContext);
	const navData = authCtx.navData;
	let routesPrivate = [];

	if (navData && navData.length > 0) {
		routesPrivate = flat(navData, 0);
		routesPrivate = mergeMetaData(routesPrivate);
	}

	// Filter-out routes with no read permission for this user
	routesPrivate = routesPrivate.filter((r: any) => {
		let sec = resolvePath(authCtx.user.security, r.uid);
		return sec && sec.read;
	});

	// Filter-out submenu routes because these components are not loaded-in through a URL route, but
	// instead loaded/switched-in through Submenu.tsx. This stops the browser from being able to go
	// to one of these components directly as a route, yet allows the Submenu.tsx to render it.
	if (excludeSubmenuRoutes === true) {
		routesPrivate = routesPrivate.filter((rp: any) => rp.submenu !== true);
	}

	return routesPrivate;
};

const useGetRoutes = (uidFilter?: string, discardExact?: boolean): Array<any> => {
	let routesAll = [...useGetRoutesPublic(), ...useGetRoutesPrivate()];

	// Filter the routes based on the start of the UID
	if (uidFilter && uidFilter.length > 0) {
		routesAll = routesAll.filter((r) => String(r.uid).substring(0, uidFilter.length) === uidFilter);

		if (discardExact === true) routesAll.shift();

		return routesAll;
	}

	return routesAll;
};

const useMergeByUID = (array: Array<any>, arrayUIDs: Array<any>): Array<any> => {
	array.forEach((a: any) => {
		let tmp = arrayUIDs.filter((u) => u.uid === a.uid);
		a.title = tmp && tmp.length === 1 ? tmp[0].title : 'Unknown';
	});

	return array;
};

// Route and PrivateRoute components
const Routes = () => {
	return (
		<>
			{useGetRoutesPublic().map((route: any, key: number) => (
				<Route path={route.pathname} exact component={route.component} key={key} />
			))}

			{useGetRoutesPrivate(true).map((route: any, key: number) => (
				<PrivateRoute
					path={route.pathname}
					exact
					component={route.component}
					// Further route information
					key={key}
					uid={route.uid}
					routeTitle={route.title}
					moduleName={route.moduleName}
				/>
			))}
			{/* TODO: The app 404s when doing a browser refresh - routes might not be loaded yet
			<Route path="/404" exact component={GenericNotFound} />
			<Redirect to="/404" /> */}
		</>
	);
};

export { Routes, useGetRoutes, useMergeByUID, useGetRoutesPublic, useGetRoutesPrivate };

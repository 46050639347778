import { useContext, useEffect, useState } from 'react';
import { IonCard } from '@ionic/react';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import InvoiceCard from './components/InvoiceCard';
import { useParams } from 'react-router-dom';
import { moduleContext } from '../../../contexts/ModuleContext';
import Loading from '../../../components/UI/Loading';
import { showToast } from '../../../lib/toast';

const EditInvoice: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const { invoiceId } = useParams<{ invoiceId: string }>();
	const [estimateId, setEstimateId] = useState<string>('');
	const [jobId, setJobId] = useState<string>('');

	useEffect(() => {
		// Get the estimateId and jobId from the provided invoiceId
		moduleCtx
			.getInvoice(invoiceId)
			.then((res: any) => {
				setEstimateId(res.estimate_id);
				setJobId(res.job_id);
			})
			.catch(() => {
				showToast('error');
			});
	}, []);

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card'>
					{!jobId && <Loading />}
					{jobId && (
						<InvoiceCard
							permissionTo={permissionTo}
							isNew={false}
							estimateId={estimateId}
							jobId={jobId}
							invoiceId={invoiceId}
						/>
					)}
				</IonCard>
			</div>
		</>
	);
};

export default EditInvoice;

import { faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonInput,
	IonLabel,
} from '@ionic/react';
import { Ref, useContext, useEffect, useRef, useState } from 'react';
import Loading from '../../UI/Loading';
import Form from '../../Forms/Form';
import useMyDetailsFormArray from '../myDetailsFormArray';
import { db2Form } from '../../../api/forms';
import axios from '../../../lib/axios';
import { moduleContext } from '../../../contexts/ModuleContext';
import { JobSkillsOptions } from '../../../utils/autocomplete';
import { showToast } from '../../../lib/toast';

interface MyDetailsModalProps {
	isOpen: boolean;
	onClose: Function;
}

const MyDetailsModal: React.FC<MyDetailsModalProps> = (props) => {
	const moduleCtx = useContext<any>(moduleContext);
	const ionRef: any = useRef<Ref<HTMLIonModalElement>>();
	const passwordRef: any = useRef<any>();
	const [passwordOk, setPasswordOk] = useState<boolean>(false);
	const [data, setData] = useState<any>(null);
	const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
	const [items, setItems] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [rolesLoading, setRolesLoading] = useState<boolean>(true);
	const [roles, setRoles] = useState<Array<any>>([]);
	const [jobSkillsLoading, setJobSkillsLoading] = useState<boolean>(true);
	const [jobSkills, setJobSkills] = useState<Array<any>>([]);
	const [typesOfEngagementLoading, setTypesOfEngagementLoading] = useState<boolean>(true);
	const [typesOfEngagement, setTypesOfEngagement] = useState<Array<any>>([]);
	const [relationshipsLoading, setRelationshipsLoading] = useState<boolean>(true);
	const [relationships, setRelationships] = useState<any>({});
	const formDataArray = useMyDetailsFormArray();

	useEffect(() => {
		if (props.isOpen && passwordOk === true) {
			setIsLoading(true);
			loadRoles();
			loadJobSkills();
			loadTypesOfEngagement();
			loadRelationships();
		}
	}, [props.isOpen, passwordOk]);

	useEffect(() => {
		if (
			rolesLoading === false &&
			jobSkillsLoading === false &&
			typesOfEngagementLoading === false &&
			relationshipsLoading === false
		) {
			loadForm();
			setIsLoading(false);
		}
	}, [rolesLoading, jobSkillsLoading, typesOfEngagementLoading, relationshipsLoading]);

	const loadRoles = () => {
		setRolesLoading(true);

		moduleCtx
			.getSecuritySettingsTemplatesOptions()
			.then((res: any) => {
				setRoles(res);
			})
			.finally(() => {
				setRolesLoading(false);
			});
	};

	const loadJobSkills = () => {
		setJobSkillsLoading(true);

		moduleCtx
			.getJobSkillsOptions()
			.then((res: any) => {
				setJobSkills(res);
			})
			.finally(() => {
				setJobSkillsLoading(false);
			});
	};

	const loadTypesOfEngagement = () => {
		setTypesOfEngagementLoading(true);

		moduleCtx
			.getWorkerTypesOfEngagementOptions()
			.then((res: any) => {
				setTypesOfEngagement(res);
			})
			.finally(() => {
				setTypesOfEngagementLoading(false);
			});
	};

	const loadRelationships = () => {
		setRelationshipsLoading(true);

		moduleCtx
			.getKinRelationshipOptions()
			.then((res: any) => {
				setRelationships(res);
			})
			.finally(() => {
				setRelationshipsLoading(false);
			});
	};

	const loadForm = () => {
		let formData: any = formDataArray.myDetails;

		// Form setup
		formData.map((fd: any) => {
			if (fd.id === 'role_id') fd.values = roles;
			if (fd.id === 'job_skills') {
				// Set the values immediately
				fd.values = jobSkills;
				fd.loadOptions = JobSkillsOptions;
			}
			if (fd.id === 'type_of_engagement') fd.values = typesOfEngagement;
			if (fd.id === 'relationship') fd.values = relationships;
			return fd;
		});

		// Merge form data
		formData = db2Form(formData, data);
		setItems(formData);
	};

	const handlePasswordKeyDown = (e: any) => {
		if (e.key.toLowerCase() === 'enter') {
			handleContinue();
			return;
		}
	};

	const handleContinue = () => {
		if (passwordRef.current.value.length === 0) {
			showToast('error', 'Please enter your password');
			return;
		}

		setIsLoading(true);

		axios
			.post('/api/workers/my_details', { password: passwordRef.current.value })
			.then((res: any) => {
				setPasswordOk(true);
				setData(res.data);
			})
			.catch((err: any) => {
				if (err.response.status === 403) {
					showToast('error', 'There was a problem with your credentials');
				} else {
					showToast('error');
				}
				setIsLoading(false);
			});
	};

	const handleOnDidDismiss = () => {
		// Reset
		setPasswordOk(false);
		setBtnDisabled(true);
		setData(null);

		props.onClose();
	};

	return (
		<IonModal
			ref={ionRef}
			style={{ '--width': '89vw', '--height': '96vh' }}
			isOpen={props.isOpen}
			onDidDismiss={handleOnDidDismiss}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>My Details</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => ionRef.current.dismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{isLoading && <Loading overlay={true} />}
				{!isLoading && items && items.length > 0 && passwordOk === true && (
					<Form
						method='POST'
						array={items}
						permissionTo={() => {
							return false;
						}}
						noButton={true}
					/>
				)}
				{passwordOk === false && (
					<>
						<div className='no-data-placeholder'>
							<div className='no-data-placeholder-title'>Your details are secure</div>
							<FontAwesomeIcon icon={faLock} />
						</div>

						<div className='d-flex flex-column align-items-center mt-4'>
							<div className='d-flex flex-column align-items-center' style={{ width: '300px' }}>
								<IonLabel>Enter your password to continue</IonLabel>
								<IonInput
									ref={passwordRef}
									type='text'
									className='obfuscated-text-entry form-control my-2'
									onKeyUp={() => setBtnDisabled(passwordRef.current.value.length === 0)}
									onKeyDown={handlePasswordKeyDown}
								/>
								<IonButton onClick={handleContinue} disabled={btnDisabled}>
									Continue
								</IonButton>
							</div>
						</div>
					</>
				)}
			</IonContent>
		</IonModal>
	);
};

export default MyDetailsModal;

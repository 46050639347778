import { useMemo } from 'react';
import { RequestStatus } from './request-types';

const ToolTip = (props: any) => {
	const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);

	let tipTitle: string = 'Decision Reason';
	let tipMsg: string = '';

	switch (data.enum_status) {
		case RequestStatus.APPROVED:
			tipMsg = data.status;
			break;
		case RequestStatus.DECLINED:
			tipMsg = data.decision_reason ? data.decision_reason : 'None';
			break;
		case RequestStatus.REQUESTED:
			tipTitle = 'Status';
			tipMsg = data.status;
			break;
	}

	return (
		<div
			className='prop-tooltip mbsc-popup-body mbsc-material mbsc-popup-body-round '
			style={{ minWidth: '400px' }}
		>
			<div className='prop-tooltip-header'>{tipTitle}</div>
			<div className='prop-tooltip-info'>
				<span className='prop-tooltip-text '>{tipMsg}</span>
			</div>
		</div>
	);
};

export default ToolTip;

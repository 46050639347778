import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
} from '@ionic/react';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import JobTypesIndex from '../JobTypes/JobTypesIndex';

interface AddJobTypeModalProps {
	isOpen: boolean;
	onClose: Function;
	onSuccess: Function;
}

const AddJobTypeModal: React.FC<AddJobTypeModalProps> = ({ isOpen, onClose, onSuccess }) => {
	const uid = 'utilities.job_types';

	return (
		<IonModal
			style={{ '--width': '70%', '--height': '500px' }}
			isOpen={isOpen}
			onDidDismiss={() => onClose()}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Add New Job Type</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<JobTypesIndex
					uid={uid}
					routeTitle=''
					permissionTo={usePermissionTo(uid)}
					usageMode='modal'
					usageModeCompletion={onSuccess}
				/>
			</IonContent>
		</IonModal>
	);
};

export default AddJobTypeModal;

import { IonCard } from '@ionic/react';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import EstimateCard from './components/EstimateCard';
import { useParams } from 'react-router-dom';

const AddEstimate: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { jobId } = useParams<{ jobId: string }>();

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card'>
					<EstimateCard permissionTo={permissionTo} isNew={true} jobId={jobId} />
				</IonCard>
			</div>
		</>
	);
};

export default AddEstimate;

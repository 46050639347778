import { IonButton, IonItem, IonLabel, IonList, IonProgressBar } from '@ionic/react';
import { useHistory } from 'react-router';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import { useContext, useEffect, useState } from 'react';
import { authContext } from '../../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import Loading from '../../../components/UI/Loading';
import { faWorkers } from '../../../components/Icons/custom-icons.module';
import SelectStyled from '../../../components/UI/SelectStyled';
import { moduleContext } from '../../../contexts/ModuleContext';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { getTimezone } from '../../../lib/functions';
import { numberFormatter } from '../../../helpers/numbers';
import { Link } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
	widgetId: string;
	systemSettings: any;
}

const Sales: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const permissionTo = usePermissionTo('jobs.estimates_list');
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingMainData, setLoadingMainData] = useState<boolean>(true);
	const [loadingEstimateData, setLoadingEstimateData] = useState<boolean>(true);
	const [data, setData] = useState<any>({});
	const [estimateData, setEstimateData] = useState<Array<any>>([]);
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const [workersOptions, setWorkersOptions] = useState<Array<any>>([]);
	const [selectedWorker, setSelectedWorker] = useState<string>('');
	let history = useHistory();
	let wgtTitleWord = '';
	let wgtButtonWord = '';

	// Chart
	let targetMetColour = '#6ec9ba';
	let monthChartColour = '';
	let annualChartColour = '';
	let optionsMonthChart = {};
	let optionsAnnualChart = {};
	let openDealsLink = '/jobs/estimates_list?default_alltime=1';

	// Widget mode
	switch (props.widgetId) {
		case 'dashboard.personal.sales':
			wgtTitleWord = 'My ';
			wgtButtonWord = 'My ';
			monthChartColour = '#69caeb';
			annualChartColour = '#69caeb';
			openDealsLink =
				openDealsLink +
				'&issued_by=' +
				encodeURIComponent(
					`${authCtx.user.first_name} ${authCtx.user.last_name},${authCtx.user.worker_id}`
				);
			break;
		case 'dashboard.company.sales':
			wgtTitleWord = 'Company ';
			wgtButtonWord = '';
			monthChartColour = '#7796bc';
			annualChartColour = '#7796bc';
			break;
	}

	// Bar chart colours based on values
	const barColours = (salesTarget: number) => {
		return (ctx: any) => {
			const v = ctx.parsed.y;
			const c = v >= salesTarget ? targetMetColour : annualChartColour;
			return c;
		};
	};

	// Sales target chart plugin
	const salesTarget = {
		id: 'salesTarget',
		beforeDraw(chart: any, args: any, options: any) {
			const {
				ctx,
				chartArea: { left, right },
				scales: { y },
			} = chart;

			// Calculate min and max tick values required for sales target to fit between
			const yTicks: Array<any> = y
				.getTicks()
				.sort((a: any, b: any) => (a.value < b.value ? 1 : -1));
			const index = yTicks.findIndex((tick: any) => options.target >= tick.value);
			if (index > -1) {
				if (
					yTicks[index - 1] &&
					yTicks[index - 1].hasOwnProperty('value') &&
					yTicks[index] &&
					yTicks[index].hasOwnProperty('value')
				) {
					const tickUpper = y.getPixelForValue(yTicks[index - 1].value);
					const tickLower = y.getPixelForValue(yTicks[index].value);

					ctx.save();
					ctx.fillStyle = options.colour;
					ctx.fillRect(left, tickUpper, right - left, tickLower - tickUpper);
					ctx.restore();
				}
			}
		},
	};

	const salesTargetLineColour = {
		id: 'salesTargetLineColour',
		afterLayout(chart: any, args: any, options: any) {
			let ctx = chart.ctx;
			ctx.save();
			let yAxis = chart.scales.y;
			let yThreshold = yAxis.getPixelForValue(options.target);
			let gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);
			gradient.addColorStop(0, targetMetColour);
			let correction = 0.01;
			let offset = 0;
			if (options.target && Number(options.target) > 0) {
				offset = (1 / yAxis.bottom) * yThreshold - correction;
			}

			// Offset range checks
			if (offset < 0) offset = 0;
			if (offset > 1) offset = 1;

			gradient.addColorStop(offset, targetMetColour);
			gradient.addColorStop(offset, monthChartColour);
			gradient.addColorStop(1, monthChartColour);
			chart.data.datasets[0].borderColor = gradient;
			ctx.restore();
		},
	};

	const loadMainData = (filterByWorkerId: string = '') => {
		setLoadingMainData(true);

		let payload: any = {};

		if (props.widgetId === 'dashboard.personal.sales') {
			payload.worker_id = authCtx.user.worker_id;
		}

		if (filterByWorkerId.length > 0) {
			payload.worker_id = filterByWorkerId;
		}

		axios
			.post('/api/dashboard/estimates/sales_targets', payload)
			.then((res: any) => {
				let d: any = res.data;

				if (d.sales_target === null || d.sales_target === undefined) {
					d.sales_target = 0;
				}

				// Lists
				let closedWonList: Array<any> = [];
				if (d.hasOwnProperty('closed_won_list') && d.closed_won_list.length > 0) {
					closedWonList = d.closed_won_list
						.sort((a: any, b: any) => (a.value < b.value ? 1 : -1))
						.map((item: any) => {
							return {
								title: item.number + ': ' + item.client_name + ': ' + item.job_name,
								value: item.value,
								valueFormatted: authCtx.tenant.currency_symbol + numberFormatter(item.value, 1),
							};
						});
				}

				// Charts
				let monthChartLabels: Array<string> = [];
				let monthChartValues: Array<number> = [];
				if (d.hasOwnProperty('month_chart') && d.month_chart) {
					monthChartLabels = Object.keys(d.month_chart);
					monthChartValues = Object.keys(d.month_chart).map((key: any) => d.month_chart[key]);
				}

				let annualChartLabels: Array<string> = [];
				let annualChartValues: Array<number> = [];
				if (d.hasOwnProperty('annual_chart') && d.annual_chart) {
					annualChartLabels = Object.keys(d.annual_chart);
					annualChartValues = Object.keys(d.annual_chart).map((key: any) => d.annual_chart[key]);
				}

				const tmp: any = {
					sales_target: d.sales_target,
					won_value: d.won_value,
					won_fy_value: d.won_fy_value,
					won_progress: Number(d.won_progress) / 100,
					deals_no: d.deals_no,
					svt_value: d.svt_value,
					svt_positive: d.svt_positive,
					closed_won_list: closedWonList,
					monthChart: {
						labels: monthChartLabels,
						datasets: [
							{
								data: monthChartValues,
								borderWidth: 4,
								pointRadius: 0,
								pointHitRadius: 4,
							},
						],
					},
					annualChart: {
						labels: annualChartLabels,
						datasets: [
							{
								data: annualChartValues,
								borderColor: barColours(d.sales_target),
								backgroundColor: barColours(d.sales_target),
								barThickness: 6,
								borderRadius: 6,
							},
						],
					},
				};

				setData(tmp);
			})
			.catch(() => showToast('error', `There was a problem with the ${wgtTitleWord}Sales widget`))
			.finally(() => setLoadingMainData(false));
	};

	const loadEstimateData = (filterByWorkerId: string = '') => {
		setLoadingEstimateData(true);

		let payload: any = {
			status: 'issued',
			...getTimezone(),
		};

		if (props.widgetId === 'dashboard.personal.sales') {
			payload.worker_id = authCtx.user.worker_id;
		}

		if (filterByWorkerId.length > 0) {
			payload.worker_id = filterByWorkerId;
		}

		axios
			.post('/api/jobs/estimates_list', payload)
			.then((res: any) => {
				if (res.data.length > 0) {
					setEstimateData(
						res.data
							.sort((a: any, b: any) => (a.value < b.value ? 1 : -1))
							.map((item: any) => {
								return {
									id: item.id,
									title: item.number + ': ' + item.client_name + ': ' + item.job_name,
									value: item.value,
									valueFormatted: authCtx.tenant.currency_symbol + numberFormatter(item.value, 1),
								};
							})
					);
				}
			})
			.catch(() => showToast('error', `There was a problem with the ${wgtTitleWord}Sales widget`))
			.finally(() => setLoadingEstimateData(false));
	};

	const loadWorkers = async () => {
		if (props.widgetId !== 'dashboard.company.sales') {
			setWorkersLoading(false);
			return;
		} else {
			setWorkersLoading(true);
		}

		await moduleCtx.getWorkersList(null, false, false, false, true).then((res: any) => {
			const result = res.map((r: any) => {
				return {
					label: r.name,
					value: r.id,
				};
			});
			setWorkersOptions(result);
		});

		setWorkersLoading(false);
	};

	// Check if loading data
	useEffect(() => {
		setLoading(loadingMainData || loadingEstimateData);
	}, [loadingMainData, loadingEstimateData]);

	useEffect(() => {
		loadMainData();
		loadEstimateData();
		loadWorkers();
	}, []);

	optionsMonthChart = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
			tooltip: {
				displayColors: false,
				callbacks: {
					label: (context: any) => {
						let label = context.raw || '';
						if (label) label = authCtx.tenant.currency_symbol + numberFormatter(label, 2);
						return label;
					},
				},
			},
			salesTarget: { colour: '#d9e0e5', target: data.sales_target },
			salesTargetLineColour: { target: data.sales_target },
		},
		scales: {
			x: {
				grid: {
					display: false,
					drawBorder: false,
				},
			},
			y: {
				grid: {
					color: 'white',
					drawBorder: false,
				},
				ticks: {
					callback: function (value: any, index: number, ticks: any) {
						return authCtx.tenant.currency_symbol + numberFormatter(value, 0);
					},
				},
				beginAtZero: true,
				suggestedMax: 10000,
			},
		},
	};

	optionsAnnualChart = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
			tooltip: {
				displayColors: false,
				callbacks: {
					label: (context: any) => {
						let label = context.raw || '';
						if (label) label = authCtx.tenant.currency_symbol + numberFormatter(label, 2);
						return label;
					},
				},
			},
			salesTarget: { colour: '#d9e0e5', target: data.sales_target },
		},
		scales: {
			x: {
				grid: {
					display: false,
					drawBorder: false,
				},
			},
			y: {
				grid: {
					color: 'white',
					drawBorder: false,
				},
				ticks: {
					callback: function (value: any, index: number, ticks: any) {
						return authCtx.tenant.currency_symbol + numberFormatter(value, 0);
					},
				},
				beginAtZero: true,
				suggestedMax: 10000,
			},
		},
	};

	const handleGoToEstimates = () => {
		let url = '/jobs/estimates_list';

		if (props.widgetId === 'dashboard.personal.sales') {
			url += `?issued_by=${encodeURIComponent(
				`${authCtx.user.first_name} ${authCtx.user.last_name},${authCtx.user.worker_id}`
			)}`;
		} else {
			if (selectedWorker) url += `?issued_by=${encodeURIComponent(selectedWorker)}`;
		}

		history.push(url);
	};

	const handleWorkerChange = (e: any) => {
		let workerLabel: string = '';
		let workerId: string = '';

		if (e) {
			workerLabel = e.label;
			workerId = e.value;
			setSelectedWorker(`${workerLabel},${workerId}`);
		} else {
			setSelectedWorker('');
		}

		// Reload data
		loadMainData(workerId);
		loadEstimateData(workerId);
	};

	return (
		<>
			<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
				<div className='widget-header'>{wgtTitleWord}Sales</div>
				<div className='widget-content'>
					{loading && <Loading overlay={true} />}
					<div className='left'>
						<div className='panel panel-a'>
							<div className='sub-panel stats'>
								{!loading && data.hasOwnProperty('sales_target') && (
									<>
										<div className='title'>Won this month</div>
										<div className='title-stat'>
											<div>{authCtx.tenant.currency_symbol + data.won_value}</div>
											<IonProgressBar value={data.won_progress}></IonProgressBar>
										</div>
										<div className='title'>No. of deals</div>
										<div className='title-stat lower'>{data.deals_no}</div>
									</>
								)}
							</div>
							<div className='sub-panel chart'>
								<div className='chart-left'>
									{!loading && data.hasOwnProperty('sales_target') && (
										<Line
											options={optionsMonthChart}
											plugins={[salesTarget, salesTargetLineColour]}
											data={data.monthChart}
										/>
									)}
								</div>
								<div className='chart-right'>
									{!loading && data.hasOwnProperty('sales_target') && (
										<>
											<div className='title'>Closed-Won</div>
											<div className='closed-won-container'>
												<IonList className='widget-list' lines='none'>
													{data.closed_won_list.length === 0 && (
														<IonItem className='cursor-default'>
															<IonLabel className='justify-content-center'>
																Nothing this month
															</IonLabel>
														</IonItem>
													)}
													{data.closed_won_list.map((item: any, i: number) => (
														<IonItem key={i}>
															<IonLabel title={item.title + ' | ' + item.valueFormatted}>
																<div className='label-left'>{item.title}</div>
																<div className='label-right'>{item.valueFormatted}</div>
															</IonLabel>
														</IonItem>
													))}
												</IonList>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<div className='panel panel-b'>
							<div className='sub-panel stats'>
								{!loading && data.hasOwnProperty('sales_target') && (
									<>
										<div className='title'>Won this FY</div>
										<div className='title-stat'>
											{authCtx.tenant.currency_symbol + data.won_fy_value}
										</div>
										<div className='stat-vs-target'>
											<FontAwesomeIcon
												icon={data.svt_positive === true ? faArrowUp : faArrowDown}
												className={data.svt_positive === true ? 'svt-positive' : ''}
											/>
											<div className='svt-value'>
												<div>{authCtx.tenant.currency_symbol + data.svt_value}</div>
												<span>vs. target</span>
											</div>
										</div>
									</>
								)}
							</div>
							<div className='sub-panel chart'>
								{!loading && data.hasOwnProperty('sales_target') && (
									<Bar
										options={optionsAnnualChart}
										plugins={[salesTarget]}
										data={data.annualChart}
									/>
								)}
							</div>
						</div>
					</div>
					<div className='right'>
						<div className='panel panel-c'>
							{!loadingEstimateData && estimateData && (
								<>
									<div className='title'>
										<Link className='prop-link' to={openDealsLink}>
											Open deals
										</Link>
									</div>
									<div className='title-stat'>
										{authCtx.tenant.currency_symbol +
											numberFormatter(
												estimateData.reduce((a: any, v: any) => (a = a + v.value), 0),
												0
											)}
									</div>
									<div className='open-deals-container'>
										<IonList className='widget-list' lines='none'>
											{estimateData.length === 0 && (
												<IonItem className='cursor-default'>
													<IonLabel className='justify-content-center'>None</IonLabel>
												</IonItem>
											)}
											{estimateData.map((item: any, i: number) => (
												<IonItem key={i}>
													<IonLabel title={item.title + ' | ' + item.valueFormatted}>
														<Link
															className='prop-link'
															to={`/jobs/estimates_list/estimates_card/${item.id}`}
														>
															<div className='label-left'>{item.title}</div>
															<div className='label-right'>{item.valueFormatted}</div>
														</Link>
													</IonLabel>
												</IonItem>
											))}
										</IonList>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
				<div className='widget-footer justify-content-end'>
					{permissionTo('read') && (
						<>
							{props.widgetId === 'dashboard.company.sales' && (
								<div className='worker-selection-container'>
									<div className='icon-container'>
										<FontAwesomeIcon icon={faWorkers as IconDefinition} />
									</div>
									<SelectStyled
										onChange={handleWorkerChange}
										placeholder={'Type a worker name...'}
										options={workersOptions}
										isMulti={false}
										isClearable={true}
										useLabels={false}
										editMode={true}
									/>
								</div>
							)}
							<IonButton size='small' onClick={handleGoToEstimates} disabled={workersLoading}>
								Go To {wgtButtonWord}Estimates
							</IonButton>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default Sales;

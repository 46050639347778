import { useEffect, useRef, useState, useContext } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { IonRow, IonCol, IonButton, IonCard } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTurnUp } from '@fortawesome/free-solid-svg-icons';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';
import './SystemUsage.scss';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { getTimezone } from '../../../../lib/functions';

interface RouteIndexComponentExtended extends RouteIndexComponent {
	usageMode?: string;
	workerId?: string;
}

const SystemUsageIndex: React.FC<RouteIndexComponentExtended> = ({
	uid,
	routeTitle,
	permissionTo,
	usageMode,
	workerId,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const gridRef: any = useRef<any>();
	const workerFilterRef: any = useRef<any>();
	const [curMonth, setCurMonth] = useState<number>(Number(DateTime.now().toFormat('MM')));
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const [tableData, setTableData] = useState<Array<any>>([]);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [leagueTable, setLeagueTable] = useState<boolean>(false);
	const [payloadUsage, setPayloadUsage] = useState<Array<any>>([]);
	const [payloadLeague, setPayloadLeague] = useState<Array<any>>([]);
	const [tabMode, setTabMode] = useState<string>('company_usage');
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [workerFilter, setWorkerFilter] = useState<any>(null);

	const usageColumnsBasename = 'Module';
	const usageColumns = [
		{ headerName: usageColumnsBasename, field: 'item_name', flex: 1, sort: 'asc' },
		{ headerName: 'Clicks', field: 'clicks', flex: 1, maxWidth: '300' },
	];

	const leagueColumnsBasename = 'Worker';
	const leagueColumns = [
		{ headerName: leagueColumnsBasename, flex: 1, field: 'worker_name' },
		{ headerName: 'Clicks', field: 'clicks', flex: 1, maxWidth: '300', sort: 'desc' },
	];

	const [columns, setColumns] = useState<Array<any>>(usageColumns);
	const [columnsLeague, setColumnsLeague] = useState<Array<any>>(leagueColumns);

	useEffect(() => {
		// Initial load of the workers
		loadWorkers();

		switch (usageMode) {
			case 'worker_tabs':
				setWorkerFilter(workerId);
				break;
		}
	}, []);

	useEffect(() => {
		if (gridReady) {
			// Re-load the grid data when the payload tracker or table type changes
			loadGridData(curMonth, curYear);
		}
	}, [payloadUsage, payloadLeague, gridReady, leagueTable, workerFilter]);

	const onGridReady = () => {
		setGridReady(true);
	};

	const loadGridData = (month: number, year: number, payload: any = null) => {
		setTableData([]);
		setGridLoading(true);
		let leagueTableGrouping = leagueTable;

		// Update column titles if required
		if (!leagueTable) {
			const newColumnName =
				payloadUsage.length > 0 ? ` - ${payloadUsage[payloadUsage.length - 1].title}` : '';
			const newColumns = gridRef.current.getColumnDefs();
			newColumns.forEach((newColumn: any, index: number) => {
				if (newColumn.field === 'item_name') {
					newColumn.headerName = `${usageColumnsBasename}${newColumnName}`;
				}
			});
			setColumns(newColumns);
		} else {
			const newColumns = gridRef.current.getColumnDefs();
			let newColumnName = '';
			if (payloadLeague.length > 0) {
				// Only show a maximum of 2 titles
				newColumnName = payloadLeague
					.map((p: any, i: number) => {
						if (i > 0) {
							return payloadLeague[payloadLeague.length - 1].title;
						} else {
							return p.title;
						}
					})
					.slice(0, 2)
					.join(' - ');
			}
			newColumns[0].headerName = `${leagueColumnsBasename}${
				newColumnName.length > 0 ? ' - ' : ''
			}${newColumnName}`;

			// Switch column field identifiers based on payload
			if (payloadLeague.length === 0) {
				newColumns[0].field = 'worker_name';
			} else {
				newColumns[0].field = 'item_name';
			}

			setColumnsLeague(newColumns);
		}

		// Reset the search
		gridRef.current.clearSearch();

		// When no payload is provided (e.g. date parameter change), use the latest payload state
		if (!payload) {
			if (!leagueTable) {
				if (payloadUsage.length > 0)
					payload = { security_key: payloadUsage[payloadUsage.length - 1].keyID };
			} else {
				if (payloadLeague.length > 0) {
					if (payloadLeague.length === 1) {
						payload = { worker_id: payloadLeague[payloadLeague.length - 1].keyID };
					} else {
						// Maintain the worker_id key in all subsequent requests
						payload = {
							worker_id: payloadLeague[0].keyID,
							security_key: payloadLeague[payloadLeague.length - 1].keyID,
						};
					}

					leagueTableGrouping = false;
				}
			}
		}

		// Apply the worker filter if required
		if (workerFilter && workerFilter.length > 0) {
			if (!payload) {
				payload = { worker_id: workerFilter };
			} else {
				payload['worker_id'] = workerFilter;
			}
		}

		axios
			.post('/api/utilities/system_usage', {
				league: leagueTableGrouping,
				month: month,
				year: year,
				...getTimezone(),
				...payload,
			})
			.then((res) => {
				setCurMonth(month);
				setCurYear(year);

				setTableData(res.data);
				setGridLoading(false);
			});
	};

	const handleCellClicked = (params: any) => {
		setGridLoading(true);
		if (!leagueTable) {
			setPayloadUsage((prevState: any) => [
				...prevState,
				{ keyID: params.data.security_key, title: params.data.item_name },
			]);
			setPayloadLeague([]);
		} else {
			if (payloadLeague.length === 0) {
				setPayloadLeague((prevState: any) => [
					...prevState,
					{ keyID: params.data.worker_id, title: params.data.worker_name },
				]);
			} else {
				setPayloadLeague((prevState: any) => [
					...prevState,
					{ keyID: params.data.security_key, title: params.data.item_name },
				]);
			}

			setPayloadUsage([]);
		}
	};

	const handleGridBack = () => {
		if (!leagueTable) {
			const arr: Array<any> = [...payloadUsage];
			arr.splice(arr.length - 1, 1);
			setPayloadUsage(arr);
		} else {
			const arr: Array<any> = [...payloadLeague];
			arr.splice(arr.length - 1, 1);
			setPayloadLeague(arr);
		}
	};

	const handleTabChange = (event: any) => {
		let tab = event.target;
		let prevTab = tabMode;
		if (!tab.classList.contains('subnav-item')) {
			tab = tab.closest('.subnav-item');
		}

		setTabMode(tab.id);

		// Reset table data and navigation
		setTableData([]);
		setPayloadUsage([]);
		setPayloadLeague([]);

		if (gridReady) {
			switch (tab.id) {
				case 'company_usage':
					setLeagueTable(false);

					switch (usageMode) {
						case 'worker_tabs':
							setWorkerFilter(workerId);
							break;
					}
					break;
				case 'league_table':
					setLeagueTable(true);

					switch (usageMode) {
						case 'worker_tabs':
							setWorkerFilter(null);
							break;
					}
					break;
			}
		}

		if (tab.id !== prevTab) {
			// Reset the table only when changing tabs
			setGridReady(false);
			handleClearWorkerFilter();
			setCurMonth(Number(DateTime.now().toFormat('MM')));
			setCurYear(Number(DateTime.now().toFormat('yyyy')));
		}
	};

	const handleExport = (exportType: string) => {
		switch (exportType) {
			case 'screen':
				if (gridReady && gridRef.current) {
					gridRef.current.exportDataAsExcel();
				}
				break;
		}
	};

	const loadWorkers = () => {
		moduleCtx.getWorkers().then((res: any) => {
			const result = res.map((r: any) => {
				return {
					label: r.name,
					value: r.id,
				};
			});
			setWorkers(result);
		});
	};

	const handleWorkerFilter = (option: any) => {
		setGridLoading(true);
		if (!option) {
			setWorkerFilter(null);
			return;
		}

		if (option.value.length > 0) {
			setWorkerFilter(option.value);
		}
	};

	const handleClearWorkerFilter = () => {
		if (workerFilterRef.current) {
			workerFilterRef.current.clearValue();
		}
	};

	const renderFooter = () => (
		<>
			<IonRow>
				<IonCol size='6' className='row-lower-controls-left ps-0'>
					{tabMode === 'company_usage' && !usageMode && (
						<div className='row-lower-controls-container'>
							<SelectStyled
								className='worker-filter'
								placeholder='Select a name to filter by worker...'
								forwardRef={workerFilterRef}
								options={workers}
								onChange={(option: any) => handleWorkerFilter(option)}
							/>
							<IonButton
								color='secondary'
								disabled={!workerFilter || gridLoading}
								onClick={() => handleClearWorkerFilter()}
							>
								Reset Worker
							</IonButton>
						</div>
					)}
				</IonCol>
				<IonCol size='6' className='row-lower-controls-right pe-0'>
					<div className='row-lower-controls-container'>
						<IonButton
							className='btn-back'
							onClick={handleGridBack}
							color='secondary'
							disabled={(payloadUsage.length === 0 && payloadLeague.length === 0) || gridLoading}
						>
							<FontAwesomeIcon icon={faTurnUp} />
							Up a Level
						</IonButton>
						<IonButton color='tertiary' onClick={() => handleExport('detailed')}>
							Export Detailed Entity Report (xlsx)
						</IonButton>
						<IonButton color='tertiary' onClick={() => handleExport('screen')}>
							Export
						</IonButton>
					</div>
				</IonCol>
			</IonRow>
		</>
	);

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				{!usageMode && <TitleBar title={routeTitle} />}
				<IonCard className='table-card filter-data-table full-height-card flex-grow-container'>
					<IonRow className='sub-nav-tabs'>
						<IonCol
							id='company_usage'
							className={tabMode === 'company_usage' ? 'active subnav-item' : 'subnav-item'}
							onClick={(e: any) => handleTabChange(e)}
						>
							<span>Company Usage</span>
						</IonCol>
						<IonCol
							id='league_table'
							className={tabMode === 'league_table' ? 'active subnav-item' : 'subnav-item'}
							onClick={(e: any) => handleTabChange(e)}
						>
							<span>League Table</span>
						</IonCol>
					</IonRow>
					<div style={{ flex: 1 }}>
						{tabMode === 'company_usage' && (
							<DataGrid
								onGridReady={onGridReady}
								ref={gridRef}
								title={'Company Usage'}
								cols={columns}
								data={tableData}
								filterButtons={true}
								filterOptions={{
									displayCurrentMonth: loadGridData,
								}}
								cellClickedFunction={handleCellClicked}
								rowCursorPointer={true}
								extraFooter={renderFooter()}
							/>
						)}

						{tabMode === 'league_table' && (
							<DataGrid
								onGridReady={onGridReady}
								ref={gridRef}
								title={'League Table'}
								cols={columnsLeague}
								data={tableData}
								filterButtons={true}
								filterOptions={{
									displayCurrentMonth: loadGridData,
								}}
								cellClickedFunction={handleCellClicked}
								extraFooter={renderFooter()}
							/>
						)}
					</div>
				</IonCard>
			</div>
		</>
	);
};

export default SystemUsageIndex;

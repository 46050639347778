export const companyDetails: Array<any> = [
	{
		title: 'Trading Address',
		type: 'title',
	},
	{
		title: 'Address Lookup',
		type: 'addressLookup',
		id: 'address-lookup-trading',
	},
	{
		title: 'Address 1',
		type: 'text',
		group: 'trading',
		db: ['trading', 'address_1'],
		subType: 'address_1',
	},
	{
		title: 'Address 2',
		type: 'text',
		group: 'trading',
		db: ['trading', 'address_2'],
		subType: 'address_2',
	},
	{
		title: 'Town',
		type: 'text',
		group: 'trading',
		db: ['trading', 'town'],
		subType: 'town',
	},
	{
		title: 'Postcode',
		type: 'postcode',
		group: 'trading',
		db: ['trading', 'postcode'],
		subType: 'postcode',
	},
	{
		type: 'sectionBreak',
	},
	{
		title: 'General Email Address',
		type: 'email',
		db: ['general_email'],
	},
	{
		title: 'Telephone Number',
		type: 'phone',
		db: ['general_tel'],
	},
	{
		type: 'sectionBreak',
	},
	{
		title: 'Registered Office Address',
		type: 'title',
	},
	{
		title: 'Check box if same as above',
		type: 'copyAddress',
		groupFrom: 'trading',
		groupTo: 'registered',
		value: false,
	},
	{
		title: 'Address Lookup',
		type: 'addressLookup',
		id: 'address-lookup-registered',
	},
	{
		title: 'Address 1',
		type: 'text',
		group: 'registered',
		db: ['registered', 'address_1'],
		subType: 'address_1',
	},
	{
		title: 'Address 2',
		type: 'text',
		group: 'registered',
		db: ['registered', 'address_2'],
		subType: 'address_2',
	},
	{
		title: 'Town',
		type: 'text',
		group: 'registered',
		db: ['registered', 'town'],
		subType: 'town',
	},
	{
		title: 'Postcode',
		type: 'postcode',
		group: 'registered',
		db: ['registered', 'postcode'],
		subType: 'postcode',
	},
	{
		type: 'sectionBreak',
	},
	{
		title: 'Company Registration Number',
		type: 'text',
		db: ['company_registration_number'],
	},
];

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { showToast } from '../../../../lib/toast';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import AddClient from '../AddClient';

interface AddClientModalProps {
	isOpen: boolean;
	onClose: Function;
}

const AddClientModal: React.FC<AddClientModalProps> = ({ isOpen, onClose }) => {
	const uid = 'jobs.clients_list.add_new';
	const [triggerSave, setTriggerSave] = useState<number>(0);

	useEffect(() => {
		setTriggerSave(0);
	}, [onClose]);

	const onSave = () => {
		// Use useState and useEffect in the child component to trigger functionality
		setTriggerSave(prevState => prevState += 1);
	}

	const onSuccess = () => {
		showToast("success", "New client created successfully");
		onClose();
	}

	return (
		<IonModal class="note-modal" style={{ '--width': '70%' }} isOpen={isOpen} onDidDismiss={() => onClose()} className="prop-form">
			<IonHeader>
				<IonToolbar>
					<IonTitle>Add New Client</IonTitle>
					<IonButtons slot="end" className="ion-modal-buttons">
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<AddClient
					uid={uid}
					routeTitle=''
					permissionTo={usePermissionTo(uid)}
					usageMode='modal'
					usageModeCompletion={onSuccess}
					triggerSave={triggerSave}
				/>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size="12" className="text-right">
							<IonButton color="success" onClick={() => onSave()}>
								Create Client
							</IonButton>
							<IonButton color="secondary" onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
}

export default AddClientModal;

import { HATState, MenuCounts } from './hat-types';
import { Actions as Action, ActionType } from './actions';

export const hatReducer = (state: HATState, action: Action): HATState => {
	switch (action.type) {
		case ActionType.UPDATE_MENU_COUNTS: {
			const { payload } = action;

			const menuCounts: MenuCounts = { ...state.menuCounts, ...payload };

			return {
				...state,
				menuCounts: menuCounts,
			};
		}
		default: {
			return state;
		}
	}
};

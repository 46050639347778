import { useEffect, useState } from 'react';
import { keyUserDetails } from './arrays/keyUserDetails';
import { db2Form } from '../../../../../api/forms';
import Form from '../../../../../components/Forms/Form';
import Loading from '../../../../../components/UI/Loading';
import axios from '../../../../../lib/axios';

interface Props {
	uid: string;
	permissionTo: Function;
}

const KeyUserDetails = (props: Props) => {
	const [items, setItems] = useState<Array<any>>([]);
	const [endpointID, setEndpointID] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		let formData: any = keyUserDetails;

		axios
			.get('/api/utilities/company_details')
			.then((res) => res.data[0])
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
				setEndpointID(dbData._id);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<>
			{isLoading && <Loading />}
			{!isLoading && items && items.length > 0 && (
				<>
					<p>
						All reminders and account messages will be sent to this address. This person is required
						to agree the software license before the system can be used. Any amendments to our terms
						and conditions or policies will be forwarded to this address.
					</p>
					<Form
						array={items}
						permissionTo={props.permissionTo}
						endpoint={'/api/utilities/company_details/'}
						endpointID={endpointID}
					/>
				</>
			)}
		</>
	);
};

export default KeyUserDetails;

import { useGetRoutes } from '../../routes';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggle } from '../../state/slices/menuExpanded';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoMenu from '../../assets/images/logos/hf_logo_menu.png';
import logoMenuExp from '../../assets/images/logos/hf_logo_menu_exp.png';
import { createElement } from 'react';

const Menu = ({ expanded }: any) => {
	const dispatch = useDispatch();
	const routesAll = useGetRoutes();

	const menuRoutes = () => {
		let array = routesAll
			.filter((route: any) => {
				return route.toolbar !== false;
			})
			.map((route: any) => {
				return {
					pathname: route.pathname,
					title: route.title,
					icon: route.icon,
					badge: route.badge,
				};
			});
		return array;
	};

	const menuRoutesArray = () => {
		let routes = menuRoutes().map((route: any, key: number) => {
			return (
				<li key={key}>
					<NavLink to={route.pathname} activeClassName='active'>
						<FontAwesomeIcon icon={route.icon} />
						{expanded && <span className='menu-link-text'>{route.title}</span>}
						<div className={`menu-link-title${expanded ? '-exp' : ''}`}>{route.title}</div>
						{route.badge && createElement(route.badge, {})}
					</NavLink>
				</li>
			);
		});
		return routes;
	};

	const toggleMenu = () => {
		dispatch(toggle());
	};

	return (
		<div id='menu' className={expanded ? 'menu-expanded' : 'menu-icons'}>
			<div id='menu-wrapper'>
				<div id={`human-facta${expanded ? '-exp' : ''}`}>
					<img
						style={{ display: expanded ? 'inline-block' : 'none' }}
						src={logoMenuExp}
						alt='HumanFacta'
					/>
					<img
						style={{ display: !expanded ? 'inline-block' : 'none' }}
						src={logoMenu}
						alt='HumanFacta'
					/>
				</div>
				<ul id='upper-menu'>{menuRoutesArray()}</ul>
				<ul id='lower-menu'>
					<li onClick={() => toggleMenu()} className='menu-chevron'>
						<div className='menu-chevron-container'>
							{expanded ? (
								<FontAwesomeIcon icon={faChevronLeft} />
							) : (
								<FontAwesomeIcon icon={faChevronRight} />
							)}
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Menu;

import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useState } from 'react';
import { DateTime } from 'luxon';

type Props = {
	title: string;
	onClose: Function;
};

const MessageModal = (props: Props, ref: any) => {
	const [didPresent, setDidPresent] = useState<boolean>(false);

	const handleOnDidPresent = () => {
		setDidPresent(true);
	};

	const onClose = () => {
		// Reset
		setDidPresent(false);
		props.onClose();
	};

	return (
		<IonModal
			ref={ref}
			class='client-message-modal'
			style={{ '--width': '50vw', '--height': '40vh' }}
			onDidPresent={handleOnDidPresent}
			onWillDismiss={() => onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{props.title}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{didPresent && (
					<div className='message-container'>
						<div className='message-row'>
							<div className='message-label'>Date Sent:</div>
							<div className='message-value'>
								{ref.current.modalData.date
									? DateTime.fromISO(ref.current.modalData.date).toFormat('dd/MM/yyyy @ HH:mm')
									: '-'}
							</div>
						</div>
						<div className='message-row'>
							<div className='message-label'>Sent By:</div>
							<div className='message-value'>{ref.current.modalData.sent_by ?? '-'}</div>
						</div>
						<div className='message-row mr-full-height'>
							<div className='message-label'>Message:</div>
							<div className='message-value'>{ref.current.modalData.message ?? '-'}</div>
						</div>
					</div>
				)}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default forwardRef(MessageModal);

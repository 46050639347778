import { useContext } from 'react';
import { authContext } from '../../../../../../contexts/AuthContext';
import { useGetRoutes } from '../../../../../../routes';
import { getPermissionTo } from '../../../../../../lib/functions';

const AccountDetailsSubmenuArray: any = (uid: string) => {
	const authCtx = useContext<any>(authContext);
	const routes = useGetRoutes(uid, true);
	let tmp: Array<any> = [];

	// TODO: Temporary utility statuses to reflect development progress
	const tempStat = [
		{ uid: 'utilities.account_details.company_details', badge: 'success' },
		{ uid: 'utilities.account_details.key_user_details', badge: 'success' },
		{ uid: 'utilities.account_details.license_dates_and_costs', badge: 'success' },
		{ uid: 'utilities.account_details.license_agreement', badge: 'success' },
		{ uid: 'utilities.account_details.messages', badgeNumber: 1 },
		{ uid: 'utilities.account_details.storage_use', badge: 'success' },
	];

	if (Array.isArray(routes) && routes.length > 0) {
		for (let i = 0; i < routes.length; i++) {
			let r = routes[i];
			tmp.push({
				id: `submenu-${r.uid.replace(/_/g, '').split('.').join('')}`,
				uid: r.uid,
				title: r.title,
				page: r.component,
				hash: r.security_key.replace('_', '-'),
				active: i === 0,
				badge: tempStat.filter((s: any) => s.uid === r.uid)[0]?.badge,
				badgeNumber: tempStat.filter((s: any) => s.uid === r.uid)[0]?.badgeNumber ?? 0,
				fullHeight: r.security_key === 'messages',
				pageData: {
					uid: r.uid,
					permissionTo: (actions: string | Array<any> = '', uid: string = r.uid) => {
						return getPermissionTo(actions, uid, authCtx);
					},
				},
			});
		}
	}

	return tmp;
};

export default AccountDetailsSubmenuArray;

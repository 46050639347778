import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
	value: {
		loginSuccess: boolean
	}
}

export const initialState = {
	value: {
		loginSuccess: false
	}
} as InitialState;

export const appState = createSlice({
	name: "appState",
	initialState,
	reducers: {
		setAppState: (state: any, action: any) => {
			state.value = { ...state.value, ...action.payload };
		},
		setLoginSuccess: (state: any) => {
			state.value.loginSuccess = true;
		},
		setLoginFail: (state: any) => {
			state.value.loginSuccess = false;
		},
	},
});

export const { setAppState, setLoginSuccess, setLoginFail } = appState.actions;

export default appState.reducer;

import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { Eventcalendar, MbscResource } from '@mobiscroll/react';
import { DateTime } from 'luxon';
import { useCallback, useContext, useState } from 'react';
import { SchedulingContext } from '../SchedulingProvider';
import { EventType, ResourceMode } from '../scheduling-types';
import CalendarContextMenu from '../../../components/Calendars/CalendarContextMenu';
import Loading from '../../../components/UI/Loading';
import Avatar from '../../../components/Calendars/Avatar';

interface DayScheduleModalProps {
	isOpen: boolean;
	selectedDate: DateTime;
	events: Array<any>;
	resource: any;
	permissionTo: Function;
	handleUpdateEvent: Function;
	handleDeleteEvent: Function;
	onClose: Function;
	onDidDismiss: Function;
}

const DayScheduleModal: React.FC<DayScheduleModalProps> = (props) => {
	const { state, dispatch } = useContext(SchedulingContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [title, setTitle] = useState<string>('');
	const [otherCalendarOptions, setOtherCalendarOptions] = useState<any>({});
	const [calEvents, setCalEvents] = useState<Array<any>>([]);
	const [workers, setWorkers] = useState<Array<any>>([]);

	// Right-click context menu
	const [contextMenuInst, setContextMenuInst] = useState<any>();
	const [contextMenuAnchor, setContextMenuAnchor] = useState<any>();
	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
	const [contextMenuRightClickedEvent, setContextMenuRightClickedEvent] = useState<any>(null);
	const contextMenuOptions: Array<any> = [
		{
			text: (
				<>
					<FontAwesomeIcon icon={faTrash} className='pe-2' />
					Delete Single Appointment
				</>
			),
			value: 'delete_appointment',
			action: () => {
				props.handleDeleteEvent(
					contextMenuRightClickedEvent.event.request_id,
					contextMenuRightClickedEvent.event.resource_orig,
					true,
					null,
					null,
					contextMenuRightClickedEvent.event.id
				);
			},
			hasPermission: props.permissionTo('delete'),
		},
	];

	const onDidPresent = () => {
		let propEvents: Array<any> = props.events;

		// Set the modal title
		let resourceModeTitle: string = state.resourceMode.slice(0, -1);
		resourceModeTitle = resourceModeTitle.charAt(0).toUpperCase() + resourceModeTitle.slice(1);
		resourceModeTitle += ' details for ' + props.resource.name;
		resourceModeTitle += props.resource.hasOwnProperty('parentName')
			? ' on ' + props.resource.parentName
			: '';
		resourceModeTitle += ' on ' + props.selectedDate.toFormat('cccc dd/MM/yyyy');
		setTitle(resourceModeTitle);

		// Add the description to the title
		propEvents = propEvents.map((event: any) => ({
			...event,
			title: event.title + (event.description ? ' - ' + event.description : ''),
			allDay: false, // Event must not be all-day for it to show properly
		}));

		// Calendar setup
		switch (state.resourceMode) {
			case ResourceMode.WORKERS:
				setCalEvents(propEvents);
				break;
			case ResourceMode.JOBS:
			case ResourceMode.SKILLS:
				// Extract the worker from the event and use as the resource identifier
				setCalEvents(() =>
					propEvents.map((event: any) => {
						event.resource_orig = event.resource;
						event.resource = event.worker_id;
						return event;
					})
				);

				// Create an array of workers for the resources information
				let tmpWorkers: Array<any> = [];
				propEvents[0].attendees.forEach((attendee: any) => {
					// Check this worker is present in at least one of the events
					if (propEvents.some((pe: any) => pe.worker_id === attendee.worker_id)) {
						tmpWorkers.push({
							id: attendee.worker_id,
							name: attendee.name,
							worker_photo: attendee.worker_photo,
						});
					}
				});

				// Set the calendar options to use resources
				setWorkers(() => {
					setOtherCalendarOptions({
						resources: tmpWorkers,
						renderResource: (resource: MbscResource) => {
							return (
								<div className='resource-title'>
									<Avatar data={resource} />
									<div>{resource.name}</div>
								</div>
							);
						},
					});
					return tmpWorkers;
				});
				break;
		}
	};

	const handleModalOnDidDismiss = () => {
		// Run external functions
		props.onDidDismiss();

		// Reset internal state
		setIsLoading(false);
		setTitle('');
		setCalEvents([]);
		setWorkers([]);
		setOtherCalendarOptions({});
	};

	const handleOnEventRightClick = useCallback((e: any, inst: Eventcalendar) => {
		e.domEvent.preventDefault();

		// Disable right-clicks on other events
		if (e.event.enumEventType !== EventType.APPOINTMENT) return false;

		// Open the context menu
		setContextMenuAnchor(e.domEvent.target);
		setContextMenuRightClickedEvent(e);
		setTimeout(() => setContextMenuOpen(true));
	}, []);

	return (
		<IonModal
			class={`scheduling-day-schedule-modal resource-mode-${state.resourceMode}`}
			style={{ '--width': '90vw', '--height': '90vh' }}
			isOpen={props.isOpen}
			onDidPresent={() => onDidPresent()}
			onDidDismiss={handleModalOnDidDismiss}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{title}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => props.onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{isLoading && <Loading overlay={true} />}
				<div className='cal-container'>
					<Eventcalendar
						data={calEvents}
						height={'calc(100% + 130px)'}
						defaultSelectedDate={props.selectedDate}
						view={{
							schedule: {
								type: 'day',
								allDay: false,
							},
						}}
						renderHeader={() => null}
						dragToMove={props.permissionTo('update')}
						dragToResize={props.permissionTo('update')}
						dragTimeStep={15}
						onEventUpdate={(event: any) => props.handleUpdateEvent(event, setIsLoading)}
						onEventRightClick={handleOnEventRightClick}
						{...otherCalendarOptions}
					/>

					<CalendarContextMenu
						options={contextMenuOptions}
						contextMenuOpen={contextMenuOpen}
						contextMenuAnchor={contextMenuAnchor}
						setContextMenuInst={setContextMenuInst}
						setContextMenuOpen={setContextMenuOpen}
						setContextMenuRightClickedEvent={setContextMenuRightClickedEvent}
					/>
				</div>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='secondary' onClick={() => props.onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default DayScheduleModal;

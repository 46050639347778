export enum RouteStatus {
	INCOMPLETE = 'incomplete',
	PARTCOMPLETE = 'partcomplete',
	COMPLETE = 'complete',
}

export interface RoutesAuth {
	title: string;
	pathname: string;
	component: React.FC;
	toolbar: boolean;
}

export interface RoutesExternal {
	title: string;
	pathname: string;
	component: React.FC;
	toolbar: boolean;
}

export interface RoutesMetaData {
	uid: string;
	icon: any;
	component: any;
	pathIds?: Array<string>;
	badge?: React.FC<any>;
	submenu?: boolean;
	status?: RouteStatus;
}

import { Eventcalendar, localeEnGB } from '@mobiscroll/react';
import '@mobiscroll/print/dist/css/mobiscroll.scss';

const Timeline = (props: any) => {
	const eventCalendarProps: any = { ...props };

	// Apply non-default props
	if (!props.view) {
		// Default view if none specified
		eventCalendarProps.view = {
			timeline: {
				type: 'month',
			},
		};
	}

	// Extract the ref and apply
	if (props.hasOwnProperty('innerRef')) {
		eventCalendarProps.ref = eventCalendarProps.innerRef;
		delete eventCalendarProps.innerRef;
	}

	return (
		<div className='prop-timeline'>
			<Eventcalendar
				theme='material'
				themeVariant='light'
				locale={localeEnGB}
				showEventTooltip={false}
				{...eventCalendarProps}
			/>
		</div>
	);
};

export default Timeline;

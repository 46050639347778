import React, {
	FC,
	createElement,
	forwardRef,
	isValidElement,
	useCallback,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	GoogleMap,
	HeatmapLayer,
	Marker,
	InfoWindow,
	Polyline,
	DrawingManager,
	Polygon,
	Circle,
	MarkerClusterer,
} from '@react-google-maps/api';
import { faCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { getDistance } from 'geolib';

import {
	Coordinates,
	MapStyles,
	PropMarker,
	PropCluster,
	MapControls,
} from '../../interfaces/Maps/MapsInterface';
import MapControl from './MapControl';
import { googleMapsContext } from '../../contexts/GoogleMapsContext';
import styles from './../../assets/scss/styles.module.scss';
import { DateTime } from 'luxon';
import { getBoundsZoomLevel } from '../../helpers/geo';


interface Props {
	center: Coordinates;
	zoom?: number;
	markers?: Array<PropMarker>;
	markerClusters?: Array<PropCluster>;
	heatmap?: Array<google.maps.LatLng>;
	route?: Array<google.maps.LatLng>;
	onMapIdle?: Function;
	onMapLoad?: Function;
	onMapClick?: Function;
	onMapZoom?: Function;
	onMapDragEnd?: Function;
	styles?: MapStyles;
	controls?: Array<MapControls>;
	mapStyle?: google.maps.MapTypeStyle[];
	polygons?: Array<Array<google.maps.LatLng>>;
	drawPolygons?: boolean;
	drawMultiple?: boolean;
	onPolygonDrawn?: Function;
}

const Map = forwardRef<any, Props>((props: Props, ref) => {
	const googleMapsCxt = useContext<any>(googleMapsContext);
	const [zoom, setZoom] = useState<number>(14);
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [drawPolygons, setDrawPolygons] = useState<boolean>(props.drawPolygons ?? false);
	const [polygons, setPolygons] = useState<Array<Array<google.maps.LatLng>> | undefined>(props.polygons);
	const [markers, setMarkers] = useState<Array<PropMarker> | undefined>(props.markers);
	const [replacementClusterMarkers, setReplacementClusterMarkers] = useState<
		Array<PropMarker> | undefined
	>([]);
	const [markerClusters, setMarkerClusters] = useState<Array<PropCluster> | undefined>(
		props.markerClusters
	);
	const [heatmap, setHeatmap] = useState<Array<google.maps.LatLng> | undefined>(props.heatmap);
	const [route, setRoute] = useState<Array<google.maps.LatLng> | undefined>(props.route);
	const [controls, setControls] = useState<Array<MapControls> | undefined>(props.controls);
	const divRef = useRef<HTMLDivElement>(null);

	const centerOptions = useMemo(() => ({ lat: props.center.lat ?? 0, lng: props.center.lng ?? 0 }), [props.center])

	const containerStyle = {
		height: '100%',
	};

	const clusterOptions = {
		gridSize: 20,
		//minimumClusterSize: 5,
	};

	useImperativeHandle(ref, () => ({
	}));

	useEffect(() => {
		if (props.zoom) {
			setZoom(props.zoom);
		}
	}, [props.zoom]);

	useEffect(() => {
		setControls(props.controls);
	}, [props.controls]);

	useEffect(() => {
		setDrawPolygons(props.drawPolygons ?? false);
	}, [props.drawPolygons]);

	useEffect(() => {
		setMarkers(props.markers);
	}, [props.markers]);

	useEffect(() => {
		setPolygons(props.polygons);
	}, [props.polygons]);

	useEffect(() => {
		setMarkerClusters(props.markerClusters);
	}, [props.markerClusters]);

	useEffect(() => {
		setRoute(props.route);
	}, [props.route]);

	useEffect(() => {
		setHeatmap(props.heatmap);
	}, [props.heatmap]);

	const handleMapLoaded = (map: google.maps.Map) => {
		setMap(map);
		if (props.onMapLoad !== undefined) {
			props.onMapLoad(map);
		}
	};

	const markerClick = useCallback(
		(marker: any, type: 'marker' | 'cluster') => {
			if (markers !== undefined && type === 'marker') {
				let localMarkers = [...markers];
				const index = localMarkers.findIndex((m: any) => m.title === marker.title);
				if (localMarkers[index].canOpen === true) {
					localMarkers[index].isOpen = true;
					setMarkers(localMarkers);
				}
			}
			if (markerClusters !== undefined && type === 'cluster') {
				let localMarkers = [...markerClusters];
				const index = localMarkers[0].markers.findIndex((m: any) => m.title === marker.title);
				if (localMarkers[0].markers[index].canOpen === true) {
					localMarkers[0].markers[index].isOpen = true;
					setMarkerClusters(localMarkers);
				}
			}
		},
		[markers, markerClusters]
	);

	const markerClose = (marker: any, type: 'marker' | 'cluster') => {
		if (markers !== undefined && type === 'marker') {
			let localMarkers = [...markers];
			const index = localMarkers.findIndex((m: any) => m.title === marker.title);
			localMarkers[index].isOpen = false;
			setMarkers(localMarkers);
		}
		if (markerClusters !== undefined && type === 'cluster') {
			let localMarkers = [...markerClusters];
			const index = localMarkers[0].markers.findIndex((m: any) => m.title === marker.title);
			localMarkers[0].markers[index].isOpen = false;
			setMarkerClusters(localMarkers);
		}
	};

	const renderControl = (control: MapControls) => {
		if (isValidElement(control.component)) {
			return control.component;
		} else {
			return createElement(control.component as FC, control.data);
		}
	};

	const renderInfo = (marker: PropMarker) => {
		if (isValidElement(marker.infoComponent)) {
			return marker.infoComponent;
		} else if (marker.infoComponent) {
			return createElement(marker.infoComponent as FC, marker.infoData ?? {});
		} else {
			return (
				<>
					<div style={props.styles?.marker?.title}>{marker.title}</div>
					{marker.description !== undefined && (
						<div style={props.styles?.marker?.description}>
							{marker.description}
							{'\n'}Accuracy: {marker.radius}
						</div>
					)}
				</>
			);
		}
	};

	const clusterEnd = (clusterer: any, cluster: PropCluster, index: number) => {
		if (markerClusters !== undefined) {
			let lMarkers = [...cluster.markers];

			// code to ensure that radius is hidden if marker is hidden
			lMarkers.forEach((marker: PropMarker, i) => {
				let mindex = clusterer.markers.findIndex((m: any) => {
					return m.getMap() !== null && m.getTitle() === marker.title;
				});
				if (mindex > -1) {
					lMarkers[i].isVisible = true;
				} else {
					lMarkers[i].isVisible = false;
				}
			});
			let lClusters = [...markerClusters];
			lClusters[index].markers = lMarkers;
			setMarkerClusters(lClusters);

			// replacement cluster marker
			let replace: Array<PropMarker> = [];
			clusterer.clusters.forEach((c: any) => {
				if (
					c.getMarkers().filter((ma: any) => {
						return ma.map === null;
					}).length > 1
				) {
					let distance = getDistance(
						{
							latitude: c.getBounds().getSouthWest().lat(),
							longitude: c.getBounds().getSouthWest().lng(),
						},
						{
							latitude: c.getBounds().getNorthEast().lat(),
							longitude: c.getBounds().getNorthEast().lng(),
						}
					);
					replace.push({
						title: String(
							c.getMarkers().filter((ma: any) => {
								return ma.map === null;
							}).length
						),
						icon: {
							path: String(faCircle.icon[4]),
							scale: 0.125,
							anchor: new google.maps.Point(
								faCircle.icon[0] / 2, // width
								faCircle.icon[1] / 2 // height
							),
							fillColor: styles.dark,
							fillOpacity: 1,
							strokeColor: '',
							strokeWeight: 0,
							labelOrigin: new google.maps.Point(
								faCircle.icon[0] / 2, // width
								(faCircle.icon[1] / 2) * 1.5 // height
							),
						},
						latlng: {
							lat: c.getCenter().lat(),
							lng: c.getCenter().lng(),
						},
						showRadius: true,
						accuracyFill: styles.dark,
						accuracyStroke: styles.dark,
						radius: distance,
						canOpen: false,
						isOpen: false,
						date: DateTime.now(),
						onClick: () => {
							map?.setCenter(c.getCenter());
							let localZoom = getBoundsZoomLevel(
								{
									northeast: c.getBounds().getNorthEast(),
									southwest: c.getBounds().getSouthWest(),
								},
								{
									height: map?.getDiv().clientHeight ?? 0,
									width: map?.getDiv().clientWidth ?? 0,
								}
							);
							setZoom(localZoom);
						}
					});

					// hide old cluster icon
					setTimeout(() => {
						c.clusterIcon.hide();
					}, 100);
				}
			});
			setReplacementClusterMarkers(replace);
		}
	};

	const polygonComplete = (polygon: google.maps.Polygon) => {
		const coords: Array<google.maps.LatLng> = [];
		for (var i = 0; i < polygon.getPath().getLength(); i++) {
			coords.push(new google.maps.LatLng(
				Number(polygon.getPath().getAt(i).lat()),
				Number(polygon.getPath().getAt(i).lng())
			));
		}
		if (props.onPolygonDrawn) {
			props.onPolygonDrawn(coords);
		}
		setPolygons(prevValue => {
			if (prevValue === undefined) {
				prevValue = [];
			}
			if (props.drawMultiple !== true) {
				prevValue = [];
			}
			prevValue?.push(coords);
			return prevValue;
		});
		polygon.setMap(null);
	};

	const markerDrag = (marker: PropMarker, event: google.maps.MapMouseEvent) => {
		let index = markers?.findIndex((mark: PropMarker) => marker.title === mark.title) ?? -1;
		if (index > -1) {
			setMarkers((prevMarkers) => {
				if (prevMarkers !== undefined) {
					let localMarkers = [...prevMarkers];
					localMarkers[index].latlng = {
						lat: Number(event.latLng?.lat() ?? 0),
						lng: Number(event.latLng?.lng() ?? 0),
					}
					return localMarkers;
				}
				return prevMarkers;
			});
		}
	}

	const markerDragEnd = (marker: PropMarker, event: google.maps.MapMouseEvent) => {
		let index = markers?.findIndex((mark: PropMarker) => marker.title === mark.title) ?? -1;
		if (index > -1) {
			setMarkers((prevMarkers) => {
				if (prevMarkers !== undefined) {
					let localMarkers = [...prevMarkers];
					localMarkers[index].latlng = {
						lat: Number(event.latLng?.lat() ?? 0),
						lng: Number(event.latLng?.lng() ?? 0),
					};
					return localMarkers;
				}
				return prevMarkers;
			});
			setTimeout(() => {
				if (marker.onDragEnd) {
					marker.onDragEnd(marker, {
						lat: Number(event.latLng?.lat() ?? 0),
						lng: Number(event.latLng?.lng() ?? 0),
					});
				}
			}, 200);
		}
	};

	return (
		<>
			<div className='prop-map' ref={divRef}>
				{googleMapsCxt.isLoaded ? (
					<GoogleMap
						options={{
							styles: props.mapStyle ?? null,
							mapTypeControlOptions: { position: google.maps.ControlPosition.BOTTOM_RIGHT },
						}}
						mapContainerStyle={containerStyle}
						center={centerOptions}
						zoom={zoom}
						onClick={(event: google.maps.MapMouseEvent) => {
							if (props.onMapClick !== undefined) {
								props.onMapClick(event);
							}
						}}
						onZoomChanged={() => {
							if (map) {
								let gotZoom = map.getZoom();
								if (gotZoom) {
									setZoom(gotZoom);
								}
								if (props.onMapZoom !== undefined) {
									props.onMapZoom(gotZoom);
								}
							}
						}}
						onDragEnd={() => {
							if (props.onMapDragEnd !== undefined) {
								props.onMapDragEnd();
							}
						}}
						onIdle={() => {
							if (props.onMapIdle !== undefined) {
								props.onMapIdle();
							}
						}}
						onLoad={(map: google.maps.Map) => handleMapLoaded(map)}
					>
						{replacementClusterMarkers &&
							replacementClusterMarkers.map((marker: PropMarker, index: number) => (
								<Marker
									key={'marker-' + index}
									position={marker.latlng}
									label={{ color: '#ffffff', text: marker.title, fontSize: '18px' }}
									icon={marker.icon}
									zIndex={10000}
									onClick={() => {
										if (marker.onClick) {
											marker.onClick();
										}
									}}
								>
									<Circle
										center={marker.latlng}
										radius={marker.radius}
										options={{
											fillColor: marker.accuracyFill ?? 'rgba(26, 115, 232,0.5)',
											strokeColor: marker.accuracyStroke ?? 'rgba(26, 115, 232,0.5)',
										}}
									/>
								</Marker>
							))}
						{replacementClusterMarkers &&
							replacementClusterMarkers.map((marker: PropMarker, index: number) => (
								<Marker
									key={'user-' + index}
									position={marker.latlng}
									icon={{
										path: String(faUsers.icon[4]),
										scale: 0.04,
										anchor: new google.maps.Point(
											faUsers.icon[0] / 2, // width
											faUsers.icon[1] * 1.2 // height
										),
										fillColor: '#FFFFFF',
										fillOpacity: 1,
										strokeColor: '',
										strokeWeight: 0,
									}}
									onClick={() => {
										if (marker.onClick) {
											marker.onClick();
										}
									}}
									zIndex={10001}
								></Marker>
							))}

						{markerClusters !== undefined &&
							markerClusters.map((cluster: PropCluster, index: number) => (
								<MarkerClusterer
									key={index}
									options={clusterOptions}
									averageCenter={true}
									onClusteringEnd={(clusterer) => {
										clusterEnd(clusterer, cluster, index);
									}}
									styles={[
										{
											url: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png',
											height: 1,
											width: 1,
										},
									]}
								>
									{(clusterer) => (
										<>
											{cluster.markers.map((marker: PropMarker, index) => (
												<Marker
													key={'marker-' + index}
													onClick={(e: google.maps.MapMouseEvent) => {
														if (marker.onOpen !== undefined) {
															marker.onOpen(e, marker);
														}
														markerClick(marker, 'cluster');
													}}
													clusterer={clusterer}
													position={marker.latlng}
													title={marker.title}
													icon={marker.icon}
													label={marker.label}
													draggable={marker.draggable ?? false}
													onDrag={(event) => {
														markerDrag(marker, event);
													}}
													onDragEnd={(event) => {
														markerDragEnd(marker, event);
													}}
													zIndex={9999}
												>
													{marker.showRadius === true && marker.isVisible === true && (
														<Circle
															key={'circle-' + index}
															center={marker.latlng}
															radius={marker.radius}
															options={{
																fillColor: marker.accuracyFill ?? 'rgba(26, 115, 232,0.5)',
																strokeColor: marker.accuracyStroke ?? 'rgba(26, 115, 232,0.5)',
															}}
														/>
													)}
													{marker.isOpen ? (
														<InfoWindow
															onCloseClick={() => {
																if (marker.onClose !== undefined) {
																	marker.onClose(marker);
																}
																markerClose(marker, 'cluster');
															}}
														>
															{renderInfo(marker)}
														</InfoWindow>
													) : null}
												</Marker>
											))}
										</>
									)}
								</MarkerClusterer>
							))}
						{controls !== undefined &&
							controls !== null &&
							controls.map((control: MapControls, index: number) => {
								return (
									<MapControl key={index} position={control.position} style={control.style}>
										{renderControl(control)}
									</MapControl>
								)
							})}
						{heatmap !== undefined && heatmap !== null && heatmap.length > 0 ? (
							<HeatmapLayer data={heatmap}></HeatmapLayer>
						) : null}
						{markers !== undefined &&
							markers.map((marker: PropMarker, index: number) => (
								<div key={index}>
									<Marker
										onClick={(e: google.maps.MapMouseEvent) => {
											if (marker.onOpen !== undefined) {
												marker.onOpen(e, marker);
											}
											markerClick(marker, 'marker');
										}}
										position={marker.latlng}
										title={marker.title}
										icon={marker.icon}
										label={marker.label}
										draggable={marker.draggable ?? false}
										onDrag={(event) => {
											markerDrag(marker, event);
										}}
										onDragEnd={(event) => {
											markerDragEnd(marker, event);
										}}
										zIndex={9999}
									>
										{marker.isOpen ? (
											<InfoWindow
												onCloseClick={() => {
													if (marker.onClose !== undefined) {
														marker.onClose(marker);
													}
													markerClose(marker, 'marker');
												}}
											>
												{renderInfo(marker)}
											</InfoWindow>
										) : null}
										{marker.showRadius === true && (
											<Circle
												center={marker.latlng}
												radius={marker.radius}
												options={{
													fillColor: marker.accuracyFill ?? 'rgba(26, 115, 232,0.5)',
													strokeColor: marker.accuracyStroke ?? 'rgba(26, 115, 232,0.5)',
												}}
											/>
										)}
									</Marker>
								</div>
							))}
						{route !== undefined &&
							route.map((line: any, index: number) => (
								<Polyline
									key={index}
									path={line.coordinates}
									options={{
										strokeColor: line.type === 'kalman' ? '#f6ff00' : '#1B248A',
										strokeOpacity: 0.8,
										strokeWeight: 4,
										visible: true,
										draggable: false,
										editable: false,
									}}
								></Polyline>
							))}
						{polygons !== undefined &&
							polygons.map((polygon: Array<google.maps.LatLng>, index: number) => (
								<Polygon
									key={index}
									path={polygon}
									options={{
										fillColor: styles.primary,
										strokeColor: styles.primary,
									}}
								/>
							))}

						{drawPolygons ? (
							<DrawingManager
								drawingMode={google.maps.drawing.OverlayType.POLYGON}
								options={{
									drawingControl: false,
									polygonOptions: {
										fillColor: styles.primary,
										fillOpacity: 1,
										strokeColor: styles.primary,
										strokeOpacity: 1,
										strokeWeight: 3,
									},
								}}
								onPolygonComplete={(polygon: google.maps.Polygon) => {
									polygonComplete(polygon);
								}}
							/>
						) : null}
					</GoogleMap>
				) : (
					''
				)}
			</div>
		</>
	);
});

export default Map;

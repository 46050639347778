import { IonFabButton } from '@ionic/react';
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { DateTime } from 'luxon';

import { authContext } from "../../contexts/AuthContext";
import { useInterval } from '../../utils/custom-hooks/useInterval';
import { publish } from '../../helpers/events';
import NotificationsBadge from './NotificationsBadge';
import { isset } from '../../helpers/typeFunctions';
import AllocationsPage from '../Overlays/Allocations/Allocations';

interface Props {
	onClick?: MouseEventHandler<HTMLIonFabButtonElement> | undefined;
	className?: string;
	flash?: boolean;
}

const Avatar = (props: Props) => {
	const authCtx: any = useContext(authContext);
	const initials = (authCtx.user.first_name.substring(0, 1) + authCtx.user.last_name.substring(0, 1)).toUpperCase();
	const [flash, setFlash] = useState<boolean>(false);
	const [delay, setDelay] = useState<number|null>(null);
	const [color, setColor] = useState<string>('secondary');

	const [showAllocations, setShowAllocations] = useState<boolean>(false);
	const [allocationDate, setAllocationDate] = useState<DateTime>();
	const [minHours, setMinHours] = useState<number>(0);

	useEffect(() => {
		if (isset(authCtx.user.monthly_allocation_start) && authCtx.user.monthly_allocation_start !== false) {
			setAllocationDate(DateTime.fromISO(authCtx.user.monthly_allocation_start));
			setMinHours(authCtx.user.monthly_allocation_amount);
			setShowAllocations(true);
		}
	}, []);

	useEffect(() => {
		setFlash(props.flash ?? false);
	}, [props.flash])

	useEffect(() => {
		if (flash) {
			setDelay(500);
		} else {
			setDelay(null);
			setColor('secondary');
		}
	}, [flash])

	useInterval(() => {
		if (color === 'secondary') {
			setColor('danger');
		} else {
			setColor('secondary');
		}
	}, delay);

	return (
		<>
			<IonFabButton
				color={color}
				className={`title-avatar${props.className ? ' ' + props.className : ''}`}
				onClick={(e) => {
					if (props.onClick) {
						props.onClick(e);
					}
					publish('alert:worker', authCtx.user);
				}}
			>
				{initials}
			</IonFabButton>
			<NotificationsBadge />
			<AllocationsPage
				date={allocationDate}
				visible={showAllocations}
				minHours={minHours}
				onClose={() => {
					setShowAllocations(false);
				}}
			/>
		</>
	);
};

export default Avatar;

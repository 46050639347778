import debounce from 'lodash.debounce';
import axios from '../../lib/axios';

const JobSkillsOptions = debounce((inputValue: string, callback: any, includeCodes: boolean) => {
	axios({
		method: 'get',
		url: '/api/utilities/workers/job_skills',
		data: {
			name: inputValue,
		},
	}).then((res) => {
		const options = res.data
			.map((r: any) => {
				return { label: `${includeCodes ? r.code + ' - ' : ''}${r.skill}`, value: r._id };
			})
			.sort((a: any, b: any) => {
				return a.label > b.label ? 1 : -1;
			});

		callback(options);
	});
}, 600);

export default JobSkillsOptions;

import { ActionType } from './actions';
import axios from '../../../lib/axios';
import { EstimateItem } from './estimate-types';

export const calculateEstimateAmount = (
	taxExclusive: boolean,
	items: EstimateItem[],
	dispatch: Function,
	isLoading?: Function
) => {
	// Set as loading
	dispatch({
		type: ActionType.SET_ESTIMATE_TOTALS_LOADING,
		payload: true,
	});

	// Parse the items
	const itemsParsed: any = items.map((item: EstimateItem) => {
		return {
			quantity: Number(item.quantity),
			price: Number(item.price),
			discount: Number(item.discount),
			tax_rate_id: item.taxRateId,
		};
	});

	// Create the calculation payload
	const payload: any = {
		tax_exclusive: taxExclusive,
		items: itemsParsed,
	};

	axios
		.post('/api/jobs/items/calculate', payload)
		.then((res: any) => {
			dispatch({
				type: ActionType.UPDATE_ESTIMATE_TOTALS,
				payload: {
					subtotal: res.data.subtotal,
					discount: res.data.discount,
					total: res.data.total,
					vat: res.data.vat,
				},
			});
		})
		.catch(() => {})
		.finally(() => {
			dispatch({
				type: ActionType.SET_ESTIMATE_TOTALS_LOADING,
				payload: false,
			});

			if (isLoading) isLoading(false);
		});
};

export const buildClientDetails = (data: any): string => {
	const cd: Array<any> = [];
	if (data.client?.company_name) cd.push(data.client.company_name);
	if (data.client?.address?.address_1) cd.push(data.client.address.address_1);
	if (data.client?.address?.address_2) cd.push(data.client.address.address_2);
	if (data.client?.address?.town) cd.push(data.client.address.town);
	if (data.client?.address?.postcode) cd.push(data.client.address.postcode);
	if (data.client?.first_name && data.client?.last_name)
		cd.push(`${data.client.first_name} ${data.client.last_name}`);
	if (data.client?.email) cd.push(data.client.email);
	if (data.client?.mobile) cd.push(data.client.mobile);

	return cd.join('\n');
};

import debounce from 'lodash.debounce';
import axios from '../../lib/axios';
import { JobStatus } from '../../pages/Jobs/Jobs/job-types';

const JobsOptions = debounce(
	(inputValue: string, callback: any, includeNumbers?: boolean, status?: JobStatus) => {
		let payload: any = {};
		if (inputValue.length > 0) payload.search = inputValue;
		if (status) payload.status = status;

		axios({
			method: 'post',
			url: '/api/jobs/jobs_list',
			data: payload,
		}).then((res) => {
			const options = res.data
				.sort((a: any, b: any) => {
					let sort: any = null;

					if (includeNumbers && a.hasOwnProperty('number') && b.hasOwnProperty('number')) {
						sort = Number(a.number) > Number(b.number) ? 1 : -1 || a.label.localeCompare(b.label);
					} else {
						sort = a.label.localeCompare(b.label);
					}

					return sort;
				})
				.map((r: any) => {
					return { label: `${includeNumbers ? r.number + ': ' : ''}${r.name}`, value: r.id };
				});

			callback(options);
		});
	},
	600
);

export default JobsOptions;

import { useState, useEffect, useRef } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { IonCard, IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '../../../../components/Forms/FormFields';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import Form from '../../../../components/Forms/Form';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import FilterMenu from '../../../../components/Menu/FilterMenu';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';

const WorkSuspendedReasonsIndex: React.FC<RouteIndexComponent> = ({
	uid,
	routeTitle,
	permissionTo,
}) => {
	const gridRef: any = useRef<any>();
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [jobTypesLoading, setJobTypesLoading] = useState<boolean>(true);
	const [jobTypeID, setJobTypeID] = useState<string>('');
	const [jobTypes, setJobTypes] = useState<Array<any>>([]);
	const [presentAlert] = useIonAlert();

	useEffect(() => {
		loadJobTypes();
	}, []);

	useEffect(() => {
		if (jobTypesLoading === false) {
			loadGridData(null, '');
		}
	}, [jobTypesLoading]);

	const columns = [
		{
			headerName: 'Suspended Reasons',
			field: 'reason',
			sortable: true,
			sort: 'asc',
			flex: 1,
			cellRenderer: (params: any) => {
				return (
					<Form
						array={[
							{
								id: `item-${params.data._id}`,
								title: 'Suspended Reasons',
								type: 'text',
								value: params.data.reason,
								db: ['reason'],
							},
						]}
						forceEdit={permissionTo('update')}
						noButton={true}
						tableMode={true}
						permissionTo={permissionTo}
						endpoint={`/api/utilities/jobs/work_suspended_reasons`}
						endpointID={params.data._id}
					/>
				);
			},
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 60,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data._id)}
						/>
					)
				);
			},
		},
	];

	const loadJobTypes = () => {
		setJobTypesLoading(true);
		axios.get('/api/utilities/jobs/job_types').then((res) => {
			const result = res.data.map((d: any) => {
				return {
					_id: d._id,
					label: d.type,
				};
			});

			setJobTypes([{ _id: '', label: 'Default All Jobs' }, ...result]);
			setJobTypesLoading(false);
		});
	};

	const loadGridData = (event: any, filterJobTypeID: any) => {
		setData([]);
		setGridLoading(true);
		setJobTypeID(filterJobTypeID);

		// Reset the search
		gridRef.current.clearSearch();

		const payload = filterJobTypeID.length > 0 ? { job_type_id: filterJobTypeID } : {};

		axios.post('/api/utilities/jobs/work_suspended_reasons', payload).then((res) => {
			setData(res.data);
			setGridLoading(false);
		});
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		axios
			.delete(`/api/utilities/jobs/work_suspended_reasons/${id}`)
			.then((res) => {
				// Capture any grid changes and update state with those and the deletion
				let rowData: Array<any> = data;

				// Update grid row items to prevent value flipping
				rowData = updateRow(rowData);

				// Remove item
				rowData = rowData.filter((d) => d._id !== id);

				setData(rowData);

				showToast('deleted', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const handleCreate = () => {
		const parent = document.getElementById('add-suspended-reason') as HTMLElement;
		const value = parent.getElementsByTagName('input')[0].value.toString();

		if (!value || parent.classList.contains('is-invalid')) {
			showToast('error', 'Please enter a new suspended reason');
			return;
		}

		const toastID = toast.loading('Please wait...');

		let payload: any = { reason: value };
		if (jobTypeID.length > 0) payload = { ...payload, job_type_id: jobTypeID };

		axios
			.put('/api/utilities/jobs/work_suspended_reasons', payload)
			.then((res) => {
				// Capture any grid changes and update state with those and the new item
				let rowData: Array<any> = data;

				// Update grid row items to prevent value flipping
				rowData = updateRow(rowData);

				// Add item
				setData([...rowData, { _id: res.data._id, reason: value }]);

				showToast('saved', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const updateRow = (rowData: Array<any>) => {
		if (permissionTo('update')) {
			rowData.map((a: any) => {
				const gridElement = document.getElementById(`item-${a._id}`) as HTMLElement;
				const gridElementValue = gridElement.getElementsByTagName('input')[0].value.toString();
				a.reason = gridElementValue;
				return a;
			});
		}

		return rowData;
	};

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonRow>
				<IonCol size={'2'} className='pt-0'>
					<FilterMenu loading={jobTypesLoading} items={jobTypes} handleOnClick={loadGridData} />
				</IonCol>
				<IonCol size={'10'} className='p-0'>
					<IonCard className='table-card filter-data-table full-height-card'>
						<DataGrid
							ref={gridRef}
							title={`${routeTitle}: ${
								jobTypes.filter((a) => a._id === jobTypeID)[0]?.label ?? 'Loading...'
							}`}
							cols={columns}
							data={data}
							autoSize={true}
							extraFooter={
								permissionTo('create') && (
									<IonRow>
										<IonCol size={'10'} className='ps-0'>
											<TextField
												id='add-suspended-reason'
												title={'Add a new Suspended Reason'}
												value={''}
												editMode={true}
												handleUpdateValue={handleCreate}
												noForm={true}
											/>
										</IonCol>
										<IonCol size={'2'} className='text-right pe-0'>
											<IonButton
												color='success'
												onClick={() => handleCreate()}
												disabled={gridLoading}
											>
												Create
											</IonButton>
										</IonCol>
									</IonRow>
								)
							}
						/>
					</IonCard>
				</IonCol>
			</IonRow>
		</>
	);
};

export default WorkSuspendedReasonsIndex;

import { IonButton, IonCheckbox, IonItem, IonLabel, IonList } from '@ionic/react';
import { toast } from 'react-toastify';

import axios from '../../../../lib/axios';
import { useState, useEffect, useContext } from 'react';
import Loading from '../../../../components/UI/Loading';
import { showToast } from '../../../../lib/toast';
import { moduleContext } from '../../../../contexts/ModuleContext';

import './SecuritySettings.scss';

interface Props {
	mode: string;
	roleID?: string;
	workerID?: string;
	permissionTo: Function;
	hideOverlay: Function;
	securitySettingName?: string;
	securitySettingColour?: string;
	handleUpdate: Function;
	setOverlayFooterContent: Function;
}

const SecuritySettingsTabs: React.FC<Props> = ({
	mode,
	roleID,
	workerID,
	permissionTo,
	hideOverlay,
	securitySettingName,
	securitySettingColour,
	handleUpdate,
	setOverlayFooterContent,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [appsArray, setAppsArray] = useState<Array<any>>([]);
	const [securitySettingNameValue, setSecuritySettingNameValue] = useState<string>(`${securitySettingName}`);
	const [securitySettingColourValue, setSecuritySettingColourValue] = useState<string>(`#${securitySettingColour}`);
	const [id, setId] = useState<string>('');

	useEffect(() => {
		const init = async () => {
			let permissions: Array<any> = [];
			let response: any;
			switch (mode) {
				case 'template':
					response = await axios.get(`/api/utilities/security_settings/apps/${roleID}`);
					permissions = response.data.apps;
					setId(response.data._id);
					break;
				case 'worker':
					// Load the all worker's permissions
					response = await axios.get(`/api/utilities/security_settings/apps/${roleID}/worker/${workerID}`);
					permissions = response.data.apps;
					setId(response.data._id);
					break;
				default:
					break;
			}
			// Load the app data
			axios.get('/api/system/apps').then((data) => {
				let apps = data.data.map((item: any, index: number) => {
					let result = permissions.find((permission: any) => {
						return permission.app_id === item._id;
					});
					item.checked = result.checked ?? false;
					return item;
				});
				setAppsArray(apps);
				setIsLoading(false);
			});
		}
		init();
	}, []);

	useEffect(() => {
		switch (mode) {
			case 'worker':
			case 'template':
				setOverlayFooterContent({
					content: (
						<>
							<div className="footer-controls justify-right">
								<IonButton color="tertiary" onClick={() => hideOverlay()}>Close</IonButton>
								{permissionTo('update') && (
									<IonButton color="primary" onClick={handleSaveChanges}>Save Changes</IonButton>
								)}
							</div>
						</>
					)
				});
				break;
			default:
				break;
		}
	})

	const onItemChecked = (e:any, i:number) => {
		setAppsArray((prevValue) => {
			let arr = [...prevValue];
			arr[i].checked = e.detail.checked;
			return arr;
		})
	}

	const handleSaveChanges = () => {
		const toastID = toast.loading("Please wait...");

		// Parse the permissions
		let permissions: Array<any> = [];

		appsArray.forEach((item: any) => {
			if (item.checked) {
				permissions.push(item._id);
			}
		});

		// Settings based on mode
		let url = '/api/utilities/security_settings';
		let forRoleID: any = '';
		switch (mode) {
			case 'template':
				forRoleID = roleID;
				url = `${url}/apps/${forRoleID}`;
				break;
			case 'worker':
				forRoleID = id;
				url = `${url}/apps/${forRoleID}`;
				break;
			default:
				break;
		}

		axios.put(
			url,
			{
				apps: permissions,
				security_setting_name: securitySettingNameValue,
				security_setting_colour: securitySettingColourValue,
			}
		).then((res) => {
			showToast("saved", null, toastID);

			// Update the grid data
			switch (mode) {
				case 'template':
					handleUpdate({
						securitySettingName: securitySettingNameValue,
						securitySettingColour: securitySettingColourValue
					}, forRoleID);
					break;
				case 'worker':
					moduleCtx.getSecuritySettingsTemplatesOptions(workerID).then((res: any) => {
						handleUpdate({ value: forRoleID }, workerID, res);
					});
					break;
			}
		}).catch((err) => {
			showToast("error", null, toastID);
		});
	}

	return (
		<>
			{isLoading && <Loading />}
			{!isLoading && (
				<>
					{appsArray.map((app: any, i: number) => {
						return (
							<IonItem key={i} className='app-item'>
								<IonLabel>{app.name}</IonLabel>
								<IonCheckbox
									slot="end"
									checked={app.checked}
									onIonChange={(e) => onItemChecked(e, i)}></IonCheckbox>
							</IonItem>
						);
					})}
				</>
			)}
		</>
	);
};

export default SecuritySettingsTabs;

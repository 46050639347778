import { Table, TableRow, TableCell, TableBody } from '@mui/material';
import FilterButtons from '../../../../components/Forms/FilterButtons';
import { useState } from 'react';
import Loading from '../../../../components/UI/Loading';
import axios from '../../../../lib/axios';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

interface Props {
	parentRef: any;
	title: string;
	uid: string;
	permissionTo: Function;
}

const AssessmentIndex: React.FC<Props> = (props: Props) => {
	const { workerId }: any = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const defaultData: any = {
		late_start_early_finish: {
			late_start_occurrence: 0,
			early_finish_occurrence: 0,
			time_lost_string: '00:00:00',
			league_position: 0,
		},
		early_start_late_finish: {
			early_start_occurrence: 0,
			late_finish_occurrence: 0,
			time_gained_string: '00:00:00',
			league_position: 0,
		},
		failed_to_record: {
			failed_to_record_occurrence: 0,
			league_position: 0,
		},
		stationary_phone: {
			days: 0,
			league_position: 0,
		},
		kpi: {
			kpi_value: 0,
			league_position: 0,
		},
		phone_usage: {
			seconds: 0,
			league_position: 0,
		},
	};
	const [data, setData] = useState<any>(defaultData);

	const loadData = (month: number, year: number) => {
		setIsLoading(true);
		setData(defaultData);

		let payload: any = { worker_id: workerId };

		if (month === 13) {
			payload.start = DateTime.now().set({ year }).startOf('year').toISO();
			payload.end = DateTime.now().set({ year }).endOf('year').toISO();
		} else if (year === 0) {
			payload.start = null;
			payload.end = null;
		} else {
			payload.start = DateTime.now().set({ year, month }).startOf('month').toISO();
			payload.end = DateTime.now().set({ year, month }).endOf('month').toISO();
		}

		axios
			.post('/api/workers/workers_list/worker_card/assessment', payload)
			.then((res: any) => {
				setData(res.data);
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<div className={`component-${props.uid.replaceAll('.', '-')}`}>
			<h4 className='font-bold data-grid-title'>{props.title}</h4>
			{isLoading && <Loading overlay={true} />}
			<div className='table-container'>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell rowSpan={2}>Late Start/Early Finish</TableCell>
							<TableCell>Late Start Occurrence</TableCell>
							<TableCell>Early Finish Occurrence</TableCell>
							<TableCell>Time Lost</TableCell>
							<TableCell>League Position</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{data.late_start_early_finish.late_start_occurrence}</TableCell>
							<TableCell>{data.late_start_early_finish.early_finish_occurrence}</TableCell>
							<TableCell>{data.late_start_early_finish.time_lost_string}</TableCell>
							<TableCell>{data.late_start_early_finish.league_position}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell rowSpan={2}>Early Start/Late Finish</TableCell>
							<TableCell>Early Start Occurrence</TableCell>
							<TableCell>Late Finish Occurrence</TableCell>
							<TableCell>Time Gained</TableCell>
							<TableCell>League Position</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{data.early_start_late_finish.early_start_occurrence}</TableCell>
							<TableCell>{data.early_start_late_finish.late_finish_occurrence}</TableCell>
							<TableCell>{data.early_start_late_finish.time_gained_string}</TableCell>
							<TableCell>{data.early_start_late_finish.league_position}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell rowSpan={2}>Failed to Record</TableCell>
							<TableCell>Failed to Record Occurrence</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>League Position</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{data.failed_to_record.failed_to_record_occurrence}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>{data.failed_to_record.league_position}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell rowSpan={2}>Stationary Phone</TableCell>
							<TableCell>Days with greater than 1 hour period</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>League Position</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{data.stationary_phone.days}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>{data.stationary_phone.league_position}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell rowSpan={2}>KPI</TableCell>
							<TableCell>Average KPI score this period</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>League Position</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{data.kpi.kpi_value}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>{data.kpi.league_position}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell rowSpan={2}>Phone Usage</TableCell>
							<TableCell>Hours this period</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>League Position</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{data.phone_usage.seconds}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>{data.phone_usage.league_position}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
			<div className='filter-buttons-container'>
				<FilterButtons displayCurrentMonth={loadData} initialLoad={true} gridIsReady={true} />
			</div>
		</div>
	);
};

export default AssessmentIndex;

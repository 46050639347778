import { useState, useEffect, useContext } from 'react';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { reportsSubmenuArray } from './reportsSubmenuArray';
import Submenu from '../../../components/Menu/Submenu';
import { reportContext } from './reportContext';
import Loading from '../../../components/UI/Loading';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import { moduleContext } from '../../../contexts/ModuleContext';
import { JobStatus } from '../Jobs/job-types';

const ReportsIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { Provider } = reportContext;
	const moduleCtx = useContext<any>(moduleContext);
	const [submenu, setSubmenu] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [clientsLoading, setClientsLoading] = useState<boolean>(true);
	const [clients, setClients] = useState<Array<any>>([]);
	const [jobManagersLoading, setJobManagersLoading] = useState<boolean>(true);
	const [jobManagers, setJobManagers] = useState<Array<any>>([]);
	const jobStatuses: Array<any> = [
		{
			label: 'In Progress',
			value: JobStatus.IN_PROGRESS,
		},
		{
			label: 'Suspended',
			value: JobStatus.SUSPENDED,
		},
		{
			label: 'Completed',
			value: JobStatus.COMPLETED,
		},
	];

	useEffect(() => {
		setIsLoading(clientsLoading || jobManagersLoading);
	}, [clientsLoading, jobManagersLoading]);

	useEffect(() => {
		loadClients();
		loadJobManagers();
	}, []);

	useEffect(() => {
		if (!isLoading) {
			let array = reportsSubmenuArray.map((item) => {
				item.active = false;
				return item;
			});
			array[0].active = true;
			setSubmenu(array);
		}
	}, [isLoading]);

	const loadClients = () => {
		setClientsLoading(true);
		moduleCtx.getClients().then((res: any) => {
			setClients(
				res.map((client: any) => ({
					label: client.client_name_full,
					value: client.id,
				}))
			);
			setClientsLoading(false);
		});
	};

	const loadJobManagers = () => {
		setJobManagersLoading(true);
		moduleCtx.getWorkersList(null, false, true, false, false).then((res: any) => {
			setJobManagers(
				res.map((manager: any) => ({
					label: manager.first_name + ' ' + manager.last_name,
					value: manager.id,
				}))
			);
			setJobManagersLoading(false);
		});
	};

	return (
		<div className={`component-${uid.replaceAll('.', '-')}`}>
			{isLoading && <Loading overlay={true} />}
			{!isLoading && submenu.length > 0 && (
				<Provider value={{ clients, jobManagers, jobStatuses }}>
					<TitleBar title={'Jobs - Reports'} />
					{submenu.length > 0 && <Submenu array={submenu} />}
				</Provider>
			)}
		</div>
	);
};

export default ReportsIndex;

import { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import axios from '../../lib/axios';
import ErrorMessage from '../../components/Auth/ErrorMessage';
import useErrorHandler from '../../utils/custom-hooks/ErrorHandler';
import AuthButton from '../../components/Forms/AuthButton';
import { Logos, AuthStyles } from '../../components/Auth/Styling';
import { csrf } from '../../helpers/csrf';
import AuthFooter from '../../components/Auth/AuthFooter';
import { useHistory } from 'react-router';

const ForgottenPassword = () => {
	const [userEmail, setUserEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [resetSent, setResetSent] = useState(false);
	const { error, showError } = useErrorHandler(null);
	let history = useHistory();

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setResetSent(false);

		// Validate email
		if (!isEmail(userEmail)) {
			showError('Please enter a valid email address.');
			return false;
		}

		try {
			setLoading(true);

			await csrf();

			await axios.post('/forgot-password', { email: userEmail });

			setResetSent(true);
		} catch (err) {
			showError('Sorry, please try again later.');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<AuthStyles />
			<form className='form-auth' onSubmit={handleSubmit}>
				<div className='form-inner rounded-3 card'>
					<div className='card-body p-md-5 mx-md-4'>
						<div role='button' onClick={() => history.go(-1)}>
							<Logos />
						</div>

						<div className='text-center mb-4'>Forgotten Password</div>

						{resetSent && (
							<div className='text-center text-success mb-4'>
								Please check your emails for the
								<br />
								password reset link.
							</div>
						)}

						<div className='form-group mb-2'>
							<input
								type='text'
								id='email'
								className='form-control'
								placeholder='Please enter your email address'
								autoFocus
								disabled={loading}
								onChange={(e) => setUserEmail(e.target.value)}
								value={userEmail}
							/>
						</div>

						<div className='buttons-2fa'>
							<AuthButton btnType='submit' loading={loading} className='mb-3'>
								SEND RESET LINK
							</AuthButton>
						</div>
						{error && <ErrorMessage errorMessage={error} />}
					</div>
				</div>
			</form>
			<AuthFooter />
		</>
	);
};

export default ForgottenPassword;

import { useState, useEffect, useContext, useRef } from 'react';
import { IonCard } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import axios from '../../../../lib/axios';
import Form from '../../../../components/Forms/Form';
import { db2Form } from '../../../../api/forms';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import './WorkingHolidayYear.scss';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserXmark } from '@fortawesome/free-solid-svg-icons';

const WorkingHolidayYearIndex: React.FC<RouteIndexComponent> = ({
	uid,
	routeTitle,
	permissionTo,
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const approvalWorkerRef = useRef<any>();
	const autoForwardRef = useRef<any>();
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [items, setItems] = useState<Array<any>>([]);
	const [endpointID, setEndpointID] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const approvalWorkerDefaultValueLabel = (
		<>
			<FontAwesomeIcon icon={faUserXmark} className='button-icon' />
			<span>Nobody</span>
		</>
	);
	const approvalWorkerDefaultValue = [{ label: approvalWorkerDefaultValueLabel, value: '0' }];
	const yesNoValues: Array<any> = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	];

	useEffect(() => {
		loadWorkers();
	}, []);

	useEffect(() => {
		setIsLoading(true);
		if (workersLoading === false) {
			let formData: any = [
				{
					title: 'Holiday Year',
					type: 'title',
					style: 'alpha',
				},
				{
					title: 'Set Company Rule for period that holidays are granted from',
					type: 'title',
					style: 'beta',
				},
				{
					type: 'date',
					db: ['settings', 'working_holiday_year'],
				},
				{
					title: 'Holiday Requests',
					type: 'title',
					style: 'alpha',
				},
				{
					title: 'Auto forward requests to line manager if company organisation chart set',
					type: 'title',
					style: 'beta',
				},
				{
					type: 'dropdown',
					ref: autoForwardRef,
					values: yesNoValues,
					preChangeCallback: handleAutoForwardPreChanged,
					db: ['settings', 'autoforward'],
				},
				{
					title: 'Or select a worker to receive all holiday requests',
					type: 'title',
					style: 'beta',
				},
				{
					type: 'dropdown',
					ref: approvalWorkerRef,
					values: workers,
					defaultValue: approvalWorkerDefaultValue,
					preChangeCallback: handleApprovalWorkerPreChanged,
					db: ['settings', 'approval_worker_id'],
				},
			];

			axios
				.get('/api/utilities/company_details/settings')
				.then((res) => res.data[0])
				.then((dbData) => {
					formData = db2Form(formData, dbData);
					setItems(formData);
					setEndpointID(dbData._id);
				})
				.catch((err) => {
					showToast('error');
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [workersLoading]);

	const loadWorkers = () => {
		setWorkersLoading(true);
		moduleCtx.getWorkers().then((res: any) => {
			let result = res.map((r: any) => {
				return {
					label: r.name,
					value: r.id,
				};
			});
			result = approvalWorkerDefaultValue.concat(result);
			setWorkers(result);
			setWorkersLoading(false);
		});
	};

	const handleAutoForwardPreChanged = () => {
		// Make sure the approval worker option is set to nobody
		if (approvalWorkerRef.current) {
			approvalWorkerRef.current.setValue(approvalWorkerDefaultValue[0]);
		}
	};

	const handleApprovalWorkerPreChanged = () => {
		// Make sure the auto-forward option is set to false
		if (autoForwardRef.current) {
			autoForwardRef.current.setValue(yesNoValues[1]);
		}
	};

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonCard className='table-card'>
				{isLoading && <Loading height={140} />}
				{!isLoading && items && items.length > 0 && (
					<div className='utility-form-padding'>
						<Form
							array={items}
							permissionTo={permissionTo}
							forceEdit={permissionTo('update')}
							noButton={true}
							tableMode={true}
							endpoint={'/api/utilities/company_details/settings/'}
							endpointID={endpointID}
						/>
					</div>
				)}
			</IonCard>
		</>
	);
};

export default WorkingHolidayYearIndex;

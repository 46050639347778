import { useContext, useEffect, useRef, useState } from 'react';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import { DateTime } from 'luxon';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { authContext } from '../../../contexts/AuthContext';
import { dateFormatter } from '../../../helpers/dateFormatter';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { capitalFirstLetter, getTimezone, getYearSelectOptions } from '../../../lib/functions';
import {
	EventType,
	HalfDayOptions,
	SickData,
	SickStatus,
} from '../../Workers/HolidayAbsenceTraining/Sick/sick-types';
import { IonButton, IonLabel } from '@ionic/react';
import SelectStyled from '../../../components/UI/SelectStyled';
import SickModal from '../../Workers/HolidayAbsenceTraining/modals/SickModal';
import { handleSaveRequest } from '../../Workers/HolidayAbsenceTraining/Sick/sick-functions';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const Sick: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const gridRef: any = useRef<any>();
	const [viewHistory, setViewHistory] = useState<boolean>(false);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [tableData, setTableData] = useState<Array<any>>([]);
	const [curYear, setCurYear] = useState<number>(DateTime.now().year);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const monthStart: number = DateTime.fromSQL(props.systemSettings.working_holiday_year).month;
	const yearSelectOptions = getYearSelectOptions({ offsetMode: monthStart > 1 });
	const defaultStats: any = {
		sick: { average: '-', approved: '-', difference: '-', requested: '-' },
	};
	const [stats, setStats] = useState<any>(defaultStats);
	const sickDataDefault: SickData = {
		requestId: undefined,
		workerId: authCtx.user.worker_id,
		description: '',
		requestedDate: DateTime.now().startOf('day'),
		period: 1,
		status: SickStatus.APPROVED,
	};
	const permissionTo = usePermissionTo('workers.holidays_and_absences.sick');
	const sickStatsSortingArr: Array<string> = ['average', 'requested', 'difference'];

	// Modals
	const [sickModal, setSickModal] = useState<{ isOpen: boolean }>({ isOpen: false });
	const [sickModalData, setSickModalData] = useState<SickData>(sickDataDefault);

	useEffect(() => {
		if (gridReady) {
			loadGridData(curYear);
		}

		loadStats(curYear);
	}, [gridReady, curYear, viewHistory]);

	const columns: Array<any> = [
		{
			field: 'requested_date',
			headerName: 'Requested',
			valueFormatter: dateFormatter,
			flex: 1,
		},
		{ field: 'description', headerName: 'Sickness Reason', flex: 1 },
		{ field: 'start', valueFormatter: dateFormatter, flex: 1 },
		{ field: 'end', valueFormatter: dateFormatter, flex: 1 },
		{
			field: 'period',
			cellRenderer: (params: any) => (
				<>
					{params.node.data.period}
					<span className='small'> day{Number(params.node.data.period) !== 1 ? 's' : ''}</span>
				</>
			),
			flex: 1,
		},
	];

	const loadGridData = (year: number) => {
		setTableData([]);

		const payload: any = {
			worker_id: authCtx.user.worker_id,
			event_types: [EventType.SICK],
			year,
			month: 13,
			...getTimezone(),
		};

		axios
			.post('/api/workers/holidays_and_absences/requests', payload)
			.then((res: any) => {
				setTableData(
					res.data.sort(function (a: any, b: any) {
						return DateTime.fromISO(a.requested_date).valueOf() <
							DateTime.fromISO(b.requested_date).valueOf()
							? 1
							: -1;
					})
				);
			})
			.catch(() => {
				showToast('error', 'There was a problem with the My Sickness Notifications widget');
			});
	};

	const loadStats = (year: number) => {
		let payload: any = {
			worker_id: authCtx.user.worker_id,
			event_types: [EventType.SICK],
			year,
			month: 13,
		};

		axios
			.post('/api/workers/holidays_and_absences/information', payload)
			.then((res: any) => {
				setStats(res.data);
			})
			.catch(() => {
				showToast('error');
			});
	};

	const handleNotifySickness = () => {
		setSickModal({ isOpen: true });
	};

	return (
		<>
			<div
				className={`widget-container ${
					props.widgetId.replaceAll('.', '-') + (viewHistory ? ' view-history' : '')
				}`}
			>
				<div className='widget-header'>My Sick Notifications</div>

				{!viewHistory && (
					<>
						<div className='widget-content'>
							<div className='widget-plaques-container'>
								{Object.keys(stats.sick)
									.sort((a, b) => sickStatsSortingArr.indexOf(a) - sickStatsSortingArr.indexOf(b))
									.filter((stat: any) => sickStatsSortingArr.includes(stat))
									.map((stat: any, index: number) => {
										// Custom colour overrides
										let colorOverrideClass: string = '';
										if (stat === 'difference') {
											if (Number(stats.sick[stat]) <= 0) {
												colorOverrideClass = ' theme-beta-tertiary';
											} else {
												colorOverrideClass = ' theme-beta-quinary';
											}
										}

										return (
											<div
												key={index}
												className={`widget-plaque widget-plaque__${stat}${colorOverrideClass}`}
											>
												<div className='title'>{capitalFirstLetter(stat)}</div>
												<div className='stat'>{stats.sick[stat]}</div>
											</div>
										);
									})}
							</div>
						</div>
						<div className='widget-footer'>
							<IonButton className='btn-alt' size='small' onClick={() => setViewHistory(true)}>
								View History
							</IonButton>
							<IonButton size='small' onClick={handleNotifySickness}>
								Notify Sickness
							</IonButton>
						</div>
					</>
				)}

				{viewHistory && (
					<>
						<div className='widget-content'>
							<DataGrid
								ref={gridRef}
								title=''
								onGridReady={() => setGridReady(true)}
								useSearch={false}
								cols={columns}
								data={tableData}
								rowCursorPointer={false}
								suppressRowHoverHighlight={true}
								compact={true}
								gridSizeChangedFunction={() => {
									if (gridReady) gridRef.current.sizeToFit();
								}}
							/>
						</div>
						<div className='widget-footer'>
							<div className='history-stats'>
								{stats && stats.hasOwnProperty(EventType.SICK) && (
									<>
										Total days taken: <strong>{stats[`${EventType.SICK}`].approved}</strong>
									</>
								)}
							</div>

							<div className='history-controls'>
								<IonLabel>Year:</IonLabel>
								<SelectStyled
									value={[
										{
											label: yearSelectOptions.filter((opt: any) => opt.value === curYear)[0].label,
											value: curYear,
										},
									]}
									isSearchable={false}
									options={yearSelectOptions}
									onChange={(newValue: any) => {
										setCurYear(newValue.value);
									}}
								/>
								<IonButton className='btn-alt' size='small' onClick={() => setViewHistory(false)}>
									View Stats
								</IonButton>
								<IonButton size='small' onClick={handleNotifySickness}>
									Notify Sickness
								</IonButton>
							</div>
						</div>
					</>
				)}
			</div>

			<SickModal
				isOpen={sickModal.isOpen}
				showLoading={isLoading}
				initialData={sickModalData}
				onClose={() => {
					setSickModal((prevState: any) => ({ ...prevState, isOpen: false }));
					setSickModalData(sickDataDefault);
				}}
				onSave={(data: SickData, halfDayOptions: HalfDayOptions[]) => {
					handleSaveRequest(data, halfDayOptions, permissionTo, setIsLoading, () => {
						// On success
						loadGridData(curYear);
						loadStats(curYear);

						// Reset the modal
						setSickModal({ isOpen: false });
						setSickModalData(sickDataDefault);
					});
				}}
				onRequestManager={() => {}}
				onSaveManagersNotes={() => {}}
				usageMode='dashboard'
				permissionTo={permissionTo}
			/>
		</>
	);
};

export default Sick;

import { useContext, useEffect, useState } from 'react';
import { IonButton, IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { showToast } from '../../../../lib/toast';
import { useHistory } from 'react-router-dom';
import { InvoiceContext } from '../InvoicesProvider';
import axios from '../../../../lib/axios';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import { ActionType } from '../actions';
import { InvoiceItem } from '../invoice-types';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import Loading from '../../../../components/UI/Loading';
import SendModal from '../modals/SendModal';
import PaymentModal from '../modals/PaymentModal';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	invoiceId?: string;
	handleUploadsModalOpen: Function;
}

const InvoiceFooter: React.FC<Props> = ({
	permissionTo,
	isNew,
	invoiceId,
	handleUploadsModalOpen,
}) => {
	const { state, dispatch } = useContext(InvoiceContext);
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const [taxRateIdCount, setTaxRateIdCount] = useState(0);
	let history = useHistory();

	// Modals
	const [sendModal, setSendModal] = useState<{ isOpen: boolean; mode: 'send' | 'reissue' | null }>({
		isOpen: false,
		mode: null,
	});
	const [paymentModal, setPaymentModal] = useState<{
		isOpen: boolean;
		paymentStatus: 'decision' | null;
		kick: boolean;
	}>({ isOpen: false, paymentStatus: null, kick: false });

	useEffect(() => {
		// Load the terms and conditions
		axios.get('/api/utilities/styles/terms_and_conditions/latest').then((res: any) => {
			if (res.data && res.data.content !== null) {
				// Convert back to an editor state object
				const convertedState = convertFromRaw(JSON.parse(res.data.content));
				const editorValue = EditorState.createWithContent(convertedState);

				// Set the editor's content
				setEditorState(editorValue);
			}
		});
	}, []);

	// Check all the tax rate dropdowns have been selected
	useEffect(() => {
		setTaxRateIdCount(state.items.filter((item: any) => item.taxRateId.length > 0).length);
	}, [state]);

	// Check if when closing the payment modal we need to kick the user to a new page
	useEffect(() => {
		if (paymentModal.kick === true) {
			history.push('/jobs/invoices_list#debtors');
		}
	}, [paymentModal]);

	// Check if the form is ready to submit
	let canSubmit = false;
	if (
		state.items.length > 0 &&
		(taxRateIdCount === state.items.length || state.invoiceData?.vatRegistered === false)
	) {
		canSubmit = true;
	}

	const handleUploadFiles = () => {
		if (permissionTo('update')) {
			handleUploadsModalOpen();
		} else {
			showToast('permission');
		}
	};

	const handleSaveInvoice = () => {
		if (permissionTo('create')) {
			if (!mandatoryFieldsCheckOK()) return false;

			storeInvoice('save');
		} else {
			showToast('permission');
		}
	};

	const handleSendInvoice = () => {
		if (permissionTo('create')) {
			if (!mandatoryFieldsCheckOK()) return false;
			setSendModal((prevState: any) => ({ ...prevState, isOpen: true, mode: 'send' }));
		} else {
			showToast('permission');
		}
	};

	const handleReIssueInvoice = () => {
		if (permissionTo('create')) {
			if (!mandatoryFieldsCheckOK()) return false;
			setSendModal((prevState: any) => ({ ...prevState, isOpen: true, mode: 'reissue' }));
		} else {
			showToast('permission');
		}
	};

	const handleInvoiceSendAction = (mode: string) => {
		setSendModal((prevState: any) => ({ ...prevState, isOpen: false }));
		storeInvoice(mode);
	};

	const storeInvoice = (action: string) => {
		let url: string = '/api/jobs/invoices_list/invoices_card';
		if (
			action === 'reissue' ||
			(state.invoiceData?.statusEnum && state.invoiceData?.statusEnum === 'draft')
		) {
			url = `/api/jobs/invoices_list/invoices_card/${invoiceId}`;
		}

		// TODO: Send invoice email with PDF attachment i.e. with sendInvoiceSubject & sendInvoiceBody

		dispatch({ type: ActionType.SET_IS_LOADING, payload: true });

		let payload: any = {
			invoice_id: state.invoiceData?.invoiceId,
			estimate_id: state.invoiceData?.estimateId,
			job_id: state.invoiceData?.jobId,
			number: state.invoiceData?.number,
			issue_date: state.invoiceData?.issueDate,
			due_date: state.invoiceData?.dueDate,
			reference: state.invoiceData?.reference,
			title: state.invoiceData?.title ? state.invoiceData.title.trim() : null,
			summary: state.invoiceData?.summary ? state.invoiceData.summary.trim() : null,
			tax_exclusive: state.invoiceData?.amountsAre[0].value === 'exclusive',
			items: state.items.map((item: InvoiceItem) => {
				let itemObj: any = {
					item: item.item,
					description: item.description,
					percentage_complete: Number(item.percentageComplete),
					quantity: Number(item.quantity),
					price: Number(item.price),
					discount: Number(item.discount),
					tax_rate_id: item.taxRateId,
				};

				if (isNew) {
					itemObj.id = '0';
					itemObj.estimate_id = item.hasOwnProperty('id') ? item.id : '0';
				} else {
					itemObj.id = item.hasOwnProperty('id') ? item.id : '0';
				}

				return itemObj;
			}),
		};

		// Add the status depending on the store action
		if (action !== 'save') payload.status = 'issued';

		axios
			.put(url, payload)
			.then(() => {
				switch (action) {
					case 'save':
						showToast('success', 'Invoice successfully saved');
						break;
					case 'issue':
						showToast('success', 'Invoice successfully sent');
						break;
					case 'reissue':
						showToast('success', 'Invoice successfully re-issued');
						break;
				}

				history.push('/jobs/invoices_list#debtors');
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
			});
	};

	const handlePaymentReceived = () => {
		if (permissionTo('update')) {
			setPaymentModal((prevState: any) => ({ ...prevState, isOpen: true, paymentStatus: null }));
		} else {
			showToast('permission');
		}
	};

	const handlePaymentReceivedAction = (value: Number, datePaid: string, resolve: string | null) => {
		const toastId = toast.loading('Please wait...');

		dispatch({ type: ActionType.SET_IS_LOADING, payload: true });
		setPaymentModal((prevState: any) => ({ ...prevState, paymentStatus: null }));

		let payload: any = {
			amount: value,
			date_paid: datePaid,
		};

		if (resolve) payload.resolve = resolve;

		axios
			.put(`/api/jobs/invoices_list/invoices_card/${invoiceId}/payment`, payload)
			.then((res: any) => {
				switch (res.data.status) {
					case 'paid':
						setPaymentModal((prevState: any) => ({
							...prevState,
							isOpen: false,
							paymentStatus: null,
							kick: true,
						}));
						showToast('success', 'Invoice payment successfully made', toastId);
						break;
					case 'decision':
						setPaymentModal((prevState: any) => ({ ...prevState, paymentStatus: res.data.status }));
						showToast('warning', 'Please make a decision regarding this payment', toastId);
						break;
					case 'error':
						showToast('error', res.data.message, toastId);
						break;
				}
			})
			.catch(() => {
				showToast('error', null, toastId);
			})
			.finally(() => {
				dispatch({ type: ActionType.SET_IS_LOADING, payload: false });
			});
	};

	const mandatoryFieldsCheckOK = () => {
		if (!state.invoiceData?.amountsAre) {
			showToast('error', "Please select a tax status from the 'Amounts Are' field");
			return false;
		}

		if (!state.invoiceData?.issueDate || !state.invoiceData.dueDate) {
			showToast('error', 'Please ensure all dates are selected');
			return false;
		}

		return true;
	};

	return (
		<>
			<IonGrid className='invoice-footer'>
				<IonRow>
					<IonCol size='8'>
						<IonLabel className='lbl-terms'>Terms & Conditions</IonLabel>
						<Editor
							editorState={editorState}
							toolbarClassName='terms-toolbar'
							editorClassName='wysiwyg-editor-class-readonly terms-editor'
							readOnly
						/>
					</IonCol>
					<IonCol size='4'>
						{state.totalsLoading && <Loading overlay={true} />}
						<Table className='tbl-totals' size='small'>
							<TableBody>
								<TableRow>
									<TableCell variant='head' width='70%'>
										Sub Total
									</TableCell>
									<TableCell>
										{state.totals.subtotal.toLocaleString('en-GB', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
									</TableCell>
								</TableRow>
								{state.totals.vat.map((vatTotal: any, i: number) => {
									if (Number(vatTotal.total) <= 0) return;
									return (
										<TableRow key={i}>
											<TableCell variant='head'>{vatTotal.description}</TableCell>
											<TableCell>
												{vatTotal.total.toLocaleString('en-GB', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}
											</TableCell>
										</TableRow>
									);
								})}
								{Number(state.totals.discount) > 0 && (
									<TableRow>
										<TableCell variant='head'>Included Discount</TableCell>
										<TableCell>
											{state.totals.discount.toLocaleString('en-GB', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</TableCell>
									</TableRow>
								)}
								<TableRow>
									<TableCell variant='head'>Total</TableCell>
									<TableCell>
										{state.totals.total.toLocaleString('en-GB', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size='6'>
						{!isNew && <IonButton onClick={handleUploadFiles}>Upload Files</IonButton>}
					</IonCol>
					<IonCol size='6' className='text-right'>
						{(isNew || state.invoiceData?.statusEnum === 'draft') && (
							<>
								<IonButton color='primary' onClick={handleSaveInvoice} disabled={!canSubmit}>
									Save Invoice
								</IonButton>
								<IonButton color='success' onClick={handleSendInvoice} disabled={!canSubmit}>
									Send Invoice
								</IonButton>
							</>
						)}
						{!isNew && state.invoiceData?.statusEnum !== 'draft' && (
							<>
								<IonButton
									color='success'
									onClick={handleReIssueInvoice}
									disabled={!canSubmit || state.invoiceData?.statusEnum === 'paid'}
								>
									Re-Issue Invoice
								</IonButton>
								<IonButton
									onClick={handlePaymentReceived}
									disabled={!canSubmit || state.invoiceData?.statusEnum === 'paid'}
								>
									Payment Received
								</IonButton>
							</>
						)}
					</IonCol>
				</IonRow>
			</IonGrid>

			<SendModal
				isOpen={sendModal.isOpen}
				mode={sendModal.mode}
				onClose={() => {
					setSendModal((prevState: any) => ({ ...prevState, isOpen: false }));
				}}
				onSave={(mode: string) => handleInvoiceSendAction(mode)}
			/>

			<PaymentModal
				isOpen={paymentModal.isOpen}
				paymentStatus={paymentModal.paymentStatus}
				onClose={() => {
					setPaymentModal((prevState: any) => ({
						...prevState,
						isOpen: false,
					}));
				}}
				onSave={handlePaymentReceivedAction}
			/>
		</>
	);
};

export default InvoiceFooter;

import { useState, useEffect, useContext } from 'react';
import OrgTreeComponent, { useTree } from 'react-drag-hierarchy-tree';
import Draggable from 'react-draggable';
import { IonCard, IonRow, IonCol, IonText } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { moduleContext } from '../../../../contexts/ModuleContext';
import Loading from '../../../../components/UI/Loading';
import './ManagementStructure.scss';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBullseye,
	faCircleChevronLeft,
	faCircleCheck,
	faGripLinesVertical,
	faRightLeft,
} from '@fortawesome/free-solid-svg-icons';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { getDomElementInPath } from '../../../../lib/functions';
import ContextMenu from '../../../../components/UI/ContextMenu';
import ReplaceWithModal from '../../../../modals/ReplaceWithModal';
import { EngagementTypes } from '../../../Workers/Workers/workers-types';
import { Alert } from '@mui/material';
import RoleBadge from '../../../../components/UI/RoleBadge';
import { createPortal } from 'react-dom';

interface RouteIndexComponentExtended extends RouteIndexComponent {
	usageMode?: string;
	workerId?: string;
}

const ManagementStructureIndex: React.FC<RouteIndexComponentExtended> = ({
	uid,
	routeTitle,
	permissionTo,
	usageMode,
	workerId,
}) => {
	const { treeRef } = useTree();
	const moduleCtx = useContext<any>(moduleContext);
	const [treeLoading, setTreeLoading] = useState<boolean>(true);
	const [treeData, setTreeData] = useState<any>({});
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [activeDrag, setActiveDrag] = useState<boolean>(false);
	const [droppedOn, setDroppedOn] = useState<any>(null);
	const [currentTreeNode, setCurrentTreeNode] = useState<any>(null);
	const [topLevelWorker, setTopLevelWorker] = useState<any>(null);
	const draggerZIndexLow = 1000;
	const draggerZIndexHigh = 1001;
	let treeWorkerId: string = '';

	// Replace-with modal state
	const [modalLoading, setModalLoading] = useState<boolean>(false);
	const [replaceWithModal, setReplaceWithModal] = useState<any>({ isOpen: false });
	const [replaceWithModalData, setReplaceWithModalData] = useState<any>({});

	// Information text
	let infoText = '';
	switch (usageMode) {
		case 'worker_tabs':
			infoText =
				'Drag and drop the worker below in to the Management Tree. Right-click on a tree worker for options.';
			break;
		default:
			infoText =
				'Drag and drop the worker(s) below to create a Management Tree (the first drop becomes the boss). Right-click on a tree worker for options.';
			break;
	}

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		// Reset
		treeWorkerId = '';

		loadTree();
	};

	const loadTree = () => {
		setTreeLoading(true);
		setTreeData(null);
		setWorkers([]);

		axios
			.get('/api/utilities/workers/management_structure')
			.then((res: any) => {
				let treeIDs: Array<any> = [];

				// Add the id key for the hierarchy tree library and other extra data
				const nestedMap = (arr: any) => {
					const result = arr.map((row: any) => {
						// Keep track of workers already in the tree
						treeIDs.push(row._id);

						if (row.children && row.children.length) {
							const children = nestedMap(row.children);
							return { ...row, id: row._id, type_of_engagement: row.type_of_engagement, children };
						} else {
							return { ...row, id: row._id, type_of_engagement: row.type_of_engagement };
						}
					});
					return result;
				};

				const treeNodeData = nestedMap(res.data);

				if (treeNodeData.length > 0) {
					setTreeData(treeNodeData[0]);
					setTopLevelWorker(res.data[0]); // Always the first item in the endpoint's data
				} else {
					setTreeData(null);
					setTopLevelWorker(null);
				}

				loadWorkers(treeIDs);
			})
			.finally(() => {
				setTreeLoading(false);
			});
	};

	const loadWorkers = (treeIDs: Array<any>) => {
		setWorkersLoading(true);

		moduleCtx.getWorkers().then((res: any) => {
			let result = null;

			// Filter by usage mode
			switch (usageMode) {
				case 'worker_tabs':
					// Filter all workers except for the selected worker
					result = res.filter((w: any) => !treeIDs.includes(w.id) && w.id === workerId);
					break;
				default:
					// Filter workers that are already in the tree
					result = res.filter((w: any) => !treeIDs.includes(w.id));
					break;
			}

			// Filter by type of engagement
			result = result.filter((w: any) => {
				if (
					w.type_of_engagement === EngagementTypes.DIRECTLY ||
					(w.type_of_engagement === EngagementTypes.SELF &&
						w.appears_on_organisation_chart !== false)
				) {
					return w;
				}
			});

			setWorkers(result);
			setWorkersLoading(false);
		});
	};

	const renderCard = (props: any) => {
		let style: any = {};
		let classes: any = ['tree-card'];
		let mouseEventHandlers: any = {};
		let subtitle = props.data && props.data.subtitle ? props.data.subtitle : '-';
		let cardID = {};

		if (props.dragger) {
			// Dragger
			cardID = { id: `worker_${props.worker.id}` };

			style.zIndex = draggerZIndexLow;
			style.marginBottom = '4px';

			subtitle = props.worker.job_title
				? props.worker.job_title
				: props.worker.role_name
				? props.worker.role_name
				: '-';

			classes.push('dragger-node');

			if (props.worker.type_of_engagement === EngagementTypes.SELF) {
				classes.push('self-employed');
			}
		} else {
			// Tree node
			subtitle = props.data.subtitle
				? props.data.subtitle
				: props.data.role_name
				? props.data.role_name
				: '-';

			classes.push('tree-node');

			if (props.data.archived === true) {
				classes.push('tree-node-archived');
			}

			if (props.data.type_of_engagement === EngagementTypes.SELF) {
				classes.push('self-employed');
			}

			if (permissionTo('update')) {
				mouseEventHandlers = {
					onMouseEnter: onMouseEnterTreeItem,
					onMouseLeave: onMouseLeaveTreeItem,
					onMouseDown: onMouseDownTreeItem,
					onDrop: onMouseDropTreeItem,
				};
			}
		}

		return (
			<IonCard {...mouseEventHandlers} {...cardID} className={classes.join(' ')} style={style}>
				{props.dragger && (
					<>
						<div className='dragger-left'>
							<RoleBadge
								mode='inline'
								roleName={props.worker.role_name}
								roleColour={props.worker.role_colour}
							/>
						</div>
						<div className='dragger-right'>
							<h5>{props.label}</h5>
							<p>{subtitle}</p>
						</div>
						<div className='dragger-edge'>
							<FontAwesomeIcon icon={faGripLinesVertical} />
						</div>
					</>
				)}

				{!props.dragger && (
					<>
						<h5>{props.label}</h5>
						<p>{subtitle}</p>
					</>
				)}
			</IonCard>
		);
	};

	// Functions to handle dragging within the hierarchy
	const onMouseDownTreeItem = (event: any) => {
		let treeNode: any = null;
		let workerId: string = '';

		if (event.target.classList && event.target.classList.contains('tree-node')) {
			treeNode = event.target;
		} else {
			treeNode = event.target.offsetParent;
		}

		workerId = treeNode.closest('.org-tree-node-label').id.split('label_')[1];

		treeWorkerId = workerId;
	};

	const onMouseDropTreeItem = (event: any) => {
		let treeNode: any = null;
		let bossId: string = '';

		if (event.target.classList && event.target.classList.contains('tree-node')) {
			treeNode = event.target;
		} else {
			treeNode = event.target.offsetParent;
		}

		bossId = treeNode.closest('.org-tree-node-label').id.split('label_')[1];

		saveDrop(treeWorkerId, { workerId: bossId });
	};

	// Functions to handle outside dragging into hierarchy
	const onMouseEnterTreeItem = (event: any) => {
		if (!activeDrag) return false;

		let treeNode: any = null;
		let workerId: string = '';

		if (event.target.classList && event.target.classList.contains('tree-node')) {
			treeNode = event.target;
		} else {
			treeNode = event.target.offsetParent;
		}

		treeNode.classList.add('tree-node-hover');

		workerId = treeNode.closest('.org-tree-node-label').id.split('label_')[1];

		setCurrentTreeNode(treeNode);
		setDroppedOn({ workerId: workerId });
	};

	const onMouseLeaveTreeItem = (event: any) => {
		if (!activeDrag) return false;

		currentTreeNode.classList.remove('tree-node-hover');

		setCurrentTreeNode(null);
		setDroppedOn(null);
	};

	const onDragStart = (event: any, data: any) => {
		setActiveDrag(true);

		data.node.style.zIndex = draggerZIndexHigh;
		data.node.style.opacity = 0.6;

		// Calculate the container's Y scroll offset so that off-screen draggers attach to cursor
		const offsetParentChildren: HTMLElement[] = data.node.offsetParent.children;
		const list: HTMLElement = Array.from(offsetParentChildren).filter(
			(element: HTMLElement) => element.className === 'list-container'
		)[0];
		const draggerOffsetY: number = data.node.offsetTop - list.scrollTop;
		const hasVScroll: boolean = list.scrollHeight > list.clientHeight;

		// Allows us to drag outside of an overflow-hidden container
		data.node.style.position = 'absolute';
		data.node.style.top = draggerOffsetY + 'px'; // Low down draggers top offset against list scroll
		data.node.style.width = data.node.getBoundingClientRect().width - (hasVScroll ? 30 : 18) + 'px';

		// Allows the tree-node mouse enter/leave events to fire under the draggable
		data.node.style.pointerEvents = 'none';
	};

	const onDragStop = (event: any, data: any) => {
		const workerId: string = data.node.id.split('_')[1];

		setActiveDrag(false);
		data.node.style.zIndex = draggerZIndexLow;
		data.node.style.opacity = 1;

		// Reset
		data.node.style.position = 'relative';
		data.node.style.top = 'inherit';
		data.node.style.width = '100%';

		// Allows the draggable to be draggable once more
		data.node.style.pointerEvents = 'auto';

		// Get the dropped-on node
		let node = null;
		if (event.path) {
			node = event.path.find((el: any) => el.classList && el.classList.contains('tree-node'));
		}

		if (!node) {
			node = getDomElementInPath(event.target, 'tree-node');
		}

		// Dropped on a valid tree-node
		if (node) {
			saveDrop(workerId, droppedOn);

			if (currentTreeNode) {
				currentTreeNode.classList.remove('tree-node-hover');
			}
		}

		// Check if dropped in the org tree and no nodes exist yet, i.e. first worker (the boss)
		if (!node && event.target.id === 'org_tree_container' && !treeData) {
			saveDrop(workerId, { workerId: 'parent' });
		}

		// Reset
		setCurrentTreeNode(null);
		setDroppedOn(null);
	};

	const saveDrop = (workerId: string, droppedOn: any) => {
		setTreeLoading(true);
		setWorkersLoading(true);

		const payload: any = {};
		payload[workerId] = droppedOn.workerId;

		axios
			.put('/api/utilities/workers/management_structure', payload)
			.then((res: any) => {
				showToast('saved');
				loadData();
			})
			.catch((err: any) => {
				showToast('error');
				setTreeLoading(false);
				setWorkersLoading(false);
			});
	};

	const handleRemove = async (removeId: string) => {
		const workerId = removeId.split('label_')[1];

		if (topLevelWorker && workerId === topLevelWorker._id) {
			showToast('info', "You can't unassign the boss from the tree");
			return;
		}

		setTreeLoading(true);
		setWorkersLoading(true);

		axios
			.delete(`/api/utilities/workers/management_structure/${workerId}`)
			.then(() => {
				loadData();
			})
			.catch(() => {
				showToast('error');
				setTreeLoading(false);
				setWorkersLoading(false);
			});
	};

	// Replace-with modal functions
	const handleReplaceWith = async (replaceId: string) => {
		setModalLoading(true);

		// Get worker information
		const workerId = replaceId.split('label_')[1];
		const workerData = await moduleCtx.getWorker(workerId);

		// Get workers options
		const workersOptions = await moduleCtx.getWorkers().then((workers: any) => {
			let result = workers.filter((worker: any) => worker.id !== workerId);
			return result.map((worker: any) => {
				return {
					label: worker.name,
					value: worker.id,
				};
			});
		});

		setModalLoading(false);

		replaceWithModalOnOpen({
			replaceId: workerId,
			replaceName: `${workerData.user.first_name} ${workerData.user.last_name}`,
			workersOptions: workersOptions,
		});
	};

	const replaceWithModalOnOpen = async (params: any) => {
		setReplaceWithModalData(params);
		setReplaceWithModal({ isOpen: true });
	};

	const replaceWithModalOnClose = () => {
		setReplaceWithModal({ isOpen: false });
	};

	const replaceWithModalOnSave = (replaceId: string, withId: string) => {
		setModalLoading(true);
		setWorkersLoading(true);

		axios
			.put('/api/utilities/workers/management_structure/replace', { replaceId, withId })
			.then(() => {
				showToast('saved');
			})
			.finally(() => {
				setModalLoading(false);
				loadTree();
				replaceWithModalOnClose();
			});
	};

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				{modalLoading && <Loading overlay={true} />}
				{!usageMode && <TitleBar title={routeTitle} />}
				<IonCard className='table-card full-height-card'>
					<Alert severity='info' className='alert-info mb-3'>
						<div className='info-left'>{infoText}</div>
						<div className='info-right'>
							<div className='key-self-employed me-1'></div>
							<div>= Self Employed</div>
						</div>
					</Alert>
					<IonRow>
						<IonCol size={'3'}>
							{workersLoading && <Loading />}
							{!workersLoading && workers.length > 0 && (
								<div className='msg-to-be-assigned'>
									<div className='line'>
										<div className='liner'></div>
									</div>
									<IonText>
										<h5>To be assigned</h5>
									</IonText>
									<div className='line'>
										<div className='liner'></div>
									</div>
								</div>
							)}
							{!workersLoading && !treeLoading && workers.length > 0 && (
								<div className='list-container'>
									{workers.map((worker: any, i: number) => {
										if (permissionTo('update')) {
											return (
												<Draggable
													key={i}
													axis='both'
													position={{ x: 0, y: 0 }}
													defaultPosition={{ x: 0, y: 0 }}
													scale={1}
													onStart={onDragStart}
													onStop={onDragStop}
												>
													{renderCard({
														dragger: true,
														worker: worker,
														label: `${worker.first_name} ${worker.last_name}`,
													})}
												</Draggable>
											);
										} else {
											// Non-editable list
											return (
												<div key={i}>
													{renderCard({
														dragger: true,
														worker: worker,
														label: `${worker.first_name} ${worker.last_name}`,
													})}
												</div>
											);
										}
									})}
								</div>
							)}
							{!workersLoading && workers.length === 0 && (
								<div className='msg-all-assigned'>
									<FontAwesomeIcon className='icon-success' icon={faCircleCheck} />
									<div>All workers have been assigned</div>
								</div>
							)}
						</IonCol>
						<IonCol id='org_tree' size={'9'} className='mb-4'>
							<div id='org_tree_container' className='org-tree-container'>
								{treeLoading && <Loading overlay={true} background={false} />}
								{!treeLoading && !treeData && (
									<div className='msg-drop-target'>
										<div>Please drag and drop a worker here from the list on the left...</div>
										<FontAwesomeIcon icon={faBullseye} />
									</div>
								)}
								{!treeLoading && treeData && (
									<>
										<OrgTreeComponent
											data={treeData}
											ref={treeRef}
											renderCard={renderCard}
											horizontal={false}
											strokeColor='#999999'
										/>
										{permissionTo('update') &&
											createPortal(
												<ContextMenu
													targetId='org_tree'
													srcElementClass='org-tree-node-label'
													options={[
														{
															label: (
																<>
																	<FontAwesomeIcon className='pe-2' icon={faRightLeft} />
																	Replace With
																</>
															),
															action: handleReplaceWith,
														},
														{
															label: (
																<>
																	<FontAwesomeIcon className='pe-2' icon={faCircleChevronLeft} />
																	Unassign Worker
																</>
															),
															action: handleRemove,
														},
													]}
													classes={{
														listWrapper: 'contextMenuWrapper',
														listItem: 'contextMenuListItem',
													}}
												/>,
												/*
												Put it where many other portalled Ionic components go by default so that it doesn't hog
												the top of the stack.
											*/
												document.getElementsByTagName('ion-app')[0]
											)}
									</>
								)}
							</div>
						</IonCol>
					</IonRow>
				</IonCard>
			</div>

			{permissionTo('update') && (
				<ReplaceWithModal
					isOpen={replaceWithModal.isOpen}
					onClose={replaceWithModalOnClose}
					onSave={replaceWithModalOnSave}
					onDidDismiss={replaceWithModalOnClose}
					initialData={replaceWithModalData}
					permissionTo={permissionTo}
				/>
			)}
		</>
	);
};

export default ManagementStructureIndex;

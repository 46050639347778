import pkg from "../../../package.json";

const AuthFooter = () => {
	const styles = {
		marginTop: '10px',
		fontSize: '0.8rem',
		color: '#888'
	};

	return (
		<footer style={styles} className="text-center">
			<span>v{pkg.version} | &copy; Propeller Studios Ltd.</span>
		</footer>
	);
}

export default AuthFooter;

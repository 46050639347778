import { DateTime } from 'luxon';
import { ordinal } from './oridinal';

export function dateFormatter(
	params: any,
	includeTime: boolean = false,
	outputEmptyStr: string = '-'
): string {
	const dateISOString = params.data[params.colDef.field];
	let output: string = outputEmptyStr;
	if (dateISOString) {
		if (!includeTime) {
			output = DateTime.fromISO(dateISOString).toFormat('dd/MM/yyyy');
		} else {
			output = DateTime.fromISO(dateISOString).toFormat('dd/MM/yyyy @ HH:mm');
		}
	}

	if (dateISOString === false) output = '';

	return output;
}

export function timeFormatter(params: any): string {
	const dateISOString = params.data[params.colDef.field];
	let tmp: DateTime | null = null;
	let output: string = '-';

	if (dateISOString) {
		tmp = DateTime.fromISO(dateISOString);
		if (tmp.isValid) output = tmp.toFormat('HH:mm');
	}

	if (dateISOString === false) output = '';

	return output;
}

export function dateFormatterFullOrdinal(
	dateInput: DateTime | string,
	includeWords: boolean = true
): string {
	let dateFinal: any = dateInput;

	switch (typeof dateInput) {
		case 'string':
			dateFinal = DateTime.fromISO(dateInput);
			break;
	}

	return (
		dateFinal.toFormat('cccc') +
		(includeWords ? ' the ' : ' ') +
		ordinal(dateFinal.day) +
		(includeWords ? ' of ' : ' ') +
		dateFinal.toFormat('LLLL yyyy')
	);
}

import Timeline from '../../../components/Calendars/Timeline';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import { showToast } from '../../../lib/toast';
import { MbscResource, MbscCalendarEvent } from '@mobiscroll/react';
import { print } from '@mobiscroll/print';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Loading from '../../../components/UI/Loading';
import { getTimezone } from '../../../lib/functions';
import axios from '../../../lib/axios';
import FilterButtons from '../../../components/Forms/FilterButtons';
import { moduleContext } from '../../../contexts/ModuleContext';
import { baseColours } from '../../../components/Calendars/Defaults';
import EventTip from '../../../components/Calendars/EventTip';
import { loadBankHolidays, parseCalendarEvent } from '../../../helpers/calendar';
import Avatar from '../../../components/Calendars/Avatar';
import HolidayChartFilterPopover from '../../../components/Calendars/HolidayChartFilter';

const HolidayChart = () => {
	const moduleCtx = useContext<any>(moduleContext);
	const MOBISCROLL_MODULES = [print];
	const [inst, setInst] = useState<any>(null);
	const [curDate, setCurDate] = useState<string>(DateTime.now().toFormat('LLLL y'));
	const [settingsLoading, setSettingsLoading] = useState<boolean>(true);
	const [eventsLoading, setEventsLoading] = useState<boolean>(true);
	const [workingHolidayYear, setWorkingHolidayYear] = useState<DateTime>(
		DateTime.now().set({ month: 1 }).startOf('month')
	);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [events, setEvents] = useState<Array<any>>([]);
	const [curMonth, setCurMonth] = useState<number>(Number(DateTime.now().toFormat('MM')));
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('y')));
	const [filters, setFilters] = useState<any>(null);
	const [bankHolidays, setBankHolidays] = useState<Array<any>>([]);

	useEffect(() => {
		loadSettings();
	}, []);

	useEffect(() => {
		if (!settingsLoading) loadEvents(curMonth, curYear);
	}, [settingsLoading]);

	// Chart filtering
	useEffect(() => {
		if (filters !== null) loadEvents(curMonth, curYear);
	}, [filters]);

	const loadSettings = () => {
		setSettingsLoading(true);
		moduleCtx
			.getSettings()
			.then((res: any) => {
				setWorkingHolidayYear(DateTime.fromISO(res.working_holiday_year));
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				setSettingsLoading(false);
			});
	};

	const loadEvents = (month: number, year: number) => {
		setEventsLoading(true);

		let payload: any = {
			month,
			year,
			...getTimezone(),
		};

		if (filters && filters.hasOwnProperty('leader')) {
			payload.boss_id = filters.leader.value;
		}

		loadBankHolidays(month, year, setBankHolidays);

		axios
			.post('/api/workers/holidays_and_absences/events/chart', payload)
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);
				setCurDate(DateTime.now().set({ month: month, year: year }).toFormat('LLLL y'));
				setWorkers(
					res.data.resources.map((worker: any) => ({
						id: worker.id,
						name: worker.name,
						colour: worker.colour,
						allowance: worker.information.holiday.allowance,
						taken: worker.information.holiday.approved,
						worker_photo: worker.worker_photo,
						type_of_engagement_enum: worker.type_of_engagement_enum,
					}))
				);
				setEvents(
					res.data.events.map((event: any) =>
						parseCalendarEvent({ event, allDay: true, source: 'holidayChart' })
					)
				);
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				setEventsLoading(false);
			});
	};

	const handleOnPrint = () => {
		if (inst) inst.print();
	};

	const renderHeader = useCallback(() => {
		return (
			<>
				<HolidayChartFilterPopover
					filters={filters}
					onFilter={(filters) => {
						setFilters(filters);
					}}
				/>
				<h2>{curDate}</h2>
			</>
		);
	}, [curDate]);

	const renderResource = useCallback(
		(resource: MbscResource) => {
			const worker = workers.filter((worker: any) => resource.id === worker.id)[0];
			if (!worker) return;

			return (
				<Avatar
					data={resource}
					includeTitle={true}
					titleLink={`/workers/workers_list/worker_card/${resource.id}`}
					extraInfo={
						<p>
							Allowed: {Number(worker.allowance)} Taken: {Number(worker.taken)}
						</p>
					}
				/>
			);
		},
		[workers]
	);

	// Event Tip
	const [eventTipIsOpen, setEventTipIsOpen] = useState(false);
	const [eventTipAnchor, setEventTipAnchor] = useState(undefined);
	const [currentEvent, setCurrentEvent] = useState<any>(null);
	const eventTipTimerRef = useRef<any>(null);
	const onEventHoverIn = useCallback((args: any) => {
		setCurrentEvent(args.event);
		if (eventTipTimerRef.current) clearTimeout(eventTipTimerRef.current);
		setEventTipAnchor(args.domEvent.target);
		setEventTipIsOpen(true);
	}, []);

	const onEventHoverOut = useCallback(() => {
		eventTipTimerRef.current = setTimeout(() => setEventTipIsOpen(false), 200);
	}, []);

	const onMouseEnter = useCallback(() => {
		if (eventTipTimerRef.current) clearTimeout(eventTipTimerRef.current);
	}, []);

	const onMouseLeave = useCallback(() => {
		eventTipTimerRef.current = setTimeout(() => setEventTipIsOpen(false), 200);
	}, []);

	return (
		<>
			{(settingsLoading || eventsLoading) && <Loading overlay={true} />}
			<IonRow className='mt-3'>
				<IonCol size={'12'}>
					<Timeline
						innerRef={setInst}
						modules={MOBISCROLL_MODULES}
						data={events}
						resources={workers.map((worker: any) => ({
							id: worker.id,
							name: worker.name,
							worker_photo: worker.worker_photo,
							type_of_engagement_enum: worker.type_of_engagement_enum,
						}))}
						selectedDate={DateTime.now()
							.set({ day: 1, month: curMonth, year: curYear })
							.startOf('day')
							.toJSDate()}
						renderHeader={renderHeader}
						renderResource={renderResource}
						colors={[...baseColours, ...bankHolidays]}
						onEventHoverIn={onEventHoverIn}
						onEventHoverOut={onEventHoverOut}
					/>
					<EventTip
						eventTipIsOpen={eventTipIsOpen}
						eventTipAnchor={eventTipAnchor}
						currentEvent={currentEvent}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					/>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size={'12'} className='p-0'>
					{!settingsLoading && (
						<FilterButtons
							monthStart={workingHolidayYear.month}
							displayCurrentMonth={loadEvents}
							displayAnnual={false}
							displayAllTime={false}
							rowSlot={
								<IonButton color='primary' onClick={handleOnPrint}>
									Print
								</IonButton>
							}
						/>
					)}
				</IonCol>
			</IonRow>
		</>
	);
};

export default HolidayChart;

import { DateTime } from "luxon";
import { useState } from "react";

const useDateHandler = () => {
	const [date, setDate] = useState<DateTime | null>(null)

	return {
		date,
		setDate
	};
};

export default useDateHandler;

import { csrf } from '../../../helpers/csrf';
import axios from '../../../lib/axios';

const useConversation = () => {
	const save = (
		participants: any,
		conversationName: string,
		callback: Function,
		conversation_id?: string
	) => {
		const data: any = {
			name: conversationName,
			participants: [...participants],
		};

		saveConversation(data, callback, conversation_id);
	};

	const saveConversation = (
		data: any,
		callback: Function,
		conversation_id?: string
	) => {
		let url = '/api/conversations';
		if (conversation_id !== undefined) {
			url += '/' + conversation_id;
		}
		csrf().then(() => {
			axios
				.put(url, data)
				.then((result) => {
					callback(result);
				})
				.catch((error) => {
					console.error(error);
				});
		});
	};

	return {save};
};

export default useConversation;

import { useState } from 'react';
import { IonCard } from '@ionic/react';
import { useHistory } from 'react-router';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Loading from '../../../components/UI/Loading';
import { processForm } from '../../../api/forms';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { mandatoryFieldChecks } from '../../../components/Forms/FormValidation';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import ClientDetails from './forms/ClientDetails';

interface RouteIndexComponentExtended extends RouteIndexComponent {
	usageMode?: string;
	usageModeCompletion?: Function;
	triggerSave?: number | undefined;
}

const AddClient: React.FC<RouteIndexComponentExtended> = ({ uid, routeTitle, permissionTo, usageMode, usageModeCompletion, triggerSave }) => {
	let history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);

	const createClient = (e: any, formData: any) => {
		saveForm(formData);
	}

	const saveForm = (formData: any) => {
		// Check if any of the form's fields are mandatory and stop the form they're empty
		if (mandatoryFieldsEmpty(formData) === true) return;

		// Strip the form data down to DB data fields only
		const fd: any = processForm(formData);

		setLoading(true);

		axios.put("/api/jobs/clients_list/client_card", fd).then((res: any) => {
			switch (usageMode) {
				case 'modal':
					if (usageModeCompletion) usageModeCompletion();
					break;
				default:
					history.replace("/jobs/clients_list");
					break;
			}

		}).catch((err: any) => {
			setLoading(false);
			showToast("error", err.response.data.message);
		});
	}

	function mandatoryFieldsEmpty(items: any) {
		let hasEmptyFields = false;

		if (items.some((item: any) => item.hasOwnProperty('isMandatory') && item.isMandatory === true)) {
			const checks = mandatoryFieldChecks(items);

			if (checks.formValid !== true) {
				showToast("error", checks.errorMessage);
				hasEmptyFields = true;
			}
		}

		return hasEmptyFields;
	}

	// Build the output
	let clientDetailsForm = (
		<>
			{loading && <Loading overlay={true} fixed={usageMode === 'modal'} />}
			<ClientDetails
				newClient={true}
				createClient={createClient}
				permissionTo={permissionTo}
				usageMode={usageMode}
				triggerSave={triggerSave}
			/>
		</>
	);

	switch (usageMode) {
		case 'modal':
			// Render with no container
			break;
		default:
			clientDetailsForm = (
				<IonCard className="table-card">
					{clientDetailsForm}
				</IonCard>
			);
			break;
	}

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				{!usageMode && <TitleBar title={routeTitle} />}
				{clientDetailsForm}
			</div>
		</>
	);
};

export default AddClient;

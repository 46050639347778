import { useRef, useState } from 'react';
import { useIonAlert, IonButton } from '@ionic/react';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { getTimezone, underscoredToUppercasedWords } from '../../../../lib/functions';

interface Props {
	clientId: string;
}

const EstimatesIndex: React.FC<Props> = (props: Props) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [curMonth, setCurMonth] = useState<number>(13);
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const [estimateId, setEstimateId] = useState<string | null>(null);
	const { clientId }: any = useParams();
	const [presentAlert] = useIonAlert();
	const permissionTo = usePermissionTo('jobs.estimates_list');
	let history = useHistory();

	const colData = [
		{
			field: 'number',
			headerName: 'Estimate Number',
			flex: 1,
			sortable: true,
			resizable: true,
		},
		{
			field: 'job_name',
			headerName: 'Job Name',
			flex: 1,
			sortable: true,
			resizable: true,
		},
		{
			field: 'issue_date',
			headerName: 'Issue Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
			sort: 'desc',
		},
		{
			field: 'expiry_date',
			headerName: 'Expiry Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'value',
			headerName: 'Value Gross',
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value) {
					return params.data.value.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'status',
			headerName: 'Status',
			sortable: true,
			resizable: true,
			flex: 1,
			cellRenderer: (params: any) => {
				const statusWord = params.getValue();
				if (statusWord) {
					return underscoredToUppercasedWords(statusWord);
				}
			},
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') &&
					params.getValue() !== false && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const loadGridData = (month: number, year: number) => {
		setGridLoading(true);
		setData([]);
		setEstimateId(null);

		axios
			.post('/api/jobs/estimates_list', {
				month: month,
				year: year,
				...getTimezone(),
				client_id: clientId,
			})
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleDelete = (id: string) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Estimate',
				message: 'Are you sure you want to delete this estimate?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleDeleteGo(id);
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		setEstimateId(null);

		axios
			.delete(`/api/jobs/estimates_list/estimates_card/${id}`)
			.then(() => {
				loadGridData(curMonth, curYear);
				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete' && params.rowPinned !== 'bottom') {
			params.node.setSelected(true);
			setEstimateId(params.data.id);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete' && params.rowPinned !== 'bottom') {
			handleCellClicked(params);
			handleEditEstimate();
		}
	};

	const handleEditEstimate = () => {
		if (permissionTo('update', 'jobs.estimates_list.estimates_card')) {
			const path = `/jobs/estimates_list/estimates_card/${estimateId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	return (
		<>
			<DataGrid
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				title={'Estimates'}
				cols={colData}
				data={data}
				rowCursorPointer={true}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: loadGridData,
					initialLoad: true,
					defaultAnnual: true,
					rowSlot: (
						<IonButton
							className='btn-edit'
							color='primary'
							disabled={estimateId === null}
							onClick={() => handleEditEstimate()}
						>
							Edit Estimate
						</IonButton>
					),
				}}
			/>
		</>
	);
};

export default EstimatesIndex;

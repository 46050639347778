import { useEffect, useRef, useState } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { dateFormatter, timeFormatter } from '../../../../helpers/dateFormatter';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { uploadCell } from '../../../../helpers/uploadCell';
import { getTimezone } from '../../../../lib/functions';
import { DateTime } from 'luxon';
import { breadcrumbsClasses } from '@mui/material';
import { faBuildingCircleXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
	usageMode?: 'workerCard' | undefined;
	usageModeId?: string | undefined;
	usageModeCallback?: Function | undefined;
}

const AddUploads: React.FC<Props> = (props: Props) => {
	const [data, setData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const toastId = useRef<any>(null);
	const permissionTo = usePermissionTo('workers.holidays_and_absences.training_or_event');
	const [tableTitle, setTableTitle] = useState<string>('');
	const [curMonth, setCurMonth] = useState<number>(props.usageMode === 'workerCard' ? 13 : 0);
	const [curYear, setCurYear] = useState<number>(
		props.usageMode === 'workerCard' ? Number(DateTime.now().toFormat('yyyy')) : 0
	);

	useEffect(() => {
		loadGridData(curMonth, curYear);

		switch (props.usageMode) {
			case 'workerCard':
				setTableTitle('Training and Other Events');
				break;
			default:
				setTableTitle('Uploads');
				break;
		}
	}, []);

	// Build the columns
	let columns: Array<any> = [];

	if (props.usageMode !== 'workerCard') {
		columns = columns.concat([
			{
				headerName: 'Worker Name',
				field: 'last_name',
				sortable: true,
				cellRenderer: (params: any) => {
					return `${params.data.first_name} ${params.data.last_name}`;
				},
				flex: 1,
			},
		]);
	}

	columns = columns.concat([
		{
			field: 'start',
			headerName: 'Date',
			valueFormatter: (params: any) => {
				if (!String(params.value).includes('Total')) {
					return dateFormatter(params);
				}
			},
			filter: 'agDateColumnFilter',
			width: 110,
			sort: 'desc',
		},
		{
			field: 'start',
			headerName: 'Time',
			valueFormatter: (params: any) => {
				if (!String(params.data['start']).includes('Total')) {
					return timeFormatter(params);
				} else {
					return params.data['time'];
				}
			},
			filter: 'agDateColumnFilter',
			width: 90,
		},
		{ field: 'type', width: 120 },
		{ field: 'description', flex: 1 },
		{
			field: 'location',
			valueFormatter: (params: any) => {
				if (params.data.location) {
					const loc = params.data.location;
					let address: Array<any> = [loc.address_1];
					if (loc.town) address.push(loc.town);
					if (loc.postcode) address.push(loc.postcode);
					return address.join(', ');
				}
			},
			flex: 1,
		},
		...uploadCell(permissionTo, {
			mainId: 'request_id',
			subId: 'worker_id',
			toastId,
			setIsLoading,
			setData,
		}),
	]);

	const gridControls = () => {
		let controls: any = {};

		if (props.usageMode === 'workerCard') {
			controls = {
				filterButtons: true,
				filterOptions: {
					displayCurrentMonth: loadGridData,
					defaultAnnual: true,
				},
			};

			return controls;
		}
	};

	const loadGridData = (month: number, year: number) => {
		setData([]);

		let payload: any = { month, year, ...getTimezone() };

		switch (props.usageMode) {
			case 'workerCard':
				payload.worker_id = props.usageModeId;
				break;
			default:
				payload.enable_uploads = true;
				break;
		}

		axios
			.post('/api/workers/holidays_and_absences/requests/attendees', payload)
			.then((res: any) => {
				setData(res.data);
			})
			.catch(() => {
				showToast('error');
			});
	};

	const totalRow = function (rowdata: any) {
		let result: any = [
			{
				start: 'Total:',
				time: rowdata ? rowdata.length : 0,
				uploaded: 'pinned-row',
			},
		];
		return result;
	};

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			<DataGrid
				title={tableTitle}
				cols={columns}
				data={data}
				useSearch={false}
				totalsRow={(props.usageMode === 'workerCard') ? totalRow : undefined}
				{...gridControls()}
			/>
		</>
	);
};

export default AddUploads;

import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { IonButton, IonRow } from '@ionic/react';
import './MyPage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCircleCheck,
	faCircleXmark,
	faMobileScreen,
	faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { useLocation, useParams } from 'react-router';
import logoTitleBar from '../../../../assets/images/logos/hf_logo_title_bar.png';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import { MyPageData } from '../types';
import { DateTime } from 'luxon';
import GeneralError from '../../../../components/UI/GeneralError';
import { currencyFormatter } from '../../../../helpers/currencyFormatter';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Photo from '../../../../components/Media/Photo';
import { toast } from 'react-toastify';
import { EstimateStatus } from '../../../Jobs/Estimates/estimate-types.d';

const MyPageIndex: React.FC = () => {
	const { externalId, jobId }: any = useParams();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [errorLoading, setErrorLoading] = useState<boolean>(false);
	const [data, setData] = useState<MyPageData | null>(null);
	const [isLocked, setIsLocked] = useState<boolean>(false);
	const [styles, setStyles] = useState<any>({});
	const DATE_FMT: string = 'dd/MM/yyyy';
	const axios = Axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			Accept: 'application/json',
		},
	});
	let toastId: any = null;

	useEffect(() => {
		loadData();
	}, []);

	const buildAddress = (input: any): Array<string> => {
		const cd: Array<any> = [];
		if (input) {
			if (input?.address_1) cd.push(input.address_1);
			if (input?.address_2) cd.push(input.address_2);
			if (input?.town) cd.push(input.town);
			if (input?.postcode) cd.push(input.postcode);
		}
		return cd;
	};

	const loadData = () => {
		setIsLoading(true);
		setErrorLoading(false);

		axios
			.get(
				`/external/client/my-page/${externalId}/${jobId}?signature=${urlParams.get('signature')}`
			)
			.then((res: any) => {
				if (res?.data) {
					setData(res.data);

					if (res.data?.styles && Array.isArray(res.data.styles) && res.data.styles.length === 1) {
						setStyles((prevState: any) => {
							const headerImage: string | null = res.data.styles[0].header ?? null;
							const headerStyleObj: any = headerImage
								? {
										backgroundImage: `url(${res.data.styles[0].header})`,
								  }
								: null;
							const footerImage: string | null = res.data.styles[0].footer ?? null;
							const footerStyleObj: any = footerImage
								? {
										backgroundImage: `url(${res.data.styles[0].footer})`,
								  }
								: null;
							const logoImage: string | null = res.data.styles[0].logo ?? null;
							const logoStyleObj: any = logoImage
								? {
										backgroundImage: `url(${res.data.styles[0].logo})`,
								  }
								: null;

							return {
								...prevState,
								header: headerStyleObj,
								footer: footerStyleObj,
								logo: logoStyleObj,
								payment_information: res.data.styles[0]?.payment_information,
							};
						});
					}

					// Check if this estimate is now approved or declined
					if (
						res.data?.estimate?.status_enum === EstimateStatus.APPROVED ||
						res.data?.estimate?.status_enum === EstimateStatus.REFUSED
					) {
						setIsLocked(true);
					}
				}
			})
			.catch(() => setErrorLoading(true))
			.finally(() => setIsLoading(false));
	};

	const handleAction = (action: 'approved' | 'refused') => {
		setIsLoading(true);

		toastId = toast.loading('Please wait...');

		axios
			.get(
				`/external/client/my-page/${externalId}/${jobId}?signature=${urlParams.get(
					'signature'
				)}&status=${action}`
			)
			.then(() => {
				showToast('success', `Successfully ${action}`, toastId);
				loadData();
			})
			.catch(() => {
				showToast('error', null, toastId);
				setIsLoading(false);
			});
	};

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			{errorLoading && <GeneralError />}
			{data && !errorLoading && (
				<div className='page-wrapper external-my-page'>
					{styles?.header && <div className='header-custom' style={styles?.header}></div>}
					{!styles?.header && (
						<div className='header-default'>
							<div>Client Page</div>
							<div>
								<img src={logoTitleBar} />
							</div>
						</div>
					)}

					<div className='banner'>
						<div>Your Estimate</div>
						<div>Status: {data?.estimate.status}</div>
					</div>

					<div className='estimate-header'>
						{styles?.logo && <div className='company-logo' style={styles?.logo}></div>}

						<div className='company-details'>
							<div className='company-address'>
								<h1>{`${data?.company_details.company_name}`}</h1>
								{buildAddress(data?.company_details.trading).map((item: any, index: number) => (
									<div key={index} className='address-item'>
										{item}
									</div>
								))}
								<div className='address-item'>{data?.company_details.general_tel}</div>
								<div className='address-item'>{data?.company_details.general_email}</div>
							</div>
							<div className='basic-details'>
								<div className='basic-details-item'>
									<h2>Estimate</h2>
									<div>{data?.estimate.number}</div>
								</div>

								<div className='basic-details-item'>
									<h2>Date</h2>
									<div>
										{data?.estimate.issue_date
											? DateTime.fromISO(data.estimate.issue_date).toFormat(DATE_FMT)
											: ''}
									</div>
								</div>

								<div className='basic-details-item'>
									<h2>Total</h2>
									<div>£{currencyFormatter({ value: data?.estimate_total })}</div>
								</div>
							</div>
						</div>
					</div>

					<div className='sub-section-divider'></div>

					<div className='receiver-details'>
						<h2>To</h2>
						<h1>
							{data?.estimate?.client.company_name
								? data.estimate.client.company_name
								: `${data?.estimate.client.first_name} ${data?.estimate.client.last_name}`}
						</h1>
						{data?.estimate.client.mobile && (
							<div className='address-item'>
								<FontAwesomeIcon icon={faMobileScreen} />
								{data.estimate.client.mobile}
							</div>
						)}
						{data?.estimate.client.email && (
							<div className='address-item'>
								<FontAwesomeIcon icon={faEnvelope} className='fa-envelope' />
								<Link
									to='#'
									onClick={(e: any) => {
										window.location.href = `mailto:${data.estimate.client.email}`;
										e.preventDefault();
									}}
								>
									{data.estimate.client.email}
								</Link>
							</div>
						)}
					</div>

					{data?.estimate?.items && data.estimate.items.length > 0 && (
						<>
							<div className='sub-section-divider ssd-invisible'></div>
							<div className='items-table'>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Description</TableCell>
											<TableCell>Rate</TableCell>
											<TableCell>Qty</TableCell>
											<TableCell>Amount</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data?.estimate.items.map((item: any, index: number) => (
											<TableRow key={index} className='address-item'>
												<TableCell>{item.description}</TableCell>
												<TableCell>
													{currencyFormatter({ value: item.price }, false, true)}
												</TableCell>
												<TableCell>{item.quantity.toLocaleString()}</TableCell>
												<TableCell>
													{currencyFormatter({ value: item.amount }, false, true)}
												</TableCell>
											</TableRow>
										))}
										<TableRow className='total-row'>
											<TableCell colSpan={3}>Sub Total</TableCell>
											<TableCell>
												{currencyFormatter(
													{
														value: data.estimate.items.reduce(
															(acc: any, cur: any) => acc + cur.amount,
															0
														),
													},
													false,
													true
												)}
											</TableCell>
										</TableRow>
										{data?.estimate?.tax_exclusive === true && (
											<TableRow className='total-row'>
												<TableCell colSpan={3}>VAT</TableCell>
												<TableCell>
													{currencyFormatter(
														{
															value: data?.estimate_total - data?.estimate_subtotal,
														},
														false,
														true
													)}
												</TableCell>
											</TableRow>
										)}
										<TableRow className='total-row'>
											<TableCell colSpan={3}>Total</TableCell>
											<TableCell>
												{currencyFormatter(
													{
														value: data?.estimate_total ? data.estimate_total : 0,
													},
													false,
													true
												)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</div>
						</>
					)}

					<div className='banner'>
						<div>Your Job Details</div>
					</div>

					<div className='your-job-details'>
						<div className='job-details-row'>
							<div className='left'>Target start date:</div>
							<div className='right'>
								{data?.target_start_date
									? DateTime.fromISO(data?.target_start_date).toFormat(DATE_FMT)
									: '-'}
							</div>
						</div>
						<div className='job-details-row'>
							<div className='left'>Target finish date:</div>
							<div className='right'>
								{data?.target_finish_date
									? DateTime.fromISO(data?.target_finish_date).toFormat(DATE_FMT)
									: '-'}
							</div>
						</div>
						<div className='job-details-row'>
							<div className='left'>Your job manager:</div>
							<div className='right'>{data?.job_manager_name}</div>
						</div>
						<div className='job-details-row'>
							<div className='left'>Job manager contact details:</div>
							<div className='right'>{data?.job_manager_details}</div>
						</div>
					</div>

					{data?.photos && data.photos.length > 0 && (
						<>
							<div className='banner'>
								<div>Your Photo Gallery</div>
							</div>

							<div className='photo-gallery'>
								<IonRow>
									{data.photos.map((photo: any, index: number) => {
										let photoDescription: Array<string> = [];

										if (photo.postcode) photoDescription.push(photo.postcode);
										if (photo.town) photoDescription.push(photo.town);
										if (photo.created_at)
											photoDescription.push(DateTime.fromISO(photo.created_at).toFormat(DATE_FMT));
										if (photo.created_at)
											photoDescription.push(DateTime.fromISO(photo.created_at).toFormat('HH:mm'));

										return <Photo key={index} path={photo.thumb} description={photoDescription} />;
									})}
								</IonRow>
							</div>
						</>
					)}

					{data?.invoice?.items && data.invoice.items.length > 0 && (
						<>
							<div className='banner'>
								<div>Your Job Invoice</div>
							</div>

							<div className='items-table'>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Description</TableCell>
											<TableCell>Rate</TableCell>
											<TableCell>Qty</TableCell>
											<TableCell>Amount</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.invoice.items.map((item: any, index: number) => (
											<TableRow key={index} className='address-item'>
												<TableCell>{item.description}</TableCell>
												<TableCell>
													{currencyFormatter({ value: item.price }, false, true)}
												</TableCell>
												<TableCell>{item.quantity.toLocaleString()}</TableCell>
												<TableCell>
													{currencyFormatter({ value: item.amount }, false, true)}
												</TableCell>
											</TableRow>
										))}
										<TableRow className='total-row'>
											<TableCell colSpan={3}>Sub Total</TableCell>
											<TableCell>
												{currencyFormatter(
													{
														value: data.invoice.items.reduce(
															(acc: any, cur: any) => acc + cur.amount,
															0
														),
													},
													false,
													true
												)}
											</TableCell>
										</TableRow>
										{data?.invoice?.tax_exclusive === true && (
											<TableRow className='total-row'>
												<TableCell colSpan={3}>VAT</TableCell>
												<TableCell>
													{currencyFormatter(
														{
															value: data?.invoice_total - data?.invoice_subtotal,
														},
														false,
														true
													)}
												</TableCell>
											</TableRow>
										)}
										<TableRow className='total-row'>
											<TableCell colSpan={3}>Total</TableCell>
											<TableCell>
												{currencyFormatter(
													{
														value: data?.invoice_total ? data.invoice_total : 0,
													},
													false,
													true
												)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</div>

							{(styles?.payment_information || data?.company_details?.vat_number) && (
								<h1>Payment Information</h1>
							)}

							{styles?.payment_information && (
								<div className='text-item'>
									<h2>Payment Instructions</h2>
									{styles.payment_information}
								</div>
							)}

							{data?.company_details?.vat_number && (
								<div className='text-item'>
									<h2>VAT Registration Number</h2>
									{data.company_details.vat_number}
								</div>
							)}
						</>
					)}

					<div className='button-bar'>
						<IonButton onClick={window.print} disabled={isLoading}>
							<FontAwesomeIcon icon={faPrint} className='pe-1' />
							Print
						</IonButton>
						{!urlParams.has('internal') && (
							<>
								<IonButton onClick={() => handleAction('refused')} disabled={isLoading || isLocked}>
									<FontAwesomeIcon icon={faCircleXmark} className='pe-1' />
									Refuse
								</IonButton>
								<IonButton
									onClick={() => handleAction('approved')}
									disabled={isLoading || isLocked}
								>
									<FontAwesomeIcon icon={faCircleCheck} className='pe-1' />
									Approve
								</IonButton>
							</>
						)}
					</div>

					{styles?.footer && <div className='footer-custom' style={styles?.footer}></div>}
				</div>
			)}
		</>
	);
};

export default MyPageIndex;

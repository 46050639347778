import EstimatesProvider from '../EstimatesProvider';
import EstimateCardLayout from './EstimateCardLayout';

interface Props {
	permissionTo: Function;
	isNew: boolean;
	jobId?: string;
	estimateId?: string;
}

const EstimateCard: React.FC<Props> = ({ permissionTo, isNew, jobId, estimateId }) => {
	return (
		<EstimatesProvider>
			<EstimateCardLayout
				permissionTo={permissionTo}
				isNew={isNew}
				jobId={jobId}
				estimateId={estimateId}
			/>
		</EstimatesProvider>
	);
};

export default EstimateCard;

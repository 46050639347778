import { TimesheetsState } from './timesheets-types';
import { Actions as Action, ActionType } from './actions';
import { DateTime } from 'luxon';

export const timesheetsReducer = (state: TimesheetsState, action: Action): TimesheetsState => {
	switch (action.type) {
		case ActionType.SET_WEEK_ENDING: {
			const { payload } = action;

			const weekEnding: DateTime = payload;
			const year: number = weekEnding.year;

			return {
				...state,
				weekEnding,
				year,
			};
		}
		case ActionType.SET_YEAR: {
			const { payload } = action;

			const year: number = payload;
			let weekEnding: DateTime = state.weekEnding.set({ year: year }).endOf('week');

			// Check if incrementing the year then correct the week backwards
			if (year > state.year) weekEnding = weekEnding.minus({ week: 1 });

			// Ensure the week ending is within the current year
			if (weekEnding.year > year) weekEnding = weekEnding.minus({ week: 1 });
			if (weekEnding.year < year) weekEnding = weekEnding.plus({ week: 1 });

			return {
				...state,
				year,
				weekEnding,
			};
		}
		case ActionType.SET_SHOW_MY_TEAM: {
			const { payload } = action;

			const showMyTeam: boolean = payload;

			return {
				...state,
				showMyTeam,
			};
		}
		case ActionType.SET_WORKER_REPORT_MONTH: {
			const { payload } = action;

			return {
				...state,
				workerReportMonth: payload,
			};
		}
		case ActionType.SET_WORKER_REPORT_YEAR: {
			const { payload } = action;

			return {
				...state,
				workerReportYear: payload,
			};
		}
		default: {
			return state;
		}
	}
};

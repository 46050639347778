import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../../../../components/UI/Loading';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';
import { ICellRendererParams } from '@ag-grid-community/core';

type Props = {
	onClose: Function;
	setAllocationCostHead: Function;
};

const CostHeadModal = forwardRef<Props, any>((props: Props, ref: any) => {
	const [didPresent, setDidPresent] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const gridRef: any = useRef<any>();
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridData, setGridData] = useState<Array<any>>([]);
	const [costHead, setCostHead] = useState<{ costHeadId: string; costHead: string } | undefined>(
		undefined
	);

	const gridColumns: Array<any> = [
		{
			field: 'type',
			headerName: 'Type',
			rowGroup: true,
			hide: true,
		},
		{
			field: 'costhead',
			headerName: 'Cost Head',
			flex: 1,
			sortable: true,
		},
	];

	useEffect(() => {
		if (didPresent && gridReady) loadData();
	}, [didPresent, gridReady]);

	const handleOnDidPresent = () => {
		setDidPresent(true);
	};

	const loadData = () => {
		setIsLoading(true);
		axios
			.get('/api/timesheets/costheads')
			.then((res: any) => {
				setGridData(res.data);
			})
			.catch(() => showToast('error'))
			.finally(() => setIsLoading(false));
	};

	const onClose = () => {
		// Reset
		setDidPresent(false);
		setGridData([]);
		setIsLoading(true);
		setCostHead(undefined);

		props.onClose();
	};

	const handleRowClicked = (params: any) => {
		if (params.node.group === true) return;
		params.node.setSelected(true);
		setCostHead({ costHeadId: params.data._id, costHead: params.data.costhead });
	};

	const handleRowDoubleClicked = (params: any) => {
		if (params.node.group === true) return;
		handleRowClicked(params);
		handleSetCostHead();
	};

	const handleSetCostHead = () => {
		if (!costHead) {
			showToast('error', 'Please select a cost head from the list');
			return;
		}

		props.setAllocationCostHead(
			ref.current.modalData.allocationId,
			costHead.costHeadId,
			costHead.costHead
		);

		onClose();
	};

	const groupRowRendererParams = useMemo(() => {
		return {
			innerRenderer: (props: ICellRendererParams) => {
				return props.value;
			},
			suppressCount: true,
		};
	}, []);

	return (
		<IonModal
			ref={ref}
			class='timesheets-cost-head-modal'
			style={{ '--width': '60vw', '--height': '70vh' }}
			onDidPresent={handleOnDidPresent}
			onWillDismiss={() => onClose()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Cost Heads</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{isLoading && <Loading overlay={true} />}
				{didPresent && (
					<DataGrid
						ref={gridRef}
						onGridReady={() => setGridReady(true)}
						cols={gridColumns}
						data={gridData}
						title=''
						useSearch={true}
						compact={false}
						rowCursorPointer={true}
						rowClickedFunction={handleRowClicked}
						rowDoubleClickedFunction={handleRowDoubleClicked}
						groupDisplayType={'groupRows'}
						groupDefaultExpanded={1}
						groupRowRendererParams={groupRowRendererParams}
					/>
				)}
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size='12' className='text-right'>
							<IonButton color='success' onClick={handleSetCostHead} disabled={!costHead}>
								Set Cost Head
							</IonButton>
							<IonButton color='secondary' onClick={() => onClose()}>
								Close
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
});

export default CostHeadModal;

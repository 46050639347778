import { useEffect, useState } from 'react';
import { IonRow, IonCol, IonCard } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { Calendar, CalendarControls } from 'react-yearly-calendar';
import './BankHolidaysIndex.scss';
import axios from '../../../../lib/axios';
import Loading from '../../../../components/UI/Loading';
import { DateTime } from 'luxon';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';

const BankHolidaysIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const [year, setYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const [customClasses, setCustomClasses] = useState<any>({});
	const [customTitles, setCustomTitles] = useState<Array<any>>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const mapTitles = (day: any) => {
		return customTitles.filter((t: any) => t.date === day.format('YYYY-MM-DD'))[0]?.title;
	};

	useEffect(() => {
		loadBankHolidays();
	}, [year]);

	const loadBankHolidays = () => {
		setLoading(true);

		let payload: any = {
			start: DateTime.fromFormat(year.toString(), 'yyyy'),
			end: DateTime.fromFormat(year.toString(), 'yyyy').plus({ year: 1 }),
		};

		axios
			.post('/api/utilities/general/bank_holidays', payload)
			.then((res: any) => {
				const events = res.data;
				let eventsArray: Array<any> = [];
				let titlesArray: Array<any> = [];

				if (events.length > 0) {
					events.forEach((event: any) => {
						let startDate = DateTime.fromISO(event.start).toFormat('yyyy-MM-dd');
						eventsArray.push(startDate);
						titlesArray.push({
							date: startDate,
							title: event.name,
						});
					});

					setCustomClasses({ holidays: eventsArray });
					setCustomTitles(titlesArray);
				}
			})
			.finally(() => setLoading(false));
	};

	const onPrevYear = () => {
		setYear((prevState: number) => (prevState -= 1));
	};

	const onNextYear = () => {
		setYear((prevState: number) => (prevState += 1));
	};

	const goToToday = () => {
		setYear((prevState: number) => Number(DateTime.now().toFormat('yyyy')));
	};

	return (
		<>
			<TitleBar title={routeTitle} />
			<IonCard className='table-card'>
				<IonRow>
					<IonCol size={'12'} className='bank-holidays-calendar-container'>
						{loading && <Loading overlay={true} />}
						<CalendarControls
							year={year}
							showTodayButton={true}
							onPrevYear={onPrevYear}
							onNextYear={onNextYear}
							goToToday={goToToday}
						/>
						<Calendar
							year={year}
							firstDayOfWeek={1}
							customClasses={customClasses}
							titles={mapTitles}
						/>
					</IonCol>
				</IonRow>
			</IonCard>
		</>
	);
};

export default BankHolidaysIndex;

import { useContext, useState } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { AutoComplete, InfoBox } from '../../../../components/Forms/FormFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { IonButton, IonInput } from '@ionic/react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import CatalogueItemsOptions from '../../../../utils/autocomplete/CatalogueItemsOptions';
import CatalogueModal from '../../shared/estimates-invoices/CatalogueModal';
import { customSelectStyles } from '../../shared/estimates-invoices/vars';
import { components } from 'react-select';
import { VatRate } from './types';

interface Props {
	rowType: 'estimate' | 'invoice';
	state: any;
	localState: any;
	loadCatalogueItems: Function;
	onItemInput: Function;
	onItemChange: Function;
	onSelectChange: Function;
	itemChangeUpdateRow: Function;
	onDeleteRow: Function;
	index: number;
	catalogueItems: any;
	vatRates: any;
	amountSpinner: boolean;
}

const ItemRowStructure: React.FC<Props> = ({
	rowType,
	state,
	localState,
	loadCatalogueItems,
	onItemInput,
	onItemChange,
	onSelectChange,
	itemChangeUpdateRow,
	onDeleteRow,
	index,
	catalogueItems,
	vatRates,
	amountSpinner,
}) => {
	// Modals
	const [catalogueModal, setCatalogueModal] = useState<{ isOpen: boolean }>({ isOpen: false });

	// Setup
	let vatRegistered = null;
	switch (rowType) {
		case 'estimate':
			vatRegistered = state.estimateData?.vatRegistered;
			break;
		case 'invoice':
			vatRegistered = state.invoiceData?.vatRegistered;
			break;
	}

	const NoOptionsMessage = (props: any) => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className={`link-button`} onClick={() => setCatalogueModal({ isOpen: true })}>
					<FontAwesomeIcon icon={faPlusCircle} className='icon-add-catalogue-item' />
					Add New Catalogue Item
				</span>
			</components.NoOptionsMessage>
		);
	};

	return (
		<>
			<TableRow className='prop-form'>
				<TableCell>
					<AutoComplete
						value={{
							label: localState.item,
							value: localState.item,
						}}
						isSearchable={true}
						onChangeCallback={(newValue: any) => {
							onItemInput(newValue, 'item', index);
							onSelectChange(index, newValue, 'item');
							itemChangeUpdateRow(newValue);
						}}
						isClearable={true}
						defaultOptions={false}
						cacheOptions={false}
						options={catalogueItems}
						loadOptions={CatalogueItemsOptions}
						components={{ NoOptionsMessage }}
						useLabels={false}
						editMode={true}
					/>
				</TableCell>
				<TableCell>
					<IonInput
						placeholder='Description'
						value={localState.description}
						onInput={(e: any) => onItemInput(e, 'description', index)}
						onIonChange={() => onItemChange('description', index, state, localState)}
					/>
				</TableCell>
				{rowType === 'invoice' && (
					<TableCell>
						<IonInput
							type='number'
							className='ion-number-input hide-number-spinner'
							value={localState.percentageComplete}
							onInput={(e: any) => onItemInput(e, 'percentageComplete', index)}
							onIonChange={() => onItemChange('percentageComplete', index, state, localState)}
						/>
					</TableCell>
				)}
				<TableCell>
					<IonInput
						type='number'
						className='ion-number-input hide-number-spinner'
						value={localState.quantity}
						onInput={(e: any) => onItemInput(e, 'quantity', index)}
						onIonChange={() => onItemChange('quantity', index, state, localState)}
					/>
				</TableCell>
				<TableCell>
					<IonInput
						type='number'
						className='ion-number-input hide-number-spinner'
						value={localState.price}
						onInput={(e: any) => onItemInput(e, 'price', index)}
						onIonChange={() => onItemChange('price', index, state, localState)}
					/>
				</TableCell>
				<TableCell>
					<IonInput
						type='number'
						className='ion-number-input hide-number-spinner'
						value={localState.discount}
						onInput={(e: any) => onItemInput(e, 'discount', index)}
						onIonChange={() => onItemChange('discount', index, state, localState)}
					/>
				</TableCell>
				{vatRegistered && (
					<TableCell>
						<SelectStyled
							value={vatRates
								.filter((rateItem: VatRate) => rateItem.id === localState.taxRateId)
								.map((rateItem: VatRate) => {
									return {
										label: rateItem.description,
										value: rateItem.id,
									};
								})}
							options={vatRates.map((rateItem: VatRate) => {
								return {
									label: rateItem.description,
									value: rateItem.id,
								};
							})}
							onChange={(newValue: any) => {
								onItemInput(newValue, 'taxRateId', index);
								onSelectChange(index, newValue, 'taxRateId');
							}}
							isDisabled={amountSpinner}
							isSearchable={false}
							stylesExtra={customSelectStyles}
						/>
					</TableCell>
				)}
				<TableCell className='cell-info'>
					<InfoBox
						value={localState.amount.toLocaleString('en-GB', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
						useLabels={false}
						showBorder={true}
						showBackground={true}
						showSpinner={amountSpinner}
					/>
				</TableCell>
				<TableCell className='cell-button'>
					<IonButton size='small' onClick={() => onDeleteRow(index)} color='danger'>
						<FontAwesomeIcon className='icon-delete' icon={faCircleXmark} />
					</IonButton>
				</TableCell>
			</TableRow>

			<CatalogueModal
				isOpen={catalogueModal.isOpen}
				onClose={() => {
					setCatalogueModal({ isOpen: false });
				}}
				onSuccess={loadCatalogueItems}
			/>
		</>
	);
};

export default ItemRowStructure;

import AssessmentIndex from './Assessment/AssessmentIndex';
import DiaryIndex from './Diary/DiaryIndex';
import HolidayIndex from './Holiday/HolidayIndex';
import MapIndex from './Map/MapIndex';
import MessagesIndex from './Messages/MessagesIndex';
import NotesIndex from './Notes/NotesIndex';
import PhoneUsageIndex from './PhoneUsage/PhoneUsageIndex';
import PhotosIndex from '../../../components/Media/PhotosIndex';
import SickIndex from './Sick/SickIndex';
import TrackingIndex from './Tracking/TrackingIndex';
import TrainingIndex from './Training/TrainingIndex';
import UsageIndex from './Usage/UsageIndex';
import WorkHistoryIndex from './WorkHistory/WorkHistoryIndex';
import WorkerTabsIndex from './WorkerTabs/WorkerTabsIndex';
import WorkerTeamIndex from './WorkerTeam/WorkerTeamIndex';

const EditWorkerSubmenuArray: any = (permissionTo: Function) => {
	return [
		{
			id: 'submenu-workertabs',
			uid: 'workers.workers_list.worker_card.worker_tabs',
			title: 'Worker Tabs',
			hash: 'worker-tabs',
			page: WorkerTabsIndex,
			active: true,
			fullHeight: false,
			pageData: { permissionTo },
		},
		{
			id: 'submenu-workerassessment',
			uid: 'workers.workers_list.worker_card.assessment',
			title: 'Assessment',
			hash: 'assessment',
			page: AssessmentIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workerdiary',
			uid: 'workers.workers_list.worker_card.diary',
			title: 'Diary',
			hash: 'diary',
			page: DiaryIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workerholiday',
			uid: 'workers.workers_list.worker_card.holiday',
			title: 'Holiday',
			hash: 'holiday',
			page: HolidayIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workermap',
			uid: 'workers.workers_list.worker_card.map',
			title: 'Map',
			hash: 'map',
			page: MapIndex,
			active: false,
			fullHeight: true,
			pageData: { permissionTo },
		},
		{
			id: 'submenu-workerphotos',
			uid: 'workers.workers_list.worker_card.photos',
			title: 'Media',
			hash: 'media',
			page: PhotosIndex,
			active: false,
			fullHeight: false,
			pageData: { mediaCategory: 'worker' },
		},
		{
			id: 'submenu-workermessages',
			uid: 'workers.workers_list.worker_card.messages',
			title: 'Messages',
			hash: 'messages',
			page: MessagesIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workermyteam',
			uid: 'workers.workers_list.worker_card.my_team',
			title: 'My Team',
			hash: 'myteam',
			page: WorkerTeamIndex,
			active: false,
			fullHeight: true,
			pageData: { permissionTo },
		},
		{
			id: 'submenu-workernotes',
			uid: 'workers.workers_list.worker_card.notes',
			title: 'Notes',
			hash: 'notes',
			page: NotesIndex,
			active: false,
			fullHeight: true,
			pageData: { permissionTo },
		},
		{
			id: 'submenu-workerphoneusage',
			uid: 'workers.workers_list.worker_card.phone_usage',
			title: 'Phone Usage',
			hash: 'phone-usage',
			page: PhoneUsageIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workersick',
			uid: 'workers.workers_list.worker_card.sick',
			title: 'Sick',
			hash: 'sick',
			page: SickIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workertracking',
			uid: 'workers.workers_list.worker_card.tracking',
			title: 'Tracking',
			hash: 'tracking',
			page: TrackingIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workertraining',
			uid: 'workers.workers_list.worker_card.training',
			title: 'Training',
			hash: 'training',
			page: TrainingIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workerusage',
			uid: 'workers.workers_list.worker_card.system_usage',
			title: 'Usage',
			hash: 'usage',
			page: UsageIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-workerhistory',
			uid: 'workers.workers_list.worker_card.work_history',
			title: 'Work History',
			hash: 'work-history',
			page: WorkHistoryIndex,
			active: false,
			fullHeight: true,
		},
	];
};

export default EditWorkerSubmenuArray;

import React, { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import './KPIRating.scss';
import { useLocation, useParams } from 'react-router';
import logoTitleBar from '../../../../assets/images/logos/hf_logo_title_bar.png';
import { showToast } from '../../../../lib/toast';
import Loading from '../../../../components/UI/Loading';
import { KPIRatingData } from '../types';
import GeneralError from '../../../../components/UI/GeneralError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { Rating } from '@mui/material';
import { toast } from 'react-toastify';

const KPIRatingIndex: React.FC = () => {
	const { externalId, jobId }: any = useParams();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const focusGrabberRef = useRef<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [errorLoading, setErrorLoading] = useState<boolean>(false);
	const [data, setData] = useState<KPIRatingData | null>(null);
	const [styles, setStyles] = useState<any>({});
	const [ratingValue, setRatingValue] = useState<number | null>(null);
	const axios = Axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			Accept: 'application/json',
		},
	});
	let toastId: any = null;

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		setIsLoading(true);
		setErrorLoading(false);

		axios
			.get(
				`/external/client/kpi-rating/${externalId}/${jobId}?signature=${urlParams.get('signature')}`
			)
			.then((res: any) => {
				if (res?.data) {
					setData(res.data);
					setRatingValue(res.data.rating);

					if (res.data?.styles && Array.isArray(res.data.styles) && res.data.styles.length === 1) {
						setStyles((prevState: any) => {
							const headerImage: string | null = res.data.styles[0].header ?? null;
							const headerStyleObj: any = headerImage
								? {
										backgroundImage: `url(${res.data.styles[0].header})`,
								  }
								: null;
							const footerImage: string | null = res.data.styles[0].footer ?? null;
							const footerStyleObj: any = footerImage
								? {
										backgroundImage: `url(${res.data.styles[0].footer})`,
								  }
								: null;
							const logoImage: string | null = res.data.styles[0].logo ?? null;
							const logoStyleObj: any = logoImage
								? {
										backgroundImage: `url(${res.data.styles[0].logo})`,
								  }
								: null;

							return {
								...prevState,
								header: headerStyleObj,
								footer: footerStyleObj,
								logo: logoStyleObj,
							};
						});
					}
				}
			})
			.catch(() => setErrorLoading(true))
			.finally(() => setIsLoading(false));
	};

	const handleSaveRating = (newValue: number | null) => {
		setIsLoading(true);
		setRatingValue(newValue);

		// Stops the tab-switching bug where last clicked star is re-focused
		focusGrabberRef.current.focus();

		toastId = toast.loading('Please wait...');

		axios
			.get(
				`/external/client/kpi-rating/${externalId}/${jobId}?signature=${urlParams.get(
					'signature'
				)}&rating=${newValue}`
			)
			.then(() => {
				showToast('success', 'Thank you for your feedback!', toastId);
			})
			.catch(() => {
				showToast('error', null, toastId);
				setIsLoading(false);
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			{errorLoading && <GeneralError />}
			{data && !errorLoading && (
				<div className='page-wrapper external-kpi-rating'>
					<a id='focus-grabber' ref={focusGrabberRef} href=''></a>
					{styles?.header && <div className='header-custom' style={styles?.header}></div>}
					{!styles?.header && (
						<div className='header-default'>
							<div></div>
							<div>
								<img src={logoTitleBar} />
							</div>
						</div>
					)}

					<div className='rating-icon-container'>
						<div className='rating-icon'>
							<FontAwesomeIcon icon={faStar} />
						</div>
					</div>

					<div className='rating-title'>How did we do?</div>

					<div className='rating-container'>
						<div className='rc-text'>
							<strong>
								Dear{' '}
								{data.company_details.company_name
									? data.company_details.company_name
									: data.company_details.contact_first_name +
									  ' ' +
									  data.company_details.contact_last_name}
								,
							</strong>
						</div>

						<div className='rc-text text-secondary'>
							<strong>{data.job.job_manager_name}</strong> has requested your feedback for the work
							completed on the job <strong>{data.job.job}</strong>. Please provide a rating using
							the stars below.
						</div>

						<div className='rc-rating'>
							<Rating
								size='large'
								value={ratingValue}
								onChange={(event: any, newValue: number | null) => {
									handleSaveRating(newValue);
								}}
							/>
						</div>
					</div>

					{styles?.footer && <div className='footer-custom' style={styles?.footer}></div>}
				</div>
			)}
		</>
	);
};

export default KPIRatingIndex;

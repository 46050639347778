import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
} from '@ionic/react';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import Resources from '../../components/Resources';

interface ResourcesModalProps {
	isOpen: boolean;
	onClose: Function;
	onSuccess: Function;
	jobId: string;
}

const ResourcesModal: React.FC<ResourcesModalProps> = ({ isOpen, onClose, onSuccess, jobId }) => {
	const uid = 'jobs.jobs_list.job_card';

	return (
		<IonModal
			style={{ '--width': '70vw', '--height': '85vh' }}
			isOpen={isOpen}
			onDidDismiss={() => onClose()}
			className='prop-form'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Job Targets and Skills</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='tight-margins-modal'>
				<Resources
					uid={uid}
					jobId={jobId}
					permissionTo={usePermissionTo(uid)}
					usageMode='modal'
					usageModeCompletion={onSuccess}
				/>
			</IonContent>
		</IonModal>
	);
};

export default ResourcesModal;

import { DateTime } from 'luxon';

export enum RequestStatus {
	REQUESTED = 'requested',
	APPROVED = 'approved',
	DECLINED = 'declined',
}

export enum EventType {
	HOLIDAY = 'holiday',
	APPROVED_ABSENCE = 'absence',
}

export enum RequestAction {
	APPROVE = 'approve',
	DECLINE = 'decline',
}

export interface RequestDate {
	start: string;
	end: string;
}

export interface RequestData {
	requestId: string | undefined;
	workerId: string | undefined;
	workerName?: string;
	requestType: EventType;
	requestedDate: DateTime;
	start?: DateTime;
	end?: DateTime;
	startHourIndex?: number;
	endHourIndex?: number;
	period: Number;
	status: RequestStatus;
	approver?: string;
	approvedDate?: DateTime;
	clashes?: Array<any>;
	decisionReason?: string;
}

export interface RequestPayload {
	event_type: EventType;
	worker_ids: Array<string>;
	requested_date: DateTime;
	start: DateTime;
	end: DateTime;
	status: RequestStatus;
}

export interface Clash {
	date: string;
	description: string;
	worker_id: string;
}

export interface HalfDayOptions {
	label: string;
	value: number;
}

import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import Subnav from '../../../../components/Menu/Subnav';
import EditWorkerTabsArray from '../EditWorkerTabsArray';
import { WorkersContext } from '../WorkersProvider';
import { EngagementTypes } from '../workers-types';
import { ActionType } from '../actions';

interface Props {
	permissionTo: Function;
}

const WorkerTabsIndex: React.FC<Props> = (props: Props) => {
	const { state, dispatch } = useContext(WorkersContext);
	const [subnav, setSubnav] = useState<Array<any>>([]);
	const location = useLocation();
	let history = useHistory();

	useEffect(() => {
		// Switch to tab if required
		let jumpToTab: string = '';
		if (location && location.search) {
			// Use the URL location
			const urlParams = new URLSearchParams(location.search);
			if (urlParams.get('tab')) {
				jumpToTab = String(urlParams.get('tab'));

				// Remove the query parameter
				urlParams.delete('tab');
				history.replace({
					search: urlParams.toString(),
				});
			}
		} else {
			// Use the module's state
			jumpToTab = state.activeSubNavTabs.workerTabs;
		}

		// Get the array of tabs along the top of the page
		let array = EditWorkerTabsArray(props.permissionTo);

		// Filter the tabs based on the Worker's engagement type
		array = array.filter(
			(item: any) =>
				!item.hasOwnProperty('engagementType') ||
				item.engagementType === state.worker?.key_details.type_of_engagement
		);

		// Filter the tabs for other settings
		if (
			state.worker?.key_details.type_of_engagement === EngagementTypes.SELF &&
			state.worker?.payroll_details.appears_on_organisation_chart === false
		) {
			array = array.filter((item: any) => item.id !== 'management_structure');
		}

		// Check if jumping to a specific tab
		array = array.map((item: any) => {
			if (jumpToTab && item.id === jumpToTab) {
				item.active = true;
			} else {
				item.active = false;
			}
			return item;
		});

		// Select the first item as the default if no tab specified in the URL
		if (array.every((element: any) => element.active === false)) array[0].active = true;

		setSubnav(array);
	}, [state.worker]);

	const setModuleState = (args: any) => {
		// Update the state's currently selected subnav tab
		dispatch({
			type: ActionType.UPDATE_ACTIVE_SUBNAV_TAB,
			payload: { workerTabs: args.activeItem.id },
		});
	};

	return <>{subnav.length > 0 && <Subnav array={subnav} onTogglePage={setModuleState} />}</>;
};

export default WorkerTabsIndex;

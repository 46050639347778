export const faWorkers = {
	prefix: 'fas',
	iconName: 'workers',
	icon: [
		100,
		100,
		[] as string[],
		null,
		'M73.63,54.12c7.13-2.5,12.27-9.29,12.27-17.26,0-10.09-8.21-18.3-18.3-18.3-3.96,0-7.73,1.25-10.91,3.61l2.12,2.85c2.56-1.9,5.6-2.9,8.79-2.9,8.13,0,14.75,6.61,14.75,14.74s-6.61,14.75-14.75,14.75c-4.11,0-8.07-1.74-10.86-4.76l-2.62,2.41c2.08,2.26,4.7,3.93,7.56,4.91-3.26,.69-6.37,1.94-9.22,3.71-3.63-2.71-7.9-4.63-12.56-5.52,7.77-2.53,13.41-9.84,13.41-18.45,0-10.7-8.71-19.41-19.41-19.41S14.51,23.21,14.51,33.9c0,8.65,5.69,15.99,13.52,18.49-13.79,2.73-24.2,14.61-24.2,28.8h3.56c0-14.25,11.98-25.83,26.7-25.83s26.7,11.59,26.7,25.83h3.56c0-8.17-3.45-15.56-9-20.9,3.77-2.1,8.02-3.21,12.42-3.21,13.74,0,24.92,10.82,24.92,24.11h3.56c0-13.3-9.71-24.43-22.6-27.07Zm-39.72-4.37c-8.74,0-15.85-7.11-15.85-15.85s7.11-15.85,15.85-15.85,15.85,7.11,15.85,15.85-7.11,15.85-15.85,15.85Z',
	],
};

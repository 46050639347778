import { reactionList } from './emojis';

export function swapIcon(msg: string) {
	let obj: any = {};
	msg = swapReactions(msg);
	if (msg.indexOf('{{file:') > -1) {
		const regex = /\{{([^}}]+)\}/;
		const match = msg.match(regex);

		const startIndex = msg.indexOf('{{file:');
		const endIndex = msg.indexOf('}}');
		let start = '';
		let end = '';
		if (startIndex >= 0) {
			start = msg.substring(0, startIndex);
		}
		if (endIndex >= 0) {
			end = msg.substring(endIndex + 2);
		}
		obj.start = start;
		obj.end = end;

		if (match) {
			const betweenBraces = match[1];
			const pieces = betweenBraces.split(':');
			obj.pieces = pieces;
		}
	} else {
		obj.msg = msg;
	}
	return obj;
}

export function swapReactions(msg: string): string {
	const pattern = /\{\{\s*(\w+?\:+?[0-9]+-?[A-Za-z0-9]+?)\s*}\}/g; // {{reaction:1f44d}}
	return msg.replace(pattern, (_, token) => {
		let react = reactionList.find((item: any) => {
			return item.key === token.replace('reaction:', '');
		});
		return react !== null ? react.value : '';
	});
}

export function titleCase(str: string) {
	return str
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return word.replace(word[0], word[0].toUpperCase());
		})
		.join(' ');
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
	return (Object.values(enm) as unknown as string[]).includes(value)
		? (value as unknown as T)
		: undefined;
}

export function initialsFromString(str: string) {
	return str
		.split(' ')
		.map((n) => n[0])
		.join('')
		.toUpperCase();
}

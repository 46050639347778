import EditClient from './EditClient';
import InvoicesIndex from './Invoices/InvoicesIndex';
import EstimatesIndex from './Estimates/EstimatesIndex';
import JobHistoryIndex from './JobHistory/JobHistoryIndex';
import MessagesIndex from './Messages/MessagesIndex';

// TODO: TEMPORARY CLIENT TABS STATUSES TO REFLECT DEVELOPMENT PROGRESS
const EditClientSubmenuArray: any = (permissionTo: Function, clientId: string) => {
	return [
		{
			id: 'submenu-clienttab',
			uid: 'jobs.clients_list.client_card.client_tabs',
			title: 'Client Tab',
			page: EditClient,
			hash: 'client-tab',
			active: true,
			fullHeight: false,
			pageData: { permissionTo },
		},
		{
			id: 'submenu-jobestimates',
			uid: 'jobs.clients_list.client_card.estimates',
			title: 'Estimates',
			page: EstimatesIndex,
			hash: 'estimates',
			active: false,
			fullHeight: true,
			pageData: { clientId },
		},
		{
			id: 'submenu-jobinvoices',
			uid: 'jobs.clients_list.client_card.invoices',
			title: 'Invoices',
			page: InvoicesIndex,
			hash: 'invoices',
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-jobhistory',
			uid: 'jobs.clients_list.client_card.job_history',
			title: 'Job History',
			page: JobHistoryIndex,
			hash: 'job-history',
			active: false,
			fullHeight: true,
			pageData: { permissionTo, clientId },
		},
		{
			id: 'submenu-messages',
			uid: 'jobs.clients_list.client_card.messages',
			title: 'Messages',
			page: MessagesIndex,
			hash: 'messages',
			active: false,
			fullHeight: true,
			pageData: { clientId },
		},
	];
};

export default EditClientSubmenuArray;

import { IonInput } from '@ionic/react';
import { Datepicker } from '@mobiscroll/react';
import { DateTime } from 'luxon';
import React from 'react';

const TimeRow = (
	theTime: string,
	index: number,
	eventData: any,
	eventDataCallback?: Function,
	onChangeCallback?: Function,
	onCloseCallback?: Function
) => {
	const defaultTime: string = '09:00 - 17:00';

	// If no time is provided then set a default
	if (!theTime) {
		theTime = defaultTime;
		if (eventDataCallback) eventDataCallback(index, theTime);
	}

	// The time needs to be turned into an array of date objects
	const arrTime: Array<string> = theTime.split(' - ');
	const theTimeStart = DateTime.now().set({
		hour: Number(arrTime[0].split(':')[0]),
		minute: Number(arrTime[0].split(':')[1]),
	});

	// Sometimes when clicking parts of the UI, the end-time part of the array will be cleared
	let theTimeEnd: DateTime | null;
	if (arrTime.length !== 2) {
		theTimeEnd = null;
	} else {
		theTimeEnd = DateTime.now().set({
			hour: Number(arrTime[1].split(':')[0]),
			minute: Number(arrTime[1].split(':')[1]),
		});
	}

	return (
		<React.Fragment key={index}>
			<Datepicker
				select='range'
				controls={['time']}
				cssClass='mbsc-timepicker-no-clear-buttons'
				stepMinute={15}
				inputComponent={IonInput}
				inputProps={{
					className: 'mb-1 form-time-row',
					readOnly: true,
				}}
				timeFormat='HH:mm'
				touchUi={false}
				returnFormat={'iso8601'}
				value={[theTimeStart, theTimeEnd]}
				onChange={(e: any) => {
					// Check if we need to update
					if (e.valueText !== eventData?.time[index]) {
						if (onChangeCallback) onChangeCallback(index, e, defaultTime);
					}
				}}
				onClose={(e: any) => {
					if (onCloseCallback) onCloseCallback(index, e, defaultTime);
				}}
			/>
		</React.Fragment>
	);
};

export default TimeRow;

export enum ActionType {
	UPDATE_MENU_COUNTS = 'UPDATE_MENU_COUNTS',
}

export interface ACTION_UPDATE_MENU_COUNTS {
	type: ActionType.UPDATE_MENU_COUNTS;
	payload: any;
}

export type Actions = ACTION_UPDATE_MENU_COUNTS;

import { faBuilding, faClipboard, faHome } from '@fortawesome/free-solid-svg-icons';

import { PropMarker } from '../../interfaces/Maps/MapsInterface';
import axios from '../../lib/axios';
import styles from './../../assets/scss/styles.module.scss';
import { IonButton } from '@ionic/react';
import { DateTime } from 'luxon';
import { publish } from '../../helpers/events';

export const mapLocations = async (workerId?: string, fromDashboard?: boolean): Promise<any> => {
	let data: any = {};
	if (workerId !== undefined) {
		data = { worker_id: workerId };
	}

	if (fromDashboard === true) {
		data.dashboard = true;
	}

	return new Promise((res, rej) => {
		axios
			.post('/api/workers/places', data)
			.then((result) => {
				if (result.data) {
					let localMarkers = [];
					result.data.jobs.forEach((job: any) => {
						let obj: PropMarker = {
							latlng: {
								lat: job.location.coordinates[1],
								lng: job.location.coordinates[0],
							},
							title: 'Job: ' + job.name,
							isOpen: false,
							canOpen: true,
							showRadius: true,
							accuracyFill: styles.primary,
							accuracyStroke: styles.primary,
							icon: {
								path: String(faClipboard.icon[4]),
								scale: 0.075,
								anchor: new google.maps.Point(
									faClipboard.icon[0] / 2, // width
									faClipboard.icon[1] / 2 // height
								),
								fillColor: styles.primary,
								fillOpacity: 1,
								strokeColor: '',
								strokeWeight: 0,
							},
							radius: job.boundary,
							date: DateTime.now(),
							infoComponent: (
								<>
									<p>Job: {job.name}</p>
									{!fromDashboard && (
										<div className='align-center'>
											<IonButton
												size='small'
												color='primary'
												onClick={() => {
													publish('message:job', job);
												}}
											>
												Message Job
											</IonButton>
										</div>
									)}
								</>
							),
						};
						localMarkers.push(obj);
					});
					result.data.offices.forEach((office: any) => {
						let obj: PropMarker = {
							latlng: {
								lat: office.location.coordinates[1],
								lng: office.location.coordinates[0],
							},
							title: 'Office: ' + office.name,
							isOpen: false,
							canOpen: true,
							showRadius: true,
							accuracyFill: styles.primary,
							accuracyStroke: styles.primary,
							icon: {
								path: String(faBuilding.icon[4]),
								scale: 0.075,
								anchor: new google.maps.Point(
									faBuilding.icon[0] / 2, // width
									faBuilding.icon[1] / 2 // height
								),
								fillColor: styles.primary,
								fillOpacity: 1,
								strokeColor: '',
								strokeWeight: 0,
							},
							radius: office.boundary,
							date: DateTime.now(),
						};
						localMarkers.push(obj);
					});

					let clusterMarkers: Array<any> = [];
					if (result.data.workers) {
						result.data.workers.forEach((worker: any) => {
							const dt = DateTime.fromISO(worker.datetime);
							const now = DateTime.now();
							let minutes = now.diff(dt, ['minutes']).toObject().minutes ?? 0;
							let color =
								minutes < 15 ? styles.success : styles.quaternary;
							let textColor =
								minutes < 15 ? styles.white : styles.white;
							var matches = worker.name.match(/\b(\w)/g); // ['J','S','O','N']
							var acronym = matches.join(''); // JSON

							let obj: PropMarker = {
								_id: worker._id,
								latlng: {
									lat: worker.location.coordinates[1],
									lng: worker.location.coordinates[0],
								},
								title: 'Worker: ' + worker.name,
								isOpen: false,
								canOpen: true,
								showRadius: false,
								locationName: worker.site.name ?? '',
								accuracyFill: color,
								accuracyStroke: color,
								icon: {
									path: google.maps.SymbolPath.CIRCLE,
									scale: 25,
									fillColor: color,
									fillOpacity: 1,
									strokeColor: '',
									strokeWeight: 0,
								},
								label: {
									color: textColor,
									fontSize: '25px',
									text: acronym,
								},
								radius: worker.accuracy,
								infoComponent: (
									<>
										<p>Name: {worker.name}</p>
										<p>
											Last Seen: {dt.toLocaleString()} {dt.toFormat('HH:mm:ss')}
										</p>
										{!fromDashboard && (
											<div className='align-center'>
												<IonButton
													size='small'
													color='primary'
													onClick={() => {
														publish('message:worker', worker);
													}}
												>
													Message Worker
												</IonButton>
											</div>
										)}
									</>
								),
								date: dt,
							};
							clusterMarkers.push(obj);
						});
					}
					if (result.data.worker !== undefined && result.data.worker !== null) {
						const dt = DateTime.fromISO(result.data.worker.datetime);
						let obj: PropMarker = {
							latlng: {
								lat: result.data.worker.location.coordinates[1],
								lng: result.data.worker.location.coordinates[0],
							},
							title: result.data.worker.name,
							isOpen: false,
							canOpen: false,
							showRadius: true,
							accuracyFill: styles.primary,
							accuracyStroke: styles.primary,
							icon: {
								path: String(faHome.icon[4]),
								scale: 0.075,
								anchor: new google.maps.Point(
									faHome.icon[0] / 2, // width
									faHome.icon[1] / 2 // height
								),
								fillColor: styles.primary,
								fillOpacity: 1,
								strokeColor: '',
								strokeWeight: 0,
							},
							radius: result.data.worker.boundary,
							date: dt,
						};
						localMarkers.push(obj);
					}
					res({ markers: localMarkers, cluster: clusterMarkers });
				}
			})
			.catch((e) => {
				rej(e);
			});
	});
};

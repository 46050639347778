import React, { createContext, useReducer } from 'react';
import { estimatesReducer } from './estimates-reducer';
import { EstimateState } from './estimate-types';
import { Actions as Action } from './actions';

interface ProviderProps {
	children: React.ReactNode;
}

interface ContextProvider {
	state: EstimateState;
	dispatch: React.Dispatch<Action>;
}

const initialState: EstimateState = {
	estimateData: null,
	items: [],
	isLoading: false,
	totals: { subtotal: 0, discount: 0, total: 0, vat: [] },
	totalsLoading: false,
};

const contextProps = {
	state: initialState,
	dispatch: () => null,
};

export const EstimateContext = createContext<ContextProvider>(contextProps);

const EstimatesProvider = ({ children }: ProviderProps) => {
	const [state, dispatch] = useReducer(estimatesReducer, initialState);

	return (
		<EstimateContext.Provider value={{ state, dispatch }}>{children}</EstimateContext.Provider>
	);
};

export default EstimatesProvider;

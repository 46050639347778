import { useParams } from 'react-router-dom';
import AddUploads from '../../HolidayAbsenceTraining/TrainingEvent/AddUploads';

const TrainingIndex = () => {
	const { workerId }: any = useParams();

	return <AddUploads usageMode='workerCard' usageModeId={workerId} />;
};

export default TrainingIndex;

import { useState, useRef, useContext } from 'react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import useWorkerReport from '../useWorkerReport';
import ExtraFooterControls from '../components/ExtraFooterControls';
import { ReportContextType, reportContext } from '../reportContext';
import ReportModal from '../modals/ReportModal';
import { DateTime } from 'luxon';
import { TotalCol, TotalColTitle } from '../report-types';

interface Props {
	parentRef: any;
	title: string;
	uid: string;
	permissionTo: Function;
}

const FailedToRecordIndex: React.FC<Props> = (props: Props) => {
	const reportCtx: ReportContextType = useContext(reportContext);
	const workerReport = useWorkerReport({
		uid: props.uid,
		title: props.title,
		modalColumns: [
			{
				headerName: 'Date Failed to Record',
				field: 'date_failed',
				valueFormatter: (params: any) => DateTime.fromISO(params.value).toFormat('dd/MM/yyyy'),
				sort: 'desc',
				flex: 1,
			},
		],
	});
	const gridUrl = '/api/workers/reports/failed';
	const gridPayload = {
		start: workerReport.startDate,
		end: workerReport.endDate,
		roles: workerReport.chosenRoles.map((value) => value.value),
		payment_method: workerReport.chosenPaymentMethod?.value,
	};
	const [gridReady, setGridReady] = useState<boolean>(false);
	const gridRef: any = useRef<any>();
	const calcTotalCols: TotalCol[] = [{ id: 'failed_to_record_days' }];
	const totalColsTitles: TotalColTitle[] = [{ id: 'worker_name', title: 'Totals' }];
	const colData: Array<any> = [
		{ headerName: 'Worker', field: 'worker_name', flex: 1 },
		{
			headerName: 'Failed to Record (days)',
			field: 'failed_to_record_days',
			headerClass: 'ag-right-aligned-header',
			type: 'rightAligned',
			valueFormatter: (params: any) => workerReport.formatNumber(params.value),
			sort: 'desc',
			flex: 1,
		},
	];

	return (
		<div className='flex-grow-container h-100'>
			<DataGrid
				ref={gridRef}
				onGridReady={() => setGridReady(true)}
				useSearch={false}
				title={props.title}
				cols={colData}
				dataSource={{
					url: gridReady ? gridUrl : '',
					method: 'POST',
					data: gridPayload,
				}}
				excelStyles={workerReport.excelStyles}
				exportExcelButton={true}
				rowCursorPointer={true}
				rowClickedFunction={workerReport.handleRowClicked}
				rowDoubleClickedFunction={workerReport.handleRowDoubleClicked}
				appendedButtons={[workerReport.btnViewDetails()]}
				totalsRow={workerReport.totalRow(calcTotalCols, totalColsTitles)}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: workerReport.setMonthYear,
					initialLoad: false,
					defaultAnnual: false,
				}}
				extraFooter={
					<ExtraFooterControls
						roles={reportCtx.roles}
						chosenRoles={workerReport.chosenRoles}
						setChosenRoles={workerReport.setChosenRoles}
						paymentMethods={reportCtx.paymentMethods}
						chosenPaymentMethod={workerReport.chosenPaymentMethod}
						setChosenPaymentMethod={workerReport.setChosenPaymentMethod}
					/>
				}
			/>

			<ReportModal
				ref={workerReport.reportModalRef}
				gridUrl={gridUrl}
				gridPayload={gridPayload}
				onClose={workerReport.handleReportModalClose}
			/>
		</div>
	);
};

export default FailedToRecordIndex;

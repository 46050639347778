export enum ActionType {
	SET_VIEW = 'SET_VIEW',
	SET_RESOURCE_MODE = 'SET_RESOURCE_MODE',
	CREATE_TEMP_EVENT = 'CREATE_TEMP_EVENT',
	UPDATE_TEMP_EVENT = 'UPDATE_TEMP_EVENT',
	DELETE_TEMP_EVENT = 'DELETE_TEMP_EVENT',
	SET_FILTER = 'SET_FILTER',
}

export interface ACTION_SET_VIEW {
	type: ActionType.SET_VIEW;
	payload: any;
}

export interface ACTION_SET_RESOURCE_MODE {
	type: ActionType.SET_RESOURCE_MODE;
	payload: any;
}

export interface ACTION_CREATE_TEMP_EVENT {
	type: ActionType.CREATE_TEMP_EVENT;
	payload: any;
}

export interface ACTION_UPDATE_TEMP_EVENT {
	type: ActionType.UPDATE_TEMP_EVENT;
	payload: any;
}

export interface ACTION_DELETE_TEMP_EVENT {
	type: ActionType.DELETE_TEMP_EVENT;
	payload: any;
}

export interface ACTION_SET_FILTER {
	type: ActionType.SET_FILTER;
	payload: any;
}

export type Actions =
	| ACTION_SET_VIEW
	| ACTION_SET_RESOURCE_MODE
	| ACTION_CREATE_TEMP_EVENT
	| ACTION_UPDATE_TEMP_EVENT
	| ACTION_DELETE_TEMP_EVENT
	| ACTION_SET_FILTER;

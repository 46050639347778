import Select from 'react-select';
import chroma from 'chroma-js';

const SelectStyled = (props: any) => {
	const defaultBGColour = 'ffffff';
	const defaultStyles = {
		control: (styles: any, { getValue, isDisabled }: any) => {
			let bgColour =
				'#' + (getValue()[0] && getValue()[0].colour ? getValue()[0].colour : defaultBGColour);
			let fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';

			return {
				...styles,
				color: isDisabled ? '#ccc' : fontColour,
				backgroundColor: bgColour,
			};
		},
		singleValue: (styles: any, { getValue, isDisabled }: any) => {
			let bgColour =
				'#' + (getValue()[0] && getValue()[0].colour ? getValue()[0].colour : defaultBGColour);
			let fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';

			return {
				...styles,
				height: '32px',
				lineHeight: '32px',
				color: isDisabled ? '#ccc' : fontColour,
			};
		},
		input: (styles: any, { getValue, isDisabled }: any) => {
			let bgColour =
				'#' + (getValue()[0] && getValue()[0].colour ? getValue()[0].colour : defaultBGColour);
			let fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';

			return {
				...styles,
				height: '28px',
				lineHeight: '22px',
				color: isDisabled ? '#ccc' : fontColour,
			};
		},
		dropdownIndicator: (styles: any, { getValue, isDisabled }: any) => {
			let bgColour =
				'#' + (getValue()[0] && getValue()[0].colour ? getValue()[0].colour : defaultBGColour);
			let fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';

			return {
				...styles,
				color: isDisabled ? '#ccc' : fontColour,
			};
		},
		indicatorSeparator: (styles: any, { getValue, isDisabled }: any) => {
			let bgColour =
				'#' + (getValue()[0] && getValue()[0].colour ? getValue()[0].colour : defaultBGColour);
			let fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';

			return {
				...styles,
				color: isDisabled ? '#ccc' : fontColour,
			};
		},
		option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
			if (!data.colour) return { ...styles };
			let bgColour = '#' + data.colour;
			let fontColour = chroma.contrast(bgColour, 'white') > 2 ? 'white' : 'black';

			return {
				...styles,
				color: isDisabled
					? undefined
					: isSelected
					? fontColour
					: isFocused
					? fontColour
					: undefined,
				backgroundColor: isDisabled
					? undefined
					: isSelected
					? bgColour
					: isFocused
					? bgColour
					: undefined,
				cursor: isDisabled ? 'not-allowed' : 'default',
			};
		},
	};

	// Final props
	let finalProps = props;

	// Manage the stylesExtra over-ride
	let stylesFinal: any = defaultStyles;
	if (props.stylesExtra) {
		stylesFinal = { ...defaultStyles, ...props.stylesExtra };
	}

	// Manage the className
	let classNameFinal: string = 'react-select-container';
	if (props.className && props.className.length > 0) {
		// Add the provided className prop to the default class name
		classNameFinal = `${classNameFinal} ${props.className}`;

		// Remove the className prop
		const { className, ...modifiedProps } = finalProps;

		// Update the final props to use on the component
		finalProps = modifiedProps;
	}

	return (
		<Select
			{...finalProps}
			styles={stylesFinal}
			ref={props.forwardRef && props.forwardRef}
			menuPlacement='auto'
			menuPortalTarget={document.body}
			className={classNameFinal}
			classNamePrefix='react-select'
		/>
	);
};

export default SelectStyled;

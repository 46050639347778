import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton, IonCol, IonRow } from '@ionic/react';

const Overlay = ({ hideFunction, content, title, footerContent }: any) => {
	let footerButtons = [];
	let footerOther = [];

	if (footerContent) {
		// Look for buttons
		if (footerContent.buttons && footerContent.buttons.length > 0) {
			footerButtons = footerContent.buttons.map((a: any, i: any) => {
				if (a.type === "close") {
					return (
						<IonButton key={i} color="tertiary" onClick={hideFunction}>
							Close
						</IonButton>
					)
				}
				return <IonButton />
			});
		}

		// Look for other content
		if (footerContent.content) {
			footerOther = footerContent.content;
		}
	}

	let contentStyle = {};

	if (footerContent) {
		contentStyle = {
			maxHeight: 'calc(100vh - 230px)'
		};
	}

	return (
		<>
			<div className="overlay" onClick={hideFunction}></div>
			<div className="overlay-container">
				{title && (
					<div className="overlay-title">
						<IonRow>
							<IonCol size="8" className="p-0">{title}</IonCol>
							<IonCol size="4" className="p-0 text-right">
								<IonButton size="small" onClick={hideFunction}>
									<FontAwesomeIcon icon={faTimes} />
								</IonButton>
							</IonCol>
						</IonRow>
					</div>
				)}
				<div className="overlay-content" style={contentStyle}>
					{content}
				</div>
				{footerContent && (
					<div className="overlay-footer">
						{footerButtons && (
							<div className="text-right">{footerButtons}</div>
						)}
						{footerOther}
					</div>
				)}
			</div>
		</>
	);
}

export default Overlay;

import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
	value: boolean
}

export const initialState = {
	value: false
} as InitialState;

export const menuExpanded = createSlice({
	name: 'menuExpanded',
	initialState,
	reducers: {
		toggleOn: (state: any) => {
			state.value = true;
		},
		toggleOff: (state: any) => {
			state.value = false;
		},
		toggle: (state: any) => {
			if (state.value === true) {
				state.value = false;
			} else {
				state.value = true;
			}
		},
	},
});

export const { toggleOn, toggleOff, toggle } = menuExpanded.actions;

export default menuExpanded.reducer;

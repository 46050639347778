import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import Map from './Map';
import { Coordinates, MapControls, PropMarker } from '../../interfaces/Maps/MapsInterface';
import { subtleGreyScale } from './MapStyles';
import { DateTime } from 'luxon';

type LocateOptions = {
	initialCentre: Coordinates,
	markerTitle: string,
	radius?: number
}
interface Props {
	options: LocateOptions;
	markerPosition?: Coordinates;
	polygon?: Array<google.maps.LatLng>;
	setPolygon?: Function;
	controls?: Array<MapControls>;
	mode?: 'point' | 'polygon';
	showRadius?: boolean;
}

const LocateOnMap = forwardRef<any, Props>((props: Props, ref) => {
	const [mapCenter, setMapCenter] = useState(props.options.initialCentre);
	const [mapRef, setMapRef] = useState<any>(null);
	const [mode, setMode] = useState<any>(props.mode ?? 'point');
	const [bounds, setBounds] = useState<Array<google.maps.LatLng>>([]);
	const [marker, setMarker] = useState<Array<PropMarker>>();
	const [markerPosition, setMarkerPosition] = useState<Coordinates | undefined>(props.markerPosition);

	useImperativeHandle(ref, () => ({
		getBounds() {
			return bounds;
		},
		getPosition() {
			return markerPosition;
		},
	}));

	useEffect(() => {
		setMapCenter(props.options.initialCentre);
	}, [props.options]);

	useEffect(() => {
		setMode(props.mode ?? 'point');
	}, [props.mode]);

	useEffect(() => {
		let v = getMarkers();
		setMarker(v);
	}, []);

	useEffect(() => {
		setBounds(props.polygon ?? []);
	}, [props.polygon]);

	const handleDragEnd = () => {
		if (mapRef) {
			const newCentre = mapRef.getCenter();
			setMapCenter({
				lat: newCentre.lat(),
				lng: newCentre.lng(),
			});
		}
	};

	const getMarkers = (): Array<PropMarker> => {
		if (props.mode !== 'polygon') {
			let item: PropMarker = {
				title: props.options.markerTitle,
				latlng: markerPosition ?? { lat: 0, lng: 0 },
				showRadius:
					props.showRadius === true ? (props.options.radius !== undefined ? true : false) : false,
				radius: props.options.radius,
				canOpen: false,
				isOpen: false,
				date: DateTime.now(),
				draggable: true,
				onDragEnd: (marker: PropMarker, coordinate: Coordinates) => {
					setMarkerPosition(coordinate);
				},
			};
			return [item];
		} else {
			return [
				{
					title: props.options.markerTitle,
					latlng: markerPosition ?? { lat: 0, lng: 0 },
					showRadius: false,
					canOpen: false,
					isOpen: false,
					date: DateTime.now(),
					draggable: true,
					onDragEnd: (marker: PropMarker, coordinate: Coordinates) => {
						setMarkerPosition(coordinate);
					},
				},
			];
		}
	};

	return (
		<Map
			mapStyle={subtleGreyScale}
			center={mapCenter}
			onMapLoad={(map: google.maps.Map) => {
				setMapRef(map);
			}}
			onMapDragEnd={() => handleDragEnd()}
			controls={props.controls}
			markers={marker}
			polygons={[bounds]}
			drawPolygons={mode === 'polygon'}
			drawMultiple={false}
			onPolygonDrawn={(coords: Array<google.maps.LatLng>) => {
				setBounds(coords);
				if (props.setPolygon) {
					props.setPolygon(coords);
				}
			}}
		/>
	);
});

export default LocateOnMap;

export enum ActionType {
	SET_WEEK_ENDING = 'SET_WEEK_ENDING',
	SET_YEAR = 'SET_YEAR',
	SET_SHOW_MY_TEAM = 'SET_SHOW_MY_TEAM',
	SET_WORKER_REPORT_MONTH = 'SET_WORKER_REPORT_MONTH',
	SET_WORKER_REPORT_YEAR = 'SET_WORKER_REPORT_YEAR',
}

export interface ACTION_SET_WEEK_ENDING {
	type: ActionType.SET_WEEK_ENDING;
	payload: any;
}

export interface ACTION_SET_YEAR {
	type: ActionType.SET_YEAR;
	payload: any;
}

export interface ACTION_SET_SHOW_MY_TEAM {
	type: ActionType.SET_SHOW_MY_TEAM;
	payload: any;
}

export interface ACTION_SET_WORKER_REPORT_MONTH {
	type: ActionType.SET_WORKER_REPORT_MONTH;
	payload: any;
}

export interface ACTION_SET_WORKER_REPORT_YEAR {
	type: ActionType.SET_WORKER_REPORT_YEAR;
	payload: any;
}

export type Actions =
	| ACTION_SET_WEEK_ENDING
	| ACTION_SET_YEAR
	| ACTION_SET_SHOW_MY_TEAM
	| ACTION_SET_WORKER_REPORT_MONTH
	| ACTION_SET_WORKER_REPORT_YEAR;

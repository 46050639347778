import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
} from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { TimesheetsContext } from '../../TimesheetsProvider';
import Loading from '../../../../components/UI/Loading';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { getTimezone } from '../../../../lib/functions';
import WorkerReportHeader from '../components/WorkerReportHeader';

type Props = {
	isOpen: boolean;
	modalData: any;
	onClose: Function;
	permissionTo: Function;
};

const WorkerAllocationsReportModal: React.FC<Props> = (props) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [curMonth, setCurMonth] = useState<number>(-1);
	const [curYear, setCurYear] = useState<number>(-1);
	const headerRef: any = useRef<any>();
	const gridRef: any = useRef<any>();
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<any>>([]);
	const gridColumns: Array<any> = [{ field: 'col_a', headerName: 'Column A', flex: 1 }];

	useEffect(() => {
		setIsLoading(gridLoading);
	}, [gridLoading]);

	useEffect(() => {
		if (props.isOpen) {
			if (props.modalData && props.modalData.worker) {
				setModalTitle(
					`Allocation Details for ${props.modalData.worker.user.first_name} ${props.modalData.worker.user.last_name}`
				);
			}

			// Setup the local state with the main state values
			setCurMonth(state.workerReportMonth);
			setCurYear(state.workerReportYear);
		}
	}, [props.isOpen, props.modalData]);

	useEffect(() => {
		if (props.isOpen && curMonth > 0 && curYear > 0) loadGridData(curMonth, curYear);
	}, [props.isOpen, curMonth, curYear]);

	const loadGridData = (month: number, year: number) => {
		setGridLoading(true);
		setData([]);

		let payload: any = {
			month,
			year,
			...getTimezone(),
			my_team: state.showMyTeam,
			payment_method: ['salary'],
			worker_id: props.modalData.workerId,
		};

		axios
			.post('/api/timesheets/timesheets_and_allocations/allocations_salaried/details', payload)
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleOnDidPresent = () => {
		if (headerRef.current) headerRef.current.setHeaderTitle('Worker Allocations Report - Details');
	};

	const onClose = () => {
		// Reset
		setModalTitle('');
		setData([]);
		setIsLoading(false);
		setGridLoading(false);
		setCurMonth(-1);
		setCurYear(-1);

		props.onClose();
	};

	return (
		<>
			<IonModal
				class='timesheets-worker-allocations-report-modal'
				style={{ '--width': '90vw', '--height': '90vh' }}
				isOpen={props.isOpen}
				onDidPresent={handleOnDidPresent}
				onWillDismiss={() => onClose()}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{modalTitle}</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton onClick={() => onClose()}>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{isLoading && <Loading overlay={true} />}
					{props.isOpen && (
						<div className='content-container'>
							<div className='header-container'>
								<WorkerReportHeader
									ref={headerRef}
									mode={'modal'}
									approvesAllTimesheets={
										props.modalData.worker && props.modalData.worker.hasOwnProperty('key_details')
											? props.modalData.worker.key_details.approves_all_timesheets
											: false
									}
									curMonth={curMonth}
									curYear={curYear}
									modalData={props.modalData}
									setCurMonth={setCurMonth}
									setCurYear={setCurYear}
								/>
							</div>
							<div className='grid-container'>
								<DataGrid ref={gridRef} cols={gridColumns} data={data} title='' useSearch={false} />
							</div>
						</div>
					)}
				</IonContent>
				<IonFooter>
					<IonToolbar>
						<IonRow>
							<IonCol size='12' className='text-right'>
								<IonButton color='secondary' onClick={() => onClose()}>
									Close
								</IonButton>
							</IonCol>
						</IonRow>
					</IonToolbar>
				</IonFooter>
			</IonModal>
		</>
	);
};

export default WorkerAllocationsReportModal;

import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
} from '@ionic/react';

interface PhotoModalProps {
	isOpen: boolean;
	onClose: Function;
	onDidDismiss: Function;
	initialData: any;
	handleDownloadImage: Function;
}

const PhotoModal: React.FC<PhotoModalProps> = ({
	isOpen,
	onClose,
	onDidDismiss,
	initialData,
	handleDownloadImage,
}) => {

	return (
		<IonModal
			className='photo-modal'
			style={{ '--width': '80vw', '--height': '96vh' }}
			isOpen={isOpen}
			onDidDismiss={() => onDidDismiss()}
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>
						{initialData.photoDescription && <>{initialData.photoDescription.join(' | ')}</>}
					</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => handleDownloadImage(initialData)}>
							<FontAwesomeIcon icon={faDownload} />
						</IonButton>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className="center">
				{initialData?.file_type?.indexOf('image/') > -1 && (
					<img className='modal-photo' src={initialData.path} alt='' />
				)}
				{initialData?.file_type?.indexOf('video/') > -1 && (
					<video controls className='gallery-photo'>
						<source src={initialData.path} type={initialData.file_type} />
					</video>
				)}
				
			</IonContent>
		</IonModal>
	);
};

export default PhotoModal;

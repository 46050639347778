import { IonButton, IonCol, IonRow } from '@ionic/react';
import { useContext } from 'react';
import { moduleContext } from '../../../contexts/ModuleContext';

interface Props {
	tabId: string;
	workerId: string;
	buttons?: any;
	setIsLoading: Function;
	prevFunction?: Function;
	nextFunction?: Function;
}

const WorkerControls: React.FC<Props> = (props: Props) => {
	const moduleCtx = useContext<any>(moduleContext);
	let ionRowClasses: Array<string> = [];

	// Calculate classes
	switch (props.tabId) {
		case 'working_hours':
		case 'management_structure':
			break;
		default:
			ionRowClasses.push('mt-4');
			break;
	}

	return (
		<>
			<IonRow className={ionRowClasses.join(' ')}>
				<IonCol size={'4'}>
					<IonButton
						color='danger'
						onClick={() => moduleCtx.handleLeaveEmployment(props.workerId, props.setIsLoading)}
					>
						Leave Employment
					</IonButton>
				</IonCol>
				<IonCol size={'8'} className='text-right'>
					{props.buttons && props.buttons}
					{props.prevFunction && (
						<IonButton
							color='secondary'
							onClick={(e) => {
								if (props.prevFunction) {
									props.prevFunction(e);
								}
							}}
						>
							Prev Step
						</IonButton>
					)}
					{props.nextFunction && (
						<IonButton
							color='primary'
							onClick={(e) => {
								if (props.nextFunction) {
									props.nextFunction(e);
								}
							}}
						>
							Next Step
						</IonButton>
					)}
				</IonCol>
			</IonRow>
		</>
	);
};

export default WorkerControls;

import { useContext, useEffect, useState } from 'react';
import { IonRow, IonCol, IonButton, IonCard, useIonAlert } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import TitleBar from '../../../components/TitleBar/TitleBar';
import DataGrid from '../../../components/DataGrid/DataGrid';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import ListFilter from './components/ListFilter';
import { dateFormatter } from '../../../helpers/dateFormatter';
import { authContext } from '../../../contexts/AuthContext';
import { getTimezone, underscoredToUppercasedWords } from '../../../lib/functions';

import './Estimates.scss';
import '../shared/estimates-invoices/styles.scss';

const EstimatesList: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const authCtx: any = useContext(authContext);
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const urlParamsTmp = decodeURIComponent(String(urlParams.get('issued_by')));
	const urlParamsTmpSplit = urlParamsTmp.split(',');
	// Must be state so that it can only be set once while urlParams still exists
	const [defaultFilter, setDefaultFilter] = useState<any>({
		status: {
			label: 'Issued',
			payload: { status: 'issued' },
		},
		issuedBy: {
			label: urlParams.get('issued_by') ? urlParamsTmpSplit[0] : '',
			payload: { workerId: urlParams.get('issued_by') ? urlParamsTmpSplit[1] : '' },
		},
	});
	const [curFilterPayload, setCurFilterPayload] = useState<any>({
		status: 'issued',
		worker_id: urlParams.get('issued_by') ? urlParamsTmpSplit[1] : '',
		job_type_id: '',
	});
	const defaultAllTime: boolean = Number(urlParams.get('default_alltime')) === 1;
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [tableTitle, setTableTitle] = useState<string>(defaultFilter.status.label);
	const [curMonth, setCurMonth] = useState<number>(Number(DateTime.now().toFormat('MM')));
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const [estimateId, setEstimateId] = useState<string | null>(null);
	const [jobId, setJobId] = useState<string | null>(null);
	const [presentAlert] = useIonAlert();
	let history = useHistory();

	// Remove the query parameter
	useEffect(() => {
		if (location && location.search) {
			// Remove the query parameter
			urlParams.delete('issued_by');
			urlParams.delete('default_alltime');
			history.replace({
				search: urlParams.toString(),
			});
		}
	}, [location.search]);

	const loadGridData = (month: number, year: number) => {
		setGridLoading(true);
		setJobId(null);
		setEstimateId(null);
		setData([]);

		let payload: any = {
			month: month,
			year: year,
			...getTimezone(),
			...curFilterPayload,
		};

		if (defaultAllTime) {
			payload.month = 0;
			payload.year = 0;
		}

		axios
			.post('/api/jobs/estimates_list', payload)
			.then((res: any) => {
				setData(res.data);
				setCurYear(year);
				setCurMonth(month);
			})
			.catch((err: any) => {
				if (err && err.code && err.code !== 'ERR_CANCELED') {
					showToast('error');
				}
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleListFilterChange = (label: string, payload: any) => {
		setCurFilterPayload(() => payload);
		loadGridData(curMonth, curYear);
		setTableTitle(`Estimates - ${label}`);
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete') {
			params.node.setSelected(true);
			setEstimateId(params.data.id);
			setJobId(params.data.job_id);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete') {
			handleCellClicked(params);
			handleEditEstimate();
		}
	};

	const handleAddEstimate = () => {
		if (permissionTo('create', 'jobs.estimates_list.add_new')) {
			const path = '/jobs/estimates_list/add_new';
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleEditEstimate = () => {
		if (permissionTo('update', 'jobs.estimates_list.estimates_card')) {
			const path = `/jobs/estimates_list/estimates_card/${estimateId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleGoToJob = () => {
		if (permissionTo('update', 'jobs.jobs_list.job_card')) {
			const path = `/jobs/jobs_list/job_card/${jobId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleDelete = (id: string) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Estimate',
				message: 'Are you sure you want to delete this estimate?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleDeleteGo(id);
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		setJobId(null);
		setEstimateId(null);

		axios
			.delete(`/api/jobs/estimates_list/estimates_card/${id}`)
			.then(() => {
				loadGridData(curMonth, curYear);
				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const columns = [
		{
			field: 'number',
			headerName: 'Estimate Number',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'job_name',
			headerName: 'Job Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'issue_date',
			headerName: 'Issue Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
			sort: 'desc',
		},
		{
			field: 'expiry_date',
			headerName: 'Expiry Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'client_name',
			headerName: 'Client Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'value',
			headerName: `Value Gross (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value) {
					return params.data.value.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'issued_by',
			headerName: 'Issued By',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'status',
			headerName: 'Status',
			sortable: true,
			resizable: true,
			cellRenderer: (params: any) => {
				const statusWord = params.getValue();
				if (statusWord) {
					return underscoredToUppercasedWords(statusWord);
				}
			},
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card filter-data-table full-height-card'>
					<DataGrid
						onGridReady={() => {
							setGridReady(true);
						}}
						title={tableTitle}
						cols={columns}
						data={data}
						cellClickedFunction={handleCellClicked}
						cellDoubleClickedFunction={handleCellDoubleClicked}
						rowCursorPointer={permissionTo('update')}
						filterButtons={true}
						filterOptions={{
							displayCurrentMonth: loadGridData,
							defaultAllTime,
						}}
						extraFooter={
							<IonRow>
								<IonCol size='8' className='row-lower-controls-left'>
									<ListFilter defaultSelected={defaultFilter} onChange={handleListFilterChange} />
								</IonCol>
								<IonCol size='4' className='row-lower-controls-right pe-0'>
									<div>
										<IonButton
											color='secondary'
											disabled={jobId === null}
											onClick={() => handleGoToJob()}
										>
											Go To Job
										</IonButton>
										<IonButton
											color='primary'
											disabled={estimateId === null}
											onClick={() => handleEditEstimate()}
										>
											Edit Estimate
										</IonButton>
										<IonButton color='success' onClick={() => handleAddEstimate()}>
											Add New
										</IonButton>
									</div>
								</IonCol>
							</IonRow>
						}
					/>
				</IonCard>
			</div>
		</>
	);
};

export default EstimatesList;

import { IonButton } from "@ionic/react";

const BackButton = (props: any) => {
	return (
		<IonButton color="white" className={`title-backbutton${(props.className) ? ' ' + props.className : ''}`} onClick={props.onClick}>
			Back
		</IonButton>
	);
};

export default BackButton;

import { Alert } from '@mui/material';
import TitleBar from '../../components/TitleBar/TitleBar';

const ReportsExports = () => {
	return (
		<>
			<TitleBar title='Reports and Exports' />
			<Alert severity='info'>Reports and exports: To do...</Alert>
		</>
	);
};

export default ReportsExports;

import { useState, useEffect, useRef, createElement, useContext } from 'react';
import { returnSubmenuBadge } from '../../helpers/badge';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRow, IonCol, IonCard } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { getPermissionTo } from '../../lib/functions';
import { authContext } from '../../contexts/AuthContext';

interface Props {
	array: Array<any>;
	onTogglePage?: Function;
	extraOptions?: any;
}

const Submenu: React.FC<Props> = (props: Props) => {
	const authCtx = useContext<any>(authContext);
	const [currentItem, setCurrentItem] = useState<any>(null);
	const [fullHeightCard, setFullHeightCard] = useState<boolean>(false);
	const [currentPageId, setCurrentPageId] = useState<string>('');
	const [submenuArray, setSubmenuArray] = useState<Array<any>>(props.array);
	const [submenuItems, setSubmenuItems] = useState<Array<any>>([]);
	const location = useLocation();
	let history = useHistory();
	const cardRef = useRef<any>();

	// Update the submenu array every time the props changes, e.g. the badge count updates
	useEffect(() => {
		setSubmenuArray(props.array);

		// Extra options are sometimes stateful and therefore useful to re-render this menu when changed
	}, [props.array, props.extraOptions]);

	// Initialise the menu when the submenu array state changes to render those changes
	useEffect(() => {
		initialiseMenu();
	}, [submenuArray]);

	const initialiseMenu = () => {
		// Initially modify the array before use if a hash was provided to initially display that item
		if (location && location.hash) {
			submenuArray.map((item: any) => {
				if (item.hash === location.hash.replace('#', '')) {
					// Switch to the menu item that matches the hash
					item.active = true;
				} else {
					// Set all other items as not active
					item.active = false;
				}
				return item;
			});
		}

		generateSubmenuItems();
	};

	const generateSubmenuItems = () => {
		// Print and set the sub menu
		let result = submenuArray.map((item: any, i: number) => {
			// Permission to view this page
			if (item.hasOwnProperty('uid') && item.uid.length > 0) {
				// Skip this menu item if no permission
				if (!getPermissionTo('read', item.uid, authCtx)) return;
			}

			if (item.active === true) {
				setCurrentItem(item);
				setFullHeightCard(item.fullHeight);
				setCurrentPageId(item.id);
			}
			return (
				<li
					key={i}
					className={item.active ? 'active utilities-submenu-item' : 'utilities-submenu-item'}
					onClick={() => handleTogglePage(i)}
				>
					{(item.badge || item.badgeNumber || item.badgeColor) && returnSubmenuBadge(item)}
					<span>{item.title}</span>
					<FontAwesomeIcon className='utilities-submenu-chevron' icon={faChevronRight} />
				</li>
			);
		});
		setSubmenuItems(result);
	};

	const handleTogglePage = (index: any) => {
		let array = submenuArray;
		for (let i = 0; i < array.length; i++) {
			array[i].active = false;
		}
		array[index].active = true;

		// Apply the hash to the URL if there is one
		if (array[index].hasOwnProperty('hash')) {
			history.replace(location.pathname + `#${array[index].hash}`);
		}

		setSubmenuArray(array);
		generateSubmenuItems();
		if (props.onTogglePage) props.onTogglePage({ activeItem: array[index] });
	};

	const renderPage = () => {
		if (
			currentItem !== null &&
			currentItem !== undefined &&
			currentItem.page !== null &&
			currentItem.page !== undefined
		) {
			return createElement(currentItem.page, {
				...currentItem.pageData,
				parentRef: cardRef,
				title: currentItem.title,
				uid: currentItem.uid,
			});
		}
	};

	return (
		<>
			<IonRow>
				<IonCol className='p-0' size={'4'} sizeMd={'3'} sizeLg={'2'}>
					<ul className='utilities-submenu'>{submenuItems}</ul>
				</IonCol>

				<IonCol className='p-0' size={'8'} sizeMd={'9'} sizeLg={'10'}>
					<IonCard
						ref={cardRef}
						className={`propeller-content-card module-submenu-item ${currentPageId} ${
							fullHeightCard ? 'full-height-card' : ''
						}`}
					>
						{renderPage()}
					</IonCard>
				</IonCol>
			</IonRow>
		</>
	);
};

export default Submenu;

import { NavLink } from 'react-router-dom';
import { IonRow, IonCol, IonCard } from '@ionic/react';
import TitleBar from '../../components/TitleBar/TitleBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserPlus,
	faUserGear,
	faUserCheck,
	faUserPen,
	faSearch,
	faCalendarCheck,
	faCalendarDay,
	faVirus,
	faClipboardList,
	faBuildingColumns,
	faCertificate,
} from '@fortawesome/free-solid-svg-icons';
import usePermissionTo from '../../utils/custom-hooks/PermissionTo';

const WorkersIndex = () => {
	const permissions: any = {
		workers_list: usePermissionTo('workers.workers_list'),
		holidays_and_absences: usePermissionTo('workers.holidays_and_absences'),
		reports: usePermissionTo('workers.reports'),
	};

	return (
		<>
			<TitleBar title='Workers' />
			<IonRow className='module-panels'>
				{permissions.workers_list('read') && (
					<IonCol size={'6'}>
						<NavLink to='/workers/workers_list'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Workers</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faUserPlus} />
										Add new workers
									</li>
									<li>
										<FontAwesomeIcon icon={faUserGear} />
										Update workers' information
									</li>
									<li>
										<FontAwesomeIcon icon={faUserCheck} />
										Review workers' performance
									</li>
									<li>
										<FontAwesomeIcon icon={faUserPen} />
										Upload workers via the spreadsheet
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
				{permissions.holidays_and_absences('read') && (
					<IonCol size={'6'}>
						<NavLink to='/workers/holidays_and_absences'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Holidays, Absence and Training</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faCalendarCheck} />
										Review the company holiday chart
									</li>
									<li>
										<FontAwesomeIcon icon={faCalendarDay} />
										Review requests for approval
									</li>
									<li>
										<FontAwesomeIcon icon={faVirus} />
										Review Sick requests
									</li>
									<li>
										<FontAwesomeIcon icon={faClipboardList} />
										Review history of holidays and sickness
									</li>
									<li>
										<FontAwesomeIcon icon={faBuildingColumns} />
										Add training and other diary events
									</li>
									<li>
										<FontAwesomeIcon icon={faCertificate} />
										Upload training certificates
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
				{permissions.reports('read') && (
					<IonCol size={'6'}>
						<NavLink to='/workers/reports#late-start-early-finish'>
							<IonCard className='m-0 table-card landing-card'>
								<h3>Reports</h3>
								<ul>
									<li>
										<FontAwesomeIcon icon={faSearch} />
										Run a number of reports relevant to workers including league table reports
									</li>
								</ul>
							</IonCard>
						</NavLink>
					</IonCol>
				)}
			</IonRow>
		</>
	);
};

export default WorkersIndex;

import { useState } from 'react';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Conversations from '../../../components/Chat/Conversations/Conversations';

const ConversationsPage: React.FC = () => {
	const [hasTitleBar, setHasTitleBar] = useState<boolean>(true);

	const showTitleBar = (value: boolean) => {
		setHasTitleBar(value);
	}

	return (
		<>
			{hasTitleBar && <TitleBar title='Conversations' />}
			<div style={{flex: 1, height: hasTitleBar ? 'calc(100% - 69px)' : '100%'}}>
				<Conversations convoListFunction={showTitleBar} readOnly={false}></Conversations>
			</div>
		</>
	);
};

export default ConversationsPage;

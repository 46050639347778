import { RouteStatus } from '../types/routes-type';

// Check if an element is visible within a container
export function isVisible(ele: any, container: any) {
	const { bottom, height, top } = ele.getBoundingClientRect();
	const containerRect = container.getBoundingClientRect();

	return top <= containerRect.top
		? containerRect.top - top <= height
		: bottom - containerRect.bottom <= height;
}

export function translateRouteStatus(status: RouteStatus): string {
	let translatedStatus: string = 'success';

	switch (status) {
		case RouteStatus.INCOMPLETE:
			translatedStatus = 'danger';
			break;
		case RouteStatus.PARTCOMPLETE:
			translatedStatus = 'warning';
			break;
		case RouteStatus.COMPLETE:
			translatedStatus = 'success';
			break;
	}

	return translatedStatus;
}

import { useState, useEffect } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { IonCard, IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import { NumberField, TextField } from '../../../../components/Forms/FormFields';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import './VATRates.scss';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import EditRatesModal from './EditRatesModal';
import { db2Form } from '../../../../api/forms';
import Form from '../../../../components/Forms/Form';
import Loading from '../../../../components/UI/Loading';

const VATRatesIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [formLoading, setFormLoading] = useState<boolean>(true);
	const [registerId, setRegisterId] = useState<string>('');
	const [registerName, setRegisterName] = useState<string>('');
	const [items, setItems] = useState<Array<any>>([]);
	const [endpointID, setEndpointID] = useState<string>('');
	const [isDefaultRate, setIsDefaultRate] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();
	const yesNoValues: Array<any> = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	];

	// Modals
	const [editRatesModal, setEditRatesModal] = useState<any>({
		isOpen: false,
		registerId: '',
		registerName: '',
	});

	useEffect(() => {
		loadGridData();
		loadFormData();
	}, []);

	const columns = [
		{
			headerName: 'Description',
			field: 'description',
			sortable: true,
			sort: 'asc',
			cellRenderer: (params: any) => {
				return (
					<Form
						array={[
							{
								id: `description-${params.data.id}`,
								type: 'text',
								value: params.data.description,
								db: ['description'],
							},
						]}
						forceEdit={permissionTo('update')}
						noButton={true}
						tableMode={true}
						permissionTo={permissionTo}
						endpoint={'/api/utilities/general/vat_register'}
						endpointID={params.data.id}
						onChangeCallback={(formData: Array<any>) => cellEdited(formData, 'description')}
					/>
				);
			},
			flex: 1,
		},
		{
			headerName: 'VAT Rate',
			field: 'vat_rate',
			sortable: true,
			cellRenderer: (params: any) => Number(params.data.vat_rate) + '%',
			flex: 1,
		},
		{
			headerName: 'Effective From Date',
			field: 'date_effective',
			sortable: true,
			cellRenderer: (params: any) => {
				let output = '-';
				try {
					if (params.value) {
						output = DateTime.fromISO(params.value).toFormat('dd/MM/yyyy');
					}
				} catch (err) {
					output = '-';
				}
				return output;
			},
			flex: 1,
		},
		{
			headerName: 'Default Rate',
			field: 'default',
			sortable: true,
			cellRenderer: (params: any) => {
				return params.value === true ? 'Yes' : '-';
			},
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const cellEdited = (formData: any, cellKey: string) => {
		// Update grid row items to prevent value flipping
		const prevState: any = data;
		prevState.forEach((item: any) => {
			if (item.id === formData.data[0].id.split('-')[1]) {
				item[cellKey] = formData.data[0].value;
			}
			return item;
		});

		// We overwrite the state so that it doesn't perform a render
		setData(prevState);
	};

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		setRegisterId('');
		axios.get('/api/utilities/general/vat_register').then((res) => {
			setData(res.data);
			setGridLoading(false);
		});
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'description' && params.column.colId !== 'delete') {
			params.node.setSelected(true);
			setRegisterId(params.data.id);
			setRegisterName(params.data.description);
			setIsDefaultRate(params.data.default);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'description' && params.column.colId !== 'delete') {
			handleCellClicked(params);
			handleEditRate();
		}
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');
		setRegisterId('');

		axios
			.delete(`/api/utilities/general/vat_register/${id}`)
			.then(() => {
				loadGridData();

				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const handleCreate = () => {
		const parentDescription = document.getElementById('add-description') as HTMLElement;
		const description = parentDescription.getElementsByTagName('input')[0].value;
		const parentRate = document.getElementById('add-initial-rate') as HTMLElement;
		const rate = parentRate.getElementsByTagName('input')[0].value;

		if (!description) {
			showToast('error', 'Please fill-in all fields');
			return;
		}

		const toastID = toast.loading('Please wait...');

		const payload = {
			description: description,
			rate: Number(rate),
		};

		setRegisterId('');

		axios
			.put('/api/utilities/general/vat_register', payload)
			.then(() => {
				// Reset
				parentDescription.getElementsByTagName('input')[0].value = '';
				parentRate.getElementsByTagName('input')[0].value = '0';

				loadGridData();

				showToast('saved', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const handleEditRate = () => {
		if (permissionTo('update')) {
			setEditRatesModal({ isOpen: true, registerId: registerId, registerName: registerName });
		} else {
			showToast('permission');
		}
	};

	const loadFormData = () => {
		setFormLoading(true);

		let formData: any = [
			{
				title: 'Settings',
				type: 'title',
				style: 'alpha',
			},
			{
				title: 'Are you registered for VAT?',
				type: 'dropdown',
				values: yesNoValues,
				db: ['settings', 'vat_registered'],
			},
			{
				title: 'VAT Registration Number',
				type: 'text',
				db: ['settings', 'vat_number'],
			},
		];

		axios
			.get('/api/utilities/company_details/settings')
			.then((res) => res.data[0])
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
				setEndpointID(dbData._id);
			})
			.catch(() => {
				showToast('error');
			})
			.finally(() => {
				setFormLoading(false);
			});
	};

	const handleSetDefault = () => {
		presentAlert({
			header: 'Set as Default Rate',
			message: 'Are you sure you want to set this item as the default rate?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleSetDefaultGo(registerId);
					},
				},
			],
		});
	};

	const handleSetDefaultGo = (id: string) => {
		const toastID = toast.loading('Please wait...');
		setRegisterId('');

		axios
			.put(`/api/utilities/general/vat_register/${id}`, { default: true })
			.then(() => {
				loadGridData();

				showToast('saved', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card filter-data-table full-height-card'>
					<DataGrid
						title={routeTitle}
						cols={columns}
						data={data}
						autoSize={true}
						cellClickedFunction={handleCellClicked}
						cellDoubleClickedFunction={handleCellDoubleClicked}
						rowCursorPointer={permissionTo('update')}
						extraFooter={
							permissionTo('create') && (
								<>
									<IonRow>
										<IonCol size={'8'} className='ps-0'>
											<IonRow>
												<IonCol size={'6'} style={{ display: 'flex' }}>
													<label htmlFor='add-description'>Description</label>
													<div style={{flex: 1}}>
														<TextField
															id='add-description'
															useLabels={false}
															editMode={true}
															multiLine={false}
															noForm={true}
															handleUpdateValue={handleCreate}
															value=''
														/>
													</div>
												</IonCol>
												<IonCol size={'4'} style={{ display: 'flex' }}>
													<label htmlFor='add-initial-rate'>VAT Rate %</label>
													<div style={{flex: 1}}>
														<NumberField
															id='add-initial-rate'
															value={''}
															useLabels={false}
															editMode={true}
															handleUpdateValue={handleCreate}
															hideSpinner={true}
															noForm={true}
															decimalLimit={2}
														/>
													</div>
												</IonCol>
												<IonCol size={'2'} style={{ padding: '2px 0 0 0' }}>
													<IonButton
														color='success'
														onClick={() => handleCreate()}
														disabled={gridLoading}
													>
														Add
													</IonButton>
												</IonCol>
											</IonRow>
										</IonCol>
										<IonCol size={'4'} className='text-right pe-0'>
											{permissionTo('update') && (
												<>
													<IonButton
														color='secondary'
														onClick={() => handleSetDefault()}
														disabled={registerId.length === 0 || isDefaultRate}
													>
														Set as Default
													</IonButton>
													<IonButton
														color='primary'
														onClick={() => handleEditRate()}
														disabled={registerId.length === 0}
													>
														Edit Rate
													</IonButton>
												</>
											)}
										</IonCol>
									</IonRow>
									{formLoading && <Loading height={180} />}
									{!formLoading && items && items.length > 0 && (
										<div className='utility-form-padding'>
											<Form
												array={items}
												permissionTo={permissionTo}
												forceEdit={permissionTo('update')}
												noButton={true}
												endpoint={'/api/utilities/company_details/settings/'}
												endpointID={endpointID}
											/>
										</div>
									)}
								</>
							)
						}
					/>
				</IonCard>
			</div>

			<EditRatesModal
				isOpen={editRatesModal.isOpen}
				registerId={editRatesModal.registerId}
				registerName={editRatesModal.registerName}
				onClose={() => {
					setEditRatesModal((prevState: any) => {
						return { ...prevState, isOpen: false, registerId: '' };
					});
				}}
				onAnimationEnd={() => {
					setEditRatesModal((prevState: any) => {
						return { ...prevState, registerName: '' };
					});
				}}
				onSuccess={loadGridData}
			/>
		</>
	);
};

export default VATRatesIndex;

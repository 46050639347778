import { IonCol, IonRow } from '@ionic/react';
import ClientDetails from './forms/ClientDetails';

interface Props {
	permissionTo: Function;
}

const EditClient: React.FC<Props> = (props: Props) => {
	return (
		<>
			<IonRow>
				<IonCol size={"12"} className="p-0">
					<ClientDetails
						newClient={false}
						permissionTo={props.permissionTo}
					/>
				</IonCol>
			</IonRow>
		</>
	);
};

export default EditClient;

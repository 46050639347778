import { Layout } from 'react-grid-layout';

export interface Defaults {
	[key: string]: Layout[];
}

export const layoutDefaults = (cols: any): Defaults => {
	const minH = 8;
	const maxH = 20;

	const presetSizes: any = {
		lg: {
			thirds: { w: 4, h: minH, minW: 3, maxW: 12, minH, maxH },
			quarters: { w: 3, h: minH, minW: 3, maxW: 12, minH, maxH },
			halves: { w: 6, h: minH, minW: 3, maxW: 12, minH, maxH },
			quads: { w: 6, h: 16, minW: 3, maxW: 12, minH: 8, maxH },
		},
	};

	return {
		lg: [
			{
				i: 'dashboard.personal.requests',
				x: 0,
				y: 0,
				...presetSizes.lg.thirds,
			},
			{
				i: 'dashboard.personal.sick',
				x: 0,
				y: 0,
				...presetSizes.lg.thirds,
			},
			{
				i: 'dashboard.personal.team_locations',
				x: 0,
				y: 0,
				...presetSizes.lg.thirds,
			},
			{
				i: 'dashboard.personal.suspended_jobs',
				x: 0,
				y: 0,
				...presetSizes.lg.halves,
			},
			{
				i: 'dashboard.personal.diary',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
			},
			{
				i: 'dashboard.personal.holidays_approval',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
			},
			{
				i: 'dashboard.personal.sales',
				x: 0,
				y: 0,
				...presetSizes.lg.quads,
			},
			{
				i: 'dashboard.features.create_worker',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
				isResizable: false,
			},
			{
				i: 'dashboard.features.create_job',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
				isResizable: false,
			},
			{
				i: 'dashboard.features.go_to_worker',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
				isResizable: false,
			},
			{
				i: 'dashboard.features.go_to_job',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
				isResizable: false,
			},
			{
				i: 'dashboard.company.sickness_today',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
				isResizable: false,
			},
			{
				i: 'dashboard.company.holidays_this_month',
				x: 0,
				y: 0,
				...presetSizes.lg.quarters,
				isResizable: false,
			},
			{
				i: 'dashboard.company.suspended_jobs',
				x: 0,
				y: 0,
				...presetSizes.lg.halves,
			},
			{
				i: 'dashboard.company.sales',
				x: 0,
				y: 0,
				...presetSizes.lg.quads,
			},
		],
		sm: [
			{
				i: 'dashboard.personal.requests',
				x: 0,
				y: 0,
				w: 6,
				h: minH,
				minW: 6,
				maxW: cols.sm,
				minH,
				maxH,
			},
			{
				i: 'dashboard.personal.sick',
				x: 0,
				y: 0,
				w: 6,
				h: minH,
				minW: 6,
				maxW: cols.sm,
				minH,
				maxH,
			},
			{
				i: 'dashboard.personal.team_locations',
				x: 0,
				y: 0,
				w: 6,
				h: minH,
				minW: 6,
				maxW: cols.sm,
				minH,
				maxH,
			},
			{
				i: 'dashboard.personal.suspended_jobs',
				x: 0,
				y: 0,
				w: 6,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
			},
			{
				i: 'dashboard.personal.diary',
				x: 0,
				y: 0,
				w: 6,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
			},
			{
				i: 'dashboard.personal.holidays_approval',
				x: 0,
				y: 0,
				w: 6,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
			},
			{
				i: 'dashboard.personal.sales',
				x: 0,
				y: 0,
				w: 6,
				h: 16,
				minW: 6,
				maxW: cols.sm,
				minH: 16,
				maxH: 16,
				isResizable: false,
			},
			{
				i: 'dashboard.features.create_worker',
				x: 0,
				y: 0,
				w: 3,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
				isResizable: false,
			},
			{
				i: 'dashboard.features.create_job',
				x: 0,
				y: 0,
				w: 3,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
				isResizable: false,
			},
			{
				i: 'dashboard.features.go_to_worker',
				x: 0,
				y: 0,
				w: 3,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
				isResizable: false,
			},
			{
				i: 'dashboard.features.go_to_job',
				x: 0,
				y: 0,
				w: 3,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
				isResizable: false,
			},
			{
				i: 'dashboard.company.sickness_today',
				x: 0,
				y: 0,
				w: 3,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
				isResizable: false,
			},
			{
				i: 'dashboard.company.holidays_this_month',
				x: 0,
				y: 0,
				w: 3,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
				isResizable: false,
			},
			{
				i: 'dashboard.company.suspended_jobs',
				x: 0,
				y: 0,
				w: 6,
				h: minH,
				minW: 3,
				maxW: cols.sm,
				minH: minH,
				maxH: minH,
			},
			{
				i: 'dashboard.company.sales',
				x: 0,
				y: 0,
				w: 6,
				h: 16,
				minW: 6,
				maxW: cols.sm,
				minH: 16,
				maxH: 16,
				isResizable: false,
			},
		],
	};
};

import { IonRow, IonCol, IonLabel } from '@ionic/react';
import { useContext, useState, useRef, useEffect } from 'react';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';

interface SFProps {
	onChange?: Function;
}

const ListFilterCreditNotes: React.FC<SFProps> = ({ onChange }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [workers, setWorkers] = useState<any>();

	const filterByIssuedByRef = useRef<any>();
	const [filterIssuedBy, setFilterIssuedBy] = useState<any>({ label: '', value: '' });

	useEffect(() => {
		moduleCtx.getWorkersOptions().then((res: any) => {
			setWorkers(res);
		});
	}, []);

	useEffect(() => {
		applyFilters();
	}, [filterIssuedBy]);

	const setFilters = (newValue: any, filterName: string) => {
		switch (filterName) {
			case 'worker_id':
				setFilterIssuedBy(newValue);
				break;
		}
	};

	const applyFilters = () => {
		let payload: any = { worker_id: '' };

		if (filterIssuedBy) payload.worker_id = filterIssuedBy.value;

		if (onChange) onChange(payload);
	};

	return (
		<div className='d-flex w-100 align-items-center'>
			<div className='pe-3 text-nowrap'>
				<IonLabel>Issued By</IonLabel>
			</div>
			<div className='w-25'>
				<SelectStyled
					className='w-100'
					options={workers}
					onChange={(newValue: any) => setFilters(newValue, 'worker_id')}
					forwardRef={filterByIssuedByRef}
					isClearable={true}
				/>
			</div>
		</div>
	);
};

export default ListFilterCreditNotes;

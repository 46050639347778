import { IonCard, IonCol, IonRow } from '@ionic/react';
import { useState } from 'react';
import { WorkerTimesheetsTabs } from '../job-types';
import { useParams } from 'react-router-dom';
import AllocationHistory from './components/AllocationHistory';
import CumulativeCost from './components/CumulativeCost';

const WorkerTimesheetsIndex: React.FC = () => {
	const { jobId }: any = useParams();
	const [tabMode, setTabMode] = useState<WorkerTimesheetsTabs>('allocation_history');

	const handleTabChange = (event: any) => {
		let tab = event.target;
		if (!tab.classList.contains('subnav-item')) tab = tab.closest('.subnav-item');
		setTabMode(tab.id);
	};

	return (
		<IonCard className='table-card filter-data-table full-height-card flex-grow-container'>
			<IonRow className='sub-nav-tabs'>
				<IonCol
					id='allocation_history'
					className={tabMode === 'allocation_history' ? 'active subnav-item' : 'subnav-item'}
					onClick={(e: any) => handleTabChange(e)}
				>
					<span>Allocation History</span>
				</IonCol>
				<IonCol
					id='cumulative_cost'
					className={tabMode === 'cumulative_cost' ? 'active subnav-item' : 'subnav-item'}
					onClick={(e: any) => handleTabChange(e)}
				>
					<span>Cumulative Cost Allocation Vs. Cost Estimate</span>
				</IonCol>
			</IonRow>
			<div style={{ flex: 1 }}>
				{tabMode === 'allocation_history' && <AllocationHistory jobId={jobId} />}
				{tabMode === 'cumulative_cost' && <CumulativeCost jobId={jobId} />}
			</div>
		</IonCard>
	);
};

export default WorkerTimesheetsIndex;

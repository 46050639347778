import { DateTime } from 'luxon';
import { showToast } from '../../../../lib/toast';
import { EventType, HalfDayOptions, SickData, SickPayload, SickStatus } from './sick-types';
import axios from '../../../../lib/axios';
import { renderHalfDayOptionToDate } from '../../../../lib/functions';

export const handleSaveRequest = (
	data: SickData,
	halfDayOptions: HalfDayOptions[],
	permissionTo: Function,
	setIsLoading: Function,
	onSuccess: Function
) => {
	let isNew: boolean = true;

	// Permissions and create/update checks
	if (data.requestId) {
		if (!permissionTo('update')) {
			showToast('permission');
			return;
		}

		isNew = false;
	} else {
		if (!permissionTo('create')) {
			showToast('permission');
			return;
		}

		isNew = true;
	}

	// Mandatory fields checks
	if (!data.workerId) {
		showToast('error', 'Please select a worker');
		return;
	}

	if (!data.description) {
		showToast('error', 'Please provide a sickness reason');
		return;
	}

	if (!data.requestedDate) {
		showToast('error', 'Please provide a requested date');
		return;
	}

	if (!data.start || !data.end || data.start > data.end) {
		showToast('error', 'Please provide valid start and end dates');
		return;
	}

	// Half-day logic
	if (Number(data.period) <= 0) {
		showToast('error', 'Please check your request start and end times');
		return;
	}

	setIsLoading(true);

	const startUpdated: DateTime = renderHalfDayOptionToDate(
		'start',
		data.start,
		data.startHourIndex!,
		halfDayOptions
	);

	const endUpdated: DateTime = renderHalfDayOptionToDate(
		'end',
		data.end,
		data.endHourIndex!,
		halfDayOptions
	);

	// Sicknesses are auto-approved
	let payload: SickPayload = {
		event_type: EventType.SICK,
		worker_ids: [data.workerId],
		description: data.description,
		requested_date: data.requestedDate,
		start: startUpdated,
		end: endUpdated,
		status: SickStatus.APPROVED,
	};

	let url: string = '';
	if (isNew) {
		url = '/api/workers/holidays_and_absences/requests';
	} else {
		url = `/api/workers/holidays_and_absences/requests/${data.requestId}`;
	}

	axios
		.put(url, payload)
		.then((res: any) => {
			if (res.data.error && res.data.error.length > 0) {
				showToast('error', res.data.error);
			} else {
				showToast(
					'success',
					`Your sickness report was successfully ${isNew ? 'created' : 'updated'}`
				);
				onSuccess();
			}
		})
		.catch(() => {
			showToast('error', null);
		})
		.finally(() => {
			setIsLoading(false);
		});
};

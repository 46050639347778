import { useContext, useEffect, useState } from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@mui/material';
import { ActionType } from '../actions';
import { EstimateContext } from '../EstimatesProvider';
import { EstimateItem } from '../estimate-types';
import ItemRow from './ItemRow';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { cloneDeep, uniqueId } from 'lodash';
import { calculateEstimateAmount } from '../estimate-functions';
import { CatalogueItem } from '../../../../interfaces/Utilities/CatalogueItem';
import { authContext } from '../../../../contexts/AuthContext';
import { showToast } from '../../../../lib/toast';

interface Props {
	permissionTo: Function;
}

const EstimateItems: React.FC<Props> = ({ permissionTo }) => {
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const { state, dispatch } = useContext(EstimateContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [catalogueItems, setCatalogueItems] = useState<Array<CatalogueItem>>([]);
	const [catalogueItemsLoading, setCatalogueItemsLoading] = useState<boolean>(false);
	const [vatRates, setVatRates] = useState<Array<any>>([]);
	const [vatRatesLoading, setVatRatesLoading] = useState<boolean>(false);

	useEffect(() => {
		loadCatalogueItems();
	}, []);

	useEffect(() => {
		// Don't load rates if not vat registered
		if (state.estimateData?.vatRegistered === true) loadVatRates();
	}, [state.estimateData?.vatRegistered]);

	useEffect(() => {
		setIsLoading(vatRatesLoading || catalogueItemsLoading);
	}, [vatRatesLoading, catalogueItemsLoading]);

	const loadCatalogueItems = () => {
		setCatalogueItemsLoading(true);

		moduleCtx
			.getCatalogueItems()
			.then((res: any) => {
				setCatalogueItems(res);
			})
			.finally(() => {
				setCatalogueItemsLoading(false);
			});
	};

	const loadVatRates = () => {
		setVatRatesLoading(true);

		moduleCtx
			.getVatRates()
			.then((res: any) => {
				setVatRates(res);
			})
			.finally(() => {
				setVatRatesLoading(false);
			});
	};

	const handleAddNewRow = (event: any) => {
		if (isLoading) return false;

		// Check for required settings and data
		if (
			state.estimateData?.vatRegistered === true &&
			(!vatRates || (Array.isArray(vatRates) && vatRates.length === 0))
		) {
			showToast('error', 'Please setup your tax rate(s) if registered for VAT');
			return false;
		}

		const item: EstimateItem = {
			_uid: uniqueId('row_'),
			item: '',
			description: '',
			quantity: 0,
			price: 0,
			discount: 0,
			taxRateId:
				state.estimateData?.vatRegistered === true
					? vatRates.filter((rate: any) => rate.default === true)[0].id
					: '0',
			amount: 0,
		};

		let repeat = 1;
		if (event.ctrlKey) repeat = 5;

		for (let i = 0; i < repeat; i++) {
			dispatch({ type: ActionType.ADD_ITEM_ROW, payload: item });
		}
	};

	const handleDeleteRow = (index: number) => {
		// Grab a copy of the state
		const _state = cloneDeep(state);

		// Parse the tax state
		const amountsAre: any = _state.estimateData?.amountsAre;
		let taxExclusive: boolean = false;
		if (Array.isArray(amountsAre)) taxExclusive = amountsAre[0].value === 'exclusive';

		// Remove the item row locally
		_state.items.splice(index, 1);

		// Re-calculate totals based on the new items array
		calculateEstimateAmount(taxExclusive, _state.items, dispatch);

		// Update the main state
		dispatch({ type: ActionType.DELETE_ITEM_ROW, payload: { index } });
	};

	const row = (i: number, item: EstimateItem, isNew: boolean) => {
		/**
		 * Note: we must use a unique key for each row so that react keeps track of each object,
		 * e.g. when deleting item at index 0, we don't want to show what was in memory at index 0
		 * (react will show what was at index 0 because it tries to be efficient with memory usage)
		 */
		return (
			<ItemRow
				key={item._uid}
				index={i}
				item={item}
				catalogueItems={catalogueItems}
				vatRates={vatRates}
				isNew={isNew}
				onDeleteRow={handleDeleteRow}
				loadCatalogueItems={loadCatalogueItems}
			/>
		);
	};

	let tableStructure: JSX.Element = <h1>Loading</h1>;
	if (state.items !== undefined) {
		tableStructure = (
			<Table className='tbl-estimate-items' size='small'>
				<TableHead>
					<TableRow>
						<TableCell width={'19%'}>Item</TableCell>
						<TableCell width={'30%'}>Description</TableCell>
						<TableCell width={'8%'}>Quantity</TableCell>
						<TableCell width={'8%'}>Price ({authCtx.tenant.currency_symbol})</TableCell>
						<TableCell width={'8%'}>Discount (%)</TableCell>
						{state.estimateData?.vatRegistered && <TableCell width={'12%'}>Tax Rate (%)</TableCell>}
						<TableCell width={'10%'}>Amount ({authCtx.tenant.currency_symbol})</TableCell>
						<TableCell width={'3%'}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody className='items-body'>
					{state.items.map((item: EstimateItem, i: number) => row(i, item, !item?.id))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell colSpan={8}>
							<span
								onClick={handleAddNewRow}
								className={`link-button ${isLoading ? 'disabled' : ''}`}
							>
								<FontAwesomeIcon icon={faPlusCircle} /> Add new row
							</span>
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		);
	}

	return tableStructure;
};

export default EstimateItems;

import { DateTime } from 'luxon';

export interface TimesheetsState {
	year: number;
	weekEnding: DateTime;
	showMyTeam: boolean;
	workerReportMonth: number;
	workerReportYear: number;
}

export type AllocationModalProps = {
	isOpen: boolean;
	allocationData: AllocationData | null;
};

export type AllocationData = {
	type: 'day' | 'week';
	event: {
		id?: string;
		date: DateTime;
	};
	workerData: any;
};

export enum PaymentMethod {
	DAILY = 'daily',
	HOURLY = 'hourly',
	SALARY = 'salary',
}

export enum AllocationRowType {
	BREAK = 'break',
	BREAK_SUMMARY = 'break-summary',
}

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const faMobile: IconDefinition = {
	prefix: 'fas',
	iconName: 'mobile',
	icon: [
		240,
		240,
		[] as string[],
		'',
		'm 70.561106,9.0621905 c -8.740529,0 -15.464637,6.7241015 -15.464637,15.4646295 v 190.94635 c 0,8.74053 6.724108,15.46464 15.464637,15.46464 h 99.508014 c 8.06818,0 15.4642,-6.72411 14.79184,-15.46464 V 24.526819 c 0,-8.740528 -6.72411,-15.4646285 -15.46464,-15.4646285 z m 0,10.7569945 h 98.835214 c 2.68941,0 4.70571,2.018241 4.70571,4.707634 V 216.14597 c 0,2.6894 -2.0163,4.7057 -4.70571,4.7057 H 70.561106 c -2.689392,0 -4.705705,-2.0163 -4.705705,-4.7057 V 25.197682 c 0,-2.689393 2.016312,-4.705699 4.705705,-4.705699 z m 49.754004,6.051295 c -4.03408,0 -7.39689,3.362798 -7.39689,7.396888 0,4.03409 3.36281,7.394951 7.39689,7.394951 4.0341,0 7.39496,-4.033209 7.39496,-7.394951 0,-4.03409 -3.36087,-7.396888 -7.39496,-7.396888 z',
	],
};

import React, { createContext, useReducer } from 'react';
import { invoicesReducer } from './invoices-reducer';
import { InvoiceState } from './invoice-types';
import { Actions as Action } from './actions';

interface ProviderProps {
	children: React.ReactNode;
}

interface ContextProvider {
	state: InvoiceState;
	dispatch: React.Dispatch<Action>;
}

const initialState: InvoiceState = {
	invoiceData: null,
	items: [],
	isLoading: false,
	totals: { subtotal: 0, discount: 0, total: 0, vat: [] },
	totalsLoading: false,
};

const contextProps = {
	state: initialState,
	dispatch: () => null,
};

export const InvoiceContext = createContext<ContextProvider>(contextProps);

const InvoicessProvider = ({ children }: ProviderProps) => {
	const [state, dispatch] = useReducer(invoicesReducer, initialState);

	return <InvoiceContext.Provider value={{ state, dispatch }}>{children}</InvoiceContext.Provider>;
};

export default InvoicessProvider;

import {
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import Photo from '../../../../components/Media/Photo';
import Video from '../../../../components/Media/Video';
import File from '../../../../components/Media/File';
import { dateFormatterFullOrdinal } from '../../../../helpers/dateFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { TimelineDataItem } from '../job-types';
import { Link } from 'react-router-dom';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { faUser } from '@fortawesome/free-solid-svg-icons';

type TimelineRowProps = {
	index: number;
	item: TimelineDataItem;
	printMode: boolean;
	photoModalOpen?: Function;
};

const TimelineRow: React.FC<TimelineRowProps> = (props: TimelineRowProps) => {
	let output: JSX.Element = <></>;

	// Permission checks
	let permissionUid: string = '';
	switch (props.item.link_type) {
		case 'estimate':
			permissionUid = 'jobs.estimates_list.estimates_card';
			break;
		case 'invoice':
			permissionUid = 'jobs.invoices_list.debtors';
			break;
	}

	// Permission for this Timeline row, e.g. links
	const permissionTo = usePermissionTo(permissionUid);

	const buildDescription = (item: TimelineDataItem, side: string) => {
		let desc: JSX.Element = <>{item.description}</>;

		switch (item.link_type) {
			case 'message':
				desc = (
					<>
						<FontAwesomeIcon icon={faUser} className='pe-1' />
						<strong>{item.worker_name}</strong>
						<span className='pe-1'>:</span>
						{item.description}
					</>
				);
				break;
		}

		return <Typography className={side}>{desc}</Typography>;
	};

	// Type of Timeline row
	switch (props.item.type) {
		case 'timeline-date':
			output = (
				<div className='timeline-date'>
					<FontAwesomeIcon icon={faCalendar} className='pe-1' />
					{dateFormatterFullOrdinal(props.item.date)}
				</div>
			);
			break;
		case 'timeline-row':
			// Media description
			let mediaDesc: Array<string> = [];
			if (props.item?.file) {
				if (
					props.item.file.file_type.indexOf('image/') > -1 ||
					props.item.file.file_type.indexOf('video/') > -1 ||
					(props.item.file.file_type.indexOf('image/') === -1 &&
						props.item.file.file_type.indexOf('video/') === -1)
				) {
					if (props.item.file.created_at)
						mediaDesc.push(DateTime.fromISO(props.item.file.created_at).toFormat('dd/MM/yyyy'));
					if (props.item.file.town) mediaDesc.push(props.item.file.town);
					if (props.item.file.postcode) mediaDesc.push(props.item.file.postcode);
				}
			}

			// Alternate the positions of each timeline row based on the index
			const side: string = props.index % 2 === 0 ? 'push-left' : 'push-right';

			// Description element
			let descriptionElement: JSX.Element = buildDescription(props.item, side);

			// Link checks
			if (permissionTo(['read', 'update'])) {
				switch (props.item.link_type) {
					case 'estimate':
						descriptionElement = (
							<Link
								className='prop-link dotted'
								to={`/jobs/estimates_list/estimates_card/${props.item.id}`}
								title='Go to estimate'
								target='_blank'
							>
								{descriptionElement}
							</Link>
						);
						break;
					case 'invoice':
						descriptionElement = (
							<Link
								className='prop-link dotted'
								to={`/jobs/invoices_list/debtors/${props.item.id}`}
								title='Go to invoice'
								target='_blank'
							>
								{descriptionElement}
							</Link>
						);
						break;
				}
			}

			// Timeline output
			output = (
				<TimelineItem className={side}>
					<TimelineSeparator>
						<TimelineDot
							color={props.item.colour}
							sx={
								props.item.palette
									? {
											backgroundColor: `${props.item.colour}.${props.item.palette}`,
									  }
									: null
							}
						/>
						{!props.item.isEnd && <TimelineConnector />}
					</TimelineSeparator>
					<TimelineContent sx={{ py: 0 }} className={side}>
						<Typography variant='h6' component='div' className={side}>
							{DateTime.fromISO(props.item.date).toFormat('HH:mm')}
							<span className='timeline-seconds'>
								{DateTime.fromISO(props.item.date).toFormat(':ss')}
							</span>
						</Typography>

						{descriptionElement}

						{props.item?.file && props.item.file.file_type.indexOf('image/') > -1 && (
							<Photo
								path={props.item.file.thumb}
								description={mediaDesc}
								imageFunction={() => {
									if (props.photoModalOpen) {
										props.photoModalOpen({ ...props.item.file, photoDescription: mediaDesc });
									}
								}}
								printMode={props.printMode}
							/>
						)}

						{props.item?.file && props.item.file.file_type.indexOf('video/') > -1 && (
							<Video
								path={props.item.file.thumb}
								description={mediaDesc}
								imageFunction={() => {
									if (props.photoModalOpen) {
										props.photoModalOpen({ ...props.item.file, photoDescription: mediaDesc });
									}
								}}
								printMode={props.printMode}
							/>
						)}
						{props.item?.file &&
							props.item.file.file_type.indexOf('image/') === -1 &&
							props.item.file.file_type.indexOf('video/') === -1 && (
								<File
									path={props.item.file.path}
									fileName={props.item.file.file_name}
									description={mediaDesc}
									printMode={props.printMode}
									outputMode='job-timeline'
								/>
							)}
					</TimelineContent>
				</TimelineItem>
			);
			break;
	}

	return output;
};

export default TimelineRow;

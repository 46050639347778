import { useState, useRef } from 'react';
import axios from '../../lib/axios';
import ErrorMessage from '../../components/Auth/ErrorMessage';
import useErrorHandler from '../../utils/custom-hooks/ErrorHandler';
import AuthButton from '../../components/Forms/AuthButton';
import { useSelector } from 'react-redux';
import { Logos, AuthStyles } from '../../components/Auth/Styling';
import { publish } from '../../helpers/events';
import AuthFooter from '../../components/Auth/AuthFooter';
import { IonInput } from '@ionic/react';
import { Device } from '@capacitor/device';

interface Props {
	onClose: Function;
}

const TwoFA: React.FC<Props> = (props: Props) => {
	const codeDigitFirst = useRef<HTMLIonInputElement | null>(null);
	const [codeDigits, setCodeDigits] = useState<any>({
		0: '',
		1: '',
		2: '',
		3: '',
		4: '',
		5: '',
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [reIssuing, setReIssuing] = useState<boolean>(false);
	const { error, showError } = useErrorHandler(null);

	const totalDigits = 6;
	let numbersRegex = /^[0-9]+$/;

	const browserID = useSelector((state: any) => state.browserID.value);

	const handleDigitKeyDown = (e: any) => {
		if (e.key.toLowerCase() === 'enter') return false;

		if (e.key.match(numbersRegex)) {
			// Force input to be value of key
			e.target.value = e.key;
		} else {
			e.target.value = '';
		}
	};

	const handleDigitKeyUp = (e: any) => {
		if (e.key.toLowerCase() === 'enter') return false;
		
		if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === '') {
			const form = e.target.form;
			let index = [...form].indexOf(e.target) - 1;
			index = index < 0 ? 0 : index;
			form.elements[index].focus();
			return true;
		}
		
		if (e.keyCode === 8 || e.keyCode === 46) return true;

		if (e.target.value.length === 5) {
			handleCode(String(e.target.value));
			return false;
		}

		if (e.key.match(numbersRegex)) {
			// Force input to be value of key
			e.target.value = e.key;

			// Store current digits in state
			let digitIdx = e.target.parentNode.id.split('_')[1];
			setCodeDigits((prevValue: any) => ({ ...prevValue, [digitIdx]: e.key }));

			// Move to next element
			const form = e.target.form;
			let index = [...form].indexOf(e.target) + 1;
			index = index > totalDigits - 1 ? totalDigits - 1 : index;
			form.elements[index].focus();
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		const otp = Object.values(codeDigits).join('');

		if (otp.length === 0) {
			showError('Please enter a valid code.');
		} else {
			try {
				setLoading(true);

				let config = {};

				const deviceInfo = await Device.getInfo();

				const userData = await axios.post(
					'/api/user',
					{
						device_fingerprint: browserID,
						otp,
						device_info: deviceInfo,
					},
					config
				);

				const navData = await axios.get('/api/system/modules');

				setTimeout(() => {
					publish('login', true);
				}, 500);
				
				props.onClose({
					isAuthenticated: true,
					navData: navData.data,
					...userData.data,
				});
			} catch (err) {
				console.log(err);
				setLoading(false);
				showError('Please enter a valid code.');
				if (codeDigitFirst && codeDigitFirst.current) {
					//codeDigitFirst.current.focus();
				}
			}
		}
	};

	/*const getCode = () => {
		axios.get(
			'/api/twofa',
		).then((data) => {
			console.log(data);
		});
	}*/

	const handleReIssueCode = async (e: any) => {
		setReIssuing(true);

		try {
			await axios.post('/api/user', {
				device_fingerprint: browserID,
				resend: 1,
			});

			setTimeout(() => {
				publish('login', true);
			}, 500);
		} catch (err) {
		} finally {
			setReIssuing(false);

			if (codeDigitFirst && codeDigitFirst.current) {
				codeDigitFirst.current.focus();
			}
		}
	};

	const handlePaste = (event: any) => {
		event.clipboardData.items[0].getAsString((text: string) => {
			handleCode(text);
		});
	};

	const handleCode = (text: string) => {
		text.split('').forEach((char, index) => {
			if (Number.isInteger(Number(char))) {
				setCodeDigits((prevValue: any) => ({
					...prevValue,
					[index]: char,
				}));
			}
		});
	};

	return (
		<>
			<AuthStyles />
			<form className="form-auth" onSubmit={handleSubmit}>
				<div className="form-inner rounded-3 card">
					<div className="card-body p-md-5 mx-md-4">
						<Logos />

						<div className="text-center mb-4">
							2-factor authentication process
						</div>

						<div className="form-group code-digits mb-4" onPaste={handlePaste}>
							{[...Array(totalDigits)].map((x, i) => (
								<IonInput
									type="text"
									id={`code_${i}`}
									key={i}
									value={codeDigits[i]}
									ref={i === 0 ? codeDigitFirst : null}
									className="form-control code-digit"
									maxlength={1}
									min={0}
									max={9}
									onKeyDown={(e) => handleDigitKeyDown(e)}
									onKeyUp={(e) => handleDigitKeyUp(e)}
									disabled={loading}
									autofocus={i === 0}
									inputMode={'numeric'}
								/>
							))}
						</div>

						<div className="text-center">
							<p>
								You have been sent a text message to your mobile phone
								containing a 6 digit code.
							</p>
							<p>Please enter this in the boxes above.</p>
							<p>The code will expire in 5 minutes.</p>
						</div>

						<div className="buttons-2fa">
							<AuthButton
								btnType="button"
								loading={reIssuing}
								onClick={handleReIssueCode}
								className="mb-3"
							>
								RE-ISSUE CODE
							</AuthButton>
							{/*<AuthButton
								btnType="button"
								loading={reIssuing}
								onClick={getCode}
								className="mb-3"
							>
								SHOW CODE
							</AuthButton>*/}
							<AuthButton btnType="submit" loading={loading} className="mb-3">
								AUTHENTICATE
							</AuthButton>
						</div>
						{error && <ErrorMessage errorMessage={error} />}
					</div>
				</div>
			</form>
			<AuthFooter />
		</>
	);
};

export default TwoFA;

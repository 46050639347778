import { faRectangleList as faRectangleListRegular } from '@fortawesome/free-regular-svg-icons';
import { faRectangleList as faRectangleListSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
	label: string;
	choiceMade: boolean;
	dayId: string;
	allocationId: string;
	costHeadModalOpen: Function;
};

const CostHeadControl = (props: Props) => {
	return (
		<>
			<span
				onClick={() => props.costHeadModalOpen(props.dayId, props.allocationId)}
				className={`cost-head-control-container${props.choiceMade ? ' choice-made' : ''}`}
			>
				<FontAwesomeIcon icon={props.choiceMade ? faRectangleListRegular : faRectangleListSolid} />
				{props.label}
			</span>
		</>
	);
};

export default CostHeadControl;

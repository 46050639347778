import JobCostReportIndex from './JobCostReport/JobCostReportIndex';

export const reportsSubmenuArray: Array<any> = [
	{
		id: 'submenu-jobreportjobcostreport',
		uid: 'jobs.reports.job_cost_report',
		title: 'Job Cost Report',
		hash: 'job-cost-report',
		page: JobCostReportIndex,
		active: true,
		fullHeight: true,
	},
];

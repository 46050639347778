import SchedulingProvider from './SchedulingProvider';
import Scheduling from './Scheduling';
import { RouteIndexComponent } from '../../interfaces/Pages/RouteIndexComponent';
import usePermissionTo from '../../utils/custom-hooks/PermissionTo';
import './Scheduling.scss';

const SchedulingIndex: React.FC<RouteIndexComponent> = (props) => {
	const propsFinal = { ...props, permissionTo: usePermissionTo('scheduling.scheduling_board') };

	return (
		<SchedulingProvider>
			<div className={`component-${props.uid.replaceAll('.', '-')}`}>
				<Scheduling {...propsFinal} />
			</div>
		</SchedulingProvider>
	);
};

export default SchedulingIndex;

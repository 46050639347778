//import EventLogIndex from './EventLog/EventLogIndex';
import EventReportIndex from './EventReport/EventReportIndex';
import HeatmapsIndex from './Heatmaps/HeatmapsIndex';
import RouteMapIndex from './RouteMap/RouteMapIndex';

const EditTrackingTabsArray: any = (permissionTo: Function) => {
	return [
		{
			id: 'event_report',
			title: 'Event Report',
			page: EventReportIndex,
			active: true,
			pageData: { permissionTo },
		},
		/*{
			id: 'event_log',
			title: 'Event Log',
			page: EventLogIndex,
			active: false,
			pageData: { permissionTo },
		},*/
		{
			id: 'heatmap',
			title: 'Heatmap',
			page: HeatmapsIndex,
			active: false,
			pageData: { permissionTo },
		},
		{
			id: 'route_map',
			title: 'Route Map',
			page: RouteMapIndex,
			active: false,
			pageData: { permissionTo },
		},
	];
};

export default EditTrackingTabsArray;

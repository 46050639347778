import { useParams } from 'react-router-dom';
import Requests from '../../HolidayAbsenceTraining/Requests/Requests';
import { EventType, RequestStatus } from '../../HolidayAbsenceTraining/Requests/request-types';
import axios from '../../../../lib/axios';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useState } from 'react';
import { showToast } from '../../../../lib/toast';

const HolidayIndex: React.FC = () => {
	const { workerId }: any = useParams();
	const defaultStats: any = {
		holiday: { allowance: '-', approved: '-', remaining: '-', requested: '-' },
		absence: { allowance: '-', approved: '-', remaining: '-', requested: '-' },
	};
	const [stats, setStats] = useState<any>(defaultStats);
	const [curYear, setCurYear] = useState<number>(-1);

	const loadStats = (month: number, year: number) => {
		// Only update stats when changing between non-months
		let reloadStats: boolean = true;
		if (year === curYear) {
			reloadStats = false;
		}

		setCurYear(year);

		if (!reloadStats) return;

		setStats(defaultStats);

		let payload: any = {
			worker_id: workerId,
			event_types: [EventType.HOLIDAY, EventType.APPROVED_ABSENCE],
			month,
			year,
		};

		axios
			.post('/api/workers/holidays_and_absences/information', payload)
			.then((res: any) => {
				setStats(res.data);
			})
			.catch(() => {
				showToast('error');
			});
	};

	return (
		<>
			<div className='d-flex flex-column h-100'>
				<div className='flex-grow-1'>
					<Requests
						componentMode={[RequestStatus.APPROVED, RequestStatus.DECLINED]}
						usageMode={'workerCard'}
						usageModeId={workerId}
						usageModeCallback={loadStats}
						parentRef={null}
						tabRef={null}
					/>
				</div>
				<div>
					<Table className='table-sections mt-2' size='small'>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell className='fw-bold text-nowrap'>Holidays</TableCell>
								<TableCell className='fw-bold text-nowrap'>Approved Absence</TableCell>
								<TableCell width='100%'></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell className='text-nowrap'>
									Days requested {curYear > 0 ? 'in ' + curYear : 'all-time'}
								</TableCell>
								<TableCell>{stats.holiday.requested}</TableCell>
								<TableCell colSpan={2}>{stats.absence.requested}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className='text-nowrap'>
									Days approved {curYear > 0 ? 'in ' + curYear : 'all-time'}
								</TableCell>
								<TableCell>{stats.holiday.approved}</TableCell>
								<TableCell colSpan={2}>{stats.absence.approved}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className='text-nowrap'>
									Allowance {curYear > 0 ? 'in ' + curYear : 'all-time'}
								</TableCell>
								<TableCell>{stats.holiday.allowance}</TableCell>
								<TableCell colSpan={2}>{stats.absence.allowance}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className='text-nowrap'>
									Days remaining {curYear > 0 ? 'in ' + curYear : 'all-time'}
								</TableCell>
								<TableCell>{stats.holiday.remaining}</TableCell>
								<TableCell colSpan={2}>{stats.absence.remaining}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</div>
			</div>
		</>
	);
};

export default HolidayIndex;

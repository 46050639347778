import { IonRow, IonCol, IonCard, IonButton } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import UploadFileButton from '../../../../components/Forms/UploadFileButton';
import { Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { downloadFile } from '../../../../helpers/download';
import { useState } from 'react';
import Loading from '../../../../components/UI/Loading';

const ImportClientIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleDownloadTemplate = () => {
		downloadFile(
			'api/jobs/clients_list/import/template',
			setIsLoading,
			'Clients Import Template.xlsx'
		);
	};

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={`Clients - ${routeTitle}`} />
				<IonCard className='table-card'>
					{isLoading && <Loading overlay={true} />}
					<IonRow>
						<IonCol size='6'>
							<Alert severity='info' className='m-0 mb-2'>
								<strong>Step 1.</strong> Download the Clients import template
							</Alert>
							<IonButton className='btn-large-icon' color='light' onClick={handleDownloadTemplate}>
								<div className='d-flex flex-column'>
									<div>
										<FontAwesomeIcon icon={faFileDownload} />
									</div>
									<div className='btn-large-icon-label'>Download Template</div>
								</div>
							</IonButton>
						</IonCol>
						<IonCol size='6'>
							<Alert severity='info' className='m-0 mb-2'>
								<strong>Step 2.</strong> Upload your filled-in Clients import template
							</Alert>
							<UploadFileButton
								permissionTo={permissionTo}
								uploadKey='clients_upload'
								apiUrl='/api/jobs/clients_list/import'
								acceptTypes='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv'
							/>
						</IonCol>
					</IonRow>
				</IonCard>
			</div>
		</>
	);
};

export default ImportClientIndex;

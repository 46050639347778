import { ResourceMode, SchedulingEvent, SchedulingState, ViewMode } from './scheduling-types';
import { Actions as Action, ActionType } from './actions';

export const schedulingReducer = (state: SchedulingState, action: Action): SchedulingState => {
	switch (action.type) {
		case ActionType.SET_VIEW: {
			const { payload } = action;

			const viewMode: ViewMode = payload;

			return {
				...state,
				viewMode,
			};
		}
		case ActionType.SET_RESOURCE_MODE: {
			const { payload } = action;

			const resourceMode: ResourceMode = payload;

			return {
				...state,
				resourceMode,
			};
		}
		case ActionType.CREATE_TEMP_EVENT: {
			const { payload } = action;

			const tempEvent: SchedulingEvent = payload;

			return {
				...state,
				tempEvent,
			};
		}
		case ActionType.UPDATE_TEMP_EVENT: {
			const { payload } = action;

			const tempEvent: SchedulingEvent = { ...state.tempEvent, ...payload };

			return {
				...state,
				tempEvent,
			};
		}
		case ActionType.DELETE_TEMP_EVENT: {
			const { tempEvent, ...rest } = state;

			return rest;
		}
		case ActionType.SET_FILTER: {
			const { payload } = action;

			let newState: SchedulingState = state;

			if (Object.keys(payload).length > 0) {
				// Set or update value
				newState = { ...state, filter: payload };
			} else {
				// Remove value and its key
				const { filter, ...rest } = newState;
				newState = rest;
			}

			return {
				...newState,
			};
		}
		default: {
			return state;
		}
	}
};

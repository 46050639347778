import debounce from 'lodash.debounce';
import axios from '../../lib/axios';

const JobsOptionsEstimates = debounce((inputValue: any, callback: any, jobId: string = '') => {
	let payload: any = {};

	if (jobId.length > 0) {
		payload = { job_id: jobId };
	} else {
		payload = { search: inputValue };
	}

	axios({
		method: 'post',
		url: '/api/jobs/estimates_list/estimates_card/search',
		data: payload,
	}).then((res) => {
		const options = res.data.map((r: any) => {
			const data = {
				label: r.name,
				value: r,
			};
			return data;
		});
		callback(options);
	});
}, 600);

export default JobsOptionsEstimates;

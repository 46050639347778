import { useHistory } from "react-router";
import { IonRow, IonCol } from '@ionic/react';
import { useState } from "react";

import useComponentVisible from "../../utils/custom-hooks/ComponentVisible";
import Avatar from "../../components/TitleBar/Avatar";
import BackButton from "../../components/TitleBar/BackButton";
import UserMenu from "../../components/TitleBar/UserMenu";
import logoTitleBar from "../../assets/images/logos/hf_logo_title_bar.png";

interface Props {
	title: string;
	goBack?: Function | string;
	children?: any;
}

const TitleBar: React.FC<Props> = (props: Props) => {
	const history = useHistory();
	const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);

	const handleGoBack = () => {
		if (props.goBack) {
			if (typeof props.goBack === "function") {
				props.goBack();
			} else {
				history.push(props.goBack);
			}
		} else {
			history.goBack();
		}
	};

	return (
		<>
			<IonRow className='title-bar'>
				<IonCol size={'6'} className='p-0' style={{ display: 'flex', alignItems: 'center' }}>
					<BackButton onClick={() => handleGoBack()} />
					<h1 className='hf-page-title m-0'>{props.title}</h1>
					{props.children}
				</IonCol>
				<IonCol size={'6'} className='p-0'>
					<div className='top-left-container'>
						<Avatar
							onClick={() => setIsComponentVisible((prevValue) => !prevValue)}
						/>
						<img src={logoTitleBar} className='title-bar-logo' alt='HumanFacta' slot='start' />
						<UserMenu isVisible={isComponentVisible} onVisibleChange={setIsComponentVisible} />
					</div>
				</IonCol>
			</IonRow>
		</>
	);
};

export default TitleBar;

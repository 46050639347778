import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonCol,
	IonRow,
	IonGrid,
	IonLabel,
	IonInput,
	IonRadio,
	IonRadioGroup,
} from '@ionic/react';
import { EventData, EventType } from '../TrainingEvent/event-types';
import { DateTime, Info } from 'luxon';
import SelectStyled from '../../../../components/UI/SelectStyled';
import { Datepicker } from '@mobiscroll/react';
import { AddressLookup, AutoComplete, InfoBox } from '../../../../components/Forms/FormFields';
import WorkersOptions from '../../../../utils/autocomplete/WorkersOptions';
import { getNoOfDays } from '../../../../lib/functions';
import { cloneDeep } from 'lodash';
import Loading from '../../../../components/UI/Loading';
import { BoundarySize } from '../../../../components/Maps/map-types';
import TimeRow from '../../../../components/Forms/TimeRow';

interface EventModalProps {
	isOpen: boolean;
	initialData: EventData;
	onClose: Function;
	onSave: Function;
	loadEvent: Function;
}

const EventModal: React.FC<EventModalProps> = ({
	isOpen,
	initialData,
	onClose,
	onSave,
	loadEvent,
}) => {
	const [title, setTitle] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [rangeIsDisabled, setRangeIsDisabled] = useState<boolean>(true);
	const [hideLocationInfoBox, setHideLocationInfoBox] = useState<boolean>(false);
	const [eventData, setEventData] = useState<EventData>();
	const [isNew, setIsNew] = useState<boolean>(true);
	const [loadedTimes, setLoadedTimes] = useState<Array<string>>([]);
	const refMultiDay = useRef<any>(null);
	const yesNoValues: Array<any> = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	];
	const [dayNames, setDayNames] = useState<Array<string>>([]);

	useEffect(() => {
		let weekdays = Info.weekdays('short');
		let last = weekdays.pop();
		if (last) {
			weekdays.unshift(last);
		}
		setDayNames(weekdays.map((day) => day.toUpperCase()));
	}, []);

	useEffect(() => {
		if (isNew === false && initialData.requestId) {
			loadEvent(initialData.requestId)
				.then((res: any) => {
					// Build an object to replace the eventData state with
					let evt: EventData = {
						requestId: res.request_id,
						selectedDate: initialData.selectedDate,
						multiDay: res.events.length > 1,
						startDate: DateTime.fromISO(res.start),
						endDate: DateTime.fromISO(res.end),
						time: [],
						type: res.events[0].enum_event_type,
						description: res.events[0].description,
						companyName: res.company_name,
						contactName: res.contact_name,
						contactNumber: res.contact_number,
						attendees: res.attendees,
						location: {
							address1: res.location.address_1,
							address2: res.location.address_2,
							town: res.location.town,
							postcode: res.location.postcode,
							latitude: res.location.latitude,
							longitude: res.location.longitude,
							boundarySize: res.location.boundary_size,
						},
						certificate: Boolean(res.enable_uploads),
					};

					// Build the time(s) into a state object to use after the multi day dropdown is set
					let times: Array<string> = [];
					res.events.forEach((event: any) => {
						const theTime: string =
							DateTime.fromISO(event.start).toFormat('HH:mm') +
							' - ' +
							DateTime.fromISO(event.end).toFormat('HH:mm');

						evt.time.push(theTime);
						times.push(theTime);
					});
					setLoadedTimes(times);

					// Set the loaded event data
					setEventData(evt);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [isNew]);

	const handleOnWillPresent = () => {
		// Must clone the initial data into the new state variable or changes affect the initial data
		setEventData(cloneDeep(initialData));

		// Reset
		setRangeIsDisabled(true);
		setLoadedTimes([]);
		setHideLocationInfoBox(false);

		// Setup
		if (!initialData?.requestId && initialData?.selectedDate) {
			setIsNew(true);
			setTitle(
				`New Event: ${DateTime.fromJSDate(initialData?.selectedDate).toLocaleString(
					DateTime.DATE_HUGE
				)}`
			);
		} else if (initialData?.requestId) {
			setIsLoading(true);
			setTitle(`Update Event: ${initialData?.description}`);
			setIsNew(false);
		}
	};

	const handleOnDidDismiss = () => {
		// Reset
		setIsNew(true);

		// Close the modal
		onClose();
	};

	// Date range
	useEffect(() => {
		updateDateRange(eventData?.startDate!, eventData?.endDate!);
	}, [rangeIsDisabled]);

	useEffect(() => {
		setRangeIsDisabled(!eventData?.multiDay);
	}, [eventData]);

	const updateDateRange = (startDate: DateTime, endDate: DateTime) => {
		if (rangeIsDisabled !== true && startDate && endDate) {
			// Set the time array
			let timeArray: Array<string> = [];
			const noOfDays: number = getNoOfDays([startDate, endDate]);

			// Fill the time array with as many items as number of days
			[...Array(noOfDays)].forEach((element: any, i: number) =>
				timeArray.push(loadedTimes.length > 0 ? loadedTimes[i] : eventData?.time[0])
			);
			setEventData((prevState: any) => ({ ...prevState, time: timeArray }));
		} else {
			// Reset the time array back to one
			let timeArray: Array<string> = [loadedTimes.length > 0 ? loadedTimes[0] : eventData?.time[0]];
			setEventData((prevState: any) => ({ ...prevState, time: timeArray }));
		}
	};

	// TimeRow callbacks
	const timeRowCallback = useCallback((index: number, theTime: string) => {
		setEventData((prevState: any) => {
			// Update the time array at the correct key
			let timeChange = prevState.time;
			timeChange[index] = theTime;
			return { ...prevState, time: timeChange };
		});
	}, []);

	const timeRowOnChange = useCallback((index: number, e: any, defaultTime: string) => {
		setEventData((prevState: any) => {
			// Update the time array at the correct key
			let timeChange = prevState.time;
			timeChange[index] = e.valueText.length > 5 ? e.valueText : defaultTime;
			return { ...prevState, time: timeChange };
		});
	}, []);

	return (
		<IonModal
			style={{ '--width': '50vw', '--height': '75vh' }}
			isOpen={isOpen}
			onWillPresent={handleOnWillPresent}
			onDidDismiss={handleOnDidDismiss}
			className='prop-form hattrainingevent-training-event-modal'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{title}</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => handleOnDidDismiss()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='tight-margins-modal prop-form'>
				{isLoading && <Loading overlay={true} />}
				<IonGrid>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Multi-Day</IonLabel>
						</IonCol>
						<IonCol size='8'>
							<IonRow>
								<IonCol
									className='ps-0 py-0'
									style={{ flexGrow: 0, flexShrink: 0, flexBasis: '6.4em' }}
								>
									<SelectStyled
										className='react-select-container'
										classNamePrefix='react-select'
										forwardRef={refMultiDay}
										options={yesNoValues}
										onChange={(newValue: any) => {
											setEventData((prevState: any) => ({
												...prevState,
												multiDay: newValue.value,
											}));
										}}
										value={yesNoValues.filter(
											(option: any) => option.value === eventData?.multiDay
										)}
									/>
								</IonCol>
								<IonCol className='pe-0 py-0' style={{ flexGrow: 1 }}>
									<Datepicker
										dayNamesMin={dayNames}
										select='range'
										controls={['calendar']}
										firstDay={1}
										rangeHighlight={true}
										showRangeLabels={false}
										defaultValue={
											!isNew &&
											eventData?.multiDay === true &&
											eventData.startDate &&
											eventData.endDate
												? eventData.startDate.toFormat('dd/MM/yy') +
												  ' - ' +
												  eventData.endDate.toFormat('dd/MM/yy')
												: null
										}
										disabled={rangeIsDisabled}
										inputComponent={IonInput}
										inputProps={{
											placeholder: 'Please select...',
											disabled: rangeIsDisabled,
										}}
										returnFormat={'jsdate'}
										onClose={(e: any) => {
											if (e.value) {
												const selStart: DateTime = DateTime.fromJSDate(e.value[0]);
												const selEnd: DateTime = DateTime.fromJSDate(e.value[1]);

												updateDateRange(selStart, selEnd);
												setEventData((prevState: any) => {
													return {
														...prevState,
														startDate: selStart,
														endDate: selEnd,
													};
												});
											}
										}}
									/>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Event Time</IonLabel>
						</IonCol>
						<IonCol size='8' className='pb-0'>
							{eventData?.time.map((theTime: string, index: number) =>
								TimeRow(theTime, index, eventData, timeRowCallback, timeRowOnChange)
							)}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>Event Type</IonLabel>
						</IonCol>
						<IonCol size='8' style={{ padding: '7px 0 2px 5px' }}>
							<IonRadioGroup
								onIonChange={(e: any) =>
									setEventData((prevState: any) => ({ ...prevState, type: e.detail.value }))
								}
								value={eventData?.type}
							>
								<IonRow>
									<IonCol className='ps-0 flex-grow-0 text-nowrap'>Training</IonCol>
									<IonCol className='pe-3 flex-grow-0'>
										<IonRadio value={EventType.TRAINING} />
									</IonCol>
									<IonCol className='flex-grow-0 text-nowrap'>Other</IonCol>
									<IonCol className='flex-grow-0'>
										<IonRadio value={EventType.OTHER} />
									</IonCol>
								</IonRow>
							</IonRadioGroup>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Description</IonLabel>
						</IonCol>
						<IonCol size='8'>
							<IonInput
								onIonInput={(e: any) =>
									setEventData((prevState: any) => ({ ...prevState, description: e.target.value }))
								}
								value={eventData?.description}
							/>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol size='4'>
							<IonLabel>Company Name</IonLabel>
						</IonCol>
						<IonCol size='8'>
							<IonInput
								onIonInput={(e: any) =>
									setEventData((prevState: any) => ({ ...prevState, companyName: e.target.value }))
								}
								value={eventData?.companyName}
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Contact Name</IonLabel>
						</IonCol>
						<IonCol size='8'>
							<IonInput
								onIonInput={(e: any) =>
									setEventData((prevState: any) => ({ ...prevState, contactName: e.target.value }))
								}
								value={eventData?.contactName}
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size='4'>
							<IonLabel>Contact Number</IonLabel>
						</IonCol>
						<IonCol size='8'>
							<IonInput
								onIonInput={(e: any) =>
									setEventData((prevState: any) => ({
										...prevState,
										contactNumber: e.target.value,
									}))
								}
								value={eventData?.contactNumber}
							/>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>Attendees</IonLabel>
						</IonCol>
						<IonCol size='8' className='px-0 py-0'>
							<AutoComplete
								placeholder='Please start typing the name of an attendee...'
								loadOptions={(inputValue: any, callback: any) => {
									WorkersOptions(inputValue, callback);
								}}
								value={eventData?.attendees}
								isMulti={true}
								defaultOptions={false}
								useLabels={false}
								editMode={true}
								onChangeCallback={(options: any) => {
									setEventData((prevState: any) => {
										return { ...prevState, attendees: options };
									});
								}}
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>Location</IonLabel>
						</IonCol>
						<IonCol size='8' className='px-0 py-0'>
							<AddressLookup
								id='location'
								title='Location'
								editMode={true}
								useLabels={false}
								addressLookupFunction={(address: any, lookupID: any) => {
									setEventData((prevState: any) => {
										return {
											...prevState,
											location: {
												address1: address.addressline1,
												address2: address.addressline2,
												town: address.posttown,
												postcode: address.postcode,
												latitude: address.latitude,
												longitude: address.longitude,
												boundarySize: BoundarySize.LARGE,
											},
										};
									});

									// Hide the box
									setHideLocationInfoBox(true);
								}}
								oneLine={true}
							/>
							{eventData?.requestId && eventData?.location && !hideLocationInfoBox && (
								<InfoBox
									value={`Event Location: ${eventData.location.address1}, ${eventData.location.town}, ${eventData.location.postcode}`}
									useLabels={false}
									showBorder={true}
									showBackground={true}
								/>
							)}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size='4' className='pb-0'>
							<IonLabel>Certificate Upload</IonLabel>
						</IonCol>
						<IonCol size='8' className='px-0 py-0'>
							<IonRow>
								<IonCol style={{ flexGrow: 0, flexShrink: 0, flexBasis: '6.5em' }}>
									<SelectStyled
										className='react-select-container'
										classNamePrefix='react-select'
										options={yesNoValues}
										onChange={(newValue: any) => {
											setEventData((prevState: any) => ({
												...prevState,
												certificate: newValue.value,
											}));
										}}
										value={yesNoValues.filter(
											(option: any) => option.value === eventData?.certificate
										)}
									/>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
			<IonFooter>
				<IonToolbar>
					<IonRow>
						<IonCol size={'12'} className='text-right'>
							<IonButton
								color='secondary'
								onClick={() => handleOnDidDismiss()}
								disabled={isLoading}
							>
								Cancel
							</IonButton>
							<IonButton color='primary' onClick={() => onSave(eventData)} disabled={isLoading}>
								{eventData?.requestId ? 'Update' : 'Create'} Event
							</IonButton>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonFooter>
		</IonModal>
	);
};

export default EventModal;

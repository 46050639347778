import styled from 'styled-components';

const ErrorMessage = styled.p`
	textalign: center;
	margin-top: 10px;
	color: #ff0000;
`;

const ErrorMessageContainer = ({ errorMessage }: any) => {
	let theMessage: string = '';

	switch (typeof errorMessage) {
		case 'object':
			if (errorMessage.hasOwnProperty('errorMessage')) theMessage = errorMessage.errorMessage;
			break;
		case 'string':
		default:
			theMessage = errorMessage;
	}

	return <ErrorMessage>{theMessage}</ErrorMessage>;
};

export default ErrorMessageContainer;

import { IonButton } from '@ionic/react';
import SelectStyled from '../../../../components/UI/SelectStyled';

type Props = {
	gridRef: any;
	clients: Array<any>;
	chosenClient: any;
	setChosenClient: Function;
	jobManagers: Array<any>;
	chosenJobManager: any;
	setChosenJobManager: Function;
	jobStatuses: Array<any>;
	chosenJobStatus: any;
	setChosenJobStatus: Function;
};

const ExtraFooterControls = (props: Props) => {
	const handleExport = () =>
		props.gridRef.current ? props.gridRef.current.exportDataAsExcel() : null;

	return (
		<div className='extra-footer-controls-container'>
			<div className='efc-label'>Filter Client:</div>
			<div className='efc-control'>
				<SelectStyled
					defaultValue={props.chosenClient}
					value={props.chosenClient}
					options={props.clients}
					onChange={props.setChosenClient}
					isMulti={false}
					isClearable={true}
					isSearchable={false}
				/>
			</div>
			<div className='efc-label'>Filter Job Manager:</div>
			<div className='efc-control'>
				<SelectStyled
					defaultValue={props.chosenJobManager}
					value={props.chosenJobManager}
					options={props.jobManagers}
					onChange={props.setChosenJobManager}
					isMulti={false}
					isClearable={true}
					isSearchable={false}
				/>
			</div>
			<div className='efc-label'>Filter Job Status:</div>
			<div className='efc-control'>
				<SelectStyled
					defaultValue={props.chosenJobStatus}
					value={props.chosenJobStatus}
					options={props.jobStatuses}
					onChange={props.setChosenJobStatus}
					isMulti={false}
					isClearable={true}
					isSearchable={false}
				/>
			</div>
			<IonButton color='tertiary' onClick={handleExport}>
				Export XLSX
			</IonButton>
		</div>
	);
};

export default ExtraFooterControls;

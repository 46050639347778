import { IonRow, IonCol, IonLabel, IonButton } from '@ionic/react';
import { MouseEventHandler, useEffect, useState } from 'react';
import { ActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';

import './AutoComplete.scss';

interface AutoCompleteProps {
	id?: any;
	forwardRef?: any;
	className?: string;
	title?: string;
	editMode?: boolean;
	useLabels?: boolean;
	disabled?: boolean;
	isSearchable?: boolean;
	onChangeCallback: ((newValue: unknown, actionMeta: ActionMeta<unknown>) => void) | undefined;
	placeholder?: string;
	loadOptions: any;
	addNewLabel?: string;
	handleAddNew?: MouseEventHandler<HTMLIonButtonElement> | null;
	styles?: any;
	classNames?: any;
	value?: any;
	isClearable?: boolean;
	defaultOptions?: boolean;
	cacheOptions?: boolean;
	options?: any;
	components?: any;
	isMulti?: boolean;
}

export const AutoComplete: React.FC<AutoCompleteProps> = (props: AutoCompleteProps) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);

	// Component styles
	let styles = { menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) };
	if (props.styles) styles = { ...styles, ...props.styles };

	useEffect(() => {
		if (props.editMode) {
			setIsEdit(props.editMode);
		}
	}, [props.editMode]);

	return isEdit ? (
		<IonRow className='prop-form prop-form-edit'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel position='floating'>
						<span className='field-title'>{props.title}</span>
					</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ flex: 1 }}>
						<AsyncSelect
							id={props.id && props.id}
							ref={props.forwardRef && props.forwardRef}
							className={`react-select-container react-select-autocomplete${
								props.isMulti ? '-multi' : ''
							} ${props.className ? props.className : ''}`}
							classNamePrefix='react-select'
							isDisabled={props.disabled}
							isSearchable={props.hasOwnProperty('isSearchable') ? props.isSearchable : true}
							onChange={props.onChangeCallback}
							placeholder={props.placeholder ? props.placeholder : 'Please start typing'}
							menuPortalTarget={document.body}
							defaultOptions={props.hasOwnProperty('defaultOptions') ? props.defaultOptions : false}
							loadOptions={props.loadOptions}
							isClearable={props.hasOwnProperty('isClearable') ? props.isClearable : false}
							styles={styles}
							classNames={props.classNames && props.classNames}
							value={props.value && props.value}
							cacheOptions={props.hasOwnProperty('cacheOptions') ? props.cacheOptions : false}
							options={props.hasOwnProperty('options') ? props.options : []}
							components={props.hasOwnProperty('components') ? props.components : null}
							isMulti={props.isMulti}
							menuPlacement='auto'
						/>
					</div>
					{props.handleAddNew && (
						<IonButton
							style={{ margin: '0 0 0 8px' }}
							color='primary'
							size='small'
							disabled={props.disabled}
							onClick={props.handleAddNew}
						>
							{props.addNewLabel ? props.addNewLabel : 'Add New'}
						</IonButton>
					)}
				</div>
			</IonCol>
		</IonRow>
	) : (
		<IonRow className='prop-form'>
			{props.useLabels !== false && (
				<IonCol size={'12'} sizeMd={'6'} sizeLg={'3'}>
					<IonLabel>{props.title}</IonLabel>
				</IonCol>
			)}
			<IonCol
				size={'12'}
				sizeMd={props.useLabels === false ? '12' : '6'}
				sizeLg={props.useLabels === false ? '12' : '9'}
			>
				<p>N/A</p>
			</IonCol>
		</IonRow>
	);
};

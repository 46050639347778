import { useContext, useEffect, useState } from 'react';
import { IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../../lib/toast';
import { toast } from 'react-toastify';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { cloneDeep } from 'lodash';
import Loading from '../../../../components/UI/Loading';
import AddTeamMemberModal from './modals/AddTeamMemberModal';

interface Props {
	permissionTo: Function;
}

const JobTeamIndex: React.FC<Props> = (props: Props) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [workersLoading, setWorkersLoading] = useState<boolean>(true);
	const [workersOptions, setWorkersOptions] = useState<Array<any>>([]);
	const [workersLookup, setWorkersLookup] = useState<Array<any>>([]);
	const [data, setData] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const { jobId }: any = useParams();
	const [presentAlert] = useIonAlert();

	// Modals
	const [addTeamMemberModal, setAddTeamMemberModal] = useState<any>({
		isOpen: false,
		modalData: null,
	});

	useEffect(() => {
		loadWorkers();
	}, []);

	useEffect(() => {
		if (!workersLoading) loadGridData();
	}, [workersLoading]);

	const colData: Array<any> = [
		{
			headerName: 'Team Member',
			field: 'name',
			flex: 1,
		},
		{
			headerName: 'Role',
			field: 'role',
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 90,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					props.permissionTo('delete') &&
					params.data.role !== 'Job Manager' && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const loadWorkers = async () => {
		setWorkersLoading(true);

		await moduleCtx.getWorkers().then((res: any) => {
			const result = res.map((r: any) => {
				return {
					label: r.name,
					value: r.id,
				};
			});
			setWorkersOptions(result);
			setWorkersLookup(result);
		});

		setWorkersLoading(false);
	};

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		axios.get(`/api/jobs/jobs_list/job_card/${jobId}`).then((res: any) => {
			let team: Array<any> = [];

			// Sort the existing team
			const existingTeam = sortTeam(res.data.team);

			// Build team: Job Manager
			const jobManager = workersLookup.filter(
				(worker: any) => worker.value === res.data.manager_id
			);
			if (Array.isArray(jobManager) && jobManager.length > 0) {
				team.push({
					id: res.data.manager_id,
					name: jobManager[0].label,
					role: 'Job Manager',
				});
			}

			// Add the existing team after the defaults
			team.push(...existingTeam);

			updateTeamSelect(team);
			setData(team);
			setGridLoading(false);
		});
	};

	const sortTeam = (team: Array<any>) => {
		return team.sort((a: any, b: any) => {
			let lastNameA = a.name.split(' ');
			lastNameA = lastNameA[lastNameA.length - 1];

			let lastNameB = b.name.split(' ');
			lastNameB = lastNameB[lastNameB.length - 1];

			return lastNameA > lastNameB ? 1 : -1;
		});
	};

	const updateTeamSelect = (team: Array<any>) => {
		// Update existing team members in the dropdown (e.g. to avoid multi-adding the same member)
		setWorkersOptions((prevState: any) => {
			return prevState.filter((option: any) => team.every((t) => t.id !== option.value));
		});
	};

	const handleAddTeamMember = () => {
		setAddTeamMemberModal({
			isOpen: true,
			modalData: {
				workersOptions,
				jobId,
			},
		});
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Remove Team Member',
			message: 'Are you sure you want to remove this team member?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');
		setIsLoading(true);

		const newData: Array<any> = data.filter(
			(item: any) => item.id !== id && item.role === 'Additional'
		);

		// Remove selected team member
		const payload: any = {
			team: newData,
		};

		// Full grid data minus the deleted member
		const finalGridData = data.filter((item: any) => item.id !== id);

		// Put the removed team member back in the dropdown list
		let replenishDropdownArray: Array<any> = cloneDeep(workersLookup);
		replenishDropdownArray = replenishDropdownArray.filter((ad) =>
			finalGridData.every((fd) => fd.id !== ad.value)
		);

		axios
			.put(`/api/jobs/jobs_list/job_card/${jobId}`, payload)
			.then(() => {
				setWorkersOptions(replenishDropdownArray);
				updateTeamSelect(newData);
				setData(finalGridData);

				showToast('removed', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const addTeamMemberModalOnClose = () => {
		setAddTeamMemberModal({ ...addTeamMemberModal, isOpen: false });
	};

	return (
		<>
			{isLoading && <Loading overlay={true} />}
			<DataGrid
				title={'Job Team'}
				cols={colData}
				data={data}
				showFooter={false}
				extraFooter={
					<IonRow>
						<IonCol size={'10'}></IonCol>
						<IonCol size={'2'} className='px-0 py-0 text-right'>
							{props.permissionTo('update') && (
								<IonButton
									color='success'
									onClick={handleAddTeamMember}
									disabled={workersLoading || gridLoading}
								>
									Add Team Member
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				}
			/>

			<AddTeamMemberModal
				isOpen={addTeamMemberModal.isOpen}
				modalData={addTeamMemberModal.modalData}
				onClose={addTeamMemberModalOnClose}
				permissionTo={props.permissionTo}
				data={data}
				setData={setData}
				sortTeam={sortTeam}
				updateTeamSelect={updateTeamSelect}
			/>
		</>
	);
};

export default JobTeamIndex;

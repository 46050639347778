import { useEffect, useReducer } from 'react';
import { DateTime } from 'luxon';
import { IonAccordion, IonAccordionGroup, IonBadge, IonItem, IonLabel, IonList, IonTitle,} from '@ionic/react';

import { listReducer } from '../../../helpers/listReducer';
import './WorkerList.scss';

interface Props {
	data?: Array<any>;
	height?: number | string;
	workerClick?: (worker: any) => {}
}

const WorkerList: React.FC<Props> = (props: Props) => {
	const [jobData, dispatchJobData] = useReducer(listReducer, {
		list: [],
		originalList: [],
		isShowList: true,
	});
	const [officeData, dispatchOfficeData] = useReducer(listReducer, {
		list: [],
		originalList: [],
		isShowList: true,
	});
	const [generalData, dispatchGeneralData] = useReducer(listReducer, {
		list: [],
		originalList: [],
		isShowList: true,
	});

	useEffect(() => {
		generateData();
	}, [props.data]);

	const generateData = () => {
		if (props.data) {
			let data = props.data ?? [];
			buildData('office', data, dispatchOfficeData);
			buildData('job', data, dispatchJobData);
			buildData('general', data, dispatchGeneralData);
		}
	}

	const buildData = (key: string, items: any, func?: Function) => {
		let data: Array<any> = items[key] ?? [];
		data = data.filter((datum) => {
			return datum.workers.length > 0;
		}).sort(function (a, b) {
			if (a.location < b.location) return -1;
			if (a.location > b.location) return 1;
			return 0;
		});
		data.forEach((datum: any) => {
			if (datum.location !== 'Not Logged In') {
				datum.workers = datum.workers.sort(function (a: any, b: any) {
					let keyA = DateTime.fromISO(a.date).toFormat('x');
					let keyB = DateTime.fromISO(b.date).toFormat('x');
					if (keyA < keyB) return 1;
					if (keyA > keyB) return -1;
					return 0;
				});
			}
		});

		if (key === 'office') {
			let locations = buildData('location', items);
			locations?.forEach((location: any) => {
				let offLocation = items['office'].find((office: any) => {
					return office._id === location._id 
				});
				if (offLocation !== undefined && offLocation !== null) {
					data.push(location);
				}
			});
		}

		if (key === 'job') {
			let locations = buildData('appointment', items);
			locations?.forEach((location: any) => {
				let jobLocation = items['job'].find((job: any) => {
					return job._id === location._id
				});
				if (jobLocation !== undefined && jobLocation !== null) {
					data.push(jobLocation);
				}
			});
		}
		
		if (func !== undefined) {
			func({
				type: 'replace',
				data: data ?? [],
			});
		} else {
			return data;
		}
	}

	const getColour = (worker: any) => {
		const dt = DateTime.fromISO(worker.date);
		const now = DateTime.now();
		let minutes = now.diff(dt, ['minutes']).toObject().minutes ?? 0;
		return minutes < 15 ? 'success' : 'quaternary';
	}

	const buildItem = (item: any, index: number) => (
		<IonAccordion value={item.location} key={index}>
			<IonItem slot="header" color="chat-grey">
				<IonLabel>{item.location} ({item.workers.length})</IonLabel>
			</IonItem>
			<div slot="content">
				<IonList>
					{item.workers?.map((worker: any, wI: number) => {
						return (
							<IonItem button key={wI} color='light' onClick={() => {
								if (props.workerClick) {
									props.workerClick({label: worker.name, value: worker._id});
								}
							}}>
								<IonLabel>{worker.name}</IonLabel>
								{worker.date && item.location !== 'Not Logged In' ? (<IonBadge slot="end" color={getColour(worker)}>{DateTime.fromISO(worker.date).toFormat('HH:mm')}</IonBadge>) : null}
							</IonItem>
						)
					})}
				</IonList>
			</div>
		</IonAccordion>
	);

	return (
		<div className='worker-container'>
			<IonList>
				<IonItem color={'white'} className='list-item-title'>
					<IonTitle>Location Summary</IonTitle>
				</IonItem>
			</IonList>
			<div className='worker-content'>
				<>
					<IonAccordionGroup multiple={true}>
						{jobData.list.length > 0 && (
							<IonItem color='mid-light-grey' className="sub-title">
								<IonLabel>Jobs</IonLabel>
							</IonItem>
						)}
						{jobData.list.length > 0 && jobData.list.map((item: any, index: number) => {
							return buildItem(item, index);
						})}
						{officeData.list.length > 0 && (
							<IonItem color='mid-light-grey' className="sub-title">
								<IonLabel>Offices</IonLabel>
							</IonItem>
						)}
						{officeData.list.length > 0 && officeData.list.map((item: any, index: number) => {
							return buildItem(item, index);
						})}
						{generalData.list.length > 0 && (
							<IonItem color='mid-light-grey' className="sub-title">
								<IonLabel>Other</IonLabel>
							</IonItem>
						)}
						{generalData.list.length > 0 && generalData.list.map((item: any, index: number) => {
							return buildItem(item, index);
						})}
					</IonAccordionGroup>
				</>
			</div>
		</div>
	);
};

export default WorkerList;

import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
	IonFooter,
	IonRow,
	IonCol,
	IonToggle,
	IonInput,
} from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TimesheetsContext } from '../../TimesheetsProvider';
import { AllocationData, AllocationRowType } from '../../timesheets-types';
import Loading from '../../../../components/UI/Loading';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { DateTime, Duration } from 'luxon';
import HeatmapModal from './HeatmapModal';
import axios from '../../../../lib/axios';
import { showToast } from '../../../../lib/toast';
import { cloneDeep } from 'lodash';
import {
	DURATION_UNITS_OBJ,
	EMPTY_DATA_STR,
	dataRow,
	getCellClass,
	getColSpan,
	headerRow,
	renderCell,
	spacerRow,
	subHeaderRow,
	subTotalRow,
	totalRow,
} from '../allocations-functions';
import CostHeadControl from '../components/CostHeadControl';
import CostHeadModal from './CostHeadModal';
import { Datepicker } from '@mobiscroll/react';
import RoutemapModal from './RoutemapModal';
import LocationsModal from './LocationsModal';
import { HeatmapsUsageMode } from '../../../Workers/Workers/workers-types';
import { convertSecondsToDurationString } from '../../../../helpers/times';

type Props = {
	isOpen: boolean;
	allocationData: AllocationData | null;
	onClose: Function;
	permissionTo: Function;
	loadWeekEndings: Function;
};

const AllocationModal: React.FC<Props> = (props) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const [modifiedRows, setModifiedRows] = useState<Array<string>>([]);
	const [modalTitle, setModalTitle] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const gridRef: any = useRef<any>();
	const [gridLoading, setGridLoading] = useState<boolean>(false);
	const [gridData, setGridData] = useState<Array<any>>([]);
	const [isApproved, setIsApproved] = useState<boolean>(false);

	// Renderers
	const cellTimeRenderer = useCallback(
		(params: any) => {
			if (params.value !== EMPTY_DATA_STR && params.data.col_g === true) {
				if (
					isApproved === true ||
					params.data.type === AllocationRowType.BREAK ||
					params.data.type === AllocationRowType.BREAK_SUMMARY
				) {
					return (
						<>
							{params.value}
							{DURATION_UNITS_OBJ}
						</>
					);
				} else {
					return (
						<Datepicker
							controls={['time']}
							stepMinute={1}
							inputComponent={IonInput}
							inputProps={{
								className: 'ts-time-input',
								readOnly: true,
							}}
							timeFormat="HH:mm' hrs'"
							timeWheels='|HH|mm|'
							touchUi={false}
							defaultValue={params.value}
							returnFormat='iso8601'
							onClose={(e: any) => {
								handleTimeChange(
									true,
									params.data.day_id,
									params.data.allocation_id,
									{ label: e.value, value: e.value },
									params.data.col_g,
									params.data?.type ?? EMPTY_DATA_STR
								);
							}}
						/>
					);
				}
			}
			return EMPTY_DATA_STR;
		},
		[gridData, modifiedRows]
	);

	const cellTotalRenderer = useCallback(
		(params: any) => {
			let secondsToPayTotal: number = 0;

			// Get the correct modified row that contains all relecant duration strings
			const mRow: any = modifiedRows.filter((mRow: any) => mRow.day_id === params.data.day_id);

			// Reduce the modified row items array to total seconds
			if (Array.isArray(mRow) && mRow.length === 1) {
				secondsToPayTotal = mRow[0].allocations
					.filter((allocation: any) => {
						let allow: boolean = false;

						if (params.data.cellClass === 'sub-total-row') {
							if (
								allocation.rowType === EMPTY_DATA_STR ||
								allocation.rowType === AllocationRowType.BREAK
							) {
								if (
									(params.colDef.field === 'col_e' && allocation.included === false) ||
									(params.colDef.field === 'col_f' && allocation.included === true)
								) {
									allow = true;
								}
							}
						} else {
							if (allocation.included === true) {
								allow = true;
							}
						}

						if (allow) return allocation;
					})
					.reduce((acc: number, cur: any) => {
						if (String(cur.to_pay).charAt(0) === '-') {
							// Remove time
							return acc - Duration.fromISOTime(String(cur.to_pay).substring(1)).toMillis() / 1000;
						} else {
							// Add time
							return acc + Duration.fromISOTime(String(cur.to_pay)).toMillis() / 1000;
						}
					}, 0);

				if (secondsToPayTotal < 0) secondsToPayTotal = 0;
			}

			// Return formatted
			return (
				<>
					{Duration.fromObject({ seconds: secondsToPayTotal }).toFormat('hh:mm')}
					{DURATION_UNITS_OBJ}
				</>
			);
		},
		[modifiedRows]
	);

	const cellCostHeadRenderer = useCallback((params: any) => {
		if (
			// Skip if excluded
			params.data.col_g !== true ||
			// Skip if no start or end times
			(params.data.col_c === EMPTY_DATA_STR && params.data.col_d === EMPTY_DATA_STR) ||
			// Skip if break
			params.data.type === AllocationRowType.BREAK ||
			params.data.type === AllocationRowType.BREAK_SUMMARY
		) {
			return EMPTY_DATA_STR;
		}

		let label: string = params.value.cost_head;
		let choiceMade: boolean = true;

		if (!label || label === EMPTY_DATA_STR) {
			label = 'Choose a Cost Head';
			choiceMade = false;
		}

		return (
			<CostHeadControl
				label={label}
				choiceMade={choiceMade}
				dayId={params.data.day_id}
				allocationId={params.data.allocation_id}
				costHeadModalOpen={costHeadModalOpen}
			/>
		);
	}, []);

	const cellBehaviourRenderer = useCallback((params: any) => {
		return params.value === false ? (
			<FontAwesomeIcon icon={faWarning} className='icon-behaviour-bad' />
		) : (
			<FontAwesomeIcon icon={faCheckCircle} className='icon-behaviour-good' />
		);
	}, []);

	const cellToggleRenderer = useCallback(
		(params: any) => {
			return (
				<IonToggle
					onIonChange={handleToggle}
					mode='ios'
					checked={params.value === true}
					value={params.data.allocation_id}
				></IonToggle>
			);
		},
		[gridData, modifiedRows]
	);

	const cellExcludedRenderer = useCallback(
		(params: any) =>
			params.data.col_g === false ? (
				<>
					{params.value}
					{DURATION_UNITS_OBJ}
				</>
			) : (
				EMPTY_DATA_STR
			),
		[]
	);

	const gridColumns: Array<any> = [
		{
			field: 'col_a',
			headerName: '',
			flex: 1,
			sortable: false,
			cellClass: getCellClass,
			cellRenderer: renderCell,
			colSpan: getColSpan,
		},
		{
			field: 'col_b',
			headerName: '',
			flex: 1,
			sortable: false,
			minWidth: 250,
			maxWidth: 300,
			cellClass: getCellClass,
			cellRenderer: (params: any) => renderCell(params, { cellCostHeadRenderer }),
		},
		{
			field: 'col_c',
			headerName: '',
			flex: 1,
			sortable: false,
			minWidth: 70,
			maxWidth: 120,
			cellClass: getCellClass,
			cellRenderer: renderCell,
		},
		{
			field: 'col_d',
			headerName: '',
			flex: 1,
			sortable: false,
			minWidth: 70,
			maxWidth: 120,
			cellClass: getCellClass,
			cellRenderer: renderCell,
		},
		{
			field: 'col_e',
			headerName: '',
			flex: 1,
			sortable: false,
			minWidth: 70,
			maxWidth: 120,
			cellClass: getCellClass,
			cellRenderer: (params: any) =>
				renderCell(params, { cellBehaviourRenderer, cellExcludedRenderer, cellTotalRenderer }),
		},
		{
			field: 'col_f',
			headerName: '',
			flex: 1,
			sortable: false,
			minWidth: 70,
			maxWidth: 120,
			cellClass: getCellClass,
			cellRenderer: (params: any) => renderCell(params, { cellTimeRenderer, cellTotalRenderer }),
		},
		{
			field: 'col_g',
			headerName: '',
			flex: 1,
			sortable: false,
			minWidth: 50,
			maxWidth: 100,
			cellClass: getCellClass,
			cellRenderer: (params: any) => renderCell(params, { cellToggleRenderer }),
		},
	];

	// Modals
	const heatmapModalRef = useRef<any>(null);
	const routemapModalRef = useRef<any>(null);
	const locationsModalRef = useRef<any>(null);
	const costHeadModalRef = useRef<any>(null);

	useEffect(() => {
		setIsLoading(gridLoading);
	}, [gridLoading]);

	useEffect(() => {
		if (props.isOpen) {
			if (props.allocationData) {
				setModalTitle(
					`Timesheet allocation for ${props.allocationData.workerData.name} | ${
						props.allocationData.type === 'day'
							? props.allocationData.event.date.toFormat('dd/MM/yyyy')
							: `Week ending ${state.weekEnding.toFormat('dd/MM/yyyy')}`
					}`
				);
			}

			loadData();
		}
	}, [props.isOpen, props.allocationData]);

	const handleTimeChange = (
		updateState: boolean,
		dayId: string,
		allocationId: string,
		selectedOption: any,
		included: boolean,
		rowType: string,
		tmpModifiedRows?: Array<any> // Used for setting initial values without state changes
	): Array<any> => {
		// Update the grid data (using the rowId system to allow single row redraws)
		if (updateState === true) {
			setGridData((prevState: any) =>
				prevState.map((item: any) => {
					if (item.day_id === dayId && item.allocation_id === allocationId) {
						item.col_e = selectedOption.value;
						item.col_f = selectedOption.value;
					}
					return item;
				})
			);
		}

		// Keep track of changed rows to send to the endpoint
		let mRows: Array<any> = [];
		if (updateState === true) {
			mRows = cloneDeep(modifiedRows);
		} else {
			if (tmpModifiedRows) mRows = tmpModifiedRows;
		}

		// Look for the selected day
		let day: { day_id: string; allocations: Array<any> } = mRows.find(
			(item: any) => item.day_id === dayId
		);

		// Add missing day
		if (!day) day = { day_id: dayId, allocations: [] };

		// Replace the selected day allocation time
		let dayAllocations: Array<any> = day.allocations.filter(
			(item: any) => item._id !== allocationId
		);

		dayAllocations.push({
			_id: allocationId,
			to_pay: selectedOption.value,
			included,
			rowType: rowType,
		});
		day.allocations = [...dayAllocations];

		// Replace the overall allocation with the new object
		let tmp: Array<any> = mRows.filter((item: any) => item.day_id !== dayId);
		tmp.push({ day_id: dayId, allocations: dayAllocations });

		if (updateState === true) setModifiedRows([...tmp]);

		return tmp;
	};

	const handleToggle = (e: CustomEvent) => {
		const checked: boolean = e.detail.checked;
		const allocationId: string = e.detail.value;
		let row = gridData.filter((item: any) => item.allocation_id === allocationId)[0];

		// Update the row data
		row.col_g = checked;

		setGridData((prevState: any) => {
			return prevState.map((item: any) => {
				if (item.allocation_id === allocationId) item.col_g = checked;
				return item;
			});
		});

		// Update the modified rows
		setModifiedRows((days: any) => {
			return days.map((day: any) => {
				day.allocations.map((allocation: any) => {
					if (allocation._id === allocationId) {
						allocation.included = checked;
					}
					return allocation;
				});
				return day;
			});
		});
	};

	const loadData = () => {
		setGridLoading(true);
		setModifiedRows([]);

		let payload: any = {};

		switch (props.allocationData?.type) {
			case 'day':
				payload.day_id = props.allocationData.event.id;
				break;
			case 'week':
				payload.timesheet_id = props.allocationData?.workerData.timesheet_id;
				break;
		}

		axios
			.post('/api/timesheets/timesheets_and_allocations/allocations', payload)
			.then((res: any) => {
				let listing: Array<any> = [];
				let tmpModifiedRows: Array<any> = [];

				// Update modal title if necessary
				if (props.allocationData?.type === 'day') {
					if (res.data[0].approver !== null && res.data[0].signed_off !== null) {
						setIsApproved(true);
						setModalTitle(
							(prevState: any) =>
								prevState +
								` | Approved by ${res.data[0].approver} on ${DateTime.fromISO(
									res.data[0].signed_off
								).toFormat('dd/MM/yyyy')}`
						);
					} else {
						setIsApproved(false);
					}
				}

				// Build the rows of headers, sections, and data
				for (let i = 0; i < res.data.length; i++) {
					const day: any = res.data[i];
					const prevDay: any = res.data[i - 1];
					const expectedLen: any = Object.keys(day.expected).length;
					const tsLen: any = day.allocations.length;
					const behaviourLen: any = Object.keys(day.behaviour).length;

					// Skip day - future date
					if (DateTime.fromISO(day.date).startOf('day') >= DateTime.now().startOf('day')) continue;

					// Day spacer row
					if (prevDay) {
						const expectedLenPrev = Object.keys(prevDay.expected).length;
						const tsLenPrev = prevDay.allocations.length;
						const behaviourLenPrev: any = Object.keys(prevDay.behaviour).length;

						if (
							expectedLenPrev > 0 ||
							tsLenPrev > 0 ||
							behaviourLenPrev > 0 ||
							expectedLen > 0 ||
							tsLen > 0 ||
							behaviourLen > 0
						) {
							listing.push(spacerRow());
						}
					}

					// Block - expected
					if (expectedLen > 0) {
						listing.push(headerRow('expected-items', day));
						listing.push(subHeaderRow('alpha', 'Expected Timesheet'));

						Object.keys(day.expected).forEach((key: any) => {
							listing.push(dataRow('expected', day.id, day.expected[key]));
						});
					}

					// Block - timesheet and behaviour items
					if (tsLen > 0 || behaviourLen > 0) {
						listing.push(headerRow('timesheet', day));

						// Calculate the hours to pay for each timesheet row
						const ts: any = day.allocations.map((item: any) => {
							let hoursToPay: any = item.to_pay;
							if (!hoursToPay || hoursToPay === undefined) {
								const secondsToPay = item.recorded >= item.expected ? item.expected : item.recorded;
								hoursToPay = convertSecondsToDurationString(secondsToPay);
							}

							// Update the timesheet array
							item.to_pay = hoursToPay;

							// Pre-fill the modified rows with initial values
							tmpModifiedRows = handleTimeChange(
								false,
								day.id,
								item.id, // allocationId
								{ value: item.to_pay }, // simulate a selected dropdown option,
								item.included,
								item?.type ?? EMPTY_DATA_STR,
								tmpModifiedRows
							);

							return item;
						});

						if (tsLen > 0) {
							listing.push(subHeaderRow('beta', 'Timesheet Payment and Cost Heads'));

							ts.map((item: any, index: number) => {
								listing.push(dataRow('ts', day.id, item, index === tsLen - 2, index === tsLen - 1));

								if (index === tsLen - 2) listing.push(subTotalRow(day.id));
							});

							// Total
							listing.push(totalRow(day.id));
						}

						if (behaviourLen > 0) {
							listing.push(headerRow('behaviour', day));
							listing.push(subHeaderRow('beta', 'Behavioural Issues'));

							day.behaviour.forEach((item: any) => {
								listing.push(dataRow('behaviour', day.id, item));
							});
						}
					}
				}

				// Give each row a unique ID to enable grid data changes without a full redraw
				listing = listing.map((item: any, rowId: number) => ({ ...item, rowId }));

				// Initial setting of modified rows - all
				if (tmpModifiedRows) setModifiedRows(() => tmpModifiedRows);

				setGridData(listing);
			})
			.catch(() => showToast('error'))
			.finally(() => setGridLoading(false));
	};

	const handleSignOff = () => {
		if (!props.permissionTo('update')) {
			showToast('permission');
			return;
		}

		setIsLoading(true);

		let payload: any = cloneDeep(modifiedRows);

		// Add cost heads to payload
		payload.map((day: any) => {
			day.allocations.map((allocation: any) => {
				let allocationData: any = gridData.filter(
					(row: any) => row.allocation_id === allocation._id
				);
				allocation.cost_head_id = allocationData[0]?.col_b?.cost_head_id;
				return allocation;
			});

			return day;
		});

		axios
			.put('/api/timesheets/timesheets_and_allocations/allocations', payload)
			.then(() => {
				showToast('saved');
				props.loadWeekEndings();

				// Close modal and reload calendar in parent
				onClose(true);
			})
			.catch(() => showToast('error'))
			.finally(() => setIsLoading(false));
	};

	const onClose = (reloadCal?: boolean) => {
		// Reset
		setModifiedRows([]);
		setModalTitle('');
		setGridData([]);
		setIsLoading(true);
		setGridLoading(false);
		setIsApproved(false);

		props.onClose(reloadCal);
	};

	// Modal functions
	const heatmapModalOpen = () => {
		if (heatmapModalRef.current) {
			heatmapModalRef.current.isOpen = true;

			heatmapModalRef.current.allocationData = {
				workerId: props.allocationData?.workerData.id,
				workerName: props.allocationData?.workerData.name,
				usageMode:
					props.allocationData?.type === 'day'
						? HeatmapsUsageMode.TIMESHEET_DAILY
						: HeatmapsUsageMode.TIMESHEET_WEEKLY,
			};

			switch (props.allocationData?.type) {
				case 'day':
					heatmapModalRef.current.allocationData.startDate =
						props.allocationData?.event.date.startOf('day');
					heatmapModalRef.current.allocationData.endDate =
						props.allocationData?.event.date.endOf('day');
					break;
				case 'week':
					heatmapModalRef.current.allocationData.startDate = state.weekEnding.startOf('week');
					heatmapModalRef.current.allocationData.endDate = state.weekEnding;
					break;
			}
		}
	};

	const heatmapModalOnClose = () => {
		if (heatmapModalRef.current) heatmapModalRef.current.isOpen = false;
	};

	const routemapModalOpen = () => {
		if (routemapModalRef.current) {
			routemapModalRef.current.isOpen = true;

			routemapModalRef.current.allocationData = {
				workerId: props.allocationData?.workerData.id,
				workerName: props.allocationData?.workerData.name,
				selectedDate: props.allocationData?.event.date.startOf('day'),
			};
		}
	};

	const routemapModalOnClose = () => {
		if (routemapModalRef.current) routemapModalRef.current.isOpen = false;
	};

	const locationsModalOpen = () => {
		if (locationsModalRef.current) {
			locationsModalRef.current.isOpen = true;

			locationsModalRef.current.allocationData = {
				workerId: props.allocationData?.workerData.id,
				workerName: props.allocationData?.workerData.name,
				selectedDate: props.allocationData?.event.date.startOf('day'),
			};
		}
	};

	const locationsModalOnClose = () => {
		if (locationsModalRef.current) locationsModalRef.current.isOpen = false;
	};

	const costHeadModalOpen = (dayId: string, allocationId: string) => {
		if (costHeadModalRef.current) {
			costHeadModalRef.current.isOpen = true;

			costHeadModalRef.current.modalData = { dayId, allocationId };
		}
	};

	const costHeadModalOnClose = () => {
		if (costHeadModalRef.current) costHeadModalRef.current.isOpen = false;
	};

	const setAllocationCostHead = (allocationId: string, costHeadId: string, costHead: string) => {
		// Update the grid data
		setGridData((prevState: any) => {
			return prevState.map((row: any) => {
				if (row.allocation_id === allocationId) {
					// Set the allocation row cost head information
					row.col_b = { cost_head_id: costHeadId, cost_head: costHead };
				}
				return row;
			});
		});
	};

	return (
		<>
			<IonModal
				class='timesheets-allocation-modal'
				style={{ '--width': '90vw', '--height': '90vh' }}
				isOpen={props.isOpen}
				onWillDismiss={() => onClose()}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>{modalTitle}</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton onClick={() => onClose()}>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{isLoading && <Loading overlay={true} />}
					{props.isOpen && (
						<DataGrid
							className='grid-theme-alpha'
							rowBuffer={props.allocationData?.type === 'week' ? 100 : 0}
							ref={gridRef}
							cols={gridColumns}
							data={gridData}
							title=''
							useSearch={false}
							compact={true}
							rowIdName='rowId'
						/>
					)}
				</IonContent>
				<IonFooter>
					<IonToolbar>
						<IonRow>
							<IonCol size='12' className='text-right'>
								{props.allocationData?.type === 'day' && (
									<>
										<IonButton
											color='theme-beta-quaternary-dark'
											onClick={locationsModalOpen}
											disabled={gridLoading}
										>
											Locations
										</IonButton>
										<IonButton
											color='theme-beta-quaternary-dark'
											onClick={routemapModalOpen}
											disabled={gridLoading}
										>
											Route Map
										</IonButton>
									</>
								)}
								<IonButton
									color='theme-beta-quaternary-dark'
									onClick={heatmapModalOpen}
									disabled={gridLoading}
								>
									Heatmap
								</IonButton>
								<IonButton
									color='success'
									onClick={handleSignOff}
									disabled={isApproved || gridLoading}
								>
									Sign Off
								</IonButton>
								<IonButton color='secondary' onClick={() => onClose()} disabled={gridLoading}>
									Close
								</IonButton>
							</IonCol>
						</IonRow>
					</IonToolbar>
				</IonFooter>
			</IonModal>

			{props.isOpen && (
				<>
					<HeatmapModal ref={heatmapModalRef} onClose={heatmapModalOnClose} />

					<RoutemapModal ref={routemapModalRef} onClose={routemapModalOnClose} />

					<LocationsModal ref={locationsModalRef} onClose={locationsModalOnClose} />

					<CostHeadModal
						ref={costHeadModalRef}
						onClose={costHeadModalOnClose}
						setAllocationCostHead={setAllocationCostHead}
					/>
				</>
			)}
		</>
	);
};

export default AllocationModal;

import { IonRow, IonCol, IonLabel } from '@ionic/react';
import { useContext, useState, useRef, useEffect } from 'react';

import SelectStyled from '../../../../components/UI/SelectStyled';
import { moduleContext } from '../../../../contexts/ModuleContext';
import RadioButtons from '../../../../components/UI/RadioButtons/RadioButtons';
import { JobStatus } from '../job-types';

interface SFProps {
	defaultSelected: string;
	onChange?: Function;
}

const ListFilter: React.FC<SFProps> = ({ defaultSelected, onChange }) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [jobTypes, setJobTypes] = useState<any>();
	const filterByRef = useRef<any>();
	const [btnSel, setBtnSel] = useState<Array<any>>([defaultSelected]);
	const statusFilterItems: Array<any> = [
		{
			label: 'In Progress',
			value: JobStatus.IN_PROGRESS,
		},
		{
			label: 'Suspended',
			value: JobStatus.SUSPENDED,
		},
		{
			label: 'Completed',
			value: JobStatus.COMPLETED,
		},
		{
			label: 'All',
			value: JobStatus.ALL,
		},
	];

	useEffect(() => {
		setBtnSel([defaultSelected]);
	}, [defaultSelected]);

	useEffect(() => {
		moduleCtx.getJobTypeOptions().then((res: any) => {
			setJobTypes(res);
		});
	}, []);

	const updateSelection = (e: any) => {
		let payload: any = {};
		setBtnSel(e);

		// Get the currently selected filter-by
		let jobTypeId: any = '';
		if (filterByRef.current) {
			jobTypeId = filterByRef.current.getValue();
			if (jobTypeId.length > 0) payload.job_type_id = jobTypeId[0].value;
		}

		if (onChange) {
			const id = e[0];
			switch (id) {
				case JobStatus.IN_PROGRESS:
				case JobStatus.SUSPENDED:
				case JobStatus.COMPLETED:
					payload.status = id;
					break;
				case JobStatus.ALL:
					break;
			}

			let item = statusFilterItems.find((item: any) => {
				return item.value === e[0];
			});

			onChange(item.label, payload);
		}
	};

	const handleFilterByChange = (e: any) => {
		let jobTypeId: string = '';
		let payload: any = {};
		if (e && e.value) {
			jobTypeId = e.value;
			payload.job_type_id = jobTypeId;
		}

		// Get the currently selected status
		let item = statusFilterItems.find((item: any) => {
			return (item.value = btnSel[0]);
		});

		if (onChange) {
			if (item.value) payload.status = item.value;
			onChange(item.label, payload);
		}
	};

	return (
		<IonRow>
			<IonCol size='6' className='ps-0'>
				<RadioButtons options={statusFilterItems} onChange={updateSelection} value={btnSel} />
			</IonCol>
			<IonCol size='6' className='filter-by'>
				<IonRow>
					<IonCol size='3' className='ps-0 text-right'>
						<IonLabel className='lbl-filter-by'>Filter By</IonLabel>
					</IonCol>
					<IonCol size='9' className='ps-0'>
						<SelectStyled
							options={jobTypes}
							onChange={handleFilterByChange}
							forwardRef={filterByRef}
							isClearable={true}
						/>
					</IonCol>
				</IonRow>
			</IonCol>
		</IonRow>
	);
};

export default ListFilter;

import { useContext } from 'react';
import MediaIndex from '../../../components/Media/MediaIndex';
import EstimatesIndex from './Estimates/EstimatesIndex';
import JobTabsIndex from './JobTabs/JobTabsIndex';
import NotesIndex from './Notes/NotesIndex';
import JobsContext from '../../../contexts/JobsContext';
import MapIndex from './Map/MapIndex';
import JobTeamIndex from './JobTeam/JobTeamIndex';
import InvoicesIndex from './Invoices/InvoicesIndex';
import KPIIndex from './KPI/KPIIndex';
import DiaryIndex from './Diary/DiaryIndex';
import MessagesIndex from './Messages/MessagesIndex';
import WorkerTimesheetsIndex from './WorkerTimesheets/WorkerTimesheetsIndex';

// TODO: TEMPORARY JOB TABS STATUSES TO REFLECT DEVELOPMENT PROGRESS
const EditJobSubmenuArray: any = (permissionTo: Function, jobMessagesNotRead: number) => {
	const { jobData } = useContext(JobsContext);

	return [
		{
			id: 'submenu-jobtabs',
			uid: 'jobs.jobs_list.job_card.job_tabs',
			title: 'Job Tabs',
			hash: 'job-tabs',
			page: JobTabsIndex,
			active: true,
			fullHeight: false,
			pageData: { permissionTo },
		},
		{
			id: 'submenu-jobdiary',
			uid: 'jobs.jobs_list.job_card.diary',
			title: 'Diary',
			page: DiaryIndex,
			hash: 'diary',
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-jobestimates',
			uid: 'jobs.jobs_list.job_card.estimates',
			title: 'Estimates',
			hash: 'estimates',
			page: EstimatesIndex,
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-jobinvoices',
			uid: 'jobs.jobs_list.job_card.invoices',
			title: 'Invoices',
			page: InvoicesIndex,
			hash: 'invoices',
			active: false,
			fullHeight: true,
		},
		{
			id: 'submenu-jobteam',
			uid: 'jobs.jobs_list.job_card.team',
			title: 'Job Team',
			hash: 'team',
			page: JobTeamIndex,
			active: false,
			fullHeight: true,

			pageData: { permissionTo },
		},
		{
			id: 'submenu-jobkpi',
			uid: 'jobs.jobs_list.job_card.kpi',
			title: 'KPI',
			page: KPIIndex,
			hash: 'kpi',
			active: false,
			fullHeight: true,

			pageData: { permissionTo },
		},
		{
			id: 'submenu-jobmap',
			uid: 'jobs.jobs_list.job_card.map',
			title: 'Map',
			hash: 'map',
			page: MapIndex,
			active: false,
			fullHeight: true,

			pageData: { permissionTo },
		},
		{
			id: 'submenu-jobphotos',
			uid: 'jobs.jobs_list.job_card.photos',
			title: 'Media',
			hash: 'media',
			page: MediaIndex,
			active: false,
			fullHeight: false,

			pageData: { mediaCategory: 'job', mediaData: jobData },
		},
		{
			id: 'submenu-jobmessages',
			uid: 'jobs.jobs_list.job_card.messages',
			title: 'Messages',
			page: MessagesIndex,
			hash: 'messages',
			active: false,
			fullHeight: true,
			badgeNumber: jobMessagesNotRead,
		},
		{
			id: 'submenu-jobnotes',
			uid: 'jobs.jobs_list.job_card.notes',
			title: 'Notes',
			hash: 'notes',
			page: NotesIndex,
			active: false,
			fullHeight: true,
			pageData: { permissionTo },
		},
		{
			id: 'submenu-jobworkertimesheets',
			uid: 'jobs.jobs_list.job_card.time_sheets',
			title: 'Worker Timesheets',
			page: WorkerTimesheetsIndex,
			hash: 'time-sheets',
			active: false,
			fullHeight: true,
		},
	];
};

export default EditJobSubmenuArray;

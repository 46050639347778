import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	IonContent,
} from '@ionic/react';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import ItemCatalogueIndex from '../../../Utilities/Jobs/ItemCatalogue/ItemCatalogueIndex';

interface CatalogueModalProps {
	isOpen: boolean;
	onClose: Function;
	onSuccess: Function;
}

const CatalogueModal: React.FC<CatalogueModalProps> = ({ isOpen, onClose, onSuccess }) => {
	const uid = 'utilities.item_catalogue';

	return (
		<IonModal
			style={{ '--width': '80vw', '--height': '540px' }}
			isOpen={isOpen}
			onDidDismiss={() => onClose()}
			className='prop-form catalogue-items-modal'
		>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Item Catalogue</IonTitle>
					<IonButtons slot='end' className='ion-modal-buttons'>
						<IonButton onClick={() => onClose()}>
							<FontAwesomeIcon icon={faTimes} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className='tight-margins-modal'>
				<ItemCatalogueIndex
					uid={uid}
					permissionTo={usePermissionTo(uid)}
					routeTitle=''
					usageMode='modal'
					usageModeCompletion={onSuccess}
				/>
			</IonContent>
		</IonModal>
	);
};

export default CatalogueModal;

import { DateTime, Duration } from 'luxon';

export function getTimesOptions() {
	let timesOptions: Array<any> = [];
	let startTime = DateTime.fromFormat('00:00', 'HH:mm');
	let endTime = DateTime.fromFormat('00:00', 'HH:mm').plus({ hours: 24 });

	while (startTime.toSeconds() <= endTime.minus({ minutes: 15 }).toSeconds()) {
		timesOptions.push({
			label: startTime.toFormat('HH:mm'),
			value: startTime.toFormat('HH:mm'),
		});
		startTime = startTime.plus({ minutes: 15 });
	}

	return timesOptions;
}

export function getBreaktimeOptions() {
	let breaktimeOptions: Array<any> = [];
	let startTime = DateTime.fromFormat('00:00', 'HH:mm');
	let endTime = DateTime.fromFormat('00:00', 'HH:mm').plus({ hours: 3 });
	while (startTime.toSeconds() <= endTime.toSeconds()) {
		breaktimeOptions.push({
			label: startTime.toFormat('H:mm') + ' hr',
			value: startTime.toFormat('H:mm'),
		});
		startTime = startTime.plus({ minutes: 15 });
	}

	return breaktimeOptions;
}

export const convertSecondsToDurationString = (seconds: number, formatStr: string = 'hh:mm') => {
	let duration = '';
	let negativeIndicator = '';

	if (seconds < 0) {
		negativeIndicator = '-';
		seconds = seconds * -1;
	}

	duration = negativeIndicator + Duration.fromObject({ seconds }).toFormat(formatStr);

	return duration;
};

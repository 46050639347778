import { useEffect, useState, useContext } from 'react';
import { IonButton, IonRow, IonCol, IonInput, IonCheckbox } from '@ionic/react';
import { ColorInput } from '../../../../components/Forms/FormFields';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

var item_group: string = '';
var path: Array<string> = [];
var uid: Array<string> = [];
function flat(array: Array<any>, level: number): Array<any> {
	var result: any = [];

	array.forEach((a: any) => {
		// Check if at lowest level
		if (a.hasOwnProperty('module_name')) {
			path = [a.security_key];
			uid = [a.security_key];
			level = 0;
		}

		if (a.hasOwnProperty('item_group')) {
			// If the group changes then replace in the path
			if (a.item_group !== item_group) {
				if (path.length > 1) {
					path.pop();
				}

				path.push(a.item_group);
			}

			// Keep track of the group
			item_group = a.item_group;
		}

		// Build the path and UID
		if (level > 0) {
			path.push(a.security_key);
			uid.push(a.security_key);
		}

		// Increment the level
		level = level + 1;

		result.push({
			_id: a._id,
			title: (a.module_name ? a.module_name : a.item_name),
			pathname: "/" + path.join("/").toLowerCase(),
			uid: uid.join(".").toLowerCase(),
			security_key: a.security_key,
			item_group: item_group.toLowerCase(),
			isModule: a.hasOwnProperty('module_name'),
			level: level,
		});

		// Deeper level check
		if (Array.isArray(a.items)) {
			result = result.concat(flat(a.items, level));
		}

		// Pop the path and UID each time we come down a level
		level = level - 1;
		path.pop();
		uid.pop();
	});

	return result;
}

interface Props {
	mode: string;
	data: any;
	permissionsArray: Array<any>;
	roleID?: string;
	workerID?: string;
	moduleID: string;
	permissionTo: Function;
	hideOverlay: Function;
	securitySettingName?: string;
	securitySettingColour?: string;
	handleUpdate: Function;
	setOverlayFooterContent: Function;
}

const SSTab: React.FC<Props> = ({
	mode,
	data,
	permissionsArray,
	roleID,
	workerID,
	moduleID,
	permissionTo,
	hideOverlay,
	securitySettingName,
	securitySettingColour,
	handleUpdate,
	setOverlayFooterContent
}) => {
	const moduleCtx = useContext<any>(moduleContext);
	const [checkedArray, setCheckedArray] = useState<Array<any>>([]);
	const [securitySettingNameValue, setSecuritySettingNameValue] = useState<string>(`${securitySettingName}`);
	const [securitySettingColourValue, setSecuritySettingColourValue] = useState<string>(`#${securitySettingColour}`);

	// Flatten this module's navigation data
	const navData = flat(data.filter((d: any) => d._id === moduleID), 0);

	// Pre-fill with permissions data
	useEffect(() => {
		let permArr: any = permissionsArray;
		permArr = (permArr && permArr.permissions) ? permArr.permissions : [];
		let preCheck: Array<any> = [];

		if (permArr.length > 0) {
			for (let i in data) {
				// Flatten the current iteration of module data
				let dataFlat: Array<any> = flat([data[i]], 0);

				for (let j in dataFlat) {
					let df = dataFlat[j];

					// Match permissions against flattened module data
					for (let k in permArr) {
						let pa = permArr[k];
						let uid = pa.permission.split(' ')[0];
						let action = pa.permission.split(' ')[1];

						// Pre-check the boxes if the permissions match
						if (df.uid === uid && pa.checked === true) {
							preCheck.push({ id: `box-${action}-${df._id}`, value: pa.permission });
						}
					}
				}
			}
		}

		setCheckedArray(preCheck);
	}, []);

	// Update the overlay on each render cycle
	useEffect(() => {
		switch (mode) {
			case 'template':
			case 'template-new':
				setOverlayFooterContent({
					content: (
						<>
							<div className="footer-controls prop-form">
								<label>Security Setting Name:</label>
								<IonInput type="text" className="text-left" onBlur={handleSecurityNameChange} value={securitySettingNameValue} placeholder="Please enter a security setting name" />
								<label>Security Setting Colour:</label>
								<ColorInput id="security-setting-colour" onChange={handleSecuritySettingColourChange} value={`${securitySettingColourValue}`} />
								<div style={{ width: '30%' }} />
								<IonButton color="tertiary" onClick={() => hideOverlay()}>Close</IonButton>
								{permissionTo('update') && (
									<IonButton
										color={mode === 'template-new' ? 'success' : 'primary'}
										onClick={handleSaveChanges}>
										{mode === 'template-new' ? 'Create Template' : 'Save Changes'}
									</IonButton>
								)}
							</div>
						</>
					)
				});
				break;
			case 'worker':
				setOverlayFooterContent({
					content: (
						<>
							<div className="footer-controls justify-right">
								<IonButton color="tertiary" onClick={() => hideOverlay()}>Close</IonButton>
								{permissionTo('update') && (
									<IonButton color="primary" onClick={handleSaveChanges}>Save Changes</IonButton>
								)}
							</div>
						</>
					)
				});
				break;
			default:
				break;
		}
	});

	const handleBoxOnChange = (e: any) => {
		const isChecked = e.target.checked;
		const value = e.target.value;
		const id = e.target.id;
		const changeTo = { id: id, value: value }
		let boxWasChecked = false;

		// Add checked boxes - remove if un-checked
		if (!checkedArray.some((item: any) => item.id === id)) {
			// Add new value
			if (isChecked === true) {
				setCheckedArray([...checkedArray, changeTo]);
				boxWasChecked = true;
			}
		} else {
			// Update value
			if (isChecked === true) {
				setCheckedArray((prevState: any) => {
					return prevState.map((item: any) => {
						if (item.id === id) return changeTo;
						return item;
					});
				});
				boxWasChecked = true;
			} else {
				// Skip (remove) item if un-checked
				setCheckedArray((prevState: any) => {
					return prevState.filter((item: any) => item.id !== id);
				});
			}
		}

		return boxWasChecked;
	}

	const crudRows: any = navData.map((d: any, i: number) => {
		return (
			<CrudRow
				key={i}
				id={d._id}
				uid={d.uid}
				title={d.title}
				level={d.level}
				isModule={d.isModule}
				onChange={handleBoxOnChange}
				checkedArray={checkedArray}
			/>
		);
	});

	const handleSecurityNameChange = (e: any) => {
		setSecuritySettingNameValue(e.target.value);
	}

	const handleSecuritySettingColourChange = (e: any) => {
		setSecuritySettingColourValue(e.target.value);
	}

	const handleSaveChanges = () => {
		if (!securitySettingNameValue || !securitySettingColourValue) {
			showToast("error", "Please provide a name and colour");
			return;
		}

		const toastID = toast.loading("Please wait...");

		// Parse the permissions
		let permArr: any = permissionsArray;
		permArr = (permArr && permArr.permissions) ? permArr.permissions : [];
		let permissions: Array<any> = [];

		switch (mode) {
			case 'template':
			case 'worker':
				for (let i in checkedArray) {
					let ca = checkedArray[i];
					let pe = permArr.filter((p: any) => p.permission === ca.value)[0];

					if (pe && pe.permission_id) {
						permissions.push(pe.permission_id);
					}
				}
				break;
			case 'template-new':
			default:
				for (let i in checkedArray) {
					permissions.push(checkedArray[i].value);
				}
				break;
		}

		// Settings based on mode
		let url = '/api/utilities/security_settings';
		let forRoleID: any = '';
		switch (mode) {
			case 'template':
				forRoleID = roleID;
				url = `${url}/permissions/${forRoleID}`;
				break;
			case 'worker':
				forRoleID = Object(permissionsArray)._id;
				url = `${url}/permissions/${forRoleID}`;
				break;
			case 'template-new':
			default:
				break;
		}

		axios.put(
			url,
			{
				permissions: permissions,
				security_setting_name: securitySettingNameValue,
				security_setting_colour: securitySettingColourValue,
			}
		).then((res) => {
			showToast("saved", null, toastID);

			// Update the grid data
			switch (mode) {
				case 'template':
					handleUpdate({
						securitySettingName: securitySettingNameValue,
						securitySettingColour: securitySettingColourValue
					}, forRoleID);
					break;
				case 'template-new':
					handleUpdate({
						securitySettingRoleID: res.data._id,
						securitySettingName: securitySettingNameValue,
						securitySettingColour: securitySettingColourValue
					});

					hideOverlay();
					break;
				case 'worker':
					moduleCtx.getSecuritySettingsTemplatesOptions(workerID).then((res: any) => {
						handleUpdate({ value: forRoleID }, workerID, res);
					});
					break;
			}
		}).catch((err) => {
			showToast("error", null, toastID);
		});
	}

	return (
		<IonRow>
			<IonCol size="12">
				<div className="security-crud-rows-container">
					{crudRows}
				</div>
			</IonCol>
		</IonRow>
	);
}

interface CrudRowProps {
	id: string;
	uid: string;
	title: string;
	level: number;
	isModule: boolean;
	onChange: Function;
	checkedArray: Array<any>;
}

const CrudRow: React.FC<CrudRowProps> = ({
	id,
	uid,
	title,
	level,
	isModule,
	onChange,
	checkedArray,
}) => {
	const actionsArray = !isModule ? ['create', 'read', 'update', 'delete'] : ['read'];
	const securityTabClasses = `security-tab${isModule ? ' is-module' : ''}`;

	return (
		<div className={securityTabClasses}>
			<div className="security-tab--title" style={{ paddingLeft: `${(level - 1) * 20}px` }}>{title}</div>
			{id && (
				<div className="security-tab--controls">
					{actionsArray.map((a: any, i: number) => {
						const htmlID = `box-${a}-${id}`;
						const box: any = checkedArray.filter((a: any) => a.id === htmlID);
						const isChecked = (box.length === 1);
						const value = `${uid} ${a}`;

						return (
							<div key={i} className="security-tab--controls--container text-center">
								<label htmlFor={htmlID} style={{ display: !isModule ? 'block' : 'none' }}>
									<div>{a}</div>
									<div className="security-tab--control--checkbox">
										<IonCheckbox checked={isChecked} onClick={(e) => onChange(e)} id={htmlID} value={value} />
									</div>
								</label>
								<div style={{ display: (isModule && isChecked) ? 'block' : 'none' }} className="module-icon">
									<FontAwesomeIcon icon={faCircleCheck} />
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default SSTab;

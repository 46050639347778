import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { moduleContext } from '../../../../contexts/ModuleContext';
import useNextOfKinArray from '../arrays/nextOfKinArray';
import axios from '../../../../lib/axios';
import Form from '../../../../components/Forms/Form';
import { db2Form } from '../../../../api/forms';
import Loading from '../../../../components/UI/Loading';
import { IonRow, IonCol } from '@ionic/react';
import WorkerControls from '../WorkerControls';

interface Props {
	nextFunction?: Function;
	prevFunction?: Function;
	permissionTo: Function;
}

const NextOfKin: React.FC<Props> = (props: Props) => {
	const tabId = 'next_of_kin';
	const moduleCtx = useContext<any>(moduleContext);
	const [items, setItems] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [relationshipsLoading, setRelationshipsLoading] = useState<boolean>(true);
	const [relationships, setRelationships] = useState<any>({});
	const { workerId }: any = useParams();
	const endpoint = '/api/workers/workers_list/worker_card';
	const endpointId = workerId;
	const formDataArray = useNextOfKinArray();

	useEffect(() => {
		loadRelationships();
	}, []);

	useEffect(() => {
		if (relationshipsLoading === false) {
			loadForm();
		}
	}, [relationshipsLoading]);

	const loadRelationships = () => {
		setRelationshipsLoading(true);

		moduleCtx
			.getKinRelationshipOptions()
			.then((res: any) => {
				setRelationships(res);
			})
			.finally(() => {
				setRelationshipsLoading(false);
			});
	};

	const loadForm = () => {
		let formData: any = formDataArray.nextOfKin;

		// Form setup
		formData.map((fd: any) => {
			if (fd.id === 'relationship') fd.values = relationships;
			return fd;
		});

		setIsLoading(true);
		axios
			.get(`${endpoint}/${endpointId}`)
			.then((res) => res.data)
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<>
			{isLoading && <Loading height={600} />}
			{!isLoading && items && items.length > 0 && (
				<>
					<IonRow className='mb-4'>
						<IonCol size={'12'}>
							<Form
								method='POST'
								array={items}
								permissionTo={props.permissionTo}
								forceEdit={props.permissionTo('update')}
								noButton={true}
								endpoint={endpoint}
								endpointID={endpointId}
							/>
						</IonCol>
					</IonRow>

					<WorkerControls tabId={tabId} workerId={workerId} setIsLoading={setIsLoading} />
				</>
			)}
		</>
	);
};

export default NextOfKin;

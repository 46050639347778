import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCheckbox,
	IonLabel,
} from '@ionic/react';
import { MouseEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Loading from '../../../components/UI/Loading';
import axios from '../../../lib/axios';
import { DateTime } from 'luxon';
import { showToast } from '../../../lib/toast';
import { TimesheetsContext } from '../TimesheetsProvider';
import { AllocationModalProps, PaymentMethod } from '../timesheets-types';
import AllocationModal from './modals/AllocationModal';
import { getYearSelectOptions } from '../../../lib/functions';
import { moduleContext } from '../../../contexts/ModuleContext';
import {
	Eventcalendar,
	MbscCalendarEventData,
	MbscEventClickEvent,
	MbscPageChangeEvent,
	MbscResource,
} from '@mobiscroll/react';
import Avatar from '../../../components/Calendars/Avatar';
import TimesheetHeader from './components/TimesheetHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faTriangleExclamation,
	faCalendarDays,
	faClock,
	faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { authContext } from '../../../contexts/AuthContext';
import WeekendingLabel from './components/WeekendingLabel';
import { dateFormatterFullOrdinal } from '../../../helpers/dateFormatter';

type Props = {
	pageId: string;
	permissionTo: Function;
};

const DailyHourlyTimesheets = (props: Props) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const prevShowMyTeam: any = useRef(state.showMyTeam);
	const DEFAULT_DATE_FORMAT: string = 'yyyy-MM-dd';
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [yearChange, setYearChange] = useState<boolean>(false);
	const [weekEndingsLoading, setWeekEndingsLoading] = useState<boolean>(true);
	const [weekEndingOptions, setWeekEndingOptions] = useState<Array<any>>([]);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [workerLoading, setWorkerLoading] = useState<boolean>(true);
	const [worker, setWorker] = useState<any>({});
	const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
	const [calLoading, setCalLoading] = useState<boolean>(true);
	const [calEvents, setCalEvents] = useState<Array<any>>([]);
	const [colours, setColours] = useState<Array<any>>([]);
	const [approvedBoxes, setApprovedBoxes] = useState<Array<any>>([]);

	// Modals
	const [allocationModal, setAllocationModal] = useState<AllocationModalProps>({
		isOpen: false,
		allocationData: null,
	});

	useEffect(() => {
		setIsLoading(workerLoading || weekEndingsLoading || yearChange || calLoading);
	}, [workerLoading, weekEndingsLoading, yearChange, calLoading]);

	useEffect(() => {
		loadWorker(authCtx.user.worker_id);
		loadWeekEndings();
	}, []);

	useEffect(() => {
		if (state.showMyTeam !== prevShowMyTeam.current) {
			loadCal();
		}
		prevShowMyTeam.current = state.showMyTeam;
	}, [state.showMyTeam]);

	const loadWorker = (workerId: string) => {
		setWorkerLoading(true);

		moduleCtx
			.getWorker(workerId)
			.then((res: any) => {
				setWorker(res);
			})
			.finally(() => {
				setWorkerLoading(false);
			});
	};

	const loadWeekEndings = (theYear?: number) => {
		setWeekEndingsLoading(true);

		axios
			.post('/api/timesheets/timesheets_and_allocations/weekending', {
				year: theYear ? theYear : state.year,
			})
			.then((res: any) => {
				setWeekEndingOptions(
					res.data.map((item: any) => ({
						label: <WeekendingLabel weekending={item.weekending} approved={item.approved} />,
						value: item.weekending,
					}))
				);
			})
			.catch(() => showToast('error'))
			.finally(() => setWeekEndingsLoading(false));
	};

	const loadCal = () => {
		setCalLoading(true);
		setApprovedBoxes([]);
		setCalEvents([]);
		setColours([]);

		let payload: any = {
			week_ending: state.weekEnding.toFormat(DEFAULT_DATE_FORMAT),
			payment_method: [PaymentMethod.DAILY, PaymentMethod.HOURLY],
			my_team: state.showMyTeam,
		};

		let tmpWorkers: Array<any> = [];
		let tmpCalEvents: Array<any> = [];

		axios
			.post('/api/timesheets/timesheets_and_allocations', payload)
			.then((res: any) => {
				tmpWorkers = res.data.resources.map((resource: any) => ({
					id: resource.id,
					name: resource.name,
					job_title: resource.job_title,
					worker_photo: resource.worker_photo,
					type_of_engagement: resource.type_of_engagement,
					type_of_engagement_enum: resource.type_of_engagement_enum,
					approved: resource.approved,
					eventCreation: false,
					timesheet_id: resource.timesheet_id,
				}));
				setWorkers(tmpWorkers);

				tmpCalEvents = res.data.events
					.filter(
						(event: any) =>
							DateTime.fromISO(event.date).endOf('day') <=
							DateTime.now().minus({ day: 1 }).endOf('day')
					)
					.map((event: any) => ({
						id: event.id,
						resource: event.resource,
						start: DateTime.fromISO(event.date).startOf('day').toJSDate(),
						end: DateTime.fromISO(event.date).endOf('day').toJSDate(),
						hasExceptions: event.has_exceptions,
						approved: event.approved,
					}));
				setCalEvents(tmpCalEvents);

				// Calendar background colours and icon classes
				let calendarColours: Array<any> = [];
				tmpWorkers.forEach((worker: any) => {
					const weekDaysBad: Array<string> = tmpCalEvents
						.filter((ev: any) => ev.resource === worker.id && ev.hasExceptions && !ev.approved)
						.map((ev: any) =>
							DateTime.fromJSDate(ev.start).toFormat('ccc').substring(0, 2).toUpperCase()
						);

					const weekDaysGood: Array<string> = tmpCalEvents
						.filter((ev: any) => ev.resource === worker.id && !ev.hasExceptions)
						.map((ev: any) =>
							DateTime.fromJSDate(ev.start).toFormat('ccc').substring(0, 2).toUpperCase()
						);

					const weekDaysMadeGood: Array<string> = tmpCalEvents
						.filter((ev: any) => ev.resource === worker.id && ev.hasExceptions && ev.approved)
						.map((ev: any) =>
							DateTime.fromJSDate(ev.start).toFormat('ccc').substring(0, 2).toUpperCase()
						);

					if (weekDaysBad.length > 0) {
						calendarColours.push({
							resource: worker.id,
							allDay: true,
							recurring: {
								repeat: 'weekly',
								weekDays: weekDaysBad.join(','),
							},
							cssClass: 'bg-cal-cell bg-cal-cell-warn',
						});
					}

					if (weekDaysGood.length > 0) {
						calendarColours.push({
							resource: worker.id,
							allDay: true,
							recurring: {
								repeat: 'weekly',
								weekDays: weekDaysGood.join(','),
							},
							cssClass: 'bg-cal-cell bg-cal-cell-ok',
						});
					}

					if (weekDaysMadeGood.length > 0) {
						calendarColours.push({
							resource: worker.id,
							allDay: true,
							recurring: {
								repeat: 'weekly',
								weekDays: weekDaysMadeGood.join(','),
							},
							cssClass: 'bg-cal-cell bg-cal-cell-warn-ok',
						});
					}
				});

				if (calendarColours.length > 0) setColours(calendarColours);
			})
			.catch(() => showToast('error'))
			.finally(() => {
				setCalLoading(false);
				setYearChange(false);
			});
	};

	const renderResource = useCallback(
		(resource: MbscResource) => {
			const worker = workers.filter((worker: any) => resource.id === worker.id)[0];
			if (!worker) return;

			const toApprove: boolean =
				calEvents.filter(
					(event: any) => event.resource === resource.id && event.hasExceptions === true
				).length > 0;

			return (
				<div className='resource-controls'>
					<Avatar
						data={resource}
						includeTitle={true}
						titleLink={`/workers/workers_list/worker_card/${resource.id}`}
						extraInfo={<p>{worker.job_title}</p>}
					/>
					{!calLoading && (
						<>
							<div className='resource-info-container'>
								<div>{worker.type_of_engagement}</div>
							</div>
							<div className='resource-approval-controls'>
								<div className='ac-left'>
									{toApprove && (
										<>
											<IonLabel className='pe-2'>Approve</IonLabel>
											<IonCheckbox
												onIonChange={(e) =>
													handleApproveChanged(
														e,
														String(resource.timesheet_id),
														String(resource.id)
													)
												}
												checked={worker.approved}
											/>
										</>
									)}
									{!toApprove && state.weekEnding <= DateTime.now().endOf('week') && (
										<FontAwesomeIcon icon={faCheck} />
									)}
								</div>
								<div className='ac-right'>
									<IonButton
										title='View week'
										onClick={(e: any) => handleWeekButtonClick(e, worker.id)}
									>
										<FontAwesomeIcon icon={faCalendarDays} />
									</IonButton>
								</div>
							</div>
						</>
					)}
				</div>
			);
		},
		[workers, calEvents, calLoading, state]
	);

	const renderScheduleEvent = useCallback((data: MbscCalendarEventData) => {
		let icon: any = null;

		if (data.original) {
			if (data.original.hasExceptions && !data.original.approved) {
				icon = <FontAwesomeIcon className='icon-exceptions' icon={faTriangleExclamation} />;
			} else if (data.original.hasExceptions && data.original.approved) {
				icon = (
					<FontAwesomeIcon className='icon-exceptions-approved' icon={faTriangleExclamation} />
				);
			} else {
				icon = <FontAwesomeIcon className='icon-no-exceptions' icon={faCircleCheck} />;
			}
		}

		return icon;
	}, []);

	const handleOnPageLoading = useCallback(
		(e: MbscPageChangeEvent, inst: Eventcalendar) => {
			// Set a timeout on the first load to avoid the component update warning
			setTimeout(
				() => {
					loadCal();
				},
				firstPageLoaded ? 0 : 200
			);
		},
		[state]
	);

	const handleOnPageLoaded = useCallback(() => {
		setFirstPageLoaded(true);
	}, []);

	const handleEventClick = useCallback(
		(e: MbscEventClickEvent) => {
			setAllocationModal({
				isOpen: true,
				allocationData: {
					type: 'day',
					event: {
						id: String(e.event.id),
						date: DateTime.fromJSDate(e.date),
					},
					workerData: workers.find((worker: any) => worker.id === e.event.resource),
				},
			});
		},
		[workers]
	);

	const handleWeekButtonClick = useCallback(
		(e: MouseEventHandler<HTMLIonButtonElement>, workerId: string) => {
			setAllocationModal({
				isOpen: true,
				allocationData: {
					type: 'week',
					event: {
						date: state.weekEnding.startOf('week'),
					},
					workerData: workers.find((worker: any) => worker.id === workerId),
				},
			});
		},
		[workers]
	);

	const handleApproveChanged = (e: any, allocationId: string, workerId: string) => {
		const isChecked: boolean = e.detail.checked;

		// Update the calendar's resource data to maintain checkbox state during transitions
		setWorkers((prevState: any) => {
			return prevState.map((item: any) => {
				if (item.id === workerId) item.approved = isChecked;
				return item;
			});
		});

		// Keep track of changes to send to the endpoint
		setApprovedBoxes((prevState: any) => {
			let tmp: Array<any> = prevState.filter((item: any) => item.id !== allocationId);
			tmp.push({ id: allocationId, approved: isChecked });
			return [...tmp];
		});
	};

	const handleApproveSelected = () => {
		if (!props.permissionTo('update')) {
			showToast('permission');
			return;
		}

		setIsLoading(true);
		axios
			.put('/api/timesheets/timesheets_and_allocations', { ids: approvedBoxes })
			.then(() => {
				showToast('saved');
				loadCal();
				loadWeekEndings();
			})
			.catch(() => {
				setIsLoading(false);
				showToast('error');
			});
	};

	const allocationModalOnClose = (reloadCal: boolean = false) => {
		setAllocationModal({ ...allocationModal, isOpen: false });
		if (reloadCal === true) loadCal();
	};

	return (
		<div className={`${props.pageId}`}>
			{isLoading && <Loading overlay={true} />}
			<TimesheetHeader
				setIsLoading={setIsLoading}
				setCalLoading={setCalLoading}
				setYearChange={setYearChange}
				loadWeekEndings={loadWeekEndings}
				weekEndingOptions={weekEndingOptions}
				yearSelectOptions={getYearSelectOptions({ prefix: 'Year' })}
				approvesAllTimesheets={
					worker && worker.hasOwnProperty('key_details')
						? worker.key_details.approves_all_timesheets
						: false
				}
				DEFAULT_DATE_FORMAT={DEFAULT_DATE_FORMAT}
			/>

			<div className='cal-container'>
				<Eventcalendar
					height='auto'
					data={calEvents}
					selectedDate={state.weekEnding.minus({ day: 1 })}
					colors={colours}
					resources={workers}
					view={{
						timeline: {
							type: 'week',
							startDay: 1,
							endDay: 0,
							resolutionHorizontal: 'day',
						},
					}}
					renderHeader={() => null}
					renderScheduleEvent={renderScheduleEvent}
					renderResource={renderResource}
					onPageLoading={handleOnPageLoading}
					onPageLoaded={handleOnPageLoaded}
					onEventClick={handleEventClick}
					showEventTooltip={false}
				/>

				{workers.length === 0 && calLoading === false && (
					<div className='msg-no-timesheets'>
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Timesheets</IonCardTitle>
								<IonCardSubtitle>
									<FontAwesomeIcon icon={faClock} className='pe-1' />
									{dateFormatterFullOrdinal(state.weekEnding)}
								</IonCardSubtitle>
							</IonCardHeader>

							<IonCardContent>
								There are no workers with timesheets for this week-ending date.
							</IonCardContent>
						</IonCard>
					</div>
				)}
			</div>

			<div className='cal-controls'>
				{props.permissionTo('update') && (
					<IonButton
						color='success'
						onClick={handleApproveSelected}
						disabled={approvedBoxes.length === 0}
					>
						Approve Selected
					</IonButton>
				)}
			</div>

			<AllocationModal
				isOpen={allocationModal.isOpen}
				allocationData={allocationModal.allocationData}
				onClose={allocationModalOnClose}
				permissionTo={props.permissionTo}
				loadWeekEndings={loadWeekEndings}
			/>
		</div>
	);
};

export default DailyHourlyTimesheets;

import TitleBar from '../../../components/TitleBar/TitleBar';
import EditWorkerSubmenuArray from './EditWorkerSubmenuArray';
import Submenu from '../../../components/Menu/Submenu';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import { useContext } from 'react';
import { WorkersContext } from './WorkersProvider';
import Loading from '../../../components/UI/Loading';
import { ActionType } from './actions';

const WorkerCardContainer: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const { state, dispatch } = useContext(WorkersContext);
	const defaultTab = 'key_details';

	const setModuleState = () => {
		// Reset the state's currently selected subnav tab
		if (state.activeSubNavTabs.workerTabs !== defaultTab) {
			dispatch({
				type: ActionType.UPDATE_ACTIVE_SUBNAV_TAB,
				payload: { workerTabs: defaultTab },
			});
		}
	};

	return (
		<div className={`component-${uid.replaceAll('.', '-')}`}>
			<TitleBar
				title={`Worker: ${
					state.worker ? `${state.worker?.user.first_name} ${state.worker?.user.last_name}` : '...'
				}`}
			/>
			{!state.worker && <Loading />}
			{state.worker && (
				<Submenu array={EditWorkerSubmenuArray(permissionTo)} onTogglePage={setModuleState} />
			)}
		</div>
	);
};

export default WorkerCardContainer;

import { IonContent, IonHeader, IonMenu, IonTitle, IonToolbar } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import SideMenuForm from './SideMenuForm';
import { SchedulingContext } from '../SchedulingProvider';
import { SideMenuAction } from '../scheduling-types';

interface Props {
	permissionTo: Function;
	handleSideMenuWillClose: any;
	handleSideMenuClose: any;
	handleOnSuccess: Function;
	handleDeleteEvent: Function;
}

const SideMenu: React.FC<Props> = (props) => {
	const MENU_ACTION_DEFAULT = SideMenuAction.CANCEL;
	const { state, dispatch } = useContext(SchedulingContext);
	const [showForm, setShowForm] = useState<boolean>(false);
	const [menuAction, setMenuAction] = useState<SideMenuAction>(MENU_ACTION_DEFAULT);
	let mainTitle: string | undefined = undefined;

	// Build the title
	if (
		state.tempEvent?.start.startOf('day').valueOf() ===
		state.tempEvent?.end.startOf('day').valueOf()
	) {
		// Single day
		mainTitle = state.tempEvent?.start.toFormat('ccc, LLL dd, yyyy');
	} else {
		// Multi-day
		mainTitle = `${state.tempEvent?.start.toFormat(
			'ccc, LLL dd, yyyy'
		)} to ${state.tempEvent?.end.toFormat('ccc, LLL dd, yyyy')}`;
	}

	const handleOnWillOpen = () => {
		// This helps stop unnecessary loads within the side menu form until it is opened
		setShowForm(true);

		// Reset
		setMenuAction(MENU_ACTION_DEFAULT);
	};

	const handleOnWillClose = () => {
		// Close the menu and inform the scheduler what action was performed
		props.handleSideMenuWillClose(menuAction);
	};

	const handleOnDidClose = () => {
		setShowForm(false);
	};

	return (
		<IonMenu
			menuId='schedulingSideMenu'
			contentId='appMainContent'
			side='end'
			type='overlay'
			swipeGesture={false}
			onIonWillOpen={handleOnWillOpen}
			onIonWillClose={handleOnWillClose}
			onIonDidClose={handleOnDidClose}
		>
			<IonHeader className='ion-no-border'>
				<IonToolbar>
					<IonTitle>
						<FontAwesomeIcon
							className='btnSideMenuClose'
							icon={faArrowRightLong}
							onClick={props.handleSideMenuClose}
						/>
						<div className='text-center'>{mainTitle}</div>
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className='ion-padding'>
				{showForm && (
					<SideMenuForm
						permissionTo={props.permissionTo}
						handleSideMenuClose={props.handleSideMenuClose}
						handleOnSuccess={props.handleOnSuccess}
						handleDeleteEvent={props.handleDeleteEvent}
						menuAction={menuAction}
						setMenuAction={setMenuAction}
						menuActionDefault={MENU_ACTION_DEFAULT}
					/>
				)}
			</IonContent>
		</IonMenu>
	);
};

export default SideMenu;

import SelectStyled from '../../../../components/UI/SelectStyled';

type Props = {
	roles: Array<any>;
	chosenRoles: Array<any>;
	setChosenRoles: Function;
	paymentMethods: Array<any>;
	chosenPaymentMethod: { label: string; value: string } | null;
	setChosenPaymentMethod: Function;
};

const ExtraFooterControls = (props: Props) => {
	return (
		<div className='extra-footer-controls-container'>
			<div className='efc-label'>Filter By Security:</div>
			<div className='efc-control'>
				<SelectStyled
					defaultValue={props.chosenRoles}
					value={props.chosenRoles}
					options={props.roles}
					onChange={props.setChosenRoles}
					isMulti={true}
					isClearable={true}
					isSearchable={false}
				/>
			</div>
			<div className='efc-label'>Filter By Payment Method:</div>
			<div className='efc-control'>
				<SelectStyled
					defaultValue={props.chosenPaymentMethod}
					value={props.chosenPaymentMethod}
					options={props.paymentMethods}
					onChange={props.setChosenPaymentMethod}
					isMulti={false}
					isClearable={true}
					isSearchable={false}
				/>
			</div>
		</div>
	);
};

export default ExtraFooterControls;

import { useState, useEffect } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { IonCard, IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import ItemModal from './ItemModal';
import MapModal from './MapModal';
import { processForm } from '../../../../api/forms';
import { mandatoryFieldChecks } from '../../../../components/Forms/FormValidation';

interface RouteIndexComponentExtended extends RouteIndexComponent {
	usageMode?: string;
	usageModeCompletion?: Function;
}

const OfficeLocationsIndex: React.FC<RouteIndexComponentExtended> = ({
	uid,
	routeTitle,
	permissionTo,
	usageMode,
	usageModeCompletion,
}) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [itemId, setItemId] = useState<string>('');
	const [itemName, setItemName] = useState<string>('');
	const [itemData, setItemData] = useState<any>(null);
	const [itemModalIsLoading, setItemModalIsLoading] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();

	// Modals
	const [itemModal, setItemModal] = useState<any>({
		isOpen: false,
		itemId: '',
		itemName: '',
	});
	const mapModalDefault: any = {
		isOpen: false,
		itemName: '',
		itemData: { address: { latitude: 0, longitude: 0 } },
	};
	const [mapModal, setMapModal] = useState<any>(mapModalDefault);

	useEffect(() => {
		loadGridData();
	}, []);

	const columns = [
		{
			headerName: 'Office Name',
			field: 'name',
			sortable: true,
			sort: 'asc',
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 100,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data._id)}
						/>
					)
				);
			},
		},
	];

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		setItemId('');
		setItemName('');
		setItemData(null);
		axios.get('/api/utilities/office_locations').then((res) => {
			setData(res.data);
			if (usageModeCompletion) usageModeCompletion();
			setGridLoading(false);
		});
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'map' && params.column.colId !== 'delete') {
			params.node.setSelected(true);
			setItemId(params.data._id);
			setItemName(params.data.name);
			setItemData(params.data);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'map' && params.column.colId !== 'delete') {
			handleCellClicked(params);
			handleEditItem();
		}
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this Office Location?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');
		setItemId('');
		setItemName('');
		setItemData(null);

		axios
			.delete(`/api/utilities/office_locations/${id}`)
			.then(() => {
				loadGridData();

				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const handleAddItem = () => {
		if (permissionTo('create')) {
			setItemModal({ isOpen: true, itemId: '', itemName: '' });
		} else {
			showToast('permission');
		}
	};

	const handleEditItem = () => {
		if (permissionTo('update')) {
			setItemModal({ isOpen: true, itemId: itemId, itemName: itemName, itemData: itemData });
		} else {
			showToast('permission');
		}
	};

	const handleSave = (isNew: boolean, formData: any) => {
		if (!permissionTo(isNew ? 'create' : 'update')) {
			showToast('permission');
			return false;
		}

		// Check if any of the form's fields are mandatory and stop the form they're empty
		if (mandatoryFieldsEmpty(formData) === true) return;

		// Strip the form data down to DB data fields only
		const fd: any = processForm(formData);

		const url = isNew
			? '/api/utilities/office_locations'
			: `/api/utilities/office_locations/${itemId}`;

		const payload: any = fd;

		setItemModalIsLoading(true);

		axios
			.put(url, payload)
			.then(() => {
				loadGridData();
				showToast('success');
				setItemModal({ isOpen: false });
			})
			.catch(() => {
				showToast('error');
			});
	};

	function mandatoryFieldsEmpty(items: any) {
		let hasEmptyFields = false;

		if (
			items.some((item: any) => item.hasOwnProperty('isMandatory') && item.isMandatory === true)
		) {
			const checks = mandatoryFieldChecks(items);

			if (checks.formValid !== true) {
				showToast('error', checks.errorMessage);
				hasEmptyFields = true;
			}
		}

		return hasEmptyFields;
	}

	const mapOpen = (itemData: any) => {
		if (permissionTo('update')) {
			setMapModal({
				isOpen: true,
				itemId: itemData.itemId,
				itemName: itemData.name,
				itemData: itemData,
			});
		} else {
			showToast('permission');
		}
	};

	const mapSave = (itemData: any, latlng: any) => {
		if (!permissionTo('update')) {
			showToast('permission');
			return false;
		}

		// Initial payload
		let payload: any = {
			name: itemData.name,
			address: itemData.address,
		};

		// Update lat/lng
		payload.address.latitude = latlng.lat;
		payload.address.longitude = latlng.lng;

		axios
			.put(`/api/utilities/office_locations/${itemData._id}`, payload)
			.then(() => {
				showToast('success');
				setMapModal((prevState: any) => {
					return { ...prevState, isOpen: false };
				});
			})
			.catch(() => {
				showToast('error');
			});
	};

	// Build the output
	let output = (
		<>
			<DataGrid
				title={routeTitle}
				cols={columns}
				data={data}
				autoSize={true}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				rowCursorPointer={permissionTo('update')}
				extraFooter={
					<IonRow>
						<IonCol size={'12'} className='text-right pe-0'>
							{permissionTo('update') && (
								<IonButton
									color='primary'
									onClick={() => handleEditItem()}
									disabled={itemId.length === 0}
								>
									Edit Item
								</IonButton>
							)}
							{permissionTo('create') && (
								<IonButton color='success' onClick={() => handleAddItem()}>
									Add Item
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				}
			/>

			<ItemModal
				isOpen={itemModal.isOpen}
				itemId={itemModal.itemId}
				itemName={itemModal.itemName}
				itemData={itemModal.itemData}
				isLoading={itemModalIsLoading}
				onSave={handleSave}
				onClose={() => {
					setItemModalIsLoading(false);
					setItemModal((prevState: any) => {
						return { ...prevState, isOpen: false };
					});
				}}
				onSuccess={loadGridData}
				permissionTo={permissionTo}
			/>

			<MapModal
				isOpen={mapModal.isOpen}
				itemName={mapModal.itemName}
				itemData={mapModal.itemData}
				onSave={mapSave}
				onClose={() => {
					setMapModal((prevState: any) => {
						return { ...prevState, isOpen: false };
					});
				}}
			/>
		</>
	);

	switch (usageMode) {
		case 'modal':
			output = (
				<div className='usage-modal' style={{ height: '100%' }}>
					<div className='filter-data-table' style={{ height: '100%' }}>
						{output}
					</div>
				</div>
			);
			break;
		default:
			output = (
				<IonCard className='table-card filter-data-table full-height-card'>
					{output}
				</IonCard>
			);
			break;
	}

	return (
		<div className={`component-${uid.replaceAll('.', '-')}`}>
			{!usageMode && <TitleBar title={routeTitle} />}
			{output}
		</div>
	);
};

export default OfficeLocationsIndex;

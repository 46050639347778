import { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router';

import Subnav from "../Menu/Subnav";
import MediaTabsArray from './MediaTabsArray';

interface Props {
	mediaCategory: 'worker' | 'job';
	mediaData?: any;
}

const MediaIndex: React.FC<Props> = ({ mediaCategory, mediaData }) => {
	const [subnav, setSubnav] = useState<Array<any>>([]);
	let history = useHistory();
	const location = useLocation();

	useEffect(() => {
		let array = MediaTabsArray(mediaCategory, mediaData).map((item: any) => {
			// Switch to tab if required
			let jumpToTab: string = '';
			if (location && location.search) {
				const urlParams = new URLSearchParams(location.search);
				if (urlParams.get('tab')) {
					jumpToTab = String(urlParams.get('tab'));

					// Remove the query parameter
					urlParams.delete('tab');
					history.replace({
						search: urlParams.toString(),
					});
				}
			}
			if (jumpToTab && item.id === jumpToTab) {
				item.active = true;
			} else {
				item.active = false;
			}
			return item;
		});

		// Select the first item as the default if no tab specified in the URL
		if (array.every((element: any) => element.active === false)) array[0].active = true;

		setSubnav(array);
	}, []);
	
	return (
		<>
			{ subnav.length > 0 && <Subnav array={subnav} /> }
		</>
	);
};

export default MediaIndex;

import { DateTime } from 'luxon';
import { HalfDayOptions } from '../pages/Workers/HolidayAbsenceTraining/Requests/request-types';

export function getPermissionTo(
	actions: string | Array<any> = '',
	uid: string = '',
	authCtx: any,
	restUID: string = ''
) {
	let permissionGranted = false;
	let countGranted = 0;
	let theUID = '';

	// Check this user has security permission for this route
	const resolvePath = (obj: any, path: any) =>
		path.split('.').reduce((o: any, p: any) => o?.[p], obj);

	if (uid.length > 0) {
		// Permission over-ride with UID
		theUID = uid;
	} else {
		// Permission of current route
		theUID = restUID;
	}

	let routeSec = resolvePath(authCtx.user.security, theUID);

	if (!routeSec) return false;

	if (typeof actions === 'string') actions = [actions];

	for (let i in actions) {
		if (routeSec[actions[i]] === true) {
			countGranted++;
		}
	}

	if (countGranted === actions.length) permissionGranted = true;

	//if (process.env.NODE_ENV !== 'production') console.log(`Can I ${actions.join(' and ')} ? ${permissionGranted ? "Yes" : "No"} (the UID:${theUID})`);

	return permissionGranted;
}

export function getDomElementInPath(el: any, className: string = '') {
	while (el.parentNode != null) {
		if (el.classList.contains(className)) {
			return el;
		}
		el = el.parentNode;
	}
	return null;
}

export function enforceDecimalLimit(value: string, limit: number) {
	const adjustedValue =
		value.indexOf('.') >= 0
			? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), Number(limit) + 1)
			: value;

	return adjustedValue;
}

export function underscoredToUppercasedWords(str: string) {
	let strArray: Array<string> = str.split('_');

	strArray = strArray.map((word: string) => {
		return capitalFirstLetter(word);
	});

	return strArray.join(' ');
}

export function capitalFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getNoOfDays = (
	date: DateTime[],
	includeTime: boolean = false,
	time: Array<number> = []
): number => {
	let start: DateTime, end: DateTime;
	let noOfDays: number = 1;

	if (date.length === 2) {
		start = date[0].startOf('day');
		end = date[1].startOf('day');

		if (start.toUnixInteger() !== end.toUnixInteger()) {
			noOfDays += end.diff(start, ['days']).toObject().days || 1;
		}

		if (includeTime) {
			noOfDays -= 0.5 * (time[0] + time[1]);
		}
	}

	return noOfDays;
};

export const getDaysArray = (start: Date, end: Date): Date[] => {
	let arr: Date[], date: Date;
	for (arr = [], date = new Date(start); date <= new Date(end); date.setDate(date.getDate() + 1)) {
		arr.push(new Date(date));
	}
	return arr;
};

export const getTimezone = (mode: 'payload' | null = null): any => {
	let output: any = null;

	switch (mode) {
		case 'payload':
		default:
			output = { timezone: DateTime.now().toFormat('z') };
			break;
	}

	return output;
};

export const buildHalfDayOptions = (
	halfDayOptions: Array<any>,
	selectRef: any,
	dayOfWeek: string | null,
	mode: string
): Array<any> => {
	if (halfDayOptions.length === 0) return [];

	const modeIndex: number = mode === 'start' ? 0 : 1;
	let options: Array<any> = [];
	let optionData: any = halfDayOptions.filter((opt: any) => opt.day_of_week === dayOfWeek);
	optionData = optionData.length === 0 ? halfDayOptions[0] : optionData[0];

	switch (mode) {
		case 'start':
			options = [
				{ label: optionData.hours_start, value: 0 },
				{ label: optionData.hours_mid_start, value: 1 },
			];
			break;
		case 'end':
			options = [
				{ label: optionData.hours_mid_end, value: 1 },
				{ label: optionData.hours_end, value: 0 },
			];
			break;
	}

	// Set the default selected option and rememebr if AM/PM was selected between date changes
	setTimeout(() => {
		if (selectRef.current && options) {
			const curOpt = selectRef.current.getValue()[0];
			selectRef.current.setValue(
				curOpt ? options.filter((opt: any) => opt.value === curOpt.value)[0] : options[modeIndex]
			);
		}
	}, 100);

	return options;
};

export const renderHalfDayOptionToDate = (
	mode: 'start' | 'end',
	theDate: DateTime,
	hourIndex: Number,
	halfDayOptions: HalfDayOptions[]
): DateTime => {
	const t: any = halfDayOptions.filter(
		(opt: any) => opt.day_of_week === theDate.toFormat('ccc').toLowerCase()
	)[0];

	// Check if this worker has any hours on this day
	if (!t) return theDate;

	let tSplit: Array<string> = [];

	switch (mode) {
		case 'start':
			if (hourIndex === 0) {
				tSplit = t.hours_start.split(':');
			} else {
				tSplit = t.hours_mid_start.split(':');
			}
			break;
		case 'end':
			if (hourIndex === 0) {
				tSplit = t.hours_end.split(':');
			} else {
				tSplit = t.hours_mid_end.split(':');
			}
			break;
	}

	return theDate.set({
		hour: Number(tSplit[0]),
		minute: Number(tSplit[1]),
	});
};

export const getYearSelectOptions = (args: {
	offsetMode?: boolean;
	baseYear?: number;
	range?: number;
	prefix?: string;
}) => {
	// Argument defaults
	const offsetMode: boolean = args.offsetMode ?? false;
	let baseYear: number = args.baseYear ?? -1;
	let range: number = args.range ?? 10;
	const prefix: string = args.prefix ?? '';

	const curYear = DateTime.now().year;

	if (baseYear < 0) {
		baseYear = 2022;
	} else if (baseYear > curYear) {
		range += baseYear - curYear;
	}

	return [...Array(curYear + range - baseYear)].map((val: any, i: number) => ({
		label:
			(prefix.length > 0 ? prefix + ': ' : '') +
			(!offsetMode
				? String(baseYear + i)
				: `${String(baseYear + i)} - ${String(baseYear + 1 + i)}`),
		value: baseYear + i,
	}));
};

export const getGroupedOptionByValue = (values: Array<any>, val: any) => {
	// If a grouped value is provided, then filter-out the correct group
	let valueFromGroup: any = [values[0].options[0]];
	const valueGroup: any = values.filter(
		(group: any) => group.options.filter((option: any) => option.value === val).length === 1
	);

	// Use the filtered-out group to get the correct option
	if (valueGroup && valueGroup.length > 0 && valueGroup[0].hasOwnProperty('options')) {
		valueFromGroup = valueGroup[0].options.filter((option: any) => option.value === val);
	}

	return valueFromGroup;
};

import { useRef } from 'react';

const useJobDatesArray = () => {
	let jobDates: Array<any> = [
		{
			title: 'Job Target Dates',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'target_start_date',
			title: 'Job Target Start Date',
			type: 'date',
			db: ['target_start_date'],
		},
		{
			id: 'target_completion_date',
			title: 'Job Target Completion Date',
			type: 'date',
			db: ['target_completion_date'],
		},
		{
			title: 'Job Actual Dates',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'work_scheduled_date',
			title: 'Work Scheduled Date',
			type: 'infoBox',
			db: ['work_scheduled_date'],
		},
		{
			id: 'work_started_date',
			title: 'Work Started Date',
			type: 'infoBox',
			db: ['work_started_date'],
		},
		{
			id: 'work_completed_date',
			title: 'Work Completed Date',
			type: 'infoBox',
			db: ['work_completed_date'],
		},
	];

	return { jobDates };
};

export default useJobDatesArray;

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
export const faClock: IconDefinition = {
	prefix: 'fas',
	iconName: 'clock',
	icon: [
		100,
		100,
		[] as string[],
		'',
		'M 50,87.6 C 29.2,87.6 12.3,70.7 12.3,49.9 12.3,29.1 29.2,12.2 50,12.2 70.8,12.2 87.7,29.1 87.7,49.9 87.7,70.7 70.8,87.6 50,87.6 Z M 50,16.8 C 31.7,16.8 16.9,31.7 16.9,49.9 16.9,68.1 31.7,83 50,83 68.3,83 83.1,68.1 83.1,49.9 83.1,31.7 68.3,16.8 50,16.8 Z m 25.3,33.5 c 0,-1.6 -1.3,-2.9 -2.9,-2.9 H 51.9 V 23.5 c 0,-1.6 -1.3,-2.9 -2.9,-2.9 -1.6,0 -2.9,1.3 -2.9,2.9 v 29.7 h 26.3 c 1.6,0 2.9,-1.3 2.9,-2.9 z',
	],
};

import TargetsAndSkills from './JobTabs/TargetsAndSkills';
import KeyDetails from './JobTabs/KeyDetails';
import JobDates from './JobTabs/JobDates';

const EditJobTabsArray: any = (permissionTo: Function) => {
	return [
		{
			id: 'key_details',
			title: 'Key Details',
			page: KeyDetails,
			active: true,
			pageData: { permissionTo, newJob: false },
		},
		{
			id: 'job_targets_and_skills',
			title: 'Job Targets and Skills',
			page: TargetsAndSkills,
			active: false,
			pageData: { permissionTo },
		},
		{
			id: 'job_dates',
			title: 'Job Dates',
			page: JobDates,
			active: false,
			pageData: { permissionTo },
		},
	];
};

export default EditJobTabsArray;

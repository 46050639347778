import { useMemo } from 'react';

const ToolTip = (props: any) => {
	const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);

	let managersNotes = data.managers_notes ? data.managers_notes : 'None';

	return (
		<div
			className='prop-tooltip mbsc-popup-body mbsc-material mbsc-popup-body-round '
			style={{ minWidth: '400px' }}
		>
			<div className='prop-tooltip-header'>Managers Notes</div>
			<div className='prop-tooltip-info'>
				<span className='prop-tooltip-text '>{managersNotes}</span>
			</div>
		</div>
	);
};

export default ToolTip;

const useNextOfKinArray = () => {
	let nextOfKin: Array<any> = [
		{
			title: 'Next of Kin',
			type: 'title',
			style: 'alpha',
		},
		{
			id: 'name',
			title: 'Next of Kin Name',
			type: 'text',
			db: ['next_of_kin', 'name'],
		},
		{
			id: 'contact_number',
			title: 'Next of Kin Contact Number',
			type: 'phone',
			db: ['next_of_kin', 'contact_number'],
		},
		{
			id: 'email_address',
			title: 'Next of Kin Email Address',
			type: 'email',
			db: ['next_of_kin', 'email_address'],
		},
		{
			id: 'relationship',
			title: 'Relationship',
			type: 'dropdown',
			values: null,
			db: ['next_of_kin', 'relationship'],
		},
		{
			title: 'Address',
			type: 'title',
			style: 'alpha',
		},
		{
			// Address look-up fields must have auto-assigned IDs
			title: 'Address Lookup',
			type: 'addressLookup',
			id: 'address-lookup-kin',
		},
		{
			title: 'Address 1',
			type: 'text',
			group: 'kin',
			db: ['next_of_kin', 'address', 'address_1'],
			subType: 'address_1',
		},
		{
			title: 'Address 2',
			type: 'text',
			group: 'kin',
			db: ['next_of_kin', 'address', 'address_2'],
			subType: 'address_2',
		},
		{
			title: 'Town',
			type: 'text',
			group: 'kin',
			db: ['next_of_kin', 'address', 'town'],
			subType: 'town',
		},
		{
			title: 'Postcode',
			type: 'postcode',
			group: 'kin',
			db: ['next_of_kin', 'address', 'postcode'],
			subType: 'postcode',
		},
	];

	return { nextOfKin };
};

export default useNextOfKinArray;

import { forwardRef, RefObject, useEffect, useRef, useState } from 'react';
import { IonButton, IonContent, IonHeader, IonToolbar, IonTitle } from '@ionic/react';
import axios from '../../../../../lib/axios';
import { showToast } from '../../../../../lib/toast';
import Loading from '../../../../../components/UI/Loading';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import './AccountDetails.scss';

interface Props {
	uid: string;
	permissionTo: Function;
}

const LicenseAgreement = (props: Props) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<any>([]);
	const componentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setLoading(true);
		axios
			.get('/api/utilities/company_details')
			.then((res: any) => {
				setData({
					companyName: res.data[0].company_name,
					agreementData: JSON.parse(res.data[0].agreement),
				});
			})
			.catch((err: any) => {
				showToast('error');
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<div className={`component-${props.uid.replaceAll('.', '-')}`}>
			{loading && <Loading />}
			{!loading && data && (
				<>
					<IonHeader>
						<IonToolbar>
							<IonTitle>License Agreement for {data.companyName}</IonTitle>
						</IonToolbar>
					</IonHeader>
					<IonContent className='ion-padding'>
						<div
							id='the_agreement'
							className='agreement-content'
							dangerouslySetInnerHTML={{ __html: data.agreementData.agreement }}
						></div>
					</IonContent>

					<div style={{ textAlign: 'right' }}>
						<ReactToPrint
							trigger={() => (
								<IonButton className='btn-print mt-3'>
									<FontAwesomeIcon icon={faPrint} />
									Print
								</IonButton>
							)}
							content={() => componentRef.current}
						/>
					</div>
					<div style={{ display: 'none' }}>
						<ComponentToPrint ref={componentRef} text={data.agreementData.agreement} />
					</div>
				</>
			)}
		</div>
	);
};

export default LicenseAgreement;

interface PrintProps {
	ref: any;
	text?: any;
}

const ComponentToPrint: React.FC<PrintProps> = forwardRef((props, ref) => {
	return (
		<div ref={ref as RefObject<HTMLDivElement>}>
			<div style={{ margin: '20px' }} dangerouslySetInnerHTML={{ __html: props.text }} />
		</div>
	);
});

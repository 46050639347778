import { useEffect, useState } from "react";

interface Props {
	filename: string;
	height?: string;
}

const FileIcon: React.FC<Props> = (props: Props) => {	
	const [icon, setIcon] = useState<string>('');
	const extensions: any = [
		{
			icon: 'access',
			extensions: [
				'accdb',
				'accdt',
				'mdb',
				'accda',
				'accdc',
				'accde',
				'accdp',
				'accdr',
				'accdu',
				'ade',
				'adp',
				'laccdb',
				'ldb',
				'mam',
				'maq',
				'mdw',
			],
		},
		{ icon: 'ai', extensions: ['ai'] },
		{
			icon: 'affinitydesigner',
			extensions: ['afdesign', 'affinitydesigner'],
		},
		{
			icon: 'affinityphoto',
			extensions: ['afphoto', 'affinityphoto'],
		},
		{
			icon: 'affinitypublisher',
			extensions: ['afpub', 'affinitypublisher'],
		},
		{
			icon: 'angular',
			extensions: [
				'.angular-cli.json',
				'angular-cli.json',
				'angular.json',
				'.angular.json',
			],
			filename: true,
		},
		{
			icon: 'ng_tailwind',
			extensions: ['ng-tailwind.js'],
			filename: true,
		},
		{
			icon: 'api_extractor',
			extensions: ['api-extractor.json', 'api-extractor-base.json'],
			filename: true,
		},
		{
			icon: 'appveyor',
			extensions: ['appveyor.yml', '.appveyor.yml'],
			filename: true,
		},
		{
			icon: 'arduino',
			extensions: ['ino', 'pde'],
		},
		{
			icon: 'asp',
			extensions: ['asp'],
		},
		{
			icon: 'aspx',
			extensions: ['aspx', 'ascx'],
		},
		{
			// https://en.wikipedia.org/wiki/Audio_file_format
			icon: 'audio',
			extensions: [
				'aac',
				'act',
				'aiff',
				'amr',
				'ape',
				'au',
				'dct',
				'dss',
				'dvf',
				'flac',
				'gsm',
				'iklax',
				'ivs',
				'm4a',
				'm4b',
				'm4p',
				'mmf',
				'mogg',
				'mp3',
				'mpc',
				'msv',
				'oga',
				'ogg',
				'opus',
				'ra',
				'raw',
				'tta',
				'vox',
				'wav',
				'wma',
			],
		},
		{
			icon: 'aurelia',
			extensions: ['aurelia.json'],
			filename: true,
		},
		{
			icon: 'avif',
			extensions: ['avif'],
		},
		{
			icon: 'awk',
			extensions: ['awk'],
		},
		{
			icon: 'azurepipelines',
			extensions: ['azure-pipelines.yml', '.vsts-ci.yml'],
			filename: true,
		},
		{
			icon: 'babel',
			extensions: ['.babelrc', '.babelignore'],
			filenamesGlob: ['.babelrc', 'babel.config'],
			extensionsGlob: ['js', 'cjs', 'mjs', 'json'],
			light: true,
			filename: true,
		},
		{
			icon: 'bat',
			extensions: ['bat'],
		},
		{
			icon: 'bazaar',
			extensions: ['.bzrignore'],
			filename: true,
		},
		{
			icon: 'bazel',
			extensions: ['BUILD.bazel', '.bazelrc', 'bazel.rc', 'bazel.bazelrc'],
			filename: true,
		},
		{
			// http://www.file-extensions.org/filetype/extension/name/binary-files
			icon: 'binary',
			extensions: [
				'a',
				'app',
				'bin',
				'cmo',
				'cmx',
				'cma',
				'cmxa',
				'cmi',
				'dll',
				'exe',
				'hl',
				'ilk',
				'lib',
				'n',
				'ndll',
				'o',
				'obj',
				'pyc',
				'pyd',
				'pyo',
				'pdb',
				'scpt',
				'scptd',
				'so',
			],
		},
		{
			icon: 'bithound',
			extensions: ['.bithoundrc'],
			filename: true,
		},
		{
			icon: 'bitbucketpipeline',
			extensions: ['bitbucket-pipelines.yml'],
			filename: true,
		},
		{
			icon: 'blitzbasic',
			extensions: ['bb'],
		},
		{
			icon: 'bower',
			extensions: ['.bowerrc', 'bower.json'],
			filename: true,
		},
		{
			icon: 'browserslist',
			extensions: ['.browserslistrc', 'browserslist'],
			filename: true,
		},
		{
			icon: 'buckbuild',
			extensions: ['.buckconfig'],
			filename: true,
		},
		{
			icon: 'bundler',
			extensions: ['gemfile', 'gemfile.lock'],
		},
		{
			icon: 'bundler',
			extensions: ['gemfile', 'gemfile.lock'],
			filename: true,
		},
		{
			icon: 'c',
			extensions: ['c'],
		},
		{ icon: 'cake', extensions: ['cake'] },
		{
			icon: 'capacitor',
			extensions: [],
			extensionsGlob: ['json'],
			filename: true,
			filenamesGlob: ['capacitor.config'],
		},
		{
			icon: 'cargo',
			extensions: ['cargo.toml', 'cargo.lock'],
			filename: true,
		},
		{
			icon: 'cert',
			extensions: [
				'csr',
				'crt',
				'cer',
				'der',
				'pfx',
				'p12',
				'p7b',
				'p7r',
				'src',
				'crl',
				'sst',
				'stl',
			],
		},
		{
			icon: 'cf',
			extensions: ['lucee'],
		},
		{
			icon: 'cheader',
			extensions: ['h'],
		},
		{
			icon: 'chef',
			extensions: ['chefignore', 'berksfile', 'berksfile.lock', 'policyfile'],
			filename: true,
		},
		{ icon: 'class', extensions: ['class'] },
		{
			icon: 'circleci',
			extensions: ['circle.yml'],
			light: true,
			filename: true,
		},
		{
			icon: 'clojure',
			extensions: ['cjm', 'cljc'],
		},
		{
			icon: 'clojurescript',
			extensions: ['cljs'],
		},
		{
			icon: 'cloudfoundry',
			extensions: ['.cfignore'],
			light: true,
			filename: true,
		},
		{
			icon: 'codacy',
			extensions: ['.codacy.yml', '.codacy.yaml'],
			light: true,
			filename: true,
		},
		{
			icon: 'codeclimate',
			extensions: ['.codeclimate.yml'],
			light: true,
			filename: true,
		},
		{
			icon: 'codecov',
			extensions: ['codecov.yml', '.codecov.yml'],
			filename: true,
		},
		{ icon: 'codekit', extensions: ['kit'] },
		{
			icon: 'codekit',
			extensions: [
				'config.codekit',
				'config.codekit2',
				'config.codekit3',
				'.config.codekit',
				'.config.codekit2',
				'.config.codekit3',
			],
			filename: true,
		},
		{
			icon: 'coffeelint',
			extensions: ['coffeelint.json', '.coffeelintignore'],
			filename: true,
		},
		{
			icon: 'conan',
			extensions: ['conanfile.txt', 'conanfile.py'],
			filename: true,
		},
		{
			icon: 'conda',
			extensions: ['.condarc'],
			filename: true,
		},
		{
			icon: 'config',
			extensions: ['plist'],
			light: true,
		},
		{
			icon: 'commitlint',
			extensions: ['commitlint.config.js'],
			filenamesGlob: ['.commitlintrc'],
			extensionsGlob: ['js', 'json', 'yml'],
			filename: true,
		},
		{
			icon: 'composer',
			extensions: ['composer.json', 'composer.lock'],
			filename: true,
		},
		{
			icon: 'coveralls',
			extensions: ['.coveralls.yml'],
			filename: true,
		},
		{
			icon: 'cpp',
			extensions: ['cpp'],
		},
		{
			icon: 'cppheader',
			extensions: ['hpp', 'hh', 'hxx', 'h++'],
		},
		{
			icon: 'crowdin',
			extensions: ['crowdin.yml'],
			filename: true,
		},
		{
			icon: 'csharp',
			extensions: ['csx'],
		},
		{ icon: 'csproj', extensions: ['csproj'] },
		{
			icon: 'css',
			extensions: ['css'],
		},
		{
			icon: 'csscomb',
			extensions: ['.csscomb.json'],
			filename: true,
		},
		{
			icon: 'csslint',
			extensions: ['.csslintrc'],
			filename: true,
		},
		{ icon: 'cssmap', extensions: ['css.map'] },
		{
			icon: 'cypress',
			extensions: [`cypress.json`, `cypress.env.json`],
			filename: true,
		},
		{
			icon: 'cvs',
			extensions: ['.cvsignore'],
			filename: true,
		},
		{
			icon: 'darcs',
			extensions: ['.boringignore'],
			filename: true,
		},
		{
			icon: 'db',
			extensions: ['db'],
			light: true,
		},
		{
			icon: 'dependabot',
			extensions: ['dependabot.yml'],
			filename: true,
		},
		{
			icon: 'dependencies',
			extensions: ['dependencies.yml'],
			filename: true,
		},
		{
			icon: 'devcontainer',
			extensions: ['devcontainer.json'],
			filename: true,
		},
		{
			icon: 'django',
			extensions: ['djt'],
		},
		{
			icon: 'docker',
			extensions: [],
			filenamesGlob: [
				'docker-compose',
				'docker-compose.ci-build',
				'docker-compose.override',
				'docker-compose.vs.debug',
				'docker-compose.vs.release',
				'docker-cloud',
			],
			extensionsGlob: ['yml'],
			filename: true,
		},
		{
			icon: 'dockertest',
			extensions: ['docker-compose.test.yml'],
			filename: true,
		},
		{
			icon: 'docpad',
			extensions: ['eco'],
			light: true,
		},
		{
			icon: 'docz',
			extensions: ['.doczrc'],
			filenamesGlob: ['docz', '.docz', 'doczrc', 'docz.config'],
			extensionsGlob: ['js', 'json'],
			filename: true,
		},
		{
			icon: 'dojo',
			extensions: ['.dojorc'],
			filename: true,
		},
		{
			icon: 'drawio',
			extensions: ['drawio', 'dio'],
			filenamesGlob: ['.drawio'],
			extensionsGlob: ['png', 'svg'],
		},
		{
			icon: 'drone',
			extensions: ['.drone.yml', '.drone.yml.sig'],
			light: true,
			filename: true,
		},
		{
			icon: 'dvc',
			extensions: ['.dvc'],
			languages: [],
			filename: true,
		},
		{
			icon: 'editorconfig',
			extensions: ['.editorconfig'],
			filename: true,
		},
		{ icon: 'ejs', extensions: ['ejs'] },
		{
			icon: 'elm',
			extensions: ['elm-package.json'],
			filename: true,
		},
		{
			icon: 'emacs',
			extensions: ['el', 'elc'],
		},
		{
			icon: 'ember',
			extensions: ['.ember-cli'],
			filename: true,
		},
		{ icon: 'ensime', extensions: ['ensime'] },
		{ icon: 'eps', extensions: ['eps'] },
		{
			icon: 'erlang',
			extensions: ['emakefile', '.emakerfile'],
			filename: true,
		},
		{
			icon: 'eslint',
			extensions: ['.eslintrc', '.eslintignore', '.eslintcache'],
			filenamesGlob: ['.eslintrc'],
			extensionsGlob: ['js', 'mjs', 'cjs', 'json', 'yaml', 'yml'],
			filename: true,
		},
		{
			icon: 'excel',
			extensions: ['xls', 'xlsx', 'xlsm', 'ods', 'fods'],
		},
		{
			icon: 'expo',
			extensions: [
				'app.json',
				'app.config.js',
				'app.config.json',
				'app.config.json5',
			],
			light: true,
			filename: true,
		},
		{
			icon: 'fauna',
			extensions: ['.faunarc'],
			filename: true,
		},
		{
			icon: 'favicon',
			extensions: ['favicon.ico'],
			filename: true,
		},
		{ icon: 'fbx', extensions: ['fbx'] },
		{
			icon: 'firebase',
			extensions: ['.firebaserc'],
			filename: true,
		},
		{
			icon: 'firebasehosting',
			extensions: ['firebase.json'],
			light: true,
			filename: true,
		},
		{
			icon: 'firestore',
			extensions: ['firestore.rules', 'firestore.indexes.json'],
			filename: true,
		},
		{
			icon: 'flash',
			extensions: ['swf', 'swc'],
		},
		{
			icon: 'fla',
			extensions: ['fla'],
			light: true,
		},
		{
			icon: 'floobits',
			extensions: ['.flooignore'],
			filename: true,
		},
		{ icon: 'flow', extensions: ['js.flow'] },
		{
			icon: 'flow',
			extensions: ['.flowconfig'],
			filename: true,
		},
		{
			icon: 'flutter',
			extensions: ['.flutter-plugins', '.metadata'],
			filename: true,
		},
		{
			icon: 'flutter_package',
			extensions: ['pubspec.lock', 'pubspec.yaml', '.packages'],
			filename: true,
		},
		{
			icon: 'font',
			extensions: ['woff', 'woff2', 'ttf', 'otf', 'eot', 'pfa', 'pfb', 'sfd'],
			light: true,
		},
		{
			icon: 'fossa',
			extensions: ['.fossaignore'],
			filename: true,
		},
		{
			icon: 'fossil',
			extensions: ['ignore-glob'],
			filename: true,
		},
		{ icon: 'fsproj', extensions: ['fsproj'] },
		{
			icon: 'fusebox',
			extensions: ['fuse.js'],
			filename: true,
		},
		{
			icon: 'git',
			extensions: [
				'.gitattributes',
				'.gitconfig',
				'.gitignore',
				'.gitmodules',
				'.gitkeep',
				'.mailmap',
			],
			filename: true,
		},
		{
			icon: 'gamemaker',
			extensions: ['gmx'],
		},
		{
			icon: 'gamemaker2',
			extensions: ['yy', 'yyp'],
			light: true,
		},
		{
			icon: 'gatsby',
			extensions: [],
			filenamesGlob: [
				'gatsby-config',
				'gatsby-node',
				'gatsby-browser',
				'gatsby-ssr',
			],
			extensionsGlob: ['js', 'ts'],
			filename: true,
		},
		{
			icon: 'gitlab',
			extensions: ['.gitlab-ci.yml'],
			filename: true,
		},
		{
			icon: 'gitpod',
			extensions: [],
			filenamesGlob: ['.gitpod', 'gitpod'],
			extensionsGlob: ['yaml', 'yml'],
			filename: true,
		},
		{
			icon: 'glide',
			extensions: ['glide.yml'],
			filename: true,
		},
		{
			icon: 'glitter',
			extensions: ['.glitterrc'],
			filename: true,
		},
		{
			icon: 'go_package',
			extensions: ['go.sum', 'go.mod'],
			filename: true,
		},
		{
			icon: 'godot',
			extensions: [],
		},
		{
			icon: 'gradle',
			extensions: ['gradle'],

			light: true,
		},
		{
			icon: 'graphql',
			extensions: ['.gqlconfig'],
			filename: true,
		},
		{
			icon: 'graphql_config',
			extensions: ['.graphqlconfig'],
			filenamesGlob: ['.graphqlconfig'],
			extensionsGlob: ['yml', 'yaml'],
			filename: true,
		},
		{
			icon: 'greenkeeper',
			extensions: ['greenkeeper.json'],
			filename: true,
		},
		{
			icon: 'gridsome',
			extensions: [],
			filenamesGlob: ['gridsome.config', 'gridsome.server', 'gridsome.client'],
			extensionsGlob: ['js', 'ts'],
			filename: true,
		},
		{
			icon: 'grunt',
			extensions: [],
			filenamesGlob: ['gruntfile', 'gruntfile.babel'],
			extensionsGlob: ['js', 'coffee', 'ts'],
			filename: true,
		},
		{
			icon: 'gulp',
			extensions: [],
			filenamesGlob: ['gulpfile', 'gulpfile.esm', 'gulpfile.babel'],
			extensionsGlob: ['js', 'coffee', 'ts', 'mjs'],
			filename: true,
		},
		{
			icon: 'haxe',
			extensions: ['haxelib.json'],
			filename: true,
		},
		{
			icon: 'haxecheckstyle',
			extensions: ['checkstyle.json'],
			filename: true,
		},
		{
			icon: 'haxedevelop',
			extensions: ['hxproj'],
		},
		{
			icon: 'helix',
			extensions: ['.p4ignore'],
			filename: true,
		},
		{
			icon: 'html',
			extensions: ['html', 'htm'],
		},
		{
			icon: 'htmlhint',
			extensions: ['.htmlhintrc'],
			filename: true,
		},
		{
			icon: 'husky',
			extensions: ['.huskyrc', 'husky.config.js'],
			filenamesGlob: ['.huskyrc'],
			extensionsGlob: ['js', 'json', 'yaml', 'yml'],
			filename: true,
		},
		{
			icon: 'hygen',
			extensions: ['ejs.t'],
		},
		{
			icon: 'idris',
			extensions: ['idr', 'lidr'],
		},
		{ icon: 'idrisbin', extensions: ['ibc'] },
		{ icon: 'idrispkg', extensions: ['ipkg'] },
		{
			icon: 'image',
			extensions: ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'tiff', 'ico'],
		},
		{
			icon: 'imba',
			extensions: ['imba', 'imba2'],
		},
		{
			icon: 'inc',
			extensions: ['inc', 'include'],
		},
		{
			icon: 'infopath',
			extensions: ['infopathxml', 'xsn', 'xsf', 'xtp2'],
		},
		{
			icon: 'ini',
			extensions: ['ini'],
			light: true,
		},
		{
			icon: 'ink',
			extensions: ['ink'],
		},
		{
			icon: 'ionic',
			extensions: ['ionic.project', 'ionic.config.json'],
			filename: true,
		},
		{
			icon: 'jake',
			extensions: ['jakefile', 'jakefile.js'],
			filename: true,
		},
		{ icon: 'jar', extensions: ['jar'] },
		{
			icon: 'jasmine',
			extensions: ['jasmine.json'],
			filename: true,
		},
		{
			icon: 'java',
			extensions: ['java'],
		},
		{
			icon: 'jbuilder',
			extensions: ['jbuilder'],
		},
		{
			icon: 'jest',
			extensions: [
				'jest.config.json',
				'jest.config.base.json',
				'jest.config.common.json',
				'jest.config.ts',
				'jest.config.base.ts',
				'jest.config.common.ts',
				'jest.json',
				'.jestrc',
				'.jestrc.js',
				'.jestrc.json',
			],
			filenamesGlob: [
				'jest.config',
				'jest.config.base',
				'jest.config.common',
				'jest.config.babel',
			],
			extensionsGlob: ['js', 'cjs', 'mjs'],
			filename: true,
		},
		{
			icon: 'jest_snapshot',
			extensions: ['js.snap', 'jsx.snap', 'ts.snap', 'tsx.snap'],
		},
		{
			icon: 'jpm',
			extensions: ['.jpmignore'],
			filename: true,
		},
		{
			icon: 'js',
			extensions: ['js'],
			light: true,
		},
		{
			icon: 'jsbeautify',
			extensions: [
				'.jsbeautifyrc',
				'jsbeautifyrc',
				'.jsbeautify',
				'jsbeautify',
			],
			filename: true,
		},
		{
			icon: 'jsconfig',
			extensions: ['jsconfig.json'],
			light: true,
			filename: true,
		},
		{
			icon: 'jscpd',
			extensions: ['.jscpd.json'],
			filenamesGlob: ['jscpd-report'],
			extensionsGlob: ['xml', 'json', 'html'],
			filename: true,
		},
		{
			icon: 'jshint',
			extensions: ['.jshintrc', '.jshintignore'],
			filename: true,
		},
		{
			icon: 'jsmap',
			extensions: ['js.map'],
			light: true,
		},
		{
			icon: 'json',
			extensions: ['json', 'jsonc'],
			light: true,
		},
		{
			icon: 'json5',
			extensions: ['json5'],
			light: true,
		},
		{
			icon: 'jsonld',
			extensions: ['jsonld', 'json-ld'],
			light: true,
		},
		{ icon: 'jsp', extensions: ['jsp'] },
		{ icon: 'jss', extensions: ['jss'] },
		{ icon: 'jupyter', extensions: ['ipynb'] },
		{
			icon: 'io',
			extensions: ['io'],
			light: true,
		},
		{
			icon: 'karma',
			extensions: [],
			filenamesGlob: ['karma.conf'],
			extensionsGlob: ['js', 'coffee', 'ts'],
			filename: true,
		},
		{ icon: 'key', extensions: ['key', 'pem'] },
		{
			icon: 'kite',
			extensions: ['.kiteignore'],
			light: true,
			filename: true,
		},
		{
			icon: 'kitchenci',
			extensions: ['.kitchen.yml', 'kitchen.yml'],
			filename: true,
		},
		{
			icon: 'layout',
			extensions: ['master', 'layout.html', 'layout.htm'],
		},
		{
			icon: 'layout',
			extensions: ['layout.html', 'layout.htm'],
			filename: true,
		},
		{
			icon: 'lerna',
			extensions: ['lerna.json'],
			light: true,
			filename: true,
		},
		{
			icon: 'less',
			extensions: ['less'],
		},
		{ icon: 'license', extensions: ['enc'] },
		{
			icon: 'license',
			extensions: ['license', 'licence', 'copying', 'copying.lesser'],
			filenamesGlob: ['license', 'licence', 'copying', 'copying.lesser'],
			extensionsGlob: ['md', 'txt'],
			filename: true,
		},
		{
			icon: 'lighthouse',
			extensions: [],
			filenamesGlob: ['.lighthouserc'],
			extensionsGlob: ['js', 'json', 'yaml', 'yml'],
			filename: true,
		},
		{ icon: 'lime', extensions: ['hxp'] },
		{
			icon: 'lime',
			extensions: ['include.xml'],
			filename: true,
		},
		{
			icon: 'lintstagedrc',
			extensions: ['.lintstagedrc', 'lint-staged.config.js'],
			filenamesGlob: ['.lintstagedrc'],
			extensionsGlob: ['js', 'json', 'yaml', 'yml'],
			filename: true,
		},
		{ icon: 'liquid', extensions: ['liquid'] },
		{ icon: 'livescript', extensions: ['ls'] },
		{ icon: 'lnk', extensions: ['lnk'] },
		{ icon: 'locale', extensions: [] },
		{ icon: 'log', extensions: ['log', 'tlg'] },
		{
			icon: 'lua',
			extensions: ['lua'],
		},
		{
			icon: 'lync',
			extensions: ['crec', 'ocrec'],
		},
		{
			icon: 'makefile',
			extensions: ['makefile'],
		},
		{
			icon: 'manifest',
			extensions: ['manifest'],
			filename: true,
		},
		{
			icon: 'manifest_skip',
			extensions: ['manifest.skip'],
			filename: true,
		},
		{
			icon: 'manifest_bak',
			extensions: ['manifest.bak'],
			filename: true,
		},
		{ icon: 'map', extensions: ['map'] },
		{
			icon: 'markdown',
			extensions: ['mdown', 'markdown'],
		},
		{
			icon: 'markdownlint',
			extensions: ['.markdownlint.json'],
			filename: true,
		},
		{
			icon: 'markojs',
			extensions: ['marko.js'],
		},
		{
			icon: 'matlab',
			extensions: [
				'fig',
				'mex',
				'mexn',
				'mexrs6',
				'mn',
				'mum',
				'mx',
				'mx3',
				'rwd',
				'slx',
				'slddc',
				'smv',
				'xvc',
			],
		},
		{
			icon: 'maven',
			extensions: ['maven.config'],
			filenamesGlob: ['pom', 'extensions', 'settings'],
			extensionsGlob: ['xml'],
			filename: true,
		},
		{
			icon: 'mercurial',
			extensions: ['.hgignore'],
			filename: true,
		},
		{
			icon: 'mocha',
			extensions: ['mocha.opts'],
			filenamesGlob: ['.mocharc'],
			extensionsGlob: ['js', 'json', 'jsonc', 'yaml', 'yml'],
			filename: true,
		},
		{
			icon: 'modernizr',
			extensions: ['modernizr'],
			filenamesGlob: ['modernizr', 'modernizrrc', '.modernizr', '.modernizrrc'],
			extensionsGlob: ['js'],
			filename: true,
		},
		{
			icon: 'moleculer',
			extensions: [],
			filenamesGlob: ['moleculer.config'],
			extensionsGlob: ['js', 'json', 'ts'],
			filename: true,
		},
		{
			icon: 'monotone',
			extensions: ['.mtn-ignore'],
			filename: true,
		},
		{
			icon: 'mustache',
			extensions: ['mustache', 'mst'],
			light: true,
		},
		{
			icon: 'nestjs',
			extensions: [
				'.nest-cli.json',
				'nest-cli.json',
				'nestconfig.json',
				'.nestconfig.json',
			],
			filename: true,
		},
		{
			icon: 'netlify',
			extensions: ['netlify.toml'],
			filename: true,
		},
		{
			icon: 'next',
			extensions: ['next.config.js'],
			filename: true,
			light: true,
		},
		{
			icon: 'nginx',
			extensions: ['nginx.conf'],
			filename: true,
		},
		{
			icon: 'ninja',
			extensions: ['build.ninja'],
			filename: true,
		},
		{
			icon: 'njsproj',
			extensions: ['njsproj'],
		},
		{
			icon: 'node',
			extensions: ['.node-version', '.nvmrc'],
			filename: true,
		},
		{
			icon: 'nodemon',
			extensions: ['nodemon.json'],
			filename: true,
		},
		{
			icon: 'npm',
			extensions: [
				'.npmignore',
				'.npmrc',
				'package.json',
				'package-lock.json',
				'npm-shrinkwrap.json',
			],
			filename: true,
		},
		{
			icon: 'nsri',
			extensions: ['.nsrirc', '.nsriignore', 'nsri.config.js'],
			filenamesGlob: ['.nsrirc'],
			extensionsGlob: ['js', 'json', 'yaml', 'yml'],
			filename: true,
		},
		{
			icon: 'nsri-integrity',
			extensions: ['.integrity.json'],
			filename: true,
		},
		{
			icon: 'nuget',
			extensions: ['nupkg', 'snupkg', 'nuspec', 'psmdcp'],
		},
		{
			icon: 'numpy',
			extensions: ['npy', 'npz'],
		},
		{
			icon: 'nunjucks',
			extensions: ['nunj', 'njs'],
		},
		{
			icon: 'nuxt',
			extensions: [],
			filenamesGlob: ['nuxt.config'],
			extensionsGlob: ['js', 'ts'],
			filename: true,
		},
		{
			icon: 'nyc',
			extensions: ['.nycrc', '.nycrc.json'],
			filename: true,
		},
		{
			icon: 'ocaml',
			extensions: ['.merlin'],
			filename: true,
		},
		{
			icon: 'onenote',
			extensions: ['one', 'onepkg', 'onetoc', 'onetoc2', 'sig'],
		},
		{
			icon: 'opencl',
			extensions: ['cl', 'opencl'],
		},
		{ icon: 'org', extensions: ['org'] },
		{
			icon: 'outlook',
			extensions: ['pst', 'bcmx', 'otm', 'msg', 'oft', 'eml'],
		},
		{ icon: 'ovpn', extensions: ['ovpn'] },
		{ icon: 'package', extensions: ['pkg'] },
		{
			icon: 'paket',
			extensions: [],
			filenamesGlob: ['paket'],
			extensionsGlob: [
				'dependencies',
				'lock',
				'references',
				'template',
				'local',
			],
			filename: true,
		},
		{ icon: 'patch', extensions: ['patch'] },
		{
			icon: 'pcl',
			extensions: ['pcd'],
			light: true,
		},
		{ icon: 'pdf', extensions: ['pdf'] },
		{
			icon: 'perl',
			extensions: ['perl'],
		},
		{ icon: 'photoshop', extensions: ['psd'] },
		{
			icon: 'php',
			extensions: [
				'php',
				'php1',
				'php2',
				'php3',
				'php4',
				'php5',
				'php6',
				'phps',
				'phpsa',
				'phpt',
				'phtml',
				'phar',
			],
		},
		{
			icon: 'phpcsfixer',
			extensions: ['.php_cs', '.php_cs.dist'],
			filename: true,
		},
		{
			icon: 'phpunit',
			extensions: ['phpunit', 'phpunit.xml', 'phpunit.xml.dist'],
			filename: true,
		},
		{
			icon: 'phraseapp',
			extensions: ['.phraseapp.yml'],
			filename: true,
		},
		{
			icon: 'pip',
			extensions: ['pipfile', 'pipfile.lock'],
			filename: true,
		},
		{
			icon: 'pipeline',
			extensions: ['pipeline'],
		},
		{
			icon: 'platformio',
			extensions: ['platformio.ini'],
			filename: true,
		},
		{
			icon: 'plantuml',
			extensions: ['pu', 'plantuml', 'iuml', 'puml'],
		},
		{
			icon: 'plsql_package',
			extensions: ['pck'],
		},
		{
			icon: 'plsql_package_body',
			extensions: ['pkb'],
		},
		{
			icon: 'plsql_package_header',
			extensions: ['pkh'],
		},
		{
			icon: 'plsql_package_spec',
			extensions: ['pks'],
		},
		{
			icon: 'pnpm',
			extensions: ['pnpmfile.js', 'pnpm-lock.yaml', 'pnpm-workspace.yaml'],
			light: true,
			filename: true,
		},
		{
			icon: 'poedit',
			extensions: ['po', 'mo'],
		},
		{
			icon: 'postcssconfig',
			extensions: [
				'.postcssrc',
				'.postcssrc.json',
				'.postcssrc.yml',
				'.postcssrc.js',
				'postcss.config.js',
			],
			filename: true,
		},
		{
			icon: 'powerpoint',
			extensions: [
				'pot',
				'potx',
				'potm',
				'pps',
				'ppsx',
				'ppsm',
				'ppt',
				'pptx',
				'pptm',
				'pa',
				'ppa',
				'ppam',
				'sldm',
				'sldx',
			],
		},
		{
			icon: 'powershell_psm',
			extensions: ['psm1'],
		},
		{
			icon: 'powershell_psd',
			extensions: ['psd1'],
		},
		{
			icon: 'powershell_format',
			extensions: ['format.ps1xml'],
		},
		{
			icon: 'powershell_types',
			extensions: ['types.ps1xml'],
		},
		{
			icon: 'precommit',
			extensions: ['.pre-commit-config.yaml'],
			filename: true,
		},
		{
			icon: 'prettier',
			extensions: ['.prettierrc', '.prettierignore'],
			light: true,
			filename: true,
		},
		{
			icon: 'prettier',
			extensions: [],
			filenamesGlob: ['prettier.config'],
			extensionsGlob: ['js', 'cjs', 'ts', 'coffee'],
			light: true,
			filename: true,
		},
		{
			icon: 'prettier',
			extensions: [],
			filenamesGlob: ['.prettierrc'],
			extensionsGlob: ['js', 'cjs', 'json', 'json5', 'yml', 'yaml'],
			light: true,
			filename: true,
		},
		{
			icon: 'procfile',
			extensions: ['procfile'],
			filename: true,
		},
		{
			icon: 'prolog',
			extensions: ['pro', 'P'],
		},
		{
			icon: 'protractor',
			extensions: [],
			filenamesGlob: ['protractor.conf'],
			extensionsGlob: ['js', 'coffee', 'ts'],
			filename: true,
		},
		{
			icon: 'publisher',
			extensions: ['pub', 'puz'],
		},
		{
			icon: 'pug',
			extensions: [
				'.jade-lintrc',
				'.pug-lintrc',
				'.jade-lint.json',
				'.pug-lintrc.js',
				'.pug-lintrc.json',
			],
			filename: true,
		},
		{
			icon: 'python',
			extensions: ['py'],
		},
		{
			icon: 'pytyped',
			extensions: ['py.typed'],
			filename: true,
		},
		{
			icon: 'pyup',
			extensions: ['.pyup', '.pyup.yml'],
			filename: true,
		},
		{ icon: 'q', extensions: ['q'] },
		{ icon: 'qbs', extensions: ['qbs'] },
		{
			icon: 'qlikview',
			extensions: ['qvd', 'qvw'],
		},
		{
			icon: 'qmldir',
			extensions: ['qmldir'],
			filename: true,
		},
		{
			icon: 'quasar',
			extensions: ['quasar.conf.js'],
			filename: true,
		},
		{ icon: 'rake', extensions: ['rake'] },
		{
			icon: 'rake',
			extensions: ['rakefile'],
			filename: true,
		},
		{
			icon: 'razzle',
			extensions: [],
			filenamesGlob: ['razzle.config'],
			extensionsGlob: ['js'],
			light: true,
			filename: true,
		},
		{
			icon: 'reacttemplate',
			extensions: ['rt'],
		},
		{ icon: 'registry', extensions: ['reg'] },
		{
			icon: 'rego',
			extensions: ['rego'],
		},
		{
			icon: 'rehype',
			extensions: ['.rehyperc', '.rehypeignore'],
			filenamesGlob: ['.rehyperc'],
			extensionsGlob: ['js', 'json', 'yml', 'yaml'],
			light: true,
			filename: true,
		},
		{
			icon: 'remark',
			extensions: ['.remarkrc', '.remarkignore'],
			filenamesGlob: ['.remarkrc'],
			extensionsGlob: ['js', 'json', 'yml', 'yaml'],
			light: true,
			filename: true,
		},
		{
			icon: 'renovate',
			extensions: ['.renovaterc'],
			filenamesGlob: ['renovate', '.renovaterc'],
			extensionsGlob: ['json'],
			filename: true,
		},
		{
			icon: 'retext',
			extensions: ['.retextrc', '.retextignore'],
			filenamesGlob: ['.retextrc'],
			extensionsGlob: ['js', 'json', 'yml', 'yaml'],
			light: true,
			filename: true,
		},
		{
			icon: 'robots',
			extensions: ['robots.txt'],
			filename: true,
		},
		{
			icon: 'rollup',
			extensions: [],
			filenamesGlob: [
				'rollup.config',
				'rollup.config.common',
				'rollup.config.dev',
				'rollup.config.prod',
			],
			extensionsGlob: ['js', 'cjs', 'mjs', 'coffee', 'ts'],
			filename: true,
		},
		{ icon: 'rproj', extensions: ['rproj'] },
		{
			icon: 'rspec',
			extensions: ['.rspec'],
			filename: true,
		},
		{
			icon: 'rubocop',
			extensions: ['.rubocop.yml', '.rubocop_todo.yml'],
			filename: true,
			light: true,
		},
		{
			icon: 'rust_toolchain',
			extensions: ['rust-toolchain'],
			filename: true,
		},
		{
			icon: 'sails',
			extensions: ['.sailsrc'],
			filename: true,
		},
		{ icon: 'saltstack', extensions: ['sls'] },
		{ icon: 'sass', extensions: ['sass'] },
		{
			icon: 'scss',
			extensions: ['scssm', 'scss'],
		},
		{
			icon: 'sentry',
			extensions: ['.sentryclirc'],
			filename: true,
		},
		{
			icon: 'serverless',
			extensions: [],
			filenamesGlob: ['serverless'],
			extensionsGlob: ['yml', 'json', 'js', 'ts'],
			filename: true,
		},
		{
			icon: 'sequelize',
			extensions: ['.sequelizerc'],
			filenamesGlob: ['.sequelizerc'],
			extensionsGlob: ['js', 'json'],
			filename: true,
		},
		{
			icon: 'shaderlab',
			extensions: ['unity'],
			light: true,
		},
		{
			icon: 'shell',
			extensions: ['fish'],
		},
		{ icon: 'sketch', extensions: ['sketch'] },
		{ icon: 'sln', extensions: ['sln'] },
		{
			icon: 'skipper',
			extensions: ['eskip'],
		},
		{
			icon: 'snapcraft',
			extensions: ['snapcraft.yaml'],
			filename: true,
		},
		{
			icon: 'snyk',
			extensions: ['.snyk'],
			filename: true,
		},
		{
			icon: 'solidarity',
			extensions: ['.solidarity', '.solidarity.json'],
			filename: true,
		},
		{
			icon: 'spacengine',
			extensions: ['spe'],
		},
		{
			icon: 'sql',
			extensions: ['sql'],
		},
		{
			icon: 'sqlite',
			extensions: ['sqlite', 'sqlite3', 'db3'],
		},
		{ icon: 'sss', extensions: ['sss'] },
		{
			icon: 'stata',
			extensions: ['dta'],
		},
		{
			icon: 'stylelint',
			extensions: ['.stylelintrc', '.stylelintignore', '.stylelintcache'],
			filenamesGlob: ['stylelint.config', '.stylelintrc'],
			extensionsGlob: ['js', 'json', 'yaml', 'yml', 'ts', 'cjs'],
			light: true,
			filename: true,
		},
		{
			icon: 'stylish_haskell',
			extensions: ['.stylish-haskell.yaml'],
			filename: true,
		},
		{
			icon: 'storyboard',
			extensions: ['storyboard'],
		},
		{
			icon: 'storybook',
			extensions: [],
			filenamesGlob: ['story', 'stories'],
			extensionsGlob: ['js', 'jsx', 'ts', 'tsx', 'mdx'],
		},
		{
			icon: 'subversion',
			extensions: ['.svnignore'],
			filename: true,
		},
		{ icon: 'svg', extensions: ['svg'] },
		{
			icon: 'swift',
			extensions: ['package.pins'],
			filename: true,
		},
		{
			icon: 'symfony',
			extensions: ['symfony.lock'],
			light: true,
			filename: true,
		},
		{
			icon: 'tailwind',
			extensions: [],
			filenamesGlob: ['tailwind', 'tailwind.config'],
			extensionsGlob: ['js', 'coffee', 'ts', 'json'],
			filename: true,
		},
		{
			icon: 'tt',
			extensions: ['tt2'],
		},
		{ icon: 'tcl', extensions: ['tcl', 'exp'] },
		{
			icon: 'terraform',
			extensions: ['tfstate'],
		},
		{ icon: 'test', extensions: ['tst'] },
		{
			icon: 'testcafe',
			extensions: ['.testcaferc.json'],
			filename: true,
			light: true,
		},
		{
			icon: 'testjs',
			extensions: [],
			filenamesGlob: ['test', 'spec'],
			extensionsGlob: ['js', 'jsx', 'mjs'],
			light: true,
		},
		{
			icon: 'testts',
			extensions: [],
			filenamesGlob: ['test', 'spec', 'e2e-test', 'e2e-spec'],
			extensionsGlob: ['ts', 'tsx'],
		},
		{
			icon: 'tex',
			extensions: ['texi', 'tikz'],
			light: true,
		},
		{
			icon: 'text',
			extensions: ['csv', 'tsv', 'txt'],
		},
		{
			icon: 'tfs',
			extensions: ['.tfignore'],
			filename: true,
		},
		{
			icon: 'todo',
			extensions: ['todo'],
			light: true,
		},
		{
			icon: 'tox',
			extensions: ['tox.ini'],
			filename: true,
		},
		{
			icon: 'travis',
			extensions: ['.travis.yml'],
			filename: true,
		},
		{
			icon: 'tsconfig',
			extensions: [],
			filenamesGlob: [
				'tsconfig',
				'tsconfig.app',
				'tsconfig.base',
				'tsconfig.common',
				'tsconfig.dev',
				'tsconfig.development',
				'tsconfig.e2e',
				'tsconfig.prod',
				'tsconfig.production',
				'tsconfig.server',
				'tsconfig.spec',
				'tsconfig.staging',
				'tsconfig.test',
			],
			extensionsGlob: ['json'],
			filename: true,
		},
		{
			icon: 'tslint',
			extensions: ['tslint.json', 'tslint.yaml', 'tslint.yml'],
			filename: true,
		},
		{
			icon: 'typedoc',
			extensions: [],
			filenamesGlob: ['typedoc'],
			extensionsGlob: ['js', 'json'],
			filename: true,
		},
		{
			icon: 'typescript',
			extensions: ['ts', 'tsx'],
		},
		{
			icon: 'typescriptdef',
			extensions: ['d.ts'],
		},
		{
			icon: 'unibeautify',
			extensions: ['.unibeautifyrc', 'unibeautify.config.js'],
			filenamesGlob: ['.unibeautifyrc'],
			extensionsGlob: ['js', 'json', 'yaml', 'yml'],
			filename: true,
			light: true,
		},
		{
			icon: 'unlicense',
			extensions: ['unlicense', 'unlicence'],
			filenamesGlob: ['unlicense', 'unlicence'],
			extensionsGlob: ['md', 'txt'],
			filename: true,
		},
		{
			icon: 'vagrant',
			extensions: ['vagrantfile'],
			filename: true,
		},
		{ icon: 'vala', extensions: ['vala'] },
		{ icon: 'vapi', extensions: ['vapi'] },
		{
			icon: 'vash',
			extensions: ['vash'],
			light: true,
		},
		{
			icon: 'vapor',
			extensions: ['vapor.yml'],
			filename: true,
		},
		{
			icon: 'vb',
			extensions: ['vb'],
		},
		{
			icon: 'vba',
			extensions: ['vba'],
		},
		{ icon: 'vbhtml', extensions: ['vbhtml'] },
		{ icon: 'vbproj', extensions: ['vbproj'] },
		{
			icon: 'vcxproj',
			extensions: ['vcxproj'],
		},
		{
			// https://en.wikipedia.org/wiki/Video_file_format
			icon: 'video',
			extensions: [
				'3g2',
				'3gp',
				'asf',
				'amv',
				'avi',
				'divx',
				'qt',
				'f4a',
				'f4b',
				'f4p',
				'f4v',
				'flv',
				'm2v',
				'm4v',
				'mkv',
				'mk3d',
				'mov',
				'mp2',
				'mp4',
				'mpe',
				'mpeg',
				'mpeg2',
				'mpg',
				'mpv',
				'nsv',
				'ogv',
				'rm',
				'rmvb',
				'svi',
				'vob',
				'webm',
				'wmv',
			],
		},
		{
			icon: 'vim',
			extensions: ['.vimrc', '.gvimrc'],
			filename: true,
		},
		{
			icon: 'vite',
			extensions: ['vite.config.js', 'vite.config.ts'],
			filename: true,
		},
		{
			icon: 'vscode',
			extensions: ['.vscodeignore'],
			filenamesGlob: ['launch', 'tasks', 'vscodeignore'],
			extensionsGlob: ['json'],
			filename: true,
		},
		{
			icon: 'vsix',
			extensions: ['vsix'],
			light: true,
		},
		{
			icon: 'vsixmanifest',
			extensions: ['vsixmanifest'],
			light: true,
		},
		{
			icon: 'vueconfig',
			extensions: ['.vuerc', 'vue.config.js'],
			filename: true,
		},
		{
			icon: 'wallaby',
			extensions: [],
			filenamesGlob: ['wallaby', 'wallaby.conf', '.wallaby', '.wallaby.conf'],
			extensionsGlob: ['json', 'js', 'ts', 'coffee'],
			filename: true,
		},
		{
			icon: 'watchmanconfig',
			extensions: ['.watchmanconfig'],
			filename: true,
		},
		{
			icon: 'wasm',
			extensions: ['wasm'],
		},
		{
			icon: 'webp',
			extensions: ['webp'],
		},
		{
			icon: 'webpack',
			extensions: [],
			filenamesGlob: [
				'webpack.base.conf',
				'webpack.common',
				'webpack.config',
				'webpack.config.base',
				'webpack.config.common',
				'webpack.config.dev',
				'webpack.config.development',
				'webpack.config.staging',
				'webpack.config.test',
				'webpack.config.prod',
				'webpack.config.production',
				'webpack.config.babel',
				'webpack.config.base.babel',
				'webpack.config.common.babel',
				'webpack.config.dev.babel',
				'webpack.config.development.babel',
				'webpack.config.staging.babel',
				'webpack.config.test.babel',
				'webpack.config.prod.babel',
				'webpack.config.production.babel',
				'webpack.dev',
				'webpack.dev.conf',
				'webpack.prod',
				'webpack.prod.conf',
				'webpack.main.config',
				'webpack.mix',
				'webpack.plugins',
				'webpack.renderer.config',
				'webpack.rules',
				'webpack.test.conf',
			],
			extensionsGlob: ['js', 'coffee', 'ts'],
			filename: true,
		},
		{
			icon: 'wercker',
			extensions: ['wercker.yml'],
			filename: true,
		},
		{
			icon: 'word',
			extensions: ['doc', 'docx', 'docm', 'dot', 'dotx', 'dotm', 'wll'],
		},
		{
			icon: 'wpml',
			extensions: ['wpml-config.xml'],
			filename: true,
		},
		{ icon: 'wxml', extensions: ['wxml'] },
		{ icon: 'wxss', extensions: ['wxss'] },
		{
			icon: 'xcode',
			extensions: ['xcodeproj'],
		},
		{
			icon: 'xfl',
			extensions: ['xfl'],
			light: true,
		},
		{ icon: 'xib', extensions: ['xib'] },
		{
			icon: 'xliff',
			extensions: ['xliff', 'xlf'],
		},
		{
			icon: 'xml',
			extensions: ['xml', 'pex', 'tmlanguage'],
		},
		{
			icon: 'xsl',
			extensions: ['xsl'],
		},
		{
			icon: 'yaml',
			extensions: ['yaml'],
			light: true,
		},
		{
			icon: 'yamllint',
			extensions: ['.yamllint'],
			filename: true,
		},
		{
			icon: 'yandex',
			extensions: ['.yaspellerrc', '.yaspeller.json'],
			filename: true,
		},
		{
			icon: 'yarn',
			extensions: [
				'yarn.lock',
				'.yarnrc',
				'.yarnclean',
				'.yarn-integrity',
				'.yarn-metadata.json',
				'.yarnignore',
			],
			filename: true,
		},
		{
			icon: 'yeoman',
			extensions: ['.yo-rc.json'],
			filename: true,
		},
		{
			icon: 'zeit',
			extensions: ['now.json', '.nowignore', 'vercel.json', '.vercelignore'],
			light: true,
			filename: true,
		},
		{
			icon: 'zip',
			extensions: [
				'zip',
				'rar',
				'7z',
				'tar',
				'tgz',
				'bz',
				'gz',
				'bzip2',
				'xz',
				'bz2',
				'zipx',
			],
		},
	];

	useEffect(() => {
		const fileArr = String(props.filename).split('.');
		if (Array.isArray(fileArr)) {
			const value = extensions.find((value: any) => {
				return value.extensions.indexOf(fileArr[fileArr.length - 1]) >= 0;
			});
			
			if (value !== undefined && value !== null) {
				setIcon('file_type_' + value.icon);
			} else {
				setIcon('default_file');
			}
		}
	}, [props.filename])
		

	return (
		<>
			<img
				src={
					`/assets/file-icons/` +
					icon +
					`.svg`
				}
				style={{ maxHeight: props.height }}
			/>
		</>
	);
}

export default FileIcon;
import { useState, useEffect, useContext } from 'react';
import {
	IonCol,
	IonContent,
	IonFooter,
	IonHeader,
	IonModal,
	IonRow,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonButton,
	useIonAlert,
} from '@ionic/react';
import { Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import TitleBar from '../../components/TitleBar/TitleBar';
import Map from '../../components/Maps/Map';
import { subtleGreyScale } from '../../components/Maps/MapStyles';
import { MapControls, PropCluster, PropMarker } from '../../interfaces/Maps/MapsInterface';
import { useSubscribe } from '../../helpers/events';
import { authContext } from '../../contexts/AuthContext';
import { webSocketContext } from '../../contexts/WebSocketContext';
import WorkerList from '../../components/Maps/WorkerList/WorkerList';
import Messages from '../../components/Chat/Messages/Messages';
import useConversation from '../../utils/custom-hooks/Chat/useConversation';
import { AutoComplete } from '../../components/Forms/FormFields';
import WorkersOptions from '../../utils/autocomplete/WorkersOptions';
import Loading from '../../components/UI/Loading';
import axios from '../../lib/axios';
import {
	getData,
	locations,
	removeMarker,
	removeWorker,
	subscribeGPSWorkerStartBreak,
	subscribeGPSWorkerUpdate,
} from '../../helpers/liveLocations';

const CurrentLocationsIndex = () => {
	const [clusters, setClusters] = useState<Array<PropCluster>>([]);
	const [presentAlert] = useIonAlert();

	const createControls = (data: Array<any>): Array<MapControls> => {
		return [
			{
				position: 'TOP_CENTER',
				component: (
					<AutoComplete
						classNames={{
							menu: (state: any) => 'react-select-menu',
							menuList: (state: any) => 'react-select-menu-list',
							option: (state: any) => 'react-select-option',
						}}
						placeholder='Please start typing the name of a worker...'
						loadOptions={(inputValue: any, callback: any) => {
							WorkersOptions(inputValue, callback);
						}}
						isClearable={true}
						value={selectWorker}
						isMulti={false}
						defaultOptions={true}
						useLabels={false}
						editMode={true}
						onChangeCallback={(options: any) => {
							setSelectWorker(options);
						}}
					/>
				),
				data: {},
			},
			{
				position: 'LEFT_TOP',
				component: WorkerList,
				data: { data: data, workerClick: setSelectWorker },
				style: { height: '100%' },
			},
		];
	};

	const authCtx: any = useContext(authContext);
	const webSocketCtx: any = useContext(webSocketContext);
	const [mapCenter, setMapCenter] = useState({ lat: 54.093409058179, lng: -2.8947900255456 });
	const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
	const [zoom, setZoom] = useState<number>(6);
	const [selectWorker, setSelectWorker] = useState<any>(null);
	const [markers, setMarkers] = useState<Array<PropMarker>>([]);
	const [locationData, setLocationData] = useState<any>({});
	const [controls, setControls] = useState<Array<MapControls>>([]);
	const [showOverlay, setShowOverlay] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [worker, setWorker] = useState<any>({});
	const [conversationId, setConversationId] = useState<string>('');
	const [conversation, setConversation] = useState<any>({});
	const [idleFunc, setIdleFunc] = useState<Function>(() => {});
	const { save } = useConversation();

	useSubscribe(
		'message:worker',
		(worker: any) => {
			if (authCtx.user.id !== worker.user_id) {
				save([authCtx.user.id, worker.user_id], '', (data: any) => {
					setConversationId(data.data._id);
					setConversation(data.data);
					setShowOverlay(true);
					setWorker(worker);
				});
			} else {
				presentAlert({
					header: 'Cannot send message',
					message: 'You cannot send a message to yourself',
					buttons: ['OK'],
				});
			}
		},
		[]
	);

	useSubscribe(
		'message:job',
		(job: any) => {
			let obj: any = {
				job_id: job._id,
				user_id: authCtx.user.id,
			};
			axios.put('api/jobs/conversation/participants', obj).then((response) => {
				setConversationId(response.data._id);
				setConversation(response.data);
				setShowOverlay(true);
				setWorker({
					name: response.data.name,
				});
			});
		},
		[]
	);

	useSubscribe(
		'GPS:workerStartBreak',
		(data: any) => {
			subscribeGPSWorkerStartBreak(data, locationData, setLocationData, clusters, setClusters);
		},
		[locationData, markers]
	);

	useSubscribe(
		'GPS:workerStopBreak',
		(data: any) => {
			let localData = removeWorker(data.item._id, locationData);
			setLocationData(localData);
		},
		[locationData]
	);

	useSubscribe(
		'GPS:workerStop',
		(data: any) => {
			let localData = removeWorker(data.item._id, locationData);
			if (localData['general'].length > 0) {
				let breakIndex = localData['general'].findIndex((ldata: any, index: number) => {
					return ldata._id === 'stop_work';
				});
				if (breakIndex > -1) {
					let workerIndex = localData['general'][breakIndex].workers.findIndex((ldata: any) => {
						return ldata.name === data.item.worker;
					});
					if (workerIndex === -1) {
						localData['general'][breakIndex].workers.push({
							name: data.item.worker,
							_id: data.item._id,
							date: data.item.date,
						});
					}
				}
			}
			setLocationData(localData);

			removeMarker(data.item._id, clusters, setClusters);
		},
		[locationData, markers]
	);

	useSubscribe(
		'GPS:workerUpdate',
		(data: any) => {
			subscribeGPSWorkerUpdate(data, clusters, setClusters, locationData, setLocationData);
		},
		[markers]
	);

	useEffect(() => {
		getData(setLocationData);
	}, []);

	useEffect(() => {
		setControls(createControls(locationData));
	}, [locationData]);

	useEffect(() => {
		if (webSocketCtx.isConnected) {
			webSocketCtx.sendMessage(
				JSON.stringify({
					type: 'User:locationUpdates',
					data: true,
				})
			);
		} else {
			webSocketCtx.sendMessage(
				JSON.stringify({
					type: 'User:locationUpdates',
					data: false,
				})
			);
		}
	}, [webSocketCtx.isConnected]);

	useEffect(() => {
		return () => {
			webSocketCtx.sendMessage(
				JSON.stringify({
					type: 'User:locationUpdates',
					data: false,
				})
			);
		};
	}, []);

	useEffect(() => {
		let localMarkers = [...clusters];
		if (localMarkers.length > 0) {
			localMarkers[0].markers.forEach((marker: PropMarker, index: number) => {
				marker.isOpen = false;
			});

			if (selectWorker !== null) {
				let found: number = localMarkers[0].markers.findIndex((marker) => {
					return marker._id === selectWorker.value;
				});

				if (found > -1) {
					localMarkers[0].markers[found].isOpen = true;
					setMapCenter({
						lat: localMarkers[0].markers[found].latlng.lat,
						lng: localMarkers[0].markers[found].latlng.lng,
					});
					setZoom(20);
					setClusters(localMarkers);
				} else {
					presentAlert({
						header: 'Not on map',
						message: 'The person selected is not on the map',
						buttons: ['OK'],
					});
				}
			}
		}
		setControls(createControls(locationData));
	}, [selectWorker]);

	useEffect(() => {
		if (mapRef) {
			locations(mapRef, setLoading, setMapCenter, setZoom, setIdleFunc, setMarkers, setClusters);
		}
	}, [mapRef]);

	const closeModal = () => {
		setShowOverlay(false);
		setWorker({});
	};

	const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
		setConversationId('');
	};

	return (
		<>
			<IonModal
				style={{ '--width': '70%' }}
				isOpen={showOverlay}
				onDidDismiss={() => {
					setShowOverlay(false);
					setWorker({});
				}}
			>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Send Message To {worker.name}</IonTitle>
						<IonButtons slot='end' className='ion-modal-buttons'>
							<IonButton onClick={() => closeModal()}>
								<FontAwesomeIcon icon={faTimes} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<Messages
						isVisible={true}
						conversationId={conversationId}
						conversation={conversation}
						onWillDismiss={onWillDismiss}
						readOnly={false}
						showTitle={false}
					/>
				</IonContent>
				<IonFooter>
					<IonToolbar>
						<IonRow>
							<IonCol size='12' className='text-right'>
								<IonButton color='secondary' onClick={() => closeModal()}>
									Close
								</IonButton>
							</IonCol>
						</IonRow>
					</IonToolbar>
				</IonFooter>
			</IonModal>
			<TitleBar title={'Live Locations'} />
			<div className='flex-grow-container' style={{ height: 'calc(100% - 69px)' }}>
				{loading && <Loading overlay={true} />}
				<Alert severity='info' style={{ margin: '0 0 8px 0' }}>
					Both job and worker markers available in all theme colours for displaying correct
					status/security level. Use the search box at the top of the map to find a worker.
				</Alert>
				<Map
					mapStyle={subtleGreyScale}
					center={mapCenter}
					markers={markers}
					markerClusters={clusters}
					controls={controls}
					zoom={zoom}
					onMapZoom={(zoom: number) => {
						setZoom(zoom);
					}}
					onMapLoad={(map: google.maps.Map) => {
						setMapRef(map);
					}}
					onMapIdle={idleFunc}
				/>
			</div>
		</>
	);
};

export default CurrentLocationsIndex;

import { IonApp, IonContent, IonPage, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from './routes';
import { useState, useEffect, useMemo } from 'react';
import { Device } from '@capacitor/device';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Menu from './components/Menu/Menu';
import AuthenticatingMessage from './components/Auth/AuthenticatingMessage';
import Login from './components/Auth/Login';
import TwoFA from './components/Auth/TwoFA';
import SyncComponent from './components/Database/SyncComponent';
import WebSocketProvider from './contexts/WebSocketContext';
import AuthContextProvider from './contexts/AuthContext';
import GoogleMapsProvider from './contexts/GoogleMapsContext';
import axios from './lib/axios';
import { setBrowserID } from './state/slices/browserID';
import { setLoginSuccess } from './state/slices/appState';
import { ResponseInterceptor } from './utils/ResponseInterceptor';
import { publish, useSubscribe } from './helpers/events';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Stylesheets */
import './assets/scss/mobiscroll-bootup.scss';
import './assets/scss/variables.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/propeller-styles.scss';
import './assets/scss/document.scss';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/print.scss';
import { closeToast, showToast } from './lib/toast';
import WorkerAlarm from './components/Overlays/WorkerAlarm';

setupIonicReact();

const App = () => {
	const styles = document.createElement('style');
	styles.textContent = `
		::-webkit-scrollbar {
			width: 12px;
			height: 12px;
		}
		::-webkit-scrollbar-thumb {
			border-radius: var(--ion-border-radius-medium);
			box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
			background-color: var(--ion-color-medium);
		}
		::-webkit-scrollbar-track {
			border-radius: var(--ion-border-radius-medium);
			box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
			background-color: var(--ion-color-light);
		}
	`;

	// Initial setup
	let noAuthCheck = false;
	const authDataUnAuthState = { isAuthenticated: false, navData: [] };

	const [loading, setLoading] = useState(true);
	const [twoFactor, setTwoFactor] = useState(false);
	const [displayMenuExpanded, setDisplayMenuExpanded] = useState(false);
	const [authData, setAuthData] = useState(authDataUnAuthState);

	const dispatch = useDispatch();
	const menuExpanded = useSelector((state: any) => state.menuExpanded.value);
	let appState = useSelector((state: any) => state.appState.value);
	let browserID = useSelector((state: any) => state.browserID.value);

	const arrNoAuthCheck = useMemo(() => {
		return [
			'/forgotten-password',
			'/password-reset',
			'/external/client/my-page/',
			'/external/client/kpi-rating/',
		];
	}, []);

	// Match the pathname to the arrNoAuthCheck array items
	arrNoAuthCheck.some((item: string) => {
		let p = window.location.pathname;
		if (item === p || item === p.substring(0, item.length)) {
			noAuthCheck = true;
			return;
		}
	});

	// Check if viewing an external page
	const isExternalPage: boolean = window.location.pathname.substring(0, 10) === '/external/';

	const showWorkerInDanger = (worker: any) => {
		if (worker.inAlarm) {
			showToast('error', <WorkerAlarm worker={worker} />, 'worker_in_danger:' + worker._id, {
				autoClose: false,
				closeButton: false,
				closeOnClick: false,
				isLoading: false,
				draggable: false,
				position: 'bottom-center',
				className: 'worker-in-danger',
				theme: 'colored',
			});
		} else {
			closeToast('worker_in_danger:' + worker._id);
		}
	};

	const getWorkersInDanger = async () => {
		const response: any = await axios.get('/api/workers/danger/');
		let workers = response.data;
		workers.forEach((worker: any) => {
			showWorkerInDanger(worker);
		});
	};

	useSubscribe(
		'logout',
		(data: any) => {
			setAuthData(authDataUnAuthState);
			window.location.reload();
		},
		[]
	);

	useSubscribe(
		'login',
		(data: any) => {
			getWorkersInDanger();
		},
		[]
	);

	useSubscribe(
		'2fa',
		(data: any) => {
			setTwoFactor(true);
		},
		[]
	);

	useSubscribe(
		'Alarm:worker',
		(data: any) => {
			let worker = data.item;
			showWorkerInDanger(worker);
		},
		[]
	);

	useEffect(() => {
		setTimeout(() => {
			const content = document.querySelector('ion-content');
			if (content && content.shadowRoot) {
				content.shadowRoot.appendChild(styles);
			}
		}, 400);

		// Store the browser ID in a redux slice
		if (browserID.length === 0) {
			Device.getId().then((id) => {
				browserID = id.uuid;
				dispatch(setBrowserID(browserID));
			});
		}
	}, []);

	// Menu expansion state
	useEffect(() => {
		setDisplayMenuExpanded(menuExpanded);
	}, [menuExpanded]);

	// Try to fetch the logged-in user from the server and provide as initial state
	useEffect(() => {
		function authInitialState() {
			setLoading(true);
			// Fetch the user data
			axios
				.post('/api/user', { device_fingerprint: browserID })
				.then((res: any) => {
					setTimeout(() => {
						publish('login', true);
					}, 500);

					// Capture the user's auth data
					const authData = res;

					// Fetch the navigation data
					axios.get('/api/system/modules').then((res) => {
						// Mark the user as authenticated
						authData.data.isAuthenticated = true;

						// Capture the navigation data
						authData.data.navData = res.data;

						// Set auth data to be used as the context's intial state
						setAuthData(authData.data);

						// Update the app state
						setTimeout(() => {
							dispatch(setLoginSuccess());
							setLoading(false);
						}, 500);
					});
				})
				.catch(() => {
					// The user is not logged-in
					setLoading(false);
				});
		}

		// Try an initial user authentication on page load
		if (
			authData.isAuthenticated !== true &&
			!noAuthCheck &&
			appState.loginSuccess === false &&
			browserID.length > 0
		) {
			authInitialState();
		} else {
			setLoading(false);
		}
	}, [authData, arrNoAuthCheck, browserID, noAuthCheck]);

	// Do not render the app until we've checked if they're logged in
	if (loading || browserID.length === 0) {
		return <AuthenticatingMessage />;
	}

	if (twoFactor) {
		return (
			<>
				<IonPage>
					<IonContent>
						<TwoFA
							onClose={(data: any) => {
								setAuthData(data);
								setTwoFactor(false);
							}}
						></TwoFA>
						<ResponseInterceptor arrNoAuthCheck={arrNoAuthCheck} />
					</IonContent>
				</IonPage>
			</>
		);
	}

	// Render the login screen, if not authenticated after check
	if (authData.isAuthenticated !== true && !noAuthCheck && appState.loginSuccess === false) {
		return (
			<>
				<IonPage>
					<IonContent>
						<Login
							onClose={(data: any) => {
								setAuthData(data);
							}}
						></Login>
					</IonContent>
				</IonPage>
			</>
		);
	}

	// Render the app
	return (
		<IonApp className={isExternalPage === true ? 'external-page' : ''}>
			<GoogleMapsProvider>
				<AuthContextProvider initialState={{ ...authData }}>
					<WebSocketProvider>
						<IonReactRouter basename={process.env.PUBLIC_URL}>
							<ResponseInterceptor arrNoAuthCheck={arrNoAuthCheck} />
							{!isExternalPage && <Menu expanded={displayMenuExpanded} />}
							<ToastContainer />

							<IonRouterOutlet
								id='appMainContent'
								className={displayMenuExpanded ? 'router-narrow' : 'router-expanded'}
							>
								<Switch>
									<>
										<IonPage>
											<IonContent>
												<Routes />
												<Route exact path='/'>
													<Redirect to='/dashboard' />
												</Route>
											</IonContent>
										</IonPage>
									</>
								</Switch>
							</IonRouterOutlet>
						</IonReactRouter>
						<SyncComponent></SyncComponent>
					</WebSocketProvider>
				</AuthContextProvider>
			</GoogleMapsProvider>
		</IonApp>
	);
};

export default App;

import { IonPopover } from '@ionic/react';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import { useEffect, useRef } from 'react';
import './Reaction.scss';

interface Props {
	show: boolean;
	onSelect: Function;
	target: any;
}

const Reaction: React.FC<Props> = (props: Props) => {
	const ref: any = useRef<HTMLIonPopoverElement>(null);

	const emojis: Array<string> = [
		'1f44d',
		'2764-fe0f',
		'1f606',
		'1f632',
		'1f614',
		'1f620',
	];

	useEffect(() => {
		ref.current!.event = props.target;
	}, [props.target]);

	return (
		<>
			<IonPopover
				side="top"
				alignment="start"
				ref={ref}
				isOpen={props.show}
				className="reaction-picker"
				onDidDismiss={() => {
					props.onSelect(null);
				}}
			>
				{emojis.map((emoji, index) => {
					return (
						<div
							className="emojiWrapper"
							key={index}
							onClick={() => {
								props.onSelect(emoji);
							}}
						>
							<Emoji
								emojiStyle={EmojiStyle.NATIVE}
								size={22}
								unified={emoji}
							></Emoji>
						</div>
					);
				})}
			</IonPopover>
		</>
	);
};

export default Reaction;

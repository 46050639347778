import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useUserDefinedFieldsArray from '../arrays/userDefinedFieldsArray';
import axios from '../../../../lib/axios';
import Form from '../../../../components/Forms/Form';
import { db2Form } from '../../../../api/forms';
import Loading from '../../../../components/UI/Loading';
import { IonRow, IonCol } from '@ionic/react';
import WorkerControls from '../WorkerControls';
import { WorkersContext } from '../WorkersProvider';
import axiosMP from '../../../../lib/axiosMp';
import { AxiosProgressEvent } from 'axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';

interface Props {
	nextFunction?: Function;
	prevFunction?: Function;
	permissionTo: Function;
}

const UserDefinedFields: React.FC<Props> = (props: Props) => {
	const tabId = 'user_defined_fields';
	const { state, dispatch } = useContext(WorkersContext);
	const [items, setItems] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { workerId }: any = useParams();
	const endPoint = '/api/workers/workers_list/worker_card';
	const endPointId = workerId;
	const formDataArray = useUserDefinedFieldsArray(state);
	const toastId = useRef<any>(null);

	useEffect(() => {
		loadForm();
	}, []);

	const loadForm = async () => {
		let formData: any = await formDataArray.getFields();

		// Apply the file upload handlers
		formData.map((fd: any) => {
			if (fd.type === 'fileUpload') {
				fd.handleFileUpload = handleFileUpload;
			}
			return fd;
		});

		setItems([]);

		axios
			.get(`${endPoint}/${endPointId}`)
			.then((res) => res.data)
			.then((dbData) => {
				formData = db2Form(formData, dbData);
				setItems(formData);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleFileUpload = async (e: any, fileId: string) => {
		const file = e.target.files[0];

		let fd = new FormData();
		fd.append('_method', 'POST');
		fd.append('upload', file);
		fd.append('id', fileId);

		// Reset
		toastId.current = null;

		setIsLoading(true);

		await axiosMP
			.post(`${endPoint}/${endPointId}`, fd, {
				onUploadProgress: (p: AxiosProgressEvent) => {
					const progress = p.loaded / (p.total ?? 0);

					if (toastId.current === null) {
						toastId.current = toast('Uploading file...', { type: toast.TYPE.INFO });
					} else {
						toast.update(toastId.current, {
							progress,
							render: 'Uploading file (' + Math.floor(progress * 100) + '%)...',
						});
					}
				},
			})
			.then(() => {
				showToast('success');
				loadForm();
			})
			.catch(() => {
				showToast('error');
				setIsLoading(false);
			})
			.finally(() => {
				toast.dismiss(toastId.current);
			});

		return true;
	};

	return (
		<>
			{isLoading && <Loading height={600} />}
			{!isLoading && items && items.length > 0 && (
				<>
					<IonRow className='mb-4'>
						<IonCol size={'12'}>
							<Form
								method='POST'
								array={items}
								permissionTo={props.permissionTo}
								forceEdit={props.permissionTo('update')}
								noButton={true}
								endpoint={endPoint}
								endpointID={endPointId}
							/>
						</IonCol>
					</IonRow>

					<WorkerControls tabId={tabId} workerId={workerId} setIsLoading={setIsLoading} />
				</>
			)}
		</>
	);
};

export default UserDefinedFields;

import { useContext, useEffect, useRef, useState } from 'react';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import usePermissionTo from '../../../utils/custom-hooks/PermissionTo';
import { dateFormatter } from '../../../helpers/dateFormatter';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { showToast } from '../../../lib/toast';
import axios from '../../../lib/axios';
import { JobStatus } from '../../Jobs/Jobs/job-types';
import { DateTime } from 'luxon';
import { authContext } from '../../../contexts/AuthContext';

interface Props {
	widgetId: string;
	systemSettings: any;
}

const SuspendedJobs: React.FC<Props> = (props) => {
	const authCtx: any = useContext(authContext);
	const gridRef: any = useRef<any>();
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [tableData, setTableData] = useState<Array<any>>([]);
	const [jobId, setJobId] = useState<string | null>(null);
	const permissionTo = usePermissionTo('jobs.jobs_list');
	const permissionToEditJob = permissionTo(['read', 'update'], 'jobs.jobs_list.job_card');
	let history = useHistory();
	let wgtKey = '';
	let wgtHeader = '';
	let wgtTitleWord = '';

	// Widget mode
	switch (props.widgetId) {
		case 'dashboard.company.suspended_jobs':
			wgtKey = 'manager';
			wgtHeader = 'Manager';
			wgtTitleWord = 'Company';
			break;
		case 'dashboard.personal.suspended_jobs':
			wgtKey = 'work_suspended_reason';
			wgtHeader = 'Reason';
			wgtTitleWord = 'My';
			break;
	}

	useEffect(() => {
		if (gridReady) {
			loadGridData();
		}
	}, [gridReady]);

	const columns: Array<any> = [
		{ field: 'number', headerName: 'Job Number', width: 100 },
		{ field: 'name', headerName: 'Job Name', flex: 1 },
		{ field: wgtKey, headerName: wgtHeader, flex: 1 },
		{
			field: 'work_suspended_date',
			headerName: 'Date',
			valueFormatter: dateFormatter,
			sort: 'asc',
			width: 100,
		},
	];

	const loadGridData = () => {
		setGridLoading(true);
		setJobId(null);
		setTableData([]);

		const payload: any = {
			status: JobStatus.SUSPENDED,
		};

		if (props.widgetId === 'dashboard.personal.suspended_jobs') {
			payload.worker_id = authCtx.user.worker_id;
		}

		axios
			.post('/api/jobs/jobs_list', payload)
			.then((res: any) => {
				setTableData(
					res.data.sort(function (a: any, b: any) {
						return DateTime.fromISO(a.date_suspended).valueOf() <
							DateTime.fromISO(b.date_suspended).valueOf()
							? -1
							: 1;
					})
				);
			})
			.catch(() => {
				showToast('error', `There was a problem with the ${wgtTitleWord} Suspended Jobs widget`);
			})
			.finally(() => setGridLoading(false));
	};

	const handleViewList = () => {
		history.push('/jobs/jobs_list?status=suspended');
	};

	const handleRowClicked = (params: any) => {
		params.node.setSelected(true);
		setJobId(params.data.id);
	};

	const handleRowDoubleClicked = (params: any) => {
		handleRowClicked(params);
		handleEditJob();
	};

	const handleEditJob = () => {
		if (jobId === null) {
			showToast('error', 'Please select a job from the list');
			return;
		}

		const path = `/jobs/jobs_list/job_card/${jobId}`;
		history.push(path);
	};

	return (
		<div className={`widget-container ${props.widgetId.replaceAll('.', '-')}`}>
			<div className='widget-header'>{wgtTitleWord} Suspended Jobs</div>
			<div className='widget-content'>
				<div className='left'>
					<DataGrid
						ref={gridRef}
						title=''
						onGridReady={() => setGridReady(true)}
						useSearch={false}
						cols={columns}
						data={tableData}
						rowClickedFunction={handleRowClicked}
						rowDoubleClickedFunction={handleRowDoubleClicked}
						rowCursorPointer={permissionToEditJob}
						suppressRowHoverHighlight={!permissionToEditJob}
						compact={true}
						gridSizeChangedFunction={() => {
							if (gridReady) gridRef.current.sizeToFit();
						}}
					/>
				</div>
				<div className='right'>
					<div className='widget-plaque'>
						<div className='title'>Total</div>
						<div className='stat'>{gridLoading ? '-' : tableData.length}</div>
					</div>
				</div>
			</div>
			<div className='widget-footer justify-content-end'>
				{permissionTo('read') && (
					<IonButton className='btn-alt' size='small' onClick={handleViewList}>
						View Full List
					</IonButton>
				)}
				{permissionToEditJob && (
					<IonButton size='small' onClick={handleEditJob}>
						Show Details
					</IonButton>
				)}
			</div>
		</div>
	);
};

export default SuspendedJobs;

import chroma from 'chroma-js';
import { DateTime } from 'luxon';
import { EventType as RequestEventType } from '../pages/Workers/HolidayAbsenceTraining/Requests/request-types';
import {
	EventType,
	ResourceMode,
	EventType as SchedulingEventType,
} from '../pages/Scheduling/scheduling-types';
import { initialsFromString } from './strings';
import axios from '../lib/axios';
import { showToast } from '../lib/toast';

type Sources = 'scheduling' | 'holidayChart' | 'workersDiary';

interface Args {
	event: any;
	allDay?: boolean;
	source?: Sources;
	resourceMode?: ResourceMode;
}

export const parseCalendarEvent = ({ event, allDay = false, source, resourceMode }: Args) => {
	let cssClasses: Array<string> = [];
	let eventObj: any = {
		start: DateTime.fromISO(event.start),
		end: DateTime.fromISO(event.end),
		allDay: allDay,
	};

	if (event.hasOwnProperty('id')) eventObj.id = event.id;
	if (event.hasOwnProperty('request_id')) eventObj.request_id = event.request_id;
	if (event.hasOwnProperty('job_id')) eventObj.job_id = event.job_id;
	if (event.hasOwnProperty('worker_id')) eventObj.worker_id = event.worker_id;
	if (event.hasOwnProperty('resource')) eventObj.resource = event.resource;
	if (event.hasOwnProperty('description')) eventObj.description = event.description;
	if (event.hasOwnProperty('title')) {
		eventObj.title = event.title;
	}
	if (event.hasOwnProperty('event_type')) {
		eventObj.title = event.event_type; // Title override
		eventObj.eventType = event.event_type;
	}
	if (event.hasOwnProperty('colour')) {
		eventObj.color = chroma('#' + event.colour);
	}
	if (event.hasOwnProperty('enum_event_type')) {
		eventObj.enumEventType = event.enum_event_type;
		cssClasses.push('event-type-' + event.enum_event_type);
	}

	// Event attendees
	if (
		event.hasOwnProperty('attendees') &&
		event.hasOwnProperty('enum_event_type') &&
		event.enum_event_type === SchedulingEventType.APPOINTMENT
	) {
		eventObj.attendees = event.attendees;
	}

	// Event type specifics
	switch (event.enum_event_type) {
		case RequestEventType.HOLIDAY:
			if (event.enum_status !== 'approved') {
				eventObj.requested = true;
				eventObj.title += ': Requested';
				eventObj.color = eventObj.color.alpha(0.55);
			}
			break;
		case SchedulingEventType.APPOINTMENT:
			if (
				event.hasOwnProperty('enum_event_type') &&
				event.enum_event_type === EventType.APPOINTMENT
			) {
				switch (resourceMode) {
					case ResourceMode.JOBS:
					case ResourceMode.SKILLS:
						// Event title is the name of the worker
						eventObj.title = event.appointment_for;
						break;
					case ResourceMode.WORKERS:
						// Event title is the job title
						eventObj.title = event.description;
						break;
				}
			}
			break;
	}

	// Source specifics
	switch (source) {
		case 'workersDiary':
			if (event.enum_event_type === SchedulingEventType.APPOINTMENT) {
				let eventTitle: Array<string> = [];
				if (event.hasOwnProperty('job_number') && event.job_number)
					eventTitle.push(event.job_number);
				if (event.hasOwnProperty('job_name') && event.job_name) eventTitle.push(event.job_name);
				if (eventTitle.length > 0) eventObj.title = eventTitle.join(': ');
			}
			break;
		case 'holidayChart':
			cssClasses.push('event-source-holiday-chart');
			break;
		case 'scheduling':
			cssClasses.push('sch-conn-' + eventObj.request_id);
			break;
	}

	// Apply classes
	if (cssClasses.length > 0) eventObj.cssClass = cssClasses.join(' ');

	return eventObj;
};

export const loadBankHolidays = (month: number, year: number, setBankHolidays: Function) => {
	axios
		.post('/api/utilities/general/bank_holidays', {
			start: DateTime.fromFormat(`${year}-${month}`, 'yyyy-M').toISODate(),
			end: DateTime.fromFormat(`${year}-${month}`, 'yyyy-M')
				.plus({ month: 1 })
				.endOf('month')
				.toISODate(),
		})
		.then((res: any) => {
			setBankHolidays(
				res.data.map((item: any) => ({
					start: DateTime.fromISO(item.start).startOf('day'),
					end: DateTime.fromISO(item.start).endOf('day'),
					cssClass: 'bg-cal-cell-bank-holiday',
					cellCssClass: 'bg-cal-cell-bank-holiday',
				}))
			);
		})
		.catch(() => showToast('error'));
};

import { IonFab, IonFabButton, IonHeader, IonSearchbar, IonToolbar } from '@ionic/react';
import { useState, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router';
import { OverlayEventDetail } from '@ionic/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { listReducer } from '../../../helpers/listReducer';
import { useSubscribe } from '../../../helpers/events';
import InfinteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import ConversationItem from '../ConversationItem/ConversationItem';
import { ConversationInterface } from '../../../interfaces/Chat/ConversationInterface';
import axios from '../../../lib/axios';
import { csrf } from '../../../helpers/csrf';
import Messages from '../Messages/Messages';
import NewConversation from '../NewConversation/NewConversation';
import './Conversations.scss';

interface Props {
	readOnly: boolean;
	convoListFunction: Function;
	workerId?: string;
}

const Conversations: React.FC<Props> = (props: Props) => {
	const [conversationData, dispatchConversationData] = useReducer(listReducer, {
		list: [],
		originalList: [],
		isShowList: true,
	});

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
	const [conversationId, setConversationId] = useState<string>('');
	const [conversation, setConversation] = useState<any>({});
	const [search, setSearch] = useState<string>('');

	const location = useLocation();

	useSubscribe(
		'switchConversation',
		(data: any) => {
			setConversationId(data);
		},
		[]
	);

	useSubscribe(
		'database:new:conversations',
		(data: any) => {
			dispatchConversationData({
				type: 'add',
				data: data,
				position: 'top',
			});
		},
		[]
	);

	useSubscribe(
		'database:delete:conversations',
		(data: any) => {
			dispatchConversationData({ type: 'remove', data: data });
		},
		[]
	);

	useEffect(() => {
		if (props.convoListFunction !== undefined) {
			props.convoListFunction(isNewModalOpen !== true && isModalOpen !== true);
		}
	}, [isNewModalOpen, isModalOpen])

	useEffect(() => {
		getConversations().then(() => {
			const params = new URLSearchParams(location.search);
			if (params && params.has('conversation') && !isModalOpen) {
				let cId = params.get('conversation') ?? '';
				setConversationId(cId);
			}
			if (params && params.has('new-conversation') && !isNewModalOpen) {
				addConversation();
			}
		});
	}, []);

	useEffect(() => {
		if (conversationId !== '') {
			getConversation(conversationId);
		}
	}, [conversationId]);

	useEffect(() => {
		if (search !== '') {
			dispatchConversationData({
				type: 'filter',
				data: {
					keys: ['name', 'participant_names'],
					text: search,
				},
			});
		} else {
			dispatchConversationData({
				type: 'clearFilter',
			});
		}
	}, [search]);

	const getConversations = () => {
		return new Promise((res, rej) => {
			csrf().then(() => {
				if (props.workerId === undefined) {
					axios
						.get('/api/conversations')
						.then((data: any) => {
							let d = data.data;
							dispatchConversationData({ type: 'replace', data: d });
							res(true);
						})
						.catch((error: any) => {
							console.error(error);
							rej(error);
						});
				} else {
					axios
						.post('/api/conversations', {
							worker_id: props.workerId,
						})
						.then((data: any) => {
							let d = data.data;
							dispatchConversationData({ type: 'replace', data: d });
							res(true);
						})
						.catch((error: any) => {
							console.error(error);
							rej(error);
						});
				}
			});
		});
	};

	const getConversation = (conversation_id: string | null) => {
		if (conversation_id !== null) {
			setIsModalOpen(true);
			setConversationId(conversation_id);
			setConversation(
				conversationData.list.find((conversation: ConversationInterface) => {
					return conversation._id === conversation_id;
				})
			);
		}
	};

	const onWillDismiss = (ev: CustomEvent<OverlayEventDetail>) => {
		setConversationId('');
		getConversations();
		setIsModalOpen(false);
	};
	
	const onNewWillDismiss = (data: any) => {
		if (data.data !== undefined) {
			getConversations().then(() => {
				setConversationId(data.data._id);
			});
		}
		setIsNewModalOpen(false);
	};

	const addConversation = () => {
		setIsNewModalOpen(true);
	};

	const handleSearchChange = (ev: any) => {
		let query: string = '';
		const target = ev.target as HTMLIonSearchbarElement;
		if (target) query = target.value!.toLowerCase();
		setSearch(query);
	};

	return (
		<div className="flex-grow-container" style={{height: '100%'}}>
			{!isNewModalOpen && !isModalOpen && (
				<>
					<IonHeader>
						<IonToolbar color={'primary'}>
							<div slot='end'>
								<IonSearchbar
									onKeyUp={(e) => (e.key === 'Enter' ? handleSearchChange(e) : null)}
									onIonClear={(ev) => setSearch('')}
									onIonBlur={(ev) => handleSearchChange(ev)}
									value={search}
								/>
							</div>
						</IonToolbar>
					</IonHeader>
					<div className='content'>
						<InfinteScroll
							items={conversationData.list}
							itemTemplate={ConversationItem}
							click={setConversationId}
						></InfinteScroll>
					</div>

					{(props.readOnly === undefined || props.readOnly === false) && (
						<IonFab slot='fixed' vertical='bottom' horizontal='end'>
							<IonFabButton color={'success'} className='icon-only' onClick={addConversation}>
								<FontAwesomeIcon icon={faPlus} />
							</IonFabButton>
						</IonFab>
					)}
				</>
			)}

			{!isNewModalOpen && isModalOpen && (
				<Messages
					isVisible={isModalOpen}
					conversationId={conversationId}
					conversation={conversation}
					onWillDismiss={onWillDismiss}
					readOnly={props.readOnly}
					showTitle={(props.workerId !== undefined) ? false : true}
					workerId={props.workerId}
				></Messages>
			)}
			{isNewModalOpen && !isModalOpen && (
				<NewConversation
					isVisible={isNewModalOpen}
					onWillDismiss={onNewWillDismiss}
				></NewConversation>
			)}
		</div>
	);
};

export default Conversations;

import { useContext } from 'react';
import { authContext } from '../../../../../../contexts/AuthContext';
import { useGetRoutes } from '../../../../../../routes';
import { getPermissionTo } from '../../../../../../lib/functions';

const StylesSubmenuArray: any = (uid: string) => {
	const authCtx = useContext<any>(authContext);
	const routes = useGetRoutes(uid, true);
	let tmp: Array<any> = [];

	if (Array.isArray(routes) && routes.length > 0) {
		for (let i = 0; i < routes.length; i++) {
			let r = routes[i];
			tmp.push({
				id: `submenu-${r.uid.replace(/_/g, '').split('.').join('')}`,
				uid: r.uid,
				title: r.title,
				page: r.component,
				hash: r.security_key.replace('_', '-'),
				active: i === 0,
				fullHeight: r.security_key === 'terms_and_conditions',
				pageData: {
					uid: r.uid,
					permissionTo: (actions: string | Array<any> = '', uid: string = r.uid) => {
						return getPermissionTo(actions, uid, authCtx);
					},
				},
			});
		}
	}

	return tmp;
};

export default StylesSubmenuArray;
